/**
 * Custom fetch wrapper with error handling and auth headers
 *
 * @param {string} url - The request URL
 * @param {RequestInit} options - Fetch options
 *
 * @throws {Error} - The json response in case of non-20X status code
 * @returns {Promise} - Parsed response data in case of 20X status code
 */
export const customFetch = async (url, options = {}) => {
  const authHeaders = getAuthHeaders();

  const headers = new Headers(options.headers);

  if (authHeaders.Authorization)
    headers.append('Authorization', authHeaders.Authorization);

  if (authHeaders.admin) headers.append('admin', authHeaders.admin);

  const fetchOptions = {
    ...options,
    headers,
  };

  const response = await fetch(url, fetchOptions);

  return handleNonOkResponse(response);
};

/**
 * Get the authentication tokens from local storage
 *
 * @returns {object} - The authentication tokens (`Authorization` and `admin`)
 */
function getAuthHeaders() {
  const headers = {};
  const jwtToken =
    localStorage.getItem('Authorization') ||
    localStorage.getItem('t_Authorization');

  if (jwtToken) {
    headers['Authorization'] = jwtToken;
  }

  const adminAuthToken = localStorage.getItem('i_Authorization');
  if (adminAuthToken) {
    headers['admin'] = adminAuthToken;
  }

  return headers;
}

/**
 * Handle HTTP errors (non-20X status codes)
 *
 * @param {Response} res - Fetch response object
 *
 * @throws {Error} - The json response in case of non-20X status code
 * @returns {Promise} - Parsed response data in case of 20X status code
 */
const handleNonOkResponse = async (res) => {
  if (res.ok) return res;

  const resErr = await res.json();

  throw resErr;
};

export default customFetch;
