/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Drawer, Popover, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  NotificationIcon,
  NavbarIcon,
  CloseIcon,
  SearchIcon,
  DownCaretIcon,
  SimsColorIcon,
} from '../../components/icons';
import Logo from '../../images/logo.png';
import { DebounceInput } from 'react-debounce-input';
import './styles.scss';
import _ from 'lodash';
import moment from 'moment';
import socket from '../../utils/socket';
import NumberFormat from 'react-number-format';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';

const dateFormat = 'MMMM DD, YYYY hh:mm a';

export default class TopNavigationComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userData: {},
      searchVal: '',
      selectedNav: '',
      simTab: '',
      iAuthorization: localStorage.getItem('i_Authorization'),
      isNotificationModal: false,
      notifications: [],
      searchData: [],
      showSearchDropdown: false,
      showSearchMobileDropdown: false,
      skip: 0,
      limit: 5,
      currentPage: 1,
      isread: 0,
      unreadNotificationsCount: 0,
      notificationLoader: true,
    };
  }

  componentDidMount = async () => {
    const iAuthorization = localStorage.getItem('i_Authorization');
    try {
      const {
        value: { success, user, outage, isportaldown },
      } = await this.props.fetchMe();
      if (success && user) {
        if (
          (outage || isportaldown === 'true') &&
          iAuthorization === null &&
          (isSPRPartner(user) || isSubUser(user))
        ) {
          localStorage.clear();
          this.props.history.push('/maintenance');
        } else {
          this.props.setUser(user);
          this.connectSocket(user);
          this.setSelectedNav();
          this.setSimTab();
        }
      } else if (!success) {
        this.logout();
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
      if (!error.success && !error.user) {
        this.logout();
      }
    }
  };

  connectSocket = (user) => {
    socket.on('notifications', (data) => {
      if (data && user) {
        let userNotifications = data.userNotifications.filter(
          (item) => item.userid === user.id
        );
        const userNotificationsCount = data.userNotificationsCount;
        let notifications = this.props.notifications;
        notifications = notifications.concat(userNotifications);
        this.props.setNotification({
          userNotifications: notifications,
          userNotificationsCount,
        });
      }
    });
    socket.on('get:notifications', (data) => {
      if (data && user) {
        let userNotifications = data.userNotifications.filter(
          (item) => item.userid === user.id
        );
        const userNotificationsCount = data.userNotificationsCount;
        const pageno = this.state.currentPage;
        let notifications = pageno === 1 ? [] : this.props.notifications;
        notifications = notifications.concat(userNotifications);
        this.setState({
          notificationLoader : false
        })
        this.props.setNotification({
          userNotifications: notifications,
          userNotificationsCount,
        });
      }
    });
    socket.on('get:unreadnotifications', (data) => {
      if (data && user) {
        let userNotifications = [];
        const userNotificationsCount = 0;
        const unreadNotificationsCount = data.unreadNotificationsCount;
        this.setState({
          unreadNotificationsCount,
        });
        this.props.setNotification({
          userNotifications,
          userNotificationsCount,
        });
      }
    });
    socket.emit('get:unreadnotifications', { userid: _.get(user, 'id', 0) });
    socket.emit('get:notifications', {
      userid: user?.id || 0,
      page: 1,
      limit: this.state.limit,
      isread: 0,
    });
  };

  componentWillUnmount() {
    socket.removeListener('notifications');
    socket.removeListener('get:notifications');
    socket.removeListener('get:unreadnotifications');
  }

  onBellIconClick = () => {
    const { user } = this.props;
    this.setState(
      {
        skip: 0,
        currentPage: 1,
        isread: 0,
      },
      () => {
        socket.emit('get:notifications', {
          userid: _.get(user, 'id', 0),
          page: 1,
          limit: this.state.limit,
          isread: 0,
        });
      }
    );
  };

  onClickViewReadNotifications = () => {
    const { user } = this.props;
    this.setState(
      {
        skip: 0,
        currentPage: 1,
        isread: 1,
      },
      () => {
        socket.emit('get:notifications', {
          userid: _.get(user, 'id', 0),
          page: 1,
          limit: this.state.limit,
          isread: 1,
        });
      }
    );
  };

  onClickLoadMore = () => {
    const { user } = this.props;
    this.setState(
      {
        skip: (this.state.currentPage - 1) * this.state.limit,
        currentPage: this.state.currentPage + 1,
      },
      () => {
        socket.emit('get:notifications', {
          userid: _.get(user, 'id', 0),
          page: this.state.currentPage,
          limit: this.state.limit,
          isread: this.state.isread,
        });
      }
    );
  };

  logout() {
    localStorage.clear();
    this.props.history.push('/login');
  }

  setSelectedNav = () => {
    const path = window.location.pathname;
    let selectedNav = '';
    if (path === '/dashboard') {
      selectedNav = 'dashboard';
    } else if (
      path === '/active-sims' ||
      path === '/available-sims' ||
      path === '/available-esims' ||
      path === '/paused-sims' ||
      path === '/deactivated-sims' ||
      path === '/activation-in-progress'
    ) {
      selectedNav = 'yoursims';
    } else if (path === '/clients') {
      selectedNav = 'clients';
    } else if (path === '/orders') {
      selectedNav = 'ordersims';
    } else if (path === '/view-coverage-map') {
      selectedNav = 'map';
    } else if (path === '/terms-and-conditions') {
      selectedNav = 'tnc';
    } else if (
      path === '/users/add-user' ||
      path === '/all-users' ||
      path === '/ultra-admins' ||
      path === '/partners'
    ) {
      selectedNav = 'users';
    }
    this.setState({ selectedNav });
  };

  setSimTab = () => {
    const path = window.location.pathname;
    let simTab = '';
    if (path === '/active-sims') {
      simTab = 'active-sims';
    } else if (path === '/available-sims') {
      simTab = 'available-sims';
    } else if (path === '/available-esims') {
      simTab = 'available-esims';
    } else if (path === '/paused-sims') {
      simTab = 'paused-sims';
    } else if (path === '/deactivated-sims') {
      simTab = 'deactivated-sims';
    } else if (path === '/activation-in-progress') {
      simTab = 'activation-in-progress';
    }
    this.setState({ simTab: simTab });
  };

  setTabForSims = (simTab) => {
    if (simTab === 'active-sims') {
      this.props.onTabChange('active');
    } else if (simTab === 'available-sims') {
      this.props.onTabChange('inactive');
    } else if (simTab === 'available-esims') {
      this.props.onTabChange('availableeSim');
    } else if (simTab === 'paused-sims') {
      this.props.onTabChange('paused');
    } else if (simTab === 'deactivated-sims') {
      this.props.onTabChange('deactive');
    } else if (simTab === 'activation-in-progress') {
      this.props.onTabChange('activationInProgress');
    }
    this.setState({ simTab });
  };

  onSearch = async (event) => {
    const self = this;
    this.setState({ searchVal: event.target.value });
    const { user } = this.props;
    const role = user ? user.role : '';
    let simType = '';
    if (_.get(user, 'lobId', 0) === 1) {
      simType = 'spr';
    }
    if (_.get(user, 'lobId', 0) === 3) {
      simType = 'fixed';
    }
    if (event.target.value !== '') {
      const filter = {};
      filter.role = role;
      filter.simType = simType;
      filter.query = event.target.value;
      const {
        value: { data },
      } = await this.props.searchResults(filter);
      if (data && data.length > 0) {
        self.setState({ showSearchDropdown: true, searchData: data });
      }
    } else {
      self.setState({ showSearchDropdown: false, searchData: [] });
    }
  };

  onSearchMobile = async (event) => {
    const self = this;
    this.setState({ searchVal: event.target.value });
    const { user } = this.props;
    const role = user ? user.role : '';
    if (event.target.value !== '') {
      let simType = '';
      if (_.get(user, 'lobId', 0) === 1) {
        simType = 'spr';
      }
      if (_.get(user, 'lobId', 0) === 3) {
        simType = 'fixed';
      }
      const filter = {};
      filter.role = role;
      filter.simType = simType;
      filter.query = event.target.value;
      const {
        value: { data },
      } = await this.props.searchResults(filter);
      if (data && data.length > 0) {
        self.setState({ showSearchMobileDropdown: true, searchData: data });
      }
    } else {
      self.setState({ showSearchMobileDropdown: false, searchData: [] });
    }
  };

  handleSelectSearchField(key, searchKey, val) {
    console.log('onclick search item');
  }

  simDetail = (iccid, simtype) => {
    if (simtype && simtype === 'fixed') {
      localStorage.setItem('prevTab', 'fixed');
    } else {
      localStorage.setItem('prevTab', 'spr');
    }
    this.props.history.push(`/detail/${iccid}`);
    this.setState({ searchVal: '', showSearchDropdown: false });
    if (_.get(this.props, 'updateSimDetail', false)) {
      this.props.updateSimDetail();
    }
  };

  impersonateUser = async (userId) => {
    const { userData } = this.state;
    let currentAdminUserId = userData.id;

    if (userId !== currentAdminUserId) {
      try {
        let id = userId;
        const {
          value: { success, token },
        } = await this.props.userImpersonate(id);
        if (success) {
          const auth = localStorage.getItem('Authorization');
          localStorage.setItem('i_Authorization', auth);
          localStorage.setItem('Authorization', token);
          const {
            value: { success, user },
          } = await this.props.fetchMe();
          if (success && user) {
            this.props.setUser(user);
            this.setState({
              iAuthorization: auth,
              searchVal: '',
              showSearchDropdown: false,
            });
            if (user && user.role === 'superadmin') {
              this.props.history.push('/spr-dashboard');
            } else {
              if (
                user &&
                (isSPRPartner(user) || isSubUser(user)) &&
                _.get(user, 'lobId', 1) === 3
              ) {
                this.props.history.push('/fixed-dashboard');
              } else {
                this.props.history.push('/dashboard');
              }
            }
            if (_.get(this.props, 'updateDashboard', false)) {
              await this.props.fetchMe();
              this.props.updateDashboard();
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  renderResult = (searchData, role) =>
    searchData &&
    searchData.map((d, i) => (
      <ul key={i} className="search-wrapper-options-menu">
        <li className="search-wrapper-options-menu-item d-flex align-items-center">
          <Link to={`#`}>
            <div className="search-bar-info">
              <div className="sim-icon">
                <SimsColorIcon />
              </div>
              <div className="user-info">
                <div className="number">{d.simnumber}</div>
                <div className="phone">
                  <NumberFormat
                    format="(###) ###-####"
                    allowEmptyFormatting={false}
                    mask=""
                    displayType={'text'}
                    value={d.msisdn}
                  />
                </div>
                <div className="tag">
                  {d.firstname && d.lastname
                    ? `${d.firstname} ${d.lastname}`
                    : '-'}
                </div>
                <div className="name">
                  {d.companyname ? d.companyname : '-'}
                </div>
              </div>
            </div>
          </Link>
          <ul className="search-wrapper-options-menu-item-extra-feature">
            <li
              onClick={() =>
                this.simDetail(
                  _.get(d, 'simnumber', ''),
                  _.get(d, 'simtype', '')
                )
              }
            >
              {d && d.simStatus !== 0 && (
                <Link className="btn-link" to={`/`}>
                  SIM detail
                </Link>
              )}
            </li>
            <li>
              {role === 'superadmin' ||
              role === 'admin' ||
              role === 'sysops' ||
              (this.state.iAuthorization &&
                this.state.iAuthorization !== '') ? (
                <>
                  {this.state.iAuthorization &&
                  this.state.iAuthorization !== '' ? (
                    ''
                  ) : (
                    <Link
                      className="btn-link"
                      onClick={() => this.impersonateUser(d.userId)}
                    >
                      Dashboard
                    </Link>
                  )}
                </>
              ) : (
                ''
              )}
            </li>
          </ul>
        </li>
      </ul>
    ));

  logoutImpersonate = () => {
    const token = localStorage.getItem('i_Authorization');
    localStorage.setItem('Authorization', token);
    localStorage.removeItem('i_Authorization');
    localStorage.removeItem('@admin');
    this.props.history.push('/all-users');
  };

  searchClick() {
    this.setState({
      searchIconClick: !this.state.searchIconClick,
      searchVal: '',
    });
    if (this.state.searchIconClick) {
      this.setState({
        showSearchDropdown: false,
        showSearchMobileDropdown: false,
      });
    }
  }

  notificationModalOpen = (isNotificationModal) => {
    this.setState({ isNotificationModal });
    this.onBellIconClick();
  };

  closeModal = () => {
    this.setState({ isNotificationModal: false });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  hideDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  notificationHandle = async (key) => {
    const { user } = this.props;
    if (key === 'clear_all') {
      if (user) {
        let data = {
          type: 'multiple',
          action: 'clear',
        };
        const {
          value: { success },
        } = await this.props.setNotificationStatus(data);
        if (success) {
          socket.emit('get:notifications', {
            userid: _.get(user, 'id', 0),
            page: this.state.currentPage,
            limit: this.state.limit,
            isread: this.state.isread,
          });
        }
      }
    } else if (key === 'mark_all_read') {
      if (user) {
        let data = {
          type: 'multiple',
          action: 'read',
        };
        const {
          value: { success },
        } = await this.props.setNotificationStatus(data);
        if (success) {
          socket.emit('get:notifications', {
            userid: _.get(user, 'id', 0),
            page: this.state.currentPage,
            limit: this.state.limit,
            isread: this.state.isread,
          });
        }
      }
    }
  };

  isReadNotification = async (item, index) => {
    const { user } = this.props;
    let status = item.isread === 0 ? 'read' : 'unread';
    if (user) {
      let data = {
        id: item.id,
        type: 'single',
        action: status,
      };
      const {
        value: { success },
      } = await this.props.setNotificationStatus(data);
      if (success) {
        socket.emit('get:notifications', {
          userid: _.get(user, 'id', 0),
          page: this.state.currentPage,
          limit: this.state.limit,
          isread: this.state.isread,
        });
      }
    }
  };

  memberMenu = () => (
    <Menu>
      <Menu.Item key="0">
        <Link
          to={{
            pathname: '/profile',
            state: { lastPath: window.location.pathname },
          }}
        >
          Settings
        </Link>
      </Menu.Item>
      {this.props.user &&
        this.props.user.role === 'spr_partner' &&
        parseInt(_.get(this.props, 'user.showApiKey', 0)) === 1 && (
          <Menu.Item key="1">
            <Link to={'/api-keys'}>API Keys</Link>
          </Menu.Item>
        )}
      <Menu.Item onClick={this.logout.bind(this)} key="2">
        Logout
      </Menu.Item>
    </Menu>
  );

  adminMenu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link
            to={{
              pathname: '/profile',
              state: { lastPath: window.location.pathname },
            }}
          >
            Settings
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link
            to={{
              pathname: '/banner-setting',
              state: { lastPath: window.location.pathname },
            }}
          >
            App Settings
          </Link>
        </Menu.Item>
        <Menu.Item onClick={this.logout.bind(this)} key="3">
          Logout
        </Menu.Item>
      </Menu>
    );
  };

  // function to add comma's to a numerical value
  formatNumWithComma(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  render() {
    const {
      searchData,
      searchVal,
      iAuthorization,
      isNotificationModal,
      selectedNav,
      unreadNotificationsCount,
    } = this.state;
    const { user, notifications, notificationCount } = this.props;
    const remianingPasswordExpiryDays = _.get(
      user,
      'remianingPasswordExpiryDays',
      0
    );
    return (
      <>
        {iAuthorization && (
          <div className="impersonate">
            <div className="impersonate-wrapper">
              {`You are impersonating ( ${_.get(user, 'firstname', '')} ${_.get(
                user,
                'lastname',
                ''
              )} )`}{' '}
              <span
                className="impersonate-link"
                onClick={() => this.logoutImpersonate()}
              >
                Click here to go back to your account
              </span>
            </div>
          </div>
        )}
        {remianingPasswordExpiryDays > 0 &&
          (_.get(user, 'scope', '') === '' ||
            _.get(user, 'role', '') === 'superadmin') && (
            <div className="expire-password">
              <div className="expiry-wrapper">
                {`Your account password is about to expire ${
                  remianingPasswordExpiryDays > 1
                    ? `in ${remianingPasswordExpiryDays} days`
                    : 'today'
                }. Please reset it`}{' '}
                <span
                  className="expiry-link"
                  onClick={() => this.props.history.push('/update-password')}
                >
                  here
                </span>
              </div>
            </div>
          )}
        <header className="header topnavigation-layout-header d-flex align-items-center">
          <div className="topnavigation-layout-search">
            <div className="topnavigation-layout-wrapper">
              <DebounceInput
                minLength={2}
                debounceTimeout={500}
                className="form-control"
                placeholder="Search by SIM name, iccid, user id, tags, or phone number..."
                value={searchVal}
                onChange={this.onSearch}
              />
              {this.state.showSearchDropdown && this.state.searchVal !== '' ? (
                <div
                  className={
                    searchData && searchData.length
                      ? 'search-wrapper-open-container open'
                      : 'search-wrapper-open-container'
                  }
                >
                  {this.renderResult(searchData, user.role)}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="topnavigation-layout-notification">
            <Popover
              trigger="click"
              visible={isNotificationModal}
              onVisibleChange={this.notificationModalOpen}
              className="notificaton__modal"
              overlayClassName="notificaton__modal__overlay"
              content={
                <div className="notificaton__container">
                  <div className="notificaton__container__header">
                    <div className="notificaton__container__content">
                      Notifications
                    </div>
                    <button
                      onClick={this.closeModal}
                      className="btn btn__close__notify"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="notificaton__container__body w-100">
                    {this.state.notificationLoader ? (
                        <div style={{ textAlign: 'center', marginTop: '20px', width:'100%', minWidth:'470px', display:'block', overflow:'hidden' }}>
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} />
                        </div>
                    ) : (_.size(notifications) > 0 ? (
                      <>
                        {iAuthorization ? (
                          <div className="notificaton__container__cta">
                            <div className="">
                              <button
                                disabled={true}
                                className="btn btn__clear_all"
                              >
                                Clear All
                              </button>
                            </div>
                            <div className="ml-auto">
                              <button
                                disabled={true}
                                className="btn btn__clear_all"
                              >
                                Mark All As Read
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="notificaton__container__cta">
                            <div className="">
                              <button
                                onClick={this.notificationHandle.bind(
                                  this,
                                  'clear_all'
                                )}
                                className="btn btn__clear_all"
                              >
                                Clear All
                              </button>
                            </div>
                            <div className="ml-auto">
                              <button
                                disabled={notificationCount === 0}
                                onClick={this.notificationHandle.bind(
                                  this,
                                  'mark_all_read'
                                )}
                                className="btn btn__clear_all"
                              >
                                Mark All As Read
                              </button>
                            </div>
                          </div>
                        )}
                        <ul className="notificaton__list">
                          {notifications.map((item, index) => {
                            /* eslint-disable no-useless-escape */
                            const URL_REGEX =
                              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
                            const renderText = (txt) =>
                              txt.split(' ').map((part, i) =>
                                URL_REGEX.test(part) ? (
                                  <a key={i} href={part}>
                                    {part}{' '}
                                  </a>
                                ) : (
                                  part + ' '
                                )
                              );
                            return (
                              <li
                                onClick={() =>
                                  !iAuthorization &&
                                  this.isReadNotification(item, index)
                                }
                                key={item.id}
                                className={item.isread === 0 ? 'active' : ''}
                              >
                                <div className="notificaton__item">
                                  <div className="notificaton__card">
                                    {renderText(item.text)}
                                  </div>
                                  <div className="notificaton__delivery">
                                    {moment(item.datecreated).format(
                                      dateFormat
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <div className="notificaton__blank__text">
                        You don't have any unread message.
                      </div>
                    ))}
                    {!this.state.notificationLoader && (notifications.length < notificationCount && (
                      <div
                        className="notification-load-more"
                        onClick={this.onClickLoadMore}
                      >
                        Load More
                      </div>
                    ))}
                    {!this.state.notificationLoader  && (this.state.isread === 0 ? (
                      <div
                        className="notification-view-unread"
                        onClick={this.onClickViewReadNotifications}
                      >
                        View Read Messages
                      </div>
                    ) : (
                      <div
                        className="notification-view-unread"
                        onClick={this.onBellIconClick}
                      >
                        View UnRead Messages
                      </div>
                    ))}
                  </div>
                </div>
              }
            >
              <div className="chat-notificaton">
                {unreadNotificationsCount > 0 && (
                  <span className="chat-notificaton-count">
                    {this.formatNumWithComma(notificationCount)}
                  </span>
                )}
                <NotificationIcon />
              </div>
            </Popover>
          </div>
          <div className="topnavigation-layout-divider"></div>
          <div className="topnavigation-layout__profile">
            <Dropdown
              overlay={
                _.get(user, 'role', false) === 'admin' ||
                _.get(user, 'role', false) === 'superadmin'
                  ? this.adminMenu()
                  : this.memberMenu()
              }
              trigger={['click']}
              overlayClassName="profile--dropdown--overlay"
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link d-flex align-items-center"
                href="/"
              >
                <div className="avatar-info">
                  <div className="avatar-name">
                    {user && user.firstname} {user && user.lastname}
                  </div>
                  <div className="avatar-email">{user && user.email}</div>
                </div>
                <DownCaretIcon />
              </a>
            </Dropdown>
          </div>
        </header>

        <div className="topnavigation__mobile">
          <div className="topnavigation__mobile__left">
            <div className="topnavigation__mobile__logo">
              <Link to={'/dashboard'}>
                <img src={Logo} alt="" width="88" />
              </Link>
            </div>
          </div>
          <div className="topnavigation__mobile__right">
            <div className="topnavigation__mobile__inner">
              <button
                className="btn btn__search"
                onClick={this.searchClick.bind(this)}
              >
                <SearchIcon />
              </button>
              <button className="btn btn__navbar" onClick={this.showDrawer}>
                <NavbarIcon />
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.searchIconClick
              ? 'modal-search-box open'
              : 'modal-search-box'
          }
          id="search-box"
        >
          <div className="d-flex align-items-center">
            <DebounceInput
              minLength={2}
              debounceTimeout={500}
              className="form-control modal-search-box-input"
              placeholder="Search by SIM name, ICCID, tag, or phone number..."
              value={searchVal}
              onChange={this.onSearchMobile}
            />
            {this.state.showSearchMobileDropdown &&
            this.state.searchVal !== '' ? (
              <div
                className={
                  searchData && searchData.length
                    ? 'search-wrapper-open-container open'
                    : 'search-wrapper-open-container'
                }
              >
                {this.renderResult(searchData, user.role)}
              </div>
            ) : (
              ''
            )}
            <button
              onClick={this.searchClick.bind(this)}
              className="btn modal-search-box-button"
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <Drawer
          className="mobile-drawer"
          width="320px"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div className="mobile-drawer-container">
            <div className="mobile-drawer-header">
              <button className="btn" onClick={this.hideDrawer}>
                <CloseIcon className="close-icon" />
              </button>
            </div>
            <ul className="mobile-nav-list">
              <li
                className={
                  selectedNav === 'dashboard'
                    ? 'mobile-nav-list-item active'
                    : 'mobile-nav-list-item'
                }
              >
                <Link className="btn btn-link" to={'/dashboard'}>
                  Dashboard
                </Link>
              </li>
              <li
                className={
                  selectedNav === 'yoursims'
                    ? 'mobile-nav-list-item active'
                    : 'mobile-nav-list-item'
                }
              >
                <Link className="btn btn-link" to={'/active-sims'}>
                  {user.role === 'spr_partner' ? 'Your SPR SIMs' : 'Your SIMs'}{' '}
                </Link>
                <ul className="mobile-nav-list-submenu">
                  {checkPermission(this.props.user, '/active-sims') && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'active-sims'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn'
                        }
                        onClick={(e) => this.setTabForSims('active-sims')}
                      >
                        Active (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'active', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                  {checkPermission(
                    this.props.user,
                    '/activation-in-progress'
                  ) && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'activation-in-progress'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn'
                        }
                        onClick={(e) =>
                          this.setTabForSims('activation-in-progress')
                        }
                      >
                        In Progress (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'activationInProgress', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                  {checkPermission(this.props.user, '/available-sims') && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'available-sims'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn'
                        }
                        onClick={(e) => this.setTabForSims('available-sims')}
                      >
                        Available SIMs (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'inactive', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                  {checkPermission(this.props.user, '/available-esims') && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'available-esims'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn'
                        }
                        onClick={(e) => this.setTabForSims('available-esims')}
                      >
                        Available eSIMs (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'availableeSim', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                  {checkPermission(this.props.user, '/paused-sims') && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'paused-sims'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn btn'
                        }
                        onClick={(e) => this.setTabForSims('paused-sims')}
                      >
                        Paused (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'paused', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                  {checkPermission(this.props.user, '/deactivated-sims') && (
                    <li>
                      <button
                        className={
                          this.state.simTab === 'deactivated-sims'
                            ? 'mobile-nav-list-item-link btn active'
                            : 'mobile-nav-list-item-link btn'
                        }
                        onClick={(e) => this.setTabForSims('deactivated-sims')}
                      >
                        {' '}
                        Deactivated (
                        {this.formatNumWithComma(
                          _.get(this.props.simCounts, 'deactive', 0)
                        )}
                        )
                      </button>
                    </li>
                  )}
                </ul>
              </li>
              {user &&
                (isSPRPartner(user) ||
                  (isSubUser(user) &&
                    checkPermission(this.props.user, '/clients'))) && (
                  <li
                    className={
                      selectedNav === 'clients'
                        ? 'mobile-nav-list-item active'
                        : 'mobile-nav-list-item'
                    }
                  >
                    <Link className="btn btn-link" to={'/clients'}>
                      Your Clients
                    </Link>
                  </li>
                )}
              {user &&
                (isSPRPartner(user) ||
                  checkPermission(this.props.user, '/orders')) && (
                  <li
                    className={
                      selectedNav === 'ordersims'
                        ? 'mobile-nav-list-item active'
                        : 'mobile-nav-list-item'
                    }
                  >
                    <Link className="btn btn-link" to={'/orders'}>
                      {' '}
                      Your Orders
                    </Link>
                  </li>
                )}
              {user &&
                (isSPRPartner(user) ||
                  checkPermission(this.props.user, '/support')) && (
                  <li
                    className={
                      selectedNav === 'support'
                        ? 'mobile-nav-list-item active'
                        : 'mobile-nav-list-item'
                    }
                  >
                    <a
                      className="btn btn-link"
                      href="https://kaena1.atlassian.net/servicedesk/customer/portal/38"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Support
                    </a>
                  </li>
                )}
              {user &&
                (_.get(user, 'role', false) === 'admin' ||
                  _.get(user, 'role', false) === 'superadmin') && (
                  <li
                    className={
                      selectedNav === 'users'
                        ? 'mobile-nav-list-item active'
                        : 'mobile-nav-list-item'
                    }
                  >
                    <Link className="btn btn-link" to={'/all-users'}>
                      {' '}
                      Users
                    </Link>
                  </li>
                )}
              <li className="mobile-nav-list-item">
                <Link className="btn btn-link" to={'/settings'}>
                  Settings
                </Link>
              </li>
              {user &&
                (_.get(user, 'role', false) === 'admin' ||
                  _.get(user, 'role', false) === 'superadmin') && (
                  <li className="mobile-nav-list-item">
                    <Link className="btn btn-link" to={'/banner-setting'}>
                      App Settings
                    </Link>
                  </li>
                )}
              {user && isSPRPartner(user) && (
                <li className="mobile-nav-list-item">
                  <Link className="btn btn-link" to={'/terms-and-conditions'}>
                    Terms & Conditions
                  </Link>
                </li>
              )}
              <li className="mobile-nav-list-item">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={this.logout.bind(this)}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </Drawer>
      </>
    );
  }
}
