import { connect } from 'react-redux';

import CdrDataFilesDetailsComponent from './component';

import { downloadCdrFile } from '../../../store/user/duck';

const CdrDataFilesDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    downloadCdrFile
  }
)(CdrDataFilesDetailsComponent);

export default CdrDataFilesDetailsContainer;
