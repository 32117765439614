import { connect } from 'react-redux';
import { forgotPassword } from '../../store/user/duck';
import ForgotPasswordComponent from './component';

const ForgotPasswordContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    forgotPassword,
  }
)(ForgotPasswordComponent);

export default ForgotPasswordContainer;
