/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import MyProfile from './MyProfile/container';
import UpdatePassword from './UpdatePassword/container';
import Users from './Users/container';
import AccountDocuments from './AccountDocuments/container';
import CdrDataFiles from './CdrDataFiles/container';
import CdrDataFilesDetails from './CdrDataFilesDetails/container';

import { CloseIcon } from '../../components/icons';
import { checkPermission } from '../../constants/common';
import _ from 'lodash';
import './styles.scss';
let lastPath;
export default class SettingsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      permissions: [],
    };
  }

  componentDidMount = async () => {
    try {
      const {
        value: { user },
      } = await this.props.fetchMe();
      if (user) {
        this.props.setUser(user);
        this.setState({ user }, () => {
          this.getUserPermission();
        });
      }
    } catch (err) {
      const error = err.data;
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }

    // set for go to last route
    lastPath = _.get(this.props, 'location.state.lastPath', '/dashboard');
  };

  getUserPermission = async () => {
    if (this.state.user) {
      try {
        const permissionDataSet = {
          id: this.state.user.id,
        };
        const {
          value: { success, data },
        } = await this.props.fetchUserPermissions(permissionDataSet);
        if (success && data.length > 0) {
          const permissionData = JSON.parse(data[0].permissionData);
          this.props.setUserPermissions(permissionData);
          this.setState({ permissions: permissionData });
        }
      } catch (error) {
        // console.log(error)
      }
    }
  };

  userPermissionCheck = (permissions, checkurl) => {
    let isAllowed = -1;
    if (permissions && permissions.length > 0) {
      isAllowed = _.findIndex(permissions, function (per) {
        return per.checked && per.route === checkurl;
      });
    }
    return isAllowed === -1 ? false : true;
  };

  render() {
    const path = window.location.pathname;
    const { user, userPermission } = this.props;
    return (
      <>
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">Settings</div>
          <div className="head-section-cta">
            <button
              className="btn btn-link btn-back"
              onClick={() => this.props.history.push(lastPath)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="editor__tabs">
          <ul className="editor__tabs--link">
            <li
              className={
                path === '/settings' || path === '/profile' ? 'active' : ''
              }
            >
              <Link className="navlink" to={'/profile'}>
                My Profile
              </Link>
            </li>
            <li className={path === '/update-password' ? 'active' : ''}>
              <Link className="navlink" to={'/update-password'}>
                Update Password
              </Link>
            </li>
            {user &&
              user.role !== 'user' &&
              checkPermission(user, '/users') && (
                <li className={path === '/users' ? 'active' : ''}>
                  <Link className="navlink" to={'/users'}>
                    Users
                  </Link>
                </li>
              )}
            {/* {user && checkPermission(user, "/spr-documents") && parseInt(user.lobId) !== 3 && ( */}
            {user &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 1) && (
                <li className={path === '/spr-documents' ? 'active' : ''}>
                  <Link className="navlink" to={'/spr-documents'}>
                    SPR Account Documents
                  </Link>
                </li>
              )}
            {user &&
              user.role === 'spr_partner' &&
              this.userPermissionCheck(userPermission, 'cdr-data-files') &&
              parseInt(user.lobId) !== 3 && (
                <li className={path === '/spr-cdr-data-files' ? 'active' : ''}>
                  <Link className="navlink" to={'/spr-cdr-data-files'}>
                    SPR CDR Data
                  </Link>
                </li>
              )}
            {user &&
              user.role === 'sub_user' &&
              checkPermission(user, '/spr-cdr-data-files') &&
              this.userPermissionCheck(userPermission, 'cdr-data-files') &&
              parseInt(user.lobId) !== 3 && (
                <li className={path === '/spr-cdr-data-files' ? 'active' : ''}>
                  <Link className="navlink" to={'/spr-cdr-data-files'}>
                    SPR CDR Data
                  </Link>
                </li>
              )}
            {user &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) && (
                <li className={path === '/fixed-documents' ? 'active' : ''}>
                  <Link className="navlink" to={'/fixed-documents'}>
                    Fixed Wireless Account Documents
                  </Link>
                </li>
              )}
            {user &&
              user.role === 'spr_partner' &&
              this.userPermissionCheck(userPermission, 'cdr-data-files') &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) && (
                <li
                  className={path === '/fixed-cdr-data-files' ? 'active' : ''}
                >
                  <Link className="navlink" to={'/fixed-cdr-data-files'}>
                    Fixed CDR Data
                  </Link>
                </li>
              )}
            {user &&
              user.role === 'sub_user' &&
              checkPermission(user, '/spr-cdr-data-files') &&
              this.userPermissionCheck(userPermission, 'cdr-data-files') &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) && (
                <li
                  className={path === '/fixed-cdr-data-files' ? 'active' : ''}
                >
                  <Link className="navlink" to={'/fixed-cdr-data-files'}>
                    Fixed CDR Data
                  </Link>
                </li>
              )}
          </ul>
        </div>
        {(path === '/settings' || path === '/profile') && (
          <MyProfile {...this.props} />
        )}
        {path === '/update-password' && <UpdatePassword {...this.props} />}
        {path === '/users' && <Users {...this.props} />}
        {path === '/spr-documents' && (
          <AccountDocuments type="spr" {...this.props} />
        )}
        {path === '/spr-cdr-data-files' && <CdrDataFiles {...this.props} />}
        {path.indexOf('/cdr-data-files/') !== -1 && (
          <CdrDataFilesDetails {...this.props} />
        )}
        {path.indexOf('/fixed-documents') !== -1 && (
          <AccountDocuments type="fixed" {...this.props} />
        )}
        {path.indexOf('/fixed-cdr-data-files') !== -1 && (
          <CdrDataFiles {...this.props} />
        )}
      </>
    );
  }
}
