import React, { useEffect, useState } from 'react';

const InternationalRoaming = ({
  searchText,
  fetchInternationalRates,
  ...props
}) => {
  const [rates, setRates] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        value: { data },
      } = await fetchInternationalRates({ searchText });
      setRates(data);
    })();
  }, [fetchInternationalRates, searchText]);

  return (
    <div className="table-responsive custom-table-shadow pb-3">
      <table className="table custom-table">
        <thead>
          <tr>
            <th>
              <span className="table-column-title cursor-poniter">
                Country Code
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Destination
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Voice Rate
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                SMS Rate
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Data Rate
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {rates.map((rate) => {
            return (
              <tr key={rate.id}>
                <td>{rate.countryCode}</td>
                <td>{rate.destination}</td>
                <td>
                  {rate.voiceRate === '' || rate.voiceRate === 'Not Available'
                    ? 'Not Available'
                    : `$${rate.voiceRate}/per min`}
                </td>
                <td>
                  {rate.smsRate === '' || rate.smsRate === 'Not Available'
                    ? 'Not Available'
                    : `$${rate.smsRate}/per sms`}
                </td>
                <td>
                  {rate.dataRate === '' || rate.dataRate === 'Not Available'
                    ? 'Not Available'
                    : `$${rate.dataRate}/per MB`}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default InternationalRoaming;
