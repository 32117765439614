import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../../store/user/duck';
import { fetchSimsByMaxUsage } from '../../../store/dashboard/duck';
import DataUsageComponent from './component';

const DataUsageContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchSimsByMaxUsage,
  }
)(DataUsageComponent);

export default DataUsageContainer;
