/**
 * The keys to be used for the sim queries and mutations, using an enum for better type safety and to avoid typos.
 *
 * @example
 * ```
 * useQuery({
 *   queryKey: [SimQueryKey.SIM_CARD_DETAIL, iccid],
 *   queryFn: async () => {
 *     // ...
 *   },
 * });
 * ```
 */
export const enum SimQueryKey {
  ACCOUNT_NUMBER = 'accountNumber',
  SIM_CARD_DETAIL = 'simCardDetail',
  ACTIVE_PORTIN_REQUEST_DATA = 'activePortInRequestData',
}
