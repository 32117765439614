import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import TermsConditionsComponent from './component';

const TermsConditionsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
  }
)(TermsConditionsComponent);

export default TermsConditionsContainer;
