import { connect } from 'react-redux';

import SimListComponent from './component';
import { downloadEsimPDF, generatePDF } from '../../../store/sim/duck';

const SimListContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  { generatePDF, downloadEsimPDF }
)(SimListComponent);

export default SimListContainer;
