import React, { useState } from 'react';
import moment from 'moment';
import {
  useActivePortInRequestData,
  useSimCardDetail,
} from 'lib/hooks/sim/sim.queries';
import ChangePhoneNumberModal from '../ChangePhoneNumberModal/ChangePhoneNumberModal';
import NumberFormat from 'react-number-format';

function getFormattedLasUpdateOn(lasUpdateOn: string) {
  return moment.utc(lasUpdateOn).local().format('MMMM DD, YYYY [at] h:mm A');
}

type MSISDNInfoProps = {
  iccid: string;
  MSISDNChangeAllowedToUser?: boolean;
};

function MSISDNInfo({
  iccid,
  MSISDNChangeAllowedToUser = false,
}: MSISDNInfoProps) {
  const [changePhoneNumberModalOpen, setChangePhoneNumberModalOpen] =
    useState(false);

  const {
    data: simDetail,
    isLoading: isSimDetailLoading,
    isError: isSimDetailError,
    isSuccess: isSimDetailSuccess,
  } = useSimCardDetail({ iccid });
  const { data: activePortInRequestData } = useActivePortInRequestData({
    iccid,
  });

  const showChangeButton =
    MSISDNChangeAllowedToUser &&
    simDetail?.status === 10 &&
    simDetail?.simtype === 'spr';

  const msisdnValue =
    (isSimDetailSuccess && simDetail.msisdn) || activePortInRequestData?.msisdn;

  const lasUpdateOnText =
    simDetail?.msisdnUpdateDate &&
    ` Last Updated on - ${getFormattedLasUpdateOn(
      simDetail?.msisdnUpdateDate
    )}`;

  return (
    <>
      <div className="info-block">
        <label className="label-text">MSISDN</label>
        {showChangeButton && (
          <span
            onClick={() => setChangePhoneNumberModalOpen(true)}
            className="btn-view-apn ml-3"
          >
            Change Phone Number
          </span>
        )}

        <p className="text-desc">
          {isSimDetailLoading && 'Loading...'}
          {isSimDetailError && 'N/A'}
          {msisdnValue && (
            <NumberFormat
              value={msisdnValue}
              format="(###) ###-####"
              displayType="text"
              allowEmptyFormatting
              mask="_"
            />
          )}

          <span className="text-desc text-muted font-14">
            {lasUpdateOnText}
          </span>
        </p>
      </div>

      <ChangePhoneNumberModal
        isOpen={changePhoneNumberModalOpen}
        iccid={iccid}
        onClose={() => setChangePhoneNumberModalOpen(false)}
      />
    </>
  );
}

export default MSISDNInfo;
