import moment from 'moment';

/**
* Takes the raw constraintMiddleware error message from iot-api and formats the UTC timestamp into a local time
*
* @param errorMessage - The raw error message from iot-api in following format
* // You can not change the msisdn for this SIM till September 11th, 2024 at 1:14PM UTC
*
* @returns The formatted error message
*
* @example
* ```
* const localErrorMessage = formatTimeConstraintErrorMessage('You can not change the msisdn for this SIM till September 11th, 2024 at 1:14PM UTC');
* console.log(localErrorMessage);
* // You can not change the msisdn for this SIM till September 11th, 2024 at 1:14PM
* ```
*/
export function formatTimeConstraintErrorMessage(errorMessage: string) {
  const [message, utcTimestamp] = errorMessage.split('till ');

  const [utcDate, utcTime] = utcTimestamp
    .replace('UTC', '')
    .trim()
    .split(' at ');

  const utcMoment = moment.utc(`${utcDate} ${utcTime}`, 'MMMM D, YYYY h:mm A');

  return `${message} till ${utcMoment
    .local()
    .format('MMMM DD, YYYY [at] h:mm A')}`;
}
