import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import ManageBulkComponent from './component';
import {
  fetchSimsWithAddOns,
  bulkManageAddOns,
  updateTag,
  fetchClients,
  addClient,
} from '../../store/sim/duck';

const ManageBulkContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchSimsWithAddOns,
    bulkManageAddOns,
    updateTag,
    fetchClients,
    addClient,
    fetchMe,
    setUser,
  }
)(ManageBulkComponent);

export default ManageBulkContainer;
