import { connect } from 'react-redux';
import { fetchMe, setUser, userImpersonate } from '../../store/user/duck';
import { fetchDashboardStats } from '../../store/dashboard/duck';
import SprDashboardComponent from './component';

const SprDashboardContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchDashboardStats,
    userImpersonate,
  }
)(SprDashboardComponent);

export default SprDashboardContainer;
