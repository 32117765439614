import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import { addSims, fetchSimCounts } from '../../store/sim/duck';
import AddSimComponent from './component';

const AddSimContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    addSims,
    fetchSimCounts,
  }
)(AddSimComponent);

export default AddSimContainer;
