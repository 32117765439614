import { connect } from 'react-redux';

import AccountDocumentsComponent from './component';

const AccountDocumentsContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(AccountDocumentsComponent);

export default AccountDocumentsContainer;
