import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import { getOrderById } from '../../store/sim/duck';
import OrderDetailsComponent from './component';

const OrderDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getOrderById,
  }
)(OrderDetailsComponent);

export default OrderDetailsContainer;
