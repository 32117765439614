/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { notification, Spin } from 'antd';
import InputMask from 'react-text-mask';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import {
  addUserSchema,
  addUserSPRSchema,
  addUserSPRSchemaforFixed,
  addUserSPRSchemaforSpr,
} from '../../utils/validations';
import { isAdmin } from '../../constants/common';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import { LoadingOutlined } from '@ant-design/icons';

import _ from 'lodash';
import './styles.scss';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />
);

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class AddUserComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectPlanDrawer: false,
      selectFixedPlanDrawer: false,
      // plans: [],
      // filteredPlans: [],
      // filteredFixedPlans: [],
      // selectedPlans: [],
      // selectedFixedPlans: [],
      // savedPlans: [],
      // savedFixedPlans: [],
      lineOfBList: [],
      user: null,
      initialValues: {
        website_url: '',
        business_description: '',
        numberOfSims: 0,
        monthlyUsage: 0,
        usecase: '',
        companyname: '',
        total_lines: '',
        firstname: '',
        lastname: '',
        email: '',
        maid: '',
        password: '',
        phone: '',
        status: 10,
        // plans: [],
        role: 'spr_partner',
        isAccountSetup: 1,
        apiKey: '',
        brandName: '',
        partnerCode: '',
        lobId: 1,
        sprPartnerId: '',
        sprPartnerSecret: '',
        fixedPartnerId: '',
        fixedPartnerSecret: '',
        countryCode: '1',
      },
      isEmailExists: false,
      isCompnayNameExists: false,
      isPhoneExists: false,
      // isPlanError: false,
      // isFixedPlanError: false,
      // isDisableSavePlan: true,
      // isFixedDisableSavePlan: true,
      addUserType: 'spr_partner',
      lobIdForValidation: 1,
      // isPlanLoading: true,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    document.title = 'Add User | UltraIoT';
    const {
      value: { lineOfBList },
    } = await this.props.fetchLoB();
    this.setState({ lineOfBList });
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      this.setState({ user });
      if (!isAdmin(user)) {
        this.props.history.push('/dashboard');
      }
      // const {
      //   value: { plans }
      // } = await this.props.fetchSPRPlans()
      // const {
      //   value: { fixedPlans }
      // } = await this.props.fetchFixedPlans()
      // this.setState({ plans, filteredPlans: plans, isPlanLoading: false, fixedPlans, filteredFixedPlans: fixedPlans })
    }
  };

  // selectPlanDrawer = () => {
  //   const { savedPlans } = this.state
  //   this.setState({
  //     selectPlanDrawer: true,
  //     selectedPlans: _.size(savedPlans) === 0 ? [] : savedPlans
  //   })
  // }
  // selectFixedPlanDrawer = () => {
  //   const { savedFixedPlans } = this.state
  //   this.setState({
  //     selectFixedPlanDrawer: true,
  //     selectedFixedPlans: _.size(savedFixedPlans) === 0 ? [] : savedFixedPlans
  //   })
  // }

  // onClose = () => {
  //   this.setState({
  //     selectPlanDrawer: false,
  //     selectFixedPlanDrawer: false,
  //     isDisableSavePlan: _.size(this.state.savedPlans) === 0 ? true : false,
  //     isPlanError: _.size(this.state.savedPlans) === 0 ? true : false,
  //     isFixedDisableSavePlan: _.size(this.state.savedFixedPlans) === 0 ? true : false,
  //     isFixedPlanError: _.size(this.state.savedFixedPlans) === 0 ? true : false,
  //   })
  // }

  // selectPlan(plan) {
  //   const plans = _.cloneDeep(this.state.selectedPlans);
  //   const index = _.findIndex(plans, function (p) {
  //     return String(p.id) === String(plan.id);
  //   });
  //   if (index === -1) {
  //     plans.push({
  //       id: plan.id,
  //       title: plan.title
  //     });
  //   } else {
  //     plans.splice(index, 1);
  //   }
  //   this.setState({
  //     selectedPlans: plans,
  //     isDisableSavePlan: _.size(plans) === 0 ? true : false,
  //     isPlanError: _.size(plans) === 0 ? true : false
  //   });
  // }
  // selectFixedPlan(plan) {
  //   const plans = _.cloneDeep(this.state.selectedFixedPlans);
  //   const index = _.findIndex(plans, function (p) {
  //     return String(p.id) === String(plan.id);
  //   });
  //   if (index === -1) {
  //     plans.push({
  //       id: plan.id,
  //       offername: plan.offername
  //     });
  //   } else {
  //     plans.splice(index, 1);
  //   }
  //   this.setState({
  //     selectedFixedPlans: plans,
  //     isFixedDisableSavePlan: _.size(plans) === 0 ? true : false,
  //     isFixedPlanError: _.size(plans) === 0 ? true : false
  //   });
  // }

  // savePlans = () => {
  //   this.setState({
  //     selectPlanDrawer: false,
  //     isPlanError: false,
  //     savedPlans: this.state.selectedPlans
  //   })
  // }

  // savedFixedPlans = () => {
  //   this.setState({
  //     selectFixedPlanDrawer: false,
  //     isFixedPlanError: false,
  //     savedFixedPlans: this.state.selectedFixedPlans
  //   })
  // }

  handleAddUser = async (values, { setSubmitting }) => {
    try {
      const { user } = this.state;
      values.invitedBy = user.id;
      if (values.role === 'spr_partner') {
        values.status = 0;
        values.isAccountSetup = 0;
        let totalLines = values.total_lines.replace(/,/g, '');
        values.total_lines = totalLines;
        let bn = values.brandName.split('-');
        values.brandName = bn[0];
        const deepCopyValues = _.cloneDeep(values);
        deepCopyValues.phone = deepCopyValues.phone.replace(/[^\d]/g, '');

        const {
          value: { success },
        } = await this.props.signUpUser(deepCopyValues);
        if (success) {
          setSubmitting(false);
          this.setState({ errorMessage: '' });
          this.props.history.goBack();
        }
      } else {
        values.phone = values.phone.replace(/[^\d]/g, '');
        const {
          value: { success },
        } = await this.props.signUpUser(values);
        if (success) {
          setSubmitting(false);
          this.setState({ errorMessage: '' });
          this.props.history.goBack();
        }
      }
    } catch (err) {
      const { message } = _.get(err, 'data', err);
      setSubmitting(false);
      if (_.get(err, 'duplicateKey', '') === 'email') {
        this.setState({ isEmailExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'phone') {
        this.setState({ isPhoneExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'companyname') {
        this.setState({ isCompnayNameExists: true });
      } else {
        this.setState({ errorMessage: message });
      }
    }
  };

  // searchFixedPlans = event => {
  //   const { fixedPlans } = this.state
  //   let searchText = (event.target.value).trim().toLowerCase()
  //   const filterData = fixedPlans.filter((item, key) => item.offername.toLowerCase().includes(searchText))
  //   if (event.target.value === '') {
  //     this.setState({ filteredFixedPlans: fixedPlans })
  //   } else {
  //     this.setState({ filteredFixedPlans: filterData })
  //   }
  // };

  // searchPlans = event => {
  //   const { plans } = this.state
  //   let searchText = (event.target.value).trim().toLowerCase()
  //   const filterData = plans.filter((item, key) => item.title.toLowerCase().includes(searchText))
  //   if (event.target.value === '') {
  //     this.setState({ filteredPlans: plans })
  //   } else {
  //     this.setState({ filteredPlans: filterData })
  //   }
  // };

  handleBrandNameBlur = async (values, e) => {
    try {
      if (e.target.value) {
        const obj = {
          brandName: e.target.value,
        };
        this.setState({ isLoading: true });
        const {
          value: { success, data, message },
        } = await this.props.getPartnerDetailFromUltra(obj);
        if (success) {
          this.setState({
            initialValues: { ...values, ...data },
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
          notification['success']({
            message: message,
            // description: message,
          });
        }
      }
    } catch (e) {
      this.setState({ isLoading: false });
      notification['error']({
        message: e.message.message,
        // description: message,
      });
    }
  };

  render() {
    const {
      errorMessage,
      addUserType,
      // filteredPlans,
      // savedPlans,
      // selectedPlans,
      initialValues,
      isEmailExists,
      isPhoneExists,
      isCompnayNameExists,
      // isPlanError,
      // isDisableSavePlan,
      // isFixedDisableSavePlan,
      // isFixedPlanError,
      // filteredFixedPlans,
      // selectedFixedPlans,
      // savedFixedPlans,
      lobIdForValidation,
    } = this.state;
    // let _plans = []
    // let _plansFixed = []
    // _.forEach(savedPlans, function (p) {
    //   _plans.push(p.title)
    // })
    // _.forEach(savedFixedPlans, function (p) {
    //   _plansFixed.push(p.offername)
    // })
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="side-heading">
                <h4 className="title">Add User</h4>
              </div>
            </div>
            <div className="col-md-10">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.handleAddUser}
                validationSchema={
                  addUserType === 'spr_partner' &&
                  parseInt(lobIdForValidation) === 2
                    ? addUserSPRSchema
                    : addUserType === 'spr_partner' &&
                      parseInt(lobIdForValidation) === 1
                    ? addUserSPRSchemaforSpr
                    : addUserType === 'spr_partner' &&
                      parseInt(lobIdForValidation) === 3
                    ? addUserSPRSchemaforFixed
                    : addUserSchema
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card mb-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                name="role"
                                value={values.role}
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    addUserType: e.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                required
                              >
                                <option
                                  disabled
                                  className="hideDefaultOption"
                                ></option>
                                <option value="spr_partner">Partners</option>
                                {/*<option value="corporate_client">Corporate User</option>*/}
                                <option value="admin">Ultra Admin</option>
                              </select>
                              <label className="material-textfield-label">
                                Account Type
                              </label>
                              <div className="invalid-feedback">
                                {errors.role && touched.role && errors.role}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {addUserType === 'spr_partner' && (
                              <div className="form-group material-textfield">
                                <select
                                  className="form-control form-control-lg custom-select material-textfield-input"
                                  name="lobId"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.setState({
                                      errorMessage: '',
                                      lobId: e.target.value,
                                      lobIdForValidation: e.target.value,
                                    });
                                  }}
                                  value={values.lobId || 1}
                                >
                                  {_.get(this.state, 'lineOfBList', []).map(
                                    (lob, i) => {
                                      return (
                                        <option key={i} value={lob.id}>
                                          {lob.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                <label className="material-textfield-label">
                                  Line of Business
                                </label>
                                <div className="invalid-feedback">
                                  {errors.role && touched.role && errors.role}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {addUserType === 'spr_partner' && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="brandName"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.setState({
                                      errorMessage: '',
                                      brandName: e.target.value,
                                    });
                                  }}
                                  onBlur={this.handleBrandNameBlur.bind(
                                    this,
                                    values
                                  )}
                                  value={values.brandName || ''}
                                  maxLength="50"
                                  required
                                />
                                <label className="material-textfield-label">
                                  Brand Name{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                {this.state.isLoading ? (
                                  <Spin
                                    className="spiner-for-field"
                                    indicator={antIcon}
                                  />
                                ) : (
                                  ''
                                )}
                                <div className="invalid-feedback">
                                  {errors.brandName &&
                                    touched.brandName &&
                                    errors.brandName}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="partnerCode"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.setState({
                                      errorMessage: '',
                                      partnerCode: e.target.value,
                                    });
                                  }}
                                  disabled={true}
                                  value={values.partnerCode || ''}
                                  maxLength="15"
                                  required
                                />
                                <label className="material-textfield-label">
                                  Partner Code{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="invalid-feedback">
                                  {errors.partnerCode &&
                                    touched.partnerCode &&
                                    errors.partnerCode}
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="apiKey"
                              onChange={(e) => {
                                handleChange(e)
                                this.setState({ errorMessage: '', apiKey: e.target.value })
                              }}
                              value={values.apiKey || ''}
                              maxLength="50"
                              required
                            />
                            <label className="material-textfield-label">Api Key <span className="text-danger">*</span></label>
                            <div className="invalid-feedback">{errors.apiKey && touched.apiKey && errors.apiKey}</div>
                          </div>
                        </div> */}
                          </div>
                        )}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="maid"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    maid: e.target.value,
                                  });
                                }}
                                disabled={
                                  values.role !== 'spr_partner' ? false : true
                                }
                                value={values.maid || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                MA ID <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.maid && touched.maid && errors.maid}
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="companyname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isCompnayNameExists: false,
                                    companyname: e.target.value.trim(),
                                  });
                                }}
                                value={values.companyname || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Company Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.companyname &&
                                  touched.companyname &&
                                  errors.companyname}
                              </div>
                              {isCompnayNameExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Company name already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {addUserType === 'spr_partner' &&
                          (parseInt(values.lobId) === 1 ||
                            parseInt(values.lobId) === 2) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="sprPartnerId"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        sprPartnerId: e.target.value,
                                      });
                                    }}
                                    value={values.sprPartnerId || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    SPR Partner Id
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.sprPartnerId &&
                                      touched.sprPartnerId &&
                                      errors.sprPartnerId}
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="sprPartnerSecret"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        sprPartnerSecret: e.target.value,
                                      });
                                    }}
                                    value={values.sprPartnerSecret || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    SPR Partner Secret{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.sprPartnerSecret &&
                                      touched.sprPartnerSecret &&
                                      errors.sprPartnerSecret}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {addUserType === 'spr_partner' &&
                          (parseInt(values.lobId) === 2 ||
                            parseInt(values.lobId) === 3) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="fixedPartnerId"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        fixedPartnerId: e.target.value,
                                      });
                                    }}
                                    value={values.fixedPartnerId || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    Fixed Partner Id
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.fixedPartnerId &&
                                      touched.fixedPartnerId &&
                                      errors.fixedPartnerId}
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="fixedPartnerSecret"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        fixedPartnerSecret: e.target.value,
                                      });
                                    }}
                                    value={values.fixedPartnerSecret || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    Fixed Partner Secret{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.fixedPartnerSecret &&
                                      touched.fixedPartnerSecret &&
                                      errors.fixedPartnerSecret}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="firstname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    firstname: e.target.value,
                                  });
                                }}
                                value={values.firstname || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                First Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.firstname &&
                                  touched.firstname &&
                                  errors.firstname}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="lastname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    lastname: e.target.value,
                                  });
                                }}
                                value={values.lastname || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.lastname &&
                                  touched.lastname &&
                                  errors.lastname}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group material-textfield">
                              <PhoneInput
                                inputProps={{
                                  readOnly: true,
                                  name: 'phone',
                                  required: true,
                                  autoFocus: true,
                                  className:
                                    'form-control form-control-lg material-textfield-input',
                                }}
                                country={'us'}
                                value={values.countryCode}
                                onChange={(phone, val) =>
                                  this.setState({
                                    initialValues: {
                                      ...values,
                                      countryCode: val.dialCode,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="form-group material-textfield">
                              <InputMask
                                guide={false}
                                type="text"
                                id="phone"
                                keepCharPositions={false}
                                mask={phoneNumberMask}
                                className="form-control form-control-lg material-textfield-input"
                                name="phone"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isPhoneExists: false,
                                    phone: e.target.value,
                                  });
                                }}
                                value={values.phone || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Phone Number{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.phone && touched.phone && errors.phone}
                              </div>
                              {isPhoneExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Phone number already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="email"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isEmailExists: false,
                                    email: e.target.value,
                                  });
                                }}
                                value={values.email || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Email Address{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.email && touched.email && errors.email}
                              </div>
                              {isEmailExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Email already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/*<div className="form-group material-textfield">
                          <input
                            type="password"
                            className="form-control form-control-lg material-textfield-input"
                            name="password"
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ errorMessage: '', password: e.target.value })
                            }}
                            value={values.password || ''}
                            required
                          />
                          <label className="material-textfield-label">Password</label>
                          <div className="invalid-feedback">{errors.password && touched.password && errors.password}</div>
                        </div>*/}

                        {/* {values.role !== 'spr_partner' ? */}
                        {/* <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="maid"
                              onChange={(e) => {
                                handleChange(e)
                                this.setState({ errorMessage: '', maid: e.target.value })
                              }}
                              disabled={true}
                              value={values.maid || ''}
                              required
                            />
                            <label className="material-textfield-label">MA ID <span className="text-danger">*</span></label>
                            <div className="invalid-feedback">{errors.maid && touched.maid && errors.maid}</div>
                          </div> */}
                        {/* : */}
                        {/* <div></div> */}
                        {/* } */}

                        {values.role === 'spr_partner' && (
                          <div className="form-group material-textfield">
                            <NumberFormat
                              className="form-control form-control-lg material-textfield-input"
                              name="total_lines"
                              allowLeadingZeros={false}
                              isAllowed={(values) =>
                                values.value !== '0' ? true : false
                              }
                              thousandSeparator={true}
                              decimalScale={0}
                              allowNegative={false}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  errorMessage: '',
                                  total_lines: e.target.value,
                                });
                              }}
                              value={values.total_lines || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Total Approved Lines{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="invalid-feedback">
                              {errors.total_lines &&
                                touched.total_lines &&
                                errors.total_lines}
                            </div>
                          </div>
                        )}

                        {/* <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control form-control-lg material-textfield-input"
                            name="companyname"
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ errorMessage: '', isCompnayNameExists: false, companyname: e.target.value })
                            }}
                            value={values.companyname || ''}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Company Name <span className="text-danger">*</span></label>
                          <div className="invalid-feedback">{errors.companyname && touched.companyname && errors.companyname}</div>
                          {isCompnayNameExists &&
                            <div>
                              <div className="invalid-feedback">Company name already in use.</div>
                            </div>
                          }
                        </div> */}

                        {/*<div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            required
                          >
                            <option disabled className="hideDefaultOption"></option>
                            <option value="10">Active</option>
                            <option value="0">Not Active</option>
                          </select>
                          <label className="material-textfield-label">Status</label>
                          <div className="invalid-feedback">{errors.status && touched.status && errors.status}</div>
                        </div>*/}
                        <div className="invalid-feedback">{errorMessage}</div>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="btn btn-dark btn-lg w-100"
                        >
                          {isSubmitting ? 'Please wait...' : 'Create User'}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
