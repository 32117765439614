/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Popover, DatePicker, Select, notification } from 'antd';
import InputMask from 'react-text-mask';
import ReactModal from 'react-modal';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import PlacesAutocomplete from '../../components/PlaceSearchBox';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';
import {
  SimsFixedColorIcon,
  FilterIcon,
  ExportIcon,
  CloseIcon,
  CalenderIcon,
  RefreshIcon,
} from '../../components/icons';
import SimList from './SimList/container';
import { DebounceInput } from 'react-debounce-input';
import './styles.scss';
import _ from 'lodash';
import moment from 'moment';
// import socket from '../../utils/socket'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ClientModal from '../../components/ClientModal';

// For tags options
const { Option } = Select;
const dateFormat = 'MMMM DD YYYY';
const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class YourSiMsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSimsForZipChange: [],
      isLoading: true,
      isExportLoading: false,
      userData: null,
      iAuthorization: localStorage.getItem('i_Authorization'),
      //new
      titleText: '',
      descriptionText: '',
      openThankYouModal: false,
      openCancelPortInFailureModal: false,
      openErrorModal: false,
      notifications: [],
      planError: false,
      isApplyButton: false,
      simPlansData: [],
      fixedPlans: [],
      selectedSimPlan: {},
      selectedFixedSimPlan: {},
      updateLoading: false,
      requireName: false,
      deleteModal: false,
      deleteSimnumber: {},
      isActivateModal: false,
      newSimName: '',
      isUpdateSimModal: false,
      newTag: '',
      originalSelectedTags: [],
      selectedListTags: [],
      allChecked: false,
      markedSimList: [],
      selectedAction: '',
      isCreateTagModal: false,
      isCreateClientModal: false,
      isActionModal: false,
      addTags: false,
      isFiltervisible: false,
      isDateFilter: false,
      isTagsFilter: false,
      isClientFilter: false,
      changePlanModal: false,
      selectedItem: {},
      selectedMenuKey: '',
      sims: [],
      total: 0,
      counts: {},
      filterTags: [],
      searchFilterTags: [],
      searchClientFilter: [],
      activeTab: 'active',
      type: 'active', //'active', 'inactive', 'paused', 'deactive'
      userId: 0,
      page: 1,
      sortBy: 'activationdate',
      sortOrder: 'desc',
      limit: 50,
      keyword: '',
      tags: '',
      byDate: false,
      startDate: '',
      endDate: '',
      searchSims: '',
      isExport: false,
      newSimNameSize: false,
      newTagSize: false,
      isTagChanged: false,
      portSimModal: false,
      portInPhoneNumber: '',
      requirePortInPhoneNumber: false,
      selectedCarrier: '',
      carrierError: false,
      carrierACNumber: '',
      requireCarrierACNumber: false,
      carrierAssignPassword: '',
      requireCarrierAssignPassword: false,
      street1: '',
      street1Error: false,
      street2: '',
      street2Error: false,
      city: '',
      cityError: false,
      state: '',
      stateError: false,
      zip4: '',
      zipcodePortIn: '',
      requireZipcodePortIn: false,
      requireAltContactNumber: false,
      reactivateSimModal: false,
      altContactNumber: '',
      clients: [],
      selectedClient: [],
      newClientSize: false,
      isFixedPlan: true,
      isSprPlan: true,
      sprPlanIndex: '',
      fixedPlanIndex: '',
      isUnpauseModal: false,
      planValue: '',
      unPauseSimPlanError: false,
      isMoveBackModal: false,
      searchBy: 'iccid',
    };
    this.createTagsModal = this.createTagsModal.bind(this);
    this.createClientModal = this.createClientModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      portSimModal: false,
      selectedAction: '',
      requireName: false,
      deleteSimnumber: {},
      deleteModal: false,
      isCreateTagModal: false,
      isCreateClientModal: false,
      isActionModal: false,
      isUpdateSimModal: false,
      isActivateModal: false,
      reactivateSimModal: false,
      isUnpauseModal: false,
      isMoveBackModal: false,
    });
  }

  componentDidMount = async () => {
    document.title = 'Your Fixed Sims | UltraIoT';
    const { fetchMe, user } = this.props;
    if (_.isEmpty(user)) {
      const {
        value: { success, user },
      } = await fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
      }
    } else {
      this.setState({ userData: user });
    }
    Promise.all([
      this.props.fetchFixedPlansByUserId(),
      this.props.fetchClients(),
    ]).then(async (res) => {
      const fixedPlansDetail = res[0].value;
      const clientsDetail = res[1].value;
      if (fixedPlansDetail.fixedPlans) {
        this.setState({ fixedPlans: fixedPlansDetail.fixedPlans });
      }

      if (clientsDetail.success) {
        this.setState({ clients: clientsDetail.data });
      }

      let selectedTab = '';
      let tab = _.get(this.props, 'location.pathname', '/fixed-active-sims');
      if (tab === '/fixed-active-sims') {
        selectedTab = 'fixed-active';
        document.title = 'Active SIMs | UltraIoT';
      } else if (tab === '/fixed-activation-in-progress') {
        document.title = 'Fixed Activation In Progress SIMs | UltraIoT';
        selectedTab = 'fixed-activationInProgress';
      } else if (tab === '/fixed-never-been-activated') {
        selectedTab = 'fixed-inactive';
        document.title = 'Fixed Never Been Activated SIMs | UltraIoT';
      } else if (tab === '/fixed-paused-sims') {
        this.setState({ sortBy: 'datePlanChanged' });
        selectedTab = 'fixed-paused';
        document.title = 'Fixed Paused SIMs | UltraIoT';
      } else if (tab === '/fixed-deactivated-sims') {
        this.setState({ sortBy: 'datePlanChanged' });
        selectedTab = 'fixed-deactive';
        document.title = 'Fixed Deactived SIMs | UltraIoT';
      } else if (tab === 'fixed-orders') {
        this.props.history.push('/fixed-orders');
        document.title = 'Fixed SIMs Orders | UltraIoT';
      }

      this.setState({ activeTab: selectedTab });

      // socket = io.connect(`${HOSTNAME}`)
      // socket.on('notifications', (data) => {
      //   if(data) {
      //     this.getSimsOnTabChange()
      //   }
      // })

      // socket.on('refresh-list', (data) => {
      //   if(data) {
      //     this.getSimsOnTabChange()
      //   }
      // })

      if (_.get(this.props, 'location.state.from', false) === 'dashboard') {
        let tags = _.get(this.props, 'location.state.tags', '');
        let searchFilterTags = tags.split(',');
        this.setState({ searchFilterTags, tags, isTagsFilter: true }, () => {
          this.getSimsOnTabChange('fixed');
        });
      } else {
        this.getSimsOnTabChange('fixed');
      }

      this.clearLocationState();
    });
  };

  getAllTags = async (q) => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchTagsByType({
        q,
        type: 'all',
        simType: 'fixed',
      });
      if (success) {
        this.setState({ filterTags: data });
      }
    } catch (error) {}
  };

  getAllClients = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchClients();
      if (success) {
        this.setState({ clients: data });
      }
    } catch (error) {}
  };

  clearLocationState = () => {
    const { history } = this.props;
    if (history) {
      const state = { ...history.location.state };
      if (state && state.from && state.tags) {
        delete state.from;
        delete state.tags;
      }
      history.replace({ ...history.location, state });
    }
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab, page: 1, allChecked: false }, () => {
      if (tab === 'fixed-active') {
        this.props.history.push('/fixed-active-sims');
        document.title = 'Fixed Active SIMs | UltraIoT';
      } else if (tab === 'fixed-activationInProgress') {
        this.props.history.push('/fixed-activation-in-progress');
        document.title = 'Fixed Activation In Progress SIMs | UltraIoT';
      } else if (tab === 'fixed-inactive') {
        this.props.history.push('/fixed-never-been-activated');
        document.title = 'Fixed Never Been Activated SIMs | UltraIoT';
      } else if (tab === 'fixed-paused') {
        this.props.history.push('/fixed-paused-sims');
        document.title = 'Fixed Paused SIMs | UltraIoT';
      } else if (tab === 'fixed-deactive') {
        this.props.history.push('/fixed-deactivated-sims');
        document.title = 'Fixed Deactived SIMs | UltraIoT';
      } else if (tab === 'fixed-orders') {
        this.props.history.push('/fixed-orders');
        document.title = 'Fixed Orders | UltraIoT';
      }
      this.getSimsOnTabChange('fixed');
    });
  };

  getSimsOnTabChange = async (simType) => {
    this.setState({ isLoading: true });
    const {
      activeTab,
      page,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      searchClientFilter,
      searchBy,
    } = this.state;
    let { sortBy } = this.state;
    let type = '';
    if (activeTab === 'fixed-active') {
      type = 'active';
    } else if (activeTab === 'fixed-inactive') {
      type = 'inactive';
    } else if (activeTab === 'fixed-paused') {
      type = 'paused';
    } else if (activeTab === 'fixed-deactive') {
      type = 'deactive';
    } else if (activeTab === 'fixed-activationInProgress') {
      type = 'activationInProgress';
    }
    let obj = {
      type: type,
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      isExport: false,
      clients: searchClientFilter.map((item) => item.id),
      simType: simType,
      orderType: 'fixed',
      searchBy,
    };

    try {
      const {
        value: { success, counts, total, records },
      } = await this.props.getSims(obj);
      if (success) {
        this.setState({
          sims: records,
          markedSimList: [],
          allChecked: false,
          total,
          counts,
          isLoading: false,
        });
        this.props.setCount(counts);
        this.markedNewTag(records);
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  setSortByKeys = async (key, order) => {
    this.setState({ sortBy: key, sortOrder: order });
  };

  markedNewTag = (records) => {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      let matchedData = records.filter((record) => {
        return (
          markedSimList.filter((mark) => {
            return mark.id === record.id;
          }).length !== 0
        );
      });
      this.setState({ markedSimList: [...matchedData] });
    }
  };

  hide = () => {
    this.setState({
      isFiltervisible: false,
    });
  };

  filterVisible = (visible) => {
    this.setState({ isFiltervisible: visible });
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getSimsOnTabChange('fixed');
    });
  };

  //Filter Methods
  onClientFilter = (client) => {
    let allClients = this.state.searchClientFilter;
    let index = allClients.findIndex((t) => t.id === client.id);
    if (index === -1) {
      allClients.push(client);
    } else {
      allClients.splice(index, 1);
    }
    this.setState({ searchClientFilter: allClients }, () => {
      this.getSimsOnTabChange('fixed');
    });
  };

  onTagsFilter = (tag) => {
    let allTags = this.state.searchFilterTags;
    if (!allTags.includes(tag)) {
      allTags.push(tag);
    } else {
      let index = allTags.findIndex((t) => t === tag);
      allTags.splice(index, 1);
    }
    this.setState({ searchFilterTags: allTags }, () => {
      let tags = '';
      _.forEach(allTags, (item, index) => {
        tags += item;
        if (index < allTags.length - 1) {
          tags += ',';
        }
      });
      this.setState({ tags }, () => {
        this.getSimsOnTabChange('fixed');
      });
    });
  };

  onChangeDate = (from, date, dateString) => {
    if (from === 'start') {
      this.setState({ startDate: date, endDate: '' });
    } else if (from === 'end') {
      this.setState({ endDate: date });
      this.setState({ byDate: true }, () => {
        this.getSimsOnTabChange();
      });
    }
  };

  disabledDate = (current) => {
    return current && current.valueOf() <= new Date(this.state.startDate);
  };

  onClearFilter = () => {
    this.setState(
      {
        isClientFilter: false,
        searchClientFilter: [],
        isTagsFilter: false,
        isDateFilter: false,
        tags: '',
        byDate: false,
        startDate: '',
        endDate: '',
        searchFilterTags: [],
      },
      () => {
        var inputs = document.querySelectorAll('.unCheckAll');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        this.getSimsOnTabChange();
      }
    );
  };

  removeFilter(index) {
    if (index === -10) {
      this.setState(
        { isDateFilter: false, byDate: false, startDate: '', endDate: '' },
        () => {
          this.forceUpdate();
          this.getSimsOnTabChange();
        }
      );
    } else {
      let searchFilterTags = this.state.searchFilterTags;
      searchFilterTags.splice(index, 1);
      this.setState({ searchFilterTags }, () => {
        let tags = '';
        _.forEach(searchFilterTags, (item, index) => {
          tags += item;
          if (index < searchFilterTags.length - 1) {
            tags += ',';
          }
        });
        this.setState({ tags }, () => {
          this.forceUpdate();
          this.getSimsOnTabChange();
        });
      });
    }
  }

  removeClientFilter(index) {
    let searchClientFilter = this.state.searchClientFilter;
    searchClientFilter.splice(index, 1);
    this.setState({ searchClientFilter }, () => {
      this.forceUpdate();
      this.getSimsOnTabChange();
    });
    // let index = allClients.findIndex(t => t.id === client.id)
    // if(index === -1){
    //   allClients.push(client)
    // } else {
    //   allClients.splice(index,1)
    // }
    // this.setState({ searchFilterTags },() => {
    //   let tags = ''
    //   _.forEach(searchFilterTags,(item,index) => {
    //     tags += item
    //     if((index < (searchFilterTags.length - 1))){
    //       tags += ','
    //     }
    //   })
    //   this.setState({ tags },() => {
    //     this.forceUpdate()
    //     this.getSimsOnTabChange()
    //   })
    // })
  }

  // Update Sim Name
  updateSimName = async () => {
    const { newSimName, markedSimList } = this.state;
    if (newSimName.trim().length === 0) {
      this.setState({ requireName: true });
    } else if (newSimName.trim().length < 3) {
      this.setState({ newSimNameSize: true });
    } else {
      this.setState({ updateLoading: true });
      let obj = {};
      obj.simname = newSimName;
      obj.ids = markedSimList.map((sim) => sim.id);
      try {
        const {
          value: { success },
        } = await this.props.updateSimName(obj);
        if (success) {
          this.setState(
            {
              updateLoading: false,
              isUpdateSimModal: false,
              newSimName: '',
              keyword: '',
              selectedAction: '',
            },
            () => {
              this.getSimsOnTabChange();
            }
          );
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          isUpdateSimModal: false,
          selectedAction: '',
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  singleSimAction = (key, item) => {
    let { markedSimList } = this.state;
    if (key === 'activate') {
      markedSimList = [];
      markedSimList.push(item);
      this.setState({ markedSimList }, async () => {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        let areZipcodesEligible = true;
        if (markedSimList.length) {
          areZipcodesEligible =
            await this.checkAllZipcodeEligibility(markedSimList);
        }

        const zipcode =
          item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
            ? item.zipcodeOnChange
            : item.zipcode;

        if (zipcode.length !== 5) {
          error = true;
          item.isZipCodeIneligible = true
        }
        if (error) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else if (!areZipcodesEligible) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is not eligible to activate sim. Please enter valid zip for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else {
          this.setState({
            isActivateModal: true,
            selectedSimPlan: {},
            planError: false,
          });
        }
      });
    } else if (key === 'delete') {
      this.setState({ deleteSimnumber: item, deleteModal: true });
    }
  };

  deleteSim = async () => {
    const { deleteSimnumber, markedSimList } = this.state;
    let simIds = [];
    if (_.isEmpty(deleteSimnumber)) {
      simIds = markedSimList.map((item) => item.id);
    } else {
      simIds.push(deleteSimnumber.id);
    }
    this.setState({ updateLoading: true });
    let obj = {
      ids: simIds,
    };
    try {
      const {
        value: { success },
      } = await this.props.deleteSims(obj);
      if (success) {
        this.setState({
          deleteModal: false,
          deleteSimnumber: {},
          updateLoading: false,
          keyword: '',
        });
        this.getSimsOnTabChange();
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        deleteModal: false,
        deleteSimnumber: {},
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  //Call Activate Sim API From Never Been Activated Tab
  activateSim = async () => {
    const { selectedSimPlan, userData, markedSimList, sims } = this.state;
    _.forEach(markedSimList, function (value) {
      if (value.simname === '') {
        value.simname = value.simnumber;
      }
    });
    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ planError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (_.get(userData, 'role', 0) === 'user') {
        if (selectedSimPlan.title !== 'Test Data Plan') {
          let amountToCharge =
            markedSimList.length * selectedSimPlan.monthlyprice;
          if (userData.availablebalance === 0) {
            error = true;
            descriptionText =
              'You have 0 balance in your account. Please add fund to activate sims.';
          } else if (amountToCharge > userData.availablebalance) {
            error = true;
            descriptionText =
              'You have insufficient balance in your account. Please add fund to activate sims.';
          }
        } else if (
          selectedSimPlan.title === 'Test Data Plan' &&
          markedSimList.length > 1
        ) {
          error = true;
          descriptionText =
            'You can activate only 1 SIM in test data plan. Please choose a single SIM instead.';
        }
      }

      if (!error) {
        let obj = {};
        obj.sims = markedSimList.map((sim) => ({
          simname: sim.simname,
          iccid: sim.simnumber,
          zipcode: sim.zipcode,
        }));
        if (_.get(userData, 'consumeOfferAPI', false)) {
          obj.offerId = selectedSimPlan.offerId;
          obj.title = selectedSimPlan.title;
        } else {
          obj.planid = selectedSimPlan.id;
        }
        try {
          const {
            value: { success },
          } = await this.props.activateSims(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            _.forEach(markedSimList, (item) => {
              let index = sims.findIndex((sim) => item.id === sim.id);
              if (index > -1) {
                let obj = sims[index];
                obj.status = 12;
              }
            });
            this.setState(
              {
                sims,
                isActivateModal: false,
                updateLoading: false,
                titleText,
                descriptionText,
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  markedSimList: [],
                  allChecked: false,
                  keyword: '',
                });
              }
            );
          }
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            updateLoading: false,
            openThankYouModal: true,
            isActivateModal: false,
            errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: true,
        });
      }
    }
  };

  pauseSim = async () => {
    const { markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let obj = {};
    obj.action = 'pause';
    obj.sims = markedSimList.map((sim) => sim.id);
    try {
      const {
        value: { success },
      } = await this.props.pauseSims(obj);
      if (success) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          markedSimList: [],
          keyword: '',
          allChecked: false,
          updateLoading: false,
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  bulkPlanChange = async () => {
    const { markedSimList, changedSimPlan } = this.state;
    const { user } = this.props;
    this.setState({ simLengthError: '' });
    if (_.isEmpty(changedSimPlan)) {
      this.setState({ changedPlanError: true });
    } else if (_.size(markedSimList) > 50) {
      this.setState({
        simLengthError:
          'Please select 50(max) or less SIMs to perform this operation.',
      });
    } else {
      this.setState({ changePlanLoading: true });
      let obj = {};
      obj.sims = markedSimList.map((sim) => sim.id);

      if (_.get(user, 'consumeOfferAPI', false)) {
        obj.offerId = changedSimPlan.offerId;
        obj.title = changedSimPlan.title;
      } else {
        obj.planId = changedSimPlan.id;
      }
      try {
        const {
          value: { success },
        } = await this.props.bulkPlanChange(obj);
        if (success) {
          setTimeout(() => {
            this.setState({
              isActionModal: false,
              selectedAction: '',
              markedSimList: [],
              keyword: '',
              allChecked: false,
              changePlanLoading: false,
              changePlanModal: false,
              changedSimPlan: {},
            });
            notification['success']({
              message: 'Sims Plan Change',
              description:
                'Your Plan Changes are processing. Check the notifications icon for updates.',
            });
            this.getSimsOnTabChange();
          }, 800);
        }
      } catch (err) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          changePlanLoading: false,
          changePlanModal: false,
          errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  deactivateSim = async () => {
    const { markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let obj = {};
    obj.action = 'deactivate';
    obj.sims = markedSimList.map((sim) => sim.id);
    try {
      const {
        value: { success },
      } = await this.props.deactivateSims(obj);
      if (success) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          markedSimList: [],
          allChecked: false,
          updateLoading: false,
          keyword: '',
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  //Activate Sim from Pause and Deactivate tab
  activatePauseDeactivateSim = async () => {
    const { selectedSimPlan, userData, markedSimList } = this.state;
    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ planError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (_.get(userData, 'role', 0) === 'user') {
        if (selectedSimPlan.title !== 'Test Data Plan') {
          let amountToCharge =
            markedSimList.length * selectedSimPlan.monthlyprice;
          if (userData.availablebalance === 0) {
            error = true;
            descriptionText =
              'You have 0 balance in your account. Please add fund to activate sims.';
          } else if (amountToCharge > userData.availablebalance) {
            error = true;
            descriptionText =
              'You have insufficient balance in your account. Please add fund to activate sims.';
          }
        } else if (
          selectedSimPlan.title === 'Test Data Plan' &&
          markedSimList.length > 1
        ) {
          error = true;
          descriptionText =
            'You can activate only 1 SIM in test data plan. Please choose a single SIM instead.';
        }
      }

      if (!error) {
        this.setState({ updateLoading: true });
        let obj = {
          sims: markedSimList.map((sim) => sim.id),
          action: 'activate',
          plan: selectedSimPlan.id,
        };
        try {
          const {
            value: { success },
          } = await this.props.activateSimByPlan(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            this.setState(
              {
                isActivateModal: false,
                updateLoading: false,
                titleText,
                descriptionText,
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  markedSimList: [],
                  allChecked: false,
                });
              }
            );
          }
        } catch (err) {
          descriptionText =
            'Something went wrong with the process. Check later...';
          this.setState({
            updateLoading: false,
            titleText,
            descriptionText,
            openThankYouModal: true,
            isActivateModal: false,
            errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: true,
        });
      }
    }
  };

  selectActionHandle = (value) => {
    let selectedAction = value;
    this.setState({ selectedAction }, async () => {
      if (selectedAction === 'pause' || selectedAction === 'inactive') {
        this.setState({ isActionModal: true });
      } else if (selectedAction === 'activate') {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        let areZipcodesEligible = true;
        const { markedSimList } = this.state;
        if (markedSimList.length) {
          areZipcodesEligible =
            await this.checkAllZipcodeEligibility(markedSimList);
        }
        _.forEach(markedSimList, (item) => {
          const zipcode =
            item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
              ? item.zipcodeOnChange
              : item.zipcode;

              if (!zipcode) {
                error = true;
                item.isZipCodeIneligible = true;
              } if ( zipcode.length !== 5) {
                item.isZipCodeIneligible = true;
                areZipcodesEligible = false
              }
        });
        if (error) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else if (!areZipcodesEligible) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is not eligible to activate sim. Please enter valid zip for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else {
          this.setState({
            isActivateModal: true,
            selectedSimPlan: {},
            fixedPlanIndex: '',
            planError: false,
          });
        }
      } else if (selectedAction === 'setsimname') {
        this.setState({
          isUpdateSimModal: true,
          newSimName: '',
          requireName: false,
          newSimNameSize: false,
        });
      } else if (selectedAction === 'delete') {
        this.setState({ deleteModal: true });
      } else if (selectedAction === 'portIn') {
        this.setState({
          portSimModal: true,
          portInPhoneNumber: '',
          selectedCarrier: '',
          carrierACNumber: '',
          carrierAssignPassword: '',
          street1: '',
          street1Error: false,
          street2: '',
          street2Error: false,
          city: '',
          cityError: false,
          state: '',
          stateError: false,
          zip: '',
          zipError: false,
          zip4: '',
          zipcodePortIn: '',
          altContactNumber: '',
          selectedSimPlan: {},
          errorMessage: '',
          requirePortInPhoneNumber: false,
          carrierError: false,
          requireCarrierACNumber: false,
          requireCarrierAssignPassword: false,
          requireZipcodePortIn: false,
          requireAltContactNumber: false,
          planError: false,
        });
      } else if (selectedAction === 'reactivate') {
        this.setState({
          reactivateSimModal: true,
        });
      } else if (selectedAction === 'unpause') {
        this.setState({
          isUnpauseModal: true,
        });
      } else if (selectedAction === 'changePlan') {
        this.setState({ changePlanModal: true, simLengthError: '' });
      }
    });
  };

  //On check Mark Sim Methods
  onCheck = (item, key = 'single') => {
    const { sims, allChecked, fixedPlans } = this.state;
    let markedSimList = this.state.markedSimList;
    if (key === 'single') {
      if (!markedSimList.includes(item)) {
        markedSimList.push(item);
      } else {
        let index = markedSimList.findIndex((list) => list.id === item.id);
        if (index !== -1) {
          markedSimList[index].isZipCodeIneligible = false;
        }
        markedSimList.splice(index, 1);
      }
      if (markedSimList.length === 1) {
        const planid = markedSimList[0].planid;
        const index = _.get(this.state, 'fixedPlans', []).findIndex(
          (item) => item.id === planid
        );
        this.setState({ planValue: index, selectedSimPlan: fixedPlans[index] });
      } else {
        this.setState({ planValue: '', selectedSimPlan: {} });
      }

      this.setState({ markedSimList }, () => {
        if (_.size(sims) === _.size(markedSimList)) {
          this.setState({ allChecked: true });
        } else {
          this.setState({ allChecked: false });
        }
      });
    } else {
      this.setState({ planValue: '', selectedSimPlan: {} });
      let allSimData = [...sims];
      if (!allChecked) {
        this.setState({ markedSimList: allSimData, allChecked: true });
      } else {
        this.setState({ markedSimList: [], allChecked: false });
        markedSimList.forEach((simItem) => {
          simItem.isZipCodeIneligible = false;
        });
      }
    }
  };

  //Tags Method
  // addTagsHandle = addTags => {
  //   this.setState({ addTags });
  //   const { markedSimList } = this.state
  //   if(_.size(this.state.markedSimList) > 0) {
  //     let tagsArr = []
  //     _.forEach(markedSimList, (item,index) => {
  //       if(item.tags && item.tags !== '') {
  //        let arr = item.tags.split(',')
  //        tagsArr.push(...arr)
  //       }
  //     })
  //    let uniquesTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i)
  //    let originalTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i)
  //    this.setState({ selectedListTags: uniquesTags, originalSelectedTags: originalTags, isApplyButton: false })
  //   } else {
  //     this.setState({ selectedListTags: [], originalSelectedTags: [], isApplyButton: false })
  //   }
  // }

  // onTagChange = (tag) => {
  //   let selectedListTags = this.state.selectedListTags
  //   let originalSelectedTags = this.state.originalSelectedTags
  //   if(!selectedListTags.includes(tag)) {
  //     selectedListTags.push(tag)
  //   } else {
  //     let index = selectedListTags.findIndex(t => t === tag)
  //     selectedListTags.splice(index,1)
  //   }
  //   this.setState({ selectedListTags },() => {
  //     let diffData = _.differenceWith(selectedListTags, originalSelectedTags,(o1, o2) => { return (o1 === o2)})
  //     if(diffData.length > 0) {
  //       this.setState({ isApplyButton: true })
  //     } else if(diffData.length === 0 && selectedListTags.length === originalSelectedTags.length) {
  //       this.setState({ isApplyButton: false })
  //     } else if(diffData.length === 0 && selectedListTags.length !== originalSelectedTags.length) {
  //       this.setState({ isApplyButton: true })
  //     } else {
  //       this.setState({ isApplyButton: false })
  //     }
  //     this.forceUpdate()
  //   })
  // }

  // applyTags = async () => {
  //   const { markedSimList, selectedListTags } = this.state
  //   this.setState({ updateLoading: true })
  //   let applyTags = selectedListTags.join(',')
  //   let obj = {}
  //   obj.sims = []
  //   obj.sims = markedSimList.map((sim) => ({ id: sim.id, tags: applyTags }))
  //   try{
  //     const {
  //       value: { success }
  //     } =  await this.props.updateTag(obj);
  //     if(success) {
  //       this.setState({ addTags: false, updateLoading: false },() => {
  //         this.getSimsOnTabChange()
  //       })
  //     }
  //   } catch(err) {
  //     this.setState({ updateLoading: false, addTags: false, errorMessage: _.get(err,'message','Some thing went wrong...')})
  //   }
  // }

  addClientOnSim = (index) => {
    let allSims = this.state.sims;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createClientModal();
    });
  };

  createClientModal() {
    const { markedSimList } = this.state;
    let clientArray = [];
    if (markedSimList.length) {
      for (const i of markedSimList) {
        if (!clientArray.includes(i.clientname) && i.clientname) {
          clientArray.push(i.clientname);
        }
      }
    }
    if (clientArray) {
      this.setState({
        isCreateClientModal: true,
        newClientSize: false,
        selectedClient: clientArray,
      });
    } else {
      this.setState({
        isCreateClientModal: true,
        newClientSize: false,
        selectedClient: [],
      });
    }
  }

  handleClientSelect = (client) => {
    this.setState({ selectedClient: [] }, () => {
      setTimeout(() => {
        if (client.trim().length !== 0) {
          this.setState({ selectedClient: [client] });
        }
      }, 300);
    });
  };

  handleClientChange = (clientsArr) => {
    if (clientsArr.length > 0) {
      let index = clientsArr.length - 1;
      let clientName = clientsArr[index];
      let newClientSize = false;
      if (clientName.trim().length < 3) {
        newClientSize = true;
      }
      if (newClientSize) {
        this.setState({ newClientSize: true });
      } else {
        this.setState({ selectedClient: [clientName], newClientSize: false });
      }
    } else {
      this.setState({ selectedClient: [], newClientSize: false });
    }
  };

  handleSearchTags(e) {
    let searchTags = e.target.value;
    this.setState({ searchTags }, () => {
      this.getAllTags(searchTags);
    });
  }

  handleSearch(e) {
    let searchSims = e.target.value;
    searchSims = searchSims.trim();
    const isSims = searchSims.includes(',');
    if (isSims) {
      const lastChar = searchSims.charAt(searchSims.length - 1);
      if (lastChar === ',') {
        searchSims = searchSims.slice(0, -1);
      }
      searchSims =
        searchSims && searchSims.trim() !== '' ? searchSims.split(',') : [];
    }
    this.setState({ searchSims, keyword: searchSims, page: 1 }, () => {
      this.getSimsOnTabChange();
    });
  }

  saveClient = async () => {
    const { selectedClient, markedSimList, clients } = this.state;
    let obj = {};

    let clientIndex;
    if (selectedClient.length) {
      clientIndex = clients.findIndex(
        (client) => client.fullname === selectedClient[0]
      );
    } else {
      clientIndex = -2;
    }
    if (clientIndex === -2) {
      let checkClient = markedSimList.some((s) => s.clientname);
      if (checkClient) {
        try {
          let obj = {
            ids: markedSimList.map((item) => item.id),
          };
          const {
            value: { success },
          } = await this.props.removeClient(obj);
          if (success) {
            this.setState(
              {
                isCreateClientModal: false,
                selectedClient: [],
                updateLoading: false,
              },
              () => {
                this.getSimsOnTabChange();
              }
            );
          }
        } catch (error) {
          //error occured while removing client
        }
      }
    } else {
      if (clientIndex !== -1) {
        obj = {
          simIds: markedSimList.map((sim) => sim.id),
          clientId: clients[clientIndex].id,
        };
      } else {
        obj = {
          simIds: markedSimList.map((sim) => sim.id),
          clientId: 0,
          name: selectedClient[0],
        };
      }
      this.setState({ updateLoading: true });
      try {
        const {
          value: { success, client },
        } = await this.props.addClient(obj);
        if (success) {
          this.setState(
            {
              isCreateClientModal: false,
              selectedClient: [],
              updateLoading: false,
            },
            () => {
              this.updateClientLocal(client);
              this.getSimsOnTabChange();
            }
          );
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  updateClientLocal = (client) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.clientId = client.clientId;
          record.clientname = client.clientname;
        }
        return mark;
      });
    });
    let allClients = this.state.clients;
    let obj = {
      id: client.clientId,
      fullname: client.clientname,
    };
    if (allClients.findIndex((item) => item.id === client.clientId) === -1) {
      allClients.push(obj);
    }
    this.setState({ sims: allSims, clients: allClients });
  };

  addTagOnSim = (index) => {
    let allSims = this.state.sims;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createTagsModal();
    });
  };

  createTagsModal() {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      let tagsArr = [];
      _.forEach(markedSimList, (item, index) => {
        if (item.tags && item.tags !== '') {
          let arr = item.tags.split(',');
          tagsArr.push(...arr);
        }
      });
      let uniquesTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      let originalTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      this.setState({
        isCreateTagModal: true,
        requireName: false,
        newTagSize: false,
        newTag: '',
        selectedListTags: uniquesTags,
        originalSelectedTags: originalTags,
      });
    } else {
      this.setState({ selectedListTags: [], originalSelectedTags: [] });
    }
  }

  handleTagChange = (tags) => {
    this.setState({ isTagChanged: true });
    if (tags.length > 0) {
      let tagSizeError = false;
      _.forEach(tags, (tag) => {
        if (tag.trim().length < 3) {
          tagSizeError = true;
        }
      });
      if (tagSizeError) {
        this.setState({ newTagSize: true });
      } else {
        this.setState({ newTagSize: false, newTag: tags });
      }
    } else {
      this.setState({ newTag: [] });
    }
  };

  saveTag = async () => {
    const { newTag, markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let applyTags = newTag ? newTag.join(',') : '';
    let obj = {};
    obj.sims = [];
    obj.sims = markedSimList.map((sim) => ({ id: sim.id, tags: applyTags }));
    try {
      const {
        value: { success },
      } = await this.props.updateTag(obj);
      if (success) {
        this.setState(
          {
            isCreateTagModal: false,
            isTagChanged: false,
            newTag: '',
            updateLoading: false,
          },
          async () => {
            this.updateTagsLocal(applyTags);
            const { value } = await this.props.fetchTagsByType({
              type: 'all',
              simType: 'fixed',
            });
            if (value && _.get(value, 'data', []).length > 0) {
              this.setState({ filterTags: _.get(value, 'data', []) });
            }
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  updateTagsLocal = (applyTags) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.tags = applyTags;
        }
        return mark;
      });
    });
    this.setState({ sims: allSims });
  };

  handleZipcodeChange = async (index, e) => {
    try {
      let allSims = this.state.sims;
      this.state.markedSimList.forEach(function (s) {
        if (parseInt(allSims[index].id) === parseInt(s.id)) {
          s['zipcodeOnChange'] = e.target.value;
          s['isZipcodeChanged'] = true;
        }
      });
      allSims[index].zipcode = e.target.value;
      let selectedSims = allSims[index];
      selectedSims.forEach(function (s) {
        if (parseInt(allSims[index].id) === parseInt(s.id)) {
          s.zipcode = e.target.value;
        }
      });
      this.setState({ sims: allSims, markedSimList: selectedSims });
    } catch (err) {
      console.log(err);
    }
  };

  handelSimNameChange = async (index, e) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    if (e.target.value) {
      let obj = {};
      obj.simname = e.target.value;
      obj.ids = [allSims[index].id];
      await this.props.updateSimName(obj);
    }
    allSims[index].simname = e.target.value;
    selectedSims.forEach(function (s) {
      if (parseInt(allSims[index].id) === parseInt(s.id)) {
        s.simname = e.target.value;
      }
    });
    this.setState({ sims: allSims, markedSimList: selectedSims });
  };

  //Export Sim Data
  exportData = async () => {
    const {
      activeTab,
      page,
      sortBy,
      sortOrder,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
    } = this.state;
    this.setState({ isExportSimsData: true, isExportLoading: true });
    let obj = {
      // type: activeTab,
      page,
      sortBy,
      sortOrder,
      limit: 0,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      isExport: true,
      simType: 'fixed',
    };
    if (activeTab === 'fixed-active') {
      obj.type = 'active';
    } else if (activeTab === 'fixed-inactive') {
      obj.type = 'inactive';
    } else if (activeTab === 'fixed-paused') {
      obj.type = 'paused';
    } else if (activeTab === 'fixed-deactive') {
      obj.type = 'deactive';
    }
    if (activeTab === 'fixed-activationInProgress') {
      obj.type = 'activationInProgress';
    }
    try {
      // Direct call without await because no dependecies of response.
      this.props.exportSimsData(obj);

      // Request sent notifications.
      setTimeout(() => {
        this.setState({ isExportSimsData: false });
        notification['success']({
          message: 'Sims Export',
          description:
            'Your export is being prepared, check the Notifications section in a few minutes',
        });
      }, 800);

      // Keep Export disabled for 3m
      setTimeout(() => {
        this.setState({ isExportLoading: false });
      }, 180000);
    } catch (err) {
      console.log(err);
      this.setState({
        isExportSimsData: false,
        isExportLoading: false,
        errorMessage: _.get(err, 'message', 'Export failed.'),
      });
    }
  };

  // SIM Detail Page
  simDetails = (iccid) => {
    localStorage.setItem('prevTab', 'fixed');
    this.props.history.push(`/detail/${iccid}`);
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  sortByKey = (key) => {
    const { sortOrder, sortBy } = this.state;
    if (sortOrder === 'asc' || sortBy !== key) {
      this.setState({ sortOrder: 'desc', sortBy: key }, () => {
        this.getSimsOnTabChange();
      });
    } else {
      this.setState({ sortOrder: 'asc', sortBy: key }, () => {
        this.getSimsOnTabChange();
      });
    }
  };

  submitPortIn = async () => {
    const {
      markedSimList,
      portInPhoneNumber,
      selectedCarrier,
      carrierACNumber,
      carrierAssignPassword,
      zipcodePortIn,
      street1,
      city,
      state,
      zip,
      altContactNumber,
      selectedSimPlan,
    } = this.state;
    let noError = true;
    if (portInPhoneNumber === '') {
      noError = false;
      this.setState({ requirePortInPhoneNumber: true });
    }
    if (selectedCarrier === '') {
      noError = false;
      this.setState({ carrierError: true });
    }
    if (carrierACNumber === '') {
      noError = false;
      this.setState({ requireCarrierACNumber: true });
    }
    if (carrierAssignPassword === '') {
      noError = false;
      this.setState({ requireCarrierAssignPassword: true });
    }
    if (street1 === '') {
      noError = false;
      this.setState({ street1Error: true });
    }
    if (city === '') {
      noError = false;
      this.setState({ cityError: true });
    }
    if (state === '') {
      noError = false;
      this.setState({ stateError: true });
    }
    if (zip === '') {
      noError = false;
      this.setState({ zipError: true });
    }
    if (zipcodePortIn === '') {
      noError = false;
      this.setState({ requireZipcodePortIn: true });
    }
    if (altContactNumber === '') {
      noError = false;
      this.setState({ requireAltContactNumber: true });
    }
    if (_.isEmpty(selectedSimPlan)) {
      noError = false;
      this.setState({ planError: true });
    }

    if (noError) {
      this.setState({ updateLoading: true });
      let obj = {
        msisdn: portInPhoneNumber.replace(/([^0-9]+)/gi, ''),
        carrier: selectedCarrier,
        accountNumber: carrierACNumber,
        accountPassword: carrierAssignPassword,
        zipcode: zipcodePortIn,
        planId: selectedSimPlan.id,
        simcardId: markedSimList[0].id,
        alternateContact: altContactNumber.replace(/([^0-9]+)/gi, ''),
        simName: markedSimList[0].simname,
        simType: 'fixed',
      };

      try {
        const {
          value: { success },
        } = await this.props.simPortInActivation(obj);
        if (success) {
          this.setState({ portSimModal: false, updateLoading: false }, () => {
            this.getSimsOnTabChange();
          });
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  cancelSimPortIn = async (item) => {
    try {
      let obj = {
        simId: item.id,
      };
      const {
        value: { success },
      } = await this.props.simPortInCancel(obj);
      if (success) {
        this.setState({ updateLoading: false }, () => {
          this.getSimsOnTabChange();
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      let titleText = 'Cancel PortIn Request failed';
      let descriptionText = `Cancel request for the on-going port-in request failed due to "${err.message}".`;
      this.setState({
        updateLoading: false,
        openCancelPortInFailureModal: true,
        titleText: titleText,
        descriptionText: descriptionText,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  handleSelectPlan(planType, e) {
    const { fixedPlans, simPlansData } = this.state;
    if (planType === 'spr') {
      this.setState({
        selectedSimPlan: simPlansData[e.target.value],
        planError: false,
        fixedPlanIndex: '',
        sprPlanIndex: e.target.value,
        selectedFixedSimPlan: {},
      });
    } else {
      this.setState({
        selectedSimPlan: fixedPlans[e.target.value],
        planError: false,
        fixedPlanIndex: e.target.value,
        sprPlanIndex: '',
      });
    }
  }

  //Call Reactivate Sim API From Deactivate Tab
  reactivateSim = async () => {
    const { selectedSimPlan, userData, markedSimList, sims } = this.state;

    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ reactivatePlanError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (!error) {
        this.setState({ reactivateLoading: true });
        let obj = {};
        if (_.get(userData, 'consumeOfferAPI', false)) {
          obj.offerId = selectedSimPlan.offerId;
          obj.title = selectedSimPlan.title;
        } else {
          obj.planid = selectedSimPlan.id;
        }
        obj.sims = markedSimList.map((sim) => sim.id);
        try {
          const {
            value: { success },
          } = await this.props.reActivateSim(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            _.forEach(markedSimList, (item) => {
              let index = sims.findIndex((sim) => item.id === sim.id);
              if (index > -1) {
                let obj = sims[index];
                obj.status = 12;
              }
            });
            this.setState(
              {
                sims,
                reactivateSimModal: false,
                reactivateLoading: false,
                titleText,
                descriptionText,
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  markedSimList: [],
                  allChecked: false,
                  keyword: '',
                });
              }
            );
          }
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            reactivateLoading: false,
            openThankYouModal: true,
            reactivateSimModal: false,
            errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: true,
        });
      }
    }
  };

  handelUnpausePlanChange(e) {
    const { fixedPlans } = this.state;
    if (e.target.value) {
      this.setState({
        selectedSimPlan: fixedPlans[e.target.value],
        planValue: e.target.value,
      });
    } else {
      this.setState({ selectedSimPlan: {}, planValue: e.target.value });
    }
  }

  unPauseSim = async () => {
    const { markedSimList, sims, selectedSimPlan } = this.state;
    const { user } = this.props;
    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ unPauseSimPlanError: true });
    } else {
      this.setState({ updateLoading: true, unPauseSimPlanError: false });
      let titleText = 'Unpause Info';
      let descriptionText = '';
      let obj = {};

      obj.sims = markedSimList.map((sim) => sim.id);

      if (_.get(user, 'consumeOfferAPI', false)) {
        obj.offerId = selectedSimPlan.offerId;
        obj.title = selectedSimPlan.title;
      } else {
        obj.planid = selectedSimPlan.id;
      }
      try {
        const {
          value: { success, message },
        } = await this.props.unPauseSim(obj);
        if (success) {
          titleText = 'Thank You';
          descriptionText =
            'Your SIM card(s) are processing. Check the notifications icon for updates.';
          _.forEach(markedSimList, (item) => {
            let index = sims.findIndex((sim) => item.id === sim.id);
            if (index > -1) {
              let obj = sims[index];
              obj.status = 12;
            }
          });
          this.setState(
            {
              sims,
              isUnpauseModal: false,
              errorMessage: '',
              updateLoading: false,
              titleText,
              descriptionText,
              planValue: '',
            },
            () => {
              this.setState({
                openThankYouModal: true,
                isIldIrEnabled: false,
                isILDEnabled: false,
                markedSimList: [],
                allChecked: false,
                selectedAction: '',
                isIREnabled: false,
                keyword: '',
              });
            }
          );
        } else {
          this.setState({
            updateLoading: false,
            errorMessage: message ? message : 'Some thing went wrong...',
          });
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          selectedAction: '',
          updateLoading: false,
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  setSimsIntoNeverBeen = async () => {
    const { markedSimList } = this.state;
    const simIds = markedSimList.map((item) => item.id);
    this.setState({ updateLoading: true });
    let obj = {
      ids: simIds,
    };
    try {
      const {
        value: { success },
      } = await this.props.setSimsIntoNeverBeen(obj);
      if (success) {
        this.setState({
          updateLoading: false,
          keyword: '',
          markedSimList: [],
          isMoveBackModal: false,
        });
        this.getSimsOnTabChange();
      }
    } catch (err) {
      this.setState({
        updateLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };
  openMoveBackModal = () => {
    this.setState({ isMoveBackModal: true });
  };

  searchByChange = (e) => {
    this.setState({ searchBy: e.target.value }, () => {
      if (this.state.keyword) {
        this.getSimsOnTabChange();
      }
    });
  };

  checkAllZipcodeEligibility = async (data) => {
    const promises = data.map((item) => {
      item.isZipCodeIneligible = false;
  
      return this.props.updateSimName({
        zipcode:
          item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
            ? item.zipcodeOnChange
            : item.zipcode,
        ids: [item.id],
      })
      .then(() => {
        item.isZipCodeIneligible = false;
      })
      .catch((error) => {
        item.isZipCodeIneligible = true;
        throw error;
      });
    });
  
    const results = await Promise.allSettled(promises);
  
    if (results.some(result => result.status === 'rejected')) {
      return false;
    }
  
    return true;
  };

  render() {
    const {
      sims,
      total,
      page,
      startDate,
      endDate,
      activeTab,
      limit,
      isFiltervisible,
      isDateFilter,
      isTagsFilter,
      isClientFilter,
      filterTags,
      searchFilterTags,
      searchClientFilter,
      iAuthorization,
      simPlansData,
      fixedPlans,
      selectedSimPlan,
      markedSimList,
      selectedListTags,
      userData,
      sortBy,
      sortOrder,
      clients,
      selectedClient,
      planValue,
      searchBy,
    } = this.state;
    let filterCount =
      _.size(searchFilterTags) +
      _.size(searchClientFilter) +
      (this.state.byDate ? 1 : 0);

    let moveBackButtonDisabl = true;
    if (markedSimList.length > 0) {
      const moveBackFilter = markedSimList.filter(
        (item) =>
          !item.failedReason ||
          (item.failedReason !== 'INVALID_ZIP' &&
            item.failedReason !== 'API_SERVER_ERROR')
      );
      moveBackButtonDisabl = moveBackFilter.length > 0 ? true : false;
    }
    // let isAddClientDisable = markedSimList.filter(item => (item.clientId !== 0 && item.clientname !== ''))
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          setSortByKeys={this.setSortByKeys}
          onTabChange={this.onTabChange}
        />
        <TopNavigation {...this.props} onTabChange={this.onTabChange} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-3">
              <h2 className="heading-title">
                <SimsFixedColorIcon />
                Your Fixed SIMs
              </h2>
            </div>
            <div className="col-lg-9 text-sm-right">
              <button
                onClick={() => this.getSimsOnTabChange('fixed')}
                className="btn btn-dark w-150 btn-refresh mr-3"
              >
                <RefreshIcon className="mr-2" /> Refresh
              </button>
              <Popover
                content={
                  <div className="filter">
                    <div className="filter__header">
                      <button
                        disabled={filterCount > 0 ? false : true}
                        onClick={this.onClearFilter}
                        className="btn btn-secondary btn-sm"
                      >
                        Clear
                      </button>
                      <div className="filter__title">Filters</div>
                      <button
                        onClick={() =>
                          this.setState({ isFiltervisible: false })
                        }
                        className="btn btn-dark btn-sm"
                      >
                        Close
                      </button>
                    </div>
                    <div className="filter__body">
                      <ul className="filter__list">
                        {/* Client Filter */}
                        {_.size(clients) > 0 && (
                          <li>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                className="custom-checkbox--input unCheckAll"
                                type="checkbox"
                                name="isClientFilter"
                                value={isClientFilter}
                                checked={isClientFilter}
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      isClientFilter: !isClientFilter,
                                      searchClientFilter: [],
                                    },
                                    () => {
                                      if (!this.state.isClientFilter) {
                                        this.getSimsOnTabChange();
                                      }
                                    }
                                  )
                                }
                              />
                              Clients
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        )}
                        {isClientFilter && (
                          <ul className="filter__list__inner">
                            {_.size(clients) > 0 &&
                              clients.map((client, index) => {
                                return (
                                  <li key={index}>
                                    <label className="custom-checkbox custom-checkbox-line">
                                      <input
                                        className="custom-checkbox--input unCheckAll"
                                        type="checkbox"
                                        name="client"
                                        checked={
                                          searchClientFilter.findIndex(
                                            (cl) => cl.id === client.id
                                          ) !== -1
                                        }
                                        onChange={this.onClientFilter.bind(
                                          this,
                                          client
                                        )}
                                      />
                                      {client.fullname}
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        )}

                        {/* Tags */}
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isTagsFilter"
                              value={isTagsFilter}
                              checked={isTagsFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isTagsFilter: !isTagsFilter,
                                    tags: '',
                                    searchFilterTags: [],
                                  },
                                  () => {
                                    if (!this.state.isTagsFilter) {
                                      this.getSimsOnTabChange();
                                    }
                                  }
                                )
                              }
                            />
                            Tags
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {isTagsFilter && (
                          <>
                            <ul className="filter__list__inner">
                              <li>
                                <div className="">
                                  <DebounceInput
                                    minLength={2}
                                    debounceTimeout={500}
                                    value={this.state.searchTags}
                                    className="form-control"
                                    placeholder="Search Tags"
                                    name="searchTags"
                                    onChange={this.handleSearchTags.bind(this)}
                                    required
                                  />
                                </div>
                              </li>
                              {_.size(filterTags) > 0 &&
                                filterTags.map((tag, index) => {
                                  return (
                                    <li key={index}>
                                      <label className="custom-checkbox custom-checkbox-line">
                                        <input
                                          className="custom-checkbox--input unCheckAll"
                                          type="checkbox"
                                          name="tag"
                                          checked={
                                            this.state.searchFilterTags.indexOf(
                                              tag
                                            ) !== -1
                                          }
                                          onChange={this.onTagsFilter.bind(
                                            this,
                                            tag
                                          )}
                                        />
                                        {tag}
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        )}
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isDateFilter"
                              checked={isDateFilter}
                              value={isDateFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isDateFilter: !isDateFilter,
                                    startDate: '',
                                    endDate: '',
                                  },
                                  () => {
                                    if (!this.state.isDateFilter) {
                                      this.setState({ byDate: false }, () => {
                                        this.getSimsOnTabChange();
                                      });
                                    }
                                  }
                                )
                              }
                            />
                            Created Date
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {isDateFilter && (
                          <ul className="filter__list__inner">
                            <div className="row mt-3 mb-3">
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'start'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    showToday={false}
                                    allowClear={false}
                                    suffixIcon={<CalenderIcon />}
                                    // value={this.state.startDate !== '' && this.state.startDate}
                                  />
                                  <label className="datepicker-textfield-label">
                                    From
                                  </label>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    disabledDate={this.disabledDate}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'end'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    showToday={false}
                                    allowClear={false}
                                    suffixIcon={<CalenderIcon />}
                                    disabled={
                                      this.state.startDate !== '' ? false : true
                                    }
                                    value={
                                      this.state.endDate !== ''
                                        ? this.state.endDate
                                        : null
                                    }
                                  />
                                  <label className="datepicker-textfield-label">
                                    To
                                  </label>
                                  {/* <div className="invalid-feedback">for validation masseges</div> */}
                                </div>
                              </div>
                            </div>
                          </ul>
                        )}
                      </ul>
                    </div>
                  </div>
                }
                placement="bottomRight"
                className="filter"
                overlayClassName="filter__overlay"
                // title="Title"
                trigger="click"
                visible={isFiltervisible}
                onVisibleChange={this.filterVisible}
              >
                <button className="btn btn-dark w-150 btn-filter">
                  <FilterIcon className="filter-icon" />
                  Filter {filterCount > 0 && '(' + filterCount + ')'}
                </button>
              </Popover>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-8">
              {activeTab === 'fixed-active' &&
              ((iAuthorization && iAuthorization !== '') ||
                (userData &&
                  (userData.role === 'superadmin' ||
                    userData.role === 'admin' ||
                    userData.role === 'sysops' ||
                    userData.role === 'spr_partner' ||
                    isSubUser(userData)))) ? (
                <Link
                  className="btn btn-purple-link"
                  to={'/fixed-sims-usage-summary'}
                >
                  SIMs Usage Summary
                </Link>
              ) : (
                ''
              )}
              {iAuthorization &&
              iAuthorization !== '' &&
              userData &&
              userData.role !== 'sub_user' ? (
                <Link
                  to={'/add-fixed-sim/upload-csv'}
                  className="btn btn-purple-link"
                >
                  Add Non Active SIMs
                </Link>
              ) : (
                ''
              )}
              {/* {((iAuthorization && iAuthorization !== "") || (userData && userData.role === "spr_partner" && (parseInt(userData.isILDEnabled) === 1 || parseInt(userData.isIREnabled) === 1))) ? (
                <Link to={'/active-fixed-sims/bulk-ild'} className="btn btn-purple-link">Manage ILD/IR </Link>
              ) : (
                ""
              )} */}
              {userData &&
              (userData.role === 'superadmin' ||
                userData.role === 'admin' ||
                userData.role === 'sysops' ||
                userData.role === 'spr_partner' ||
                (isSubUser(userData) &&
                  checkPermission(userData, '/bulk-activation'))) ? (
                <Link
                  to={'/fixed-bulk-activation-sims'}
                  className="btn btn-purple-link"
                >
                  Bulk Activate SIMs
                </Link>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-4 text-right">
              <button
                disabled={_.size(sims) === 0 || this.state.isExportLoading}
                onClick={this.exportData.bind(this)}
                className="btn btn-link btn-export"
              >
                <ExportIcon className="export-icon" />
                {this.state.isExportSimsData ? 'Please wait...' : 'Export'}
              </button>
            </div>
          </div>
          {/* <div className="mobile-select d-lg-none">
            <select
              className="form-control custom-select"
              value={activeTab}
              onChange={(e) => this.onTabChange(e.target.value)}
            >
              <option value="active">Active SIMs ({_.get(counts,'active',0)})</option>
              <option value="activationInProgress">Activations In-Progress ({_.get(counts,'activationInProgress',0)})</option>
              <option value="inactive">Never been Activated ({_.get(counts,'inactive',0)})</option>
              <option value="paused">Paused SIMs ({_.get(counts,'paused',0)})</option>
              <option value="deactive">Deactivated SIMs ({_.get(counts,'deactive',0)})</option>
            </select>
          </div> */}
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              {/* <div className="tabs__control">
                {checkPermission(userData, "/active-sims") && (
                  <button onClick={this.onTabChange.bind(this,'active')} className={activeTab === 'active' ? 'btn active' : 'btn'}>Active SIMs <span>{_.get(counts,'active',0)} SIMs</span></button>
                )}
                {checkPermission(userData, "/activation-in-progress") && (
                  <button onClick={this.onTabChange.bind(this,'activationInProgress')} className={activeTab === 'activationInProgress' ? 'btn active' : 'btn'}>Activations In-Progress <span>{_.get(counts,'activationInProgress',0)} SIMs</span></button>
                )}
                {checkPermission(userData, "/never-been-activated") && (
                  <button onClick={this.onTabChange.bind(this,'inactive')} className={activeTab === 'inactive' ? 'btn active' : 'btn'}>Never been Activated <span>{_.get(counts,'inactive',0)} SIMs</span></button>
                )}
                {checkPermission(userData, "/paused-sims") && (
                  <button onClick={this.onTabChange.bind(this,'paused')} className={activeTab === 'paused' ? 'btn active' : 'btn'}>Paused SIMs<span>{_.get(counts,'paused',0)} SIMs</span></button>
                )}
                {checkPermission(userData, "/deactivated-sims") && (
                  <button onClick={this.onTabChange.bind(this,'deactive')} className={activeTab === 'deactive' ? 'btn active' : 'btn'}>Deactivated SIMs <span>{_.get(counts,'deactive',0)} SIMs</span></button>
                )}
              </div> */}
              <div className="d-flex align-items-center mt-3">
                <div className="search-by-select">
                  <span className="search-by-select-label">Search by:</span>
                  <select
                    className="form-control custom-select"
                    name="searchBy"
                    value={this.state.searchBy}
                    onChange={this.searchByChange.bind(this)}
                  >
                    <option value={'simname'}>SIM Name</option>
                    <option value={'iccid'}>ICCID</option>
                    <option value={'userId'}>User Id</option>
                    <option value={'msisdn'}>Phone Number</option>
                  </select>
                </div>

                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  value={this.state.keyword}
                  className="form-control"
                  placeholder={`Search by ${
                    searchBy === 'simname'
                      ? 'SIM name'
                      : searchBy === 'iccid'
                      ? 'iccid'
                      : searchBy === 'userId'
                      ? 'user id'
                      : searchBy === 'tags'
                      ? 'tags'
                      : 'Phone Number'
                  }`}
                  name="searchUser"
                  onChange={this.handleSearch.bind(this)}
                  required
                />
              </div>
            </div>
          </div>
          {(_.size(searchClientFilter) > 0 ||
            _.size(searchFilterTags) > 0 ||
            this.state.byDate) && (
            <ul className="filter__list__flitered mt-3">
              {searchFilterTags.map((item, index) => {
                return (
                  <li key={index}>
                    {item}{' '}
                    <span onClick={this.removeFilter.bind(this, index)}>
                      <CloseIcon className="close-icon" />
                    </span>
                  </li>
                );
              })}
              {searchClientFilter.map((item, index) => {
                return (
                  <li key={index}>
                    {item.fullname}{' '}
                    <span onClick={this.removeClientFilter.bind(this, index)}>
                      <CloseIcon className="close-icon" />
                    </span>
                  </li>
                );
              })}
              {this.state.byDate && (
                <li>
                  {moment(startDate).format('MMMM DD, YYYY') +
                    ' - ' +
                    moment(endDate).format('MMMM DD, YYYY')}
                  <span onClick={this.removeFilter.bind(this, -10)}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
            </ul>
          )}
          <div className="row">
            <div className="col-md-12">
              {_.size(sims) > 0 && !this.state.isLoading && (
                <div className="all-filters">
                  <div className="select-all">
                    <label className="custom-checkbox-only">
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"
                        name="duplex"
                        checked={this.state.allChecked}
                        onChange={(e) => this.onCheck({}, 'All')}
                      />
                      <span className="checkmark-only"></span>
                    </label>
                  </div>

                  {/* select Action */}
                  <div className="sims-action">
                    {activeTab === 'fixed-inactive' && (
                      <>
                        <button
                          type="button"
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => {
                            this.selectActionHandle('activate');
                          }}
                          className="btn btn-link"
                        >
                          Activate{' '}
                        </button>
                        {/* <button disabled={_.size(markedSimList) !== 1} onClick={() => this.selectActionHandle('portIn')} className="btn btn-link">Activate w/ Port-in </button> */}
                      </>
                    )}
                    {activeTab === 'fixed-active' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('pause')}
                          className="btn btn-link"
                        >
                          Pause{' '}
                        </button>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('inactive')}
                          className="btn btn-link"
                        >
                          Deactivate{' '}
                        </button>
                      </>
                    )}
                    <button
                      disabled={_.size(markedSimList) === 0}
                      onClick={() => this.selectActionHandle('setsimname')}
                      className="btn btn-link"
                    >
                      Set Device Name{' '}
                    </button>
                    <button
                      disabled={_.size(markedSimList) === 0}
                      onClick={this.createTagsModal}
                      className="btn btn-link"
                    >
                      Add Tag{' '}
                    </button>

                    {(activeTab === 'fixed-active' ||
                      activeTab === 'fixed-inactive') &&
                      (isSPRPartner(userData) ||
                        (isSubUser(userData) &&
                          checkPermission(userData, '/add-client'))) && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={this.createClientModal}
                          className="btn btn-link"
                        >
                          Add Client
                        </button>
                      )}
                    {activeTab === 'fixed-deactive' && (
                      <button
                        disabled={_.size(markedSimList) === 0}
                        onClick={() => this.selectActionHandle('reactivate')}
                        className="btn btn-link"
                      >
                        Reactivate
                      </button>
                    )}
                    {activeTab === 'fixed-active' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('changePlan')}
                          className="btn btn-link"
                        >
                          Change Plan{' '}
                        </button>
                      </>
                    )}
                    {activeTab === 'fixed-paused' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('unpause')}
                          className="btn btn-link"
                        >
                          Unpause{' '}
                        </button>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('inactive')}
                          className="btn btn-link"
                        >
                          Deactivate{' '}
                        </button>
                      </>
                    )}
                    {((iAuthorization && iAuthorization !== '') ||
                      (userData &&
                        (userData.role === 'superadmin' ||
                          userData.role === 'admin' ||
                          userData.role === 'sysops'))) &&
                      activeTab === 'fixed-inactive' && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('delete')}
                          className="btn btn-delete"
                        >
                          Delete
                        </button>
                      )}
                    {activeTab === 'fixed-activationInProgress' && (
                      <button
                        disabled={moveBackButtonDisabl}
                        onClick={() => this.openMoveBackModal()}
                        className="btn btn-link"
                      >
                        Move to Never Been Activated{' '}
                      </button>
                    )}
                  </div>
                  <div className="filter-page-number ml-auto">
                    {page > 1 ? (page - 1) * limit + 1 : 1}-
                    {limit * page < total ? limit * page : total} of {total}
                  </div>
                </div>
              )}
              {/* LISTS */}
              <div className="row">
                <div className="col-md-12">
                  {this.state.isLoading ? (
                    <div className="blank__table__card mt-3">
                      <div className="blank__table__card__inner">
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: '#000' }}
                              spin
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : _.size(sims) > 0 ? (
                    <SimList
                      user={userData}
                      iAuthorization={iAuthorization}
                      activeTab={activeTab && activeTab.replace('fixed-', '')}
                      data={sims}
                      totalPagination={total}
                      page={page}
                      limit={limit}
                      onPageChange={this.onPageChanged}
                      markedSimList={markedSimList}
                      onCheck={this.onCheck.bind(this)}
                      handleZipcodeChange={this.handleZipcodeChange}
                      handelSimNameChange={this.handelSimNameChange}
                      singleSimAction={this.singleSimAction}
                      cancelSimPortIn={this.cancelSimPortIn}
                      simDetails={this.simDetails}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      sortByKey={this.sortByKey}
                      addTagOnSim={this.addTagOnSim}
                      addClientOnSim={this.addClientOnSim}
                      match={this.props.match}
                      simPlansData={fixedPlans}
                    />
                  ) : (
                    <div className="blank__table__card mt-3">
                      <div className="blank__table__card__inner">
                        No
                        {activeTab === 'active' && ' Active '}
                        {activeTab === 'activationInProgress' &&
                          ' Activations In-Progress '}
                        {activeTab === 'inactive' && ' Never been Activated '}
                        {activeTab === 'paused' && ' Paused '}
                        {activeTab === 'deactive' && ' Deactivated '}
                        SIMs
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* CREATE CLIENT MODAL */}
        <ClientModal
          isOpen={this.state.isCreateClientModal}
          onRequestClose={this.closeModal}
selectedSimPlan={selectedSimPlan}
          selectedClient={selectedClient}
          clients={clients}
          onSelectionChange={this.handleClientChange}
          onSelection={this.handleClientSelect}
          newClientSize={this.state.newClientSize}
          updateLoading={this.state.updateLoading}
          saveClient={this.saveClient.bind(this)}
        />

        {/* CREATE TAG MODAL */}
        <ReactModal
          isOpen={this.state.isCreateTagModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Create tags"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Add Tag </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <Select
                  autoFocus
                  mode="tags"
                  size="large"
                  bordered={false}
                  // maxTagTextLength={5}
                  defaultValue={selectedListTags}
                  className="multiple__tags__textfield"
                  dropdownClassName="multiple__tags__textfield__overlay"
                  // dropdownStyle={{display: "none"}}
                  style={{ width: '100%' }}
                  placeholder="Tag Name"
                  onChange={this.handleTagChange}
                >
                  {_.size(filterTags) > 0 &&
                    filterTags.map((tag, index) => {
                      return (
                        <Option key={index} value={tag}>
                          {tag}
                        </Option>
                      );
                    })}
                </Select>
                {/* { this.state.requireName &&
                  <div className="invalid-feedback">Name is required</div>
                } */}
                {this.state.newTagSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={
                  this.state.updateLoading ||
                  this.state.newTagSize ||
                  !this.state.isTagChanged
                }
                type="button"
                onClick={this.saveTag.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Set Sim Name MODAL */}
        <ReactModal
          isOpen={this.state.isUpdateSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Set SIMs Name"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Set Device Name </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="newSimName"
                  value={this.state.newSimName}
                  onChange={(e) =>
                    this.setState({
                      newSimName: e.target.value,
                      requireName: false,
                      newSimNameSize: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Device Name</label>
                {this.state.requireName && (
                  <div className="invalid-feedback">
                    Device Name is required
                  </div>
                )}
                {this.state.newSimNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.updateSimName.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Deactivate & Pause MODAL */}
        <ReactModal
          isOpen={this.state.isActionModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">
                {this.state.selectedAction === 'pause'
                  ? 'Are you sure you want to pause this SIM?'
                  : 'Are you sure you want to deactivate this SIM?'}
              </h5>
              {this.state.selectedAction === 'pause' ? (
                <p className="react-modal-subtitle mt-2">
                  SIM(s) are paused immediately. Once paused, the SIM(s) will no
                  longer be connected to data, voice, or SMS service. <br />
                  <br /> You can un-pause it any time by changing the plan of
                  the SIM.
                </p>
              ) : (
                <p className="react-modal-subtitle mt-2">
                  Please note that by deactivate this SIM you will lose the
                  phone number associated. Once this SIM is deactivated it will
                  not be able to be reactivated.
                </p>
              )}
              <div className="text-right mt-3">
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={
                    this.state.selectedAction === 'pause'
                      ? this.pauseSim
                      : this.deactivateSim
                  }
                  className="btn btn-dark w-150"
                >
                  {this.state.updateLoading
                    ? 'Please wait...'
                    : "Yes, I'm sure"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* ACTIVATE PLAN MODAL */}
        <ReactModal
          isOpen={this.state.isActivateModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Activate{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              {/* <div className="form-group material-textfield">
              <select
                className="form-control form-control-lg custom-select material-textfield-input"
                onChange={this.handleSelectPlan.bind(this, 'spr')}
                value ={this.state.sprPlanIndex}
              >
                <option value=''>Select</option>
                { _.size(simPlansData) > 0 && simPlansData.map((plan,index) => {
                    return (
                      <option key={index} value={index}>{plan.title}</option>
                    )
                  })
                }
              </select>
              <label className="material-textfield-label">Select SPR Plan</label>
              { this.state.planError &&
                <div className="invalid-feedback">Plan is required</div>
              }
            </div> */}
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={this.handleSelectPlan.bind(this, 'fixed')}
                  value={this.state.fixedPlanIndex}
                >
                  <option value="">Select</option>
                  {_.size(fixedPlans) > 0 &&
                    fixedPlans.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.offername}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">
                  Select Fixed Plan
                </label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {!_.isEmpty(selectedSimPlan) &&
                (selectedSimPlan.title.toLowerCase().includes('just data') ||
                  selectedSimPlan.title
                    .toLowerCase()
                    .includes('everything')) && (
                  <div className="info-block">
                    <label className="label-text">
                      {selectedSimPlan.title}{' '}
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? 'Plan'
                        : ''}{' '}
                      Details{' '}
                    </label>
                    <p className="text-desc">
                      ${selectedSimPlan.monthlyprice}/month <br />$
                      {selectedSimPlan.dataprice}/mb <br />
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? '(Data)'
                        : '(Data, Voice, SMS)'}
                      <br />
                    </p>
                  </div>
                )}
              {activeTab === 'fixed-inactive' ? (
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={this.activateSim}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              ) : (
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={this.activatePauseDeactivateSim}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              )}
            </div>
          </div>
        </ReactModal>

        {/* Open thank You modal */}
        <ReactModal
          isOpen={this.state.openThankYouModal}
          onRequestClose={() => this.setState({ openThankYouModal: false })}
          ariaHideApp={false}
          contentLabel="Thank You - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.titleText}
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ openThankYouModal: false })}
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() => this.setState({ openThankYouModal: false })}
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* DELETE SIM MODAL */}
        <ReactModal
          isOpen={this.state.deleteModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Delete Sim</h5>
              <p className="react-modal-subtitle mt-2">
                Are you sure you want to delete{' '}
                {!_.isEmpty(this.state.deleteSimnumber)
                  ? this.state.deleteSimnumber.simnumber + ' sim?'
                  : ' sims?'}
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={this.state.updateLoading}
                  onClick={this.deleteSim}
                >
                  {this.state.updateLoading ? 'Please wait ...' : 'Yes, Delete'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* PORT SIM MODAL */}
        <ReactModal
          isOpen={this.state.portSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="PORT SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Submit Port-In Activation</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="portInPhoneNumber"
                  value={this.state.portInPhoneNumber}
                  onChange={(e) =>
                    this.setState({
                      portInPhoneNumber: e.target.value,
                      requirePortInPhoneNumber: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Port-in Phone Number*
                </label>
                {this.state.requirePortInPhoneNumber && (
                  <div className="invalid-feedback">
                    Port-in number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedCarrier: e.target.value,
                      carrierError: false,
                    })
                  }
                >
                  <option>Select</option>
                  <option value={'Verizon'}>Verizon</option>
                  <option value={'Sprint'}>Sprint</option>
                  <option value={'AT&T'}>{`AT&T`}</option>
                  <option value={'T-Mobile'}>T-Mobile</option>
                  <option value={'Other'}>Other</option>
                </select>
                <label className="material-textfield-label">
                  Select Carrier*
                </label>
                {this.state.carrierError && (
                  <div className="invalid-feedback">Carrier is required</div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierACNumber"
                  value={this.state.carrierACNumber}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Account Number*
                </label>
                {this.state.requireCarrierACNumber && (
                  <div className="invalid-feedback">
                    Carrier account number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierAssignPassword"
                  value={this.state.carrierAssignPassword}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Assigned Password*
                </label>
                {this.state.requireCarrierAssignPassword && (
                  <div className="invalid-feedback">
                    Carrier assigned password is required
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <PlacesAutocomplete
                      type="text"
                      name="street1"
                      value={this.state.street1}
                      className="form-control form-control-lg material-textfield-input"
                      handleAddressChange={({ address, city, state, zip }) =>
                        this.setState({
                          street1: address,
                          city,
                          state,
                          zip: zip,
                        })
                      }
                      setValue={(street1) => this.setState({ street1 })}
                      address={this.state.street1}
                      isRequired={true}
                    />
                    {/* <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="street1"
                      value={this.state.street1}
                      onChange={(e) =>
                        this.setState({
                          street1: e.target.value,
                          street1Error: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">
                      Address Line 1*
                    </label>
                    {this.state.street1Error && (
                      <div className="invalid-feedback">
                        Address Line 1 is required.
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="street2"
                      value={this.state.street2}
                      onChange={(e) =>
                        this.setState({
                          street2: e.target.value,
                          street2Error: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">
                      Address Line 2*
                    </label>
                    {this.state.street2Error && (
                      <div className="invalid-feedback">
                        Address Line 2 is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="city"
                      value={this.state.city}
                      onChange={(e) =>
                        this.setState({
                          city: e.target.value,
                          cityError: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">City*</label>
                    {this.state.cityError && (
                      <div className="invalid-feedback">City is required.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="state"
                      value={this.state.state}
                      onChange={(e) =>
                        this.setState({
                          state: e.target.value,
                          stateError: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">State*</label>
                    {this.state.stateError && (
                      <div className="invalid-feedback">State is required.</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip"
                      value={this.state.zip}
                      onChange={(e) =>
                        this.setState({
                          zip: e.target.value,
                          zipError: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">Zipcode*</label>
                    {this.state.zipError && (
                      <div className="invalid-feedback">
                        Zip code is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zipcodePortIn"
                      value={this.state.zipcodePortIn}
                      maxLength="5"
                      onChange={(e) =>
                        this.setState({
                          zipcodePortIn: e.target.value,
                          requireZipcodePortIn: false,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">
                      Zipcode of Port-In Phone Number*
                    </label>
                    {this.state.requireZipcodePortIn && (
                      <div className="invalid-feedback">
                        Zipcode of port-in phone number is required
                      </div>
                    )}
                    {this.state.zipcodeErrorMessage && (
                      <div className="invalid-feedback">
                        {this.state.zipcodeErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip4"
                      value={this.state.zip4}
                      maxLength="5"
                      onChange={(e) => this.setState({ zip4: e.target.value })}
                      required
                    />
                    <label className="material-textfield-label">
                      Zipcode 4
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="altContactNumber"
                  value={this.state.altContactNumber}
                  onChange={(e) =>
                    this.setState({
                      altContactNumber: e.target.value,
                      requireAltContactNumber: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Alternate Contact Number*
                </label>
                {this.state.requireAltContactNumber && (
                  <div className="invalid-feedback">
                    Alternate contact number is required
                  </div>
                )}
                <div style={{ color: '#979797' }}>
                  We need this in case we need to contact you during the port in
                  process.
                </div>
              </div>
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: simPlansData[e.target.value],
                      planError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>

                <label className="material-textfield-label">Select Plan*</label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {!_.isEmpty(selectedSimPlan) &&
                (selectedSimPlan.title.toLowerCase().includes('just data') ||
                  selectedSimPlan.title
                    .toLowerCase()
                    .includes('everything')) && (
                  <div className="info-block">
                    <label className="label-text">
                      {selectedSimPlan.title}{' '}
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? 'Plan'
                        : ''}{' '}
                      Details{' '}
                    </label>
                    <p className="text-desc">
                      ${selectedSimPlan.monthlyprice}/month <br />$
                      {selectedSimPlan.dataprice}/mb <br />
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? '(Data)'
                        : '(Data, Voice, SMS)'}
                      <br />
                    </p>
                  </div>
                )}
              <div className="invalid-feedback">{this.state.errorMessage}</div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.submitPortIn}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Submit Port-In Activation'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Open Cance-PortIn modal */}
        <ReactModal
          isOpen={this.state.openCancelPortInFailureModal}
          onRequestClose={() =>
            this.setState({ openCancelPortInFailureModal: false })
          }
          ariaHideApp={false}
          contentLabel="Cancel PortIn Failed - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.titleText}
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() =>
                  this.setState({ openCancelPortInFailureModal: false })
                }
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() =>
                    this.setState({ openCancelPortInFailureModal: false })
                  }
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* CHANGE PLAN MODAL */}

        <ReactModal
          isOpen={this.state.changePlanModal}
          onRequestClose={() => this.setState({ changePlanModal: false })}
          ariaHideApp={false}
          contentLabel="Edit SIM Details"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Change Plan</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ changePlanModal: false })}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>Change Plan?</h4>
                <p>
                  Changing plan 3 times will lock your sim onto the new plan
                  until end of current plan cycle date, after which you will be
                  able to re-select a different plan.
                </p>
              </div>
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      changedSimPlan: fixedPlans[e.target.value],
                      changedPlanError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(fixedPlans) > 0 &&
                    fixedPlans.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.changedPlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              <div className="invalid-feedback mb-3">
                {this.state.simLengthError}
              </div>
              <button
                type="submit"
                disabled={this.state.changePlanLoading}
                onClick={this.bulkPlanChange.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.changePlanLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* REACTIVATE PLAN MODAL */}
        <ReactModal
          isOpen={this.state.reactivateSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Reactivate{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: fixedPlans[e.target.value],
                      planError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(fixedPlans) > 0 &&
                    fixedPlans.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.reactivatePlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              <button
                disabled={this.state.reactivateLoading}
                type="button"
                onClick={this.reactivateSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.reactivateLoading
                  ? 'Please wait...'
                  : 'Yes, Reactivate Now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* UNPAUSE SIM MODAL */}
        <ReactModal
          isOpen={this.state.isUnpauseModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Unpause{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={this.handelUnpausePlanChange.bind(this)}
                  value={planValue}
                >
                  <option value="">Select</option>
                  {_.size(fixedPlans) > 0 &&
                    fixedPlans.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.offername}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.unPauseSimPlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {this.state.errorMessage && (
                <div className="invalid-feedback">
                  {this.state.errorMessage}
                </div>
              )}
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.unPauseSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Yes, unpause now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/*  SIM MOVE INTO NEVER BEEN TAB MODAL */}
        <ReactModal
          isOpen={this.state.isMoveBackModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">
                Move SIMs to Never Been Activated
              </h5>
              <p className="react-modal-subtitle mt-2">
                Are you sure you want to move SIMs to Never Been Activated?
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={this.state.updateLoading}
                  onClick={this.setSimsIntoNeverBeen}
                >
                  {this.state.updateLoading
                    ? 'Please wait ...'
                    : 'Yes, Move It'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
