/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import MaintenanceBanner from './MaintenanceBanner/container';
import PageSettings from './PageSettings/container';
import { CloseIcon } from '../../components/icons';
import _ from 'lodash';
import './styles.scss';
let lastPath;
export default class AppSettingsComponent extends PureComponent {
  componentDidMount() {
    //Go for last route
    lastPath = _.get(this.props, 'location.state.lastPath', '/dashboard');
  }

  render() {
    const path = window.location.pathname;
    return (
      <>
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">App Settings</div>
          <div className="head-section-cta">
            <button
              className="btn btn-link btn-back"
              onClick={() => this.props.history.push(lastPath)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="editor__tabs">
          <ul className="editor__tabs--link">
            <li className={path === '/banner-setting' ? 'active' : ''}>
              <Link className="navlink" to={'/banner-setting'}>
                Maintenance Banner
              </Link>
            </li>
            {/*<li
              className={path === "/page-setting" ? "active" : ""}
            >
              <Link className="navlink" to={'/page-setting'}>Page Settings</Link>
            </li>*/}
          </ul>
        </div>
        {path === '/banner-setting' && <MaintenanceBanner {...this.props} />}
        {path === '/page-setting' && <PageSettings {...this.props} />}
      </>
    );
  }
}
