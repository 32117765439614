/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Popover, DatePicker } from 'antd';
import ReactModal from 'react-modal';
import moment from 'moment';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { isAdmin } from '../../constants/common';
import UsersList from './UsersList/container';
import {
  UserColorIcon,
  CalenderIcon,
  FilterIcon,
  ExportIcon,
  CloseIcon,
} from '../../components/icons';
import NumberFormat from 'react-number-format';
import { DebounceInput } from 'react-debounce-input';
import _ from 'lodash';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isSPRPartner, isSubUser } from '../../constants/common';
import './styles.scss';
const dateFormat = 'MM/DD/YYYY';
export default class UsersComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      confirmLoading: false,
      errorMessage: '',
      userData: null,
      isFiltervisible: false,
      isDateFilter: false,
      isStatusFilter: false,
      isConfirmModal: false,
      selectedItem: {},
      selectedMenuKey: '',
      searchUser: '',
      activeTab: 'all',
      users: [],
      total: 0,
      counts: {},
      page: 1,
      sortBy: 'email',
      sortOrder: 'desc',
      limit: 50,
      keyword: '',
      status: 'all',
      byDate: false,
      startDate: '',
      endDate: '',
      isExport: false,
      isOpenCreditModal: false,
      creditHeading: 'Add Credit',
      id: 0,
      creditamount: 0,
      isOpenUpdatePlanModal: false,
      isOpenUpdateUserInfo: false,
      isOpenUpdateLineModal: false,
      isOpenUpdateUserRoleModal: false,
      isOpenUpdateSupportUrlModal: false,
      userRoleNew: '',
      userSupportUrlNew: '',
      selectedUser: {},
      plans: [],
      filteredPlans: [],
      lines: '',
      isPlanLoading: false,
      selectAllPlans: 0,
      partnerCreditAmount: 0,
    };
  }

  componentDidMount = async () => {
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      if (isAdmin(user)) {
        this.props.setUser(user);
        this.setState({ userData: user });
        let selectedTab = '';
        let tab = _.get(this.props, 'location.pathname', '/all-users');
        let lastActiveTab = localStorage.getItem('@lastActiveTab');
        if (lastActiveTab) {
          tab = lastActiveTab;
          this.props.history.push(lastActiveTab);
          localStorage.removeItem('@lastActiveTab');
        }
        if (tab === '/all-users') {
          selectedTab = 'all';
          document.title = 'All Users | UltraIoT';
        } else if (tab === '/ultra-admins') {
          selectedTab = 'ultra';
          document.title = 'Ultra Admins | UltraIoT';
        } else if (tab === '/partners') {
          selectedTab = 'spr';
          document.title = 'Partners | UltraIoT';
        }

        let pageNumber = _.get(this.props.location, 'state.pageNumber', 0);
        if (pageNumber) {
          this.setState({ page: pageNumber });
        }
        this.setState({ activeTab: selectedTab }, () => {
          this.getUsersOnTabChange();
        });
      } else {
        this.props.history.push('/dashboard');
      }
    }
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab, page: 1 }, () => {
      if (tab === 'all') {
        this.props.history.push('/all-users');
        document.title = 'All Users | UltraIoT';
      } else if (tab === 'ultra') {
        this.props.history.push('/ultra-admins');
        document.title = 'Ultra Admins | UltraIoT';
      } else if (tab === 'spr') {
        this.props.history.push('/partners');
        document.title = 'Partners | UltraIoT';
      }
      this.getUsersOnTabChange();
    });
  };

  getUsersOnTabChange = async () => {
    this.setState({ isLoading: true });
    const {
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      status,
      byDate,
      startDate,
      endDate,
      activeTab,
    } = this.state;
    let obj = {
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      status,
      byDate,
      startDate,
      endDate,
      type: activeTab,
      isExport: false,
    };
    try {
      const {
        value: { success, counts, total, users },
      } = await this.props.getUsers(obj);
      if (success) {
        this.setState({ users, total, counts, isLoading: false });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  hide = () => {
    this.setState({
      isFiltervisible: false,
    });
  };

  filterVisible = (visible) => {
    this.setState({ isFiltervisible: visible });
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getUsersOnTabChange();
    });
  };

  handleSearch(e) {
    const searchUser = e.target.value;
    this.setState({ searchUser, keyword: searchUser }, () => {
      this.getUsersOnTabChange();
    });
  }

  onStatusFilter = (status) => {
    this.setState({ status }, () => {
      this.getUsersOnTabChange();
    });
  };

  onChangeDate = (from, date, dateString) => {
    if (from === 'start') {
      this.setState({ startDate: date, endDate: '' });
    } else if (from === 'end') {
      this.setState({ endDate: date });
      this.setState({ byDate: true }, () => {
        this.getUsersOnTabChange();
      });
    }
  };

  disabledDate = (current) => {
    return current && current.valueOf() <= new Date(this.state.startDate);
  };

  onClearFilter = () => {
    this.setState(
      {
        isStatusFilter: false,
        isDateFilter: false,
        status: 'all',
        byDate: false,
        startDate: '',
        endDate: '',
      },
      () => {
        var inputs = document.querySelectorAll('.unCheckAll');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        this.getUsersOnTabChange();
      }
    );
  };

  removeFilter(key) {
    if (key === 'status') {
      this.setState({ status: 'all', isStatusFilter: false }, () => {
        this.forceUpdate();
        this.getUsersOnTabChange();
      });
    } else {
      this.setState(
        { isDateFilter: false, byDate: false, startDate: '', endDate: '' },
        () => {
          this.forceUpdate();
          this.getUsersOnTabChange();
        }
      );
    }
  }

  statusUpdate = async () => {
    try {
      const { selectedItem, selectedMenuKey } = this.state;
      let obj = {};
      obj.id = selectedItem.id;
      if (selectedMenuKey === 'StatusChange') {
        obj.status = parseInt(selectedItem.status) === 10 ? 0 : 10;
      }
      if (selectedMenuKey === 'enableIR' || selectedMenuKey === 'disableIR') {
        obj.isIREnabled = selectedItem.isIREnabled ? false : true;
      }
      if (selectedMenuKey === 'enableILD' || selectedMenuKey === 'disableILD') {
        obj.isILDEnabled = selectedItem.isILDEnabled ? false : true;
      }
      const {
        value: { success },
      } = await this.props.updateUserStatus(obj);
      if (success) {
        this.getUsersOnTabChange();
        this.setState({ isConfirmModal: false, confirmLoading: false });
      }
    } catch (err) {
      this.setState({ isConfirmModal: false, confirmLoading: false });
    }
  };

  unblockUser = async () => {
    try {
      const { selectedItem } = this.state;
      let obj = {};
      obj.id = selectedItem.id;
      const {
        value: { success },
      } = await this.props.unblockUser(obj);
      if (success) {
        this.getUsersOnTabChange();
        this.setState({ isConfirmModal: false, confirmLoading: false });
      }
    } catch (err) {
      this.setState({ isConfirmModal: false, confirmLoading: false });
    }
  };

  impersonateUser = async () => {
    try {
      const { selectedItem } = this.state;
      let id = selectedItem.id;
      const {
        value: { success, token },
      } = await this.props.userImpersonate(id);
      if (success) {
        const auth = localStorage.getItem('Authorization');
        localStorage.setItem('i_Authorization', auth);
        localStorage.setItem('Authorization', token);
        const {
          value: { success, user },
        } = await this.props.fetchMe();
        if (success && user) {
          this.props.setUser(user);
          if (user && user.role === 'superadmin') {
            this.props.history.push('/spr-dashboard');
          } else {
            if (
              user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              _.get(user, 'lobId', 1) === 3
            ) {
              this.props.history.push('/fixed-dashboard');
            } else {
              this.props.history.push('/dashboard');
            }
          }
        }
        this.setState({ isConfirmModal: false, confirmLoading: false });
        let lastPath = _.get(this.props, 'location.pathname', '/all-users');
        localStorage.setItem('@lastActiveTab', lastPath);
      }
    } catch (err) {
      message.error({
        key: 'updatable',
        content: err.data.message,
      });
      this.setState({ isConfirmModal: false, confirmLoading: false });
    }
  };

  onConfirmed = () => {
    this.setState({ confirmLoading: true });
    const { selectedMenuKey } = this.state;
    if (selectedMenuKey === 'Impersonate') {
      this.impersonateUser();
    } else if (
      selectedMenuKey === 'StatusChange' ||
      selectedMenuKey === 'enableIR' ||
      selectedMenuKey === 'disableIR' ||
      selectedMenuKey === 'enableILD' ||
      selectedMenuKey === 'disableILD'
    ) {
      this.statusUpdate();
    } else if (selectedMenuKey === 'unblockUser') {
      this.unblockUser();
    }
  };

  onMore = (key, item) => {
    if (key === 'Impersonate') {
      this.setState({ selectedMenuKey: key, selectedItem: item }, () => {
        this.impersonateUser();
      });
    } else if (key === 'StatusChange') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'unblockUser') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'enableIR') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'disableIR') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'enableILD') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'disableILD') {
      this.setState({
        selectedMenuKey: key,
        selectedItem: item,
        isConfirmModal: true,
      });
    } else if (key === 'ManagePermissions') {
      this.props.history.push('/manage-permissions');
      this.props.history.push({
        pathname: '/manage-permissions',
        state: item,
      });
    }
  };

  openCreditModal = (user) => {
    this.setState({
      isOpenCreditModal: true,
      id: user.id,
      creditHeading: `Set Credit Line for ${user.companyname}`,
      partnerCreditAmount: user.partnerCreditAmount,
    });
  };

  closeModal = () => {
    this.setState({
      isOpenCreditModal: false,
      isOpenUpdatePlanModal: false,
      isOpenUpdateUserInfo: false,
      isOpenUpdateLineModal: false,
      isOpenUpdateUserRoleModal: false,
      isOpenUpdateSupportUrlModal: false,
      userRoleNew: '',
      userSupportUrlNew: '',
      selectedUser: {},
      creditHeading: '',
      id: 0,
      errorMessage: '',
      isLoading: false,
      lines: '',
      creditamount: 0,
    });
  };

  addCredit = async () => {
    try {
      const { id, creditamount, partnerCreditAmount } = this.state;
      let amount = parseFloat(String(creditamount).replace(/[,$]/g, ''));
      const err = {};
      const finalAmount = amount + partnerCreditAmount;
      if (amount === 0) {
        err.amount = 'Credit amount should be greater than 0';
        this.setState({ errorMessage: err.amount });
      }
      if (finalAmount < 0) {
        err.amount = 'Total credit line amount cannot be negative';
        this.setState({ errorMessage: err.amount });
      }
      this.setState({ err });
      if (!Object.keys(err).length) {
        this.setState({
          isLoading: true,
          errorMessage: '',
        });
        const payload = {
          userId: id,
          amount,
        };
        const {
          value: { success },
        } = await this.props.addCredits(payload);
        if (success) {
          this.getUsersOnTabChange();
          this.closeModal();
        }
      }
    } catch (err) {
      this.setState({ isLoading: false, errorMessage: err });
    }
  };

  updateLines = async () => {
    try {
      const { id, lines } = this.state;
      let total_lines = String(lines).replace(/,/g, '');
      const err = {};
      if (total_lines === '') {
        err.lines = 'Total lines is required.';
        this.setState({ errorMessage: 'Total lines is required.' });
      } else if (parseInt(total_lines) === 0) {
        err.lines = 'Total lines should be greater than 0.';
        this.setState({
          errorMessage: 'Total lines should be greater than 0.',
        });
      }
      this.setState({ err });
      if (!Object.keys(err).length) {
        this.setState({
          isLoading: true,
          errorMessage: '',
        });
        const payload = {
          id,
          total_lines,
        };
        const {
          value: { success },
        } = await this.props.updateProfile(payload);
        if (success) {
          this.closeModal();
        }
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: err,
      });
    }
  };

  // Function to update/change the user role(between admin & sysops user)
  updateUserRole = async () => {
    try {
      const { id, userRoleNew, selectedUser } = this.state;
      let noError = true;
      if (selectedUser.role === userRoleNew) {
        this.setState({
          isLoading: false,
          errorMessage: 'Entered user role is same as current user role.',
        });
        noError = false;
      }
      if (noError) {
        this.setState({
          isLoading: true,
          errorMessage: '',
        });
        const payload = {
          id,
          role: userRoleNew,
          account_type: userRoleNew === 'admin' ? 'Admin' : 'SysOps',
        };
        const {
          value: { success },
        } = await this.props.updateProfile(payload);
        if (success) {
          this.setState(
            {
              isLoading: false,
              errorMessage: '',
              isOpenUpdateUserRoleModal: false,
              userRoleNew: '',
              selectedUser: {},
            },
            () => {
              this.getUsersOnTabChange();
            }
          );
        }
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: err,
      });
    }
  };

  // Function to update/change the support url
  updateUserSupportURL = async () => {
    try {
      const { id, userSupportUrlNew } = this.state;
      let noError = true;
      if (userSupportUrlNew === '') {
        this.setState({
          isLoading: false,
          errorMessage: 'Please enter the URL.',
        });
        noError = false;
      }
      if (noError) {
        this.setState({
          isLoading: true,
          errorMessage: '',
        });
        const payload = {
          userId: id,
          url: userSupportUrlNew,
        };
        const {
          value: { success },
        } = await this.props.updateSupportUrl(payload);
        if (success) {
          this.setState(
            {
              isLoading: false,
              errorMessage: '',
              isOpenUpdateSupportUrlModal: false,
              userSupportUrlNew: '',
              selectedUser: {},
            },
            () => {
              this.getUsersOnTabChange();
            }
          );
        }
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: err,
      });
    }
  };

  // Export User
  exportData = async () => {
    const {
      page,
      sortBy,
      sortOrder,
      keyword,
      status,
      byDate,
      startDate,
      endDate,
      activeTab,
    } = this.state;
    this.setState({ isExportLoading: true });
    let obj = {
      page,
      sortBy,
      sortOrder,
      limit: 0,
      keyword,
      status,
      byDate,
      startDate,
      endDate,
      type: activeTab,
      isExport: true,
    };
    try {
      const {
        value: { success, users },
      } = await this.props.getUsers(obj);
      if (success) {
        if (_.size(users) > 0) {
          let date = moment().format('YYYY-MM-DD');
          let fileName =
            activeTab.charAt(0).toUpperCase() +
            activeTab.slice(1) +
            '-Users-List-' +
            date;
          const _data = users.map((user) => ({
            Name:
              user.firstname && user.lastname
                ? `"${user.firstname} ${user.lastname}"`
                : '',
            Email: user.email ? user.email : '',
            Company_Name: user.companyname ? `"${user.companyname}"` : '',
            Joined_Date: user.datecreated ? user.datecreated : '',
            Account_Type: user.account_type ? user.account_type : '',
            Role: user.role ? user.role : '',
            Status: user.status ? user.status : '',
            Available_Balance: user.availablebalance
              ? user.availablebalance
              : 0,
            Phone: user.phone ? user.phone.replace(/[()-]/g, '') : '',
            Address: `"${user.addressline1} ${user.city} ${user.state} ${user.zipcode}"`,
          }));
          let csvRows = [];
          const headers = Object.keys(_data[0]);
          csvRows.push(headers.join(','));
          for (const row of _data) {
            const values = headers.map((header) => {
              return row[header];
            });
            csvRows.push(values.join(','));
          }
          csvRows = csvRows.join('\n');
          const blob = new Blob([csvRows], { type: 'text/csv' });
          const csvURL = window.URL.createObjectURL(blob);
          let a = '';
          a = document.createElement('a');
          a.setAttribute('hidden', '');
          a.setAttribute('href', csvURL);
          a.setAttribute('download', fileName + '.csv');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        this.setState({ isExportLoading: false });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isExportLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  openUpdateLineModal = (user) => {
    this.setState({
      isOpenUpdateLineModal: true,
      id: user.id,
    });
  };

  openUpdateUserRoleModal = (user) => {
    this.setState({
      isOpenUpdateUserRoleModal: true,
      id: user.id,
      userRoleNew: user.role,
      selectedUser: user,
    });
  };

  openUpdateSupportUrlModal = (user) => {
    this.setState({
      isOpenUpdateSupportUrlModal: true,
      id: user.id,
      userSupportUrlNew: user.url,
      selectedUser: user,
    });
  };

  // Get to select plans page
  openUpdatePlanModal = async (user) => {
    this.props.history.push({
      pathname: '/select-plan',
      state: {
        userId: user.id,
        activeUsersTab: this.state.activeTab,
        pageNumber: this.state.page,
      },
    });
  };

  // Get to update userInfo page
  openUpdateUserInfoPage = async (user) => {
    this.props.history.push({
      pathname: '/users/edit-user',
      state: {
        userInfoToEdit: user,
        userIdToEdit: user.id,
        activeUsersTab: this.state.activeTab,
        pageNumber: this.state.page,
      },
    });
  };

  sortByKey = (key) => {
    const { sortOrder, sortBy } = this.state;
    if (sortOrder === 'asc' || sortBy !== key) {
      this.setState({ sortOrder: 'desc', sortBy: key }, () => {
        this.getUsersOnTabChange();
      });
    } else {
      this.setState({ sortOrder: 'asc', sortBy: key }, () => {
        this.getUsersOnTabChange();
      });
    }
  };

  // function to add comma's to a numerical value
  formatNumWithComma(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  render() {
    const {
      users,
      searchUser,
      counts,
      total,
      page,
      activeTab,
      limit,
      userData,
      isFiltervisible,
      isDateFilter,
      isStatusFilter,
      startDate,
      endDate,
      creditHeading,
      creditamount,
      isLoading,
      errorMessage,
      sortBy,
      sortOrder,
      lines,
    } = this.state;
    let filterCount =
      (this.state.status !== 'all' ? 1 : 0) + (this.state.byDate ? 1 : 0);
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation user={userData} {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2 className="heading-title">
                <UserColorIcon />
                Ultra Users
              </h2>
            </div>
            <div className="col-lg-8 text-sm-right">
              <Link to={'/users/add-user'} className="btn btn-dark w-150 mr-3">
                Add User
              </Link>
              <Popover
                content={
                  <div className="filter">
                    <div className="filter__header">
                      <button
                        disabled={filterCount > 0 ? false : true}
                        onClick={this.onClearFilter}
                        className="btn btn-secondary btn-sm"
                      >
                        Clear
                      </button>
                      <div className="filter__title">Filters</div>
                      <button
                        onClick={() =>
                          this.setState({ isFiltervisible: false })
                        }
                        className="btn btn-dark btn-sm"
                      >
                        Close
                      </button>
                    </div>
                    <div className="filter__body">
                      <ul className="filter__list">
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isStatusFilter"
                              value={isStatusFilter}
                              checked={isStatusFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isStatusFilter: !isStatusFilter,
                                    status: 'all',
                                  },
                                  () => {
                                    if (!this.state.isStatusFilter) {
                                      this.getUsersOnTabChange();
                                    }
                                  }
                                )
                              }
                            />
                            Status
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {isStatusFilter && (
                          <ul className="filter__list__inner">
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  className="custom-checkbox--input"
                                  type="radio"
                                  name="status"
                                  onChange={this.onStatusFilter.bind(
                                    this,
                                    'active'
                                  )}
                                />
                                Active
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  className="custom-checkbox--input"
                                  type="radio"
                                  name="status"
                                  onChange={this.onStatusFilter.bind(
                                    this,
                                    'inactive'
                                  )}
                                />
                                Not Active
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        )}
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isDateFilter"
                              checked={isDateFilter}
                              value={isDateFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isDateFilter: !isDateFilter,
                                    startDate: '',
                                    endDate: '',
                                  },
                                  () => {
                                    if (!this.state.isDateFilter) {
                                      this.setState({ byDate: false }, () => {
                                        this.getUsersOnTabChange();
                                      });
                                    }
                                  }
                                )
                              }
                            />
                            Created Date
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {isDateFilter && (
                          <ul className="filter__list__inner">
                            <div className="row mt-3 mb-3">
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'start'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    suffixIcon={<CalenderIcon />}
                                    showToday={false}
                                    allowClear={false}
                                  />
                                  <label className="datepicker-textfield-label">
                                    From
                                  </label>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    disabledDate={this.disabledDate}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'end'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    suffixIcon={<CalenderIcon />}
                                    showToday={false}
                                    allowClear={false}
                                    disabled={
                                      this.state.startDate !== '' ? false : true
                                    }
                                    value={
                                      this.state.endDate !== ''
                                        ? this.state.endDate
                                        : null
                                    }
                                  />
                                  <label className="datepicker-textfield-label">
                                    To
                                  </label>
                                </div>
                              </div>
                            </div>
                          </ul>
                        )}
                      </ul>
                    </div>
                  </div>
                }
                placement="bottom"
                className="filter"
                overlayClassName="filter__overlay"
                trigger="click"
                visible={isFiltervisible}
                onVisibleChange={this.filterVisible}
              >
                <button className="btn btn-dark w-150 btn-filter mr-3">
                  <FilterIcon className="filter-icon" />
                  Filter {filterCount > 0 && '(' + filterCount + ')'}
                </button>
              </Popover>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-right">
              <button
                disabled={_.size(users) === 0}
                onClick={this.exportData.bind(this)}
                className="btn btn-link btn-export"
              >
                <ExportIcon className="export-icon" />
                {this.state.isExportLoading ? 'Please wait...' : 'Export'}
              </button>
            </div>
          </div>

          <div className="mobile-select d-lg-none">
            <select
              value={this.state.activeTab}
              className="form-control custom-select"
              onChange={(e) => this.onTabChange(e.target.value)}
            >
              <option value="all">
                All Users ({this.formatNumWithComma(_.get(counts, 'total', 0))})
              </option>
              <option value="ultra">
                Ultra Admins (
                {this.formatNumWithComma(_.get(counts, 'ultra', 0))})
              </option>
              <option value="spr">
                Partner Users (
                {this.formatNumWithComma(_.get(counts, 'spr', 0))})
              </option>
            </select>
          </div>
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <div className="tabs__control mb-3">
                <button
                  onClick={this.onTabChange.bind(this, 'all')}
                  className={activeTab === 'all' ? 'btn active' : 'btn'}
                >
                  All Users{' '}
                  <span>
                    {this.formatNumWithComma(_.get(counts, 'total', 0))} Users
                  </span>
                </button>
                <button
                  onClick={this.onTabChange.bind(this, 'ultra')}
                  className={activeTab === 'ultra' ? 'btn active' : 'btn'}
                >
                  Ultra Admins{' '}
                  <span>
                    {this.formatNumWithComma(_.get(counts, 'ultra', 0))} Users
                  </span>
                </button>
                <button
                  onClick={this.onTabChange.bind(this, 'spr')}
                  className={activeTab === 'spr' ? 'btn active' : 'btn'}
                >
                  Partner Users{' '}
                  <span>
                    {this.formatNumWithComma(_.get(counts, 'spr', 0))} Users
                  </span>
                </button>
              </div>
              <div>
                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  className="form-control"
                  placeholder="Search by name, email, or company name..."
                  name="searchUser"
                  value={searchUser}
                  onChange={this.handleSearch.bind(this)}
                  required
                />
              </div>
            </div>
          </div>
          {(this.state.status !== 'all' || this.state.byDate) && (
            <ul className="filter__list__flitered mt-3">
              {this.state.status !== 'all' && (
                <li>
                  {this.state.status === 'active' ? 'Active' : 'Not Active'}
                  <span onClick={this.removeFilter.bind(this, 'status')}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
              {this.state.byDate && (
                <li>
                  {moment(startDate).format('MMMM DD, YYYY') +
                    ' - ' +
                    moment(endDate).format('MMMM DD, YYYY')}
                  <span onClick={this.removeFilter.bind(this, 'date')}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
            </ul>
          )}
          <div className="row">
            <div className="col-md-12">
              {_.size(users) > 0 && (
                <div className="all-filters">
                  <div className="filter-page-number ml-auto">
                    {page > 1 ? (page - 1) * limit + 1 : 1}-
                    {limit * page < total ? limit * page : total} of {total}
                  </div>
                </div>
              )}
              {this.state.isLoading ? (
                <div className="blank__table__card mt-3">
                  <div className="blank__table__card__inner">
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: '#000' }}
                          spin
                        />
                      }
                    />
                  </div>
                </div>
              ) : _.size(users) > 0 ? (
                <UsersList
                  activeTab={activeTab}
                  data={users}
                  totalPagination={total}
                  page={page}
                  limit={limit}
                  onPageChange={this.onPageChanged}
                  onMore={this.onMore}
                  openCreditModal={this.openCreditModal}
                  openUpdatePlanModal={this.openUpdatePlanModal}
                  openUpdateUserInfoPage={this.openUpdateUserInfoPage}
                  openUpdateLineModal={this.openUpdateLineModal}
                  openUpdateUserRoleModal={this.openUpdateUserRoleModal}
                  openUpdateSupportUrlModal={this.openUpdateSupportUrlModal}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  sortByKey={this.sortByKey}
                  userRole={userData.role}
                  match={this.props.match}
                  formatNumWithComma={this.formatNumWithComma}
                />
              ) : (
                <div className="blank__table__card spacer-10">
                  <div className="blank__table__card__inner">No Users</div>
                </div>
              )}
            </div>
          </div>
        </main>

        {/* ALL MODAL */}

        <ReactModal
          isOpen={this.state.isOpenCreditModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Add Credits"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{creditHeading}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <NumberFormat
                  allowEmptyFormatting={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                  thousandSeparator={true}
                  displayType={'input'}
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  onValueChange={(_values) => {
                    const { formattedValue } = _values;
                    const creditamount = formattedValue;
                    this.setState({ creditamount });
                  }}
                  value={creditamount}
                  required
                />
                <label className="material-textfield-label">
                  Credit Amount
                </label>
                <div className="invalid-feedback">{errorMessage}</div>
              </div>
              <button
                type="button"
                className="btn btn-dark btn-lg w-100"
                disabled={isLoading}
                onClick={this.addCredit}
              >
                {isLoading ? 'Please wait ...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.isOpenUpdateLineModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Update Lines"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Update lines</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <NumberFormat
                  allowEmptyFormatting={false}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  thousandSeparator={true}
                  displayType={'input'}
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  onValueChange={(_values) => {
                    const { formattedValue } = _values;
                    const lines = formattedValue;
                    this.setState({ lines });
                  }}
                  value={lines}
                  required
                />
                <label className="material-textfield-label">Total Lines</label>
                <div className="invalid-feedback">{errorMessage}</div>
              </div>
              <button
                type="button"
                className="btn btn-dark btn-lg w-100"
                disabled={isLoading}
                onClick={() => this.updateLines()}
              >
                {isLoading ? 'Please wait ...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Update User Role(only between sysops & admin roles) - Modal */}
        <ReactModal
          isOpen={this.state.isOpenUpdateUserRoleModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Update User Role"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Update User Role</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <div className="material-textfield">
                  <select
                    name="status"
                    className="form-control form-control-lg custom-select material-textfield-input"
                    require
                    onChange={(e) =>
                      this.setState({ userRoleNew: e.target.value })
                    }
                    value={this.state.userRoleNew}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    <option value="admin">Admin User</option>
                    <option value="sysops">SysOps User</option>
                  </select>
                  <label className="material-textfield-label">
                    New User Role
                  </label>
                </div>
                <div className="invalid-feedback">{errorMessage}</div>
              </div>
              <button
                type="button"
                className="btn btn-dark btn-lg w-100"
                disabled={isLoading}
                onClick={() => this.updateUserRole()}
              >
                {isLoading ? 'Please wait ...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Update Support URL- Modal */}
        <ReactModal
          isOpen={this.state.isOpenUpdateSupportUrlModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Update User Role"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Update Support Url</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <div className="material-textfield">
                  <input
                    type="text"
                    className="form-control form-control-lg material-textfield-input"
                    required
                    onChange={(e) =>
                      this.setState({ userSupportUrlNew: e.target.value })
                    }
                    value={this.state.userSupportUrlNew}
                  />
                  <label className="material-textfield-label">
                    New Support URL *
                  </label>
                </div>
                <div className="invalid-feedback">{errorMessage}</div>
              </div>
              <button
                type="button"
                className="btn btn-dark btn-lg w-100"
                disabled={isLoading}
                onClick={() => this.updateUserSupportURL()}
              >
                {isLoading ? 'Please wait ...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Edit User"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Edit --users full name-- </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select className="form-control custom-select material-textfield-input">
                  <option>Select</option>
                  <option>SPR Partner</option>
                  <option>SPR Partner</option>
                </select>
                <label className="material-textfield-label">Account Type</label>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      required
                    />
                    <label className="material-textfield-label">
                      First Name{' '}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      required
                    />
                    <label className="material-textfield-label">
                      Last Name{' '}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      required
                    />
                    <label className="material-textfield-label">
                      Mobile Number{' '}
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      required
                    />
                    <label className="material-textfield-label">
                      Email Address{' '}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <select className="form-control custom-select material-textfield-input">
                  <option>Select</option>
                  <option>Everything Plan</option>
                </select>
                <label className="material-textfield-label">
                  Select Status
                </label>
              </div>
              <button type="submit" className="btn btn-dark btn-lg w-100">
                Save
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Permissions"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                --users full name-- Permissions
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <h4 className="label-text mb-3">SIM Access</h4>
              <ul className="checkbox-list vertical mb-5">
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Active SIMs
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Available SIMs
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Paused SIMs
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Deactivated SIMs
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Usage Logs
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
              <h4 className="label-text mb-3">Settings</h4>
              <ul className="checkbox-list vertical">
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Users
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Billing
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="custom-checkbox custom-checkbox-line">
                    <input className="custom-checkbox--input" type="checkbox" />
                    Account Documents
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
              <button type="submit" className="btn btn-dark btn-lg w-100">
                Save
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.isConfirmModal}
          onRequestClose={() => this.setState({ isConfirmModal: false })}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.selectedMenuKey === 'Impersonate' && 'Impersonate'}
                {this.state.selectedMenuKey === 'StatusChange' && 'Status'}
                {this.state.selectedMenuKey === 'unblockUser' && 'Unlock User'}
                {this.state.selectedMenuKey === 'enableIR' && 'Enable IR'}
                {this.state.selectedMenuKey === 'disableIR' && 'Disable IR'}
                {this.state.selectedMenuKey === 'enableILD' && 'Enable ILD'}
                {this.state.selectedMenuKey === 'disableILD' && 'Disable ILD'}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ isConfirmModal: false })}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-subtitle">
                {this.state.selectedMenuKey === 'Impersonate' &&
                  'Are you sure you want to impersonate this user?'}
                {this.state.selectedMenuKey === 'StatusChange' &&
                  'Are you sure you want to change the status of this user?'}
                {this.state.selectedMenuKey === 'unblockUser' &&
                  'Are you sure you want to unlock this user?'}
                {this.state.selectedMenuKey === 'enableIR' &&
                  'Are you sure you want to enable IR for this user?'}
                {this.state.selectedMenuKey === 'disableIR' &&
                  'Are you sure you want to disable IR for this user?'}
                {this.state.selectedMenuKey === 'enableILD' &&
                  'Are you sure you want to enable ILD for this user?'}
                {this.state.selectedMenuKey === 'disableILD' &&
                  'Are you sure you want to disable ILD for this user?'}
              </p>
              <div className="text-right">
                <button
                  onClick={this.onConfirmed}
                  className="btn btn-dark btn-lg w-150"
                >
                  {this.state.confirmLoading ? 'Please Wait...' : 'Yes'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
