import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  fetchClientListing,
  addNewClient,
  updateClientName,
  deleteClient,
} from '../../store/sim/duck';
import ClientsComponent from './component';

const ClientsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchClientListing,
    addNewClient,
    deleteClient,
    updateClientName,
  }
)(ClientsComponent);

export default ClientsContainer;
