import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  getUsageDetailsByMonth,
  getUsageDetailsByDay,
} from '../../store/sim/duck';
import UsageLogsComponent from './component';

const UsageLogsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getUsageDetailsByMonth,
    getUsageDetailsByDay,
  }
)(UsageLogsComponent);

export default UsageLogsContainer;
