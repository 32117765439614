/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Spin, Pagination, notification } from 'antd';
import ReactModal from 'react-modal';
import { LoadingOutlined } from '@ant-design/icons';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import _ from 'lodash';
import './styles.scss';

// let isnum = /^\d+$/
// const { Option } = Select;

export default class BulkActivation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAddSimLoading: false,
      errorMessage: '',
      allChecked: true,
      userData: {},
      markedSimList: [],
      sims: [],
      newTagSize: false,
      selectedTag: '',
      simPlansData: [],
      selectedSimPlan: {},
      planError: false,
      simname: '',
      clients: [],
      selectedClient: {},
      bulkActivateModal: false,
      total: 0,
      page: 1,
      limit: 50,
      type: 'active',
      sortBy: 'activationdate',
      sortOrder: 'desc',
      keyword: '',
      tags: '',
      orderType: 'spr',
      irPlans: [],
      ildPlans: [],
      addIrPayType: 'recurring',
      serviceType: 'select',
      addIldMinutes: 0,
      addIRBalance: 0,
      remainingIRBalance: 0,
      searchSims: '',
      searchBy: 'iccid',
    };
  }

  componentDidMount = async () => {
    document.title = 'Bulk Add Ons | UltraIoT';
    //let pathname = _.get(this.props,'location.pathname','bulk-activation-sims')
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
      }
      this.getActiveSims();
      // this.fetchPlans()
      this.fetchAddOnPlans();
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  getActiveSims = async () => {
    const {
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      orderType,
      serviceType,
      searchBy,
    } = this.state;
    let obj = {
      type: 'active',
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate: false,
      isExport: false,
      orderType,
      isIR: serviceType && serviceType === 'ir' ? true : null,
      isILD: serviceType && serviceType === 'ild' ? true : null,
      searchBy,
    };
    this.setState({ isLoading: true });
    try {
      const {
        value: { success, total, records },
      } = await this.props.getSims(obj);
      if (success) {
        this.setState({
          sims: records,
          markedSimList: records,
          total,
          isLoading: false,
          allChecked: true,
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    };
  };

  searchByChange = (e) => {
    this.setState({ searchBy: e.target.value }, () => {
      if (this.state.keyword) {
        this.getActiveSims();
      }
    });
  };

  fetchAddOnPlans = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchAddOnPlans();
      if (success) {
        const irPlans = _.get(data, 'plan', [])
          .filter((plan) => plan.type === 'ir')
          .sort((a, b) => a.titleValue - b.titleValue);
        let ildPlans = _.get(data, 'plan', []).filter(
          (plan) => plan.type === 'ild'
        );
        ildPlans = ildPlans.sort(this.sortByProperty('titleValue'));
        const remainingIRBalance = _.get(data, 'remainingIRBalance', 0);
        this.setState({
          irPlans: irPlans,
          ildPlans: ildPlans,
          remainingIRBalance,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };
  onCheck = (item, key = 'single') => {
    const { sims, allChecked } = this.state;
    let markedSimList = _.cloneDeep(this.state.markedSimList);
    if (key === 'single') {
      let index = markedSimList.findIndex((list) => list.id === item.id);
      if (index === -1) {
        markedSimList.push(item);
      } else {
        markedSimList.splice(index, 1);
      }
      this.setState({ markedSimList }, () => {
        if (_.size(sims) === _.size(markedSimList)) {
          this.setState({ allChecked: true });
        } else {
          this.setState({ allChecked: false });
        }
      });
    } else {
      let allSimData = [...sims];
      if (!allChecked) {
        this.setState({ markedSimList: allSimData, allChecked: true });
      } else {
        this.setState({ markedSimList: [], allChecked: false });
      }
    }
  };

  submitRequest = async () => {
    const {
      markedSimList,
      serviceType,
      addIldMinutes,
      addIRBalance,
      addIrPayType,
      remainingIRBalance,
      irPlans,
      ildPlans,
    } = this.state;
    let isError = false;
    if (serviceType === 'select' || serviceType === '') {
      isError = true;
      this.setState({ serviceError: true });
    }
    if (serviceType && serviceType === 'ild' && addIldMinutes === 0) {
      isError = true;
      this.setState({ addIldMinutesError: true });
    }
    if (serviceType && serviceType === 'ir' && addIRBalance === 0) {
      isError = true;
      this.setState({ addIRBalanceError: true });
    }
    if (
      serviceType &&
      serviceType === 'ir' &&
      addIRBalance !== 0 &&
      markedSimList.length > 0
    ) {
      const simsLength = markedSimList.length;
      const totalAmount = parseInt(addIRBalance) * parseInt(simsLength);
      if (totalAmount > remainingIRBalance) {
        isError = true;
        this.setState({ remainingIRBalanceError: true });
      }
    }

    if (!isError) {
      // this.setState({ isAddSimLoading: true })
      this.setState({ remainingIRBalanceError: false, isAddSimLoading: true });
      let simCardIds = markedSimList.map((item) => item.id);
      try {
        let obj = {
          simcards: simCardIds,
          type: serviceType,
          addValue: serviceType === 'ild' ? addIldMinutes : addIRBalance,
          paymentType: addIrPayType,
        };
        if (
          serviceType === 'ir' &&
          _.get(this.props, 'user.consumeOfferAPI', false)
        ) {
          const filterP = irPlans.filter((el) => {
            return parseInt(el.titleValue) === parseInt(addIRBalance);
          });
          if (filterP && filterP.length > 0) {
            obj.addOnplanOfferId = filterP[0].offerId;
          }
        }
        if (
          serviceType === 'ild' &&
          _.get(this.props, 'user.consumeOfferAPI', false)
        ) {
          const filterP = ildPlans.filter((el) => {
            return parseInt(el.titleValue) === parseInt(addIldMinutes);
          });
          if (filterP && filterP.length > 0) {
            obj.addOnplanOfferId = filterP[0].offerId;
          }
        }
        const {
          value: { success },
        } = await this.props.bulkAddOnsPlan(obj);
        if (success) {
          setTimeout(() => {
            this.setState({
              isAddSimLoading: false,
              markedSimList: [],
              serviceType: '',
              keyword: '',
              addIldMinutes: 0,
              addIRBalance: 0,
              remainingIRBalanceError: false,
            });
            this.getActiveSims();
            notification['success']({
              message: 'Bulk Add ILD/IR',
              description:
                'Your SIM card(s) are processing. Check the notifications icon for updates.',
            });
          }, 800);
        }
      } catch (error) {
        this.setState({ isAddSimLoading: false, errorMessage: error.message });
      }
    }
  };

  bulkActivateOk = () => {
    this.setState({ bulkActivateModal: false });
    // this.props.history.push('/never-been-activated')
  };

  onPageChange = (page) => {
    this.setState({ page, markedSimList: [] }, () => {
      window.scrollTo(0, 0);
      this.getActiveSims();
    });
  };

  handleSearch(e) {
    let searchSims = e.target.value;
    const isSims = searchSims.includes(',');
    if (isSims) {
      searchSims =
        searchSims && searchSims.trim() !== ''
          ? searchSims.replace(/ /g, '').split(',')
          : [];
    }
    this.setState({ searchSims, keyword: searchSims, page: 1 }, () => {
      if (this.state.searchSims === '') {
        this.getActiveSims();
      }
    });
  }

  submitSearch = () => {
    this.getActiveSims();
  };

  render() {
    const {
      sims,
      markedSimList,
      total,
      page,
      limit,
      irPlans,
      ildPlans,
      addIrPayType,
      serviceType,
    } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
              <div className="side-heading mt-2">
                <h4 className="title">Bulk Add ILD/IR </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  value={this.state.serviceType}
                  id="serviceSelect"
                  onChange={(e) =>
                    this.setState(
                      {
                        serviceType: e.target.value,
                        serviceError: false,
                        page: 1,
                      },
                      () => {
                        this.getActiveSims();
                      }
                    )
                  }
                  required
                >
                  <option value="select">Select</option>
                  <option value="ild">ILD</option>
                  <option value="ir">IR</option>
                </select>
                <label
                  className="material-textfield-label"
                  htmlFor="serviceSelect"
                >
                  Select Service <span className="text-danger">*</span>
                </label>
                {this.state.serviceError && (
                  <div className="invalid-feedback">Service is required</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {serviceType && serviceType === 'ir' ? (
                <div className="form-group material-textfield">
                  <select
                    className="form-control form-control-lg custom-select material-textfield-input"
                    onChange={(e) =>
                      this.setState({
                        addIRBalance: parseFloat(e.target.value),
                        addIRBalanceError: '',
                      })
                    }
                    id="irBalanceSelect"
                  >
                    <option value={0}>Select</option>
                    {serviceType &&
                      _.size(irPlans) > 0 &&
                      irPlans.map((plan) => {
                        return (
                          <option key={plan.titleValue} value={plan.titleValue}>
                            {`$${plan.titleValue.toFixed(2)}`}
                          </option>
                        );
                      })}
                  </select>
                  <label
                    className="material-textfield-label"
                    htmlFor="irBalanceSelect"
                  >
                    Select balance amount <span className="text-danger">*</span>
                  </label>
                  {this.state.addIRBalanceError && (
                    <div className="invalid-feedback">
                      Balance amount is required
                    </div>
                  )}
                  {this.state.remainingIRBalanceError && (
                    <div className="invalid-feedback">
                      You have insufficient balance
                    </div>
                  )}
                </div>
              ) : (
                <div className="form-group material-textfield">
                  <select
                    className="form-control form-control-lg custom-select material-textfield-input"
                    onChange={(e) =>
                      this.setState({
                        addIldMinutes: parseFloat(e.target.value),
                        addIldMinutesError: '',
                      })
                    }
                  >
                    <option value={0}>Select</option>
                    {serviceType &&
                      _.size(ildPlans) > 0 &&
                      ildPlans.map((plan) => {
                        return (
                          <option key={plan.titleValue} value={plan.titleValue}>
                            {`${plan.titleValue} minutes`}
                          </option>
                        );
                      })}
                  </select>
                  <label className="material-textfield-label">
                    Select Minutes<span className="text-danger">*</span>
                  </label>
                  {this.state.addIldMinutesError && (
                    <div className="invalid-feedback">
                      Balance amount is required
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="select-btn-choice">
                  <button
                    className={
                      addIrPayType === 'oneTime' ? 'btn active' : 'btn'
                    }
                    onClick={(e) => this.setState({ addIrPayType: 'oneTime' })}
                  >
                    One Time
                  </button>
                  <button
                    className={
                      addIrPayType === 'recurring' ? 'btn active' : 'btn'
                    }
                    onClick={(e) =>
                      this.setState({ addIrPayType: 'recurring' })
                    }
                  >
                    Recurring
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Spin
            spinning={this.state.isLoading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <div className="card mb-4">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="card-title"> Active SIMs</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search-with-tags grid-block-container mobile-stack-container">
                    <div className="serach-items">
                      <div className="search-by-select">
                        <span className="search-by-select-label">
                          Search by:
                        </span>
                        <select
                          className="form-control custom-select col-md-2"
                          name="searchBy"
                          value={this.state.searchBy}
                          onChange={this.searchByChange.bind(this)}
                        >
                          <option value={'iccid'}>ICCID</option>
                          <option value={'msisdn'}>Phone Number</option>
                        </select>
                        <input
                          type="text"
                          value={this.state.keyword}
                          className="form-control form-control-lg material-textfield-input"
                          name="searchSims"
                          placeholder={`Search by ${
                            this.state.searchBy === 'iccid'
                              ? 'iccid'
                              : 'Phone Number'
                          }`}
                          onChange={this.handleSearch.bind(this)}
                          required
                        />
                      </div>
                      {total > 0 ? (
                        <div className="filter-page-number text-right mt-1">
                          {page > 1 ? (page - 1) * limit + 1 : 1}-
                          {limit * page < total ? limit * page : total} of{' '}
                          {total}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <button
                      className="btn btn-dark btn-lg"
                      onClick={this.submitSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table custom-table-secondary bulk-sim-activate-table">
                    <thead>
                      <tr>
                        <th>
                          <label className="custom-checkbox-only mr-3 align-middle">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="duplex"
                              checked={this.state.allChecked}
                              onChange={(e) => this.onCheck({}, 'All')}
                            />
                            <span className="checkmark-only"></span>
                          </label>
                          <span className="table-column-title">ICCID</span>
                        </th>
                        <th>
                          <span className="table-column-title">SIM Name</span>
                        </th>
                        <th>
                          <span className="table-column-title">MSISDN</span>
                        </th>
                        <th>
                          <span className="table-column-title">Tags</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.size(sims) > 0 &&
                        sims.map((item, index) => {
                          let tagsArr =
                            item.tags && item.tags !== ''
                              ? item.tags.split(',')
                              : [];
                          let checked =
                            markedSimList
                              .map((mark) => mark.id)
                              .indexOf(item.id) !== -1;
                          return (
                            <tr key={index}>
                              <td>
                                <label className="custom-checkbox-only mr-3 align-middle">
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    name="check"
                                    value={index}
                                    checked={checked}
                                    onChange={() => this.onCheck(item)}
                                  />
                                  <span className="checkmark-only"></span>
                                </label>
                                {_.get(item, 'simnumber', '') &&
                                _.get(item, 'simnumber', '') !== ''
                                  ? _.get(item, 'simnumber', '')
                                  : '-'}
                              </td>
                              <td>
                                {_.get(item, 'simname', '') &&
                                _.get(item, 'simname', '') !== ''
                                  ? _.get(item, 'simname', '-')
                                  : _.get(item, 'simnumber', '-')}
                              </td>
                              <td>{_.get(item, 'msisdn', '-')}</td>
                              <td>
                                {_.size(tagsArr) > 0 &&
                                  tagsArr.map((tag) => {
                                    return (
                                      <button
                                        key={tag}
                                        className="btn btn-tags mr-2"
                                      >
                                        {tag}
                                      </button>
                                    );
                                  })}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Spin>
          {this.state.errorMessage !== '' && (
            <div className="form-group">
              <div className="invalid-feedback">{this.state.errorMessage}</div>
            </div>
          )}
          <Pagination
            className="mb-5"
            hideOnSinglePage
            onChange={this.onPageChange.bind(this)}
            current={page}
            pageSize={limit}
            defaultCurrent={page}
            total={total}
            showSizeChanger={false}
          />
          <div className="form-group pb-4">
            <button
              disabled={
                this.state.isLoading ||
                this.state.isAddSimLoading ||
                _.size(markedSimList) === 0
              }
              className="btn btn-dark btn-lg w-100"
              onClick={this.submitRequest}
            >
              {this.state.isAddSimLoading
                ? 'Please wait...'
                : 'Submit Bulk Request'}
            </button>
          </div>
        </main>

        {/* BULK ACTIVATION DONE MODAL  */}
        <ReactModal
          isOpen={this.state.bulkActivateModal}
          // onRequestClose={() => this.setState({ bulkActivateModal: false })}
          ariaHideApp={false}
          contentLabel="Thank You - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Thank You
              </h5>
              <p className="react-modal-subtitle mt-2">
                Your SIM card(s) are processing. Check the notifications icon
                for updates.
              </p>
              {/* <button type="button" className="btn react-modal-close" onClick={() => this.setState({ bulkActivateModal: false })}><CloseIcon/></button> */}
              <div className="text-right mt-3">
                <button
                  onClick={this.bulkActivateOk}
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
