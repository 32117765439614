import { Record } from 'immutable';
import { assign } from 'lodash';
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/

export const CREATE_SETTING = 'ultra/setting/CREATE_SETTING';
export const FETCH_SETTING = 'ultra/setting/FETCH_SETTING';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const createSetting = (data) => ({
  type: CREATE_SETTING,
  payload: api.createSetting(data),
});

export const fetchSetting = () => ({
  type: FETCH_SETTING,
  payload: api.fetchSetting(),
});

export const settingEpic = combineEpics();
