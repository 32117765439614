/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo.png';
import {
  DashboardIcon,
  YourSimsIcon,
  YourFixedSimsIcon,
  ClientsIcon,
  ViewCoverageMapIcon,
  UsersIcon,
  SupportIcon,
  TermsConditionsIcon,
  RatesColorIcon,
  DeviceColorIcon,
  DeviceCoverageColorIcon,
} from '../../components/icons';
import {
  isAdmin,
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';
import './styles.scss';
import _ from 'lodash';

export default class SidebarNavigationComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedNav: '',
      simTab: '',
    };
  }

  componentDidMount = async () => {
    try {
      const { user } = this.props;
      if (_.isEmpty(user)) {
        const {
          value: { success, user },
        } = await this.props.fetchMe();
        if (success && user) {
          this.props.setUser(user);
          this.setSelectedNav();
          this.setSimTab();
        }
      } else {
        this.setSelectedNav();
        this.setSimTab();
      }
    } catch (err) {
      const error = err.data;
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }
  };

  // function to add comma's to a numerical value
  formatNumWithComma(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getLink = (user, selectedNav) => {
    if (user) {
      let html = '';
      if (user.permissions && user.permissions !== '') {
        const permissions = JSON.parse(user.permissions);
        const simsRoutes = _.sortBy(permissions, 'id').filter((per) => {
          return (
            per.url === '/active-sims' ||
            per.url === '/available-sims' ||
            per.url === '/available-esims' ||
            per.url === '/paused-sims' ||
            per.url === '/deactivated-sims'
          );
        });
        if (_.size(simsRoutes) > 0) {
          html = (
            <li
              className={
                selectedNav === 'yoursims'
                  ? 'sidebar-item sidebar-item-is-selected'
                  : 'sidebar-item'
              }
            >
              <Link className="sidebar-item__link" to={`${simsRoutes[0].url}`}>
                <span className="sidebar-item__icon">
                  <YourSimsIcon active={selectedNav} />
                </span>
                {user.role === 'user' || user.role === 'corporate_client'
                  ? 'Your SIMs'
                  : 'Your SPR SIMs'}
              </Link>
              <ul className="sidebar-layout__submenu">
                {checkPermission(user, '/active-sims') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'active-sims'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('active-sims');
                        this.props.setSortByKeys('activationdate', 'desc');
                      }}
                    >
                      Active (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'active', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/activation-in-progress') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'activation-in-progress'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('activation-in-progress');
                        this.props.setSortByKeys('activationdate', 'desc');
                      }}
                    >
                      In Progress (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'activationInProgress', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/available-sims') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'available-sims'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('available-sims');
                        this.props.setSortByKeys('activationdate', 'desc');
                      }}
                    >
                      Available Physical SIMs(
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'inactive', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/available-esims') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'available-esims'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('available-esims');
                        this.props.setSortByKeys('activationdate', 'desc');
                      }}
                    >
                      Available eSIMs (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'availableeSim', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/paused-sims') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'paused-sims'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('paused-sims');
                        this.props.setSortByKeys('datePlanChanged', 'desc');
                      }}
                    >
                      Paused (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'paused', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/deactivated-sims') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'deactivated-sims'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('deactivated-sims');
                        this.props.setSortByKeys('datePlanChanged', 'desc');
                      }}
                    >
                      {' '}
                      Deactivated (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'deactive', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/orders') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'spr-orders'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('spr-orders');
                      }}
                    >
                      {' '}
                      SPR SIM Orders (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'ordersCount', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
                {checkPermission(user, '/orders') && (
                  <li>
                    <button
                      className={
                        this.state.simTab === 'eSIM-orders'
                          ? 'submenu-item__link btn active'
                          : 'submenu-item__link btn'
                      }
                      onClick={(e) => {
                        this.setTabForSims('eSIM-orders');
                      }}
                    >
                      {' '}
                      SPR eSIM Orders (
                      {this.formatNumWithComma(
                        _.get(this.props.simCounts, 'eSIMOrdersCount', 0)
                      )}
                      )
                    </button>
                  </li>
                )}
              </ul>
            </li>
          );
        }
      } else {
        html = (
          <li
            className={
              selectedNav === 'yoursims'
                ? 'sidebar-item sidebar-item-is-selected'
                : 'sidebar-item'
            }
          >
            <Link className="sidebar-item__link" to={'/active-sims'}>
              <span className="sidebar-item__icon">
                <YourSimsIcon active={selectedNav} />
              </span>
              {user.role === 'user' || user.role === 'corporate_client'
                ? 'Your SIMs'
                : 'Your SPR SIMs'}
            </Link>
            <ul className="sidebar-layout__submenu">
              {checkPermission(user, '/active-sims') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'active-sims'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('active-sims');
                      this.props.setSortByKeys('activationdate', 'desc');
                    }}
                  >
                    Active (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'active', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/activation-in-progress') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'activation-in-progress'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('activation-in-progress');
                      this.props.setSortByKeys('activationdate', 'desc');
                    }}
                  >
                    In Progress (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'activationInProgress', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/available-sims') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'available-sims'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('available-sims');
                      this.props.setSortByKeys('activationdate', 'desc');
                    }}
                  >
                    Available Physical SIMs (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'inactive', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/available-esims') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'available-esims'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('available-esims');
                      this.props.setSortByKeys('activationdate', 'desc');
                    }}
                  >
                    Available eSIMs (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'availableeSim', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/paused-sims') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'paused-sims'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('paused-sims');
                      this.props.setSortByKeys('datePlanChanged', 'desc');
                    }}
                  >
                    Paused (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'paused', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/deactivated-sims') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'deactivated-sims'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('deactivated-sims');
                      this.props.setSortByKeys('datePlanChanged', 'desc');
                    }}
                  >
                    {' '}
                    Deactivated (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'deactive', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/orders') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'spr-orders'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('spr-orders');
                    }}
                  >
                    {' '}
                    SPR SIM Orders (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'ordersCount', 0)
                    )}
                    )
                  </button>
                </li>
              )}
              {checkPermission(user, '/orders') && (
                <li>
                  <button
                    className={
                      this.state.simTab === 'eSIM-orders'
                        ? 'submenu-item__link btn active'
                        : 'submenu-item__link btn'
                    }
                    onClick={(e) => {
                      this.setTabForSims('eSIM-orders');
                    }}
                  >
                    {' '}
                    SPR eSIM Orders (
                    {this.formatNumWithComma(
                      _.get(this.props.simCounts, 'eSIMOrdersCount', 0)
                    )}
                    )
                  </button>
                </li>
              )}
            </ul>
          </li>
        );
      }
      return html;
    }
  };

  getFixedLink = (user, selectedNav) => {
    if (user) {
      let html = '';
      html = (
        <li
          className={
            selectedNav === 'your-fixed-sims'
              ? 'sidebar-item sidebar-item-is-selected'
              : 'sidebar-item'
          }
        >
          <Link className="sidebar-item__link" to={'/fixed-active-sims'}>
            <span className="sidebar-item__icon">
              <YourFixedSimsIcon active={selectedNav} />
            </span>
            Your Fixed SIMs
          </Link>
          <ul className="sidebar-layout__submenu">
            <li>
              <button
                className={
                  this.state.simTab === 'fixed-active-sims'
                    ? 'submenu-item__link btn active'
                    : 'submenu-item__link btn'
                }
                onClick={(e) => {
                  this.setTabForSims('fixed-active-sims');
                  this.props.setSortByKeys('activationdate', 'desc');
                }}
              >
                Active (
                {this.formatNumWithComma(
                  _.get(this.props.simCounts, 'active', 0)
                )}
                )
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.simTab === 'fixed-activation-in-progress'
                    ? 'submenu-item__link btn active'
                    : 'submenu-item__link btn'
                }
                onClick={(e) => {
                  this.setTabForSims('fixed-activation-in-progress');
                  this.props.setSortByKeys('activationdate', 'desc');
                }}
              >
                In Progress (
                {this.formatNumWithComma(
                  _.get(this.props.simCounts, 'activationInProgress', 0)
                )}
                )
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.simTab === 'fixed-never-been-activated'
                    ? 'submenu-item__link btn active'
                    : 'submenu-item__link btn'
                }
                onClick={(e) => {
                  this.setTabForSims('fixed-never-been-activated');
                  this.props.setSortByKeys('activationdate', 'desc');
                }}
              >
                Never Been Activated (
                {this.formatNumWithComma(
                  _.get(this.props.simCounts, 'inactive', 0)
                )}
                )
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.simTab === 'fixed-paused-sims'
                    ? 'submenu-item__link btn active'
                    : 'submenu-item__link btn'
                }
                onClick={(e) => {
                  this.setTabForSims('fixed-paused-sims');
                  this.props.setSortByKeys('datePlanChanged', 'desc');
                }}
              >
                Paused (
                {this.formatNumWithComma(
                  _.get(this.props.simCounts, 'paused', 0)
                )}
                )
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.simTab === 'fixed-deactivated-sims'
                    ? 'submenu-item__link btn active'
                    : 'submenu-item__link btn'
                }
                onClick={(e) => {
                  this.setTabForSims('fixed-deactivated-sims');
                  this.props.setSortByKeys('datePlanChanged', 'desc');
                }}
              >
                {' '}
                Deactivated (
                {this.formatNumWithComma(
                  _.get(this.props.simCounts, 'deactive', 0)
                )}
                )
              </button>
            </li>
            {checkPermission(user, '/orders') && (
              <li>
                <button
                  className={
                    this.state.simTab === 'fixed-orders'
                      ? 'submenu-item__link btn active'
                      : 'submenu-item__link btn'
                  }
                  onClick={(e) => {
                    this.setTabForSims('fixed-orders');
                  }}
                >
                  {' '}
                  Fixed SIM Orders (
                  {this.formatNumWithComma(
                    _.get(this.props.simCounts, 'ordersCount', 0)
                  )}
                  )
                </button>
              </li>
            )}
          </ul>
        </li>
      );
      return html;
    }
  };

  setTabForSims = (simTab) => {
    if (simTab === 'active-sims') {
      this.props.onTabChange('active');
    } else if (simTab === 'available-sims') {
      this.props.onTabChange('inactive');
    } else if (simTab === 'available-esims') {
      this.props.onTabChange('availableeSim');
    } else if (simTab === 'paused-sims') {
      this.props.onTabChange('paused');
    } else if (simTab === 'deactivated-sims') {
      this.props.onTabChange('deactive');
    } else if (simTab === 'activation-in-progress') {
      this.props.onTabChange('activationInProgress');
    } else if (simTab === 'fixed-active-sims') {
      this.props.onTabChange('fixed-active');
    } else if (simTab === 'fixed-never-been-activated') {
      this.props.onTabChange('fixed-inactive');
    } else if (simTab === 'fixed-paused-sims') {
      this.props.onTabChange('fixed-paused');
    } else if (simTab === 'fixed-deactivated-sims') {
      this.props.onTabChange('fixed-deactive');
    } else if (simTab === 'fixed-activation-in-progress') {
      this.props.onTabChange('fixed-activationInProgress');
    } else if (simTab === 'spr-orders') {
      this.props.onTabChange('spr-orders');
    } else if (simTab === 'eSIM-orders') {
      this.props.onTabChange('eSIM-orders');
    } else if (simTab === 'fixed-orders') {
      this.props.onTabChange('fixed-orders');
    }
    this.setState({ simTab });
  };

  setSelectedNav = () => {
    const path = window.location.pathname;
    const prevTab = localStorage.getItem('prevTab');
    let selectedNav = '';
    if (
      path === '/dashboard' ||
      path === '/spr-dashboard' ||
      path === '/fixed-dashboard'
    ) {
      selectedNav = 'dashboard';
    } else if (
      path === '/active-sims' ||
      path === '/available-sims' ||
      path === '/available-esims' ||
      path === '/paused-sims' ||
      path === '/deactivated-sims' ||
      path === '/activation-in-progress' ||
      path === '/add-sim/upload-csv' ||
      path === '/add-sim/copy-and-paste' ||
      path === '/spr-orders' ||
      path === '/eSIM-orders' ||
      (path.indexOf('/detail') !== -1 && prevTab === 'spr')
    ) {
      selectedNav = 'yoursims';
    } else if (path === '/orders') {
      selectedNav = 'ordersims';
    } else if (path === '/clients') {
      selectedNav = 'clients';
    } else if (path === '/view-coverage-map') {
      selectedNav = 'map';
    } else if (path === '/legal/spr' || path === '/legal/fixed-wireless') {
      selectedNav = 'tnc';
    } else if (
      path === '/users/add-user' ||
      path === '/all-users' ||
      path === '/ultra-admins' ||
      path === '/partners'
    ) {
      selectedNav = 'users';
    } else if (
      path === '/your-fixed-sims' ||
      path === '/fixed-active-sims' ||
      path === '/fixed-never-been-activated' ||
      path === '/fixed-paused-sims' ||
      path === '/fixed-deactivated-sims' ||
      path === '/fixed-activation-in-progress' ||
      path === '/fixed-orders' ||
      path === '/add-fixed-sim/copy-and-paste' ||
      path === '/add-fixed-sim/upload-csv' ||
      (path.indexOf('/detail') !== -1 && prevTab === 'fixed')
    ) {
      selectedNav = 'your-fixed-sims';
    } else if (path === '/rates/ild') {
      selectedNav = 'rates';
    } else if (path === '/fw-device-list') {
      selectedNav = 'fwDevicelist';
    } else if (path === '/device-coverage') {
      selectedNav = 'coverageDetail';
    }

    this.setState({ selectedNav });
  };

  setSimTab = () => {
    const path = window.location.pathname;
    let simTab = '';
    if (path === '/active-sims') {
      simTab = 'active-sims';
    } else if (path === '/available-sims') {
      simTab = 'available-sims';
    } else if (path === '/available-esims') {
      simTab = 'available-esims';
    } else if (path === '/paused-sims') {
      simTab = 'paused-sims';
    } else if (path === '/deactivated-sims') {
      simTab = 'deactivated-sims';
    } else if (path === '/activation-in-progress') {
      simTab = 'activation-in-progress';
    } else if (path === '/fixed-active-sims') {
      simTab = 'fixed-active-sims';
    } else if (path === '/fixed-never-been-activated') {
      simTab = 'fixed-never-been-activated';
    } else if (path === '/fixed-paused-sims') {
      simTab = 'fixed-paused-sims';
    } else if (path === '/fixed-deactivated-sims') {
      simTab = 'fixed-deactivated-sims';
    } else if (path === '/fixed-activation-in-progress') {
      simTab = 'fixed-activation-in-progress';
    } else if (path === '/spr-orders') {
      simTab = 'spr-orders';
    } else if (path === '/eSIM-orders') {
      simTab = 'eSIM-orders';
    } else if (path === '/fixed-orders') {
      simTab = 'fixed-orders';
    }
    this.setState({ simTab });
  };

  isDeviceSetting(user) {
    let showCoverage = false;
    if (
      (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 1) &&
      user.deviceCoverageForSpr
    ) {
      showCoverage = true;
    }
    if (
      (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) &&
      user.deviceCoverageForFixed
    ) {
      showCoverage = true;
    }
    return showCoverage;
  }

  render() {
    const { user } = this.props;
    const { selectedNav } = this.state;
    return (
      <aside className="sidebar-layout-sider">
        <div className="sidebar-layout-sider__children">
          <div className="sidebar-layout-sider__logo">
            <Link to={'/dashboard'}>
              <img src={Logo} alt="" width="88" />
            </Link>
          </div>
          <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
            <li
              className={
                selectedNav === 'dashboard' || selectedNav === 'spr-dashboard'
                  ? 'sidebar-item sidebar-item-is-selected'
                  : 'sidebar-item'
              }
            >
              <Link
                className="sidebar-item__link"
                to={
                  user && user.role === 'superadmin'
                    ? '/spr-dashboard'
                    : parseInt(user.lobId) === 3
                    ? '/fixed-dashboard'
                    : '/dashboard'
                }
              >
                <span className="sidebar-item__icon">
                  <DashboardIcon active={selectedNav} />{' '}
                </span>{' '}
                Dashboard
              </Link>
            </li>
            {user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              parseInt(user.lobId) !== 3 &&
              this.getLink(user, selectedNav)}
            {user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) &&
              this.getFixedLink(user, selectedNav)}
            {user &&
              (isSPRPartner(user) ||
                (isSubUser(user) && checkPermission(user, '/clients'))) && (
                <li
                  className={
                    selectedNav === 'clients'
                      ? 'sidebar-item sidebar-item-is-selected'
                      : 'sidebar-item'
                  }
                >
                  <Link className="sidebar-item__link" to={'/clients'}>
                    <span className="sidebar-item__icon">
                      {' '}
                      <ClientsIcon active={selectedNav} />
                    </span>
                    Your Clients
                  </Link>
                </li>
              )}
            {user &&
              (isSPRPartner(user) ||
                (isSubUser(user) && checkPermission(user, '/rates'))) &&
              parseInt(user.lobId) !== 3 &&
              (parseInt(user.isILDEnabled) === 1 ||
                parseInt(user.isIREnabled) === 1) && (
                <li
                  className={
                    selectedNav === 'rates'
                      ? 'sidebar-item sidebar-item-is-selected'
                      : 'sidebar-item'
                  }
                >
                  <Link className="sidebar-item__link" to={'/rates/ild'}>
                    <span className="sidebar-item__icon">
                      <RatesColorIcon active={selectedNav} />
                    </span>{' '}
                    International Rates
                  </Link>
                </li>
              )}
            <li
              className={
                selectedNav === 'map'
                  ? 'sidebar-item sidebar-item-is-selected'
                  : 'sidebar-item'
              }
            >
              <Link className="sidebar-item__link" to={'/view-coverage-map'}>
                <span className="sidebar-item__icon">
                  <ViewCoverageMapIcon active={selectedNav} />
                </span>{' '}
                View Coverage Map
              </Link>
            </li>
            {user &&
              (isSPRPartner(user) ||
                checkPermission(this.props.user, '/support')) && (
                <li
                  className={
                    selectedNav === 'support'
                      ? 'sidebar-item sidebar-item-is-selected'
                      : 'sidebar-item'
                  }
                >
                  <a
                    href={
                      user.url
                        ? user.url
                        : 'https://kaena1.atlassian.net/servicedesk/customer/portal/38'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="sidebar-item__link"
                  >
                    <span className="sidebar-item__icon">
                      <SupportIcon active={selectedNav} />
                    </span>{' '}
                    Support
                  </a>
                </li>
              )}
            {user && isAdmin(user) && (
              <li
                className={
                  selectedNav === 'users'
                    ? 'sidebar-item sidebar-item-is-selected'
                    : 'sidebar-item'
                }
              >
                <Link className="sidebar-item__link" to={'/all-users'}>
                  <span className="sidebar-item__icon">
                    <UsersIcon active={selectedNav} />
                  </span>{' '}
                  Users
                </Link>
              </li>
            )}
            {user && isSPRPartner(user) && parseInt(user.lobId) !== 3 && (
              <li
                className={
                  selectedNav === 'tnc'
                    ? 'sidebar-item sidebar-item-is-selected'
                    : 'sidebar-item'
                }
              >
                <Link className="sidebar-item__link" to={'/legal/spr'}>
                  <span className="sidebar-item__icon">
                    <TermsConditionsIcon active={selectedNav} />
                  </span>{' '}
                  Legal
                </Link>
              </li>
            )}
            {user && isSPRPartner(user) && parseInt(user.lobId) === 3 && (
              <li
                className={
                  selectedNav === 'tnc'
                    ? 'sidebar-item sidebar-item-is-selected'
                    : 'sidebar-item'
                }
              >
                <Link
                  className="sidebar-item__link"
                  to={'/legal/fixed-wireless'}
                >
                  <span className="sidebar-item__icon">
                    <TermsConditionsIcon active={selectedNav} />
                  </span>{' '}
                  Legal
                </Link>
              </li>
            )}
            {user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              (parseInt(user.lobId) === 2 || parseInt(user.lobId) === 3) && (
                <li
                  className={
                    selectedNav === 'fwDevicelist'
                      ? 'sidebar-item sidebar-item-is-selected'
                      : 'sidebar-item'
                  }
                >
                  <Link className="sidebar-item__link" to={'/fw-device-list'}>
                    <span className="sidebar-item__icon">
                      <DeviceColorIcon active={selectedNav} />
                    </span>
                    FW Certified Device List
                  </Link>
                </li>
              )}
            {user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              this.isDeviceSetting(user) && (
                <li
                  className={
                    selectedNav === 'coverageDetail'
                      ? 'sidebar-item sidebar-item-is-selected'
                      : 'sidebar-item'
                  }
                >
                  <Link className="sidebar-item__link" to={'/device-coverage'}>
                    <span className="sidebar-item__icon">
                      <DeviceCoverageColorIcon active={selectedNav} />
                    </span>
                    Device/Coverage Checker
                  </Link>
                </li>
              )}
          </ul>
        </div>
      </aside>
    );
  }
}
