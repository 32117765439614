import { connect } from 'react-redux';
import { resetPassword, checkResetPasswordToken } from '../../store/user/duck';
import ResetPasswordComponent from './component';

const ResetPasswordContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    resetPassword,
    checkResetPasswordToken,
  }
)(ResetPasswordComponent);

export default ResetPasswordContainer;
