/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { MaintenanceIcon } from '../../components/icons';

import './styles.scss';

export default class MaintenanceComponent extends PureComponent {
  render() {
    return (
      <div className="maintenance-section">
        <div className="maintenance-wrapper">
          <div className="maintenance-icon">
            <MaintenanceIcon />
          </div>
          <h1 className="maintenance-title">
            We're sorry, we're currently undergoing maintenance.
          </h1>
          <p className="maintenance-subtitle">Please check back later.</p>
        </div>
      </div>
    );
  }
}
