/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactModal from 'react-modal';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { isSPRPartner } from '../../constants/common';
import { CloseIcon } from '../../components/icons';
import _ from 'lodash';
import 'react-phone-input-2/lib/style.css';
import './styles.scss';

const validateIPAddress = function (ipaddress) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
};

export default class PartnerAPIKeys extends PureComponent {
  constructor() {
    super();
    this.state = {
      user: {},
      countryCode: '1',
      isPageLoading: true,
      phone: '',
      otp: '',
      isSubmittingPhone: false,
      isSubmittingOtp: false,
      isSubmittingAPIkey: false,
      isAPIKeyAuthed: false,
      isOTPSent: false,
      phoneError: '',
      otpError: '',
      mobileLastFour: '',
      apiKey: '',
      apiKeys: [],
      apiKeyError: '',
      allowedIPs: '',
      isProcessing: false,
      modalIsOpen: false,
      updteError: '',
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Partner API Keys Generations | UltraIoT';
    try {
      const isAuthedAPI = sessionStorage.getItem('isAPIKeyAuthed');
      const {
        value: { user },
      } = await this.props.fetchMe();
      if (user) {
        this.props.setUser(user);
        this.setState({ user }, () => {
          this.getAPIKeys();
          if (isAuthedAPI) {
            this.setState({ isAPIKeyAuthed: true });
          }
        });
      }
    } catch (err) {
      const error = err.data;
      this.setState({ isPageLoading: false });
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      }
    }
  };

  isIpsAreValid = (allowedIPs) => {
    if (allowedIPs && allowedIPs !== '') {
      let ipsArray = allowedIPs.split(',');
      if (ipsArray && ipsArray.length > 0) {
        let isTrue = false;
        ipsArray.forEach(function (ip) {
          const onlyIP = ip.trim();
          isTrue = validateIPAddress(onlyIP);
        });
        return isTrue;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  handleCheckForAuth = async () => {
    try {
      const { user } = this.state;
      if (user) {
        this.setState({ user, isPageLoading: false });
        if (
          _.get(user, 'phone') &&
          _.get(user, 'countryCode') &&
          _.get(user, 'is2FAEnabled', 0) === 1
        ) {
          this.setState(
            {
              phone: _.get(user, 'phone'),
              countryCode: _.get(user, 'countryCode'),
              isOTPSent: true,
            },
            () => {
              this.sendOtp();
            }
          );
        }
      }
    } catch (error) {
      this.setState({
        phoneError: 'Unable to send OTP.',
        isOTPSent: false,
      });
    }
  };

  handleChange = (otp) =>
    this.setState({ otp }, () => {
      if (otp?.length === 6) {
        this.verifyOtp();
      }
    });

  sendOtp = async () => {
    const { phoneError, phone, user, countryCode } = this.state;
    this.setState({ isSubmittingPhone: true });
    try {
      if (!phone || phoneError || !user.id) {
        this.setState({
          isOTPSent: false,
          phoneError: 'Please fill your phone number.',
          isSubmittingPhone: false,
        });
        return;
      }
      const {
        value: { success },
      } = await this.props.sendOtp({ userId: user.id, phone, countryCode });
      if (success) {
        const mobileLastFour = phone?.substr(phone.length - 4);
        this.setState({
          isOTPSent: true,
          mobileLastFour,
          isSubmittingPhone: false,
        });
      }
    } catch (error) {
      this.setState({
        isOTPSent: false,
        phoneError: error.message,
        isSubmittingPhone: false,
      });
    }
  };

  reSendOtp = () => {
    this.sendOtp();
  };

  verifyOtp = async () => {
    this.setState({ isSubmittingOtp: true });
    try {
      const { phone, otp, user, countryCode } = this.state;
      if (!otp || !phone || !user.id) {
        this.setState({
          otpError: 'OTP is required.',
          otp: '',
          isSubmittingOtp: false,
        });
        return;
      }
      const {
        value: { success },
      } = await this.props.verifyOtp({
        phone,
        otp,
        userId: user.id,
        countryCode,
      });
      if (success) {
        this.setState({ isSubmittingOtp: false });
        this.props.setUser(user);
        sessionStorage.setItem('isAPIKeyAuthed', true);
        this.setState({ user });
        if (user && isSPRPartner(user)) {
          this.setState({ isAPIKeyAuthed: true }, () => {
            this.generateAPIKey();
          });
        }
      }
    } catch (error) {
      this.setState({
        otpError: error.message,
        otp: '',
        isSubmittingOtp: false,
      });
      return;
    }
  };

  getAPIKeys = async () => {
    const { user } = this.state;
    try {
      const {
        value: { success, apiKeys },
      } = await this.props.getAPIKeys({ userId: user.id });
      if (success) {
        this.setState({
          apiKeys,
          isPageLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        // apiKeyError: error.message,
        apiKeys: [],
        isPageLoading: false,
      });
    }
  };

  setIps = (e) => {
    const allowedIPs = e.target.value;
    if (!this.isIpsAreValid(allowedIPs)) {
      this.setState({
        allowedIPsError: 'All Ips should be comma (,) separated only.',
        isSubmittingAPIkey: false,
        isPageLoading: false,
      });
      return;
    } else {
      this.setState({
        allowedIPsError: '',
        allowedIPs: e.target.value,
      });
    }
  };
  generateAPIKey = async () => {
    const { isAPIKeyAuthed, user, allowedIPs } = this.state;
    this.setState({ isSubmittingAPIkey: true, isPageLoading: true });
    try {
      if (!isAPIKeyAuthed) {
        this.setState({
          isOTPSent: false,
          phoneError:
            'You are not authorized, refresh your screen and try again.',
          isSubmittingAPIkey: false,
        });
        return;
      }
      if (!allowedIPs) {
        this.setState({
          allowedIPsError: 'Ips is required.',
          isSubmittingAPIkey: false,
          isPageLoading: false,
        });
        return;
      }

      const {
        value: { success, apiKey },
      } = await this.props.generateAPIKey({ userId: user.id, allowedIPs });
      if (success) {
        this.setState({
          apiKey,
          isSubmittingAPIkey: false,
          isPageLoading: false,
        });
        window.location.reload();
      }
    } catch (error) {
      this.setState({
        apiKeyError: error.message,
        isSubmittingAPIkey: false,
        isPageLoading: false,
      });
    }
  };

  closeModal() {
    this.setState({
      modalIsOpen: false,
      errorMessage: '',
      isProcessing: false,
      allowedIPs: '',
      updteError: '',
    });
  }

  updateIps = async () => {
    const { user, allowedIPs, id } = this.state;
    this.setState({ isProcessing: true });
    try {
      if (!allowedIPs) {
        this.setState({
          allowedIPsError: 'Ips is required.',
          isProcessing: false,
        });
        return;
      }
      const {
        value: { success },
      } = await this.props.generateAPIKey({ userId: user.id, allowedIPs, id });
      if (success) {
        this.setState({
          allowedIPs,
          id: 0,
          isProcessing: false,
          modalIsOpen: false,
        });
        this.getAPIKeys();
      }
    } catch (error) {
      this.setState({
        updteError: error.message,
        isProcessing: false,
      });
    }
  };

  openModal(apiKeyData) {
    this.setState({
      modalIsOpen: true,
      id: apiKeyData.id,
      allowedIPs: apiKeyData.allowedIPs,
    });
  }

  render() {
    const {
      otpError,
      isPageLoading,
      isSubmittingPhone,
      isSubmittingOtp,
      isOTPSent,
      otp,
      mobileLastFour,
      apiKeys,
      apiKeyError,
      allowedIPsError,
      isProcessing,
      allowedIPs,
    } = this.state;
    return (
      <>
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-cta">
            <button
              className="btn btn-link btn-back"
              onClick={() => this.props.history.goBack()}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <>
                {!isOTPSent && apiKeys && apiKeys.length > 0 ? (
                  <>
                    <div className="card">
                      <div className="card-header d-flex align-items-center">
                        <div className="">
                          <h5 className="card-title">API keys</h5>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table custom-table-secondary table-nowidth">
                            <thead>
                              <tr>
                                <th>
                                  <span className="table-column-title">
                                    Type
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    Connect API Key
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    Connect API Secret
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    Created Date
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    Whitelist IPs
                                  </span>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {apiKeys &&
                                apiKeys.length > 0 &&
                                apiKeys.map((apiKeyData, index) => {
                                  return (
                                  <tr key={index}>
                                    <td className="text-left">
                                      { apiKeyData.hasOwnProperty('type') && apiKeyData.type !== '' ? apiKeyData.type : '-'}
                                    </td>
                                    <td className="text-left">
                                      { apiKeyData.hasOwnProperty('connectAPIkey') && apiKeyData.connectAPIkey !== '' ? apiKeyData.connectAPIkey : '-'}
                                    </td>
                                    <td className="text-left">
                                      { apiKeyData.hasOwnProperty('connectAPISecret') && apiKeyData.connectAPISecret !== '' ? apiKeyData.connectAPISecret : '-'}
                                    </td>
                                    <td className="text-left">
                                      { apiKeyData.hasOwnProperty('date') && apiKeyData.date !== '' ? moment(apiKeyData.date).format("LLL") : '-'}
                                    </td>
                                    <td className="text-left">
                                      {apiKeyData.hasOwnProperty('allowedIPs') && apiKeyData.allowedIPs !== '' ? apiKeyData.allowedIPs : '-'}
                                    </td>
                                    <td>
                                      <EditOutlined
                                        onClick={this.openModal.bind(
                                          this,
                                          apiKeyData
                                        )}
                                      />
                                    </td>
                                  </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isOTPSent && !isPageLoading ? (
                      <>
                        <div className="generate-key-container">
                          <div className="card">
                            <div className="card-header d-flex align-items-center">
                              <div className="">
                                <h5 className="card-title">
                                  Generate Your API key
                                </h5>
                              </div>
                            </div>
                            <div className="card-body card-body-sm">
                              <p className="card-body-text">
                                Enter the IP addresses which you want to
                                whitelist to access the API key.
                                <br />
                                Insert list of Ips comma separated only, Range,
                                Dashes, Special chars not allowed.
                              </p>
                              <div className="form-group">
                                <textarea
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="ipslist"
                                  placeholder="192.168.29.34, 192.168.29.35"
                                  onChange={(e) => this.setIps(e)}
                                  required
                                  rows="4"
                                ></textarea>
                                <div className="invalid-feedback">
                                  {allowedIPsError}
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="invalid-feedback">
                                  {apiKeyError}
                                </div>
                              </div>
                              <div className="authCodeWarp">
                                <button
                                  disabled={isSubmittingPhone}
                                  onClick={this.handleCheckForAuth}
                                  type="submit"
                                  className="btn btn-dark btn-lg w-100"
                                >
                                  {isSubmittingPhone
                                    ? 'Please wait ...'
                                    : 'Generate Key'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {!isPageLoading && (
                          <div className="oauth-authentication-2-0">
                            <div className="oauth-authentication-inner">
                              <div className="oauth-authentication-body">
                                <div className="card mb-0">
                                  <div className="card-body">
                                    <div
                                      id="verifyOTPscreen"
                                      className="verifyOTPscreen"
                                    >
                                      <h2 className="title">
                                        Confirm your session{' '}
                                      </h2>
                                      <p className="subtitle">
                                        We have sent you a code via SMS to your
                                        number ending in
                                        <b className="ml-1">{mobileLastFour}</b>
                                        .
                                      </p>
                                      <p className="subtitle">
                                        Didn't receive a code?
                                        <ResendOTP
                                          onResendClick={() => this.reSendOtp()}
                                          className="otp-resend"
                                          renderButton={renderButton}
                                          renderTime={renderTime}
                                        />
                                      </p>
                                      <div className="form-group">
                                        <OTPInput
                                          className="otp-input"
                                          value={otp}
                                          onChange={(otp) => {
                                            this.handleChange(otp);
                                          }}
                                          autoFocus
                                          OTPLength={6}
                                          otpType="number"
                                          disabled={false}
                                          secure
                                        />
                                        <div className="invalid-feedback">
                                          {otpError}
                                        </div>
                                      </div>
                                      <button
                                        disabled={isSubmittingOtp}
                                        onClick={this.verifyOtp}
                                        type="submit"
                                        className="btn btn-dark btn-lg w-100"
                                      >
                                        {isSubmittingOtp
                                          ? 'Please wait ...'
                                          : 'Verify'}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Add Team Member"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Edit IPs</h5>
            </div>
            <div className="react-modal-body">
              <div className="">
                <div className="form-group material-textfield">
                  <div className="verifyOTPscreen">
                    <p className="subtitle">
                      {' '}
                      Enter the IP addresses which you want to whitelist to
                      access the API key. Insert list of IPs comma separated
                    </p>

                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control form-control-lg material-textfield-input"
                        name="ipslist"
                        value={allowedIPs}
                        placeholder="192.168.29.34, 192.168.29.35"
                        onChange={(e) => {
                          this.setState({
                            allowedIPsError: '',
                            allowedIPs: e.target.value,
                          });
                        }}
                        required
                        rows="4"
                      ></textarea>
                      <div className="invalid-feedback">{allowedIPsError}</div>
                    </div>
                    <div className="form-group">
                      <div className="invalid-feedback">
                        {this.state.updteError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-dark btn-lg w-100"
                disabled={isProcessing}
                onClick={this.updateIps.bind(this)}
              >
                {isProcessing ? 'Please wait ...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}

const renderButton = (buttonProps) => {
  return (
    <button {...buttonProps}>
      {buttonProps.remainingTime !== 0
        ? `Please wait for ${buttonProps.remainingTime} sec`
        : 'Resend'}
    </button>
  );
};
const renderTime = () => React.Fragment;
