import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { formatMegaBytes } from '../../constants/common';
import './styles.scss';

function MonthOverMonthPlan(props) {
  document.title = 'Month Over Month Plan | UltraIoT';
  const [usageDetails, setusageDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const countPerPage = 30;

  useEffect(() => {
    setLoading(true);
    const getAverageUsagePlan = async (pge) => {
      const simType = localStorage.getItem('simType');
      try {
        const body = { type: 'mom', simType, limit: countPerPage, page: pge };
        const {
          value: { success, data },
        } = await props.fetchAverageUsagePlan(body);
        if (success) {
          setusageDetails(data);
          setLoading(false);
        }
      } catch (error) {
        // console.log(error)
        setusageDetails([]);
      }
    };

    getAverageUsagePlan(page);
  }, [page, props]);

  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.push('/dashboard')}
            >
              <BackArrowIcon className="back-icon" /> Back to Dashboard
            </button>
          </div>
          <p className="ml-3 large-para-one">
            Note: Usage Data based on individual subscriber's 30 day cycle NOT
            on the calendar month. Previous period is the most recent closed 30
            day period for your SIMs.
          </p>
          <div className="col-md-8 text-right"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {usageDetails && usageDetails.length > 0 ? (
                <>
                  <div className="card mb-3">
                    <div className="table-responsive">
                      <table className="table custom-table-secondary table-nowidth average-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">
                                Month over Month Usage
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Rate Plan ID
                              </span>
                            </th>
                            <th colSpan="3">
                              <div className="average-usage-status-text text-right">
                                <span className="average-usage-status-previous">
                                  Previous Period
                                </span>{' '}
                                |{' '}
                                <span className="average-usage-status-previous">
                                  2 Periods Ago
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {usageDetails.map((usage, inx) => {
                            return (
                              <tr key={inx}>
                                <td>
                                  <span className="average-title btnlinkorange">
                                    {usage.offername}
                                  </span>
                                </td>
                                <td>
                                  <span className="average-title">
                                    {usage.offerid || usage.productid}
                                  </span>
                                </td>
                                <td>
                                  <div className="average-data-top">
                                    {usage.previous_month_data_usage &&
                                      formatMegaBytes(
                                        usage.previous_month_data_usage,
                                        2
                                      )}{' '}
                                    |{' '}
                                    {usage.two_months_back_data_usage &&
                                      formatMegaBytes(
                                        usage.two_months_back_data_usage,
                                        2
                                      )}{' '}
                                  </div>
                                  <div className="average-data-bottom">
                                    Total Data
                                  </div>
                                </td>
                                <td>
                                  <div className="average-data-top">
                                    {usage.previous_month_sms_usage
                                      ? usage.previous_month_sms_usage
                                      : '-'}{' '}
                                    |{' '}
                                    {usage.two_months_back_sms_usage
                                      ? usage.two_months_back_sms_usage
                                      : '-'}{' '}
                                  </div>
                                  <div className="average-data-bottom">
                                    Total SMS
                                  </div>
                                </td>
                                <td>
                                  <div className="average-data-top">
                                    {usage.previous_month_voice_usage
                                      ? usage.previous_month_voice_usage
                                      : '-'}{' '}
                                    |{' '}
                                    {usage.two_months_back_voice_usage
                                      ? usage.two_months_back_voice_usage
                                      : '-'}{' '}
                                  </div>
                                  <div className="average-data-bottom">
                                    Total Voice
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={countPerPage}
                      defaultCurrent={page}
                      total={0}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  <div className="blank__table__card__inner">
                    No record found
                  </div>
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MonthOverMonthPlan;
