import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
//import data from "./data.json";

import './styles.scss';
import { downloadFileFromBlob } from '../../../utils/helpers';

const CdrDataFilesComponent = (props) => {
  const [cdrData, setCDRData] = useState([]);
  const [isCDRloading, setCDRloading] = useState(false);
  const [isDownLoading, setisDownLoading] = useState(false);

  // Action to download cdr files from API
  const handleCdrFileDownload = async (keyData) => {
    setisDownLoading(true);
    message.success('Your file is being downloaded');
    try {
      const { value: blob } = await props.downloadCdrFile({
        key: keyData.Key,
      });
      downloadFileFromBlob(blob, keyData.Key.split('/')[2]);
      setisDownLoading(false);
    } catch (error) {
      message.error('Error while Downloading the CDR File');
      setisDownLoading(false);
    }
  };

  useEffect(() => {
    const tab = _.get(props, 'location.pathname', '');
    let type = 'spr';
    if (tab === '/fixed-cdr-data-files') {
      type = 'fixed';
    }
    const currentMonth = moment(new Date()).format('MMMM Y');
    setCDRloading(true);
    document.title = 'CDR Data Files | UltraIoT';
    const getCDRData = async (date) => {
      setisDownLoading(true);
      let obj = {
        month: date,
        type,
      };
      const {
        value: { success, data },
      } = await props.getCDRData(obj);
      setisDownLoading(false);
      if (success) {
        setCDRData(data);
        setCDRloading(false);
      }
    };
    getCDRData(currentMonth);
  }, [props]);

  let usaseMonths = [];
  cdrData &&
    cdrData.length > 0 &&
    cdrData.map((record) => {
      if (record.Key.indexOf('/daily') !== -1) {
        const tempDate = record.Key.split('.')[4];
        const date = tempDate.slice(0, -2);
        const monthYear = moment(date).format('MMMM Y');
        const mm = moment(date).format('MM');
        record.date = date;
        const index = usaseMonths.findIndex((item) => {
          return String(item.month) === String(monthYear);
        });
        if (index === -1) {
          usaseMonths.push({
            mm,
            month: monthYear,
            dailyRecord: [],
            monthlyRecord: null,
          });
        }
        const monthRecord = usaseMonths.find((item) => {
          return String(item.month) === String(monthYear);
        });
        if (monthRecord) {
          monthRecord.dailyRecord.push(record);
        }
      } else if (record.Key.indexOf('/monthly') !== -1) {
        const tempDate = record.Key.split('.')[3];
        const monthYear = moment(tempDate).format('MMMM Y');
        const monthRecord = usaseMonths.find((item) => {
          return String(item.month) === String(monthYear);
        });
        if (monthRecord) {
          monthRecord.monthlyRecord = record;
        }
      }
      return true; // for Eslint rule
    });
  usaseMonths = _.orderBy(usaseMonths, (a) => moment(a.month), 'asc');
  // filtering usageArrary to get data of the current month & last 2 months only
  if (usaseMonths && usaseMonths.length && usaseMonths.length > 3) {
    usaseMonths = usaseMonths.slice(-3);
  }

  return (
    <div className="card-container">
      <div className="card-wrapper card-wrapper-lg ">
        <div className="card mb-0">
          <Spin
            spinning={isDownLoading || isCDRloading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <div className="card-header d-flex align-items-center">
              <div>
                <h5 className="card-title">CDR Data Files</h5>
              </div>
            </div>
            <div className="">
              <table className="table table-custom-normal">
                <tbody>
                  {usaseMonths && usaseMonths.length > 0 ? (
                    usaseMonths.map((monthData, index) => {
                      return (
                        <tr key={index}>
                          <td>{monthData.month}</td>
                          <td className="text-center">&nbsp;</td>
                          <td className="text-center">
                            {monthData.monthlyRecord ? (
                              <p
                                className="btn btn-xs text-purple font-16 text-center"
                                onClick={() => {
                                  handleCdrFileDownload(
                                    monthData.monthlyRecord
                                  );
                                }}
                              >
                                Download Monthly Usage Files
                              </p>
                            ) : (
                              'N/A'
                            )}
                          </td>
                          <td className="text-center">
                            {monthData.dailyRecord.length > 0 ? (
                              <Link
                                className="btn btn-xs text-purple font-16"
                                to={{
                                  pathname: `/cdr-data-files/${monthData.month.replace(
                                    ' ',
                                    '-'
                                  )}`,
                                  state: {
                                    recordData: monthData.dailyRecord,
                                  },
                                }}
                              >
                                View Daily Usage Files
                              </Link>
                            ) : (
                              'N/A'
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default CdrDataFilesComponent;
