// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable';
import { assign } from 'lodash';
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
// import { get } from "lodash"

// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// ULTRA PHASE START
export const LOGIN_USER = 'ultra/user/LOGIN_USER';
export const SEND_OTP = 'ultra/user/SEND_OTP';
export const VERIFY_OTP = 'ultra/user/VERIFY_OTP';
export const GENERATE_API_KEY = 'ultra/user/GENERATE_API_KEY';
export const GET_API_KEYS = 'ultra/user/GET_API_KEYS';
export const SIGNUP_USER = 'ultra/user/SIGNUP_USER';
export const FORGOT_PASSWORD = 'ultra/user/FORGOT_PASSWORD';
export const RESET_PASSWORD = 'ultra/user/RESET_PASSWORD';
export const CHECK_RESET_PASSWORD_TOKEN =
  'ultra/user/CHECK_RESET_PASSWORD_TOKEN';
export const FETCH_ME = 'ultra/user/FETCH_ME';
export const FETCH_SCOPE = 'ultra/user/FETCH_SCOPE';
export const FETCH_USER = 'ultra/user/FETCH_USER';
export const UPDATE_PROFILE = 'ultra/user/UPDATE_PROFILE';
export const GET_ALL_USER = 'ultra/user/GET_ALL_USER';
export const UPDATE_USER = 'ultra/user/UPDATE_USER';
export const IMPERSONATE_USER = 'ultra/user/IMPERSONATE_USER';
export const CHANGE_PASSWORD = 'ultra/user/CHANGE_PASSWORD';
export const ADD_DOCUMENT = 'ultra/user/ADD_DOCUMENT';
export const FETCH_DOCUMENTS = 'ultra/user/FETCH_DOCUMENTS';
export const DELETE_DOCUMENT = 'ultra/user/DELETE_DOCUMENT';
export const CLEAR_NOTIFICATION = 'ultra/user/CLEAR_NOTIFICATION';
export const SET_NOTIFICATION = 'ultra/user/SET_NOTIFICATION';
export const SET_REDUX_NOTIFICATION = 'ultra/user/SET_REDUX_NOTIFICATION';
export const SET_REDUX_USER = 'ultra/user/SET_REDUX_USER';
export const SET_REDUX_PERMISSION = 'ultra/user/SET_REDUX_PERMISSION';
export const FETCH_PERMISSIONS = 'ultra/user/FETCH_PERMISSIONS';
export const FETCH_SUBUSERS = 'ultra/user/FETCH_SUBUSERS';
export const ADD_SUBUSER = 'ultra/user/ADD_SUBUSER';
export const PRE_SIGNED_URL = 'ultra/user/PRE_SIGNED_URL';
export const GET_CDR_DATA = 'ultra/user/GET_CDR_DATA';
export const FETCH_INVITE_USERDETAIL = 'ultra/user/FETCH_INVITE_USERDETAIL';
export const ACCEPT_INVITE = 'ultra/user/ACCEPT_INVITE';
export const ADD_CREDITS = 'ultra/plan/ADD_CREDITS';
export const FETCH_USER_PLANS = 'ultra/user/FETCH_USER_PLANS';
export const UPDATE_PLANS = 'ultra/user/UPDATE_PLANS';
export const DELETE_USER = 'ultra/user/DELETE_USER';
export const CHECK_OUTAGE = 'ultra/user/CHECK_OUTAGE';
export const UPDATE_STATUS = 'ultra/user/UPDATE_STATUS';
export const UNBLOCK_USER = 'ultra/user/UNBLOCK_USER';
export const UPDATE_SPR_USER = 'ultra/user/UPDATE_SPR_USER';
export const GET_PARTNER_DETAILS = 'ultra/user/GET_PARTNER_DETAILS';
export const UPDATE_SUPPORT_URL = 'ultra/user/UPDATE_SUPPORT_URL';
export const FETCH_ALL_USER_PLANS = 'ultra/user/FETCH_ALL_USER_PLANS';
export const FETCH_DOCUMENTS_FILE_DATA = 'ultra/user/FETCH_DOCUMENTS_FILE_DATA';
export const DOWNLOAD_CDR_FILE = 'ultra/user/DOWNLOAD_CDR_FILE';
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  notifications: [],
  userPermission: [],
  user: {},
  notificationCount: 0,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SET_REDUX_NOTIFICATION: {
      const {
        payload: { userNotificationsCount, userNotifications },
      } = action;
      return state
        .set('notifications', userNotifications)
        .set('notificationCount', userNotificationsCount);
    }

    case SET_REDUX_USER: {
      const { payload } = action;
      return state.set('user', payload);
    }

    case SET_REDUX_PERMISSION: {
      const { payload } = action;
      return state.set('userPermission', payload);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const loginUser = (data) => {
  return {
    type: LOGIN_USER,
    payload: api.loginUser(data),
  };
};

export const sendOtp = (data) => {
  return {
    type: SEND_OTP,
    payload: api.sendOtp(data),
  };
};

export const verifyOtp = (data) => {
  return {
    type: VERIFY_OTP,
    payload: api.verifyOtp(data),
  };
};

export const generateAPIKey = (data) => {
  return {
    type: GENERATE_API_KEY,
    payload: api.generateAPIKey(data),
  };
};

export const getAPIKeys = (data) => {
  return {
    type: GET_API_KEYS,
    payload: api.getAPIKeys(data),
  };
};

export const signUpUser = (data) => {
  return {
    type: SIGNUP_USER,
    payload: api.signUpUser(data),
  };
};

export const forgotPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    payload: api.forgotPassword(data),
  };
};

export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: api.resetPassword(data),
  };
};

export const checkResetPasswordToken = (data) => {
  return {
    type: CHECK_RESET_PASSWORD_TOKEN,
    payload: api.checkResetPasswordToken(data),
  };
};

export const fetchMe = () => {
  return {
    type: FETCH_ME,
    payload: api.fetchMe(),
  };
};

export const fetchScope = () => {
  return {
    type: FETCH_SCOPE,
    payload: api.fetchScope(),
  };
};

export const fetchUser = () => {
  return {
    type: FETCH_ME,
    payload: api.fetchUser(),
  };
};

export const updateProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: api.updateProfile(data),
});

export const updateSupportUrl = (data) => ({
  type: UPDATE_SUPPORT_URL,
  payload: api.updateSupportUrl(data),
});

export const getUsers = (credentials) => {
  return {
    type: GET_ALL_USER,
    payload: api.getUsers(credentials),
  };
};

export const updateUsers = (credentials) => {
  return {
    type: UPDATE_USER,
    payload: api.updateUsers(credentials),
  };
};

export const userImpersonate = (credentials) => {
  return {
    type: IMPERSONATE_USER,
    payload: api.userImpersonate(credentials),
  };
};

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: api.changePassword(data),
});

export const fetchDocuments = (data) => ({
  type: FETCH_DOCUMENTS,
  payload: api.fetchDocuments(data),
});

export const addDocument = (data) => ({
  type: ADD_DOCUMENT,
  payload: api.addDocument(data),
});

export const deleteDocument = (data) => ({
  type: DELETE_DOCUMENT,
  payload: api.deleteDocument(data),
});

export const clearNotifications = (data) => ({
  type: CLEAR_NOTIFICATION,
  payload: api.clearNotifications(data),
});

export const setNotificationStatus = (data) => ({
  type: SET_NOTIFICATION,
  payload: api.setNotificationStatus(data),
});

export const setNotification = (data) => ({
  type: SET_REDUX_NOTIFICATION,
  payload: data,
});

export const setUser = (data) => ({
  type: SET_REDUX_USER,
  payload: data,
});

export const setUserPermissions = (data) => ({
  type: SET_REDUX_PERMISSION,
  payload: data,
});

export const fetchPermissions = () => ({
  type: FETCH_PERMISSIONS,
  payload: api.fetchPermissions(),
});

export const fetchSubUsers = () => ({
  type: FETCH_SUBUSERS,
  payload: api.fetchSubUsers(),
});

export const addSubUser = (data) => ({
  type: ADD_SUBUSER,
  payload: api.addSubUser(data),
});
export const getSignedURL = (data) => ({
  type: PRE_SIGNED_URL,
  payload: api.getSignedURL(data),
});
export const getCDRData = (data) => ({
  type: GET_CDR_DATA,
  payload: api.getCDRData(data),
});

export const fetchInviteUserDetail = (token) => ({
  type: FETCH_INVITE_USERDETAIL,
  payload: api.fetchInviteUserDetail(token),
});

export const acceptInvite = (data) => ({
  type: ACCEPT_INVITE,
  payload: api.acceptInvite(data),
});

export const addCredits = (data) => ({
  type: ADD_CREDITS,
  payload: api.addCredits(data),
});

export const fetchUserPlans = (id) => {
  return {
    type: FETCH_ME,
    payload: api.fetchUserPlans(id),
  };
};

export const fetchAllUserPlanById = (id) => {
  return {
    type: FETCH_ALL_USER_PLANS,
    payload: api.fetchAllUserPlanById(id),
  };
};

export const updatePlans = (data) => ({
  type: UPDATE_PLANS,
  payload: api.updatePlans(data),
});

export const deleteUser = (data) => ({
  type: DELETE_USER,
  payload: api.deleteUser(data),
});

export const checkOutage = () => ({
  type: CHECK_OUTAGE,
  payload: api.checkOutage(),
});

export const updateUserStatus = (data) => ({
  type: UPDATE_STATUS,
  payload: api.updateUserStatus(data),
});

export const unblockUser = (data) => ({
  type: UNBLOCK_USER,
  payload: api.unblockUser(data),
});

export const updatePartnerProfile = (data) => ({
  type: UPDATE_SPR_USER,
  payload: api.updatePartnerProfile(data),
});

export const getPartnerDetailFromUltra = (data) => ({
  type: GET_PARTNER_DETAILS,
  payload: api.getPartnerDetailFromUltra(data),
});

export const fetchFileDataFromDocumentId = (data) => ({
  type: FETCH_DOCUMENTS_FILE_DATA,
  payload: api.fetchFileDataFromDocumentId(data),
});

export const downloadCdrFile = (data) => ({
  type: DOWNLOAD_CDR_FILE,
  payload: api.downloadCdrFile(data),
})

/***********************************
 * Epics
 ***********************************/

export const userEpic = combineEpics();
