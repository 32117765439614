import { z } from 'zod';

const simResponseSchemas = {
  getAccountNumber: z.object({
    success: z.boolean(),
    data: z.string().transform((val) => Number(val)),
  }),
  getSimCardDetail: z.object({
    success: z.boolean(),
    data: z.object({
      id: z.number(),
      simnumber: z.string(),
      isEsim: z.number(),
      userid: z.number(),
      simtype: z.string(),
      simname: z.string(),
      imeinumber: z.string(),
      msisdn: z.string(),
      ultraplan: z.string(),
      username: z.string(),
      password: z.string(),
      tags: z.string(),
      ultraUserId: z.string(),
      activationdate: z.string(),
      msisdnUpdateDate: z.string(),
      planChangedDate: z.string(),
      billingstartdate: z.string(),
      billingenddate: z.string(),
      status: z.number(),
      QRActivationCode: z.string(),
      SMDPAddress: z.string(),
      ActivationCode: z.string(),
      PdfUrl: z.string(),
      datecreated: z.string(),
      datemodified: z.string(),
      planid: z.number(),
      reconnectTime: z.number().nullable(),
      title: z.string(),
      role: z.string(),
      offername: z.string(),
      productid: z.number(),
      offerId: z.number(),
      requestId: z.number(),
      newOfferName: z.string(),
      monthlyprice: z.number(),
      overageprice: z.number(),
      dataprice: z.number(),
      smsprice: z.number(),
      voiceprice: z.number(),
      duration: z.string(),
      unit: z.number(),
      category: z.string(),
      orderid: z.string(),
      ordernumber: z.string(),
      isILDEnabled: z.number(),
      isIREnabled: z.number(),
    }),
  }),
  getActivePortInRequestData: z.object({
    success: z.boolean(),
    data: z.null().or(
      z.object({
        title: z.string(),
        msisdn: z.string(),
        accountNumber: z.string(),
        accountPassword: z.string(),
        zipcode: z.string(),
        status: z.string(),
        reason: z.string(),
        datecreated: z.string(),
        datemodified: z.string(),
      })
    ),
  }),
  changePhoneNumber: z.object({
    success: z.boolean(),
    message: z.string(),
  }),
} as const;

export default simResponseSchemas;
