import { connect } from 'react-redux';
import {
  fetchMe,
  clearNotifications,
  setNotificationStatus,
  setNotification,
  setUser,
  userImpersonate,
} from '../../store/user/duck';
import { searchResults } from '../../store/dashboard/duck';
import TopNavigationComponent from './component';

const TopNavigationContainer = connect(
  // Map state to props
  (state) => ({
    notifications: state.user.notifications,
    notificationCount: state.user.notificationCount,
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    clearNotifications,
    setNotificationStatus,
    setNotification,
    setUser,
    searchResults,
    userImpersonate,
  }
)(TopNavigationComponent);

export default TopNavigationContainer;
