import { connect } from 'react-redux';
import { fetchMe, fetchScope, setUser } from '../../store/user/duck';
import {
  coverageDetail,
  getDeviceCompatibility,
  getBrands,
} from '../../store/coverageDetail/duck';
import {
  activateSims,
  pauseSims,
  deleteSims,
  deactivateSims,
  reActivateSimByPlan,
  updateSimName,
  updateTag,
  getUsageDailySummary,
  getSwapSimSearch,
  updateSimPortInRequest,
  simPortInCancel,
  swapSIM,
  swapESIM,
  enableWifiCalling,
  refreshPortInStatus,
  reconnectNetwork,
  adminOverride,
  fetchSimCounts,
  changePhoneNumber,
  setCount,
  updateAddOnPlans,
  bulkManageAddOns,
  cancelRecurringPayment,
  UpdateVoicemailStatus,
  getVoiceMailStatus,
  getWifiCallingDetails,
  getDataUsageFromUltra,
  reActivateSim,
  unPauseSim,
  fetchPlans,
  getEsimInfo,
  getEsimQuantity,
  getPreSignedUrl,
  planChangeCount,
  planDetails,
  simcardDetail,
  planCycle,
  accountNumber,
  getActivitiesLogs,
  hidePlanCycle,
  pukCodes,
  reconnectWaitTime,
  addOnPlans,
  addOnPlansUsage,
  irIldDetails,
  recurringInfo,
  remainingIRBalance,
  ActivePortinData,
  generatePDF,
  getAdditionalEsimInfo,
  downloadEsimPDF,
} from '../../store/sim/duck';
import SIMDetailComponent from './component';

const SIMDetailContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    simCounts: state.sim.counts,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    fetchScope,
    setUser,
    planChangeCount,
    planDetails,
    simcardDetail,
    planCycle,
    accountNumber,
    getActivitiesLogs,
    hidePlanCycle,
    pukCodes,
    reconnectWaitTime,
    addOnPlans,
    addOnPlansUsage,
    irIldDetails,
    recurringInfo,
    activateSims,
    pauseSims,
    deleteSims,
    deactivateSims,
    reActivateSimByPlan,
    updateSimName,
    updateTag,
    getUsageDailySummary,
    getSwapSimSearch,
    updateSimPortInRequest,
    simPortInCancel,
    swapSIM,
    swapESIM,
    enableWifiCalling,
    refreshPortInStatus,
    reconnectNetwork,
    adminOverride,
    fetchSimCounts,
    changePhoneNumber,
    setCount,
    updateAddOnPlans,
    bulkManageAddOns,
    cancelRecurringPayment,
    UpdateVoicemailStatus,
    getVoiceMailStatus,
    getWifiCallingDetails,
    getDataUsageFromUltra,
    reActivateSim,
    unPauseSim,
    coverageDetail,
    getDeviceCompatibility,
    getBrands,
    fetchPlans,
    getEsimQuantity,
    getEsimInfo,
    getPreSignedUrl,
    remainingIRBalance,
    ActivePortinData,
    generatePDF,
    getAdditionalEsimInfo,
    downloadEsimPDF,
  }
)(SIMDetailComponent);

export default SIMDetailContainer;
