import { connect } from 'react-redux';

import { fetchMe, setUser } from '../../store/user/duck';
import { fetchAverageUsagePlan } from '../../store/dashboard/duck';

import MonthOverMonthPlanComponent from './component';

const MonthOverMonthPlanContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchAverageUsagePlan,
  }
)(MonthOverMonthPlanComponent);

export default MonthOverMonthPlanContainer;
