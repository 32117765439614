import { connect } from 'react-redux';
import {
  fetchMe,
  setUser,
  setUserPermissions,
  fetchUser,
  updateProfile,
  changePassword,
  addDocument,
  fetchDocuments,
  deleteDocument,
  fetchPermissions,
  fetchSubUsers,
  addSubUser,
  deleteUser,
  fetchFileDataFromDocumentId
} from '../../store/user/duck';
import { fetchUserPermissions } from '../../store/admin/duck';
import SettingsComponent from './component';

const SettingsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPermission: state.user.userPermission,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    setUserPermissions,
    fetchUser,
    updateProfile,
    changePassword,
    addDocument,
    fetchDocuments,
    deleteDocument,
    fetchPermissions,
    fetchUserPermissions,
    fetchSubUsers,
    addSubUser,
    deleteUser,
    fetchFileDataFromDocumentId
  }
)(SettingsComponent);

export default SettingsContainer;
