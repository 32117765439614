import { connect } from 'react-redux';

import ManagePermissionsComponent from './component';
import {
  setUserPermissions,
  fetchUserPermissions,
} from '../../store/admin/duck';

const ManagePermissionsContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    setUserPermissions,
    fetchUserPermissions,
  }
)(ManagePermissionsComponent);

export default ManagePermissionsContainer;
