/* eslint no-undef: "off"*/
import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Dashboard from '../views/Dashboard/container';
import DataUsage from '../views/Dashboard/DataUsage/container';
import SmsUsage from '../views/Dashboard/SmsUsage/container';
import VoiceUsage from '../views/Dashboard/VoiceUsage/container';
import FixedDashboard from '../views/FixedDashboard/container';
import YourSiMs from '../views/YourSiMs/container';
import ViewCoverageMap from '../views/ViewCoverageMap/container';
import Login from '../views/Login/container';
import TwoFactorAuth from '../views/TwoFactorAuth/container';
import ResetPassword from '../views/ResetPassword/container';
import ForgotPassword from '../views/ForgotPassword/container';
import AcceptInvitation from '../views/AcceptInvitation/container';
import Users from '../views/Users/container';
import AddUser from '../views/AddUser/container';
import EditUser from '../views/EditUser/container';
import Settings from '../views/Settings/container';
import PartnerAPIKeys from '../views/PartnerAPIKeys/container';
import AppSettings from '../views/AppSettings/container';
import SimDetail from '../views/SIMDetail/container';
import AddSim from '../views/AddSim/container';
import Clients from '../views/Clients/container';
import Orders from '../views/Orders/container';
import OrderDetail from '../views/OrderDetail/container';
import OrderSims from '../views/OrderSims/container';
import OrderESIMs from '../views/OrderEsims/container';
import UsageLogs from '../views/UsageLogs/container';
import Maintenance from '../views/Maintenance/container';
import BulkActivation from '../views/BulkActivation/container';
import BulkAddOns from '../views/BulkAddOns/container';
import TermsConditions from '../views/TermsAndConditions/container';
import SimsUsageSummary from '../views/SimsUsageSummary/container';
import SelectPlan from '../views/SelectPlan/container';

import SprDashboard from '../views/SprDashboard/container';
import RecentlyActivatedSims from '../views/SprDashboard/RecentlyActivatedSims/container';
import RecentlyDeactivatedSims from '../views/SprDashboard/RecentlyDeactivatedSims/container';
import RecentlyOderedSims from '../views/SprDashboard/RecentlyOderedSims/container';

import YourFixedSims from '../views/YourFixedSims/container';
import ManagePermissions from '../views/ManagePermissions/container';

import InternationalRates from '../views/InternationalRates/container';
import ManageBulk from '../views/ManageBulk/container';
import FwDeviceList from '../views/FwDeviceList/container';
import AverageUsageByPlan from '../views/AverageUsageByPlan/container';
import MonthOverMonthPlan from '../views/MonthOverMonthPlan/container';
import PooledPlanSummaryUsage from '../views/PooledPlanSummaryUsage/container';
import CoverageDetails from '../views/coverageDetails/container';
import MostUsageSims from '../views/MostUsageSims/container';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.scss';
import PendingOrderContainer from '../views/SprDashboard/PendingOrders/container';
import RecentlyOderedEsimsContainer from '../views/SprDashboard/RecentlyOrderedEsims/container';
import RecentlyPausedSimsContainer from '../views/SprDashboard/RecentlyPausedSims/container';
import DownloadDocumentView from '../views/DownloadDocument/DownloadDocument';

const PrivateRoute = ({ component, user, ...rest }) => {
  const isAuthed = localStorage.getItem('Authorization') ? true : false;
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <div>{React.createElement(component, props)}</div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      user: {},
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    let { isLoading } = this.state;
    return isLoading ? (
      <Spin
        className="loader__full"
        size="large"
        indicator={
          <LoadingOutlined style={{ fontSize: 50, color: '#000' }} spin />
        }
      />
    ) : (
      <div className="application-container">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/signup" render={() => <Redirect to="/login" />} />
          <Route exact path="/login" component={Login} {...this.props} />
          <Route
            exact
            path="/two-factor-auth"
            component={TwoFactorAuth}
            {...this.props}
          />
          <Route
            exact
            path="/forgot-password"
            component={ForgotPassword}
            {...this.props}
          />
          <Route
            exact
            path="/reset/:token"
            component={ResetPassword}
            {...this.props}
          />
          <Route
            exact
            path="/password-update/:token"
            component={ResetPassword}
            {...this.props}
          />
          <Route
            exact
            path="/accept-invitation/:token"
            component={AcceptInvitation}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/top-data-usage"
            component={DataUsage}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/top-sms-usage"
            component={SmsUsage}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/top-voice-usage"
            component={VoiceUsage}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-dashboard"
            component={FixedDashboard}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/view-coverage-map"
            component={ViewCoverageMap}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-sims"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/activation-in-progress"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/available-sims"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/available-esims"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/paused-sims"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/deactivated-sims"
            component={YourSiMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/all-users"
            component={Users}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/ultra-admins"
            component={Users}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/partners"
            component={Users}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/users/add-user"
            component={AddUser}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/users/edit-user"
            component={EditUser}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-orders"
            component={Orders}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/eSIM-orders"
            component={Orders}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-orders"
            component={Orders}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/order-detail/:id"
            component={OrderDetail}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/order-sims"
            component={OrderSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/order-eSIMs"
            component={OrderESIMs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/clients"
            component={Clients}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={Settings}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/update-password"
            component={Settings}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/users"
            component={Settings}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-documents"
            component={Settings}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/api-keys"
            component={PartnerAPIKeys}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/banner-setting"
            component={AppSettings}
            {...this.props}
          />
          <Route
            exact
            path="/page-setting"
            render={() => <Redirect to="/dashboard" />}
          />
          <PrivateRoute
            exact
            path="/detail/:id"
            component={SimDetail}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/add-sim/copy-and-paste"
            component={AddSim}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/add-fixed-sim/copy-and-paste"
            component={AddSim}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/add-sim/upload-csv"
            component={AddSim}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/add-fixed-sim/upload-csv"
            component={AddSim}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/usage/:id/:date"
            component={UsageLogs}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/bulk-activation-sims"
            component={BulkActivation}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-bulk-activation-sims"
            component={BulkActivation}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/legal/spr"
            component={TermsConditions}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/legal/fixed-wireless"
            component={TermsConditions}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-sims-usage-summary"
            component={SimsUsageSummary}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-sims-usage-summary"
            component={SimsUsageSummary}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard"
            component={SprDashboard}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/recently-activated-sims"
            component={RecentlyActivatedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/recently-deactivated-sims"
            component={RecentlyDeactivatedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/recently-paused-sims"
            component={RecentlyPausedSimsContainer}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/recently-ordered-sims"
            component={RecentlyOderedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/recently-ordered-eSIMs"
            component={RecentlyOderedEsimsContainer}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/spr-dashboard/pending-approval"
            component={PendingOrderContainer}
            {...this.props}
          />
          <Route
            exact
            path="/select-plan"
            component={SelectPlan}
            {...this.props}
          />
          <Route
            exact
            path="/maintenance"
            component={Maintenance}
            {...this.props}
          />
          <Route
            exact
            path="/spr-cdr-data-files"
            component={Settings}
            {...this.props}
          />
          <Route
            exact
            path="/fixed-documents"
            component={Settings}
            {...this.props}
          />
          <Route
            exact
            path="/fixed-cdr-data-files"
            component={Settings}
            {...this.props}
          />
          <Route
            exact
            path="/cdr-data-files/:monthyear"
            component={Settings}
            {...this.props}
          />
          <Route
            exact
            path="/your-fixed-sims"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-active-sims"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-activation-in-progress"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-never-been-activated"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-paused-sims"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fixed-deactivated-sims"
            component={YourFixedSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/manage-permissions"
            component={ManagePermissions}
            {...this.props}
          />

          <PrivateRoute
            exact
            path="/rates/ild"
            component={InternationalRates}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/rates/ir"
            component={InternationalRates}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-spr-sims/bulk-ild"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-spr-sims/bulk-disable-ild"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-spr-sims/bulk-ir"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-spr-sims/bulk-disable-ir"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-fixed-sims/bulk-ild"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-fixed-sims/bulk-disable-ild"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-fixed-sims/bulk-ir"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/active-fixed-sims/bulk-disable-ir"
            component={ManageBulk}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/fw-device-list"
            component={FwDeviceList}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/avg-usage"
            component={AverageUsageByPlan}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/mom-usage"
            component={MonthOverMonthPlan}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/pooled-plan-summary"
            component={PooledPlanSummaryUsage}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/bulk-add-ons"
            component={BulkAddOns}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/device-coverage"
            component={CoverageDetails}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/dashboard/most-usage-sims"
            component={MostUsageSims}
            {...this.props}
          />
          <PrivateRoute
            exact
            path="/download-document/:id/:filename"
            component={DownloadDocumentView}
            {...this.props}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(withCookies(AppComponent));
