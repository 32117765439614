/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { CalenderIcon } from '../../../components/icons';
import { Formik, ErrorMessage } from 'formik';
import { maintenanceBannerSchema } from '../../../utils/validations';
import './styles.scss';

export default class MaintenanceBannerComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        maintenance_start_date: '',
        maintenance_end_date: '',
        maintenance_start_time: '',
        maintenance_end_time: '',
        maintenance_message: '',
      },
      id: '',
      errorMessage: '',
      isProcessing: false,
    };
  }

  componentDidMount = async () => {
    document.title = 'Maintenance Banner | UltraIoT';
    const {
      value: { settings },
    } = await this.props.fetchSetting();
    if (settings && settings.length > 0) {
      this.setState({
        initialValues: {
          maintenance_start_date: settings[0].maintenance_start_date
            ? moment(settings[0].maintenance_start_date).utc()
            : '',
          maintenance_end_date: settings[0].maintenance_end_date
            ? moment(settings[0].maintenance_end_date).utc()
            : null,
          maintenance_start_time: settings[0].start_hour
            ? settings[0].start_hour
            : '',
          maintenance_end_time: settings[0].end_hour
            ? settings[0].end_hour
            : '',
          maintenance_message: settings[0].maintenance_message
            ? settings[0].maintenance_message
            : '',
        },
        id: settings[0].id,
      });
    }
  };

  onChangeDate = (from, date, dateString) => {
    const initialValues = _.cloneDeep(this.state.initialValues);
    if (from === 'maintenance_start_date') {
      initialValues.maintenance_start_date = date;
      initialValues.maintenance_end_date = '';
      this.setState({ initialValues });
    } else if (from === 'maintenance_end_date') {
      initialValues.maintenance_end_date = date;
      this.setState({ initialValues });
    }
  };

  handleSubmit = async (
    { maintenance_start_time, maintenance_end_time, ...values },
    { setSubmitting, setErrors }
  ) => {
    try {
      const { id } = this.state;
      const payload = {
        id,
        maintenance_start_date: moment(values.maintenance_start_date).format(
          'YYYY-MM-DD'
        ),
        maintenance_end_date: moment(values.maintenance_end_date).format(
          'YYYY-MM-DD'
        ),
        start_hour: maintenance_start_time,
        end_hour: maintenance_end_time,
        maintenance_message: values.maintenance_message,
      };
      this.setState({ isProcessing: true, errorMessage: '' });
      const {
        value: { success },
      } = await this.props.createSetting(payload);
      if (success) {
        this.props.history.push('/dashboard');
      }
    } catch (err) {
      const { message } = err;
      this.setState({ errorMessage: message, isProcessing: false });
    }
  };

  render() {
    const { initialValues, isProcessing, errorMessage } = this.state;
    return (
      <div className="card-container">
        <div className="card-wrapper">
          <div className="card mb-0">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={maintenanceBannerSchema}
              onSubmit={(values, formikProps) =>
                this.handleSubmit(values, formikProps)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <DatePicker
                            format={'MM/DD/YYYY'}
                            onChange={this.onChangeDate.bind(
                              this,
                              'maintenance_start_date'
                            )}
                            className="custom-datepicker"
                            dropdownClassName="custom-datepicker-overlay"
                            size="large"
                            showToday={false}
                            allowClear={false}
                            suffixIcon={<CalenderIcon />}
                            value={values.maintenance_start_date}
                          />
                          <label className="datepicker-textfield-label">
                            From Date <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="maintenance_start_date"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <DatePicker
                            format={'MM/DD/YYYY'}
                            onChange={this.onChangeDate.bind(
                              this,
                              'maintenance_end_date'
                            )}
                            className="custom-datepicker"
                            dropdownClassName="custom-datepicker-overlay"
                            size="large"
                            showToday={false}
                            allowClear={false}
                            suffixIcon={<CalenderIcon />}
                            value={values.maintenance_end_date}
                          />
                          <label className="datepicker-textfield-label">
                            To Date <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="maintenance_end_date"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            name="maintenance_start_time"
                            onChange={handleChange}
                            value={values.maintenance_start_time}
                            required
                          >
                            <option value="0">00:00 AM</option>
                            <option value="1">01:00 AM</option>
                            <option value="2">02:00 AM</option>
                            <option value="3">03:00 AM</option>
                            <option value="4">04:00 AM</option>
                            <option value="5">05:00 AM</option>
                            <option value="6">06:00 AM</option>
                            <option value="7">07:00 AM</option>
                            <option value="8">08:00 AM</option>
                            <option value="9">09:00 AM</option>
                            <option value="10">10:00 AM</option>
                            <option value="11">11:00 AM</option>
                            <option value="12">12:00 PM</option>
                            <option value="13">01:00 PM</option>
                            <option value="14">02:00 PM</option>
                            <option value="15">03:00 PM</option>
                            <option value="16">04:00 PM</option>
                            <option value="17">05:00 PM</option>
                            <option value="18">06:00 PM</option>
                            <option value="19">07:00 PM</option>
                            <option value="20">08:00 PM</option>
                            <option value="21">09:00 PM</option>
                            <option value="22">10:00 PM</option>
                            <option value="23">11:00 PM</option>
                          </select>
                          <label className="material-textfield-label">
                            From Time <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="maintenance_start_time"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            name="maintenance_end_time"
                            onChange={handleChange}
                            value={values.maintenance_end_time}
                            required
                          >
                            <option value="0">00:00 AM</option>
                            <option value="1">01:00 AM</option>
                            <option value="2">02:00 AM</option>
                            <option value="3">03:00 AM</option>
                            <option value="4">04:00 AM</option>
                            <option value="5">05:00 AM</option>
                            <option value="6">06:00 AM</option>
                            <option value="7">07:00 AM</option>
                            <option value="8">08:00 AM</option>
                            <option value="9">09:00 AM</option>
                            <option value="10">10:00 AM</option>
                            <option value="11">11:00 AM</option>
                            <option value="12">12:00 PM</option>
                            <option value="13">01:00 PM</option>
                            <option value="14">02:00 PM</option>
                            <option value="15">03:00 PM</option>
                            <option value="16">04:00 PM</option>
                            <option value="17">05:00 PM</option>
                            <option value="18">06:00 PM</option>
                            <option value="19">07:00 PM</option>
                            <option value="20">08:00 PM</option>
                            <option value="21">09:00 PM</option>
                            <option value="22">10:00 PM</option>
                            <option value="23">11:00 PM</option>
                          </select>
                          <label className="material-textfield-label">
                            End Time <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="maintenance_end_time"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group material-textfield">
                      <textarea
                        type="text"
                        className="form-control form-control-lg material-textfield-input"
                        name="maintenance_message"
                        onChange={handleChange}
                        value={values.maintenance_message}
                        required
                        rows="6"
                      ></textarea>
                      <label className="material-textfield-label">
                        Banner Message <span className="text-danger">*</span>
                      </label>
                      <ErrorMessage
                        component="span"
                        name="maintenance_message"
                        className="invalid-feedback d-block"
                      />
                    </div>
                    <div className="invalid-feedback d-block text-center mb-3">
                      {errorMessage}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-dark btn-lg w-100"
                      disabled={isProcessing}
                    >
                      {isProcessing ? 'Please wait ...' : 'Save'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
