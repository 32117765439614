import { connect } from 'react-redux';

import { fetchMe, setUser } from '../../store/user/duck';
import { fetchAverageUsagePlan } from '../../store/dashboard/duck';

import MPooledPlanSummaryUsageComponent from './component';

const PooledPlanSummaryUsageContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchAverageUsagePlan,
  }
)(MPooledPlanSummaryUsageComponent);

export default PooledPlanSummaryUsageContainer;
