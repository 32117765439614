import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { CloseIcon, SuccessIcon } from 'components/icons';
import { formatTimeConstraintErrorMessage } from 'helpers/util.helpers';
import { useChangePhoneNumber } from 'lib/hooks/sim/sim.mutations';
import { useSimCardDetail } from 'lib/hooks/sim/sim.queries';
import { parseApiErrorMessage } from 'lib/api/utils';
import NumberFormat from 'react-number-format';

const changePhoneNumberFormSchema = z.object({
  zipcode: z
    .string()
    .min(1, 'Zipcode is required')
    .min(5, 'Zipcode must be 5 digits'),
});

type ChangePhoneNumberFormSchema = z.infer<typeof changePhoneNumberFormSchema>;

type ChangePhoneNumberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  iccid: string;
};

function ChangePhoneNumberModal({
  isOpen,
  onClose,
  iccid,
}: ChangePhoneNumberModalProps) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { data: simDetail } = useSimCardDetail({ iccid });

  const changePhoneNumberMutation = useChangePhoneNumber();

  const { setFocus, formState, ...form } = useForm<ChangePhoneNumberFormSchema>(
    {
      defaultValues: {
        zipcode: '',
      },
      resolver: zodResolver(changePhoneNumberFormSchema),
    }
  );

  useEffect(() => {
    // using setTimeout to wait for the modal to render
    if (isOpen) setTimeout(() => setFocus('zipcode'), 0);
  }, [isOpen, setFocus]);

  if (!simDetail) return null;

  const closeModalHandler = () => {
    form.reset();
    onClose();
  };

  const onSubmit = async (values: ChangePhoneNumberFormSchema) => {
    if (!simDetail) return;

    const { zipcode } = values;

    try {
      await changePhoneNumberMutation.mutateAsync({
        simId: simDetail.id,
        zipcode,
        ultraUserId: simDetail.ultraUserId,
      });

      setIsSuccessModalOpen(true);
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error);

      if (errorMessage?.includes('UTC'))
        return form.setError('zipcode', {
          message: formatTimeConstraintErrorMessage(errorMessage),
        });

      return form.setError('zipcode', {
        message: errorMessage ?? 'Failed to change phone number',
      });
    }
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModalHandler}
        ariaHideApp={false}
        contentLabel="Change Phone Number"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        style={{ overlay: { display: isSuccessModalOpen ? 'none' : 'block' } }}
      >
        <div className="modal-content" hidden={isSuccessModalOpen}>
          <div className="react-modal-header">
            <h5 className="react-modal-title">
              Change Phone Number for{' '}
              <NumberFormat
                value={simDetail.msisdn}
                format="(###) ###-####"
                displayType="text"
                allowEmptyFormatting
                mask="_"
              />
            </h5>

            <button
              type="button"
              className="btn react-modal-close"
              onClick={closeModalHandler}
            >
              <CloseIcon className={''} />
            </button>
          </div>

          <div className="react-modal-body">
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
              <div className="update-plan-message">
                <p>A MSISDN can only be changed one time per 48 hour period.</p>
                <p>
                  Current Phone Number -{' '}
                  <NumberFormat
                    value={simDetail.msisdn}
                    format="(###) ###-####"
                    displayType="text"
                    allowEmptyFormatting
                    mask="_"
                  />
                </p>

                <div
                  className="form-group material-textfield"
                  style={{ marginBottom: '0.5rem' }}
                >
                  <input
                    type="text"
                    className="form-control form-control-lg material-textfield-input"
                    {...form.register('zipcode')}
                    id="zipcode"
                    required
                  />

                  <label className="material-textfield-label" htmlFor="zipcode">
                    Zipcode for the New Phone Number{' '}
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </div>

              <div
                className="invalid-feedback"
                style={{ marginBottom: '1rem' }}
              >
                {formState.errors.zipcode?.message}
              </div>

              <div className="update-plan-message text-right">
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={closeModalHandler}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  disabled={formState.isSubmitting}
                  className="btn btn-dark w-150"
                >
                  {formState.isSubmitting ? 'Please wait...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>

      <ChangePhoneNumberSuccessDialog
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          closeModalHandler();
        }}
      />
    </>
  );
}

export default ChangePhoneNumberModal;

type ChangePhoneNumberSuccessDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ChangePhoneNumberSuccessDialog({
  isOpen,
  onClose,
}: ChangePhoneNumberSuccessDialogProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel="Phone Number Changed Successfully"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
    >
      <div className="modal-content">
        <div className="connection-message-header success">
          <SuccessIcon props={{}} />
          <h6>Success</h6>
        </div>

        <div className="react-modal-body">
          <div className="connection-message form-group">
            <p className="font-weight-bold">
              Phone Number changed successfully.
            </p>
          </div>

          <button className="btn btn-purple btn-lg w-100" onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
