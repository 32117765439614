import { connect } from 'react-redux';
import PartnerAPIKeysComponent from './component';
import {
  fetchMe,
  setUser,
  sendOtp,
  verifyOtp,
  generateAPIKey,
  getAPIKeys,
} from '../../store/user/duck';

const PartnerAPIKeysContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    sendOtp,
    verifyOtp,
    generateAPIKey,
    getAPIKeys,
  }
)(PartnerAPIKeysComponent);

export default PartnerAPIKeysContainer;
