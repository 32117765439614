import { connect } from 'react-redux';
import CoverageDetail from './component';
import {
  coverageDetail,
  getDeviceCompatibility,
  getBrands,
} from '../../store/coverageDetail/duck';

const CoverageDetailContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    coverageDetail,
    getDeviceCompatibility,
    getBrands,
  }
)(CoverageDetail);

export default CoverageDetailContainer;
