import 'rxjs';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import app from './app';
import user, { userEpic } from './user/duck';
import sim, { simEpic } from './sim/duck';
import coverageDetail, { coverageEpic } from './coverageDetail/duck';
import dashboard, { dashboardEpic } from './dashboard/duck';
import plan, { planEpic } from './plan/duck';
import setting, { settingEpic } from './setting/duck';
import admin, { adminEpic } from './admin/duck';
import ir, { internationalRatesEpic } from './internationRates/duck';

// Bundling Epics
const rootEpic = combineEpics(
  dashboardEpic,
  planEpic,
  userEpic,
  simEpic,
  settingEpic,
  adminEpic,
  internationalRatesEpic,
  coverageEpic
);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware();

// Define Middleware
const middleware = [thunk, promise, epicMiddleware];

// Define Reducers
const reducers = combineReducers({
  dashboard,
  plan,
  setting,
  user,
  sim,
  coverageDetail,
  app,
  admin,
  ir,
  form: formReducer,
});

// Create Store
// const store = createStore(
//   reducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   applyMiddleware(...middleware)
// )
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(...middleware))
);

epicMiddleware.run(rootEpic);
export default store;
