import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  addBulkSims,
  fetchPlans,
  getBulkSims,
  activateSims,
  fetchTagsByType,
} from '../../store/sim/duck';
import { fetchFixedPlansByUserId } from '../../store/plan/duck';

import BulkActivation from './component';

const BulkActivationContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    addBulkSims,
    fetchPlans,
    getBulkSims,
    activateSims,
    fetchTagsByType,
    fetchFixedPlansByUserId,
  }
)(BulkActivation);

export default BulkActivationContainer;
