import { connect } from 'react-redux';

import CdrDataFilesComponent from './component';
import { downloadCdrFile, getCDRData } from '../../../store/user/duck';

const CdrDataFilesContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    getCDRData,
    downloadCdrFile,
  }
)(CdrDataFilesComponent);

export default CdrDataFilesContainer;
