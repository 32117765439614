import { message } from "antd";

export function downloadFileFromBlob(blob, fileName) {
    const url = window.URL.createObjectURL(blob);
    const anchorEl = document.createElement('a');
  
    anchorEl.href = url;
    anchorEl.setAttribute('download', fileName);
    document.body.appendChild(anchorEl);
    anchorEl.click();
  
    document.body.removeChild(anchorEl);
    window.URL.revokeObjectURL(url);
  }
    
  export async function handleEsimPdfDownload(iccid, downloadEsimPDF,generatePDF) {
    const hideLoadingMessage = message.loading('Your file is being downloaded', 0); // Display loading message
    try {
      const { value: blob } = await downloadEsimPDF({ iccid });
      downloadFileFromBlob(blob, iccid);
      hideLoadingMessage();
      message.success('Download Completed');
    } catch (error) {
      hideLoadingMessage();
      await generatePDF({ ICCID: iccid });
      message.error(error?.message ?? 'Please try to download again.');

    }
  }