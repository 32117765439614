/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import { DatePicker, notification } from 'antd';
import ReactModal from 'react-modal';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import {
  ExportIcon,
  CalenderIcon,
  BackArrowIcon,
  CloseIcon,
} from '../../components/icons';
import _ from 'lodash';
import moment from 'moment';
import { Spin, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';

let curr = new Date(); // get current date
let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
let last = first + 6; // last day is the first day + 6
const firstday = new Date(curr.setDate(first)).toUTCString();
const lastday = new Date(curr.setDate(last)).toUTCString();
const monthFirstDate = moment().startOf('month').format('YYYY-MM-DD hh:mm');
const monthLastDate = moment().endOf('month').format('YYYY-MM-DD hh:mm');
const dateFormat = 'MMMM DD, YYYY';
// const todayDate = new Date()
const previousYearSameDate = curr.getMonth() - 12;

export default class SimsUsageSummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isExportLoading: false,
      userData: null,
      page: 1,
      limit: 100,
      simsReportData: [],
      todayDate: moment().format('LL'),
      weekFirstDate: moment(firstday).format('LL'),
      weekLastDate: moment(lastday).format('LL'),
      monthFirstDate: moment(monthFirstDate).format('LL'),
      monthLastDate: moment(monthLastDate).format('LL'),
      datePickerModel: false,
      customFirstDate: new Date().setMonth(previousYearSameDate),
      customEndDate: new Date(),
      startDate: '',
      endDate: '',
      datePickerValue: '',
      dropdownOpen: false,
      isExportSimsData: false,
    };
  }

  componentDidMount = async () => {
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      this.props.setUser(user);
      this.setState({ userData: user });
    }

    let date = {
      from: this.state.customFirstDate,
      to: this.state.customEndDate,
    };
    this.getSimsUsageReport(date);

    this.setState({
      datePickerValue: `Custom (${moment(this.state.customFirstDate).format(
        'LL'
      )} - ${moment(this.state.customEndDate).format('LL')})`,
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getSimsUsageReport();
    });
  };

  getSimsUsageReport = async () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const { userData } = this.state;
        let tab = _.get(
          this.props,
          'location.pathname',
          '/spr-sims-usage-summary'
        );
        let obj = {
          limit: this.state.limit,
          page: this.state.page,
          brandName: _.get(userData, 'brandName', ''),
          simType: tab === '/spr-sims-usage-summary' ? 'spr' : 'fixed',
          isExport: false,
        };
        const {
          value: { success, data, totalCount },
        } = await this.props.getSimsUsageSummaryReport(obj);
        if (success) {
          this.setState({ simsReportData: data, totalCount, isLoading: false });
        }
      } catch (err) {
        this.setState({
          isLoading: false,
          simsReportData: [],
        });
      }
    });
  };

  datePickerToggle(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  changeDate(e) {
    const {
      todayDate,
      weekFirstDate,
      weekLastDate,
      monthFirstDate,
      monthLastDate,
      customFirstDate,
      customEndDate,
    } = this.state;
    const data = {};
    if (e === 'custom') {
      this.setState({
        datePickerModel: true,
        startDate: moment(customFirstDate),
        endDate: moment(customEndDate),
      });
      this.setState({
        datePickerValue: `Custom (${moment(customFirstDate).format(
          'LL'
        )} - ${moment(customEndDate).format('LL')})`,
      });
    } else {
      if (e === 'today') {
        data.from = moment(todayDate).format('YYYY-MM-DD');
        data.to = moment(todayDate).format('YYYY-MM-DD');
        this.setState({
          datePickerValue: `Today (${todayDate})`,
          customFirstDate: moment(todayDate),
          customEndDate: moment(todayDate),
        });
      } else if (e === 'week') {
        data.from = moment(weekFirstDate).format('YYYY-MM-DD');
        data.to = moment(weekLastDate).format('YYYY-MM-DD');
        this.setState({
          datePickerValue: `This Week (${weekFirstDate} - ${weekLastDate})`,
          customFirstDate: moment(weekFirstDate),
          customEndDate: moment(weekLastDate),
        });
      } else if (e === 'month') {
        data.from = moment(monthFirstDate).format('YYYY-MM-DD');
        data.to = moment(monthLastDate).format('YYYY-MM-DD');
        this.setState({
          datePickerValue: `This Month (${monthFirstDate} - ${monthLastDate})`,
          customFirstDate: moment(monthFirstDate),
          customEndDate: moment(monthLastDate),
        });
      }
      this.getSimsUsageReport(data);
    }
  }

  onChangeDate = (from, date) => {
    const { customEndDate } = this.state;
    if (from === 'start') {
      this.setState({ startDate: date, endDate: moment(customEndDate) });
    } else if (from === 'end') {
      this.setState({ endDate: date });
    }
  };

  disabledDate = (current) => {
    const { startDate } = this.state;
    return current.isAfter(new Date()) || current.isBefore(startDate); // current.isSameOrBefore(endDate)
  };

  disabledStartDate = (current) => {
    const { endDate } = this.state;
    return current.isAfter(endDate);
  };

  closeModal = () => {
    this.setState({ datePickerModel: false });
  };

  submitCustomDate() {
    const { startDate, endDate } = this.state;
    this.setState({
      datePickerValue: `Custom (${moment(startDate).format('LL')} - ${moment(
        endDate
      ).format('LL')})`,
    });
    this.setState(
      {
        datePickerModel: false,
        customFirstDate: startDate,
        customEndDate: endDate,
      },
      () => {
        const data = {
          from: moment(startDate).format('YYYY-MM-DD'),
          to: moment(endDate).format('YYYY-MM-DD'),
        };
        this.getSimsUsageReport(data);
      }
    );
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  //Export Sim Data
  exportData = async () => {
    try {
      this.setState({ isExportLoading: true, isExportSimsData: true });
      const { userData } = this.state;
      let tab = _.get(
        this.props,
        'location.pathname',
        '/spr-sims-usage-summary'
      );
      let date = {
        fromDate: moment().startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
        simType: tab === '/spr-sims-usage-summary' ? 'spr' : 'fixed',
        brandName: _.get(userData, 'brandName', ''),
        limit: 1500,
        isExport: true,
      };
      const {
        value: { message },
      } = await this.props.exportSimsUsageSummary(date);
      this.setState({ isExportSimsData: false });
      notification['success']({
        message: 'Sims usage summary export',
        description: message,
      });
      // Keep Export disabled for 3m
      setTimeout(() => {
        this.setState({ isExportLoading: false });
      }, 180000);
    } catch (error) {
      this.setState({
        isExportSimsData: false,
        isExportLoading: false,
        errorMessage: _.get(error, 'message', 'Export failed.'),
      });
    }
  };

  render() {
    const { simsReportData, totalCount } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-4">
              <button
                onClick={() => this.props.history.push('/dashboard')}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back to Dashboard
              </button>
            </div>
            <div className="col-md-8 text-right"></div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Spin
                spinning={this.state.isLoading}
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: '#000' }}
                    spin
                  />
                }
              >
                <div className="card">
                  <div className="pooled-usage-summary">
                    <div>
                      <h5 className="title"> SIMs Usage Summary Report</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            disabled={
                              _.size(simsReportData) === 0 ||
                              this.state.isExportLoading
                            }
                            onClick={this.exportData.bind(this)}
                            className="btn btn-link btn-export"
                          >
                            <ExportIcon className="export-icon" />
                            {this.state.isExportSimsData
                              ? 'Please wait...'
                              : 'Export'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {_.size(simsReportData) > 0 ? (
                    <div className="table-responsive mb-3">
                      <table className="table custom-table-secondary table-nowidth">
                        <thead>
                          <tr>
                            <th style={{ paddingLeft: 25 }}>
                              <span className="table-column-title">UserId</span>
                            </th>
                            <th>
                              <span className="table-column-title">ICCID</span>
                            </th>
                            <th>
                              <span className="table-column-title">Tags</span>
                            </th>
                            <th>
                              <span className="table-column-title">Client</span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Phone Number
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">Plan</span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Rate Plan ID
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">MINS</span>
                            </th>
                            <th>
                              <span className="table-column-title">SMS</span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Data used in MB
                              </span>
                            </th>
                            <th colSpan="2">
                              <span className="table-column-title">Status</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {simsReportData.map((item, index) => {
                            let tagsArr =
                              item.tags && item.tags !== ''
                                ? item.tags.split(',')
                                : [];
                            return (
                              <tr key={index}>
                                <td>
                                  {_.get(item, 'ultraUserId', '') &&
                                  _.get(item, 'ultraUserId', '') !== ''
                                    ? _.get(item, 'ultraUserId', '-')
                                    : '-'}
                                </td>
                                <td>{_.get(item, 'simnumber', '-')}</td>
                                <td>
                                  {_.size(tagsArr) > 0
                                    ? tagsArr.map((tag) => {
                                        return (
                                          <button
                                            key={tag}
                                            className="btn btn-tags mr-2"
                                          >
                                            {tag}
                                          </button>
                                        );
                                      })
                                    : '-'}
                                </td>
                                <td>{_.get(item, 'clientName', '-')}</td>
                                <td>
                                  {_.get(item, 'msisdn', '') &&
                                  _.get(item, 'msisdn', '') !== ''
                                    ? this.formatPhoneNumber(
                                        _.get(item, 'msisdn', '-')
                                      )
                                    : '-'}
                                </td>
                                <td>{_.get(item, 'plantitle', '-')}</td>
                                <td>
                                  {_.get(item, 'offerId', '') ||
                                    _.get(item, 'productid', '')}
                                </td>
                                {/* <td>
                                  {_.get(item, "startOfCycle", "") !== ""
                                    ? moment(
                                        _.get(item, "startOfCycle", "-")
                                      ).format("ll")
                                    : ""}{" "}
                                  - {" "}
                                  {_.get(item, "endOfCycle", "") !== ""
                                    ? moment(
                                        _.get(item, "endOfCycle", "-")
                                      ).format("ll")
                                    : ""}
                                </td> */}
                                <td>
                                  {_.get(item, 'voice', '') &&
                                  _.get(item, 'voice', '') !== ''
                                    ? _.get(item, 'voice', '').toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                    : '-'}
                                </td>
                                <td>
                                  {_.get(item, 'sms', '') &&
                                  _.get(item, 'sms', '') !== ''
                                    ? _.get(item, 'sms', '').toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                    : '-'}
                                </td>
                                <td>
                                  {_.get(item, 'data', '0').toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 2 }
                                  )}{' '}
                                </td>
                                <td>{_.get(item, 'statusText', '-')}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="blank__table__card">
                      <div className="blank__table__card__inner">
                        No SIMs Usage Summary Report Data
                      </div>
                    </div>
                  )}
                </div>
                {_.size(simsReportData) > 0 && (
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => this.onPageChange(page)}
                      current={this.state.page}
                      pageSize={this.state.limit}
                      defaultCurrent={this.state.page}
                      total={totalCount}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </main>

        {/* // DATE PICKER CUSTOM */}
        <ReactModal
          isOpen={this.state.datePickerModel}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Date Picker"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Select Date</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <div className="material-textfield">
                    <DatePicker
                      placeholder=""
                      format={dateFormat}
                      disabledDate={this.disabledStartDate}
                      onChange={this.onChangeDate.bind(this, 'start')}
                      className="custom-datepicker"
                      dropdownClassName="custom-datepicker-overlay"
                      size="large"
                      showToday={false}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      value={
                        this.state.startDate !== ''
                          ? this.state.startDate
                          : null
                      }
                    />
                    <label className="datepicker-textfield-label">From</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="material-textfield">
                    <DatePicker
                      placeholder=""
                      format={dateFormat}
                      disabledDate={this.disabledDate}
                      onChange={this.onChangeDate.bind(this, 'end')}
                      className="custom-datepicker"
                      dropdownClassName="custom-datepicker-overlay"
                      size="large"
                      showToday={false}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      disabled={this.state.startDate !== '' ? false : true}
                      value={
                        this.state.endDate !== '' ? this.state.endDate : null
                      }
                    />
                    <label className="datepicker-textfield-label">To</label>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-dark btn-lg w-100"
                  onClick={() => this.submitCustomDate()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
