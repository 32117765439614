import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  fetchDashboardStats,
  fetchSimsByMaxUsage
} from '../../store/dashboard/duck';
import DashboardComponent from './component';

const DashboardContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchDashboardStats,
    fetchSimsByMaxUsage
  }
)(DashboardComponent);

export default DashboardContainer;
