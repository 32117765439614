/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { forgotSchema } from '../../utils/validations';
import { Formik, ErrorMessage } from 'formik';
import './styles.scss';

export default class ForgotPasswordComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      errorMessage: '',
      initialValues: {
        email: '',
      },
    };
  }

  handleForgot = async (values, { setSubmitting }) => {
    try {
      this.setState({ errorMessage: '' });
      let obj = { email: values.email.trim() };
      const {
        value: { success, message },
      } = await this.props.forgotPassword(obj);
      if (success) {
        setSubmitting(false);
        this.setState({ errorMessage: message });
      }
    } catch (err) {
      const { success, message } = _.get(err, 'data', err);
      if (!success) {
        setSubmitting(false);
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    const { initialValues, errorMessage } = this.state;
    return (
      <div className="reset-password">
        <div className="reset-password-inner d-flex align-items-center">
          <div className="reset-password-body">
            <div className="card">
              <div className="card-body">
                <div className="card-inner-heading">
                  <h4 className="title">Forgot Password?</h4>
                  <p className="subtitle">
                    Please enter your email to get the password reset link.
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={this.handleForgot}
                  validationSchema={forgotSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          className="form-control form-control-lg material-textfield-input"
                          name="email"
                          onChange={handleChange}
                          value={values.email || ''}
                          required
                        />
                        <label className="material-textfield-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <ErrorMessage
                          component="span"
                          name="email"
                          className="invalid-feedback d-block"
                        />
                      </div>
                      <span className="invalid-feedback d-block spacer-10 text-center">
                        {errorMessage}
                      </span>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmitting ? 'Please wait ...' : 'Send'}
                      </button>
                      <div className="create__link">
                        <Link className="btn-back-to-login" to={'/login'}>
                          Go Back to Login
                        </Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
