/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Spin, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';
import { OrderColorIcon } from '../../components/icons';
import _ from 'lodash';
import './styles.scss';
import {OrderStatus} from '../../constants/Orders'

export default class OrdersComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userData: {},
      orders: [],
      limit: 50,
      page: 1,
      errorMessage: '',
      totalOrders: 0,
      activeTab: 'active',
      type: 'active', //'active', 'inactive', 'paused', 'deactive', 'availableesim'
      sortBy: 'activationdate',
      sortOrder: 'desc',
      keyword: '',
      tags: '',
      byDate: false,
      startDate: '',
      endDate: '',
      searchClientFilter: [],
      orderType: 'spr',
      isEsimOrder: false,
    };
  }

  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    document.title = 'My Orders | UltraIoT';
    const { match, fetchMe } = this.props;
    try {
      const {
        value: { success, user },
      } = await fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
        if (
          user.role === 'corporate_client' ||
          user.role === 'user' ||
          (isSubUser(user) && !checkPermission(user, '/orders'))
        ) {
          this.props.history.push('/dashboard');
        }
      }
      let orderType = match.path === '/spr-orders' ? 'spr' : 'fixed';
      if (match.path === '/eSIM-orders') {
        orderType = 'eSIM';
      }
      await this.setAsyncState({ orderType });
    } catch (error) {
      this.setState({
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
    this.getSimsOnTabChange();
    this.getOrders();
  };

  getOrders = async (d) => {
    try {
      const { orderType, limit, page } = this.state;
      let obj = {
        limit,
        page,
        orderType: d ? d : orderType,
      };
      this.setState({ isEsimOrder: d ? d : orderType });
      const {
        value: { success, orders, total },
      } = await this.props.getAllOrders(obj);
      if (success) {
        this.setState({ orders, totalOrders: total, isLoading: false });
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  orderDetail = (item) => {
    this.props.history.push({
      pathname: `/order-detail/${item.id}`,
      state: this.state.orderType,
    });
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getOrders();
    });
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab, page: 1, allChecked: false }, () => {
      if (tab === 'active') {
        this.props.history.push('/active-sims');
        document.title = 'Active SIMs | UltraIoT';
      } else if (tab === 'activationInProgress') {
        this.props.history.push('/activation-in-progress');
        document.title = 'Activation In Progress SIMs | UltraIoT';
      } else if (tab === 'inactive') {
        this.props.history.push('/available-sims');
        document.title = 'Available SIMs | UltraIoT';
      } else if (tab === 'availableeSim') {
        this.props.history.push('/available-esims');
        document.title = 'Available eSIMs | UltraIoT';
      } else if (tab === 'paused') {
        this.props.history.push('/paused-sims');
        document.title = 'Paused SIMs | UltraIoT';
      } else if (tab === 'deactive') {
        this.props.history.push('/deactivated-sims');
        document.title = 'Deactived SIMs | UltraIoT';
      } else if (tab === 'spr-orders') {
        this.props.history.push('/spr-orders');
        document.title = 'Orders | UltraIoT';
        this.getOrders('spr');
      } else if (tab === 'eSIM-orders') {
        this.props.history.push('/eSIM-orders');
        document.title = 'eSIM Orders | UltraIoT';
        this.getOrders('eSIM');
      } else if (tab === 'fixed-active') {
        this.props.history.push('/fixed-active-sims');
        document.title = 'Fixed Orders | UltraIoT';
      } else if (tab === 'fixed-activationInProgress') {
        this.props.history.push('/fixed-activation-in-progress');
        document.title = 'Fixed Activation In Progress SIMs | UltraIoT';
      } else if (tab === 'fixed-inactive') {
        this.props.history.push('/fixed-never-been-activated');
        document.title = 'Fixed Never Been Activated SIMs | UltraIoT';
      } else if (tab === 'fixed-paused') {
        this.props.history.push('/fixed-paused-sims');
        document.title = 'Fixed Paused SIMs | UltraIoT';
      } else if (tab === 'fixed-deactive') {
        this.props.history.push('/fixed-deactivated-sims');
        document.title = 'Fixed Deactived SIMs | UltraIoT';
      }
    });
  };

  getSimsOnTabChange = async () => {
    this.setState({ isLoading: true });
    const {
      activeTab,
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      searchClientFilter,
      orderType,
    } = this.state;
    let obj = {
      type: activeTab,
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      isExport: false,
      clients: searchClientFilter.map((item) => item.id),
      orderType,
    };

    try {
      const {
        value: { success, counts, total, records, tags },
      } = await this.props.getSims(obj);
      if (success) {
        this.setState({
          sims: records,
          total,
          counts,
          filterTags: tags,
          isLoading: false,
        });
        this.props.setCount(counts);
        this.markedNewTag(records);
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  setSortByKeys = async (key, order) => {
    this.setState({ sortBy: key, sortOrder: order });
  };

  render() {
    const {
      userData,
      orders,
      totalOrders,
      isLoading,
      limit,
      page,
      orderType,
      isEsimOrder,
    } = this.state;
    let pathname = '';
    let history = '';
    if (window.location.pathname === '/spr-orders') {
      history = 'SPR';
      pathname = '/order-sims';
    } else if (window.location.pathname === '/eSIM-orders') {
      history = 'SPR eSIM';
      pathname = '/order-eSIMs';
    } else {
      history = 'Fixed';
      pathname = '/order-sims';
    }

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          setSortByKeys={this.setSortByKeys}
          onTabChange={this.onTabChange}
        />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <h2 className="heading-title">
                <OrderColorIcon />
                {history} Order History
              </h2>
            </div>
          </div>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center mt-3">
              <div className="blank__table__card__inner">
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: '#000' }}
                      spin
                    />
                  }
                />
              </div>
            </div>
          ) : _.size(orders) > 0 ? (
            <div className="card">
              <div className="card-header d-flex align-items-center">
                <div>
                  <h5 className="card-title">Orders</h5>
                </div>
                {userData &&
                  (isSPRPartner(userData) ||
                    (isSubUser(userData) &&
                      checkPermission(userData, '/order-sims'))) && (
                    <div className="ml-auto">
                      <button
                        type="button"
                        onClick={() =>
                          this.props.history.push({
                            pathname,
                            state: orderType,
                          })
                        }
                        className="btn btn-purple btn-sm w-150"
                      >
                        Place Order
                      </button>
                    </div>
                  )}
              </div>
              <div className="table-responsive">
                <table className="table table-orders">
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Number of SIMs</th>
                      {isEsimOrder !== 'eSIM' && <th>Tracking Number</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 &&
                      orders.map((item, index) => {
                        return (
                          <tr className="cursor-poniter" key={index}>
                          <td>
                              <span
                                className="btn-link-orange"
                                onClick={() => this.orderDetail(item)}
                              >
                                {item?.ordernumber && item.ordernumber !== ''
                                  ? item.ordernumber
                                  : '-'}
                              </span>
                            </td>

                            <td>
                              {
                                OrderStatus[item?.status] ?? '-'
                              }
                            </td>

                            <td>
                              {item?.orderdate && moment(item?.orderdate).isValid()
                                ? moment(item?.orderdate).format('MMMM DD, YYYY')
                                : '-'}
                            </td>

                            <td>
                              {item?.quantity && item.quantity !== '' ? (
                                <span className="cursor-pointer">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={item.quantity}
                                  />
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>

                            {isEsimOrder !== 'eSIM' && (
                              <td>
                                {item?.trackingnumber && item.trackingnumber !== '' ? (
                                  <span className="cursor-pointer">
                                    {item.trackingnumber}
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="blank__view">
                  <div className="blank__view__inner">
                    <h4 className="title">No Orders</h4>
                    <p className="subtitle">
                      Looks like you haven't placed any orders yet.
                    </p>
                    {userData &&
                      (isSPRPartner(userData) ||
                        (isSubUser(userData) &&
                          checkPermission(userData, '/order-sims'))) && (
                        <button
                          onClick={() =>
                            this.props.history.push({
                              pathname,
                              state: orderType,
                            })
                          }
                          className="btn btn-purple w-150"
                        >
                          Order SIMs
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <Pagination
            className="mb-5"
            hideOnSinglePage
            onChange={this.onPageChanged}
            current={page}
            pageSize={limit}
            defaultCurrent={page}
            total={totalOrders}
            showSizeChanger={false}
          />
        </main>
      </div>
    );
  }
}
