import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  exportSimsData,
  exportCsv,
  getSims,
  setCount,
  reActivateSim,
  activateSims,
  pauseSims,
  deleteSims,
  deactivateSims,
  activateSimByPlan,
  updateSimName,
  updateTag,
  updateSimCard,
  fetchPlans,
  fetchClients,
  addClient,
  simPortInActivation,
  simPortInCancel,
  unPauseSim,
  setSimsIntoNeverBeen,
  fetchTagsByType,
  bulkPlanChange,
  removeClient,
} from '../../store/sim/duck';
import {
  fetchFixedPlans,
  fetchFixedPlansByUserId,
} from '../../store/plan/duck';
import YourSiMsComponent from './component';

const YourSiMsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    simCounts: state.sim.counts,
  }),
  // Map actions to dispatch and props
  {
    exportSimsData,
    fetchMe,
    setUser,
    setCount,
    getSims,
    activateSims,
    pauseSims,
    reActivateSim,
    deleteSims,
    deactivateSims,
    activateSimByPlan,
    updateSimName,
    updateTag,
    updateSimCard,
    fetchPlans,
    fetchFixedPlansByUserId,
    fetchFixedPlans,
    exportCsv,
    simPortInActivation,
    simPortInCancel,
    fetchClients,
    addClient,
    unPauseSim,
    setSimsIntoNeverBeen,
    fetchTagsByType,
    removeClient,
    bulkPlanChange,
  }
)(YourSiMsComponent);

export default YourSiMsContainer;
