import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BackArrowIcon } from '../../../components/icons';
import _ from 'lodash';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './styles.scss';
import { downloadFileFromBlob } from '../../../utils/helpers';

const CdrDataFilesDetailsComponent = (props) => {
  let recordData = [];
  const [isDownLoading, setisDownLoading] = useState(false);

  if (props.location.state && props.location.state.recordData) {
    recordData = props.location.state.recordData;
  }

  const finalData = [];
  recordData &&
    recordData.length &&
    recordData.map((record) => {
      const filter = recordData.filter((f) => f.date === record.date);
      if (filter && finalData.indexOf(filter) === -1) {
        finalData.push(filter);
      }
      return true;
    });
  let uniqueFinalData = _.uniqWith(finalData, _.isEqual);
  uniqueFinalData = _.orderBy(uniqueFinalData, (a) => moment(a[0].date), 'asc');

  useEffect(() => {
    document.title = 'CDR Data Files Details | UltraIoT';
  }, []);
  
  const handleCdrFileDownload = async (keyData) => {
    setisDownLoading(true);
    message.success('Your file is being downloaded');
    try {
      const { value: blob } = await props.downloadCdrFile({
        key: keyData.Key,
      });
      downloadFileFromBlob(blob, keyData.Key.split('/')[2]);
      setisDownLoading(false);
    } catch (error) {
      message.error('Error while Downloading the CDR File');
      setisDownLoading(false);
    }
  };


  return (
    <div className="card-container">
      <div className="card-wrapper card-wrapper-lg ">
        <div className="head-section-cta">
          <button
            className="btn btn-link btn-back"
            onClick={() => props.history.goBack()}
          >
            <BackArrowIcon className="mr-3" /> Back
          </button>
        </div>
        <div className="card mb-0">
          <div className="card-header d-flex align-items-center">
            <div>
              <h5 className="card-title">CDR Data Files</h5>
            </div>
          </div>
          <Spin
            spinning={isDownLoading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <div className="">
              <table className="table table-custom-normal">
                <tbody>
                  {uniqueFinalData &&
                    uniqueFinalData.length > 0 &&
                    uniqueFinalData.map((record, index) => {
                      const dataUrl = record.filter(function (item) {
                        return item.Key.indexOf('.Data.') !== -1;
                      });
                      const voiceUrl = record.filter(function (item) {
                        return item.Key.indexOf('.Voice.') !== -1;
                      });
                      return (
                        <tr key={index}>
                          <td>{moment(record[0].date).format('LL')}</td>
                          <td className="text-center">&nbsp;</td>
                          <td className="text-center">
                            {dataUrl.length > 0 ? (
                              <button
                                onClick={() => {
                                  handleCdrFileDownload(dataUrl[0]);
                                }}
                                className="btn text-purple btn-xs font-16"
                              >
                                Download Data Usage
                              </button>
                            ) : (
                              'N/A'
                            )}
                          </td>
                          <td className="text-center">
                            <>
                              {voiceUrl.length > 0 ? (
                                <button
                                  onClick={() => {
                                    handleCdrFileDownload(voiceUrl[0]);
                                  }}
                                  className="btn text-purple btn-xs font-16"
                                >
                                  Download Voice Usage
                                </button>
                              ) : (
                                'N/A'
                              )}
                            </>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default CdrDataFilesDetailsComponent;
