import { fetch } from '../../utils';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const loginUser = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtp = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/sendOtp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyOtp = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/verifyOtp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateAPIKey = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/generateAPIKey`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAPIKeys = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/getAPIKeys`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const signUpUser = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/resetPasswordLink`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/resetPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkResetPasswordToken = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/checkResetPasswordToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchMe = () => {
  return fetch(`${HOSTNAME}/api/v1/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchScope = () => {
  return fetch(`${HOSTNAME}/api/v1/scope`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUser = () => {
  return fetch(`${HOSTNAME}/api/v1/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProfile = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/updateprofile`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSupportUrl = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/updatesupporturl`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsers = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/filtered`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUsers = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/user/updateUsers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const userImpersonate = (id) => {
  return fetch(`${HOSTNAME}/api/v1/user/impersonate/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/changepassword`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchDocuments = (data) => {
  let { type } = data;
  return fetch(
    `${HOSTNAME}/api/v1/user/documents?type=${type}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addDocument = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/addDocuments`, {
    method: 'POST',
    processData: false,
    contentType: false,
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteDocument = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/deletedocument`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const clearNotifications = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/clearnotifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const setNotificationStatus = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/notificationstatus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const fetchPermissions = () => {
  return fetch(`${HOSTNAME}/api/v1/user/permissions`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const fetchSubUsers = () => {
  return fetch(`${HOSTNAME}/api/v1/user/subusers`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const addSubUser = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSignedURL = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/downloadfile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCDRData = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/getcdrfiles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.log(error, 'error');
      throw error;
    });
};

export const fetchInviteUserDetail = (token) => {
  return fetch(`${HOSTNAME}/api/v1/user/inviteuserdetail/${token}`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const acceptInvite = (data) => {
  return fetch(`${HOSTNAME}/api/v1/auth/acceptInvite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addCredits = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/add-credit-line`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUserPlans = (id) => {
  return fetch(`${HOSTNAME}/api/v1/plan/fetchUserPlans?id=${id}`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const fetchAllUserPlanById = (id) => {
  return fetch(`${HOSTNAME}/api/v1/plan/fetchAllUserPlans?id=${id}`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const updatePlans = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/updateplans`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUser = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/deleteuser/${data.id}`, {
    method: 'DELETE',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkOutage = () => {
  return fetch(`${HOSTNAME}/api/v1/outage`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserStatus = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/update-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const unblockUser = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/unblock-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePartnerProfile = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/updatePartnerProfile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPartnerDetailFromUltra = (data) => {
  return fetch(`${HOSTNAME}/api/v1/user/getPartnerDetails/${data.brandName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchFileDataFromDocumentId = async (data) => {
  const { documentId } = data;
  try {
    const response = await fetch(
      `${HOSTNAME}/api/v1/sim/file-data/${documentId}`,
      {
        method: 'GET',
      }
    );

    const payload = await response.blob();
    return payload;
  } catch (error) {
    throw new Error('Error fetching document data');
  }
};

export const downloadCdrFile = async (data) => {
  try {
    const response = await fetch(`${HOSTNAME}/api/v1/user/cdr-file-download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
      const payload = await response.blob();
      return payload;
  } catch (error) {
    throw new Error('Error fetching file data');
  }
};
