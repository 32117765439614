import React from 'react';
import ReactModal from 'react-modal';
import { CloseIcon } from '../../../components/icons';

const CancelRecurringPlanModal = ({
  recurringType,
  cancelRecurringPayment,
  updateLoading,
  closeModal,
  cancelRecurringPlanModal,
}) => {
  return (
    <ReactModal
      isOpen={cancelRecurringPlanModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Are you sure?"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog modal-dialog-centered"
    >
      <div className="modal-content">
        <div className="react-modal-body">
          <button
            type="button"
            className="btn react-modal-close"
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
          <h5 className="react-modal-title">
            Cancel {recurringType.toUpperCase()} Recurring Plan
          </h5>
          <p className="react-modal-subtitle mt-2">
            Are you sure you want to cancel {recurringType.toUpperCase()}{' '}
            recurring plan?
          </p>
          <div className="text-right mt-3">
            <button
              disabled={updateLoading}
              type="button"
              onClick={() => cancelRecurringPayment()}
              className="btn btn-dark w-150"
            >
              {updateLoading ? 'Please wait...' : "Yes, I'm sure"}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default CancelRecurringPlanModal;
