import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import { fetchSetting, createSetting } from '../../store/setting/duck';
import AppSettingsComponent from './component';

const AppSettingsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchSetting,
    createSetting,
  }
)(AppSettingsComponent);

export default AppSettingsContainer;
