/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import { Menu, Dropdown, Pagination } from 'antd';
import { MoreIcon, SortUpIcon, SortDownIcon } from '../../../components/icons';
import _ from 'lodash';
import moment from 'moment';
import './styles.scss';

export default class AllUsersComponent extends Component {
  menu = (item, role) => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => this.props.onMore('Impersonate', item)}
        >
          Impersonate
        </Menu.Item>
        {item.role === 'user' && (
          <Menu.Item key="1" onClick={() => this.props.openCreditModal(item)}>
            Add Credit
          </Menu.Item>
        )}
        {(role === 'admin' || role === 'superadmin' || role === 'sysops') &&
          _.get(item, 'role', '') === 'spr_partner' && (
            <Menu.Item
              key="2"
              onClick={() => this.props.openUpdateSupportUrlModal(item)}
            >
              Update Support URL
            </Menu.Item>
          )}
        {/* {item.role === "spr_partner" && (
          <Menu.Item  key="2" onClick={() => this.props.openUpdatePlanModal(item)}>
            Update Plan
          </Menu.Item>
        )}
        {item.role === "spr_partner" && (
          <Menu.Item  key="3" onClick={() => this.props.openUpdateLineModal(item)}>
            Update Lines
          </Menu.Item>
        )} */}
        {item.role === 'spr_partner' && (
          <Menu.Item
            key="3"
            onClick={() => this.props.onMore('ManagePermissions', item)}
          >
            Manage Permissions
          </Menu.Item>
        )}
        {parseInt(item.status) === 10 ? (
          <Menu.Item
            key="4"
            onClick={() => this.props.onMore('StatusChange', item)}
          >
            Deactivate
          </Menu.Item>
        ) : (
          <Menu.Item
            key="5"
            onClick={() => this.props.onMore('StatusChange', item)}
          >
            Activate
          </Menu.Item>
        )}
        {(role === 'superadmin' || role === 'sysops' || role === 'admin') &&
          _.get(item, 'role', '') === 'spr_partner' &&
          (_.get(item, 'lobId', '') === 1 ||
            _.get(item, 'lobId', '') === 2) && (
            <Menu.Item
              key="6"
              onClick={() =>
                this.props.onMore(
                  item.isIREnabled ? 'disableIR' : 'enableIR',
                  item
                )
              }
            >
              {item.isIREnabled ? 'Disable IR' : 'Enable IR'}
            </Menu.Item>
          )}
        {(role === 'superadmin' || role === 'sysops' || role === 'admin') &&
          _.get(item, 'role', '') === 'spr_partner' &&
          (_.get(item, 'lobId', '') === 1 ||
            _.get(item, 'lobId', '') === 2) && (
            <Menu.Item
              key="7"
              onClick={() =>
                this.props.onMore(
                  item.isILDEnabled ? 'disableILD' : 'enableILD',
                  item
                )
              }
            >
              {item.isILDEnabled ? 'Disable ILD' : 'Enable ILD'}
            </Menu.Item>
          )}
        {(parseInt(item.attempts) > 2 || parseInt(item.otpAttempts) > 2) &&
          role === 'superadmin' && (
            <Menu.Item
              key="8"
              onClick={() => this.props.onMore('unblockUser', item)}
            >
              Unlock
            </Menu.Item>
          )}
        {role === 'superadmin' &&
          (item.role === 'admin' || item.role === 'sysops') && (
            <Menu.Item
              key="9"
              onClick={() => this.props.openUpdateUserRoleModal(item)}
            >
              Update User Role
            </Menu.Item>
          )}
        {(role === 'superadmin' || role === 'sysops' || role === 'admin') &&
          item.role === 'spr_partner' && (
            <Menu.Item
              key="10"
              onClick={() => this.props.openUpdateUserInfoPage(item)}
            >
              Update Account
            </Menu.Item>
          )}
      </Menu>
    );
  };

  render() {
    const {
      data,
      totalPagination,
      sortBy,
      sortOrder,
      page,
      onPageChange,
      limit,
      userRole,
      match,
      formatNumWithComma,
    } = this.props;
    return (
      <div>
        <div className="table-responsive custom-table-shadow">
          <table className="table custom-table">
            <thead>
              <tr>
                {/* <th>
                  &nbsp;
                </th> */}
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('firstname')}
                  >
                    Name
                    {sortBy === 'firstname' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('email')}
                  >
                    Email
                    {sortBy === 'email' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('companyname')}
                  >
                    Company Name
                    {sortBy === 'companyname' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('datecreated')}
                  >
                    Joined Date
                    {sortBy === 'datecreated' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span className="table-column-title cursor-poniter">
                    Credit Line
                  </span>
                </th>
                <th>
                  <span className="table-column-title">Line of Business</span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('account_type')}
                  >
                    Account Type
                    {sortBy === 'account_type' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('role')}
                  >
                    Role
                    {sortBy === 'role' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th colSpan="2">
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('status')}
                  >
                    Status
                    {sortBy === 'status' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {_.size(data) > 0 &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span
                          className="btn-link-orange"
                          onClick={() => this.props.onMore('Impersonate', item)}
                        >
                          {_.get(item, 'firstname', '')}{' '}
                          {_.get(item, 'lastname', '')}
                        </span>
                        {(parseInt(item.attempts) > 2 ||
                          parseInt(item.otpAttempts) > 2) && (
                          <>
                            <p className="text-danger mb-0">Locked</p>
                          </>
                        )}
                      </td>
                      <td>{_.get(item, 'email', '-')}</td>
                      <td>{_.get(item, 'companyname', '-')}</td>
                      <td>
                        {_.get(item, 'datecreated', '') !== ''
                          ? moment(_.get(item, 'datecreated', '')).format(
                              'MMMM DD, YYYY'
                            )
                          : '-'}
                      </td>
                      <td>
                        {match.path === '/ultra-admins' ? (
                          <div className="text-orange text-underline cursor-poniter">
                            N/A
                          </div>
                        ) : (
                          <div
                            className="text-orange text-underline cursor-poniter"
                            onClick={() => this.props.openCreditModal(item)}
                          >
                            {item.partnerCreditAmount
                              ? `$${formatNumWithComma(
                                  item.partnerCreditAmount
                                )}`
                              : 'N/A'}
                          </div>
                        )}
                      </td>
                      <td>
                        {match.path === '/ultra-admins' ? (
                          <div className="text-orange text-underline cursor-poniter">
                            N/A
                          </div>
                        ) : (
                          <div>{item.linesOfBusinessName}</div>
                        )}
                      </td>
                      <td>
                        {_.get(item, 'account_type', '-') === 'SPR Partner'
                          ? 'Partners'
                          : _.get(item, 'account_type', '-')}
                      </td>
                      <td>
                        {_.get(item, 'role', '') === 'spr_partner' &&
                          'Account Owners'}
                        {_.get(item, 'role', '') === 'sub_user' &&
                          _.get(item, 'parentid', 0) > 0 &&
                          'Regular User'}
                      </td>
                      <td>
                        {_.get(item, 'status', '') === 10
                          ? 'Active'
                          : 'Not Active'}
                      </td>
                      <td>
                        <Dropdown
                          overlay={this.menu(item, userRole)}
                          trigger={['click']}
                          overlayClassName="more__menu__overlay"
                          placement="bottomRight"
                        >
                          <button className="btn ant-dropdown-link" href="#">
                            <MoreIcon />
                          </button>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          className="mb-5"
          hideOnSinglePage
          onChange={onPageChange}
          current={page}
          pageSize={limit}
          defaultCurrent={page}
          total={totalPagination}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
