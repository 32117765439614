import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  getDeviceCompatibility,
  coverageDetail,
  getBrands,
} from '../../store/coverageDetail/duck';
import {
  exportSimsData,
  exportCsv,
  getSims,
  activateSims,
  pauseSims,
  deleteSims,
  deactivateSims,
  activateSimByPlan,
  updateSimName,
  updateTag,
  updateSimCard,
  fetchPlans,
  fetchClients,
  addClient,
  simPortInActivation,
  simPortInCancel,
  setCount,
  bulkPlanChange,
  reActivateSim,
  unPauseSim,
  fetchAddOnPlans,
  setSimsIntoNeverBeen,
  fetchTagsByType,
  getEsimQuantity,
  activateEsimsbyId,
  activateBulkEsims,
  removeClient,
  generatePDF,
  downloadEsimPDF,
} from '../../store/sim/duck';
import YourSiMsComponent from './component';

const YourSiMsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    simCounts: state.sim.counts,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getSims,
    activateSims,
    pauseSims,
    deleteSims,
    deactivateSims,
    activateSimByPlan,
    updateSimName,
    updateTag,
    updateSimCard,
    fetchPlans,
    exportCsv,
    simPortInActivation,
    simPortInCancel,
    fetchClients,
    addClient,
    exportSimsData,
    setCount,
    bulkPlanChange,
    reActivateSim,
    unPauseSim,
    fetchAddOnPlans,
    setSimsIntoNeverBeen,
    fetchTagsByType,
    getEsimQuantity,
    getDeviceCompatibility,
    coverageDetail,
    getBrands,
    activateEsimsbyId,
    activateBulkEsims,
    removeClient,
    generatePDF,
    downloadEsimPDF,
  }
)(YourSiMsComponent);

export default YourSiMsContainer;
