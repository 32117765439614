import { connect } from 'react-redux';

import AllUsersComponent from './component';

const AllUsersContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(AllUsersComponent);

export default AllUsersContainer;
