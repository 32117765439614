// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable';
import { assign } from 'lodash';
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
// import { get } from "lodash"

// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// ULTRA PHASE START
export const GET_ALL_SIM = 'ultra/sim/GET_ALL_SIM';
export const ACTIVATE_SIMS = 'ultra/sim/ACTIVATE_SIMS';
export const ACTIVATE_ESIMS_BY_ID = 'ultra/sim/ACTIVATE_ESIMS_BY_ID';
export const ACTIVATE_BULK_ESIMS = 'ultra/sim/ACTIVATE_BULK_ESIMS';
export const GET_ESIM_QUANTITY = 'ultra/sim/GET_ESIM_QUANTITY';
export const GET_ESIM_INFO = 'ultra/sim/GET_ESIM_INFO';
export const PAUSE_SIMS = 'ultra/sim/PAUSE_SIMS';
export const DEACTIVATE_SIMS = 'ultra/sim/DEACTIVATE_SIMS';
export const ACTIVATE_SIMS_PLAN = 'ultra/sim/ACTIVATE_SIMS_PLAN';
export const REACTIVATE_SIMS_PLAN = 'ultra/sim/REACTIVATE_SIMS_PLAN';
export const DELETE_SIMS = 'ultra/sim/DELETE_SIMS';
export const UPDATE_SIMCARD_NAMES = 'ultra/sim/UPDATE_SIMCARD_NAMES';
export const UPDATE_TAGS = 'ultra/sim/UPDATE_TAGS';
export const UPDATE_SIMCARD_DETAILS = 'ultra/sim/UPDATE_SIMCARD_DETAILS';
export const ADD_SIMS = 'ultra/sim/ADD_SIMS';
export const ADD_BULK_SIMS = 'ultra/sim/ADD_BULK_SIMS';
export const GET_BULK_SIMS = 'ultra/sim/GET_BULK_SIMS';
export const GET_TAGS_BY_TYPE = 'ultra/sim/GET_TAGS_BY_TYPE';
export const FETCH_SIMS_PLANS = 'ultra/sim/FETCH_SIMS_PLANS';
export const FETCH_CLIENTS_PLANS = 'ultra/sim/FETCH_CLIENTS_PLANS';
export const ADD_CLIENTS_TO_SIMS = 'ultra/sim/ADD_CLIENTS_TO_SIMS';
export const EXPORT_CSV = 'ultra/sim/EXPORT_CSV';
export const EXPORT_SIMS_DATA = 'ultra/sim/EXPORT_SIMS_DATA';
export const SIM_DETAIL = 'ultra/sim/SIM_DETAIL';
export const GET_USAGE_DETAIL_MONTH = 'ultra/sim/GET_USAGE_DETAIL_MONTH';
export const GET_USAGE_DETAIL_DAY = 'ultra/sim/GET_USAGE_DETAIL_DAY';
export const GET_USAGE_DAILY_SUMMARY = 'ultra/sim/GET_USAGE_DAILY_SUMMARY';
export const GET_POOLED_SUMMARY = 'ultra/sim/GET_POOLED_SUMMARY';
export const GET_CLIENT_SUMMARY = 'ultra/sim/GET_CLIENT_SUMMARY';
export const REMOVE_CLIENT = 'ultra/sim/REMOVE_CLIENT';
export const GET_POOLED_BETWEEN_DATES = 'ultra/sim/GET_POOLED_BETWEEN_DATES';
export const SWAP_SIM_SEARCH = 'ultra/sim/SWAP_SIM_SEARCH';
export const SWAP_SIM = 'ultra/sim/SWAP_SIM';
export const SWAP_ESIM = 'ultra/sim/SWAP_ESIM';
export const PORT_IN_SIM = 'ultra/sim/PORT_IN_SIM';
export const UPDATE_PORT_IN_SIM = 'ultra/sim/UPDATE_PORT_IN_SIM';
export const PORT_IN_CANCEL_SIM = 'ultra/sim/PORT_IN_CANCEL_SIM';
export const ORDER_SIMS = 'ultra/sim/ORDER_SIMS';
export const ORDER_ESIMS = 'ultra/sim/ORDER_ESIMS';
export const GET_ALLORDER_SIMS = 'ultra/sim/GET_ALLORDER_SIMS';
export const GET_ORDER_BY_SIM_ID = 'ultra/sim/GET_ORDER_BY_SIM_ID';
export const GET_SIMS_USAGE_SUMMARY_REPORT =
  'ultra/sim/GET_SIMS_USAGE_SUMMARY_REPORT';
export const ENABLE_WIFI_CALLING = 'ultra/sim/ENABLE_WIFI_CALLING';
export const REFRESH_PORT_IN_STATUS = 'ultra/sim/REFRESH_PORT_IN_STATUS';
export const RECONNECT_TO_NETWORK = 'ultra/sim/RECONNECT-TO-NETWORK';
export const ADMIN_OVERRIDE = 'ultra/sim/ADMIN_OVERRIDE';
export const SIM_COUNTS = 'ultra/sim/SIM_COUNTS';
export const CHANGE_PHONE_NUMBER = 'ultra/sim/CHANGE_PHONE_NUMBER';
export const FETCH_CLIENT_LISTING = 'ultra/client/FETCH_CLIENT_LISTING';
export const ADD_NEW_CLIENT = 'ultra/client/ADD_NEW_CLIENT';
export const UPDATE_CLIENT_NAME = 'ultra/client/UPDATE_CLIENT_NAME';
export const DELETE_CLIENT = 'ultra/client/DELETE_CLIENT';
export const EXPORT_SIMS_USAGE_SUMMARY =
  'ultra/client/EXPORT_SIMS_USAGE_SUMMARY';
export const GET_FW_DEVICE_LIST = 'ultra/user/GET_FW_DEVICE_LIST';
export const SET_COUNT = 'ultra/client/SET_COUNT';
export const UPDATE_ADD_ON_PLANS = 'ultra/sim/UPDATE_ADD_ON_PLANS';
export const FETCH_SIMS_WITH_ADD_ONS = 'ultra/sim/FETCH_SIMS_WITH_ADD_ONS';
export const BULK_MANAGE_ADD_ONS = 'ultra/sim/BULK_MANAGE_ADD_ONS';
export const CANCEL_RECURRING_PAYMENT = 'ultra/sim/CANCEL_RECURRING_PAYMENT';
export const GET_DATA_USAGE_FROM_ULTRA = 'ultra/sim/GET_DATA_USAGE_FROM_ULTRA';
export const UPDATE_VOICEMAIL_STATUS = 'ultra/sim/UPDATE_VOICEMAIL_STATUS';
export const GET_VOICEMAIL_STATUS = 'ultra/sim/GET_VOICEMAIL_STATUS';
export const BULK_PLAN_CHANGE = 'ultra/sim/BULK_PLAN_CHANGE';
export const FETCH_ADD_ONS_PLAN = 'ultra/plan/FETCH_ADD_ONS_PLAN';
export const BULK_ADD_ONS_PLAN = 'ultra/plan/BULK_ADD_ONS_PLAN';
export const REACTIVATE_DEACTIVE_SIMS = 'ultra/sim/REACTIVATE_DEACTIVE_SIMS';
export const UNPAUSE_SIMS = 'ultra/sim/UNPAUSE_SIMS';
export const ESIM_STATE = 'ultra/sim/ESIM_STATE';
export const SET_SIMS_INTO_NEVERBEEN = 'ultra/sim/SET_SIMS_INTO_NEVERBEEN';
export const GET_PRE_SIGNED_URL = 'ultra/sim/GET_PRE_SIGNED_URL';
export const GENERATE_PDF_URL = 'ultra/sim/GENERATE_PDF_URL';
export const DOWNLOAD_ESIM_PDF = 'ultra/sim/DOWNLOAD_ESIM_PDF';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  counts: 0,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SET_COUNT: {
      const { payload } = action;

      return state.set('counts', payload);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getSims = (data) => {
  return {
    type: GET_ALL_SIM,
    payload: api.getSims(data),
  };
};

export const exportSimsData = (data) => {
  return {
    type: EXPORT_SIMS_DATA,
    payload: api.exportSimsData(data),
  };
};

export const activateSims = (data) => ({
  type: ACTIVATE_SIMS,
  payload: api.activateSims(data),
});

export const activateEsimsbyId = (data) => ({
  type: ACTIVATE_ESIMS_BY_ID,
  payload: api.activateeSims(data),
});

export const activateBulkEsims = (data) => ({
  type: ACTIVATE_BULK_ESIMS,
  payload: api.activateBulkEsims(data),
});

export const getEsimQuantity = (data) => ({
  type: GET_ESIM_QUANTITY,
  payload: api.getEsimQuantity(data),
});

export const getEsimInfo = (data) => ({
  type: GET_ESIM_INFO,
  payload: api.getEsimInfo(data),
});

export const pauseSims = (data) => ({
  type: PAUSE_SIMS,
  payload: api.pauseSims(data),
});

export const deactivateSims = (data) => ({
  type: DEACTIVATE_SIMS,
  payload: api.deactivateSims(data),
});

export const activateSimByPlan = (data) => ({
  type: ACTIVATE_SIMS_PLAN,
  payload: api.activateSimByPlan(data),
});

export const reActivateSimByPlan = (data) => ({
  type: REACTIVATE_SIMS_PLAN,
  payload: api.reActivateSimByPlan(data),
});

export const cancelRecurringPayment = (data) => ({
  type: CANCEL_RECURRING_PAYMENT,
  payload: api.cancelRecurringPayment(data),
});

export const deleteSims = (data) => ({
  type: DELETE_SIMS,
  payload: api.deleteSims(data),
});

export const getPreSignedUrl = (data) => ({
  type: GET_PRE_SIGNED_URL,
  payload: api.getPreSignedUrl(data),
});

export const generatePDF = (data) => ({
  type: GENERATE_PDF_URL,
  payload: api.generatePDF(data),
});

export const setSimsIntoNeverBeen = (data) => ({
  type: SET_SIMS_INTO_NEVERBEEN,
  payload: api.setSimsIntoNeverBeen(data),
});

export const updateSimName = (data) => ({
  type: UPDATE_SIMCARD_NAMES,
  payload: api.updateSimName(data),
});

export const updateTag = (data) => ({
  type: UPDATE_TAGS,
  payload: api.updateTag(data),
});

export const updateSimCard = (data) => ({
  type: UPDATE_SIMCARD_DETAILS,
  payload: api.updateSimDetails(data),
});

export const addSims = (data) => ({
  type: ADD_SIMS,
  payload: api.addSims(data),
});

export const getBulkSims = (data) => ({
  type: GET_BULK_SIMS,
  payload: api.getBulkSims(data),
});

export const fetchTagsByType = (data) => ({
  type: GET_TAGS_BY_TYPE,
  payload: api.fetchTagsByType(data),
});

export const addBulkSims = (data) => ({
  type: ADD_BULK_SIMS,
  payload: api.addBulkSims(data),
});

export const fetchPlans = () => ({
  type: FETCH_SIMS_PLANS,
  payload: api.fetchPlans(),
});

export const fetchClients = () => ({
  type: FETCH_CLIENTS_PLANS,
  payload: api.fetchClients(),
});

export const addClient = (data) => ({
  type: ADD_CLIENTS_TO_SIMS,
  payload: api.addClient(data),
});

export const exportCsv = (data) => ({
  type: EXPORT_CSV,
  payload: api.exportCsv(data),
});

export const planChangeCount = (data) => ({
  type: SIM_DETAIL,
  payload: api.planChangeCount(data),
});
export const planDetails = (data) => ({
  type: SIM_DETAIL,
  payload: api.planDetails(data),
});
export const simcardDetail = (data) => ({
  type: SIM_DETAIL,
  payload: api.simcardDetail(data),
});
export const planCycle = (data) => ({
  type: SIM_DETAIL,
  payload: api.planCycle(data),
});
export const accountNumber = (data) => ({
  type: SIM_DETAIL,
  payload: api.accountNumber(data),
});
export const getActivitiesLogs = (data) => ({
  type: SIM_DETAIL,
  payload: api.getActivitiesLogs(data),
});
export const hidePlanCycle = (data) => ({
  type: SIM_DETAIL,
  payload: api.hidePlanCycle(data),
});
export const pukCodes = (data) => ({
  type: SIM_DETAIL,
  payload: api.pukCodes(data),
});
export const reconnectWaitTime = (data) => ({
  type: SIM_DETAIL,
  payload: api.reconnectWaitTime(data),
});
export const addOnPlans = (data) => ({
  type: SIM_DETAIL,
  payload: api.addOnPlans(data),
});
export const addOnPlansUsage = (data) => ({
  type: SIM_DETAIL,
  payload: api.addOnPlansUsage(data),
});
export const irIldDetails = (data) => ({
  type: SIM_DETAIL,
  payload: api.irIldDetails(data),
});
export const ActivePortinData = (data) => ({
  type: SIM_DETAIL,
  payload: api.ActivePortinData(data),
});
export const remainingIRBalance = (data) => ({
  type: SIM_DETAIL,
  payload: api.remainingIRBalance(data),
});
export const recurringInfo = (data) => ({
  type: SIM_DETAIL,
  payload: api.recurringInfo(data),
});

export const getUsageDetailsByMonth = (data) => ({
  type: GET_USAGE_DETAIL_MONTH,
  payload: api.getUsageDetailsByMonth(data),
});

export const getUsageDetailsByDay = (data) => ({
  type: GET_USAGE_DETAIL_DAY,
  payload: api.getUsageDetailsByDay(data),
});

export const getUsageDailySummary = (data, postData) => ({
  type: GET_USAGE_DAILY_SUMMARY,
  payload: api.getUsageDailySummary(data, postData),
});

export const removeClient = (data) => ({
  type: REMOVE_CLIENT,
  payload: api.removeClient(data),
});

export const getSwapSimSearch = (searchText, simType, isEsim) => ({
  type: SWAP_SIM_SEARCH,
  payload: api.getSwapSimSearch(searchText, simType, isEsim),
});

export const swapSIM = (data) => ({
  type: SWAP_SIM,
  payload: api.swapSIM(data),
});

export const swapESIM = (data) => ({
  type: SWAP_ESIM,
  payload: api.swapESIM(data),
});

export const simPortInActivation = (data) => ({
  type: PORT_IN_SIM,
  payload: api.simPortInActivation(data),
});

export const updateSimPortInRequest = (data) => ({
  type: UPDATE_PORT_IN_SIM,
  payload: api.updateSimPortInRequest(data),
});

export const simPortInCancel = (data) => ({
  type: PORT_IN_CANCEL_SIM,
  payload: api.simPortInCancel(data),
});

export const setCount = (data) => ({
  type: SET_COUNT,
  payload: data,
});

export const orderSims = (data) => ({
  type: ORDER_SIMS,
  payload: api.orderSims(data),
});

export const orderESims = (data) => ({
  type: ORDER_ESIMS,
  payload: api.orderESIMs(data),
});

export const getAllOrders = (data) => ({
  type: GET_ALLORDER_SIMS,
  payload: api.getAllOrders(data),
});

export const getOrderById = (data) => ({
  type: GET_ORDER_BY_SIM_ID,
  payload: api.getOrderById(data),
});

export const getSimsUsageSummaryReport = (data) => ({
  type: GET_SIMS_USAGE_SUMMARY_REPORT,
  payload: api.getSimsUsageSummaryReport(data),
});

export const enableWifiCalling = (data) => ({
  type: ENABLE_WIFI_CALLING,
  payload: api.enableWifiCalling(data),
});

export const refreshPortInStatus = (data) => ({
  type: REFRESH_PORT_IN_STATUS,
  payload: api.refreshPortInStatus(data),
});

export const reconnectNetwork = (data) => ({
  type: RECONNECT_TO_NETWORK,
  payload: api.reconnectNetwork(data),
});

export const adminOverride = (data) => ({
  type: ADMIN_OVERRIDE,
  payload: api.adminOverride(data),
});

export const fetchSimCounts = (data) => ({
  type: SIM_COUNTS,
  payload: api.getSimCounts(data),
});

export const changePhoneNumber = (data) => ({
  type: CHANGE_PHONE_NUMBER,
  payload: api.changePhoneNumber(data),
});

export const addNewClient = (data) => ({
  type: ADD_NEW_CLIENT,
  payload: api.addNewClient(data),
});

export const fetchClientListing = (data) => ({
  type: FETCH_CLIENT_LISTING,
  payload: api.fetchClientListing(data),
});

export const updateClientName = (data) => ({
  type: UPDATE_CLIENT_NAME,
  payload: api.updateClientName(data),
});

export const deleteClient = (data) => ({
  type: DELETE_CLIENT,
  payload: api.deleteClient(data),
});

export const exportSimsUsageSummary = (data) => ({
  type: EXPORT_SIMS_USAGE_SUMMARY,
  payload: api.exportSimsUsageSummary(data),
});

export const fetchFwdeviceList = () => ({
  type: GET_FW_DEVICE_LIST,
  payload: api.fetchFwdeviceList(),
});

export const updateAddOnPlans = (data) => ({
  type: UPDATE_ADD_ON_PLANS,
  payload: api.updateAddOnPlans(data),
});

export const fetchSimsWithAddOns = (data, searchBy) => ({
  type: FETCH_SIMS_WITH_ADD_ONS,
  payload: api.fetchSimsWithAddOns(data, searchBy),
});

export const bulkManageAddOns = (data) => ({
  type: BULK_MANAGE_ADD_ONS,
  payload: api.bulkManageAddOns(data),
});
export const getDataUsageFromUltra = (data) => ({
  type: GET_DATA_USAGE_FROM_ULTRA,
  payload: api.getDataUsageFromUltra(data),
});
export const UpdateVoicemailStatus = (data) => ({
  type: UPDATE_VOICEMAIL_STATUS,
  payload: api.UpdateVoicemailStatus(data),
});
export const getWifiCallingDetails = (data) => ({
  type: UPDATE_VOICEMAIL_STATUS,
  payload: api.getWifiCallingDetails(data),
});
export const getVoiceMailStatus = (data) => ({
  type: GET_VOICEMAIL_STATUS,
  payload: api.getVoiceMailStatus(data),
});
export const bulkPlanChange = (data) => ({
  type: BULK_PLAN_CHANGE,
  payload: api.bulkPlanChange(data),
});
export const fetchAddOnPlans = () => {
  return {
    type: FETCH_ADD_ONS_PLAN,
    payload: api.fetchAddOnPlans(),
  };
};
export const bulkAddOnsPlan = (data) => {
  return {
    type: BULK_ADD_ONS_PLAN,
    payload: api.bulkAddOnsPlan(data),
  };
};
export const reActivateSim = (data) => ({
  type: REACTIVATE_DEACTIVE_SIMS,
  payload: api.reActivateSim(data),
});

export const unPauseSim = (data) => ({
  type: UNPAUSE_SIMS,
  payload: api.unPauseSim(data),
});

export const getAdditionalEsimInfo = (data) => ({
  type: ESIM_STATE,
  payload: api.getAdditionalEsimInfo(data),
});

export const downloadEsimPDF = (data) => ({
  type: DOWNLOAD_ESIM_PDF,
  payload: api.downloadEsimPDF(data)
})

/***********************************
 * Epics
 ***********************************/

export const simEpic = combineEpics();
