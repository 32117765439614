import React, { useState } from 'react';
import _ from 'lodash';

import '../styles.scss';

function ZipcodeComponent(props) {
  const [zipcode, setZipcode] = useState('');
  const [error, setError] = useState(false);

  const zipcodeSubmit = () => {
    try {
      if (!zipcode) {
        setError(true);
      } else if (zipcode && zipcode.length < 5) {
        setError(true);
      } else {
        setError(false);
        props.nextStep({ zipcode });
      }
    } catch (error) {
      console.log(error, 'err');
    }
  };

  return (
    <>
      <div className="device-coverage-wrap">
        <div className="card">
          <div className="card-body">
            <h5 className="title">Check the coverage in your area</h5>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group material-textfield">
                  <input
                    type="number"
                    id="zipcode"
                    className="form-control form-control-lg material-textfield-input"
                    name="zipcode"
                    value={zipcode || ''}
                    onChange={(e) => {
                      setZipcode(e.target.value);
                    }}
                    required
                  />
                  <label className="material-textfield-label">Zip code</label>
                  {_.get(props, 'zipCodeApiError', '') !== '' ? (
                    <div className="invalid-feedback">
                      {_.get(props, 'zipCodeApiError', '')}
                    </div>
                  ) : (
                    <div className="invalid-feedback">
                      {error ? 'Please input a valid zipcode' : ''}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  onClick={zipcodeSubmit}
                  disabled={props.zipcodeLoding}
                  className="btn btn-dark btn-lg w-100"
                >
                  {props.zipcodeLoding ? 'Please wait...' : 'Lets go!'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ZipcodeComponent;
