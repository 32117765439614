import React from 'react';
import ReactModal from 'react-modal';
import { FailedIcon } from '../../../components/icons';

// const plans = [
// 	{ productid: '7000335', title:  'Consumer 1GB Single Month' },
// 	{ productid: '7000336', title:  'Consumer 2GB Single Month' },
// 	{ productid: '7000337', title:  'Consumer 5GB Single Month' },
// 	{ productid: '7000338', title:  'Consumer 15GB Single Month' },
// 	{ productid: '7000339', title:  'Consumer | Unlimited' },
// 	{ productid: '7000339', title:  'Consumer | Unlimited' },
// 	{ productid: '7000336', title:  'Consumer 2GB Single Month' },
// 	{ productid: '7000082', title:  'Voice Unlimited 6GB Single Month' },
// 	{ productid: '7000339', title:  'Consumer | Unlimited' }
// ]
const EligiblePlansModal = ({ eligiblePlansOpen, closeModal, plansData }) => {
  return (
    <ReactModal
      isOpen={eligiblePlansOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Success"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
    >
      <div className="modal-content">
        <div className="connection-message-header failed">
          <div>
            <FailedIcon />
          </div>
          <h6>Failed</h6>
        </div>
        <div className="react-modal-body">
          <div className="connection-message form-group">
            <p>
              {' '}
              Sorry, this feature is not available on this plan. Please choose
              an IR or ILD Compatible Plan.
            </p>
          </div>
          <button
            type="submit"
            className="btn btn-purple btn-lg w-100"
            onClick={closeModal}
          >
            Ok
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default EligiblePlansModal;
