import { connect } from 'react-redux';
import { fetchMe, updatePartnerProfile } from '../../store/user/duck';
import {
  fetchSPRPlans,
  fetchLoB,
  fetchFixedPlans,
} from '../../store/plan/duck';
import {
  setUser,
  getUsers,
  fetchUserPlans,
  updatePlans,
  fetchScope,
  fetchAllUserPlanById,
} from '../../store/user/duck';
import EditUserComponent from './component';

const EditUserContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    updatePartnerProfile,
    fetchSPRPlans,
    setUser,
    getUsers,
    fetchUserPlans,
    fetchAllUserPlanById,
    updatePlans,
    fetchScope,
    fetchLoB,
    fetchFixedPlans,
  }
)(EditUserComponent);

export default EditUserContainer;
