import { connect } from 'react-redux';

import { fetchMe, setUser, userImpersonate } from '../../../store/user/duck';
import { fetchSPRRecentOrders, getReport } from '../../../store/dashboard/duck';

import RecentlyOderedSimsComponent from './component';

const RecentlyOderedSimsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchSPRRecentOrders,
    getReport,
    userImpersonate,
  }
)(RecentlyOderedSimsComponent);

export default RecentlyOderedSimsContainer;
