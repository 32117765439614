/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Popover, DatePicker, Select, notification, Switch } from 'antd';
import InputMask from 'react-text-mask';
import ReactModal from 'react-modal';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import PlacesAutocomplete from '../../components/PlaceSearchBox';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';
import {
  SimsColorIcon,
  FilterIcon,
  ExportIcon,
  CloseIcon,
  CalenderIcon,
  RefreshIcon,
  ErrorIcon,
} from '../../components/icons';
import SimList from './SimList/container';
import { DebounceInput } from 'react-debounce-input';
import './styles.scss';
import _ from 'lodash';
import moment from 'moment';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getZipCodeSuccessMessage } from '../../constants/common';
import QR from 'react-qr-code';
import ClientModal from '../../components/ClientModal';
import clsx from 'clsx';
import { handleEsimPdfDownload } from '../../utils/helpers';

const SuccessIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <g>
        <circle cx="11" cy="11" r="11" fill="#58A770"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10.074 14.64l-3.79-3.87L7.51 9.517l2.564 2.619L15.101 7l1.226 1.253-6.253 6.388z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

const DownloadIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="24"
      fill="none"
      viewBox="0 0 17 24"
      {...props}
    >
      <path
        fill="#673594"
        fillRule="evenodd"
        d="M9.258 17.505l5.92-6.003c1.042-1.045-.521-2.613-1.564-1.568l-3.484 3.495c-.291.292-.524.196-.524-.211V1.108C9.606.497 9.11 0 8.5 0 7.89 0 7.395.496 7.395 1.11v12.109c0 .406-.235.501-.525.211L3.386 9.934c-1.043-1.045-2.606.523-1.564 1.568l5.881 6c.428.437 1.125.439 1.555.003zm-8.15 4.022a1.109 1.109 0 000 2.218h14.783a1.109 1.109 0 000-2.218H1.11z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

// For tags options
const { Option } = Select;
const dateFormat = 'MMMM DD YYYY';
const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const sprPaths = [
  '/active-sims',
  '/deactivated-sims',
  '/paused-sims',
  '/available-sims',
  '/activation-in-progress',
  '/available-esims',
];

export default class YourSiMsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSimsForZipChange: [],
      isLoading: true,
      isExportLoading: false,
      userData: null,
      iAuthorization: localStorage.getItem('i_Authorization'),
      titleText: '',
      descriptionText: '',
      openThankYouModal: false,
      openLoadingModal: false,
      openCancelPortInFailureModal: false,
      openErrorModal: false,
      notifications: [],
      planError: false,
      isApplyButton: false,
      simPlansData: [],
      selectedSimPlan: {},
      updateLoading: false,
      requireName: false,
      deleteModal: false,
      deleteSimnumber: {},
      isActivateModal: false,
      newSimName: '',
      isUpdateSimModal: false,
      ChangeplanildPlanType: '',
      ChangeplanirPlanType: '',
      newTag: '',
      originalSelectedTags: [],
      selectedListTags: [],
      allChecked: false,
      markedSimList: [],
      selectedAction: '',
      isCreateTagModal: false,
      isCreateClientModal: false,
      isActionModal: false,
      addTags: false,
      isFiltervisible: false,
      isDateFilter: false,
      isTagsFilter: false,
      isClientFilter: false,
      isSimsFilter: false,
      changePlanModal: false,
      selectedItem: {},
      selectedMenuKey: '',
      sims: [],
      total: 0,
      counts: {},
      filterTags: [],
      searchFilterTags: [],
      searchClientFilter: [],
      simFilter: [],
      eSimFilter: false,
      pSimFilter: false,
      hasSamePlan: false,
      fieldName: '',
      activeTab: 'active',
      type: 'active',
      userId: 0,
      page: 1,
      sortBy: 'activationdate',
      sortOrder: 'desc',
      limit: 50,
      keyword: '',
      tags: '',
      byDate: false,
      startDate: '',
      endDate: '',
      searchSims: '',
      isExport: false,
      newSimNameSize: false,
      newTagSize: false,
      isTagChanged: false,
      portSimModal: false,
      portInPhoneNumber: '',
      requirePortInPhoneNumber: false,
      selectedCarrier: '',
      carrierError: false,
      carrierACNumber: '',
      requireCarrierACNumber: false,
      carrierAssignPassword: '',
      requireCarrierAssignPassword: false,
      name: '',
      nameError: false,
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip4: '',
      zipcodePortIn: '',
      requireZipcodePortIn: false,
      requireAltContactNumber: false,
      altContactNumber: '',
      clients: [],
      selectedClient: [],
      newClientSize: false,
      isExportSimsData: false,
      orderType: 'spr',
      changedSimPlan: {},
      simLengthError: '',
      plans: [],
      planFilter: [],
      zipcodeErrorMessage: '',
      reactivateSimModal: false,
      reactivateLoading: false,
      reactivatePlanError: false,
      isUnpauseModal: false,
      planValue: '',
      unPauseSimPlanError: false,
      isIldIrEnabled: false,
      isILDEnabled: false,
      isIREnabled: false,
      ildPlanType: 'recurring',
      ildPlanValue: 0,
      ildPlanTypeRequired: false,
      ildPlanValueRequired: false,
      irPlanType: 'recurring',
      irPlanValue: 0,
      irPlanTypeRequired: false,
      irPlanValueRequired: false,
      remainingIRBalanceError: false,
      irPlans: [],
      ildPlans: [],
      remainingIRBalance: 0,
      addIRBalance: 0,
      isMoveBackModal: false,
      searchBy: 'iccid',
      openImeiValidateModal: false,
      activateEsimModal: false,
      convertesimModal: false,
      isCheckCoverage: false,
      isCheckDeviceCompatibility: false,
      isSelectPlan: false,
      isInstalleSIM: false,
      plansArray: [],
      eSIMCompatible: false,
      zipCodeApiError: '',
      zipCodeSuccess: '',
      zipCodeSuccessMessage: '',
      zipcode: '',
      finalZip: '',
      zipcodeLoding: false,
      imeiNumber: '',
      modelArray: [],
      model: '',
      deviceApiError: '',
      imeiLoding: false,
      brandLoading: false,
      activateLoading: false,
      deviceName: '',
      brandsArray: [],
      brand: '',
      proceed: false,
      enableEsummary: false,
      activateeSim: false,
      enableQRCodeModal: false,
      eSimObj: {},
      eSimAvailability: false,
      disableButton: false,
      activationErrorMsg: '',
      plan: '',
      isImeiSection: false,
      isCheckByBrandModal: false,
      Inprogress: false,
      swapESimError: '',
      portInFailsPopup: false,
      newZipcode: '',
      activateEsimZip: '',
      eSimDetail: {},
      isActivateEsimModal: false,
      validationLoading: false,
      areIMEIsCompatible: true,
      simDataWithImeiCompatibility: [],
    };
    this.createTagsModal = this.createTagsModal.bind(this);
    this.createClientModal = this.createClientModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      deviceApiError: '',
      zipcode: '',
      portSimModal: false,
      updateLoading: false,
      zipcodeErrorMessage: '',
      selectedAction: '',
      requireName: false,
      deleteSimnumber: {},
      deleteModal: false,
      isCreateTagModal: false,
      isCreateClientModal: false,
      isActionModal: false,
      isUpdateSimModal: false,
      isActivateModal: false,
      reactivateSimModal: false,
      isUnpauseModal: false,
      isILDEnabled: false,
      isIREnabled: false,
      irPlanTypeRequired: false,
      irPlanValueRequired: false,
      remainingIRBalanceError: false,
      isMoveBackModal: false,
      activateEsimModal: false,
      swapESimError: '',
      imeiNumber: '',
      zipCodeSuccess: false,
      zipCodeSuccessMessage: '',
      isImeiSection: false,
      updateEsimLoading: false,
      brand: '',
      model: '',
      deviceName: '',
      zipCodeApiError: '',
      portInFailsPopup: false,
      eSimDetail: {},
      isActivateEsimModal: false,
    });
  }

  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    document.title = 'Your SPR Sims | UltraIoT';
    const { fetchMe, fetchPlans, match, user } = this.props;

    if (_.isEmpty(user)) {
      const {
        value: { success, user },
      } = await fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
      }
    } else {
      this.setState({ userData: user });
    }

    if (this.state.brandsArray.length === 0) {
      const {
        value: { success, data },
      } = await this.props.getBrands();
      if (success) {
        const sortedBrands = _.orderBy(
          _.get(data, 'brands', []),
          ['name'],
          ['asc']
        );
        this.setState({ brandsArray: sortedBrands });
      }
    }

    Promise.all([fetchPlans(), this.props.fetchClients()]).then(async (res) => {
      const plansDetail = res[0].value;
      const clientsDetail = res[1].value;
      const addOnPlansDetail = res[0].value.addonPlans;
      // Handling plan data
      if (plansDetail.success) {
        const plans = plansDetail.plans;
        let filteredPlans = [];
        filteredPlans = plans.filter((plan) => plan.category !== 'Fixed');
        this.setState({ simPlansData: filteredPlans });

        const data = addOnPlansDetail;
        const irPlans = _.get(data, 'plan', [])
          .filter((plan) => plan.type === 'ir')
          .sort((a, b) => a.titleValue - b.titleValue);
        let ildPlans = _.get(data, 'plan', []).filter(
          (plan) => plan.type === 'ild'
        );
        ildPlans = ildPlans.sort(this.sortByProperty('titleValue'));
        const remainingIRBalance = _.get(data, 'remainingIRBalance', 0);
        this.setState({
          irPlans: irPlans,
          ildPlans: ildPlans,
          remainingIRBalance,
        });
      }

      const orderType = sprPaths.indexOf(match.path) !== -1 ? 'spr' : 'fixed';
      await this.setAsyncState({ orderType });

      // Handling clients data
      if (clientsDetail.success) {
        this.setState({ clients: clientsDetail.data });
      }
      let selectedTab = '';
      let tab = _.get(this.props, 'location.pathname', '/active-sims');
      if (tab === '/active-sims') {
        selectedTab = 'active';
        document.title = 'Active SIMs | UltraIoT';
      } else if (tab === '/activation-in-progress') {
        document.title = 'Activation In Progress SIMs | UltraIoT';
        selectedTab = 'activationInProgress';
      } else if (tab === '/available-sims') {
        selectedTab = 'inactive';
        document.title = 'Available SIMs | UltraIoT';
      } else if (tab === '/available-esims') {
        selectedTab = 'availableeSim';
        document.title = 'Available eSIMs | UltraIoT';
      } else if (tab === '/paused-sims') {
        this.setState({ sortBy: 'datePlanChanged' });
        selectedTab = 'paused';
        document.title = 'Paused SIMs | UltraIoT';
      } else if (tab === '/deactivated-sims') {
        this.setState({ sortBy: 'datePlanChanged' });
        selectedTab = 'deactive';
        document.title = 'Deactived SIMs | UltraIoT';
      }
      this.setState({ activeTab: selectedTab });
      if (_.get(this.props, 'location.state.from', false) === 'dashboard') {
        let tags = _.get(this.props, 'location.state.tags', '');
        let plan = _.get(this.props, 'location.state.plan', '');
        if (tags) {
          let searchFilterTags = tags.split(',');
          this.setState({ searchFilterTags, tags, isTagsFilter: true }, () => {
            this.getSimsOnTabChange();
          });
        }
        if (plan) {
          let planFilter = [plan];
          this.setState({ planFilter, isPlanFilter: true }, () => {
            this.getSimsOnTabChange();
          });
        }
      } else {
        this.getSimsOnTabChange();
      }

      this.clearLocationState();
    });
  };

  getAllTags = async (q) => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchTagsByType({ q, type: 'all', simType: 'spr' });
      if (success) {
        this.setState({ filterTags: data });
      }
    } catch (error) {}
  };

  setSortByKeys = async (key, order) => {
    this.setState({ sortBy: key, sortOrder: order });
  };

  getAllClients = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchClients();
      if (success) {
        this.setState({ clients: data });
      }
    } catch (error) {}
  };

  onPlanFilterChange(plan) {
    const planFilter = [...this.state.planFilter];
    const index = planFilter.map(({ id }) => id).indexOf(plan.id);
    if (index === -1) {
      planFilter.push(plan);
    } else {
      planFilter.splice(index, 1);
    }
    this.setState({ planFilter }, () => {
      this.getSimsOnTabChange();
    });
  }

  clearLocationState = () => {
    const { history } = this.props;
    if (history) {
      const state = { ...history.location.state };
      if (state && state.from && state.tags) {
        delete state.from;
        delete state.tags;
      }
      history.replace({ ...history.location, state });
    }
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab, page: 1, allChecked: false }, () => {
      if (tab === 'active') {
        this.props.history.push('/active-sims');
        document.title = 'Active SIMs | UltraIoT';
      } else if (tab === 'activationInProgress') {
        this.props.history.push('/activation-in-progress');
        document.title = 'Activation In Progress SIMs | UltraIoT';
      } else if (tab === 'inactive') {
        this.props.history.push('/available-sims');
        document.title = 'Available SIMs | UltraIoT';
      } else if (tab === 'availableeSim') {
        this.props.history.push('/available-esims');
        document.title = 'Available eSIMs | UltraIoT';
      } else if (tab === 'paused') {
        this.props.history.push('/paused-sims');
        document.title = 'Paused SIMs | UltraIoT';
      } else if (tab === 'deactive') {
        this.props.history.push('/deactivated-sims');
        document.title = 'Deactived SIMs | UltraIoT';
      } else if (tab === 'spr-orders') {
        this.props.history.push('/spr-orders');
        document.title = 'Deactived SIMs | UltraIoT';
      } else if (tab === 'eSIM-orders') {
        this.props.history.push('/eSIM-orders');
        document.title = 'eSIM Orders | UltraIoT';
      }
      this.getSimsOnTabChange();
    });
  };

  getSimsOnTabChange = async () => {
    this.setState({ isLoading: true });
    const {
      planFilter,
      activeTab,
      page,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      searchClientFilter,
      simFilter,
      orderType,
      searchBy,
    } = this.state;
    let { sortBy } = this.state;
    let obj = {
      type: activeTab,
      page,
      sortBy,
      sortOrder,
      limit,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      isExport: false,
      clients: searchClientFilter.map((item) => item.id),
      orderType,
      plans: planFilter.map(({ id }) => id),
      searchBy,
      simFilter,
    };
    try {
      const {
        value: { success, counts, total, records },
      } = await this.props.getSims(obj);
      if (success) {
        this.setState({
          sims: records,
          markedSimList: [],
          allChecked: false,
          total,
          counts,
          isLoading: false,
        });
        this.props.setCount(counts);
        this.markedNewTag(records);
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  markedNewTag = (records) => {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      let matchedData = records.filter((record) => {
        return (
          markedSimList.filter((mark) => {
            return mark.id === record.id;
          }).length !== 0
        );
      });
      this.setState({ markedSimList: [...matchedData] });
    }
  };

  hide = () => {
    this.setState({
      isFiltervisible: false,
    });
  };

  filterVisible = (visible) => {
    this.setState({ isFiltervisible: visible });
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getSimsOnTabChange();
    });
  };

  //Filter Methods
  onSimFilter = () => {
    let { pSimFilter, eSimFilter, simFilter, fieldName } = this.state;
    if (pSimFilter === true && fieldName === 'pSIMs') {
      simFilter.push(0);
    } else if (simFilter.length > 0 && fieldName === 'pSIMs') {
      const index = simFilter.indexOf(0);
      if (index > -1) {
        simFilter.splice(index, 1);
      }
    }

    if (eSimFilter === true && fieldName === 'eSIMs') {
      simFilter.push(1);
    } else if (simFilter.length > 0 && fieldName === 'eSIMs') {
      const index = simFilter.indexOf(1);
      if (index > -1) {
        simFilter.splice(index, 1);
      }
    }
    this.setState({ simFilter }, () => {
      this.getSimsOnTabChange();
    });
  };

  //Filter Methods
  onClientFilter = (client) => {
    let allClients = this.state.searchClientFilter;
    let index = allClients.findIndex((t) => t.id === client.id);
    if (index === -1) {
      allClients.push(client);
    } else {
      allClients.splice(index, 1);
    }
    this.setState({ searchClientFilter: allClients }, () => {
      this.getSimsOnTabChange();
    });
  };

  onTagsFilter = (tag) => {
    let allTags = this.state.searchFilterTags;
    if (!allTags.includes(tag)) {
      allTags.push(tag);
    } else {
      let index = allTags.findIndex((t) => t === tag);
      allTags.splice(index, 1);
    }
    this.setState({ searchFilterTags: allTags }, () => {
      let tags = '';
      _.forEach(allTags, (item, index) => {
        tags += item;
        if (index < allTags.length - 1) {
          tags += ',';
        }
      });
      this.setState({ tags }, () => {
        this.getSimsOnTabChange();
      });
    });
  };

  onChangeDate = (from, date, dateString) => {
    if (from === 'start') {
      this.setState({ startDate: date, endDate: '' });
    } else if (from === 'end') {
      this.setState({ endDate: date });
      this.setState({ byDate: true }, () => {
        this.getSimsOnTabChange();
      });
    }
  };

  disabledDate = (current) => {
    return current && current.valueOf() <= new Date(this.state.startDate);
  };

  onClearFilter = () => {
    this.setState(
      {
        isClientFilter: false,
        searchClientFilter: [],
        isTagsFilter: false,
        isDateFilter: false,
        tags: '',
        byDate: false,
        startDate: '',
        endDate: '',
        searchFilterTags: [],
        isPlanFilter: false,
        planFilter: [],
        isSimsFilter: false,
        eSimFilter: false,
        pSimFilter: false,
        simFilter: [],
      },
      () => {
        var inputs = document.querySelectorAll('.unCheckAll');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].checked = false;
        }
        this.getSimsOnTabChange();
      }
    );
  };

  removeFilter(index) {
    if (index === -10) {
      this.setState(
        { isDateFilter: false, byDate: false, startDate: '', endDate: '' },
        () => {
          this.forceUpdate();
          this.getSimsOnTabChange();
        }
      );
    } else {
      let searchFilterTags = this.state.searchFilterTags;
      searchFilterTags.splice(index, 1);
      this.setState({ searchFilterTags }, () => {
        let tags = '';
        _.forEach(searchFilterTags, (item, index) => {
          tags += item;
          if (index < searchFilterTags.length - 1) {
            tags += ',';
          }
        });
        this.setState({ tags }, () => {
          this.forceUpdate();
          this.getSimsOnTabChange();
        });
      });
    }
  }

  removeClientFilter(index) {
    let searchClientFilter = this.state.searchClientFilter;
    searchClientFilter.splice(index, 1);
    this.setState({ searchClientFilter }, () => {
      this.forceUpdate();
      this.getSimsOnTabChange();
    });
  }

  // remove Esim filter
  removeESimFilter = () => {
    let simFilter = this.state.simFilter;
    const index = simFilter.indexOf(1);
    simFilter.splice(index, 1);
    this.setState({ eSimFilter: !this.state.eSimFilter }, () => {
      this.forceUpdate();
      this.getSimsOnTabChange();
    });
  };

  // remove Psim filter
  removePSimFilter = () => {
    let simFilter = this.state.simFilter;
    const index = simFilter.indexOf(0);
    simFilter.splice(index, 1);
    this.setState({ pSimFilter: !this.state.pSimFilter }, () => {
      this.forceUpdate();
      this.getSimsOnTabChange();
    });
  };

  // Update Sim Name
  updateSimName = async () => {
    const { newSimName, markedSimList } = this.state;
    if (newSimName.trim().length === 0) {
      this.setState({ requireName: true });
    } else if (newSimName.trim().length < 3) {
      this.setState({ newSimNameSize: true });
    } else {
      this.setState({ updateLoading: true });
      let obj = {};
      obj.simname = newSimName;
      obj.ids = markedSimList.map((sim) => sim.id);
      try {
        const {
          value: { success },
        } = await this.props.updateSimName(obj);
        if (success) {
          this.setState(
            {
              updateLoading: false,
              isUpdateSimModal: false,
              newSimName: '',
              keyword: '',
              selectedAction: '',
            },
            () => {
              this.getSimsOnTabChange();
            }
          );
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          isUpdateSimModal: false,
          selectedAction: '',
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  singleSimAction = async (key, item) => {
    let { markedSimList } = this.state;
    if (key === 'activate') {
      markedSimList = [];
      markedSimList.push(item);
      this.setState({ markedSimList }, async () => {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        let areZipcodesEligible = true;
        if (markedSimList.length) {
          areZipcodesEligible =
            await this.checkAllZipcodeEligibility(markedSimList);
        }
        const zipcode =
          item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
            ? item.zipcodeOnChange
            : item.zipcode;

        if (zipcode.length !== 5) {
          error = true;
          item.isZipCodeIneligible = true;
        }

        if (error) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else if (!areZipcodesEligible) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is not eligible to activate sim. Please enter valid zip for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else {
          this.setState({
            isActivateModal: true,
            selectedSimPlan: {},
            planError: false,
          });
        }
      });
    } else if (key === 'delete') {
      this.setState({ deleteSimnumber: item, deleteModal: true });
    } else if (key === 'activateEsimbyID') {
      this.setState({ selectedAction: key });
      let allSims = this.state.sims;
      markedSimList = [];
      markedSimList.push(item);
      this.setState({ markedSimList }, async () => {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        if (
          this.state.selectedAction === 'activateEsim' &&
          (item.zipcode === '' || item.zipcode.length !== 5)
        ) {
          error = true;
          item.isZipCodeIneligible = true;
        }
        if (error) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
          this.setState({
            openThankYouModal: true,
            titleText,
            descriptionText,
          });
        }
        this.setState({ sims: allSims });

        if (!error) {
          this.setState({ activateEsimModal: true });
        }
      });
    }
  };

  deleteSim = async () => {
    const { deleteSimnumber, markedSimList } = this.state;
    let simIds = [];
    if (_.isEmpty(deleteSimnumber)) {
      simIds = markedSimList.map((item) => item.id);
    } else {
      simIds.push(deleteSimnumber.id);
    }
    this.setState({ updateLoading: true });
    let obj = {
      ids: simIds,
    };
    try {
      const {
        value: { success },
      } = await this.props.deleteSims(obj);
      if (success) {
        this.setState({
          deleteModal: false,
          deleteSimnumber: {},
          updateLoading: false,
          keyword: '',
        });
        this.getSimsOnTabChange();
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        deleteModal: false,
        deleteSimnumber: {},
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  setSimsIntoNeverBeen = async () => {
    const { markedSimList } = this.state;
    const simIds = markedSimList.map((item) => item.id);
    this.setState({ updateLoading: true });
    let obj = {
      ids: simIds,
    };
    try {
      const {
        value: { success },
      } = await this.props.setSimsIntoNeverBeen(obj);
      if (success) {
        this.setState({
          updateLoading: false,
          keyword: '',
          markedSimList: [],
          isMoveBackModal: false,
        });
        this.getSimsOnTabChange();
      }
    } catch (err) {
      this.setState({
        updateLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  //Call Activate Sim API From Available SIMs Tab
  activateSim = async () => {
    const {
      selectedSimPlan,
      userData,
      markedSimList,
      sims,
      isILDEnabled,
      isIREnabled,
      isIldIrEnabled,
      ildPlanType,
      ildPlanValue,
      irPlanType,
      irPlanValue,
      ildPlans,
      irPlans,
      addIRBalance,
      remainingIRBalance,
      activeTab,
    } = this.state;
    _.forEach(markedSimList, function (value) {
      if (value.simname === '') {
        value.simname = value.simnumber;
      }
    });

    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ planError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (_.get(userData, 'role', 0) === 'user') {
        if (selectedSimPlan.title !== 'Test Data Plan') {
          let amountToCharge =
            markedSimList.length * selectedSimPlan.monthlyprice;
          if (userData.availablebalance === 0) {
            error = true;
            descriptionText =
              'You have 0 balance in your account. Please add fund to activate sims.';
          } else if (amountToCharge > userData.availablebalance) {
            error = true;
            descriptionText =
              'You have insufficient balance in your account. Please add fund to activate sims.';
          }
        } else if (
          selectedSimPlan.title === 'Test Data Plan' &&
          markedSimList.length > 1
        ) {
          error = true;
          descriptionText =
            'You can activate only 1 SIM in test data plan. Please choose a single SIM instead.';
        }
      }

      let required = false;
      if (isILDEnabled) {
        if (ildPlanType === '') {
          required = true;
          this.setState({ ildPlanTypeRequired: true });
        }
        if (ildPlanValue === 0) {
          required = true;
          this.setState({ ildPlanValueRequired: true });
        }
      }
      if (isIREnabled) {
        if (irPlanType === '') {
          required = true;
          this.setState({ irPlanTypeRequired: true });
        }
        if (irPlanValue === 0) {
          required = true;
          this.setState({
            irPlanValueRequired: true,
            remainingIRBalanceError: false,
          });
        }
        if (irPlanValue > 0) {
          const simsLength = markedSimList.length;
          const totalAmount = parseInt(addIRBalance) * parseInt(simsLength);
          if (totalAmount > remainingIRBalance) {
            error = true;
            this.setState({ remainingIRBalanceError: true });
          }
        }
      }

      if (!error) {
        let titleText = 'Unpause Info';
        let descriptionText = '';
        let obj = {};

        try {
          if (!required) {
            this.setState({
              updateLoading: true,
              remainingIRBalanceError: false,
            });
            if (activeTab === 'availableeSim') {
              obj.zipcode = this.state.activateEsimZip;
              obj.isILDEnabled = isILDEnabled;
              obj.isIREnabled = isIREnabled;
              obj.isIldIrEnabled = isIldIrEnabled;
              obj.ildPlanType = ildPlanType;
              obj.ildPlanValue = ildPlanValue;
              obj.irPlanType = irPlanType;
              obj.irPlanValue = irPlanValue;
            }
            if (activeTab === 'inactive') {
              obj.isILDEnabled = isILDEnabled;
              obj.isIREnabled = isIREnabled;
              obj.isIldIrEnabled = isIldIrEnabled;
              obj.ildPlanType = ildPlanType;
              obj.ildPlanValue = ildPlanValue;
              obj.irPlanType = irPlanType;
              obj.irPlanValue = irPlanValue;
            }

            obj.sims = markedSimList.map((sim) => ({
              simname: sim.simname,
              iccid: sim.simnumber,
              zipcode: sim.zipcode,
            }));

            if (_.get(userData, 'consumeOfferAPI', false)) {
              obj.offerId = selectedSimPlan.offerId;
              obj.title = selectedSimPlan.title;
              if (isILDEnabled) {
                const filterP = ildPlans.filter((el) => {
                  return parseInt(el.titleValue) === parseInt(ildPlanValue);
                });
                if (filterP && filterP.length > 0) {
                  obj.ildAddOnplanOfferId = filterP[0].offerId;
                }
              }
              if (isIREnabled) {
                const filterP = irPlans.filter((el) => {
                  return parseInt(el.titleValue) === parseInt(irPlanValue);
                });
                if (filterP && filterP.length > 0) {
                  obj.irAddOnplanOfferId = filterP[0].offerId;
                }
              }
            } else {
              obj.planid = selectedSimPlan.id;
            }

            const {
              value: { success, data },
            } = await (activeTab === 'availableeSim'
              ? markedSimList.length > 1
                ? this.props.activateBulkEsims(obj)
                : this.props.activateEsimsbyId(obj)
              : this.props.activateSims(obj));
            if (success) {
              if (activeTab === 'availableeSim' && markedSimList.length === 1) {
                this.setState(
                  {
                    sims,
                    eSimDetail: data,
                    isActivateModal: false,
                    updateLoading: false,
                    remainingIRBalanceError: false,
                  },
                  () => {
                    this.setState({
                      isActivateEsimModal: true,
                      markedSimList: [],
                      allChecked: false,
                      keyword: '',
                      activateEsimZip: '',
                    });
                  }
                );
              } else {
                titleText = 'Thank You';
                descriptionText =
                  'Your SIM card(s) are processing. Check the notifications icon for updates.';
                _.forEach(markedSimList, (item) => {
                  let index = sims.findIndex((sim) => item.id === sim.id);
                  if (index > -1) {
                    let obj = sims[index];
                    obj.status = 12;
                  }
                });
                this.setState(
                  {
                    sims,
                    isActivateModal: false,
                    updateLoading: false,
                    titleText,
                    descriptionText,
                    remainingIRBalanceError: false,
                  },
                  () => {
                    this.setState({
                      openThankYouModal: true,
                      markedSimList: [],
                      allChecked: false,
                      keyword: '',
                    });
                  }
                );
              }
            }
          }
        } catch (err) {
          const error = _.get(err, 'data', err);
          titleText = 'Activate eSIM Info - Error';
          if (activeTab === 'availableeSim' && err.status === 400) {
            descriptionText = `Zipcode ${this.state.activateEsimZip} is not eligible for activation.`;
          } else {
            descriptionText = `${_.get(
              error,
              'message',
              'Error while processing request'
            )}`;
          }
          this.setState({
            activateEsimZip: '',
            titleText,
            descriptionText,
            updateLoading: false,
            openThankYouModal: true,
            isActivateModal: false,
            errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: false,
        });
      }
    }
  };

  pauseSim = async () => {
    const { markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let obj = {};
    obj.action = 'pause';
    obj.sims = markedSimList.map((sim) => sim.id);
    try {
      const {
        value: { success },
      } = await this.props.pauseSims(obj);
      if (success) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          markedSimList: [],
          keyword: '',
          allChecked: false,
          updateLoading: false,
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  bulkPlanChange = async () => {
    const {
      markedSimList,
      changedSimPlan,
      isILDEnabled,
      isIREnabled,
      isIldIrEnabled,
      ildPlanValue,
      irPlanValue,
      ildPlans,
      irPlans,
      ChangeplanildPlanType,
      ChangeplanirPlanType,
    } = this.state;
    // state validation
    const { user } = this.props;
    this.setState({ simLengthError: '' });
    if (_.isEmpty(changedSimPlan)) {
      this.setState({ changedPlanError: true });
    } else if (_.size(markedSimList) > 50) {
      this.setState({
        simLengthError:
          'Please select 50 or less SIMs to perform this operation.',
      });
    } else {
      let missingField = false;
      // checking if plan can be changed
      if (!(this.state.isILDEnabled || this.state.isIREnabled)) {
        // eslint-disable-next-line
        this.state.hasSamePlan = markedSimList.every(
          (sim) => sim.planid === changedSimPlan.offerId
        );
        // all sims have the same plan requested
        if (this.state.hasSamePlan) return;
      }

      if (isILDEnabled) {
        if (!ChangeplanildPlanType) {
          missingField = true;
          this.setState({ ildPlanTypeRequired: true });
        }
        if (!ildPlanValue) {
          missingField = true;
          this.setState({ ildPlanValueRequired: true });
        }
      }
      if (isIREnabled) {
        if (!ChangeplanirPlanType) {
          missingField = true;
          this.setState({ irPlanTypeRequired: true });
        }
        if (!irPlanValue) {
          missingField = true;
          this.setState({ irPlanValueRequired: true });
        }
      }
      let obj = {};
      if (!missingField) {
        this.setState({ changePlanLoading: true });
        obj.sims = markedSimList.map((sim) => sim.id);
        obj.isILDEnabled = isILDEnabled;
        obj.isIREnabled = isIREnabled;
        obj.isIldIrEnabled = isIldIrEnabled;
        obj.ildPlanType = ChangeplanildPlanType;
        obj.ildPlanValue = ildPlanValue;
        obj.irPlanType = ChangeplanirPlanType;
        obj.irPlanValue = irPlanValue;

        if (_.get(user, 'consumeOfferAPI', false)) {
          obj.offerId = changedSimPlan.offerId;
          obj.title = changedSimPlan.title;
          if (isILDEnabled) {
            const filterP = ildPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(ildPlanValue);
            });
            if (filterP.length) {
              obj.ildAddOnplanOfferId = filterP[0].offerId;
            }
          }
          if (isIREnabled) {
            const filterP = irPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(irPlanValue);
            });
            if (filterP.length) {
              obj.irAddOnplanOfferId = filterP[0].offerId;
            }
          }
        } else {
          obj.planId = changedSimPlan.id;
        }
        try {
          const {
            value: { success },
          } = await this.props.bulkPlanChange(obj);
          if (success) {
            setTimeout(() => {
              this.setState({
                isActionModal: false,
                selectedAction: '',
                markedSimList: [],
                keyword: '',
                allChecked: false,
                changePlanLoading: false,
                changePlanModal: false,
                changedSimPlan: {},
              });
              notification['success']({
                message: "Sims' Plan Change",
                description:
                  'Your plan changes are processing. Check the notifications icon for updates.',
              });
              this.getSimsOnTabChange();
            }, 800);
          }
        } catch (err) {
          this.setState({
            isActionModal: false,
            selectedAction: '',
            changePlanLoading: false,
            changePlanModal: false,
            errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
          });
        }
      }
    }
  };

  deactivateSim = async () => {
    const { markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let obj = {};
    obj.action = 'deactivate';
    obj.sims = markedSimList.map((sim) => sim.id);
    try {
      const {
        value: { success },
      } = await this.props.deactivateSims(obj);
      if (success) {
        this.setState({
          isActionModal: false,
          selectedAction: '',
          markedSimList: [],
          allChecked: false,
          updateLoading: false,
          keyword: '',
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        isActionModal: false,
        selectedAction: '',
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  //Activate Sim from Pause and Deactivate tab
  activatePauseDeactivateSim = async () => {
    const { selectedSimPlan, userData, markedSimList } = this.state;
    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ planError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (_.get(userData, 'role', 0) === 'user') {
        if (selectedSimPlan.title !== 'Test Data Plan') {
          let amountToCharge =
            markedSimList.length * selectedSimPlan.monthlyprice;
          if (userData.availablebalance === 0) {
            error = true;
            descriptionText =
              'You have 0 balance in your account. Please add fund to activate sims.';
          } else if (amountToCharge > userData.availablebalance) {
            error = true;
            descriptionText =
              'You have insufficient balance in your account. Please add fund to activate sims.';
          }
        } else if (
          selectedSimPlan.title === 'Test Data Plan' &&
          markedSimList.length > 1
        ) {
          error = true;
          descriptionText =
            'You can activate only 1 SIM in test data plan. Please choose a single SIM instead.';
        }
      }

      if (!error) {
        this.setState({ updateLoading: true });
        let obj = {
          sims: markedSimList.map((sim) => sim.id),
          action: 'activate',
          plan: selectedSimPlan.id,
        };
        try {
          const {
            value: { success },
          } = await this.props.activateSimByPlan(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            this.setState(
              {
                isActivateModal: false,
                updateLoading: false,
                titleText,
                descriptionText,
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  markedSimList: [],
                  allChecked: false,
                });
              }
            );
          }
        } catch (err) {
          descriptionText =
            'Something went wrong with the process. Check later...';
          this.setState({
            updateLoading: false,
            titleText,
            descriptionText,
            openThankYouModal: true,
            isActivateModal: false,
            errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: true,
        });
      }
    }
  };

  checkAllZipcodeEligibility = async (data, simType) => {
    try {
      if (data.length > 1 && simType === 'eSIM') {
        this.setState({
          validationLoading: true,
          openLoadingModal: true,
        });
      }
      const promises = data.map((item) => {
        item.isZipCodeIneligible = false;
        let zipcode =
          item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
            ? item.zipcodeOnChange
            : item.zipcode;
  
        if (item.newZipcode) zipcode = item.newZipcode;
        return this.props.updateSimName({
          zipcode,
          ids: [item.id],
        })
        .then(() => {
          item.isZipCodeIneligible = false;
        })
        .catch((error) => {
          item.isZipCodeIneligible = true;
          throw error;
        });
      });
  
      const results = await Promise.allSettled(promises);
  
      if (results.some(result => result.status === 'rejected')) {
        return false;
      }
  
      return true;
    } catch (error) {
      return false;
    }
  };

  checkIMEICompatibility = async (data) => {
    try {
      this.setState({
        validationLoading: true,
        openLoadingModal: true,
      });
      const imeiCompatibilityDetails = [];
      for (const item of data) {
        const imeinumber = item.imeinumber;
        const simType = item.simType;
        const {
          value: { success, data },
        } = await this.props.getDeviceCompatibility({
          imei: imeinumber,
          simType: simType,
        });
        if (success && !_.isEmpty(data)) {
          item.isImeiEsimCompatible = true;

          if (!data.eSIMCompatible) item.isImeiEsimCompatible = false;

          imeiCompatibilityDetails.push(item);
        } else {
          item.isImeiEsimCompatible = false;
          imeiCompatibilityDetails.push(item);
        }
      }
      this.setState({ simDataWithImeiCompatibility: imeiCompatibilityDetails });
      const hasIncompatibleSims = data.some(
        (item) => item.isImeiEsimCompatible === false
      );
      this.setState({ areIMEIsCompatible: !hasIncompatibleSims });
    } catch (error) {
      return false;
    }
  };

  selectActionHandle = (value) => {
    let selectedAction = value;
    this.setState({ selectedAction }, async () => {
      if (selectedAction === 'pause' || selectedAction === 'inactive') {
        this.setState({ isActionModal: true });
      } else if (
        selectedAction === 'activate' ||
        selectedAction === 'activateEsimbyID'
      ) {
        let error = false;
        let titleText = '';
        let descriptionText = '';
        let areZipcodesEligible = true;
        const { markedSimList } = this.state;

        _.forEach(markedSimList, (item) => {
          let zipcode =
            item.isZipcodeChanged && item.zipcodeOnChange !== item.zipcode
              ? item.zipcodeOnChange
              : item.zipcode;
          if (zipcode === ' ') zipcode = '';

          if (item.isEsim) {
            if (!zipcode && !this.state.newZipcode) {
              error = true;
              item.isZipCodeIneligible = true;
            }
          } else {
            if (!zipcode) {
              error = true;
              item.isZipCodeIneligible = true;
            } if ( zipcode.length !== 5) {
              item.isZipCodeIneligible = true;
              areZipcodesEligible = false
            }
          }
        });

        if (
          (selectedAction === 'activate' ||
            selectedAction === 'activateEsimbyID') &&
          markedSimList.length
        ) {
          if (selectedAction === 'activateEsimbyID' && this.state.newZipcode) {
            let tempMarkList = this.state.markedSimList;
            tempMarkList.forEach(
              (ele) => (ele['newZipcode'] = this.state.newZipcode)
            );
            areZipcodesEligible =
              await this.checkAllZipcodeEligibility(tempMarkList);
            this.setState({ newZipcode: '' });
            tempMarkList.forEach((object) => {
              delete object['newZipcode'];
            });
          } else {
            areZipcodesEligible =
              await this.checkAllZipcodeEligibility(markedSimList);
          }
        }

        if (error) {
          titleText =
            selectedAction === 'activate'
              ? 'Activate Info'
              : 'Activate eSIM Info';
          descriptionText =
            selectedAction === 'activate'
              ? 'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.'
              : 'Zipcode is required to activate an eSIM. Please enter zipcode for selected eSIM.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else if (!areZipcodesEligible) {
          titleText = 'Activate Info';
          descriptionText =
            'Zipcode is not eligible to activate sim. Please enter valid zip for all selected SIMs.';
          this.setState({
            titleText,
            descriptionText,
            selectedSimPlan: {},
            planError: false,
            selectedAction: '',
            openThankYouModal: true,
          });
        } else {
          this.setState({
            isActivateModal: true,
            selectedSimPlan: {},
            planError: false,
          });
        }
      } else if (selectedAction === 'setsimname') {
        this.setState({
          isUpdateSimModal: true,
          newSimName: '',
          requireName: false,
          newSimNameSize: false,
        });
      } else if (selectedAction === 'delete') {
        this.setState({ deleteModal: true });
      } else if (selectedAction === 'portIn') {
        this.setState({
          portSimModal: true,
          portInPhoneNumber: '',
          selectedCarrier: '',
          carrierACNumber: '',
          carrierAssignPassword: '',
          name: '',
          nameError: false,
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          zip4: '',
          zipcodePortIn: '',
          altContactNumber: '',
          selectedSimPlan: {},
          errorMessage: '',
          requirePortInPhoneNumber: false,
          carrierError: false,
          requireCarrierACNumber: false,
          requireCarrierAssignPassword: false,
          requireZipcodePortIn: false,
          requireAltContactNumber: false,
          planError: false,
        });
      } else if (selectedAction === 'changePlan') {
        this.setState({
          changePlanModal: true,
          simLengthError: '',
          isILDEnabled: false,
          isIREnabled: false,
        });
      } else if (selectedAction === 'reactivate') {
        this.setState({
          reactivateSimModal: true,
        });
      } else if (selectedAction === 'unpause') {
        this.setState({
          isUnpauseModal: true,
        });
      }
    });
  };

  //On check Mark Sim Methods
  onCheck = (item, key = 'single') => {
    const { sims, allChecked, simPlansData } = this.state;
    let markedSimList = this.state.markedSimList;
    this.setState({ simDataWithImeiCompatibility: [] });
    item.imeinumber = '';
    if (key === 'single') {
      if (!markedSimList.includes(item)) {
        markedSimList.push(item);
      } else {
        let index = markedSimList.findIndex((list) => list.id === item.id)
        if (index !== -1) {
          markedSimList[index].isZipCodeIneligible = false;
        }
        markedSimList.splice(index, 1);
      }
      if (markedSimList.length === 1) {
        const planid = markedSimList[0].planid;
        const index = _.get(this.state, 'simPlansData', []).findIndex(
          (item) => item.id === planid
        );
        this.setState({
          planValue: index,
          selectedSimPlan: simPlansData[index],
        });
      } else {
        this.setState({ planValue: '', selectedSimPlan: {} });
      }

      this.setState({ markedSimList }, () => {
        if (_.size(sims) === _.size(markedSimList)) {
          this.setState({ allChecked: true });
        } else {
          this.setState({ allChecked: false });
        }
      });
    } else {
      this.setState({ planValue: '', selectedSimPlan: {} });
      let allSimData = [...sims];
      if (!allChecked) {
        this.setState({ markedSimList: allSimData, allChecked: true });
      } else {
        this.setState({ markedSimList: [], allChecked: false });
        markedSimList.forEach((simItem) => {
          simItem.isZipCodeIneligible = false;
        });
      }
    }
  };

  addClientOnSim = (index) => {
    let allSims = this.state.sims;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createClientModal();
    });
  };

  createClientModal() {
    const { markedSimList } = this.state;
    let clientArray = [];
    if (markedSimList.length) {
      for (const i of markedSimList) {
        if (!clientArray.includes(i.clientname) && i.clientname) {
          clientArray.push(i.clientname);
        }
      }
    }
    if (clientArray) {
      this.setState({
        isCreateClientModal: true,
        newClientSize: false,
        selectedClient: clientArray,
      });
    } else {
      this.setState({
        isCreateClientModal: true,
        newClientSize: false,
        selectedClient: [],
      });
    }
  }

  handleClientSelect = (client) => {
    this.setState({ selectedClient: [] }, () => {
      setTimeout(() => {
        if (client.trim().length !== 0) {
          this.setState({ selectedClient: [client] });
        }
      }, 300);
    });
  };

  handleClientChange = (clientsArr) => {
    if (clientsArr.length > 0) {
      let index = clientsArr.length - 1;
      let clientName = clientsArr[index];
      let newClientSize = false;
      if (clientName.trim().length < 3) {
        newClientSize = true;
      }
      if (newClientSize) {
        this.setState({ newClientSize: true });
      } else {
        this.setState({ selectedClient: [clientName], newClientSize: false });
      }
    } else {
      this.setState({ selectedClient: [], newClientSize: false });
    }
  };

  handleSearch(e) {
    let searchSims = e.target.value;
    const isSims = searchSims.includes(',');
    if (isSims) {
      searchSims =
        searchSims && searchSims.trim() !== '' ? searchSims.split(',') : [];
    }
    this.setState({ searchSims, keyword: searchSims, page: 1 }, () => {
      this.getSimsOnTabChange();
    });
  }

  handleSearchTags(e) {
    let searchTags = e.target.value;
    this.setState({ searchTags }, () => {
      this.getAllTags(searchTags);
    });
  }

  saveClient = async () => {
    const { selectedClient, markedSimList, clients } = this.state;
    let obj = {};
    let clientIndex;
    if (selectedClient.length) {
      clientIndex = clients.findIndex(
        (client) => client.fullname === selectedClient[0]
      );
    } else {
      clientIndex = -2;
    }
    if (clientIndex === -2) {
      let checkClient = markedSimList.some((s) => s.clientname);
      if (checkClient) {
        try {
          let obj = {
            ids: markedSimList.map((item) => item.id),
          };
          const {
            value: { success },
          } = await this.props.removeClient(obj);
          if (success) {
            this.setState(
              {
                isCreateClientModal: false,
                selectedClient: [],
                updateLoading: false,
              },
              () => {
                this.getSimsOnTabChange();
              }
            );
          }
        } catch (error) {
          //error occured while removing client
        }
      }
    } else {
      if (clientIndex !== -1) {
        obj = {
          simIds: markedSimList.map((sim) => sim.id),
          clientId: clients[clientIndex].id,
        };
      } else {
        obj = {
          simIds: markedSimList.map((sim) => sim.id),
          clientId: 0,
          name: selectedClient[0],
        };
      }
      this.setState({ updateLoading: true });
      try {
        const {
          value: { success, client },
        } = await this.props.addClient(obj);
        if (success) {
          this.setState(
            {
              isCreateClientModal: false,
              selectedClient: [],
              updateLoading: false,
            },
            () => {
              this.updateClientLocal(client);
              this.getSimsOnTabChange();
            }
          );
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  updateClientLocal = (client) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.clientId = client.clientId;
          record.clientname = client.clientname;
        }
        return mark;
      });
    });
    let allClients = this.state.clients;
    let obj = {
      id: client.clientId,
      fullname: client.clientname,
    };
    if (allClients.findIndex((item) => item.id === client.clientId) === -1) {
      allClients.push(obj);
    }
    this.setState({ sims: allSims, clients: allClients });
  };

  addTagOnSim = (index) => {
    let allSims = this.state.sims;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createTagsModal();
    });
  };

  createTagsModal() {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      let tagsArr = [];
      _.forEach(markedSimList, (item, index) => {
        if (item.tags && item.tags !== '') {
          let arr = item.tags.split(',');
          tagsArr.push(...arr);
        }
      });
      let uniquesTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      let originalTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      this.setState({
        isCreateTagModal: true,
        requireName: false,
        newTagSize: false,
        newTag: '',
        selectedListTags: uniquesTags,
        originalSelectedTags: originalTags,
      });
    } else {
      this.setState({ selectedListTags: [], originalSelectedTags: [] });
    }
  }

  handleTagChange = (tags) => {
    this.setState({ isTagChanged: true });
    if (tags.length > 0) {
      let tagSizeError = false;
      _.forEach(tags, (tag) => {
        if (tag.trim().length < 3) {
          tagSizeError = true;
        }
      });
      if (tagSizeError) {
        this.setState({ newTagSize: true });
      } else {
        this.setState({ newTagSize: false, newTag: tags });
      }
    } else {
      this.setState({ newTag: [] });
    }
  };

  saveTag = async () => {
    const { newTag, markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let applyTags = newTag ? newTag.join(',') : '';
    let obj = {};
    obj.sims = [];
    obj.sims = markedSimList.map((sim) => ({ id: sim.id, tags: applyTags }));
    try {
      const {
        value: { success },
      } = await this.props.updateTag(obj);
      if (success) {
        this.setState(
          {
            isCreateTagModal: false,
            isTagChanged: false,
            newTag: '',
            updateLoading: false,
          },
          async () => {
            this.updateTagsLocal(applyTags);
            const { value } = await this.props.fetchTagsByType({
              type: 'all',
              simType: 'spr',
            });
            if (value && _.get(value, 'data', []).length > 0) {
              this.setState({ filterTags: _.get(value, 'data', []) });
            }
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  updateTagsLocal = (applyTags) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.tags = applyTags;
        }
        return mark;
      });
    });
    this.setState({ sims: allSims });
  };

  handleZipcodeChange = async (index, e) => {
    let allSims = this.state.sims;
    this.state.markedSimList.forEach(function (s) {
      if (parseInt(allSims[index].id) === parseInt(s.id)) {
        s['zipcodeOnChange'] = e.target.value;
        s['isZipcodeChanged'] = true;
      }
    });
    allSims[index].zipcode = e.target.value;
    let selectedSims = this.state.markedSimList;
    selectedSims.forEach(function (s) {
      if (parseInt(allSims[index].id) === parseInt(s.id)) {
        s.zipcode = e.target.value;
      }
    });
    this.setState({ sims: allSims, markedSimList: selectedSims });
  };

  handleSimImeiChange = async (index, e) => {
    let allSims = this.state.sims;
    allSims[index].imeinumber = e.target.value;
    this.setState({ sims: allSims });
  };

  handelSimNameChange = async (index, e) => {
    let allSims = this.state.sims;
    let selectedSims = this.state.markedSimList;
    if (e.target.value) {
      let obj = {};
      obj.simname = e.target.value;
      obj.ids = [allSims[index].id];
      await this.props.updateSimName(obj);
    }
    allSims[index].simname = e.target.value;
    selectedSims.forEach(function (s) {
      if (parseInt(allSims[index].id) === parseInt(s.id)) {
        s.simname = e.target.value;
      }
    });

    this.setState({ sims: allSims, markedSimList: selectedSims });
  };

  //Export Sim Data
  exportData = () => {
    const {
      activeTab,
      page,
      sortBy,
      sortOrder,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
    } = this.state;
    this.setState({ isExportSimsData: true, isExportLoading: true });
    let obj = {
      type: activeTab,
      page,
      sortBy,
      sortOrder,
      limit: 0,
      keyword,
      tags,
      byDate,
      startDate,
      endDate,
      isExport: true,
      simType: 'spr',
    };
    try {
      // Direct call without await because no dependecies of response.
      this.props.exportSimsData(obj);

      // Request sent notifications.
      setTimeout(() => {
        this.setState({ isExportSimsData: false });
        notification['success']({
          message: 'Sims Export',
          description:
            'Your export is being prepared, check the Notifications section in a few minutes',
        });
      }, 800);

      // Keep Export disabled for 3m
      setTimeout(() => {
        this.setState({ isExportLoading: false });
      }, 180000);
    } catch (err) {
      this.setState({
        isExportSimsData: false,
        isExportLoading: false,
        errorMessage: _.get(err, 'message', 'Export failed.'),
      });
    }
  };

  // SIM Detail Page
  simDetails = (iccid) => {
    localStorage.setItem('prevTab', 'spr');
    this.props.history.push(`/detail/${iccid}`);
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  sortByKey = (key) => {
    const { sortOrder, sortBy } = this.state;
    if (sortOrder === 'asc' || sortBy !== key) {
      this.setState({ sortOrder: 'desc', sortBy: key }, () => {
        this.getSimsOnTabChange();
      });
    } else {
      this.setState({ sortOrder: 'asc', sortBy: key }, () => {
        this.getSimsOnTabChange();
      });
    }
  };

  validateForm = () => {
    const {
      portInPhoneNumber,
      selectedCarrier,
      carrierACNumber,
      carrierAssignPassword,
      zipcodePortIn,
      name,
      altContactNumber,
      selectedSimPlan,
    } = this.state;

    let isValid = true;
    if (!portInPhoneNumber) {
      isValid = false;
      this.setState({ requirePortInPhoneNumber: true });
    }
    if (!selectedCarrier) {
      isValid = false;
      this.setState({ carrierError: true });
    }
    if (!carrierACNumber) {
      isValid = false;
      this.setState({ requireCarrierACNumber: true });
    }
    if (!carrierAssignPassword) {
      isValid = false;
      this.setState({ requireCarrierAssignPassword: true });
    }
    if (name.length > 25) {
      isValid = false;
      this.setState({ nameError: true });
    }
    if (!zipcodePortIn) {
      isValid = false;
      this.setState({ requireZipcodePortIn: true });
    }
    if (!altContactNumber) {
      isValid = false;
      this.setState({ requireAltContactNumber: true });
    }
    if (_.isEmpty(selectedSimPlan)) {
      isValid = false;
      this.setState({ planError: true });
    }

    if (!isValid) this.setState({ updateLoading: false });
    return isValid;
  };

  submitPortIn = async () => {
    const {
      markedSimList,
      portInPhoneNumber,
      selectedCarrier,
      carrierACNumber,
      carrierAssignPassword,
      zipcodePortIn,
      name,
      street1,
      street2,
      city,
      state,
      zip,
      zip4,
      altContactNumber,
      selectedSimPlan,
      userData,
    } = this.state;
    this.setState({ errorMessage: '' });
    const formIsValid = this.validateForm();
    if (formIsValid) {
      this.setState({ updateLoading: true });
      let obj = {
        msisdn: portInPhoneNumber.replace(/([^0-9]+)/gi, ''),
        carrier: selectedCarrier,
        accountNumber: carrierACNumber,
        accountPassword: carrierAssignPassword,
        zipcode: zipcodePortIn,
        name,
        street1,
        street2,
        city,
        state,
        zip,
        zip4,
        simcardId: markedSimList[0].id,
        alternateContact: altContactNumber.replace(/([^0-9]+)/gi, ''),
        simName: markedSimList[0].simname,
        simType: 'spr',
        isEsim: markedSimList[0].isEsim,
      };

      if (_.get(userData, 'consumeOfferAPI', false)) {
        obj.planId = selectedSimPlan.offerId;
        obj.title = selectedSimPlan.title;
      } else {
        obj.planId = selectedSimPlan.id;
      }

      try {
        const {
          value: { success, message, errorKey, portInFailError },
        } = await this.props.simPortInActivation(obj);
        if (success) {
          this.setState(
            {
              portSimModal: false,
              updateLoading: false,
              zipcodeErrorMessage: '',
              errorMessage: '',
            },
            () => {
              this.getSimsOnTabChange();
            }
          );
        } else {
          if (errorKey && errorKey === 'zipcode') {
            this.setState({
              updateLoading: false,
              errorMessage: '',
              zipcodeErrorMessage: message
                ? message
                : 'Some thing went wrong...',
            });
          } else if (markedSimList[0].isEsim && portInFailError) {
            this.setState({
              updateLoading: false,
              zipcodeErrorMessage: '',
              errorMessage: message ? message : 'Some thing went wrong...',
              portInFailsPopup: true,
              portSimModal: false,
            });
          } else {
            this.setState({
              updateLoading: false,
              zipcodeErrorMessage: '',
              errorMessage: message ? message : 'Some thing went wrong...',
            });
          }
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          updateLoading: false,
          zipcodeErrorMessage: '',
          errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
        });
      }
    }
  };

  cancelSimPortIn = async (item) => {
    try {
      let obj = {
        simId: item.id,
      };
      const {
        value: { success },
      } = await this.props.simPortInCancel(obj);
      if (success) {
        this.setState({ updateLoading: false }, () => {
          this.getSimsOnTabChange();
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      let titleText = 'Cancel PortIn Request failed';
      let descriptionText = `Cancel request for the on-going port-in request failed due to "${err.message}".`;
      this.setState({
        updateLoading: false,
        openCancelPortInFailureModal: true,
        titleText: titleText,
        descriptionText: descriptionText,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  // function too restrict user from entering special characters in acc.No & pass(for portIn-request)
  restrictSpecialCharacters = (e) => {
    const nospecial = /^[A-Za-z0-9]*$/;
    const alphanumeric = /^[A-Za-z0-9]*$/;
    const textValue = e.target.value;
    const tagName = e.target.name;

    if (textValue.match(nospecial)) {
      if (tagName === 'carrierACNumber') {
        if (textValue.match(alphanumeric)) {
          this.setState({
            carrierACNumber: textValue,
            requireCarrierACNumber: false,
          });
        }
      } else {
        this.setState({
          carrierAssignPassword: textValue,
          requireCarrierAssignPassword: false,
        });
      }
    }
  };

  //Call Reactivate Sim API From Deactivate Tab
  reactivateSim = async () => {
    const { selectedSimPlan, userData, markedSimList, sims } = this.state;

    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ reactivatePlanError: true });
    } else {
      let titleText = 'Activate Info';
      let descriptionText = '';
      let error = false;
      if (_.get(userData, 'role', 0) === 'user') {
        if (selectedSimPlan.title !== 'Test Data Plan') {
          let amountToCharge =
            markedSimList.length * selectedSimPlan.monthlyprice;
          if (userData.availablebalance === 0) {
            error = true;
            descriptionText =
              'You have 0 balance in your account. Please add fund to reactivate sims.';
          } else if (amountToCharge > userData.availablebalance) {
            error = true;
            descriptionText =
              'You have insufficient balance in your account. Please add fund to reactivate sims.';
          }
        } else if (
          selectedSimPlan.title === 'Test Data Plan' &&
          markedSimList.length > 1
        ) {
          error = true;
          descriptionText =
            'You can reactivate only 1 SIM in test data plan. Please choose a single SIM instead.';
        }
      }

      if (!error) {
        this.setState({ reactivateLoading: true });
        let obj = {};

        if (_.get(userData, 'consumeOfferAPI', false)) {
          obj.offerId = selectedSimPlan.offerId;
          obj.title = selectedSimPlan.title;
        } else {
          obj.planid = selectedSimPlan.id;
        }
        obj.sims = markedSimList.map((sim) => sim.id);
        try {
          const {
            value: { success },
          } = await this.props.reActivateSim(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            _.forEach(markedSimList, (item) => {
              let index = sims.findIndex((sim) => item.id === sim.id);
              if (index > -1) {
                let obj = sims[index];
                obj.status = 12;
              }
            });
            this.setState(
              {
                sims,
                reactivateSimModal: false,
                reactivateLoading: false,
                titleText,
                descriptionText,
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  markedSimList: [],
                  allChecked: false,
                  keyword: '',
                });
              }
            );
          }
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            reactivateLoading: false,
            openThankYouModal: true,
            reactivateSimModal: false,
            errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          });
        }
      } else {
        this.setState({
          titleText,
          descriptionText,
          openThankYouModal: true,
        });
      }
    }
  };

  handelReactivatPlanChange(e) {
    const { simPlansData } = this.state;
    if (e.target.value) {
      this.setState({
        selectedSimPlan: simPlansData[e.target.value],
        planValue: e.target.value,
        reactivatePlanError: false,
        isIldIrEnabled: false,
        isILDEnabled: false,
        isIREnabled: false,
      });
    } else {
      this.setState({ selectedSimPlan: {}, planValue: e.target.value });
    }
  }

  unPauseSim = async () => {
    const {
      markedSimList,
      sims,
      selectedSimPlan,
      isILDEnabled,
      isIREnabled,
      isIldIrEnabled,
      ildPlanType,
      ildPlanValue,
      irPlanType,
      irPlanValue,
      ildPlans,
      irPlans,
    } = this.state;
    const { user } = this.props;
    if (_.isEmpty(selectedSimPlan)) {
      this.setState({ unPauseSimPlanError: true });
    } else {
      let required = false;
      if (isILDEnabled) {
        if (ildPlanType === '') {
          required = true;
          this.setState({ ildPlanTypeRequired: true });
        }
        if (ildPlanValue === 0) {
          required = true;
          this.setState({ ildPlanValueRequired: true });
        }
      }
      if (isIREnabled) {
        if (irPlanType === '') {
          required = true;
          this.setState({ irPlanTypeRequired: true });
        }
        if (irPlanValue === 0) {
          required = true;
          this.setState({ irPlanValueRequired: true });
        }
      }
      let titleText = 'Unpause Info';
      let descriptionText = '';
      let obj = {};

      if (!required) {
        this.setState({ updateLoading: true, unPauseSimPlanError: false });
        obj.sims = markedSimList.map((sim) => sim.id);
        obj.isILDEnabled = isILDEnabled;
        obj.isIREnabled = isIREnabled;
        obj.isIldIrEnabled = isIldIrEnabled;
        obj.ildPlanType = ildPlanType;
        obj.ildPlanValue = ildPlanValue;
        obj.irPlanType = irPlanType;
        obj.irPlanValue = irPlanValue;

        if (_.get(user, 'consumeOfferAPI', false)) {
          obj.offerId = selectedSimPlan.offerId;
          obj.title = selectedSimPlan.title;
          if (isILDEnabled) {
            const filterP = ildPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(ildPlanValue);
            });
            if (filterP && filterP.length > 0) {
              obj.ildAddOnplanOfferId = filterP[0].offerId;
            }
          }
          if (isIREnabled) {
            const filterP = irPlans.filter((el) => {
              return parseInt(el.titleValue) === parseInt(irPlanValue);
            });
            if (filterP && filterP.length > 0) {
              obj.irAddOnplanOfferId = filterP[0].offerId;
            }
          }
        } else {
          obj.planid = selectedSimPlan.id;
        }
        try {
          const {
            value: { success, message },
          } = await this.props.unPauseSim(obj);
          if (success) {
            titleText = 'Thank You';
            descriptionText =
              'Your SIM card(s) are processing. Check the notifications icon for updates.';
            _.forEach(markedSimList, (item) => {
              let index = sims.findIndex((sim) => item.id === sim.id);
              if (index > -1) {
                let obj = sims[index];
                obj.status = 12;
              }
            });
            this.setState(
              {
                sims,
                isUnpauseModal: false,
                errorMessage: '',
                updateLoading: false,
                titleText,
                descriptionText,
                planValue: '',
              },
              () => {
                this.setState({
                  openThankYouModal: true,
                  isIldIrEnabled: false,
                  isILDEnabled: false,
                  markedSimList: [],
                  allChecked: false,
                  selectedAction: '',
                  isIREnabled: false,
                  ildPlanType: '',
                  ildPlanValue: 0,
                  irPlanType: '',
                  irPlanValue: 0,
                  keyword: '',
                });
              }
            );
          } else {
            this.setState({
              updateLoading: false,
              errorMessage: message ? message : 'Some thing went wrong...',
            });
          }
        } catch (err) {
          const error = _.get(err, 'data', err);
          this.setState({
            selectedAction: '',
            updateLoading: false,
            errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
          });
        }
      }
    }
  };

  onChangeSwitch(key) {
    if (key === 'isIldIrEnabled') {
      this.setState({ isIldIrEnabled: !this.state[key] }, () => {});
    }
    if (key === 'isILDEnabled') {
      this.setState({
        ildPlanValue: 0,
        [key]: !this.state[key],
        isIldIrEnabled: false,
        ildPlanValueRequired: false,
      });
    }
    if (key === 'isIREnabled') {
      this.setState({
        irPlanValue: 0,
        [key]: !this.state[key],
        isIldIrEnabled: false,
        irPlanValueRequired: false,
        remainingIRBalanceError: false,
      });
    } else {
      this.setState({
        [key]: !this.state[key],
        isIldIrEnabled: false,
      });
    }
  }

  sortByProperty = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    };
  };

  fetchAddOnPlans = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchAddOnPlans();
      if (success) {
        const irPlans = _.get(data, 'plan', [])
          .filter((plan) => plan.type === 'ir')
          .sort((a, b) => a.titleValue - b.titleValue);
        let ildPlans = _.get(data, 'plan', []).filter(
          (plan) => plan.type === 'ild'
        );
        ildPlans = ildPlans.sort(this.sortByProperty('titleValue'));
        const remainingIRBalance = _.get(data, 'remainingIRBalance', 0);
        this.setState({
          irPlans: irPlans,
          ildPlans: ildPlans,
          remainingIRBalance,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  openMoveBackModal = () => {
    this.setState({ isMoveBackModal: true });
  };

  searchByChange = (e) => {
    this.setState({ searchBy: e.target.value }, () => {
      if (this.state.keyword) {
        this.getSimsOnTabChange();
      }
    });
  };

  selectActionHandleForAvailableEsims = (value) => {
    let selectedAction = value;
    this.setState({ selectedAction }, async () => {
      let error = false;
      let titleText = '';
      let descriptionText = '';
      let areZipcodesEligible = true;
      const { markedSimList } = this.state;
      let allSims = this.state.sims;

      if (markedSimList.length > 1) {
        markedSimList.forEach((item) => {
          if (!item.zipcode) {
            error = true;
            item.isZipCodeIneligible = true
          }
        });
        areZipcodesEligible = await this.checkAllZipcodeEligibility(
          markedSimList,
          'eSIM'
        );
        if (selectedAction === 'activateEsimbyID' && areZipcodesEligible) {
          await this.checkIMEICompatibility(markedSimList);
        }
      }
      this.setState({ validationLoading: false, openLoadingModal: false });
      if (error) {
        titleText = 'Activate Info';
        descriptionText =
          'Zipcode is required to activate sim. Please enter zipcode for all selected SIMs.';
        this.setState({ openThankYouModal: true, titleText, descriptionText });
      } else if (!areZipcodesEligible) {
        titleText = 'Activate Info';
        descriptionText =
          'Zipcode is not eligible to activate sim. Please enter valid zip for all selected SIMs.';
        this.setState({
          titleText,
          descriptionText,
          selectedSimPlan: {},
          planError: false,
          selectedAction: '',
          openThankYouModal: true,
        });
      } else if (!this.state.areIMEIsCompatible) {
        titleText = 'Activate Info';
        descriptionText =
          'One or more device IMEIs have not been provided or are not compatible with eSIMs. Are you sure you want to proceed?';
        this.setState({
          titleText,
          descriptionText,
          selectedSimPlan: {},
          planError: false,
          selectedAction: '',
          openImeiIncompatibleModal: true,
          areIMEIsCompatible: true,
        });
      } else {
        markedSimList.length > 1
          ? this.setState({ isActivateModal: true })
          : this.setState({ activateEsimModal: true });
      }
      this.setState({ sims: allSims });
    });
  };

  openEsimActivateModal = () => {
    if (this.state.selectedAction === 'portInEsim') {
      this.setState({ openImeiValidateModal: false });
      this.selectActionHandle('portIn');
    } else if (this.state.selectedAction === 'activateEsim') {
      this.setState({ openImeiValidateModal: false, isActivateModal: true });
    }
  };

  fetchPlans = async () => {
    const {
      value: { success, plans },
    } = await this.props.fetchPlans();
    if (success) {
      let filteredPlans = [];
      filteredPlans = plans.filter((plan) => plan.category !== 'Fixed');
      this.setState({ plansArray: filteredPlans });
    }
  };
  handleImeiChange = (e) => {
    this.setState({
      isImeiSection: false,
    });
    this.setState({
      isCheckByBrandModal: false,
      eSIMCompatible: false,
      deviceName: '',
    });
    this.setState({
      swapESimError: '',
    });
    this.setState({ deviceApiError: '' });
    const nospecial = /^[0-9]*$/;
    const number = e.target.value;
    if (number.length > 17) {
      this.setState({ deviceApiError: 'IMEI number must be of 15 digits.' });
    } else if (number.match(nospecial)) {
      this.setState({ imeiNumber: e.target.value });
      this.setState({ deviceApiError: '' });
    } else {
      this.setState({ deviceApiError: 'Please enter valid IMEI number.' });
    }
  };
  checkImeiNumber = async () => {
    try {
      this.setState({ Inprogress: true });
      this.setState({ isCheckByBrandModal: false });
      this.setState({ isImeiSection: false });
      this.setState({ isCheckCoverage: true });
      this.setState({ isCheckDeviceCompatibility: true });
      this.setState({ isSelectPlan: false });
      this.setState({ isInstalleSIM: false });
      this.fetchPlans();
      const obj = {};
      obj.imei = this.state.imeiNumber;
      this.setState({ imeiNumber: this.state.imeiNumber });
      this.setState({ imeiLoding: true });
      const simType =
        parseInt(_.get(this.props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
      obj.simType = simType;

      const {
        value: { success, data, statusCode },
      } = await this.props.getDeviceCompatibility(obj);
      this.setState({ imeiLoding: false });
      this.setState({ brandLoading: false });
      this.setState({ eSIMCompatible: false });
      this.setState({ proceed: false });
      this.setState({ deviceName: '' });
      if (success) {
        // this.setState({isImeiSection : true, isCheckByBrandModal : false })
        this.setState({ isImeiSection: true, deviceApiError: '' });
        this.setState({ deviceName: data.name });
        if (data.eSIMCompatible) {
          this.setState({ Inprogress: false });
          this.setState({ eSIMCompatible: true });
        } else {
          this.setState({ activateeSim: false });
        }
      } else if (statusCode === 400 || statusCode === 500) {
        this.setState({ deviceApiError: 'Please enter valid IMEI number.' });
      } else if (statusCode === 404) {
        this.setState({ deviceApiError: 'Invalid IMEI number.' });
      } else {
        this.setState({ deviceApiError: 'Error while processing request.' });
      }
    } catch (error) {
      this.setState({ deviceApiError: 'Error while processing request.' });
    }
  };
  handleErrorBox = (e) => {
    this.setState({ eSIMCompatible: true, Inprogress: false });
    this.setState({ proceed: true });
  };

  handleChangeBrand = (value) => {
    if (value === '') {
      this.setState({
        brand: '',
      });
    }
    this.setState({
      isImeiSection: false,
      eSIMCompatible: false,
      deviceName: '',
      swapESimError: '',
    });
    const brandName = value;
    const filterModel = _.filter(
      this.state.brandsArray,
      (v) => _.get(v, 'name', '') === brandName
    );
    if (filterModel && filterModel.length > 0) {
      this.setState({ modelArray: _.get(filterModel[0], 'models', []) });
    }
    this.setState({ brand: brandName, model: '' });
  };

  handleChangeModel = (e) => {
    if (e.target.value === '') {
      this.setState({
        model: '',
      });
    }
    this.setState({
      eSIMCompatible: false,
      deviceName: '',
      swapESimError: '',
    });
    const modelName = e.target.value;
    this.setState({ model: modelName });
  };
  submitDeviceDetail = async () => {
    try {
      this.setState({ Inprogress: true });
      this.setState({ isImeiSection: false });
      this.setState({ isCheckByBrandModal: false });
      this.setState({ isCheckCoverage: true });
      this.setState({ isCheckDeviceCompatibility: true });
      this.setState({ isSelectPlan: false });
      this.setState({ isInstalleSIM: false });

      this.fetchPlans();
      const obj = {};
      this.setState({ brandLoading: true });
      const filterFinalData = _.filter(
        this.state.modelArray,
        (m) => _.get(m, 'name', '') === this.state.model
      );
      if (filterFinalData && filterFinalData.length > 0) {
        const finalObj = filterFinalData[0];
        let tacNumber = _.get(finalObj, 'tac', '');
        tacNumber = `${_.get(finalObj, 'tac', '')}0000000`;
        obj.imei = tacNumber;
      }

      const simType =
        parseInt(_.get(this.props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
      obj.simType = simType;

      const {
        value: { success, data },
      } = await this.props.getDeviceCompatibility(obj);
      this.setState({ imeiLoding: false });
      this.setState({ brandLoading: false });
      this.setState({ eSIMCompatible: false });
      this.setState({ deviceName: '' });
      this.setState({ proceed: false });
      if (success) {
        // this.setState({isCheckByBrandModal : true, isImeiSection : false })
        this.setState({ isCheckByBrandModal: true, deviceApiError: '' });
        this.setState({ deviceName: data.name });
        if (data.eSIMCompatible) {
          this.setState({ Inprogress: false });
          this.setState({ eSIMCompatible: true });
        } else {
          this.setState({ activateeSim: false });
        }
      }
    } catch (error) {
      this.setState({ deviceApiError: 'Error while processing request.' });
    }
  };

  generatePDF = async (e, iccid) => {
    const { simDetailData } = this.state;

    try {
      await this.props.generatePDF({
        ICCID: iccid ? iccid : simDetailData.simnumber,
      });
    } catch (e) {
      //error occurred here
      console.log('Error occured while regenerating pdf : ', e);
    }
  };


  checkCoverage = async () => {
    try {
      this.setState({ isCheckByBrandModal: false });
      this.setState({ isImeiSection: false });
      this.setState({ isCheckCoverage: true });
      this.setState({ isCheckDeviceCompatibility: true });
      this.setState({ isSelectPlan: false });
      this.setState({ isInstalleSIM: false });
      this.setState({ activationErrorMsg: '' });

      this.setState({ zipcode: this.state.zipcode });
      this.setState({ finalZip: this.state.zipcode });
      if (this.state.zipcode !== '') {
        this.setState({ zipcodeLoding: true });
        this.setState({ zipCodeApiError: '' });
        const {
          value: { success, statusCode, data },
        } = await this.props.coverageDetail({ zip: this.state.zipcode, simType: 'spr' });
        this.setState({ zipcodeLoding: false });
        this.setState({ proceed: false });
        if (success) {
          this.setState({ zipCodeSuccess: true });
          this.setState({ zipCodeApiError: '' });
          const zipMessage = await getZipCodeSuccessMessage(data);
          this.setState({
            zipCodeSuccessMessage: zipMessage,
          });
        } else {
          if (statusCode === 404) {
            this.setState({ finalZip: '' });
            this.setState({ zipCodeApiError: 'Invalid zipcode' });
            this.setState({ zipCodeSuccess: false });
          } else if (statusCode === 400) {
            this.setState({ zipCodeApiError: 'Please enter valid zipcode' });
            this.setState({ finalZip: '' });
            this.setState({ zipCodeSuccess: false });
          } else {
            this.setState({ finalZip: '' });
            this.setState({
              zipCodeApiError: 'Error while processing request',
            });
            this.setState({ zipCodeSuccess: false });
          }
        }
      } else {
        this.setState({ zipCodeApiError: 'Please enter zipcode' });
        this.setState({ finalZip: '' });
        this.setState({ zipCodeSuccess: false });
      }
    } catch (error) {
      this.setState({ zipcodeLoding: false });
      this.setState({ zipCodeSuccess: false });
      this.setState({ finalZip: '' });
      if (error.statusCode === 404) {
        this.setState({ zipCodeApiError: 'Invalid zipcode' });
      } else if (error.statusCode === 400) {
        this.setState({ zipCodeApiError: 'Please enter valid zipcode' });
      } else {
        this.setState({ zipCodeApiError: 'Error while processing request' });
      }
    }
  };

  handleZipChange = (e) => {
    const nospecial = /^[0-9]*$/;
    const zip = e.target.value;
    this.setState({ zipCodeSuccess: false });
    if (zip.match(nospecial)) {
      this.setState({ zipcode: e.target.value });
      this.setState({
        newZipcode: e.target.value,
        activateEsimZip: e.target.value,
      });
      this.setState({ zipCodeApiError: '' });
    } else {
      this.setState({ zipCodeApiError: 'Please enter valid zipcode' });
    }
  };

  render() {
    const {
      sims,
      total,
      page,
      startDate,
      endDate,
      activeTab,
      limit,
      isFiltervisible,
      isDateFilter,
      isTagsFilter,
      isClientFilter,
      isSimsFilter,
      simFilter,
      filterTags,
      searchFilterTags,
      searchClientFilter,
      iAuthorization,
      simPlansData,
      selectedSimPlan,
      markedSimList,
      selectedListTags,
      userData,
      sortBy,
      sortOrder,
      clients,
      selectedClient,
      isPlanFilter,
      planFilter,
      planValue,
      ildPlans,
      irPlans,
      searchBy,
      pSimFilter,
      eSimFilter,
      imeiNumber,
      deviceApiError,
      brand,
      brandsArray,
      zipCodeApiError,
    } = this.state;
    let filterCount =
      _.size(searchFilterTags) +
      _.size(searchClientFilter) +
      _.size(planFilter) +
      (this.state.byDate ? 1 : 0) +
      _.size(simFilter);
    let moveBackButtonDisabl = true;
    if (markedSimList.length > 0) {
      const moveBackFilter = markedSimList.filter(
        (item) =>
          !item.failedReason ||
          (item.failedReason !== 'INVALID_ZIP' &&
            item.failedReason !== 'API_SERVER_ERROR')
      );
      moveBackButtonDisabl = moveBackFilter.length > 0 ? true : false;
    }

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          setSortByKeys={this.setSortByKeys}
          onTabChange={this.onTabChange}
        />
        <TopNavigation {...this.props} onTabChange={this.onTabChange} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2 className="heading-title">
                <SimsColorIcon />
                {userData &&
                (userData.role === 'user' ||
                  userData.role === 'corporate_client')
                  ? 'Your SIMs'
                  : 'Your SPR SIMs'}
              </h2>
            </div>
            <div className="col-lg-8 text-sm-right">
              <button
                onClick={this.getSimsOnTabChange}
                className="btn btn-dark w-150 btn-refresh mr-3"
              >
                <RefreshIcon className="mr-2" /> Refresh
              </button>
              <Popover
                content={
                  <div className="filter">
                    <div className="filter__header">
                      <button
                        disabled={filterCount > 0 ? false : true}
                        onClick={this.onClearFilter}
                        className="btn btn-secondary btn-sm"
                      >
                        Clear
                      </button>
                      <div className="filter__title">Filters</div>
                      <button
                        onClick={() =>
                          this.setState({ isFiltervisible: false })
                        }
                        className="btn btn-dark btn-sm"
                      >
                        Close
                      </button>
                    </div>
                    <div className="filter__body">
                      <ul className="filter__list">
                        {/* Sim Filter */}
                        {_.size(sims) > 0 && (
                          <li>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                className="custom-checkbox--input unCheckAll"
                                type="checkbox"
                                name="isSimsFilter"
                                value={isSimsFilter}
                                checked={isSimsFilter}
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      isSimsFilter: !isSimsFilter,
                                      eSimFilter: false,
                                      pSimFilter: false,
                                      simFilter: [],
                                    },
                                    () => {
                                      if (!this.state.isSimsFilter) {
                                        this.getSimsOnTabChange();
                                      }
                                    }
                                  )
                                }
                              />
                              SIM Type
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        )}
                        {isSimsFilter && (
                          <ul className="filter__list__inner">
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  className="custom-checkbox--input unCheckAll"
                                  type="checkbox"
                                  name="pSIMs"
                                  checked={pSimFilter}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        pSimFilter: e.target.checked,
                                        fieldName: e.target.name,
                                      },
                                      () => {
                                        this.onSimFilter();
                                      }
                                    )
                                  }
                                />
                                Physical SIMs
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  className="custom-checkbox--input unCheckAll"
                                  type="checkbox"
                                  name="eSIMs"
                                  checked={eSimFilter}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        eSimFilter: e.target.checked,
                                        fieldName: e.target.name,
                                      },
                                      () => {
                                        this.onSimFilter();
                                      }
                                    )
                                  }
                                />
                                eSIMs
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        )}
                        {/* Client Filter */}
                        {_.size(clients) > 0 && (
                          <li>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                className="custom-checkbox--input unCheckAll"
                                type="checkbox"
                                name="isPlanFilter"
                                value={isClientFilter}
                                checked={isClientFilter}
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      isClientFilter: !isClientFilter,
                                      searchClientFilter: [],
                                    },
                                    () => {
                                      if (!this.state.isClientFilter) {
                                        this.getSimsOnTabChange();
                                      }
                                    }
                                  )
                                }
                              />
                              Clients
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        )}
                        {isClientFilter && (
                          <ul className="filter__list__inner">
                            {_.size(clients) > 0 &&
                              clients.map((client, index) => {
                                return (
                                  <li key={index}>
                                    <label className="custom-checkbox custom-checkbox-line">
                                      <input
                                        className="custom-checkbox--input unCheckAll"
                                        type="checkbox"
                                        name="client"
                                        checked={
                                          searchClientFilter.findIndex(
                                            (cl) => cl.id === client.id
                                          ) !== -1
                                        }
                                        onChange={this.onClientFilter.bind(
                                          this,
                                          client
                                        )}
                                      />
                                      {client.fullname}
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        )}

                        {/* Tags */}
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isTagsFilter"
                              value={isTagsFilter}
                              checked={isTagsFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isTagsFilter: !isTagsFilter,
                                    tags: '',
                                    searchFilterTags: [],
                                  },
                                  () => {
                                    if (!this.state.isTagsFilter) {
                                      this.getSimsOnTabChange();
                                    }
                                  }
                                )
                              }
                            />
                            Tags
                            <span className="checkmark"></span>
                          </label>
                        </li>

                        {isTagsFilter && (
                          <>
                            <ul className="filter__list__inner">
                              <li>
                                <div className="">
                                  <DebounceInput
                                    minLength={2}
                                    debounceTimeout={500}
                                    value={this.state.searchTags}
                                    className="form-control"
                                    placeholder="Search Tags"
                                    name="searchTags"
                                    onChange={this.handleSearchTags.bind(this)}
                                    required
                                  />
                                </div>
                              </li>

                              {_.size(filterTags) > 0 &&
                                filterTags.map((tag, index) => {
                                  return (
                                    <li key={index}>
                                      <label className="custom-checkbox custom-checkbox-line">
                                        <input
                                          className="custom-checkbox--input unCheckAll"
                                          type="checkbox"
                                          name="tag"
                                          checked={
                                            this.state.searchFilterTags.indexOf(
                                              tag
                                            ) !== -1
                                          }
                                          onChange={this.onTagsFilter.bind(
                                            this,
                                            tag
                                          )}
                                        />
                                        {tag}
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          </>
                        )}
                        {/* Plans Filter */}
                        {_.size(simPlansData) > 0 && (
                          <li>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                className="custom-checkbox--input unCheckAll"
                                type="checkbox"
                                name="isClientFilter"
                                value={isPlanFilter}
                                checked={isPlanFilter}
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      isPlanFilter: !isPlanFilter,
                                      planFilter: [],
                                    },
                                    () => {
                                      if (!this.state.isPlanFilter) {
                                        this.getSimsOnTabChange();
                                      }
                                    }
                                  )
                                }
                              />
                              Plans
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        )}
                        {isPlanFilter && (
                          <ul className="filter__list__inner">
                            {_.size(simPlansData) > 0 &&
                              simPlansData.map((plan, index) => {
                                if (_.get(userData, 'consumeOfferAPI', false)) {
                                  plan.id = plan.offerId;
                                }
                                return (
                                  <li key={index}>
                                    <label className="custom-checkbox custom-checkbox-line">
                                      <input
                                        className="custom-checkbox--input unCheckAll"
                                        type="checkbox"
                                        name="client"
                                        checked={
                                          planFilter.findIndex(
                                            (cl) => cl.id === plan.id
                                          ) !== -1
                                        }
                                        onChange={this.onPlanFilterChange.bind(
                                          this,
                                          plan
                                        )}
                                      />
                                      {plan.title}
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input unCheckAll"
                              type="checkbox"
                              name="isDateFilter"
                              checked={isDateFilter}
                              value={isDateFilter}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    isDateFilter: !isDateFilter,
                                    startDate: '',
                                    endDate: '',
                                  },
                                  () => {
                                    if (!this.state.isDateFilter) {
                                      this.setState({ byDate: false }, () => {
                                        this.getSimsOnTabChange();
                                      });
                                    }
                                  }
                                )
                              }
                            />
                            Created Date
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {isDateFilter && (
                          <ul className="filter__list__inner">
                            <div className="row mt-3 mb-3">
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'start'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    showToday={false}
                                    allowClear={false}
                                    suffixIcon={<CalenderIcon />}
                                  />
                                  <label className="datepicker-textfield-label">
                                    From
                                  </label>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="material-textfield">
                                  <DatePicker
                                    placeholder=""
                                    format={dateFormat}
                                    disabledDate={this.disabledDate}
                                    onChange={this.onChangeDate.bind(
                                      this,
                                      'end'
                                    )}
                                    className="custom-datepicker"
                                    dropdownClassName="custom-datepicker-overlay"
                                    size="large"
                                    showToday={false}
                                    allowClear={false}
                                    suffixIcon={<CalenderIcon />}
                                    disabled={
                                      this.state.startDate !== '' ? false : true
                                    }
                                    value={
                                      this.state.endDate !== ''
                                        ? this.state.endDate
                                        : null
                                    }
                                  />
                                  <label className="datepicker-textfield-label">
                                    To
                                  </label>
                                </div>
                              </div>
                            </div>
                          </ul>
                        )}
                      </ul>
                    </div>
                  </div>
                }
                placement="bottomRight"
                className="filter"
                overlayClassName="filter__overlay"
                trigger="click"
                visible={isFiltervisible}
                onVisibleChange={this.filterVisible}
              >
                <button className="btn btn-dark w-150 btn-filter">
                  <FilterIcon className="filter-icon" />
                  Filter {filterCount > 0 && '(' + filterCount + ')'}
                </button>
              </Popover>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-8">
              {activeTab === 'active' &&
              ((iAuthorization && iAuthorization !== '') ||
                (userData &&
                  (userData.role === 'superadmin' ||
                    userData.role === 'admin' ||
                    userData.role === 'sysops' ||
                    userData.role === 'spr_partner' ||
                    isSubUser(userData)))) ? (
                <Link
                  className="btn btn-purple-link"
                  to={'/spr-sims-usage-summary'}
                >
                  SIMs Usage Summary
                </Link>
              ) : (
                ''
              )}
              {iAuthorization &&
              iAuthorization !== '' &&
              userData &&
              userData.role !== 'sub_user' ? (
                <Link
                  to={'/add-sim/upload-csv'}
                  className="btn btn-purple-link"
                >
                  Add Non Active SIMs
                </Link>
              ) : (
                ''
              )}
              {userData &&
              (userData.role === 'spr_partner' || isSubUser(userData)) &&
              (parseInt(userData.isILDEnabled) === 1 ||
                parseInt(userData.isIREnabled) === 1) ? (
                <Link
                  to={'/active-spr-sims/bulk-ild'}
                  className="btn btn-purple-link"
                >
                  Bulk Manage ILD/IR{' '}
                </Link>
              ) : (
                ''
              )}
              {userData &&
              (userData.role === 'superadmin' ||
                userData.role === 'admin' ||
                userData.role === 'sysops' ||
                userData.role === 'spr_partner' ||
                (isSubUser(userData) &&
                  checkPermission(userData, '/bulk-activation'))) ? (
                <Link
                  to={'/bulk-activation-sims'}
                  className="btn btn-purple-link"
                >
                  Bulk Activate SIMs
                </Link>
              ) : (
                ''
              )}
              {activeTab === 'active' &&
              ((iAuthorization && iAuthorization !== '') ||
                (userData &&
                  (userData.role === 'superadmin' ||
                    userData.role === 'admin' ||
                    userData.role === 'sysops' ||
                    userData.role === 'spr_partner' ||
                    isSubUser(userData)))) ? (
                <Link className="btn btn-purple-link" to={'/bulk-add-ons'}>
                  Bulk Add-ons
                </Link>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-4 text-right">
              <button
                disabled={_.size(sims) === 0 || this.state.isExportLoading}
                onClick={this.exportData.bind(this)}
                className="btn btn-link btn-export"
              >
                <ExportIcon className="export-icon" />
                {this.state.isExportSimsData ? 'Please wait...' : 'Export'}
              </button>
            </div>
          </div>
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <div className="d-flex align-items-center mt-3">
                <div className="search-by-select">
                  <span className="search-by-select-label">Search by:</span>
                  <select
                    className="form-control custom-select"
                    name="searchBy"
                    value={this.state.searchBy}
                    onChange={this.searchByChange.bind(this)}
                  >
                    <option value={'simname'}>SIM Name</option>
                    <option value={'iccid'}>ICCID</option>
                    <option value={'userId'}>User Id</option>
                    <option value={'msisdn'}>Phone Number</option>
                  </select>
                </div>
                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  value={this.state.keyword}
                  className="form-control"
                  placeholder={`Search by ${
                    searchBy === 'simname'
                      ? 'SIM name'
                      : searchBy === 'iccid'
                      ? 'iccid'
                      : searchBy === 'userId'
                      ? 'user id'
                      : searchBy === 'tags'
                      ? 'tags'
                      : 'Phone Number'
                  }`}
                  name="searchUser"
                  onChange={this.handleSearch.bind(this)}
                  required
                />
              </div>
            </div>
          </div>
          {(_.size(searchClientFilter) > 0 ||
            _.size(searchFilterTags) > 0 ||
            this.state.byDate ||
            _.size(planFilter) ||
            _.size(simFilter) > 0) > 0 && (
            <ul className="filter__list__flitered mt-3">
              {searchFilterTags.map((item, index) => {
                return (
                  <li key={index}>
                    {item}{' '}
                    <span onClick={this.removeFilter.bind(this, index)}>
                      <CloseIcon className="close-icon" />
                    </span>
                  </li>
                );
              })}
              {pSimFilter && (
                <li>
                  {'Physical SIMs'}{' '}
                  <span onClick={this.removePSimFilter}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
              {eSimFilter && (
                <li>
                  {'eSIMs'}{' '}
                  <span onClick={this.removeESimFilter}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
              {searchClientFilter.map((item, index) => {
                return (
                  <li key={index}>
                    {item.fullname}{' '}
                    <span onClick={this.removeClientFilter.bind(this, index)}>
                      <CloseIcon className="close-icon" />
                    </span>
                  </li>
                );
              })}
              {planFilter.map((item, index) => {
                return (
                  <li key={index}>
                    {item.title}{' '}
                    <span onClick={this.onPlanFilterChange.bind(this, item)}>
                      <CloseIcon className="close-icon" />
                    </span>
                  </li>
                );
              })}
              {this.state.byDate && (
                <li>
                  {moment(startDate).format('MMMM DD, YYYY') +
                    ' - ' +
                    moment(endDate).format('MMMM DD, YYYY')}
                  <span onClick={this.removeFilter.bind(this, -10)}>
                    <CloseIcon className="close-icon" />
                  </span>
                </li>
              )}
            </ul>
          )}
          <div className="row">
            <div className="col-md-12">
              {_.size(sims) > 0 && !this.state.isLoading && (
                <div className="all-filters">
                  <div className="select-all">
                    <label className="custom-checkbox-only">
                      <input
                        type="checkbox"
                        className="custom-checkbox--input"
                        name="duplex"
                        checked={this.state.allChecked}
                        onChange={(e) => this.onCheck({}, 'All')}
                      />
                      <span className="checkmark-only"></span>
                    </label>
                  </div>

                  {/* select Action */}
                  <div className="sims-action">
                    {(activeTab === 'inactive' ||
                      activeTab === 'availableeSim') && (
                      <>
                        {activeTab === 'availableeSim' ? (
                          <button
                            disabled={_.size(markedSimList) === 0}
                            onClick={() =>
                              this.selectActionHandleForAvailableEsims(
                                'activateEsimbyID'
                              )
                            }
                            className="btn btn-link"
                          >
                            Activate
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={_.size(markedSimList) === 0}
                            onClick={() => {
                              this.selectActionHandle('activate');
                            }}
                            className="btn btn-link"
                          >
                            Activate
                          </button>
                        )}
                        <button
                          disabled={_.size(markedSimList) !== 1}
                          onClick={() =>
                            activeTab === 'availableeSim'
                              ? this.selectActionHandleForAvailableEsims(
                                  'portInEsim'
                                )
                              : this.selectActionHandle('portIn')
                          }
                          className="btn btn-link"
                        >
                          Activate w/ Port-in{' '}
                        </button>
                      </>
                    )}
                    {activeTab === 'active' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('pause')}
                          className="btn btn-link"
                        >
                          Pause{' '}
                        </button>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('inactive')}
                          className="btn btn-link"
                        >
                          Deactivate{' '}
                        </button>
                      </>
                    )}
                    <button
                      disabled={_.size(markedSimList) === 0}
                      onClick={() => this.selectActionHandle('setsimname')}
                      className="btn btn-link"
                    >
                      Set SIM Name{' '}
                    </button>
                    <button
                      disabled={_.size(markedSimList) === 0}
                      onClick={this.createTagsModal}
                      className="btn btn-link"
                    >
                      Add Tag{' '}
                    </button>
                    {(activeTab === 'active' ||
                      activeTab === 'inactive' ||
                      activeTab === 'availableeSim') &&
                      (isSPRPartner(userData) ||
                        (isSubUser(userData) &&
                          checkPermission(userData, '/add-client'))) && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={this.createClientModal}
                          className="btn btn-link"
                        >
                          Add Client{' '}
                        </button>
                      )}
                    {((iAuthorization && iAuthorization !== '') ||
                      (userData &&
                        (userData.role === 'superadmin' ||
                          userData.role === 'admin' ||
                          userData.role === 'sysops'))) &&
                      activeTab === 'inactive' && (
                        <button
                          disabled={
                            _.size(markedSimList) === 0 ||
                            (_.size(markedSimList) >= 1 &&
                              markedSimList[0].isEsim === 1) ||
                            _.size(sims) === _.size(markedSimList) ||
                            (markedSimList.filter((sim) => sim.isEsim === 1)
                              .length > 0 &&
                              markedSimList.filter((sim) => sim.isEsim === 0)
                                .length > 0)
                          }
                          onClick={() => this.selectActionHandle('delete')}
                          className="btn btn-delete"
                        >
                          Delete{' '}
                        </button>
                      )}
                    {activeTab === 'active' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('changePlan')}
                          className="btn btn-link"
                        >
                          Change Plan{' '}
                        </button>
                      </>
                    )}
                    {activeTab === 'deactive' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('reactivate')}
                          className="btn btn-link"
                        >
                          Reactivate{' '}
                        </button>
                      </>
                    )}
                    {activeTab === 'paused' && (
                      <>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('unpause')}
                          className="btn btn-link"
                        >
                          Unpause{' '}
                        </button>
                        <button
                          disabled={_.size(markedSimList) === 0}
                          onClick={() => this.selectActionHandle('inactive')}
                          className="btn btn-link"
                        >
                          Deactivate{' '}
                        </button>
                      </>
                    )}
                    {activeTab === 'activationInProgress' && (
                      <button
                        disabled={moveBackButtonDisabl}
                        onClick={() => this.openMoveBackModal()}
                        className="btn btn-link"
                      >
                        Move to Available SIMs{' '}
                      </button>
                    )}
                  </div>
                  <div className="filter-page-number ml-auto">
                    {page > 1 ? (page - 1) * limit + 1 : 1}-
                    {limit * page < total ? limit * page : total} of {total}
                  </div>
                </div>
              )}
              {/* LISTS */}
              <div className="row">
                <div className="col-md-12">
                  {this.state.isLoading ? (
                    <div className="blank__table__card mt-3">
                      <div className="blank__table__card__inner">
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: '#000' }}
                              spin
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : _.size(sims) > 0 ? (
                    <Spin
                      spinning={false}
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: '#000' }}
                          spin
                        />
                      }
                    >
                      <SimList
                        simDataWithImeiCompatibility={
                          this.state.simDataWithImeiCompatibility
                        }
                        user={userData}
                        iAuthorization={iAuthorization}
                        activeTab={activeTab}
                        data={sims}
                        totalPagination={total}
                        page={page}
                        limit={limit}
                        onPageChange={this.onPageChanged}
                        markedSimList={markedSimList}
                        onCheck={this.onCheck.bind(this)}
                        handleZipcodeChange={this.handleZipcodeChange}
                        handleSimImeiChange={this.handleSimImeiChange}
                        handelSimNameChange={this.handelSimNameChange}
                        singleSimAction={this.singleSimAction}
                        cancelSimPortIn={this.cancelSimPortIn}
                        simDetails={this.simDetails}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        sortByKey={this.sortByKey}
                        addTagOnSim={this.addTagOnSim}
                        addClientOnSim={this.addClientOnSim}
                        match={this.props.match}
                        simPlansData={simPlansData}
                      />
                    </Spin>
                  ) : (
                    <div className="blank__table__card mt-3">
                      <div className="blank__table__card__inner">
                        No
                        {activeTab === 'active' && ' Active '}
                        {activeTab === 'activationInProgress' &&
                          ' Activations In-Progress '}
                        {activeTab === 'inactive' && ' Available SIMs '}
                        {activeTab === 'paused' && ' Paused '}
                        {activeTab === 'deactive' && ' Deactivated '}
                        SIMs
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* CREATE CLIENT MODAL */}
        <ClientModal
          isOpen={this.state.isCreateClientModal}
          onRequestClose={this.closeModal}
          selectedSimPlan={selectedSimPlan}
          selectedClient={selectedClient}
          clients={clients}
          onSelectionChange={this.handleClientChange}
          onSelection={this.handleClientSelect}
          newClientSize={this.state.newClientSize}
          updateLoading={this.state.updateLoading}
          saveClient={this.saveClient.bind(this)}
        />
        {/* CREATE TAG MODAL */}
        <ReactModal
          isOpen={this.state.isCreateTagModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Create tags"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Add Tag </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <Select
                  autoFocus
                  mode="tags"
                  size="large"
                  bordered={false}
                  defaultValue={selectedListTags}
                  className="multiple__tags__textfield"
                  dropdownClassName="multiple__tags__textfield__overlay"
                  style={{ width: '100%' }}
                  placeholder="Tag Name"
                  onChange={this.handleTagChange}
                >
                  {_.size(filterTags) > 0 &&
                    filterTags.map((tag, index) => {
                      return (
                        <Option key={index} value={tag}>
                          {tag}
                        </Option>
                      );
                    })}
                </Select>
                {this.state.newTagSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={
                  this.state.updateLoading ||
                  this.state.newTagSize ||
                  !this.state.isTagChanged
                }
                type="button"
                onClick={this.saveTag.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Set Sim Name MODAL */}
        <ReactModal
          isOpen={this.state.isUpdateSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Set SIMs Name"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Set SIM Name </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="newSimName"
                  value={
                    this.state.newSimName === 'undefined'
                      ? ''
                      : this.state.newSimName
                  }
                  onChange={(e) =>
                    this.setState({
                      newSimName: e.target.value,
                      requireName: false,
                      newSimNameSize: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">SIM Name</label>
                {this.state.requireName && (
                  <div className="invalid-feedback">Name is required</div>
                )}
                {this.state.newSimNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
              </div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.updateSimName.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Deactivate & Pause MODAL */}
        <ReactModal
          isOpen={this.state.isActionModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">
                {this.state.selectedAction === 'pause'
                  ? 'Are you sure you want to pause this SIM?'
                  : 'Are you sure you want to deactivate this SIM?'}
              </h5>
              {this.state.selectedAction === 'pause' ? (
                <p className="react-modal-subtitle mt-2">
                  SIM(s) are paused immediately. Once paused, the SIM(s) will no
                  longer be connected to data, voice, or SMS service. <br />
                  <br /> You can un-pause it any time by changing the plan of
                  the SIM.
                </p>
              ) : (
                <p className="react-modal-subtitle mt-2">
                  Once this SIM is deactivated, you will only have 30 days to
                  reactivate, otherwise the SIM will be cancelled and the number
                  will be released.
                </p>
              )}
              <div className="text-right mt-3">
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={
                    this.state.selectedAction === 'pause'
                      ? this.pauseSim
                      : this.deactivateSim
                  }
                  className="btn btn-dark w-150"
                >
                  {this.state.updateLoading
                    ? 'Please wait...'
                    : "Yes, I'm sure"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* ACTIVATE PLAN MODAL */}
        <ReactModal
          isOpen={this.state.isActivateModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Activate{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  id="selectPlan"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: simPlansData[e.target.value],
                      planError: false,
                      ildPlanValue: 0,
                      isILDEnabled: false,
                      isIREnabled: false,
                      isIldIrEnabled: false,
                      ildPlanValueRequired: false,
                      irPlanValue: 0,
                      irPlanValueRequired: false,
                      remainingIRBalanceError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label
                  className="material-textfield-label"
                  htmlFor="selectPlan"
                >
                  Select Plan
                </label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {!_.isEmpty(selectedSimPlan) &&
                (selectedSimPlan.title.toLowerCase().includes('just data') ||
                  selectedSimPlan.title
                    .toLowerCase()
                    .includes('everything')) && (
                  <div className="info-block">
                    <label className="label-text">
                      {selectedSimPlan.title}{' '}
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? 'Plan'
                        : ''}{' '}
                      Details{' '}
                    </label>
                    <p className="text-desc">
                      ${selectedSimPlan.monthlyprice}/month <br />$
                      {selectedSimPlan.dataprice}/mb <br />
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? '(Data)'
                        : '(Data, Voice, SMS)'}
                      <br />
                    </p>
                  </div>
                )}
              {!_.isEmpty(selectedSimPlan) &&
                (activeTab === 'inactive' || activeTab === 'availableeSim') && (
                  <ul className="toggle__list">
                    {_.get(selectedSimPlan, 'isILD', false) === true &&
                    _.get(userData, 'isILDEnabled', false) ? (
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text" htmlFor="addILD">
                            Add ILD
                          </label>
                          <Switch
                            checked={this.state.isILDEnabled}
                            onChange={() => this.onChangeSwitch('isILDEnabled')}
                            className="ml-3"
                            id="addILD"
                          />
                        </div>
                        {this.state.isILDEnabled && (
                          <div className="toggle__list--wrap">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                onChange={(e) =>
                                  this.setState({
                                    ildPlanValue: parseFloat(e.target.value),
                                    ildPlanValueRequired: false,
                                  })
                                }
                              >
                                <option value={0}>Select</option>
                                {_.size(ildPlans) > 0 &&
                                  ildPlans.map((plan) => {
                                    return (
                                      <option
                                        key={plan.titleValue}
                                        value={plan.titleValue}
                                      >
                                        {`${plan.titleValue} minutes`}
                                      </option>
                                    );
                                  })}
                              </select>
                              <label className="material-textfield-label">
                                Select balance amount
                              </label>
                              {this.state.ildPlanValueRequired && (
                                <div className="invalid-feedback">
                                  Please select ILD minutes.
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="select-btn-choice">
                                <button
                                  className={
                                    this.state.ildPlanType === 'oneTime'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ ildPlanType: 'oneTime' })
                                  }
                                >
                                  One Time
                                </button>
                                <button
                                  className={
                                    this.state.ildPlanType === 'recurring'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ ildPlanType: 'recurring' })
                                  }
                                >
                                  Recurring
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ) : (
                      ''
                    )}
                    {_.get(selectedSimPlan, 'isIR', false) &&
                    _.get(userData, 'isIREnabled', false) ? (
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text" htmlFor="addIR">
                            Add IR
                          </label>
                          <Switch
                            checked={this.state.isIREnabled}
                            onChange={() => this.onChangeSwitch('isIREnabled')}
                            className="ml-3"
                            id="addIR"
                          />
                        </div>

                        {this.state.isIREnabled && (
                          <div className="toggle__list--wrap">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                id="selectIRBalance"
                                onChange={(e) =>
                                  this.setState({
                                    addIRBalance: parseFloat(e.target.value),
                                    irPlanValue: parseFloat(e.target.value),
                                    irPlanValueRequired: false,
                                  })
                                }
                              >
                                <option value={0}>Select</option>
                                {_.size(irPlans) > 0 &&
                                  irPlans.map((plan) => {
                                    return (
                                      <option
                                        key={plan.titleValue}
                                        value={plan.titleValue}
                                      >
                                        {`$${plan.titleValue.toFixed(2)}`}
                                      </option>
                                    );
                                  })}
                              </select>
                              <label
                                className="material-textfield-label"
                                htmlFor="selectIRBalance"
                              >
                                Select balance amount
                              </label>
                              {this.state.irPlanValueRequired && (
                                <div className="invalid-feedback">
                                  Please select IR balance.
                                </div>
                              )}
                              {this.state.remainingIRBalanceError && (
                                <div className="invalid-feedback">
                                  You have insufficient balance
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <div className="select-btn-choice">
                                <button
                                  className={
                                    this.state.irPlanType === 'oneTime'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ irPlanType: 'oneTime' })
                                  }
                                >
                                  One Time
                                </button>
                                <button
                                  className={
                                    this.state.irPlanType === 'recurring'
                                      ? 'btn active'
                                      : 'btn'
                                  }
                                  onClick={(e) =>
                                    this.setState({ irPlanType: 'recurring' })
                                  }
                                >
                                  Recurring
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                )}
              {activeTab === 'inactive' || activeTab === 'availableeSim' ? (
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={this.activateSim}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              ) : (
                <button
                  disabled={this.state.updateLoading}
                  type="button"
                  onClick={this.activatePauseDeactivateSim}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              )}
            </div>
          </div>
        </ReactModal>

        {/* Open thank You modal */}
        <ReactModal
          isOpen={this.state.openThankYouModal}
          onRequestClose={() => this.setState({ openThankYouModal: false })}
          ariaHideApp={false}
          contentLabel="Thank You - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.titleText}
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ openThankYouModal: false })}
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() => this.setState({ openThankYouModal: false })}
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        {/* loading modal for use while checkinf zip and imei compatibility */}
        <ReactModal
          isOpen={this.state.openLoadingModal}
          onRequestClose={() => this.setState({ openLoadingModal: false })}
          ariaHideApp={false}
          contentLabel="Validating IMEI and Zipcodes"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Validating IMEI and zipcodes before eSIMs activation.
              </h5>
              <div className="d-flex align-items-center mt-5 mb-5">
                <Spin
                  className="loader__full-"
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: '#000' }}
                      spin
                    />
                  }
                />
                <p className="loading-text">Please Wait..</p>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.openImeiIncompatibleModal}
          onRequestClose={() =>
            this.setState({ openImeiIncompatibleModal: false })
          }
          ariaHideApp={false}
          contentLabel="IMEI compatibility check"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Warning
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() =>
                  this.setState({ openImeiIncompatibleModal: false })
                }
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() =>
                    this.setState({
                      openImeiIncompatibleModal: false,
                      isActivateModal: true,
                    })
                  }
                  className="btn btn-dark w-150"
                >
                  {'YES'}
                </button>
                &nbsp;
                <button
                  onClick={() =>
                    this.setState({ openImeiIncompatibleModal: false })
                  }
                  className="btn btn-dark w-150"
                >
                  {'NO'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* DELETE SIM MODAL */}
        <ReactModal
          isOpen={this.state.deleteModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Delete Sim</h5>
              <p className="react-modal-subtitle mt-2">
                Are you sure you want to delete{' '}
                {!_.isEmpty(this.state.deleteSimnumber)
                  ? this.state.deleteSimnumber.simnumber + ' sim?'
                  : ' sims?'}
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={this.state.updateLoading}
                  onClick={this.deleteSim}
                >
                  {this.state.updateLoading ? 'Please wait ...' : 'Yes, Delete'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* PORT SIM MODAL */}
        <ReactModal
          isOpen={this.state.portSimModal}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="PORT SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Submit Port-In Activation</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="portInPhoneNumber"
                  value={this.state.portInPhoneNumber}
                  onChange={(e) =>
                    this.setState({
                      portInPhoneNumber: e.target.value,
                      requirePortInPhoneNumber: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Port-in Phone Number*
                </label>
                {this.state.requirePortInPhoneNumber && (
                  <div className="invalid-feedback">
                    Port-in number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedCarrier: e.target.value,
                      carrierError: false,
                    })
                  }
                >
                  <option>Select</option>
                  <option value={'Verizon'}>Verizon</option>
                  <option value={'Sprint'}>Sprint</option>
                  <option value={'AT&T'}>AT&T</option>
                  <option value={'T-Mobile'}>T-Mobile</option>
                  <option value={'Other'}>Other</option>
                </select>
                <label className="material-textfield-label">
                  Select Carrier*
                </label>
                {this.state.carrierError && (
                  <div className="invalid-feedback">Carrier is required</div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierACNumber"
                  value={this.state.carrierACNumber}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Account Number*
                </label>
                {this.state.requireCarrierACNumber && (
                  <div className="invalid-feedback">
                    Carrier account number is required
                  </div>
                )}
              </div>

              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="carrierAssignPassword"
                  value={this.state.carrierAssignPassword}
                  onChange={(e) => this.restrictSpecialCharacters(e)}
                  required
                />
                <label className="material-textfield-label">
                  Carrier Assigned Password*
                </label>
                {this.state.requireCarrierAssignPassword && (
                  <div className="invalid-feedback">
                    Carrier assigned password is required
                  </div>
                )}
              </div>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="name"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Name</label>
                {this.state.nameError && (
                  <div className="invalid-feedback">
                    Name should not be longer than 25 characters.
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <PlacesAutocomplete
                      type="text"
                      name="street1"
                      value={this.state.street1}
                      className="form-control form-control-lg material-textfield-input"
                      handleAddressChange={({ address, city, state, zip }) =>
                        this.setState({
                          street1: address,
                          city,
                          state,
                          zip: zip,
                        })
                      }
                      setValue={(street1) => this.setState({ street1 })}
                      address={this.state.street1}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="street2"
                      value={this.state.street2}
                      onChange={(e) =>
                        this.setState({
                          street2: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">
                      Address Line 2
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="city"
                      value={this.state.city}
                      onChange={(e) =>
                        this.setState({
                          city: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">City</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="state"
                      value={this.state.state}
                      onChange={(e) =>
                        this.setState({
                          state: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">State</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip"
                      value={this.state.zip}
                      onChange={(e) =>
                        this.setState({
                          zip: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label">Zipcode</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={clsx('form-group material-textfield', {
                      'input-error': this.state.requireZipcodePortIn || this.state.errorMessage === "Zipcode is not eligible." || this.state.errorMessage === "zipcode length must be at least 5 characters long",
                    })}
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      id="zipcodePortIn"
                      name="zipcodePortIn"
                      value={this.state.zipcodePortIn}
                      maxLength="5"
                      onChange={(e) =>
                        this.setState({
                          zipcodePortIn: e.target.value,
                          requireZipcodePortIn: false,
                          zipcodeErrorMessage: '',
                        })
                      }
                      required
                    />
                    <label className="material-textfield-label" htmlFor='zipcodePortIn'>
                      Zipcode of Port-In Phone Number*
                    </label>
                    {this.state.requireZipcodePortIn && (
                      <div className="invalid-feedback">
                        Zipcode of port-in phone number is required
                      </div>
                    )}
                    {this.state.zipcodeErrorMessage && (
                      <div className="invalid-feedback">
                        {this.state.zipcodeErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      name="zip4"
                      value={this.state.zip4}
                      maxLength="5"
                      onChange={(e) => this.setState({ zip4: e.target.value })}
                      required
                    />
                    <label className="material-textfield-label">
                      Zipcode 4
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <InputMask
                  guide={false}
                  type="text"
                  keepCharPositions={false}
                  mask={phoneNumberMask}
                  className="form-control form-control-lg material-textfield-input"
                  name="altContactNumber"
                  value={this.state.altContactNumber}
                  onChange={(e) =>
                    this.setState({
                      altContactNumber: e.target.value,
                      requireAltContactNumber: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Alternate Contact Number*
                </label>
                <div style={{ color: '#979797' }}>
                  We need this in case we need to contact you during the port in
                  process.
                </div>
                {this.state.requireAltContactNumber && (
                  <div className="invalid-feedback">
                    Alternate contact number is required
                  </div>
                )}
              </div>
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: simPlansData[e.target.value],
                      planError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>

                <label className="material-textfield-label">Select Plan*</label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {!_.isEmpty(selectedSimPlan) &&
                (selectedSimPlan.title.toLowerCase().includes('just data') ||
                  selectedSimPlan.title
                    .toLowerCase()
                    .includes('everything')) && (
                  <div className="info-block">
                    <label className="label-text">
                      {selectedSimPlan.title}
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? 'Plan'
                        : ''}
                      Details
                    </label>
                    <p className="text-desc">
                      ${selectedSimPlan.monthlyprice}/month <br />$
                      {selectedSimPlan.dataprice}/mb <br />
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? '(Data)'
                        : '(Data, Voice, SMS)'}
                      <br />
                    </p>
                  </div>
                )}
              <div className="invalid-feedback">{this.state.errorMessage}</div>
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.submitPortIn}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Submit Port-In Activation'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Open Cance-PortIn modal */}
        <ReactModal
          isOpen={this.state.openCancelPortInFailureModal}
          onRequestClose={() =>
            this.setState({ openCancelPortInFailureModal: false })
          }
          ariaHideApp={false}
          contentLabel="Cancel PortIn Failed - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                {this.state.titleText}
              </h5>
              <p className="react-modal-subtitle mt-2">
                {this.state.descriptionText}
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() =>
                  this.setState({ openCancelPortInFailureModal: false })
                }
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  onClick={() =>
                    this.setState({ openCancelPortInFailureModal: false })
                  }
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Change Plan Active sim modal start */}
        <ReactModal
          isOpen={this.state.changePlanModal}
          onRequestClose={() =>
            this.setState({
              changePlanModal: false,
              isILDEnabled: false,
              isIREnabled: false,
              irPlanTypeRequired: false,
              irPlanValueRequired: false,
              ildPlanTypeRequired: false,
              ildPlanValueRequired: false,
              remainingIRBalanceError: false,
              ChangeplanildPlanType: '',
              ChangeplanirPlanType: '',
              hasSamePlan: false,
            })
          }
          ariaHideApp={false}
          contentLabel="Edit SIM Details"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Change Plan</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() =>
                  this.setState({
                    changePlanModal: false,
                    isILDEnabled: false,
                    isIREnabled: false,
                    irPlanTypeRequired: false,
                    irPlanValueRequired: false,
                    ildPlanTypeRequired: false,
                    ildPlanValueRequired: false,
                    remainingIRBalanceError: false,
                    ChangeplanildPlanType: '',
                    ChangeplanirPlanType: '',
                    hasSamePlan: false,
                  })
                }
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>Change Plan?</h4>
                <p>
                  Changing plan 3 times will lock your sim onto the new plan
                  until end of current plan cycle date, after which you will be
                  able to re-select a different plan.
                </p>
              </div>
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      changedSimPlan: simPlansData[e.target.value],
                      selectedSimPlan: simPlansData[e.target.value],
                      planValue: e.target.value,
                      reactivatePlanError: false,
                      isIldIrEnabled: false,
                      isILDEnabled: false,
                      isIREnabled: false,
                      changedPlanError: false,
                      hasSamePlan: false,
                      ildPlanTypeRequired: false,
                      irPlanTypeRequired: false,
                    })
                  }
                  value={planValue}
                >
                  <option>Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.changedPlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              <div className="invalid-feedback mb-3">
                {this.state.simLengthError}
              </div>
              {_.get(userData, 'isIREnabled', 0) === 1 ||
              _.get(userData, 'isILDEnabled', 0) === 1 ? (
                <div className="form-group">
                  {selectedSimPlan?.isILD === true && userData.isILDEnabled === 1
                  && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on ILD functionality for ALL selected SIMs
                      <Switch
                        checked={this.state.isILDEnabled}
                        onChange={() => this.onChangeSwitch('isILDEnabled')}
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isILDEnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                ildPlanValue: parseFloat(e.target.value),
                                ildPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {ildPlans.length &&
                              ildPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`${plan.titleValue} minutes`}
                                  </option>
                                );
                              })}
                          </select>
                          <label className="material-textfield-label">
                            Select Minutes
                          </label>
                          {this.state.ildPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select ILD minutes.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            value={this.state.ChangeplanildPlanType}
                            onChange={(e) =>
                              this.setState({
                                ChangeplanildPlanType: e.target.value,
                                ildPlanTypeRequired: false,
                              })
                            }
                          >
                            <option value="">Select</option>
                            <option value="oneTime"> One Time </option>
                            <option value="recurring"> Recurring </option>
                          </select>
                          <label className="material-textfield-label">
                            Select Subscription Type
                          </label>
                          {this.state.ildPlanTypeRequired && (
                            <div className="invalid-feedback">
                              Please select ILD subscription type.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSimPlan?.isIR && userData?.isIREnabled === 1
                  && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on IR functionality for ALL selected SIMs
                      <Switch
                        checked={this.state.isIREnabled}
                        onChange={() => this.onChangeSwitch('isIREnabled')}
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isIREnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                irPlanValue: parseFloat(e.target.value),
                                irPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {irPlans.length &&
                              irPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`$${plan.titleValue.toFixed(2)}`}
                                  </option>
                                );
                              })}
                          </select>
                          <label className="material-textfield-label">
                            Select balance amount
                          </label>
                          {this.state.irPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select IR balance.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <div className="form-group material-textfield">
                            <select
                              className="form-control form-control-lg custom-select material-textfield-input"
                              value={this.state.ChangeplanirPlanType}
                              onChange={(e) =>
                                this.setState({
                                  ChangeplanirPlanType: e.target.value,
                                  irPlanTypeRequired: false,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="oneTime"> One Time </option>
                              <option value="recurring"> Recurring </option>
                            </select>
                            <label className="material-textfield-label">
                              Select Subscription Type
                            </label>
                            {this.state.irPlanTypeRequired && (
                              <div className="invalid-feedback">
                                Please select IR subscription type.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              {this.state.hasSamePlan && (
                <div className="form-group invalid-feedback">
                  This plan is already activated. Please select other plan.
                </div>
              )}
              <button
                type="submit"
                disabled={this.state.changePlanLoading}
                onClick={this.bulkPlanChange.bind(this)}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.changePlanLoading ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>
        {/* REACTIVATE PLAN MODAL */}
        <ReactModal
          isOpen={this.state.reactivateSimModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Reactivate{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: simPlansData[e.target.value],
                      planError: false,
                    })
                  }
                >
                  <option>Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.reactivatePlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {!_.isEmpty(selectedSimPlan) &&
                (selectedSimPlan.title.toLowerCase().includes('just data') ||
                  selectedSimPlan.title
                    .toLowerCase()
                    .includes('everything')) && (
                  <div className="info-block">
                    <label className="label-text">
                      {selectedSimPlan.title}{' '}
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? 'Plan'
                        : ''}{' '}
                      Details{' '}
                    </label>
                    <p className="text-desc">
                      ${selectedSimPlan.monthlyprice}/month <br />$
                      {selectedSimPlan.dataprice}/mb <br />
                      {selectedSimPlan.title.toLowerCase().includes('just data')
                        ? '(Data)'
                        : '(Data, Voice, SMS)'}
                      <br />
                    </p>
                  </div>
                )}
              <button
                disabled={this.state.reactivateLoading}
                type="button"
                onClick={this.reactivateSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.reactivateLoading
                  ? 'Please wait...'
                  : 'Yes, Reactivate Now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Unpause MODAL */}

        <ReactModal
          isOpen={this.state.isUnpauseModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Unpause"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                Unpause{' '}
                {_.size(markedSimList) === 1 ? markedSimList[0].simnumber : ''}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={this.handelReactivatPlanChange.bind(this)}
                  value={planValue}
                >
                  <option value="">Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {plan.title}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Select Plan</label>
                {this.state.unPauseSimPlanError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
              </div>
              {_.get(userData, 'isIREnabled', 0) === 1 ||
              _.get(userData, 'isILDEnabled', 0) === 1 ? (
                <div className="form-group">
                  {selectedSimPlan?.isILD === true && userData?.isILDEnabled === 1
                  && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on ILD functionality for ALL selected SIMs
                      <Switch
                        checked={this.state.isILDEnabled}
                        onChange={() => this.onChangeSwitch('isILDEnabled')}
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isILDEnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                ildPlanValue: parseFloat(e.target.value),
                                ildPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {_.size(ildPlans) > 0 &&
                              ildPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`${plan.titleValue} minutes`}
                                  </option>
                                );
                              })}
                          </select>
                          <label className="material-textfield-label">
                            Select Minutes
                          </label>
                          {this.state.ildPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select ILD minutes.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                ildPlanType: e.target.value,
                                ildPlanTypeRequired: false,
                              })
                            }
                          >
                            <option value="">Select</option>
                            <option value="oneTime"> One Time </option>
                            <option value="recurring"> Recurring </option>
                          </select>
                          <label className="material-textfield-label">
                            Select Subscription Type
                          </label>
                          {this.state.ildPlanTypeRequired && (
                            <div className="invalid-feedback">
                              Please select ILD subscription type.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedSimPlan?.isIR && userData?.isIREnabled === 1
                  && (
                    <p className="react-modal-subtitle mt-2 d-flex align-items-center justify-content-between">
                      Turn on IR functionality for ALL selected SIMs
                      <Switch
                        checked={this.state.isIREnabled}
                        onChange={() => this.onChangeSwitch('isIREnabled')}
                        className="ml-3"
                      />
                    </p>
                  )}
                  {this.state.isIREnabled && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            onChange={(e) =>
                              this.setState({
                                irPlanValue: parseFloat(e.target.value),
                                irPlanValueRequired: false,
                              })
                            }
                          >
                            <option value={0}>Select</option>
                            {_.size(irPlans) > 0 &&
                              irPlans.map((plan) => {
                                return (
                                  <option
                                    key={plan.titleValue}
                                    value={plan.titleValue}
                                  >
                                    {`$${plan.titleValue.toFixed(2)}`}
                                  </option>
                                );
                              })}
                          </select>
                          <label className="material-textfield-label">
                            Select balance amount
                          </label>
                          {this.state.irPlanValueRequired && (
                            <div className="invalid-feedback">
                              Please select IR balance.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <div className="form-group material-textfield">
                            <select
                              className="form-control form-control-lg custom-select material-textfield-input"
                              onChange={(e) =>
                                this.setState({
                                  irPlanType: e.target.value,
                                  irPlanTypeRequired: false,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="oneTime"> One Time </option>
                              <option value="recurring"> Recurring </option>
                            </select>
                            <label className="material-textfield-label">
                              Select Subscription Type
                            </label>
                            {this.state.irPlanTypeRequired && (
                              <div className="invalid-feedback">
                                Please select IR subscription type.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              <button
                disabled={this.state.updateLoading}
                type="button"
                onClick={this.unPauseSim}
                className="btn btn-dark btn-lg w-100"
              >
                {this.state.updateLoading
                  ? 'Please wait...'
                  : 'Yes, unpause now'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/*  SIM MOVE INTO NEVER BEEN TAB MODAL */}
        <ReactModal
          isOpen={this.state.isMoveBackModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Move SIMs to Available SIMs</h5>
              <p className="react-modal-subtitle mt-2">
                Are you sure you want to move SIMs to Available SIMs?
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={this.state.updateLoading}
                  onClick={this.setSimsIntoNeverBeen}
                >
                  {this.state.updateLoading
                    ? 'Please wait ...'
                    : 'Yes, Move It'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.openImeiValidateModal}
          onRequestClose={() => this.setState({ openImeiValidateModal: false })}
          ariaHideApp={false}
          contentLabel="Swap SIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Warning</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ openImeiValidateModal: false })}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="update-plan-message form-group">
                <h4>
                  One or more device IMEIs have not been provided or are not
                  compatible with eSIMs. Are you sure you want to proceed?
                </h4>
              </div>
              <div className="text-right mt-3">
                <button
                  type="submit"
                  onClick={this.openEsimActivateModal.bind(this)}
                  className="btn btn-dark w-150"
                >
                  YES
                </button>
                <button
                  type="submit"
                  onClick={() =>
                    this.setState({ openImeiValidateModal: false })
                  }
                  className="btn btn-dark w-150 ml-2"
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Activate eSIM Modal */}
        <ReactModal
          isOpen={this.state.activateEsimModal}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Activate w/ Port-in eSIM"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">
                {this.state.selectedAction === 'activateEsimbyID'
                  ? 'Activate eSIM'
                  : 'Activate w/ Port-in eSIM'}
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => {
                  this.closeModal();
                  this.setState({ newZipcode: '', activateEsimZip: '' });
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-text mb-3">
                  Check the coverage in your area
                </label>
                <div className="form-group material-textfield stacked-label-block">
                  <input
                    type="text"
                    maxLength="17"
                    className="form-control form-control-lg material-textfield-input"
                    placeholder="Enter your Zip code"
                    required
                    onChange={(e) => {
                      this.handleZipChange(e);
                    }}
                    onFocus={() => {
                      this.setState({ isCheckCoverage: true });
                      this.setState({ isCheckDeviceCompatibility: false });
                      this.setState({ isSelectPlan: false });
                      this.setState({ isInstalleSIM: false });
                    }}
                  />
                  <label className="material-textfield-label">Zip code </label>
                  {zipCodeApiError !== '' ? (
                    <div className="invalid-feedback mb-2">
                      {zipCodeApiError}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <button
                  type="button"
                  className={
                    this.state.zipcode !== ''
                      ? 'btn btn-dark btn-lg w-100'
                      : 'btn btn-gray btn-lg w-100'
                  }
                  onClick={this.checkCoverage}
                  disabled={
                    this.state.zipcodeLoding || !(this.state.zipcode !== '')
                  }
                >
                  {this.state.zipcodeLoding ? 'Please wait...' : 'Lets go!'}
                </button>
                {this.state.zipCodeSuccess &&
                  this.state.zipCodeSuccessMessage &&
                  (this.state.zipCodeSuccessMessage ===
                  'No Coverage Available.' ? (
                    <div className="activate-esim-aleart error-new">
                      <ErrorIcon /> {this.state.zipCodeSuccessMessage}
                    </div>
                  ) : (
                    <div className="activate-esim-aleart success">
                      <SuccessIcon /> {this.state.zipCodeSuccessMessage}
                    </div>
                  ))}
              </div>
              <div className="form-group">
                <label className="label-text mb-3">Check by IMEI number</label>
                <div className="form-group material-textfield stacked-label-block">
                  <input
                    type="text"
                    maxLength="17"
                    className="form-control form-control-lg material-textfield-input"
                    placeholder="Enter your phone’s 15-17 digit IMEI"
                    required
                    onChange={(e) => {
                      this.handleImeiChange(e);
                    }}
                    onFocus={() => {
                      this.setState({ isCheckCoverage: true });
                      this.setState({ isCheckDeviceCompatibility: true });
                      this.setState({ isSelectPlan: false });
                      this.setState({ isInstalleSIM: false });
                    }}
                  />
                  <label className="material-textfield-label">
                    IMEI Number
                  </label>
                  {deviceApiError !== '' ? (
                    <div className="invalid-feedback mb-2">
                      {deviceApiError}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <button
                  type="button"
                  className={
                    imeiNumber !== ''
                      ? 'btn btn-dark btn-lg w-100'
                      : 'btn btn-gray btn-lg w-100'
                  }
                  onClick={this.checkImeiNumber}
                  disabled={!(imeiNumber !== '') || this.state.imeiLoding}
                >
                  {this.state.imeiLoding
                    ? 'Please wait...'
                    : 'Check Device Compatibility'}
                </button>
              </div>
              {!this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.isImeiSection === true && (
                  <div className="card">
                    <div className="card-body">
                      <div className="activate-esim-aleart error">
                        <span>
                          <ErrorIcon />
                        </span>{' '}
                        <span>
                          {this.state.deviceName} is not compatible with eSIM.
                          You may proceed however with activating an eSIM for
                          this device or cancel and activate a physical SIM.
                        </span>
                      </div>
                      <div className="esim-activation-title">
                        Are you sure you want to continue with eSIM activation?
                      </div>
                      <button
                        type="button"
                        className="btn btn-dark btn-lg w-200 mr-3"
                        onClick={this.handleErrorBox}
                      >
                        Yes, Proceed
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark btn-lg w-200"
                        onClick={() => {
                          this.setState({
                            activateEsimModal: false,
                            eSIMCompatible: true,
                            deviceName: '',
                            imeiNumber: '',
                            brand: '',
                            model: '',
                            swapESimError: '',
                            zipCodeSuccess: '',
                            zipcodeLoding: '',
                            zipcode: '',
                          });
                          this.props.history.push(`/available-esims`);
                        }}
                      >
                        No, Cancel
                      </button>
                    </div>
                  </div>
                )}
              {this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.proceed === false &&
                this.state.isImeiSection === true && (
                  <div className="activate-esim-aleart success center-text">
                    <SuccessIcon /> Get excited! Your {this.state.deviceName} is
                    compatible with eSIMs!
                  </div>
                )}
              <div className="activate-sim-col-divider ">Or</div>
              <div className="form-group">
                <label className="label-text mb-3">
                  Quick check by brand and model
                </label>
                <div className="form-group material-textfield">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    name="brand"
                    value={brand}
                    onChange={(e) => {
                      this.handleChangeBrand(e);
                    }}
                    required
                    onFocus={() => {
                      this.setState({ isCheckCoverage: true });
                      this.setState({ isCheckDeviceCompatibility: true });
                      this.setState({ isSelectPlan: false });
                      this.setState({ isInstalleSIM: false });
                      this.setState({
                        deviceApiError: '',
                        zipCodeApiError: '',
                      });
                    }}
                    className="search-with-select"
                    showArrow={false}
                    dropdownClassName="search-with-select-overlay"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">Select</Option>
                    {brandsArray &&
                      brandsArray.map((data, i) => {
                        return (
                          <Option key={i} value={_.get(data, 'name', '')}>
                            {_.get(data, 'name', '')}
                          </Option>
                        );
                      })}
                  </Select>
                  <label className="material-textfield-label search-with-select-label">
                    Brand{' '}
                  </label>
                </div>
                <div className="form-group material-textfield">
                  <select
                    className="form-control form-control-lg custom-select material-textfield-input"
                    name="model"
                    value={this.state.model}
                    onChange={(e) => {
                      this.handleChangeModel(e);
                    }}
                    required
                  >
                    <option value="select">Select</option>
                    {this.state.modelArray &&
                      this.state.modelArray.map((data, i) => {
                        return (
                          <option key={i} value={_.get(data, 'name', '')}>
                            {_.get(data, 'name', '')}
                          </option>
                        );
                      })}
                  </select>
                  <label className="material-textfield-label">Model </label>
                </div>
                <button
                  type="button"
                  className={
                    this.state.model !== '' && this.state.brand !== ''
                      ? 'btn btn-dark btn-lg w-100'
                      : 'btn btn-gray btn-lg w-100'
                  }
                  onClick={this.submitDeviceDetail}
                  disabled={
                    !(this.state.model !== '' && this.state.brand !== '') ||
                    this.state.brandLoading
                  }
                >
                  {this.state.brandLoading
                    ? 'Please wait...'
                    : 'Check Device Compatibility'}
                </button>
              </div>
              {!this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.isCheckByBrandModal === true && (
                  <div className="card">
                    <div className="card-body">
                      <div className="activate-esim-aleart error">
                        <span>
                          <ErrorIcon />
                        </span>{' '}
                        <span>
                          {this.state.deviceName} is not compatible with eSIM.
                          You may proceed however with activating an eSIM for
                          this device or cancel and activate a physical SIM.
                        </span>
                      </div>
                      <div className="esim-activation-title">
                        Are you sure you want to continue with eSIM activation?
                      </div>
                      <button
                        type="button"
                        className="btn btn-dark btn-lg w-200 mr-3"
                        onClick={this.handleErrorBox}
                      >
                        Yes, Proceed
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark btn-lg w-200"
                        onClick={() => {
                          this.setState({
                            activateEsimModal: false,
                            eSIMCompatible: true,
                            deviceName: '',
                            imeiNumber: '',
                            brand: '',
                            model: '',
                            swapESimError: '',
                            zipCodeSuccess: '',
                            zipcodeLoding: '',
                            zipcode: '',
                          });
                          this.props.history.push(`/available-esims`);
                        }}
                      >
                        No, Cancel
                      </button>
                    </div>
                  </div>
                )}
              {this.state.eSIMCompatible &&
                this.state.deviceName !== '' &&
                this.state.proceed === false &&
                this.state.isCheckByBrandModal === true && (
                  <div className="activate-esim-aleart success center-text">
                    <SuccessIcon /> Get excited! Your {this.state.deviceName} is
                    compatible with eSIMs!
                  </div>
                )}
              <div className="form-group mb-4">
                <button
                  type="button"
                  className={
                    !(this.state.eSIMCompatible && this.state.deviceName)
                      ? 'btn btn-gray btn-lg w-100'
                      : 'btn btn-purple btn-lg w-100'
                  }
                  disabled={
                    !(this.state.eSIMCompatible && this.state.deviceName)
                  }
                  onClick={() => {
                    this.state.selectedAction === 'activateEsimbyID'
                      ? this.selectActionHandle('activateEsimbyID')
                      : this.selectActionHandle('portIn');
                    this.closeModal();
                  }}
                >
                  {this.state.selectedAction === 'activateEsimbyID'
                    ? 'Continue to Activate eSIM'
                    : 'Continue to PortIn'}
                </button>
                <div className="invalid-feedback">
                  {this.state.swapESimError}
                </div>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Esim port-In Fails modal */}
        <ReactModal
          isOpen={this.state.portInFailsPopup}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Esim Port-In Fails"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Esim Port-In Fails
              </h5>
              <p className="react-modal-subtitle mt-3">
                Port-in failed due to {this.state.errorMessage}. This eSIM will
                no longer be available to activate. Please start a new
                activation with port-in to try again.
              </p>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => {
                  this.closeModal();
                  this.setState({ errorMessage: '' });
                }}
              >
                <CloseIcon />
              </button>
              <div className="text-right mt-3">
                <button
                  className="btn btn-dark w-150"
                  onClick={() => {
                    this.closeModal();
                    this.setState({ errorMessage: '' });
                  }}
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.isActivateEsimModal}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h3 className="esim-heading">eSIM Installation Instructions</h3>

              <div className="convert-esim-modal-wrapper">
                <div className="install-by-qr-code is-background">
                  <div className="install-by-qr-code-header">
                    <h5 className="install-by-qr-code-title mb-3">
                      Install by eSIM by scanning code
                    </h5>
                    <button className="btn btn-recommended">Recommended</button>
                  </div>
                  <div className="install-by-qr-code-body text-center">
                    {this.state.eSimDetail.QRActivationCode && (
                      <QR value={this.state.eSimDetail.QRActivationCode} />
                    )}
                  </div>
                </div>
                <div className="activate-sim-col-divider ">Or</div>
                <div className="card mb-0 manually-install-esim">
                  <div className="manually-install-esim-header">
                    <h5 className="manually-install-esim-title">
                      Manually install eSIM
                    </h5>
                  </div>
                  <div className="manually-install-esim-body">
                    <ul className="manually-install-esim-list">
                      <li>
                        <h4>SM-DP+ Address</h4>
                        <p>{this.state.eSimDetail.SMDPAddress}</p>
                      </li>
                      <li>
                        <h4>Activation code</h4>
                        <p>{this.state.eSimDetail.ActivationCode}</p>
                      </li>
                      <li>
                        <h4>eSim QR Code</h4>
                        <p>{this.state.eSimDetail.QRActivationCode}</p>
                      </li>
                      <li>
                        <h4>MSISDN</h4>
                        <p>
                          {this.formatPhoneNumber(
                            _.get(this.state.eSimDetail, 'MSISDN', '-')
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                onClick={(e) =>
                  handleEsimPdfDownload(this.state.eSimDetail.ICCID, this.props.downloadEsimPDF, this.props.generatePDF)
                }
                className="e-sim-donwload convert-esim-modal-wrapper"
              >
                <span className="download-pdf download-pdf-sm">
                  <DownloadIcon />
                  Download PDF
                </span>
              </div>
              <div className="download-pdf-wrapper">
                <button
                  type="button"
                  className={'btn btn-dark btn-lg w-100 mb-4'}
                  onClick={() => {
                    this.setState({
                      isActivateEsimModal: false,
                      newZipcode: '',
                      activateEsimZip: '',
                    });
                    this.closeModal();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
