import { useMutation, useQueryClient } from '@tanstack/react-query';
import iotApi from '../../api/iotApi.client';
import { SimQueryKey } from './sim.hooks.keys';

export function useChangePhoneNumber() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      simId: number;
      zipcode: string;
      ultraUserId: string;
    }) => {
      const res = await iotApi.sim.changePhoneNumber(
        {
          id: data.simId,
          zipcode: data.zipcode,
        },
        data.ultraUserId
      );

      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SimQueryKey.SIM_CARD_DETAIL]);
    },
  });
}
