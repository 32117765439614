import React, { useEffect, useState } from 'react';

const InternationalLongDistance = ({
  searchText,
  fetchInternationalRates,
  ...props
}) => {
  const [rates, setRates] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        value: { data },
      } = await fetchInternationalRates({ searchText });
      setRates(data);
    })();
  }, [fetchInternationalRates, searchText]);

  return (
    <div className="table-responsive custom-table-shadow pb-3">
      <table className="table custom-table">
        <thead>
          <tr>
            <th>
              <span className="table-column-title cursor-poniter">
                Country Code
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Destination
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Mobile Rate
              </span>
            </th>
            <th>
              <span className="table-column-title cursor-poniter">
                Landline Rate
              </span>
            </th>
            {/* <th>
							<span className="table-column-title cursor-poniter">Speciality Services Rate (ie. $0.10/min)</span>
						</th>
						<th>
							<span className="table-column-title cursor-poniter">ILD Bundle Name (ie. Start unlimited)</span>
						</th> */}
          </tr>
        </thead>
        <tbody>
          {rates.map((rate) => {
            return (
              <tr key={rate.id}>
                <td>{rate.countryCode}</td>
                <td>{rate.destination}</td>
                <td>
                  {rate.mobileRate === '' || rate.mobileRate === 'Not Available'
                    ? 'Not Available'
                    : `$${rate.mobileRate}/per min`}
                </td>
                <td>
                  {rate.landlineRate === '' ||
                  rate.landlineRate === 'Not Available'
                    ? 'Not Available'
                    : `$${rate.landlineRate}/per min`}
                </td>
                {/* <td>${rate.ssRate}/per MB</td>
								<td>${rate.bundleName}/per MB</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default InternationalLongDistance;
