import { connect } from 'react-redux';

import { fetchMe, setUser, userImpersonate } from '../../../store/user/duck';
import {
  fetchSPRRecentActivated,
  getReport,
} from '../../../store/dashboard/duck';

import RecentlyActivatedSimsComponent from './component';

const RecentlyActivatedSimsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchSPRRecentActivated,
    getReport,
    userImpersonate,
  }
)(RecentlyActivatedSimsComponent);

export default RecentlyActivatedSimsContainer;
