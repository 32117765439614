/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import TopNavigation from '../../TopNavigation/container';
import SidebarNavigation from '../../SidebarNavigation/component';
import { Spin, Pagination } from 'antd';
import _ from 'lodash';
import { BackArrowIcon } from '../../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import './styles.scss';

export default class SmsUsageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderBySms: [],
      limit: 50,
      page: 1,
      countSmsUsage: 0,
      usaseLoading: true,
    };
  }

  componentDidMount = () => {
    document.title = 'Top Sms Usage SIMs List | UltraIoT';
    this.getSimsByMaxUsage();
  };
  onPageChanged = (page) => {
    this.setState({ page, usaseLoading: true }, () => {
      window.scrollTo(0, 0);
      this.getSimsByMaxUsage();
    });
  };
  getSimsByMaxUsage = async () => {
    const simType = localStorage.getItem('simType');
    const { limit, page } = this.state;
    try {
      // Getting Max Usase
      const {
        value: { success, data },
      } = await this.props.fetchSimsByMaxUsage({
        simType,
        type: 'sms',
        limit,
        page,
      });
      if (success) {
        const { orderBySms, countSmsUsage } = data;
        this.setState(
          {
            orderBySms,
            countSmsUsage,
          },
          () => {
            this.setState({ usaseLoading: false });
          }
        );
      }
    } catch (error) {
      // console.log(error)
    }
  };

  render() {
    const { orderBySms, usaseLoading, limit, page, countSmsUsage } = this.state;

    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-4">
              <button
                className="btn btn-back"
                onClick={(e) => this.props.history.push('/dashboard')}
              >
                <BackArrowIcon className="back-icon" /> Back to Dashboard
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Spin
                spinning={usaseLoading}
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: '#000' }}
                    spin
                  />
                }
              >
                {orderBySms && orderBySms.length > 0 ? (
                  <>
                    <div className="card mb-3">
                      <div className="card-header d-flex align-items-center">
                        <div className="">
                          <h5 className="card-title">Current billing cylce</h5>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table-secondary table-nowidth">
                          <thead>
                            <tr>
                              <th>
                                <span className="table-column-title">
                                  ICCID
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  MSISDN
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  SIM Type
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  Usage
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderBySms.map((sim, inx) => {
                              return (
                                <tr key={inx} class="cursor-poniter">
                                  <td
                                    className="text-underline"
                                    onClick={() => {
                                      this.props.history.push(
                                        `/detail/${sim.simnumber}`
                                      );
                                    }}
                                  >
                                    {sim.simnumber}
                                  </td>
                                  <td>
                                    {_.get(sim, 'msisdn', '') &&
                                    _.get(sim, 'msisdn', '') !== ''
                                      ? _.get(sim, 'msisdn', '')
                                      : '-'}
                                  </td>
                                  <td>{sim.offername}</td>
                                  <td>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={'text'}
                                      value={sim.sms_used}
                                    />{' '}
                                    sms
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={this.onPageChanged}
                      current={page}
                      pageSize={limit}
                      defaultCurrent={page}
                      total={countSmsUsage}
                      showSizeChanger={false}
                    />
                  </>
                ) : (
                  <div className="blank__table__card">
                    <div className="blank__table__card__inner">
                      No record found
                    </div>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
