import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { formatMegaBytes } from '../../constants/common';
//import NumberFormat from "react-number-format";
import './styles.scss';

function PooledPlanSummaryUsage(props) {
  document.title = 'Pooled Plans Usage Summary | UltraIoT';
  const [usageDetails, setusageDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const countPerPage = 30;

  useEffect(() => {
    setLoading(true);
    const getAverageUsagePlan = async (pge) => {
      const simType = localStorage.getItem('simType');
      try {
        const body = {
          type: 'pooled',
          simType,
          limit: countPerPage,
          page: pge,
        };
        const {
          value: { success, data },
        } = await props.fetchAverageUsagePlan(body);
        if (success) {
          setusageDetails(data);
          setLoading(false);
        }
      } catch (error) {
        // console.log(error)
        setusageDetails([]);
      }
    };

    getAverageUsagePlan(page);
  }, [page, props]);

  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.push('/dashboard')}
            >
              <BackArrowIcon className="back-icon" /> Back to Dashboard
            </button>
          </div>
          <div className="col-md-8 text-right"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {usageDetails && usageDetails.length > 0 ? (
                <>
                  <div className="card mb-3">
                    <div className="table-responsive">
                      <table className="table custom-table-secondary table-nowidth average-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">
                                Pool Plans Summary
                              </span>
                            </th>
                            <th colSpan="4">
                              <span className="table-column-title">
                                Rate Plan ID
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {usageDetails.map((usage, inx) => {
                            return (
                              <tr key={inx}>
                                <td>
                                  <div className="average-title btnlinkorange">
                                    {usage.offername}
                                  </div>
                                </td>
                                <td>
                                  <div className="average-title">
                                    {usage.offerid || usage.productid}
                                  </div>
                                </td>
                                <td>
                                  <div className="average-data-top data-current ">
                                    {usage.subs}
                                  </div>
                                  <div className="average-data-top">
                                    # of sims
                                  </div>
                                </td>
                                <td>
                                  <div className="average-data-top data-current ">
                                    {usage.prod_data_allotment}
                                  </div>
                                  <div className="average-data-top">
                                    Total bucket size (MB)
                                  </div>
                                </td>
                                <td>
                                  <div className="average-data-top data-current ">
                                    {usage.data_usage &&
                                      formatMegaBytes(usage.data_usage, 2)}{' '}
                                  </div>
                                  <div className="average-data-top">
                                    Total Data
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={countPerPage}
                      defaultCurrent={page}
                      total={0}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  <div className="blank__table__card__inner">
                    No record found
                  </div>
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PooledPlanSummaryUsage;
