import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import { getAllOrders, getSims, setCount } from '../../store/sim/duck';
import OrdersComponent from './component';

const OrdersContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    simCounts: state.sim.counts,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getAllOrders,
    getSims,
    setCount,
  }
)(OrdersComponent);

export default OrdersContainer;
