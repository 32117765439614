import { connect } from 'react-redux';
import {
  fetchMe,
  setUser,
  getUsers,
  fetchUserPlans,
  updatePlans,
  fetchScope,
} from '../../store/user/duck';
import { fetchSPRPlans } from '../../store/plan/duck';
import SelectPlanComponent from './component';

const SelectPlanContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getUsers,
    fetchSPRPlans,
    fetchUserPlans,
    updatePlans,
    fetchScope,
  }
)(SelectPlanComponent);

export default SelectPlanContainer;
