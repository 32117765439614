import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import {
  fetchDashboardStats,
  fetchSimsByMaxUsage,
  fetchPooledStats
} from '../../store/dashboard/duck';
import FixedDashboardComponent from './component';

const FixedDashboardContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchDashboardStats,
    fetchSimsByMaxUsage,
    fetchPooledStats
  }
)(FixedDashboardComponent);

export default FixedDashboardContainer;
