/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import InputMask from 'react-text-mask';
import { Formik, ErrorMessage } from 'formik';
import { message } from 'antd';
import { profileSchema, userProfileSchema } from '../../../utils/validations';
import PlacesAutocomplete from '../../../components/PlaceSearchBox';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.scss';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class MyProfileComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        phone: '',
        companyname: '',
        addressline1: '',
        addressline2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '1',
      },
      isEmailExists: false,
      isPhoneExists: false,
      isCompnayNameExists: false,
      address: '',
      id: '',
      role: '',
      errorMessage: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Profile | UltraIoT';
    this.fetchUser();
  };

  success = (msg) => {
    message.success({ key: 'updatable', content: msg });
  };

  error = (msg) => {
    message.error({ key: 'updatable', content: msg });
  };

  fetchUser = async () => {
    try {
      const {
        value: { user },
      } = await this.props.fetchUser();
      if (user) {
        this.setState({
          initialValues: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone:
              user.phone && user.phone !== ''
                ? this.formatPhoneNumber(user.phone)
                : '',
            companyname: user.companyname,
            addressline1: user.addressline1,
            addressline2: user.addressline2,
            city: user.city,
            state: user.state,
            address: user.addressline1,
            zipcode: user.zipcode,
            countryCode:
              _.get(user, 'countryCode', '') !== ''
                ? _.get(user, 'countryCode', '1')
                : '1',
          },
          address: user.addressline1,
          role: user.role,
          id: user.id,
        });
      }
    } catch (error) {
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = '';
    if (phoneNumberString.length === 8) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
    } else if (phoneNumberString.length === 9) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    } else {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    }
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  handleSubmit = async (
    { phone, address, ...rest },
    { setSubmitting, setErrors }
  ) => {
    try {
      const { id, role } = this.state;
      setSubmitting(true);
      const payload = {
        id,
        role,
        ...rest,
        phone: phone.replace(/[^\d]/g, ''),
      };
      payload.addressline1 = address ? address : payload.addressline1;
      this.setState({ errorMessage: '' });
      const {
        value: { success },
      } = await this.props.updateProfile(payload);
      if (success) {
        this.success('Your changes have been saved!');
        this.fetchUser();
      }
    } catch (err) {
      const { message } = err;
      setSubmitting(false);
      if (_.get(err, 'duplicateKey', '') === 'email') {
        this.setState({ isEmailExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'phone') {
        this.setState({ isPhoneExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'companyname') {
        this.setState({ isCompnayNameExists: true });
      } else {
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    const {
      initialValues,
      errorMessage,
      role,
      isEmailExists,
      isPhoneExists,
      isCompnayNameExists,
    } = this.state;
    return (
      role && (
        <div className="card-container">
          <div className="card-wrapper">
            <div className="card mb-0">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  role !== 'user' ? profileSchema : userProfileSchema
                }
                onSubmit={(values, formikProps) =>
                  this.handleSubmit(values, formikProps)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setValues,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="firstname"
                              value={(values && values.firstname) || ''}
                              onChange={handleChange}
                              required
                            />
                            <label className="material-textfield-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="firstname"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="lastname"
                              value={(values && values.lastname) || ''}
                              onChange={handleChange}
                              required
                            />
                            <label className="material-textfield-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="lastname"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <PhoneInput
                            inputProps={{
                              readOnly: true,
                              name: 'phone',
                              required: true,
                              autoFocus: true,
                              className:
                                'form-control form-control-lg material-textfield-input',
                            }}
                            country={'us'}
                            value={(values && values.countryCode) || ''}
                            onChange={(phone, val) =>
                              this.setState({
                                initialValues: {
                                  ...this.state.initialValues,
                                  countryCode: val.dialCode,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="form-group material-textfield">
                            <InputMask
                              guide={false}
                              type="text"
                              id="phone"
                              keepCharPositions={false}
                              mask={phoneNumberMask}
                              className="form-control form-control-lg material-textfield-input"
                              name="phone"
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  errorMessage: '',
                                  isPhoneExists: false,
                                });
                              }}
                              value={(values && values.phone) || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Phone Number{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="phone"
                              className="invalid-feedback d-block"
                            />
                            {isPhoneExists && (
                              <div>
                                <div className="invalid-feedback">
                                  Phone number already in use.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="email"
                              value={(values && values.email) || ''}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  errorMessage: '',
                                  isEmailExists: false,
                                  email: e.target.value,
                                });
                              }}
                              required
                            />
                            <label className="material-textfield-label">
                              Email Address{' '}
                              <span className="text-danger">*</span>{' '}
                            </label>
                            <ErrorMessage
                              component="span"
                              name="email"
                              className="invalid-feedback d-block"
                            />
                            {isEmailExists && (
                              <div>
                                <div className="invalid-feedback">
                                  Email already in use.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {role !== 'user' && (
                        <>
                          {localStorage.getItem('i_Authorization') === null &&
                          role === 'spr_partner' ? (
                            <></>
                          ) : (
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="companyname"
                                value={(values && values.companyname) || ''}
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isCompnayNameExists: false,
                                    companyname: e.target.value.trim(),
                                  });
                                }}
                                required
                              />
                              <label className="material-textfield-label">
                                Company Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <ErrorMessage
                                component="span"
                                name="companyname"
                                className="invalid-feedback d-block"
                              />
                              {isCompnayNameExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Company name already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group material-textfield">
                                <PlacesAutocomplete
                                  type="text"
                                  name="addressline1"
                                  value={
                                    (values &&
                                      (values.addressline1 ||
                                        values.address)) ||
                                    ''
                                  }
                                  onChange={handleChange}
                                  className="form-control form-control-lg material-textfield-input"
                                  handleAddressChange={({
                                    address,
                                    city,
                                    state,
                                    zip,
                                  }) =>
                                    setValues({
                                      ...values,
                                      addressline1: address,
                                      city,
                                      state,
                                      zipcode: zip,
                                    })
                                  }
                                  setValue={(address) =>
                                    this.setState({ address }, () => {
                                      setValues({ ...values, address });
                                    })
                                  }
                                  address={values.address}
                                  isRequired={true}
                                />
                                <ErrorMessage
                                  component="span"
                                  name="address"
                                  className="invalid-feedback d-block"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="addressline2"
                                  value={(values && values.addressline2) || ''}
                                  onChange={handleChange}
                                  required
                                />
                                <label className="material-textfield-label">
                                  Address Line 2
                                </label>
                                <ErrorMessage
                                  component="span"
                                  name="addressline2"
                                  className="invalid-feedback d-block"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="city"
                                  onChange={handleChange}
                                  value={(values && values.city) || ''}
                                  required
                                />
                                <label className="material-textfield-label">
                                  City <span className="text-danger">*</span>
                                </label>
                                <ErrorMessage
                                  component="span"
                                  name="city"
                                  className="invalid-feedback d-block"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="state"
                                  onChange={handleChange}
                                  value={(values && values.state) || ''}
                                  required
                                />
                                <label className="material-textfield-label">
                                  State <span className="text-danger">*</span>
                                </label>
                                <ErrorMessage
                                  component="span"
                                  name="state"
                                  className="invalid-feedback d-block"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="zipcode"
                                  onChange={handleChange}
                                  value={(values && values.zipcode) || ''}
                                  required
                                />
                                <label className="material-textfield-label">
                                  Zip <span className="text-danger">*</span>
                                </label>
                                <ErrorMessage
                                  component="span"
                                  name="zipcode"
                                  className="invalid-feedback d-block"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="invalid-feedback d-block text-center mb-3">
                        {errorMessage}
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmitting ? 'Please wait...' : 'Save'}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )
    );
  }
}
