import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DataIcon, SmsIcon, VoiceIcon } from '../../../components/icons';
import moment from 'moment';
import Chart from 'react-google-charts';
import { checkPermission, formatMegaBytes } from '../../../constants/common';
import { getDataUsageFromUltra } from '../../../store/sim/api';

export default function UsageLogs({
  simDetailData,
  user,
  usage,
  planCycle,
  totalMBUsed,
  usageDataArray,
}) {
  const [dataUsageFromUltra, setDataUsageFromUltra] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const displayUsage = checkPermission(user, '/usage-logs');
  const planEndDate = moment(_.get(planCycle, 'endOfCycle', moment()));
  const today = moment();
  let planLeftDays = planEndDate.diff(today, 'days');
  const dataUsasePercentage =
    (Number(_.get(usage, 'usedData', 0)) /
      (Number(_.get(usage, 'usedData', 0)) +
        Number(_.get(usage, 'remaining4G', 0)))) *
    100;

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getDataUsageFromUltra({
          iccid: simDetailData?.simnumber,
          brandName: user.brandName,
          simType: simDetailData.simtype,
        });
        if (result?.dataUsageFromUltra) {
          setDataUsageFromUltra(result.dataUsageFromUltra);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getData();
  }, [simDetailData, user]);

  return (
    <div className="row">
      <div className="col-md-12">
        {true
          ? simDetailData &&
            displayUsage &&
            simDetailData.status !== 9 &&
            simDetailData.status !== 11 && (
              <Spin
                spinning={isLoading}
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: '#000' }}
                    spin
                  />
                }
              >
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div>
                      <h5 className="card-title">Usage Logs </h5>
                      <p className="card-subtitle mt-1">
                        Usage data is indicative, it can take up to 7 days for
                        complete data to be reflected
                      </p>
                    </div>
                    <div className="ml-auto"></div>
                  </div>

                  <div className="usage-log-row">
                    <div className="usage-log-col">
                      <div className="usage-log-header">
                        <div className="d-flex align-items-center">
                          <DataIcon />
                          <h4 className="title ml-3">Data</h4>
                        </div>
                        <div className="usage-info-row">
                          <div className="usage-info-col">
                            {_.get(dataUsageFromUltra, 'data_usage', 0) > 0 ? (
                              <>
                                <h4>
                                  {formatMegaBytes(
                                    _.get(dataUsageFromUltra, 'data_usage', 0),
                                    2
                                  )}
                                </h4>
                                <p>used this period</p>
                              </>
                            ) : (
                              <>
                                <h4>0 mb</h4>
                                <p>used this period</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {simDetailData.simtype !== 'fixed' && (
                      <div className="usage-log-col">
                        <div className="usage-log-header">
                          <div className="d-flex align-items-center">
                            <VoiceIcon />
                            <h4 className="title ml-3">Voice</h4>
                          </div>
                          <div className="usage-info-row">
                            <div className="usage-info-col">
                              {
                                <>
                                  <h4>
                                    {_.get(
                                      dataUsageFromUltra,
                                      'voice_usage',
                                      0
                                    )}{' '}
                                    mins
                                  </h4>
                                  <p>used this period</p>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {simDetailData.simtype !== 'fixed' && (
                      <div className="usage-log-col">
                        <div className="usage-log-header">
                          <div className="d-flex align-items-center">
                            <SmsIcon />
                            <h4 className="title ml-3">SMS</h4>
                          </div>
                          <div className="usage-info-row">
                            <div className="usage-info-col">
                              {
                                <>
                                  <h4>
                                    {_.get(dataUsageFromUltra, 'sms_usage', 0)}{' '}
                                    sms
                                  </h4>
                                  <p>used this period</p>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* </Spin> */}
                  </div>
                  {totalMBUsed > 0 && (
                    <div className="usage-log-body">
                      <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="LineChart"
                        loader={<div></div>}
                        data={usageDataArray}
                        options={{
                          colors: ['#673594'],
                          hAxis: {
                            title: 'Days',
                          },
                          vAxis: {
                            title: 'Data Usage (In MB)',
                          },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                      />
                      {_.get(usage, 'usedData', '') !== '' && (
                        <div className="progress period-progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${String(dataUsasePercentage)}%`,
                            }}
                            aria-valuenow={String(dataUsasePercentage)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      )}
                      <div className="text-center">
                        <button className="btn btn-dark btn-xs">
                          {planLeftDays} days left
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Spin>
            )
          : ''}
      </div>
    </div>
  );
}
