import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { SuccessIcon, CloseIcon } from '../../components/icons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import './styles.scss';

export default class SelectPlanComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      filteredPlans: [],
      selectAllPlans: 0,
      isPlanLoading: false,
      isLoading: false,
      userData: null,
      ererrorMessage: '',
      savePlanSuccessModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Select Plan(s)';
    this.setState({ isPlanLoading: true });
    const [
      {
        value: { success, user },
      },
      {
        value: { scope },
      },
    ] = await Promise.all([this.props.fetchMe(), this.props.fetchScope()]);
    if (success && user) {
      if (scope && scope !== '') {
        this.setState({ adminScope: scope });
      }
      this.props.setUser(user);
      this.setState({ userData: user });
      this.getPlansListing();
    }
  };

  getPlansListing = async () => {
    try {
      const {
        value: { success, plans },
      } = await this.props.fetchUserPlans(this.props.location.state.userId);
      if (success) {
        this.setState(
          {
            plans: plans,
            filteredPlans: plans,
            isPlanLoading: false,
            isLoading: false,
          },
          () => {
            this.selectAllPlanCheckBox(plans);
          }
        );
      }
    } catch (error) {
      this.setState({ isPlanLoading: false, plans: [], isLoading: false });
    }
  };

  searchPlans = (event) => {
    const { plans } = this.state;
    let searchText = event.target.value.trim().toLowerCase();
    const filterData = plans.filter((item, key) =>
      item.title.toLowerCase().includes(searchText)
    );
    if (event.target.value === '') {
      this.setState({ filteredPlans: plans });
    } else {
      this.setState({ filteredPlans: filterData });
    }
  };

  selectAllPlanCheckBox = (plans) => {
    if (plans && plans.length > 0) {
      let all = 1;
      _.forEach(plans, function (plan) {
        if (plan.status === 0) {
          all = 0;
        }
      });
      this.setState({ selectAllPlans: all });
    }
  };

  selectPlan(plan) {
    const plans = _.cloneDeep(this.state.plans);
    const index = _.findIndex(plans, function (p) {
      return String(p.id) === String(plan.id);
    });
    if (index !== -1) {
      plans[index].status = parseInt(plans[index].status) === 10 ? 0 : 10;
    }
    this.selectAllPlanCheckBox(plans);
    this.setState({
      plans,
    });
  }

  updatePlans = async () => {
    const { plans } = this.state;
    const id = this.props.location.state.userId;
    const planIds = [];
    _.forEach(plans, function (p) {
      if (parseInt(p.status) === 10) {
        planIds.push(p.id);
      }
    });
    if (_.size(planIds) > 0) {
      this.setState({ errorMessage: '', isLoading: true });
      const payload = {
        userid: id,
        planids: planIds,
      };

      const {
        value: { success },
      } = await this.props.updatePlans(payload);
      if (success) {
        // this.getPlansListing()
        // this.setState({ isLoading: false, errorMessage:'' }, (e) => {
        //   this.handleCloseIcon(e)
        // });
        this.setState({
          isLoading: false,
          errorMessage: '',
          savePlanSuccessModal: true,
        });
        // message.success({ key: 'updatable', content: 'Changes saved successfully.' })
      }
    } else {
      this.setState({
        errorMessage: 'Please select atleast 1 plan from the list.',
      });
    }
  };

  handleAllPlansChecked = (event) => {
    let selectAllPlans = event.target.checked;
    let allPlans = this.state.plans;
    _.forEach(allPlans, function (value) {
      if (selectAllPlans === true) value.status = 10;
      else value.status = 0;
    });
    this.setState({ selectAllPlans: selectAllPlans, filteredPlans: allPlans });
  };

  handleCloseIcon = (event) => {
    let activeUsersTab = this.props.location.state.activeUsersTab;
    let usersPageNumber = _.get(this.props.location, 'state.pageNumber', 1);
    if (activeUsersTab === 'all') {
      this.props.history.push({
        pathname: '/all-users',
        state: { pageNumber: usersPageNumber },
      });
    } else {
      this.props.history.push({
        pathname: '/partners',
        state: { pageNumber: usersPageNumber },
      });
    }
  };

  closeModal() {
    this.setState({
      savePlanSuccessModal: false,
    });
  }

  render() {
    const {
      plans,
      filteredPlans,
      isPlanLoading,
      selectAllPlans,
      isLoading,
      errorMessage,
    } = this.state;

    return (
      <>
        <div className="head-section-header d-flex align-items-center">
          <div className="head-section-title">Select Plan(s)</div>

          <ReactModal
            isOpen={this.state.savePlanSuccessModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            contentLabel="Success"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="connection-message-header success">
                <div>
                  <SuccessIcon />
                </div>
                <h6>Success</h6>
              </div>
              <div className="react-modal-body">
                <div className="connection-message form-group">
                  <p>
                    <b> Changes saved successfully.</b>
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn btn-purple btn-lg w-100"
                  onClick={this.closeModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </ReactModal>
          <div className="head-section-cta">
            <button
              className="btn btn-link btn-back"
              onClick={(e) => this.handleCloseIcon()}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <section className="section-select-plan">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="select-plan-container">
                  <div className="search-form  form-group">
                    <input
                      className="form-control form-control-lg"
                      placeholder="Search Plans..."
                      onChange={this.searchPlans}
                    />
                  </div>
                  {isPlanLoading ? (
                    <div className="loadingCenter">
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: '#000' }}
                            spin
                          />
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <ul className="select-check-list form-group select-check-list-scroll">
                        <li>
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              className="custom-checkbox--input"
                              type="checkbox"
                              checked={selectAllPlans}
                              onChange={(event) =>
                                this.handleAllPlansChecked(event)
                              }
                            />
                            Select All
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        {_.size(filteredPlans) > 0 &&
                          filteredPlans.map((plan, index) => {
                            const _plan = _.find(plans, function (p) {
                              return String(p.id) === String(plan.id);
                            });
                            return (
                              <li key={plan.id}>
                                <label className="custom-checkbox custom-checkbox-line">
                                  <input
                                    className="custom-checkbox--input"
                                    type="checkbox"
                                    onChange={() => this.selectPlan(plan)}
                                    checked={_plan.status === 10 ? true : false}
                                  />
                                  {plan.title}
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  )}
                  <div className="invalid-feedback d-block text-center mb-3">
                    {errorMessage}
                  </div>
                  <button
                    disabled={isLoading}
                    onClick={(e) => this.updatePlans()}
                    className="btn btn-dark btn-lg w-100"
                  >
                    {isLoading ? 'Please wait ...' : 'Save Plan(s)'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
