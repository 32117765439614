import _ from 'lodash';
const numberFormat = (number) => {
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

function formatMegaBytes(mbs, decimals = 2) {
  if (mbs === 0) return '0 MB';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(mbs) / Math.log(k));

  return parseFloat((mbs / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const checkPermission = (user, checkurl) => {
  let isPermit = true;
  var permissions = [];
  if (user && _.get(user, 'role', '') === 'sub_user') {
    permissions = _.get(user, 'permissions', '');
    if (permissions !== '') {
      permissions = JSON.parse(permissions);
      isPermit = _.findIndex(permissions, function (per) {
        return per.url === checkurl;
      });
    }
  }
  return isPermit === -1 ? false : true;
};

const isAdmin = (user) => {
  let bool = false;
  if (
    user &&
    _.includes(['superadmin', 'admin', 'sysops'], _.get(user, 'role', ''))
  ) {
    bool = true;
  }
  return bool;
};

const isSysOpsUser = (user) => {
  let bool = false;
  if (user && _.includes(['sysops'], _.get(user, 'role', ''))) {
    bool = true;
  }
  return bool;
};

const isSPRPartner = (user) => {
  let bool = false;
  if (user && _.includes(['spr_partner'], _.get(user, 'role', ''))) {
    bool = true;
  }
  return bool;
};

const isSubUser = (user) => {
  let bool = false;
  if (user && _.includes(['sub_user'], _.get(user, 'role', ''))) {
    bool = true;
  }
  return bool;
};

const getZipCodeSuccessMessage = async (data) => {
  if (data.result === 'NO COVERAGE') {
    return 'No Coverage Available.';
  } else if (data.result === '3G FAIR') {
    return 'Fair 3G Coverage Available.';
  } else if (data.result === '3G GOOD') {
    return 'Good 3G Coverage Available.';
  }
  if (data.result5G) {
    if (data.result === '4G FAIR') {
      return 'Fair 4G/5G Coverage Available.';
    } else if (data.result === '4G GOOD') {
      return 'Good 4G/5G Coverage Available.';
    } else if (data.result === '4G EXCELLENT') {
      return 'Excellent 4G/5G Coverage Available.';
    }
  } else {
    if (data.result === '4G FAIR') {
      return 'Fair 4G Coverage Available.';
    } else if (data.result === '4G GOOD') {
      return 'Good 4G Coverage Available.';
    } else if (data.result === '4G EXCELLENT') {
      return 'Excellent 4G Coverage Available.';
    }
  }
};

export {
  numberFormat,
  formatMegaBytes,
  checkPermission,
  isAdmin,
  isSysOpsUser,
  isSPRPartner,
  isSubUser,
  getZipCodeSuccessMessage,
};
