import { fetch } from '../../utils';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const fetchSPRPlans = () => {
  return fetch(`${HOSTNAME}/api/v1/plan/getSprPlans`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchLoB = () => {
  return fetch(`${HOSTNAME}/api/v1/plan/getLoB`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchFixedPlans = () => {
  return fetch(`${HOSTNAME}/api/v1/plan/getFixedPlans`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchFixedPlansByUserId = () => {
  return fetch(`${HOSTNAME}/api/v1/plan/getFixedPlansByUserId`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
