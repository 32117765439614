import $fetch from '../../../fetch';
import { IOT_API_BASE_URL, parseResponseData } from '../../utils';
import simResponseSchemas from './sim.responses.schemas';

const BASE_SERVICE_URL = `${IOT_API_BASE_URL}/sim` as const;

export async function getSimCardDetail(params: { iccid: string }) {
  const { iccid } = params;

  const response = await $fetch(`${BASE_SERVICE_URL}/${iccid}/sim-card-detail`);

  const data = parseResponseData(response, simResponseSchemas.getSimCardDetail);

  return data;
}

export async function getActivePortInRequestData(params: { iccid: string }) {
  const { iccid } = params;

  const response = await $fetch(
    `${IOT_API_BASE_URL}/${iccid}/active-portin-req-data`
  );

  const data = parseResponseData(
    response,
    simResponseSchemas.getActivePortInRequestData
  );

  return data;
}

export async function getAccountNumber(params: { iccid: string }) {
  const { iccid } = params;

  const response = await $fetch(`${BASE_SERVICE_URL}/${iccid}/account-number`);

  const data = parseResponseData(response, simResponseSchemas.getAccountNumber);

  return data;
}

export async function changePhoneNumber(
  body: { id: number; zipcode: string },
  ultraUserId: string
) {
  const response = await $fetch(`${BASE_SERVICE_URL}/change-phone-number`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { entityId: ultraUserId },
  });

  const data = parseResponseData(
    response,
    simResponseSchemas.changePhoneNumber
  );

  return data;
}
