// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable';
import { assign } from 'lodash';
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
// import { get } from "lodash"

// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// ULTRA PHASE START
export const COVERAGE_DETAIL = 'ultra/sim/COVERAGE_DETAIL';
export const DEVICE_COMPATIBILITY = 'ultra/coverage/DEVICE_COMPATIBILITY';
export const GET_BRANDS = 'ultra/coverage/GET_BRANDS';
export const SET_COUNT = 'ultra/client/SET_COUNT';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  counts: 0,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SET_COUNT: {
      const { payload } = action;

      return state.set('counts', payload);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const coverageDetail = (data) => {
  return {
    type: COVERAGE_DETAIL,
    payload: api.getCoverageDetail(data),
  };
};

export const getDeviceCompatibility = (data) => {
  return {
    type: DEVICE_COMPATIBILITY,
    payload: api.getDeviceCompatibility(data),
  };
};

export const getBrands = () => {
  return {
    type: GET_BRANDS,
    payload: api.getBrands(),
  };
};

/***********************************
 * Epics
 ***********************************/

export const coverageEpic = combineEpics();
