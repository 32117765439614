import { connect } from 'react-redux';

import MaintenanceBannerComponent from './component';

const MaintenanceBannerContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(MaintenanceBannerComponent);

export default MaintenanceBannerContainer;
