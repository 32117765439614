/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Formik, ErrorMessage } from 'formik';
import PasswordStrengthStatus from '../../../components/PasswordStengthStatus';
import _ from 'lodash';
import './styles.scss';

export default class UpdatePasswordComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        password: '',
        confirmpassword: '',
      },
      userData: null,
      errorMessage: '',
      passwordStrength: {},
    };
  }

  componentDidMount = async () => {
    document.title = 'Update Password | UltraIoT';
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.setState({ userData: user });
      }
    } catch (err) {
      const error = err.data;
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }
  };

  validatePassword = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmpassword) {
      errors.confirmpassword = 'Passwords and Confirm password must match';
    }

    let obj = {};
    // Special Character
    if (new RegExp('[$@$!%*#?&]').test(values.password)) {
      obj.specialChar = true;
    }
    // Uppercase Alpabates
    if (new RegExp('[A-Z]').test(values.password)) {
      obj.upperChar = true;
    }
    // Numbers
    if (new RegExp('[0-9]').test(values.password)) {
      obj.numbers = true;
    }
    // Lowercase Alphabates
    if (new RegExp('[a-z]').test(values.password)) {
      obj.lowerChar = true;
    }
    // Length should be 8 char
    if (new RegExp('.{8,}').test(values.password)) {
      obj.charLength = true;
    }

    if (Object.keys(obj).length > 0 && Object.keys(obj).length < 5) {
      errors.password = 'Resolve all password pattern checks to proceed.';
    }

    this.setState({ passwordStrength: obj });
    return errors;
  };

  handleSubmit = async (
    { password, ...rest },
    { setSubmitting, setErrors }
  ) => {
    try {
      setSubmitting(true);
      const payload = {
        password,
      };
      this.setState({ errorMessage: '' });
      const {
        value: { success },
      } = await this.props.changePassword(payload);
      if (success) {
        this.props.history.push('/dashboard');
      }
    } catch (err) {
      const { message } = _.get(err, 'data', err);
      setSubmitting(false);
      this.setState({ errorMessage: message });
    }
  };

  render() {
    const { initialValues, errorMessage, passwordStrength } = this.state;
    return (
      <div className="card-container">
        <div className="card-wrapper">
          <div className="card mb-0">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={this.validatePassword}
              onSubmit={(values, formikProps) =>
                this.handleSubmit(values, formikProps)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="card-body">
                    <div className="form-group material-textfield">
                      <input
                        type="password"
                        className="form-control form-control-lg material-textfield-input"
                        name="password"
                        value={values && values.password}
                        onChange={handleChange}
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="password"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        New Password <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <input
                        type="password"
                        className="form-control form-control-lg material-textfield-input"
                        name="confirmpassword"
                        value={values && values.confirmpassword}
                        onChange={handleChange}
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="confirmpassword"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        Confirm New Password{' '}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <PasswordStrengthStatus
                      passwordStrength={passwordStrength}
                    />
                    <div className="form-group">
                      <span className="invalid-feedback d-block mb-10 text-center">
                        {errorMessage}
                      </span>
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-dark btn-lg w-100"
                    >
                      {isSubmitting ? 'Please wait...' : 'Update'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
