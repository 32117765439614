/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import _ from 'lodash';
import './styles.scss';
const Add_Sim_Format =
  window.REACT_APP_DOMAIN_CURRENT_SITE + '/sample_add_sims.csv';

let isnum = /^\d+$/;

export default class AddSimComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'upload',
      userData: null,
      csvData: [],
      pasteData: [],
      pasteLines: '',
      simAddError: {},
      sheetName: '',
      uploadLoading: false,
      markedSimList: [],
      errorString: '',
      errorUploadString: '',
      simsCount: {},
      simtype: 'spr',
    };
  }

  componentDidMount = async () => {
    document.title = 'Add Sims | UltraIoT';
    let selectedTab = '';
    let tab = _.get(this.props, 'location.pathname', 'upload');
    if (tab === '/add-sim/upload-csv' || tab === '/add-fixed-sim/upload-csv') {
      selectedTab = 'upload';
      document.title = 'Upload CSV | UltraIoT';
    } else if (
      tab === '/add-sim/copy-and-paste' ||
      tab === '/add-fixed-sim/copy-and-paste'
    ) {
      selectedTab = 'paste';
      document.title = 'Copy Paste | UltraIoT';
    }
    let simtype = 'spr';
    if (tab.indexOf('fixed') !== -1) {
      simtype = 'fixed';
    }
    const [
      {
        value: { success, user },
      },
      {
        value: { total },
      },
    ] = await Promise.all([
      this.props.fetchMe(),
      this.props.fetchSimCounts({ orderType: simtype }),
    ]);
    if (success && user) {
      this.props.setUser(user);
      this.setState({ userData: user });
    }
    if (total) {
      this.setState({ simsCount: total });
    }
    this.setState({ activeTab: selectedTab, simtype });
  };

  onTabChange = (tab) => {
    const { simtype } = this.state;
    this.setState({ activeTab: tab }, () => {
      const path1 =
        simtype === 'spr' ? '/add-sim/upload-csv' : '/add-fixed-sim/upload-csv';
      const path2 =
        simtype === 'spr'
          ? '/add-sim/copy-and-paste'
          : '/add-fixed-sim/copy-and-paste';
      if (tab === 'upload') {
        document.title = 'Upload CSV | UltraIoT';
        this.props.history.push(path1);
      } else if (tab === 'paste') {
        document.title = 'Copy Paste | UltraIoT';
        this.props.history.push(path2);
      }
    });
  };

  downloadTemplate = () => {
    window.open(Add_Sim_Format, '_blank');
  };

  // Convert JSON Format
  csvJSON = (csv) => {
    let lines = csv.split('\n');
    let allData = [];
    _.forEach(lines, (line, index) => {
      line = line.trim().replace(/\s+/g, '');
      if (line !== '') {
        let obj = {
          iccid: '',
          zipcode: '',
          simname: '',
          tags: '',
        };
        let currentline = line.split(',');
        let lineSize = _.size(currentline);
        if (_.size(currentline) > 0) {
          if (lineSize > 0) {
            let iccid = currentline[0];
            let lastChar = iccid.charAt(iccid.length - 1);
            if (!isnum.test(lastChar)) {
              iccid = iccid.replace(lastChar, '');
            }
            obj.iccid = iccid;
          }
          if (lineSize > 1) {
            obj.zipcode = currentline[1];
          }
          if (lineSize > 2) {
            obj.simname = currentline[2];
          }
          if (lineSize > 3) {
            let tags = [];
            for (let index = 3; index < lineSize; index++) {
              tags.push(currentline[index]);
            }
            obj.tags = tags.join(', ');
          }
        }
        allData.push(obj);
      }
    });
    return allData;
  };

  // Drop CSV File
  onDropFile = (files) => {
    let self = this;
    if (
      files &&
      (files[0].type.split('/')[1] === 'csv' ||
        files[0].type === 'application/vnd.ms-excel')
    ) {
      this.setState({ sheetName: files[0].name });
      var reader = new FileReader();
      reader.onload = function (e) {
        let csvData = self.csvJSON(reader.result);
        if (csvData.length > 0) {
          let csvDataHeader = csvData[0];
          if (
            _.get(csvDataHeader, 'iccid', '') === 'iccid' ||
            _.get(csvDataHeader, 'zipcode', '') === 'zipcode'
          ) {
            csvData.splice(0, 1);
          }
        }
        self.setState({ csvData, simAddError: {} }, () => {
          self.checkError();
        });
      };
      reader.readAsText(files[0]);
    }
  };

  addSims = async () => {
    const { csvData, pasteData, activeTab, simtype } = this.state;
    this.setState({ uploadLoading: true, errorString: '' });
    try {
      const simsData =
        activeTab === 'upload' ? _.cloneDeep(csvData) : _.cloneDeep(pasteData);
      simsData.filter((item) => {
        if (item.tags !== '') {
          item.tags = item.tags.split(',');
          let arr = [];
          _.forEach(item.tags, (tag) => {
            arr.push(tag.trim());
          });
          item.tags = arr;
        }
        item.simtype = simtype;
        return item;
      });
      let obj = {};
      obj.sims = simsData;
      const {
        value: { success },
      } = await this.props.addSims(obj);
      if (success) {
        this.setState({ simAddError: {}, sheetName: '', uploadLoading: false });
        const { simtype } = this.state;
        if (simtype === 'spr') {
          this.props.history.push('/available-sims');
        } else {
          this.props.history.push('/fixed-never-been-activated');
        }
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      let obj = {};
      obj.key = activeTab;
      obj.message = _.get(err, 'message', 'Something went wrong');
      this.setState({
        sheetName: '',
        uploadLoading: false,
        errorString: obj.message,
      });
    }
  };

  //COPY PASTE METHODS
  handlePasteData = (e) => {
    let lines = e.target.value;
    this.setState({ pasteLines: lines }, () => {
      if (lines.length === 0) {
        this.setState({ pasteData: [], simAddError: {}, sheetName: '' });
      }
    });
  };

  processSims = () => {
    const { pasteLines } = this.state;
    let pasteData = this.csvJSON(pasteLines);
    this.setState({ pasteData, simAddError: {}, markedSimList: [] }, () => {
      this.checkError();
    });
  };

  editRow = (key, index) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allcsvData = [...csvData];
      if (key === 'iccid') {
        allcsvData[index]['checkedIccid'] = true;
      } else if (key === 'zipcode') {
        allcsvData[index]['checkedZipcode'] = true;
      } else if (key === 'simname') {
        allcsvData[index]['checkedSimname'] = true;
      } else if (key === 'tags') {
        allcsvData[index]['checkedTags'] = true;
      }
      this.setState({ csvData: allcsvData });
    } else {
      let allPasteData = [...pasteData];
      if (key === 'iccid') {
        allPasteData[index]['checkedIccid'] = true;
      } else if (key === 'zipcode') {
        allPasteData[index]['checkedZipcode'] = true;
      } else if (key === 'simname') {
        allPasteData[index]['checkedSimname'] = true;
      } else if (key === 'tags') {
        allPasteData[index]['checkedTags'] = true;
      }
      this.setState({ pasteData: allPasteData });
    }
  };

  handelZipcodeChange = (index, e) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allSims = [...csvData];
      allSims[index].zipcode = e.target.value;
      this.setState({ csvData: allSims });
    } else {
      let allSims = [...pasteData];
      allSims[index].zipcode = e.target.value;
      this.setState({ pasteData: allSims });
    }
  };

  handeliccidChange = (index, e) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allSims = [...csvData];
      allSims[index].iccid = e.target.value;
      this.setState({ csvData: allSims });
    } else {
      let allSims = [...pasteData];
      allSims[index].iccid = e.target.value;
      this.setState({ pasteData: allSims });
    }
  };

  handelSimnameChange = (index, e) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allSims = [...csvData];
      allSims[index].simname = e.target.value;
      this.setState({ csvData: allSims });
    } else {
      let allSims = [...pasteData];
      allSims[index].simname = e.target.value;
      this.setState({ pasteData: allSims });
    }
  };

  handelTagsChange = (index, e) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allSims = [...csvData];
      allSims[index].tags = e.target.value;
      this.setState({ csvData: allSims });
    } else {
      let allSims = [...pasteData];
      allSims[index].tags = e.target.value;
      this.setState({ pasteData: allSims });
    }
  };

  saveEditSims = (index, e) => {
    const { pasteData, csvData, activeTab } = this.state;
    if (activeTab === 'upload') {
      let allSims = [...csvData];
      if (_.get(allSims[index], 'checkedIccid', false)) {
        delete allSims[index].checkedIccid;
        let iccid = allSims[index].iccid;
        allSims[index].iccid = iccid.trim();
      } else if (_.get(allSims[index], 'checkedZipcode', false)) {
        delete allSims[index].checkedZipcode;
        let zipcode = allSims[index].zipcode;
        allSims[index].zipcode = zipcode.trim();
      } else if (_.get(allSims[index], 'checkedSimname', false)) {
        delete allSims[index].checkedSimname;
        let simname = allSims[index].simname;
        allSims[index].simname = simname.trim();
      } else if (_.get(allSims[index], 'checkedTags', false)) {
        delete allSims[index].checkedTags;
        let tags = allSims[index].tags;
        let tagsArr = tags.split(',');
        let tagsTrim = tagsArr.filter((el) => el);
        allSims[index].tags = tagsTrim.join(', ');
      }
      this.setState({ csvData: allSims }, () => {
        this.checkError();
      });
    } else {
      let allSims = [...pasteData];
      if (_.get(allSims[index], 'checkedIccid', false)) {
        delete allSims[index].checkedIccid;
        let iccid = allSims[index].iccid;
        allSims[index].iccid = iccid.trim();
      } else if (_.get(allSims[index], 'checkedZipcode', false)) {
        delete allSims[index].checkedZipcode;
        let zipcode = allSims[index].zipcode;
        allSims[index].zipcode = zipcode.trim();
      } else if (_.get(allSims[index], 'checkedSimname', false)) {
        delete allSims[index].checkedSimname;
        let simname = allSims[index].simname;
        allSims[index].simname = simname.trim();
      } else if (_.get(allSims[index], 'checkedTags', false)) {
        delete allSims[index].checkedTags;
        let tags = allSims[index].tags;
        let tagsArr = tags.split(',');
        let tagsTrim = tagsArr.filter((el) => el);
        allSims[index].tags = tagsTrim.join(', ');
      }
      this.setState({ pasteData: allSims }, () => {
        this.checkError();
      });
    }
  };

  checkError = () => {
    const { pasteData, csvData, activeTab } = this.state;
    let errorIds = [];
    if (activeTab === 'upload') {
      _.forEach(csvData, (item, index) => {
        if (
          item.iccid === '' ||
          item.iccid.length !== 19 ||
          !isnum.test(item.iccid)
        ) {
          errorIds.push(item.iccid);
        }
      });
      _.forEach(csvData, (item, index) => {
        if (
          item.zipcode !== '' &&
          (item.zipcode.length !== 5 || !isnum.test(item.zipcode))
        ) {
          errorIds.push(item.zipcode);
        }
      });
      _.forEach(pasteData, (item, index) => {
        if (item.simname !== '' && item.simname.length < 3) {
          errorIds.push(item.simname);
        }
      });
      _.forEach(pasteData, (item, index) => {
        if (item.tags !== '') {
          let tags = item.tags.split(',');
          let tagError =
            tags.findIndex((item) => item.trim().length < 3) !== -1;
          if (tagError) {
            errorIds.push(item.tags);
          }
        }
      });
      let errorUploadString = '';
      if (errorIds.length > 0) {
        errorUploadString =
          'Some of your entries look incorrect. Please fix the highlighted ones above.'; // + errorIds.join(', ')
      }
      this.setState({ errorUploadString });
    } else {
      _.forEach(pasteData, (item, index) => {
        if (
          item.iccid === '' ||
          item.iccid.length !== 19 ||
          !isnum.test(item.iccid)
        ) {
          errorIds.push(item.iccid);
        }
      });
      _.forEach(pasteData, (item, index) => {
        if (
          item.zipcode !== '' &&
          (item.zipcode.length !== 5 || !isnum.test(item.zipcode))
        ) {
          errorIds.push(item.zipcode);
        }
      });
      _.forEach(pasteData, (item, index) => {
        if (item.simname !== '' && item.simname.length < 3) {
          errorIds.push(item.simname);
        }
      });
      _.forEach(pasteData, (item, index) => {
        if (item.tags !== '') {
          let tags = item.tags.split(',');
          let tagError =
            tags.findIndex((item) => item.trim().length < 3) !== -1;
          if (tagError) {
            errorIds.push(item.tags);
          }
        }
      });
      let errorString = '';
      if (errorIds.length > 0) {
        errorString =
          'Some of your entries look incorrect. Please fix the highlighted ones above.'; // + errorIds.join(', ')
      }
      this.setState({ errorString });
    }
  };

  onSideTabChange = (tab) => {
    if (tab === 'active') {
      this.props.history.push('/active-sims');
      document.title = 'Active SIMs | UltraIoT';
    } else if (tab === 'activationInProgress') {
      this.props.history.push('/activation-in-progress');
      document.title = 'Activation In Progress SIMs | UltraIoT';
    } else if (tab === 'inactive') {
      this.props.history.push('/available-sims');
      document.title = 'Available SIMs | UltraIoT';
    } else if (tab === 'availableeSim') {
      this.props.history.push('/available-esims');
      document.title = 'Available eSIMs | UltraIoT';
    } else if (tab === 'paused') {
      this.props.history.push('/paused-sims');
      document.title = 'Paused SIMs | UltraIoT';
    } else if (tab === 'deactive') {
      this.props.history.push('/deactivated-sims');
      document.title = 'Deactived SIMs | UltraIoT';
    } else if (tab === 'spr-orders') {
      this.props.history.push('/spr-orders');
      document.title = 'Orders | UltraIoT';
    } else if (tab === 'eSIM-orders') {
      this.props.history.push('/eSIM-orders');
      document.title = 'eSIM Orders | UltraIoT';
    } else if (tab === 'fixed-active') {
      this.props.history.push('/fixed-active-sims');
      document.title = 'Fixed Orders | UltraIoT';
    } else if (tab === 'fixed-activationInProgress') {
      this.props.history.push('/fixed-activation-in-progress');
      document.title = 'Fixed Activation In Progress SIMs | UltraIoT';
    } else if (tab === 'fixed-inactive') {
      this.props.history.push('/fixed-never-been-activated');
      document.title = 'Fixed Never Been Activated SIMs | UltraIoT';
    } else if (tab === 'fixed-paused') {
      this.props.history.push('/fixed-paused-sims');
      document.title = 'Fixed Paused SIMs | UltraIoT';
    } else if (tab === 'fixed-deactive') {
      this.props.history.push('/fixed-deactivated-sims');
      document.title = 'Fixed Deactived SIMs | UltraIoT';
    }
  };

  goBack() {
    const { simtype } = this.state;
    if (simtype === 'spr') {
      this.props.history.push('/active-sims');
    } else {
      this.props.history.push('/fixed-active-sims');
    }
  }

  setSortByKeys = async (key, order) => {
    this.setState({ sortBy: key, sortOrder: order });
  };

  render() {
    const {
      activeTab,
      csvData,
      pasteData,
      pasteLines,
      userData,
      sheetName,
      simsCount,
    } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          setSortByKeys={this.setSortByKeys}
          simCounts={simsCount}
          onTabChange={this.onSideTabChange}
        />
        <TopNavigation {...this.props} simCounts={simsCount} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-4">
              <button onClick={this.goBack.bind(this)} className="btn btn-back">
                <BackArrowIcon className="back-icon" /> Back
              </button>
            </div>
            {activeTab === 'upload' && (
              <div className="col-md-8 text-right">
                <button
                  onClick={this.downloadTemplate}
                  className="btn btn-dark w-180"
                >
                  {' '}
                  Download Template
                </button>
              </div>
            )}
          </div>
          <div className="mobile-select d-lg-none">
            <select
              className="form-control custom-select"
              onChange={(e) => this.onTabChange(e.target.value)}
            >
              <option value="upload">Upload CSV</option>
              <option value="paste">Copy & Paste</option>
            </select>
          </div>
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <div className="tabs__control mb-4">
                <button
                  onClick={this.onTabChange.bind(this, 'upload')}
                  className={activeTab === 'upload' ? 'btn active' : 'btn'}
                >
                  Upload CSV
                </button>
                <button
                  onClick={this.onTabChange.bind(this, 'paste')}
                  className={activeTab === 'paste' ? 'btn active' : 'btn'}
                >
                  Copy & Paste
                </button>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              {activeTab === 'upload' && (
                <>
                  <div className="dropzone">
                    <Dropzone
                      onDrop={(acceptedFiles) => this.onDropFile(acceptedFiles)}
                      accept=".csv"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="upload-container">
                          <div
                            className="dropzone-inner-area"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="upload-text">
                              Upload <br /> or
                            </div>
                            <button
                              type="button"
                              className="btn btn-dark btn-upload"
                            >
                              Drag & Drop
                            </button>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {_.size(csvData) > 0 && (
                    <>
                      <div className="card mb-4">
                        <div className="card-header d-flex align-items-center">
                          <div>
                            <h5 className="card-title">Uploaded SIMs</h5>
                            <p className="card-subtitle mt-1">
                              Below are the SIMs you uploaded from {sheetName},
                              please look them over before adding them to{' '}
                              {_.get(userData, 'companyname', '')}
                            </p>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="table-responsive">
                            <table className="table custom-table-secondary">
                              <thead>
                                <tr>
                                  <th>
                                    <span className="table-column-title">
                                      ICCID
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      Zipcode
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      SIM Name
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      Tags
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.size(csvData) > 0 &&
                                  csvData.map((item, index) => {
                                    let tags = item.tags;
                                    let tagsArr = tags.trim().split(',');
                                    let tagsArrRemoveBlank = tagsArr.filter(
                                      (item) => item.trim() !== ''
                                    );
                                    let tagError =
                                      tagsArrRemoveBlank.findIndex(
                                        (item) => item.trim().length < 3
                                      ) !== -1;
                                    let checkedIccid = _.get(
                                      item,
                                      'checkedIccid',
                                      false
                                    );
                                    let checkedZipcode = _.get(
                                      item,
                                      'checkedZipcode',
                                      false
                                    );
                                    let checkedSimname = _.get(
                                      item,
                                      'checkedSimname',
                                      false
                                    );
                                    let checkedTags = _.get(
                                      item,
                                      'checkedTags',
                                      false
                                    );
                                    return (
                                      <tr key={index}>
                                        <td
                                          onClick={() =>
                                            this.editRow('iccid', index)
                                          }
                                        >
                                          {checkedIccid ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="iccid"
                                                value={item.iccid}
                                                placeholder="Enter iccid"
                                                onChange={(e) =>
                                                  this.handeliccidChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.iccid === '' ||
                                                item.iccid.length !== 19 ||
                                                !isnum.test(item.iccid)
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.iccid !== ''
                                                ? item.iccid
                                                : '-'}
                                            </div>
                                          )}
                                          {(item.iccid === '' ||
                                            item.iccid.length !== 19 ||
                                            !isnum.test(item.iccid)) && (
                                            <div className="invalid-feedback">
                                              Enter valid ICCID number.
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('zipcode', index)
                                          }
                                        >
                                          {checkedZipcode ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="zipcode"
                                                value={item.zipcode}
                                                placeholder="Enter zipcode"
                                                onChange={(e) =>
                                                  this.handelZipcodeChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.zipcode !== '' &&
                                                (item.zipcode.length !== 5 ||
                                                  !isnum.test(item.zipcode))
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.zipcode !== ''
                                                ? item.zipcode
                                                : '-'}
                                            </div>
                                          )}
                                          {item.zipcode !== '' &&
                                            (item.zipcode.length !== 5 ||
                                              !isnum.test(item.zipcode)) && (
                                              <div className="invalid-feedback">
                                                Enter valid zipcode.
                                              </div>
                                            )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('simname', index)
                                          }
                                        >
                                          {checkedSimname ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="simname"
                                                value={item.simname}
                                                placeholder="Enter Simname"
                                                onChange={(e) =>
                                                  this.handelSimnameChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.simname !== '' &&
                                                item.simname.length < 3
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.simname !== ''
                                                ? item.simname
                                                : '-'}
                                            </div>
                                          )}
                                          {item.simname !== '' &&
                                            item.simname.length < 3 && (
                                              <div className="invalid-feedback">
                                                Please enter at least three
                                                characters to save.
                                              </div>
                                            )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('tags', index)
                                          }
                                        >
                                          {checkedTags ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm tags-input-filed"
                                                type="text"
                                                name="tags"
                                                value={item.tags}
                                                placeholder="Enter tags with comma seperated"
                                                onChange={(e) =>
                                                  this.handelTagsChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                tagError
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {tags !== '' ? tags : '-'}
                                            </div>
                                          )}
                                          {tagError && (
                                            <div className="invalid-feedback">
                                              Please enter at least three
                                              characters on each tag to save.
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        {this.state.errorUploadString !== '' && (
                          <div className="invalid-feedback">
                            {this.state.errorUploadString}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <button
                          disabled={
                            this.state.errorUploadString !== '' ||
                            this.state.uploadLoading
                          }
                          onClick={this.addSims}
                          className="btn btn-dark btn-lg w-100"
                        >
                          {this.state.uploadLoading
                            ? 'Please wait...'
                            : 'Add SIMs'}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {activeTab === 'paste' && (
                <>
                  <div className="form-group material-textfield">
                    <textarea
                      type="text"
                      className="form-control form-control-lg material-textfield-input"
                      value={pasteLines}
                      onChange={this.handlePasteData}
                      required
                      rows="6"
                    ></textarea>
                    <label className="material-textfield-label">
                      Enter one iccid, zipcode, sim name, tag1, tag2, tag3, tag4
                      one per line
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      disabled={_.size(pasteLines.trim()) === 0}
                      onClick={this.processSims}
                      className="btn btn-dark btn-lg w-100"
                    >
                      {' '}
                      Process SIMs
                    </button>
                  </div>
                  {_.size(pasteData) > 0 && (
                    <>
                      <div className="card mb-4">
                        <div className="card-header d-flex align-items-center">
                          <div>
                            <h5 className="card-title">Added SIMs</h5>
                            <p className="card-subtitle mt-1">
                              Below are the SIMs you added from above, please
                              look them over before adding them to{' '}
                              {_.get(userData, 'companyname', '')}
                            </p>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="table-responsive">
                            <table className="table custom-table-secondary">
                              <thead>
                                <tr>
                                  <th>
                                    <span className="table-column-title">
                                      ICCID
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      Zipcode
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      SIM Name
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-column-title">
                                      Tags
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.size(pasteData) > 0 &&
                                  pasteData.map((item, index) => {
                                    let tags = item.tags;
                                    let tagsArr = tags.trim().split(',');
                                    let tagsArrRemoveBlank = tagsArr.filter(
                                      (item) => item.trim() !== ''
                                    );
                                    let tagError =
                                      tagsArrRemoveBlank.findIndex(
                                        (item) => item.trim().length < 3
                                      ) !== -1;
                                    let checkedIccid = _.get(
                                      item,
                                      'checkedIccid',
                                      false
                                    );
                                    let checkedZipcode = _.get(
                                      item,
                                      'checkedZipcode',
                                      false
                                    );
                                    let checkedSimname = _.get(
                                      item,
                                      'checkedSimname',
                                      false
                                    );
                                    let checkedTags = _.get(
                                      item,
                                      'checkedTags',
                                      false
                                    );
                                    return (
                                      <tr key={index}>
                                        <td
                                          onClick={() =>
                                            this.editRow('iccid', index)
                                          }
                                        >
                                          {checkedIccid ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="iccid"
                                                value={item.iccid}
                                                placeholder="Enter iccid"
                                                onChange={(e) =>
                                                  this.handeliccidChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.iccid === '' ||
                                                item.iccid.length !== 19 ||
                                                !isnum.test(item.iccid)
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.iccid !== ''
                                                ? item.iccid
                                                : '-'}
                                            </div>
                                          )}
                                          {(item.iccid === '' ||
                                            item.iccid.length !== 19 ||
                                            !isnum.test(item.iccid)) && (
                                            <div className="invalid-feedback">
                                              Enter valid ICCID number.
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('zipcode', index)
                                          }
                                        >
                                          {checkedZipcode ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="zipcode"
                                                value={item.zipcode}
                                                placeholder="Enter zipcode"
                                                onChange={(e) =>
                                                  this.handelZipcodeChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.zipcode !== '' &&
                                                (item.zipcode.length !== 5 ||
                                                  !isnum.test(item.zipcode))
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.zipcode !== ''
                                                ? item.zipcode
                                                : '-'}
                                            </div>
                                          )}
                                          {item.zipcode !== '' &&
                                            (item.zipcode.length !== 5 ||
                                              !isnum.test(item.zipcode)) && (
                                              <div className="invalid-feedback">
                                                Enter valid zipcode.
                                              </div>
                                            )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('simname', index)
                                          }
                                        >
                                          {checkedSimname ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="simname"
                                                value={item.simname}
                                                placeholder="Enter Simname"
                                                onChange={(e) =>
                                                  this.handelSimnameChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.simname !== '' &&
                                                item.simname.length < 3
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {item.simname !== ''
                                                ? item.simname
                                                : '-'}
                                            </div>
                                          )}
                                          {item.simname !== '' &&
                                            item.simname.length < 3 && (
                                              <div className="invalid-feedback">
                                                Please enter at least three
                                                characters to save.
                                              </div>
                                            )}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.editRow('tags', index)
                                          }
                                        >
                                          {checkedTags ? (
                                            <div className="material-textfield">
                                              <input
                                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                                type="text"
                                                name="tags"
                                                value={item.tags}
                                                placeholder="Enter tags with comma seperated"
                                                onChange={(e) =>
                                                  this.handelTagsChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  this.saveEditSims(index, e)
                                                }
                                                autoFocus
                                                required
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                tagError
                                                  ? 'invalid-feedback'
                                                  : ''
                                              }
                                            >
                                              {tags !== '' ? tags : '-'}
                                            </div>
                                          )}
                                          {tagError && (
                                            <div className="invalid-feedback">
                                              Please enter at least three
                                              characters on each tag to save.
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        {this.state.errorString !== '' && (
                          <div className="invalid-feedback">
                            {this.state.errorString}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <button
                          disabled={this.state.uploadLoading}
                          onClick={this.addSims}
                          className="btn btn-dark btn-lg w-100"
                        >
                          {this.state.uploadLoading
                            ? 'Please wait...'
                            : 'Add SIMs'}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
