import React from 'react';
import { CheckmarkIcon } from '../components/icons';
import _ from 'lodash';

export default class PasswordStrengthStatus extends React.Component {
  render() {
    const { passwordStrength } = this.props;
    return (
      <div className="form-group security-password">
        <div
          className="security-password-text"
          style={
            _.get(passwordStrength, 'charLength', false)
              ? { color: '#111111' }
              : { color: '#979797' }
          }
        >
          <CheckmarkIcon
            style={
              _.get(passwordStrength, 'charLength', false)
                ? { color: '#0EDD4D' }
                : _.isEmpty(passwordStrength)
                ? { color: '#979797' }
                : { color: '#D51236' }
            }
          />
          8 characters minimum
        </div>
        <div
          className="security-password-text"
          style={
            _.get(passwordStrength, 'upperChar', false)
              ? { color: '#111111' }
              : { color: '#979797' }
          }
        >
          <CheckmarkIcon
            style={
              _.get(passwordStrength, 'upperChar', false)
                ? { color: '#0EDD4D' }
                : _.isEmpty(passwordStrength)
                ? { color: '#979797' }
                : { color: '#D51236' }
            }
          />
          One capital letter
        </div>
        <div
          className="security-password-text"
          style={
            _.get(passwordStrength, 'lowerChar', false)
              ? { color: '#111111' }
              : { color: '#979797' }
          }
        >
          <CheckmarkIcon
            style={
              _.get(passwordStrength, 'lowerChar', false)
                ? { color: '#0EDD4D' }
                : _.isEmpty(passwordStrength)
                ? { color: '#979797' }
                : { color: '#D51236' }
            }
          />
          One lowercase letter
        </div>
        <div
          className="security-password-text"
          style={
            _.get(passwordStrength, 'specialChar', false)
              ? { color: '#111111' }
              : { color: '#979797' }
          }
        >
          <CheckmarkIcon
            style={
              _.get(passwordStrength, 'specialChar', false)
                ? { color: '#0EDD4D' }
                : _.isEmpty(passwordStrength)
                ? { color: '#979797' }
                : { color: '#D51236' }
            }
          />
          One special character
        </div>
        <div
          className="security-password-text"
          style={
            _.get(passwordStrength, 'numbers', false)
              ? { color: '#111111' }
              : { color: '#979797' }
          }
        >
          <CheckmarkIcon
            style={
              _.get(passwordStrength, 'numbers', false)
                ? { color: '#0EDD4D' }
                : _.isEmpty(passwordStrength)
                ? { color: '#979797' }
                : { color: '#D51236' }
            }
          />
          One digit
        </div>
      </div>
    );
  }
}
