/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { pageSettingSchema } from '../../../utils/validations';
import './styles.scss';

export default class PageSettingsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        pagesize: 0,
        simquantity: 0,
      },
      id: '',
      errorMessage: '',
      isProcessing: false,
    };
  }

  componentDidMount = async () => {
    document.title = 'Page Setting | UltraIoT';
    const {
      value: { settings },
    } = await this.props.fetchSetting();
    if (settings && settings.length > 0) {
      this.setState({
        initialValues: {
          pagesize: settings[0].pagesize,
          simquantity: settings[0].simquantity,
        },
        id: settings[0].id,
      });
    }
  };

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const { id } = this.state;
      const payload = {
        id,
        ...values,
      };
      this.setState({ isProcessing: true, errorMessage: '' });
      const {
        value: { success },
      } = await this.props.createSetting(payload);
      if (success) {
        this.props.history.push('/dashboard');
      }
    } catch (err) {
      const { message } = err;
      this.setState({ errorMessage: message, isProcessing: false });
    }
  };

  render() {
    const { initialValues, isProcessing, errorMessage } = this.state;
    return (
      <div className="card-container">
        <div className="card-wrapper">
          <div className="card mb-0">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={pageSettingSchema}
              onSubmit={(values, formikProps) =>
                this.handleSubmit(values, formikProps)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="card-body">
                    <div className="form-group material-textfield">
                      <input
                        type="number"
                        className="form-control form-control-lg material-textfield-input"
                        name="simquantity"
                        onChange={handleChange}
                        value={values.simquantity}
                        required
                      />
                      <label className="material-textfield-label">
                        SIM Quantity{' '}
                      </label>
                      <ErrorMessage
                        component="span"
                        name="simquantity"
                        className="invalid-feedback d-block"
                      />
                    </div>
                    <div className="form-group material-textfield">
                      <input
                        type="number"
                        className="form-control form-control-lg material-textfield-input"
                        name="pagesize"
                        onChange={handleChange}
                        value={values.pagesize}
                        required
                      />
                      <label className="material-textfield-label">
                        Page Size{' '}
                      </label>
                      <ErrorMessage
                        component="span"
                        name="pagesize"
                        className="invalid-feedback d-block"
                      />
                    </div>
                    <div className="invalid-feedback d-block text-center mb-3">
                      {errorMessage}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-dark btn-lg w-100"
                      disabled={isProcessing}
                    >
                      {isProcessing ? 'Please wait ...' : 'Save'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}
