import { z } from 'zod';
import { FetchResponse, ResponseData } from '../fetch';

// @ts-expect-error type of window.REACT_APP_API_HOSTNAME is not declared
export const IOT_API_HOSTNAME = window.REACT_APP_API_HOSTNAME as string;
export const IOT_API_BASE_URL = `${IOT_API_HOSTNAME}/api/v1` as const;

/**
 * Parses the response data from a fetch request and validates it against the provided schema.
 *
 * @param response - The json response object from a fetch request.
 * @param schema - The Zod schema to validate the response data against.
 *
 * @returns The parsed and validated response data as a readonly object.
 *
 * @throws {Error} If the response data fails to validate against the provided schema.
 */
export function parseResponseData<T extends z.AnyZodObject>(
  response: FetchResponse<'json'>,
  schema: T
): Readonly<z.infer<T>> {
  try {
    const parsedResponse = schema.parse(response.data);

    return parsedResponse as Readonly<z.infer<T>>;
  } catch (error) {
    if (error instanceof z.ZodError) {
      const formattedErrors = error.format();

      console.error(
        `Schema validation failed for ${response.method} request: ${response.requestUrl}`,
        { cause: formattedErrors }
      );
    }
    
    return response.data as Readonly<z.infer<T>>;
  }
}

/**
 * The default schema of an error response from an IOT-API request using the kaena context.
 */
const defaultApiErrorSchema = z.object({
  requestBody: z.unknown(),
  impl: z.string(),
  path: z.string(),
  type: z.string(),
  stack: z.string(),
  status: z.number(),
  origin: z.string(),
  data: z.object({
    success: z.boolean(),
    message: z.string(),
    traceId: z.string(),
  }),
});

/**
 * Parses the error message from a fetch request and validates it against the provided schema (uses the default schema if none is provided).
 *
 * @param error - The json error object from a fetch request.
 * @param schema - The Zod schema to validate the error message against. Uses the default schema if none is provided.
 *
 * @returns The parsed and validated error message as a string or `null` if the error response does not match the provided schema.
 *
 * @example
 * ```
 * try {
 *   const response = await $fetch('iot-api/api/v1/sim/1234567890/sim-card-detail');
 *   // ...
 * } catch (error) {
 *   const errorMessage = parseApiErrorMessage(error);
 *   console.log(errorMessage); // "Invalid credentials" || `null`
 * }
 * ```
 */
export function parseApiErrorMessage(
  error: ResponseData<'json'>,
  schema: z.AnyZodObject = defaultApiErrorSchema
): string | null {
  try {
    const parsedError = schema.parse(error);

    return parsedError.data.message;
  } catch (error) {
    return null;
  }
}

/**
 * Returns the authorization tokens from local storage.
 *
 * @returns An object containing the jwtToken and adminToken from local storage.
 *
 * @example
 * ```
 * const { jwtToken, adminToken } = getAuthTokensFromLocalStorage();
 * console.log(jwtToken); // "<token>" || `null`
 * console.log(adminToken); // "<token>" || `null`
 * ```
 */
export function getAuthTokensFromLocalStorage() {
  const jwtToken =
    localStorage.getItem('Authorization') ||
    localStorage.getItem('t_Authorization');
  const adminToken = localStorage.getItem('i_Authorization');

  return {
    jwtToken,
    adminToken,
  };
}
