import React, { useState } from 'react';
import _ from 'lodash';
import LocationMarker from '../../../images/location-marker.png';
import Device from '../../../images/device.png';
import Nocoverage from '../../../images/location-marker-no-coverage.png';
import Compatible from '../../../images/compatible.png';
import NotCompatible from '../../../images/not-compatible.png';
import {
  CheckmarkIcon,
  CrossIcon,
  MoreInfoIcon,
  RightIcon,
  WrongIcon,
  CloseIcon,
} from '../../../components/icons';
import { Popover } from 'antd';
import '../styles.scss';

const Progressbar = ({ ...props }) => {
  let barClassName = 'u-TransformOrigin-None';
  let transformOriginClass = 'u-TransformOrigin-None';
  if (
    _.get(props.coverageDetailData, 'result', '').indexOf('EXCELLENT') !== -1
  ) {
    barClassName = 'u-progressbar-gradient-excellent';
    transformOriginClass = 'u-TransformOrigin-Excellent';
  }
  if (_.get(props.coverageDetailData, 'result', '').indexOf('POOR') !== -1) {
    barClassName = 'u-progressbar-gradient-poor';
    transformOriginClass = 'u-TransformOrigin-Poor';
  }
  if (_.get(props.coverageDetailData, 'result', '').indexOf('GOOD') !== -1) {
    barClassName = 'u-progressbar-gradient-good';
    transformOriginClass = 'u-TransformOrigin-Good';
  }
  if (_.get(props.coverageDetailData, 'result', '').indexOf('FAIR') !== -1) {
    barClassName = 'u-progressbar-gradient-fair';
    transformOriginClass = 'u-TransformOrigin-Fair';
  }
  return (
    <svg {...props} viewBox="0 0 68 68">
      <defs>
        <linearGradient
          id="prefix__b"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="0%"
          spreadMethod="pad"
        >
          <stop offset="0%" stopColor="#E3A617" />
          <stop offset="50%" stopColor="#38877A" />
        </linearGradient>
        <filter id="prefix__a" filterUnits="userSpaceOnUse">
          <feGaussianBlur in="SourceAlpha" stdDeviation={1} />
          <feOffset />
          {'-'}
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
            {'-'}
          </feMerge>
        </filter>
      </defs>
      <circle
        className="u-progressbar-track"
        cx="50%"
        cy="50%"
        r={30}
        stroke="#E3E3E3"
        filter="url(#prefix__a)"
      />
      <circle
        className={barClassName}
        cx="50%"
        cy="50%"
        r={30}
        stroke="url(#prefix__b)"
      />
      <circle
        className={`u-progressbar-endCircle ${transformOriginClass}`}
        cx="50%"
        cy="50%"
        r={1}
        stroke="#38877A"
      />
    </svg>
  );
};

const bands_4G = {
  2: 'Band 2 (4G LTE 1900 MHz)',
  4: 'Band 4 (4G LTE 2100 MHz)',
  12: 'Band 12 (4G LTE 700 MHz)',
  71: 'Band 71 (4G LTE 600 MHz)',
  5: 'Band 5 (850 MHz)',
  66: 'Band 66 (1700/2100 MHz)',
};
const bands_5G = {
  n71: 'Band N71',
  n41: 'Band N41',
  n260: 'Band N260',
  n261: 'Band N261',
};
const text = <></>;

const BandsPopup = ({ ...props }) => {
  const { set4G, setConfirmModal, deviceCompatibility } = props.props;
  let { is4G } = props.props;
  const bandsArray = _.get(deviceCompatibility.bands, '_4G', '');
  const array_5G = _.get(deviceCompatibility.bands, '_5G', '');
  const volteCompatible = _.get(
    deviceCompatibility,
    'volteCompatible',
    'false'
  );
  const eSIMCompatible = _.get(deviceCompatibility, 'eSIMCompatible', '');
  if (array_5G.length === 0) {
    is4G = true;
  }
  return (
    <div className="ModalWrapper">
      <button
        className="Close"
        type="button"
        onClick={() => {
          setConfirmModal(false);
        }}
      >
        <CloseIcon />
      </button>
      <h2 className="Title">Phone Compatibility Details</h2>
      <ul className="DetailsList">
        <li>
          {volteCompatible ? <RightIcon /> : <WrongIcon />}
          <h4 className="Heading">VoLTE(Voice over LTE)</h4>
        </li>
        <li>
          {eSIMCompatible ? <RightIcon /> : <WrongIcon />}
          <h4 className="Heading">eSIM Compatible</h4>
        </li>
        <li style={{ borderBottom: '0' }}>
          {array_5G.length > 0 && (
            <button
              onClick={() => set4G(false)}
              className={`btn btn-purple-border ${is4G ? '' : 'clicked'} mr-3`}
            >
              5G Bands
            </button>
          )}
          <button
            onClick={() => set4G(true)}
            className={`btn btn-purple-border ${is4G ? 'clicked' : ''} mr-3`}
          >
            4G LTE Bands
          </button>
        </li>
        {Object.keys(is4G ? bands_4G : bands_5G).map((key) => {
          const isMatched = is4G
            ? bandsArray.includes(parseInt(key))
            : array_5G.includes(key);
          return (
            <li>
              {isMatched ? <RightIcon /> : <WrongIcon />}
              <div>
                <h4 className="Heading">
                  {is4G ? bands_4G[key] : bands_5G[key]}
                </h4>
              </div>
            </li>
          );
        })}
      </ul>
      <button
        onClick={() => setConfirmModal(false)}
        className={`btn btn-purple-border mt-2`}
      >
        Close
      </button>
    </div>
  );
};

function CoverageComponent(props) {
  let [is4G, set4G] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);

  return (
    <>
      <div className="device-coverage-wrap">
        <h5 className="title">Results</h5>
        <div className="card device-coverage-card">
          <div className="card-body">
            <div className="u-circle-device-coverage-row">
              <div className="u-circle-device-coverage u-coverage">
                <div className="u-circle-header">
                  <h5 className="u-circle-header-title">
                    4G LTE Coverage:{' '}
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'NO COVERAGE'
                    ) !== -1 && <span className="poor">NO COVERAGE</span>}
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'EXCELLENT'
                    ) !== -1 && <span className="excellent">EXCELLENT</span>}
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'POOR'
                    ) !== -1 && <span className="poor">POOR</span>}
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'GOOD'
                    ) !== -1 && <span className="good">GOOD</span>}
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'FAIR'
                    ) !== -1 && <span className="fair">FAIR</span>}
                  </h5>
                </div>
                <div className="u-circle-container">
                  <div className="u-progress-container">
                    <Progressbar {...props} className="u-progressbar" />
                    {_.get(props.coverageDetailData, 'result', '').indexOf(
                      'NO COVERAGE'
                    ) !== -1 ? (
                      <img
                        className="u-graphic-image u-graphic-location"
                        src={Nocoverage}
                        alt=""
                      />
                    ) : (
                      <img
                        className="u-graphic-image u-graphic-location"
                        src={LocationMarker}
                        alt=""
                      />
                    )}
                    <div className="u-progress-text u-poor">Poor</div>
                    <div className="u-progress-text u-fair">Fair</div>
                    <div className="u-progress-text u-good">Good</div>
                    <div className="u-progress-text u-excellent">Excellent</div>
                  </div>
                </div>
                <div className="u-circle-footer">
                  <h5 className="u-circle-header-subtitle">{props.address}</h5>
                </div>
              </div>
              <div className="u-circle-device-coverage u-device">
                <div className="u-circle-header">
                  <h5 className="u-circle-header-title">
                    Devices:{' '}
                    {_.get(props.deviceCompatibility, 'compatibility', '') ===
                    'COMPATIBLE' ? (
                      <span className="excellent">COMPATIBLE</span>
                    ) : (
                      <span className="excellent">NOT COMPATIBLE</span>
                    )}
                  </h5>
                </div>
                <div className="u-circle-container">
                  <div className="u-progress-container u-circle">
                    <img className="u-graphic-image" src={Device} alt="" />
                    {_.get(props.deviceCompatibility, 'compatibility', '') ===
                    'COMPATIBLE' ? (
                      <img
                        className="u-graphic-image"
                        src={Compatible}
                        alt=""
                      />
                    ) : (
                      <img
                        className="u-graphic-image"
                        src={NotCompatible}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="u-circle-footer">
                  <h5 className="u-circle-header-subtitle mb-1 infoheading">
                    {_.get(props.deviceCompatibility, 'name', '')}
                    <div>
                      {/* modal start */}
                      {
                        <Popover
                          onCancel={() => setConfirmModal(false)}
                          visible={isConfirmModal}
                          placement="bottom"
                          title={text}
                          overlayClassName="ModalOverlay"
                          content={
                            <BandsPopup
                              props={{ ...props, setConfirmModal, set4G, is4G }}
                            />
                          }
                          trigger="click"
                        >
                          <span
                            className="InfoIcon"
                            onClick={() => {
                              setConfirmModal(true);
                              set4G(false);
                            }}
                          >
                            <MoreInfoIcon />
                          </span>
                        </Popover>
                      }
                      {/* modal close */}
                    </div>
                  </h5>

                  {_.get(props.deviceCompatibility, 'eSIMCompatible', '') ===
                  true ? (
                    <h6 className="u-cirlcle-footer-subtitle">
                      <CheckmarkIcon className="right-sign" /> eSIM Compatible
                    </h6>
                  ) : (
                    <h6 className="u-cirlcle-footer-subtitle">
                      <CrossIcon className="right-sign" /> eSIM Compatible
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CoverageComponent;
