import { connect } from 'react-redux';
import TwoFactorAuthComponent from './component';
import { fetchMe, setUser, sendOtp, verifyOtp } from '../../store/user/duck';

const TwoFactorAuthContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    sendOtp,
    verifyOtp,
  }
)(TwoFactorAuthComponent);

export default TwoFactorAuthContainer;
