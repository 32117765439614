import { fetch } from '../../utils';
import _ from 'lodash';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const getSims = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/sim/filtered`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
// API call action for Sims List export
export const exportSimsData = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/sims-data-export`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/activatebyid`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateeSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/activateeSimbyid`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateBulkEsims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim//bulk-esim-activation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEsimQuantity = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/get-esim-quantity`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEsimInfo = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/get-esim-info/${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const pauseSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/pauseSim`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const activateSimByPlan = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/changeplan`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const reActivateSimByPlan = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/reactivate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deactivateSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/deactivate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateTag = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/updatetags`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSimName = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/updatesimname`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSimDetails = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/updatesimdetails`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/addnonactivesims`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBulkSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/bulk-activation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchPlans = () => {
  return fetch(`${HOSTNAME}/api/v1/plan/fetchPlans`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchClients = () => {
  return fetch(`${HOSTNAME}/api/v1/client/autocomplete`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: JSON.stringify(data)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addClient = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/associate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const exportCsv = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/exportsims/${data}`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const planChangeCount = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/plan-changed-count`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const planDetails = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/sim-plans`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const simcardDetail = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/sim-card-detail`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const planCycle = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/plan-cycle`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const accountNumber = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/account-number`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getActivitiesLogs = (data) => {
  const {iccid, offset, limit} = data
  return fetch(`${HOSTNAME}/api/v1/sim/${iccid}/activity-logs?limit=${limit}&offset=${offset} `, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const hidePlanCycle = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/hide-plan-cycle-status`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const pukCodes = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/puk-codes`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const reconnectWaitTime = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/reconnect-wait-time`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const addOnPlans = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/addon-plans`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const addOnPlansUsage = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/addon-plans-usage`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const irIldDetails = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/ir-ild-check`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const ActivePortinData = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/active-portin-req-data`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const remainingIRBalance = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/remaining-ir-balance`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const recurringInfo = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data}/recurring-info`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsageDetailsByMonth = (data) => {
  return fetch(
    `${HOSTNAME}/api/v1/sim/usage/${data.iccid}/${data.year}/${data.month}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsageDetailsByDay = (data) => {
  return fetch(
    `${HOSTNAME}/api/v1/sim/usage/${data.iccid}/${data.year}/${data.month}/${data.day}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsageDailySummary = (data, postData) => {
  return fetch(`${HOSTNAME}/api/v1/sim/usagedailysummary/${data}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const removeClient = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSwapSimSearch = (searchText, simType, isEsim) => {
  return fetch(
    `${HOSTNAME}/api/v1/sim/autocomplete/inactive/${searchText}?simType=${simType}&isEsim=${isEsim}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const swapSIM = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/swap`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const swapESIM = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/esim/swap`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const simPortInActivation = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/portin-request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSimPortInRequest = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/update-portin-request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const simPortInCancel = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/portin-cancel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBulkSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/getInactiveSims`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchTagsByType = (data) => {
  const type = _.get(data, 'type', 'all');
  const simType = _.get(data, 'simType', 'spr');
  const q = _.get(data, 'q', '');
  return fetch(
    `${HOSTNAME}/api/v1/sim/tags/${type}?simType=${simType}&q=${q}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const orderSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/order/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const orderESIMs = (data) => {
  return fetch(`${HOSTNAME}/api/v1/order/esim-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllOrders = (data) => {
  return fetch(`${HOSTNAME}/api/v1/order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrderById = (obj) => {
  return fetch(`${HOSTNAME}/api/v1/order/detail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSimsUsageSummaryReport = (credentials) => {
  return fetch(`${HOSTNAME}/api/v1/usage/sims-usage-summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const enableWifiCalling = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/enable-wifi-calling`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const refreshPortInStatus = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/refresh-portin-status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const reconnectNetwork = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/reconnect-network`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const adminOverride = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/admin-override`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSimCounts = ({ orderType }) => {
  return fetch(`${HOSTNAME}/api/v1/sim/sim-counts?orderType=${orderType}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePhoneNumber = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/change-phone-number`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchClientListing = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/fetch-client-listing`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addNewClient = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateClientName = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/update-client-name`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteClient = (data) => {
  return fetch(`${HOSTNAME}/api/v1/client/delete-client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const exportSimsUsageSummary = (data) => {
  return fetch(`${HOSTNAME}/api/v1/usage/export-sims-usage-summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const fetchFwdeviceList = () => {
  return fetch(`${HOSTNAME}/api/v1/sim/getFwdeviceList`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateAddOnPlans = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/addOnPlans`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const fetchSimsWithAddOns = (data, searchBy) => {
  return fetch(`${HOSTNAME}/api/v1/sim/fetchSimsWithAddOns/${searchBy}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const bulkManageAddOns = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/bulkManageAddOns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const cancelRecurringPayment = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/cancel-recurring-payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const getDataUsageFromUltra = ({ iccid, brandName, simType }) => {
  return fetch(
    `${HOSTNAME}/api/v1/sim/getDataUsageFromUltra/${iccid}?brandName=${brandName}&simType=${simType}`,
    {
      method: 'GET',
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const UpdateVoicemailStatus = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/voicemailStatus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const getWifiCallingDetails = ({ iccid }) => {
  return fetch(`${HOSTNAME}/api/v1/sim/subscriber/${iccid}/wificalling`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const getVoiceMailStatus = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/getVoiceMailStatus/${data.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const bulkPlanChange = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/bulkPlanChange`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const fetchAddOnPlans = () => {
  return fetch(`${HOSTNAME}/api/v1/sim/getAddOnPlans`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const bulkAddOnsPlan = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/bulkAddOnsPlan`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    });
};

export const reActivateSim = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/reactivatesim`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const unPauseSim = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/unPauseSim`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const setSimsIntoNeverBeen = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/updateSimStatus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPreSignedUrl = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/getSignedUrl/${data.ICCID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const generatePDF = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/${data.ICCID}/generate-pdf`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAdditionalEsimInfo = (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/eSIMInfo/${data.ICCID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return error;
    });
};

export const downloadEsimPDF = async (data) => {
  const iccid = data.iccid;
  try {
    const response = await fetch(`${HOSTNAME}/api/v1/sim/esim/${iccid}/pdf`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
      const payload = await response.blob();
      return payload;
  } catch (error) {
    throw new Error('Error fetching eSIM file data');
  }
};