/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { CloseIcon } from '../../../components/icons';
import _ from 'lodash';
import './styles.scss';
import { message } from 'antd';

export default class AccountDocumentsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      deleteModal: false,
      user: null,
      documents: [],
      documentId: 0,
      documentName: '',
      isProcessing: false,
      fileUploading: false,
      errInvalidFile: '',
      iAuthorization: localStorage.getItem('i_Authorization'),
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Account Documents | UltraIoT';
    try {
      const {
        value: { user },
      } = await this.props.fetchMe();
      if (user) {
        this.setState({ user });
        this.fetchDocuments();
      }
    } catch (err) {
      const error = err.data;
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }
  };

  fetchDocuments = async () => {
    const type = this.props?.type;
    const payload = {type};
  
    try {
      const response = await this.props.fetchDocuments(payload);
      const { data } = response.value;
      this.setState({ documents: data });
    } catch (error) {
      this.setState({ documents: [] });
    }
  };

  fetchFileData = async (documentId, fileName) => {
    message.success('Your file is being downloaded');
    const payload = {
      documentId
    }
    try {
      const response = await this.props.fetchFileDataFromDocumentId(payload);
      const link = URL.createObjectURL(response.value)
      
      const a = document.createElement('a');
      a.href = link;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(link)
    } catch (error){
      message.error(error.message);
    }
  }

  openDeleteModal(doc) {
    this.setState({
      deleteModal: true,
      documentId: doc.id,
      documentName: doc.filename,
    });
  }

  closeModal() {
    this.setState({ deleteModal: false });
  }

  handleUpload = async (selectorFiles) => {
    const { user } = this.state;
    const formData = new FormData();
    let file = selectorFiles[0];
    const type = _.get(this.props, 'type', 'spr');
    this.setState({ errInvalidFile: '' });
    if (file && file.type.toLowerCase() === 'application/pdf') {
      formData.append('userId', user.id);
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('type', type);
      this.setState({ fileUploading: true });
      const {
        value: { success },
      } = await this.props.addDocument(formData);
      if (success) {
        this.setState({ fileUploading: false });
        this.fetchDocuments();
      }
    } else {
      let self = this;
      this.setState({
        errInvalidFile: 'Invalid file. Only pdf file can be uploaded.',
        fileUploading: false,
      });
      setTimeout(function () {
        self.setState({ errInvalidFile: '' });
      }, 3000);
    }
  };

  deleteDocument = async () => {
    const { documentId, documents } = this.state;
    this.setState({ isProcessing: true });
    const data = {
      id: documentId,
    };
    const {
      value: { success },
    } = await this.props.deleteDocument(data);
    if (success) {
      const index = _.findIndex(documents, function (d, i) {
        return parseInt(d.id) === parseInt(documentId);
      });
      if (index >= 0) {
        documents.splice(index, 1);
      }
      this.setState({
        documents,
        documentId: 0,
        documentName: '',
        isProcessing: false,
      });
      this.closeModal();
    } else {
      this.setState({ isProcessing: false });
    }
  };

  render() {
    const {
      documents,
      documentName,
      isProcessing,
      iAuthorization,
      errInvalidFile,
      fileUploading,
    } = this.state;
    return (
      <>
        <div className="card-container">
          <div className="card-wrapper">
            <p className="text-danger text-center">{errInvalidFile}</p>
            <div className="card mb-0">
              <div className="card-header d-flex align-items-center">
                <div>
                  <h5 className="card-title">Documents</h5>
                </div>
                <div className="ml-auto">
                  {iAuthorization && iAuthorization !== '' ? (
                    <div className="file-upload-wrapper">
                      <div className="file-upload">
                        <button
                          type="button"
                          className="btn btn-purple btn-sm w-150"
                        >
                          {fileUploading
                            ? 'Please wait ...'
                            : 'Upload Document'}
                        </button>
                        <input
                          className="file-upload-input"
                          type="file"
                          accept="application/pdf"
                          onChange={(e) => this.handleUpload(e.target.files)}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="">
                <table className="table table-document">
                  <tbody>
                    {documents.map((doc, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {doc.id ? (
                              <p onClick={() => this.fetchFileData(doc.id, doc.filename)} className="hover-underline">
                                {doc.filename}{' '}
                              </p>
                            ) : (
                              doc.filename
                            )}
                          </td>
                          <td className="text-right">
                            {iAuthorization ? (
                              <button
                                className="btn btn-link btn-remove"
                                onClick={() => this.openDeleteModal(doc)}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn hover-underline btn-view-usage"
                                onClick={() => this.fetchFileData(doc.id, doc.filename)}
                              >
                                Download
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.deleteModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Delete Document</h5>
              <p className="react-modal-subtitle mt-2">
                Are you want to delete {documentName}?
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={isProcessing}
                  onClick={() => this.deleteDocument()}
                >
                  {isProcessing ? 'Please wait ...' : 'Yes, Delete'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
