import { connect } from 'react-redux';

import { fetchMe, setUser, userImpersonate } from '../../../store/user/duck';
import {
  approveOrRejectPendingOrder,
  fetchEsimPendingOrders,
  getReport,
} from '../../../store/dashboard/duck';
import { getEsimQuantity } from '../../../store/sim/duck';

import PendingOrderComponent from './component';

const PendingOrderContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchEsimPendingOrders,
    getReport,
    approveOrRejectPendingOrder,
    userImpersonate,
    getEsimQuantity,
  }
)(PendingOrderComponent);

export default PendingOrderContainer;
