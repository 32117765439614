import { connect } from 'react-redux';

import UpdatePasswordComponent from './component';

const UpdatePasswordContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {}
)(UpdatePasswordComponent);

export default UpdatePasswordContainer;
