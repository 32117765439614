import { connect } from 'react-redux';

import { fetchMe, setUser } from '../../store/user/duck';
import { fetchSimsWithMostUsageByPlan } from '../../store/dashboard/duck';

import MostUsageSimsComponent from './component';

const MostUsageSimsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchSimsWithMostUsageByPlan,
  }
)(MostUsageSimsComponent);

export default MostUsageSimsContainer;
