/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Drawer } from 'antd';
import InputMask from 'react-text-mask';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon, CloseIcon } from '../../components/icons';
import {
  editPartnerUserSchema,
  editPartnerUserSchemaSpr,
  editPartnerUserSchemaFixed,
} from '../../utils/validations';
import { isAdmin } from '../../constants/common';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import './styles.scss';
const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class EditUserComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectPlanDrawer: false,
      isExistFixedPlans: true,
      selectFixedPlanDrawer: false,
      plans: [],
      filteredPlans: [],
      filteredFixedPlans: [],
      selectAllPlans: false,
      selectAllFixedPlans: false,
      selectedPlans: [],
      selectedFixedPlans: [],
      savedPlans: [],
      savedFixedPlans: [],
      lineOfBList: [],
      user: null,
      initialValues: {
        companyname: '',
        total_lines: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        apiKey: '',
        partnerCode: '',
        plans: [],
        role: 'spr_partner',
        lobId: 1,
        sprPartnerId: '',
        sprPartnerSecret: '',
        fixedPartnerId: '',
        fixedPartnerSecret: '',
        countryCode: '1',
      },
      userInfoToEdit: null,
      isEmailExists: false,
      isCompnayNameExists: false,
      isPhoneExists: false,
      isPlanError: false,
      isFixedPlanError: false,
      isDisableSavePlan: true,
      isFixedDisableSavePlan: true,
      addUserType: 'spr_partner',
      lobIdForValidation: 1,
      isPlanLoading: true,
      searchText: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Edit User | UltraIoT';
    const {
      value: { lineOfBList },
    } = await this.props.fetchLoB();
    this.setState({ lineOfBList });
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      this.setState({ user });
      if (!isAdmin(user)) {
        this.props.history.push('/dashboard');
      }
      const userInfoToEdit = this.props.location.state.userInfoToEdit;
      this.setState({ userInfoToEdit: userInfoToEdit });
      this.setState({
        initialValues: {
          partnerCode: _.get(userInfoToEdit, 'partner_code', ''),
          countryCode:
            _.get(userInfoToEdit, 'countryCode', '') !== ''
              ? _.get(userInfoToEdit, 'countryCode', '1')
              : '1',
          brandName: _.get(userInfoToEdit, 'brandName', ''),
          sprPartnerId: _.get(userInfoToEdit, 'sprPartnerId', ''),
          sprPartnerSecret: _.get(userInfoToEdit, 'sprPartnerSecret', ''),
          fixedPartnerId: _.get(userInfoToEdit, 'fixedPartnerId', ''),
          fixedPartnerSecret: _.get(userInfoToEdit, 'fixedPartnerSecret', ''),
          maid: _.get(userInfoToEdit, 'ma_id', ''),
          apiKey: _.get(userInfoToEdit, 'apiKey', ''),
          companyname: _.get(userInfoToEdit, 'companyname', ''),
          total_lines: _.get(userInfoToEdit, 'total_lines', ''),
          firstname: _.get(userInfoToEdit, 'firstname', ''),
          lastname: _.get(userInfoToEdit, 'lastname', ''),
          email: _.get(userInfoToEdit, 'email', ''),
          phone:
            _.get(userInfoToEdit, 'phone', '') !== ''
              ? this.formatPhoneNumber(_.get(userInfoToEdit, 'phone', ''))
              : '',
          lobId: _.get(userInfoToEdit, 'lobId', ''),
          plans: [],
          role: _.get(userInfoToEdit, 'role', ''),
        },
        addUserType: _.get(userInfoToEdit, 'role', ''),
        lobIdForValidation: _.get(userInfoToEdit, 'lobId', 1),
      });
      this.getPlansListing();
    }
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = '';
    if (phoneNumberString.length === 8) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
    } else if (phoneNumberString.length === 9) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    } else {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    }
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  getPlansListing = async () => {
    try {
      const { userInfoToEdit } = this.state;
      const {
        value: { success, sprPlans, fixedPlans },
      } = await this.props.fetchAllUserPlanById(userInfoToEdit.id);
      if (success) {
        this.setState({
          plans: sprPlans,
          filteredPlans: sprPlans,
          selectedPlans: sprPlans.filter((element) => element.status === 10),
          savedPlans: sprPlans.filter((element) => element.status === 10),
          fixedPlans,
          filteredFixedPlans: fixedPlans,
          selectedFixedPlans: fixedPlans.filter(
            (element) => element.status === 10
          ),
          savedFixedPlans: fixedPlans.filter(
            (element) => element.status === 10
          ),
          isPlanLoading: false,
        });
      }
    } catch (error) {
      this.setState({ isPlanLoading: false });
    }
  };

  // handleAllPlansChecked = (event) => {
  //   this.setState({ selectAllPlans: event.target.checked, selectedPlans: event.target.checked ? this.state.plans : [] },() => {
  //     this.setState({ isDisableSavePlan: _.size(this.state.selectedPlans) === 0 ? true : false })
  //   })
  // }
  // handleAllFixedPlansChecked = (event) => {
  //   this.setState({ selectAllFixedPlans: event.target.checked, selectedFixedPlans: event.target.checked ? this.state.fixedPlans : [] },() => {
  //     this.setState({ isFixedDisableSavePlan: _.size(this.state.selectedFixedPlans) === 0 ? true : false })
  //   })
  // }

  selectPlanDrawer = () => {
    const { savedPlans } = this.state;
    document.body.style.overflow = 'hidden';
    this.setState({
      selectPlanDrawer: true,
      selectedPlans: _.size(savedPlans) === 0 ? [] : savedPlans,
    });
  };

  selectFixedPlanDrawer = () => {
    const { savedFixedPlans } = this.state;
    document.body.style.overflow = 'hidden';
    this.setState({
      selectFixedPlanDrawer: true,
      selectedFixedPlans: _.size(savedFixedPlans) === 0 ? [] : savedFixedPlans,
    });
  };

  onClose = () => {
    document.body.style.overflow = 'visible';
    const { plans, fixedPlans } = this.state;
    this.setState({
      selectPlanDrawer: false,
      selectFixedPlanDrawer: false,
      searchText: '',
      filteredPlans: plans,
      filteredFixedPlans: fixedPlans,
      // isDisableSavePlan: _.size(this.state.savedPlans) === 0 ? true : false,
      // isPlanError: _.size(this.state.savedPlans) === 0 ? true : false,
      // isFixedDisableSavePlan: _.size(this.state.savedFixedPlans) === 0 ? true : false,
      // isFixedPlanError: _.size(this.state.savedFixedPlans) === 0 ? true : false,
    });
  };

  // selectPlan(plan) {
  //   const plans = _.cloneDeep(this.state.selectedPlans);
  //   const index = _.findIndex(plans, function (p) {
  //     return String(p.id) === String(plan.id);
  //   });
  //   if (index === -1) {
  //     plans.push({
  //       id: plan.id,
  //       title: plan.title
  //     });
  //   } else {
  //     plans.splice(index, 1);
  //   }
  //   this.setState({
  //     selectedPlans: plans,
  //     isDisableSavePlan: _.size(plans) === 0 ? true : false,
  //     isPlanError: _.size(plans) === 0 ? true : false
  //   });
  // }

  // selectFixedPlan(plan) {
  //   const plans = _.cloneDeep(this.state.selectedFixedPlans);
  //   const index = _.findIndex(plans, function (p) {
  //     return String(p.id) === String(plan.id);
  //   });
  //   if (index === -1) {
  //     plans.push({
  //       id: plan.id,
  //       offername: plan.offername
  //     });
  //   } else {
  //     plans.splice(index, 1);
  //   }
  //   this.setState({
  //     selectedFixedPlans: plans,
  //     isFixedDisableSavePlan: _.size(plans) === 0 ? true : false,
  //     isFixedPlanError: _.size(plans) === 0 ? true : false
  //   });
  // }

  // savePlans = () => {
  //   this.setState({
  //     selectPlanDrawer: false,
  //     isPlanError: false,
  //     savedPlans: this.state.selectedPlans
  //   })
  // }
  // savedFixedPlans = () => {
  //   this.setState({
  //     selectFixedPlanDrawer: false,
  //     isFixedPlanError: false,
  //     savedFixedPlans: this.state.selectedFixedPlans
  //   })
  // }

  handleUpdateUser = async (values, { setSubmitting }) => {
    try {
      const { userInfoToEdit } = this.state;
      let totalLines = values.total_lines
        ? values.total_lines.toString().replace(/,/g, '')
        : 0;
      values.total_lines = totalLines;
      values.userId = userInfoToEdit.id;
      delete values.role;

      const deepCopyValues = _.cloneDeep(values);
      deepCopyValues.phone = deepCopyValues.phone.replace(/[^\d]/g, '');
      const {
        value: { success },
      } = await this.props.updatePartnerProfile(deepCopyValues);
      if (success) {
        setSubmitting(false);
        this.setState({ errorMessage: '' });
        this.props.history.goBack();
      }
    } catch (err) {
      const { message } = err;
      setSubmitting(false);
      if (_.get(err, 'duplicateKey', '') === 'email') {
        this.setState({ isEmailExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'phone') {
        this.setState({ isPhoneExists: true });
      } else if (_.get(err, 'duplicateKey', '') === 'companyname') {
        this.setState({ isCompnayNameExists: true });
      } else {
        this.setState({ errorMessage: message });
      }
    }
  };

  searchPlans = (event) => {
    const { plans } = this.state;
    this.setState({ searchText: event.target.value });
    let searchText = event.target.value.trim().toLowerCase();
    const filterData = plans.filter((item, key) =>
      `${item.offerId} - ${item.title}`.toLowerCase().includes(searchText)
    );
    if (event.target.value === '') {
      this.setState({ filteredPlans: plans });
    } else {
      this.setState({ filteredPlans: filterData });
    }
  };

  searchFixedPlans = (event) => {
    const { fixedPlans } = this.state;
    this.setState({ searchText: event.target.value });
    let searchText = event.target.value.trim().toLowerCase();
    const filterData = fixedPlans.filter((item, key) =>
      `${item.offerId} - ${item.title}`.toLowerCase().includes(searchText)
    );
    if (event.target.value === '') {
      this.setState({ filteredFixedPlans: fixedPlans });
    } else {
      this.setState({ filteredFixedPlans: filterData });
    }
  };

  render() {
    const {
      errorMessage,
      filteredPlans,
      // savedPlans,
      // selectedPlans,
      initialValues,
      isEmailExists,
      isPhoneExists,
      isCompnayNameExists,
      // isPlanError,
      // isDisableSavePlan,
      // isFixedDisableSavePlan,
      // isFixedPlanError,
      filteredFixedPlans,
      // selectedFixedPlans,
      // savedFixedPlans,
      lobIdForValidation,
      addUserType,
    } = this.state;
    // let _plans = []
    // let _plansFixed = []
    // _.forEach(savedPlans, function (p) {
    //   _plans.push(p.title)
    // })
    // _.forEach(savedFixedPlans, function (p) {
    //   _plansFixed.push(p.offername)
    // })
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="side-heading">
                <h4 className="title">Edit User</h4>
              </div>
            </div>
            <div className="col-md-10">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.handleUpdateUser}
                // validationSchema={editPartnerUserSchema}
                validationSchema={
                  addUserType === 'spr_partner' &&
                  parseInt(lobIdForValidation) === 2
                    ? editPartnerUserSchema
                    : addUserType === 'spr_partner' &&
                      parseInt(lobIdForValidation) === 1
                    ? editPartnerUserSchemaSpr
                    : addUserType === 'spr_partner' &&
                      parseInt(lobIdForValidation) === 3
                    ? editPartnerUserSchemaFixed
                    : editPartnerUserSchema
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card mb-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                name="lobId"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    lobId: e.target.value,
                                    lobIdForValidation: e.target.value,
                                  });
                                }}
                                value={values.lobId || 1}
                              >
                                {_.get(this.state, 'lineOfBList', []).map(
                                  (lob, i) => {
                                    return (
                                      <option key={i} value={lob.id}>
                                        {lob.name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <label className="material-textfield-label">
                                Line of Business
                              </label>
                              <div className="invalid-feedback">
                                {errors.role && touched.role && errors.role}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control form-control-lg custom-select material-textfield-input"
                                name="role"
                                value={values.role}
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    addUserType: e.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                required
                              >
                                <option
                                  disabled
                                  className="hideDefaultOption"
                                ></option>
                                {values.role === 'admin' ||
                                values.role === 'sysops' ? (
                                  <option value="admin">Ultra Admin</option>
                                ) : (
                                  <option value="spr_partner">Partners</option>
                                )}
                              </select>
                              <label className="material-textfield-label">
                                Account Type
                              </label>
                              <div className="invalid-feedback">
                                {errors.role && touched.role && errors.role}
                              </div>
                            </div>
                          </div>
                        </div>
                        {addUserType === 'spr_partner' && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="brandName"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.setState({
                                      errorMessage: '',
                                      brandName: e.target.value,
                                    });
                                  }}
                                  // onBlur={this.handleBrandNameBlur.bind(this, values)}
                                  value={values.brandName || ''}
                                  disabled={true}
                                  maxLength="50"
                                  required
                                />
                                <label className="material-textfield-label">
                                  Brand Name{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="invalid-feedback">
                                  {errors.brandName &&
                                    touched.brandName &&
                                    errors.brandName}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group material-textfield">
                                <input
                                  type="text"
                                  className="form-control form-control-lg material-textfield-input"
                                  name="partnerCode"
                                  onChange={(e) => {
                                    handleChange(e);
                                    this.setState({
                                      errorMessage: '',
                                      partnerCode: e.target.value,
                                    });
                                  }}
                                  disabled={true}
                                  value={values.partnerCode || ''}
                                  maxLength="15"
                                  required
                                />
                                <label className="material-textfield-label">
                                  Partner Code{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="invalid-feedback">
                                  {errors.partnerCode &&
                                    touched.partnerCode &&
                                    errors.partnerCode}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="maid"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    maid: e.target.value,
                                  });
                                }}
                                disabled={
                                  addUserType !== 'spr_partner' ? false : true
                                }
                                value={values.maid || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                MA ID <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.maid && touched.maid && errors.maid}
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="companyname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isCompnayNameExists: false,
                                    companyname: e.target.value,
                                  });
                                }}
                                value={values.companyname || ''}
                                disabled={
                                  addUserType !== 'spr_partner' ? false : true
                                }
                                required
                              />
                              <label className="material-textfield-label">
                                Company Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.companyname &&
                                  touched.companyname &&
                                  errors.companyname}
                              </div>
                              {isCompnayNameExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Company name already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {addUserType === 'spr_partner' &&
                          (parseInt(values.lobId) === 1 ||
                            parseInt(values.lobId) === 2) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="sprPartnerId"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        sprPartnerId: e.target.value,
                                      });
                                    }}
                                    value={values.sprPartnerId || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    SPR Partner Id
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.sprPartnerId &&
                                      touched.sprPartnerId &&
                                      errors.sprPartnerId}
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="sprPartnerSecret"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        sprPartnerSecret: e.target.value,
                                      });
                                    }}
                                    value={values.sprPartnerSecret || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    SPR Partner Secret{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.sprPartnerSecret &&
                                      touched.sprPartnerSecret &&
                                      errors.sprPartnerSecret}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {addUserType === 'spr_partner' &&
                          (parseInt(values.lobId) === 2 ||
                            parseInt(values.lobId) === 3) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="fixedPartnerId"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        fixedPartnerId: e.target.value,
                                      });
                                    }}
                                    value={values.fixedPartnerId || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    Fixed Partner Id
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.fixedPartnerId &&
                                      touched.fixedPartnerId &&
                                      errors.fixedPartnerId}
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group material-textfield">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg material-textfield-input"
                                    name="fixedPartnerSecret"
                                    onChange={(e) => {
                                      handleChange(e);
                                      this.setState({
                                        errorMessage: '',
                                        fixedPartnerSecret: e.target.value,
                                      });
                                    }}
                                    value={values.fixedPartnerSecret || ''}
                                    required
                                  />
                                  <label className="material-textfield-label">
                                    Fixed Partner Secret{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="invalid-feedback">
                                    {errors.fixedPartnerSecret &&
                                      touched.fixedPartnerSecret &&
                                      errors.fixedPartnerSecret}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="firstname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    firstname: e.target.value,
                                  });
                                }}
                                value={values.firstname || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                First Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.firstname &&
                                  touched.firstname &&
                                  errors.firstname}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="lastname"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    lastname: e.target.value,
                                  });
                                }}
                                value={values.lastname || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.lastname &&
                                  touched.lastname &&
                                  errors.lastname}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group material-textfield">
                              <PhoneInput
                                inputProps={{
                                  readOnly: true,
                                  name: 'phone',
                                  required: true,
                                  autoFocus: true,
                                  className:
                                    'form-control form-control-lg material-textfield-input',
                                }}
                                country={'us'}
                                value={values.countryCode}
                                onChange={(phone, val) =>
                                  this.setState({
                                    initialValues: {
                                      ...values,
                                      countryCode: val.dialCode,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="form-group material-textfield">
                              <InputMask
                                guide={false}
                                type="text"
                                id="phone"
                                keepCharPositions={false}
                                mask={phoneNumberMask}
                                className="form-control form-control-lg material-textfield-input"
                                name="phone"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isPhoneExists: false,
                                    phone: e.target.value,
                                  });
                                }}
                                value={values.phone || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Phone Number
                              </label>
                              <div className="invalid-feedback">
                                {errors.phone && touched.phone && errors.phone}
                              </div>
                              {isPhoneExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Phone number already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="email"
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({
                                    errorMessage: '',
                                    isEmailExists: false,
                                    email: e.target.value,
                                  });
                                }}
                                value={values.email || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Email Address{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="invalid-feedback">
                                {errors.email && touched.email && errors.email}
                              </div>
                              {isEmailExists && (
                                <div>
                                  <div className="invalid-feedback">
                                    Email already in use.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* {values.role !== 'spr_partner' ?
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="maid"
                              onChange={(e) => {
                                handleChange(e)
                                this.setState({ errorMessage: '', maid: e.target.value })
                              }}
                              value={values.maid || ''}
                              required
                            />
                            <label className="material-textfield-label">MA ID <span className="text-danger">*</span></label>
                            <div className="invalid-feedback">{errors.maid && touched.maid && errors.maid}</div>
                          </div>
                          :
                          <div></div>
                        } */}
                        {addUserType === 'spr_partner' && (
                          <div className="form-group material-textfield">
                            <NumberFormat
                              className="form-control form-control-lg material-textfield-input"
                              name="total_lines"
                              allowLeadingZeros={false}
                              isAllowed={(values) =>
                                values.value !== '0' ? true : false
                              }
                              thousandSeparator={true}
                              decimalScale={0}
                              allowNegative={false}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  errorMessage: '',
                                  total_lines: e.target.value,
                                });
                              }}
                              value={values.total_lines || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Total Approved Lines{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="invalid-feedback">
                              {errors.total_lines &&
                                touched.total_lines &&
                                errors.total_lines}
                            </div>
                          </div>
                        )}

                        {/* <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control form-control-lg material-textfield-input"
                            name="companyname"
                            onChange={(e) => {
                              handleChange(e)
                              this.setState({ errorMessage: '', isCompnayNameExists: false, companyname: e.target.value })
                            }}
                            value={values.companyname || ''}
                            required
                          />
                          <label className="material-textfield-label">Company Name <span className="text-danger">*</span></label>
                          <div className="invalid-feedback">{errors.companyname && touched.companyname && errors.companyname}</div>
                          {isCompnayNameExists &&
                            <div>
                              <div className="invalid-feedback">Company name already in use.</div>
                            </div>
                          }
                        </div> */}
                        {(parseInt(values.lobId) === 1 ||
                          parseInt(values.lobId) === 2) && (
                          <>
                            <label className="textfield-label">
                              <b>SPR Plans</b>{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div
                              className="form-group textfield-group"
                              onClick={this.selectPlanDrawer}
                            >
                              <label className="textfield-label">
                                View Plan(s)
                              </label>
                              <div className="textfield-control"></div>
                            </div>
                          </>
                        )}
                        {(parseInt(values.lobId) === 3 ||
                          parseInt(values.lobId) === 2) && (
                          <>
                            <label className="textfield-label">
                              <b>Fixed Wireless Plans</b>{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div
                              className="form-group textfield-group"
                              onClick={this.selectFixedPlanDrawer}
                            >
                              <label className="textfield-label">
                                View Plan(s)
                              </label>
                              <div className="textfield-control"></div>
                            </div>
                          </>
                        )}
                        <div className="invalid-feedback">{errorMessage}</div>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="btn btn-dark btn-lg w-100"
                        >
                          {isSubmitting ? 'Please wait...' : 'Update User'}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        <Drawer
          placement="right"
          width="675px"
          getContainer={false}
          closable={false}
          onClose={this.onClose}
          className="drawer-main"
          visible={this.state.selectPlanDrawer}
        >
          <div className="drawer-header">
            <div className="drawer-title">Select Plan(s)</div>
            <button className="btn btn-link" onClick={this.onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="drawer-body">
            <div className="search-form">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Search Plans..."
                value={this.state.searchText}
                onChange={this.searchPlans}
              />
            </div>
            <div className="drawer-body-main-inner">
              <div className="drawer-body-inner pt-2">
                {this.state.isPlanLoading ? (
                  <div className="loadingCenter">
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: '#000' }}
                          spin
                        />
                      }
                    />
                  </div>
                ) : (
                  <ul>
                    {/*<li>
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          className="custom-checkbox--input"
                          type="checkbox"
                          checked={filteredPlans.length === selectedPlans.length}
                          onChange={(event) => this.handleAllPlansChecked(event)}
                        />
                        Select All
                        <span className="checkmark"></span>
                      </label>
                    </li>*/}
                    <ul className="select-check-list-edit">
                      <li>
                        <h4>Plan Name</h4>
                      </li>
                      <li>
                        <h4>Status</h4>
                      </li>
                    </ul>
                    {_.size(filteredPlans) > 0 ? (
                      filteredPlans
                        .slice()
                        .sort((a, b) => {
                          const statusOrder = {
                            Active: 1,
                            Inactive: 2,
                          };

                          return statusOrder[a.status] - statusOrder[b.status];
                        })
                        .map((plan) => {
                          return (
                            <>
                              <ul
                                className="select-check-list-edit"
                                key={plan.id}
                              >
                                <li>
                                  {plan.offerId} - {plan.title}
                                </li>
                                <li>{plan.status}</li>
                              </ul>
                            </>
                          );
                        })
                    ) : (
                      <div className="blank__table__card">
                        <div className="blank__table__card__inner">
                          No record found
                        </div>
                      </div>
                    )}
                  </ul>
                )}
              </div>
              {/*<div className="drawer-body-inner-bottom">
                <button disabled={isDisableSavePlan} onClick={this.savePlans} className="btn btn-dark btn-lg w-100">
                  Save Plan(s)
                </button>
              </div>*/}
            </div>
          </div>
        </Drawer>
        <Drawer
          placement="right"
          width="675px"
          getContainer={false}
          closable={false}
          onClose={this.onClose}
          className="drawer-main"
          visible={this.state.selectFixedPlanDrawer}
        >
          <div className="drawer-header">
            <div className="drawer-title">Select Fixed Plan(s)</div>
            <button className="btn btn-link" onClick={this.onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="drawer-body">
            <div className="search-form">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Search Plans..."
                value={this.state.searchText}
                onChange={this.searchFixedPlans}
              />
            </div>
            <div className="drawer-body-main-inner">
              <div className="drawer-body-inner">
                {this.state.isPlanLoading ? (
                  <div className="loadingCenter">
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: '#000' }}
                          spin
                        />
                      }
                    />
                  </div>
                ) : (
                  <ul>
                    {/*<li>
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          className="custom-checkbox--input"
                          type="checkbox"
                          checked={filteredFixedPlans.length === selectedFixedPlans.length}
                          onChange={(event) => this.handleAllFixedPlansChecked(event)}
                        />
                        Select All
                        <span className="checkmark"></span>
                      </label>
                    </li>*/}
                    <ul className="select-check-list-edit">
                      <li>
                        <h4>Plan Name</h4>
                      </li>
                      <li>
                        <h4>Status</h4>
                      </li>
                    </ul>
                    {_.size(filteredFixedPlans) > 0 ? (
                      filteredFixedPlans
                        .slice()
                        .sort((a, b) => {
                          const statusOrder = {
                            Active: 1,
                            Inactive: 2,
                          };

                          return statusOrder[a.status] - statusOrder[b.status];
                        })
                        .map((plan) => {
                          return (
                            <>
                              <ul
                                className="select-check-list-edit"
                                key={plan.id}
                              >
                                <li>
                                  {plan.offerId} - {plan.title}
                                </li>
                                <li>{plan.status}</li>
                              </ul>
                            </>
                          );
                        })
                    ) : (
                      <div className="blank__table__card">
                        <div className="blank__table__card__inner">
                          No record found
                        </div>
                      </div>
                    )}
                  </ul>
                )}
              </div>
              <div className="drawer-body-inner-bottom">
                {/*<button disabled={isFixedDisableSavePlan} onClick={this.savedFixedPlans} className="btn btn-dark btn-lg w-100">
                  Save Fixed Plan(s)
              </button>*/}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
