import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import ReactModal from 'react-modal';
import TopNavigation from '../../TopNavigation/container';
import SidebarNavigation from '../../SidebarNavigation/component';
import { BackArrowIcon, CloseIcon } from '../../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import './styles.scss';
import NumberFormat from 'react-number-format';
import { message } from 'antd';

function PendingOrderComponent(props) {
  document.title = 'Recently Activated SIMs | UltraIoT';
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [userId, setUserId] = useState('');
  const [confirmLoading, setconfirmLoading] = useState(false);
  const [totalCounts, setTotalCounts] = useState(20);
  const [page, setPage] = useState(1);
  const [recenteSIMOrders, setOrderData] = useState([]);
  const [ordertype, setOrderType] = useState('spr');
  const [orderNumber, setOrderNumber] = useState();
  const [status, setStatus] = useState();
  const [isApproveModal, setApproveModal] = useState(false);
  const [isRejectModal, setRejectModal] = useState(false);
  const [isApproveFailsPopup, setIsApproveFailsPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const countPerPage = 30;
  const [eSIMQuantity, setESIMQuantity] = useState(0);
  const [isEsimQuantity, setIsEsimQuantity] = useState(false);

  useEffect(() => {
    const getRecentActivatedOrders = async (pge) => {
      try {
        const body = { limit: countPerPage, page: pge };
        setLoading(true);
        const {
          value: { success, data },
        } = await props.fetchEsimPendingOrders(body);
        if (success) {
          const { totalOrders, ordersArray } = data;
          setOrderData(ordersArray);
          setTotalCounts(totalOrders);
          setLoading(false);
        }
      } catch (error) {
        setOrderData([]);
        setLoading(false);
        
      }
    };
    
    getRecentActivatedOrders(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => { 
    const getEsimQuantity = async () => {
      try {
        const {
          value: { quantity },
        } = await props.getEsimQuantity();
        setESIMQuantity(quantity);
        setIsEsimQuantity(true);
      } catch (error) {
        setESIMQuantity(0);
      }
    };

    getEsimQuantity();
    // eslint-disable-next-line
  }, []);

  const setImpersonate = (userId, ordertype) => {
    if (userId) {
      setUserId(userId);
      setOrderType(ordertype);
      setConfirmModal(true);
      setconfirmLoading(false);
    }
  };

  const getImpersonateUser = async () => {
    if (userId !== '') {
      try {
        const {
          value: { success, token },
        } = await props.userImpersonate(userId);
        if (success) {
          const auth = localStorage.getItem('Authorization');
          localStorage.setItem('i_Authorization', auth);
          localStorage.setItem('Authorization', token);
          if (ordertype && ordertype === 'fixed') {
            props.history.push('/fixed-orders');
          } else if (ordertype && ordertype === 'eSIM') {
            props.history.push('/eSIM-orders');
          } else {
            props.history.push('/spr-orders');
          }
          setConfirmModal(false);
          setconfirmLoading(false);
          let lastPath = _.get(this.props, 'location.pathname', '/all-users');
          localStorage.setItem('@lastActiveTab', lastPath);
        }
      } catch (err) {
        setConfirmModal(false);
        setconfirmLoading(false);
      }
    }
  };

  const onConfirmed = () => {
    setConfirmModal(false);
    setconfirmLoading(false);
    getImpersonateUser();
  };

  const setOrderAndStatus = async (userId, orderNumber, status) => {
    if (status === 'Approve') {
      setUserId(userId);
      setOrderNumber(orderNumber);
      setStatus(status);
      setApproveModal(true);
      setconfirmLoading(false);
    }
    if (status === 'Reject') {
      setUserId(userId);
      setOrderNumber(orderNumber);
      setStatus(status);
      setRejectModal(true);
      setconfirmLoading(false);
    }
  };

  const setApproveOrder = async (userId, orderNumber, status) => {
    if (userId) {
      const data = {
        orderId: orderNumber,
        status,
      };
      try {
        setconfirmLoading(true);
        setLoading(true);
        const {
          value: { success },
        } = await props.approveOrRejectPendingOrder(data);
        if (success) {
          const orderMessage = {
            Approve: 'Your order has been approved',
            Reject: 'Your order has been canceled',
          };
          setUserId(userId);
          setOrderType(ordertype);
          setApproveModal(false);
          setRejectModal(false);
          setconfirmLoading(false);
          setIsApproveFailsPopup(false);
          setLoading(false);
          message.success({ key: 'updatable', content: orderMessage[status] });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      } catch (err) {
        const errorMessage = _.get(err, 'message', 'Something went wrong...');
        setErrorMessage(errorMessage);
        setApproveModal(false);
        setRejectModal(false);
        setLoading(false);
        setIsApproveFailsPopup(true);
      }
    }
  };

  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.push('/spr-dashboard')}
            >
              <BackArrowIcon className="back-icon" /> Back to SPR Dashboard
            </button>
          </div>
          <div className="col-md-8 text-right"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {recenteSIMOrders && recenteSIMOrders.length > 0 ? (
                <>
                  <div className="card mb-3">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="card-title">
                          eSim Orders Pending Approval
                        </h5>
                      </div>
                      {isEsimQuantity === true && (
                        <p className="m-0">
                          Unallocated eSIMs Available: {eSIMQuantity}
                        </p>
                      )}
                    </div>
                    <div className="table-responsive">
                      <table className="table custom-table-secondary table-nowidth">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">
                                Order Date{' '}
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Company
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Order Number
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Ordered By
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                eSIMs Ordered
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recenteSIMOrders &&
                            recenteSIMOrders.length > 0 &&
                            recenteSIMOrders.map((orders, i) => (
                              <tr key={i}>
                                <td>
                                  {moment(orders.orderDate)
                                    .tz(moment.tz.guess())
                                    .format('MMM DD, YYYY h:mm a z')}
                                </td>
                                <td>
                                  <span>{orders.companyName}</span>
                                </td>
                                <td>
                                  <span>{orders.orderNumber}</span>
                                </td>
                                <td>
                                  <span
                                    onClick={() =>
                                      setImpersonate(
                                        orders.userId,
                                        (orders.ordertype = 'eSIM')
                                      )
                                    }
                                    className="text-underline btn-link-orange"
                                  >
                                    {orders.orderedBy}
                                  </span>
                                </td>
                                <td>
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={orders.orderedSims}
                                  />
                                </td>
                                <td>
                                  <span
                                    onClick={() =>
                                      setOrderAndStatus(
                                        orders.userId,
                                        orders.orderNumber,
                                        'Approve'
                                      )
                                    }
                                    className="text-underline text-green btn-link-orange"
                                  >
                                    Approve
                                  </span>
                                </td>
                                <td>
                                  <span
                                    onClick={() =>
                                      setOrderAndStatus(
                                        orders.userId,
                                        orders.orderNumber,
                                        'Reject'
                                      )
                                    }
                                    className="text-underline btn-link-orange"
                                  >
                                    Reject
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={countPerPage}
                      defaultCurrent={page}
                      total={totalCounts}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  <div className="blank__table__card__inner">
                    No record found
                  </div>
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
      <ReactModal
        isOpen={isConfirmModal}
        onRequestClose={() => setConfirmModal(false)}
        ariaHideApp={false}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">
              Impersonate
            </h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => setConfirmModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-subtitle">
              Are you sure you want to impersonate this user?
            </p>
            <div className="text-right">
              <button
                onClick={onConfirmed}
                className="btn btn-dark btn-lg w-150"
              >
                {confirmLoading ? 'Please Wait...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isApproveModal}
        onRequestClose={() => setApproveModal(false)}
        ariaHideApp={false}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Confirm</h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => setApproveModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-subtitle">
              Approving this order will allocate eSIMS to partner and complete
              the order. Are you sure you want to proceed?
            </p>
            <div className="text-right mr-2">
              <button
                onClick={() => setApproveOrder(userId, orderNumber, status)}
                className="btn btn-dark btn-sm w-250 mr-2"
              >
                {confirmLoading ? 'Please Wait...' : 'Yes'}
              </button>
              <button
                onClick={() => setApproveModal(false)}
                className="btn btn-dark btn-sm w-250"
                disabled={confirmLoading}
              >
                {'No'}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isRejectModal}
        onRequestClose={() => setRejectModal(false)}
        ariaHideApp={false}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Confirm</h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => setRejectModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-subtitle">
              Rejecting this order will not allocate eSIMS to partner and mark
              the order as cancelled. Are you sure you want to proceed?
            </p>
            <div className="text-right mr-2">
              <button
                onClick={() => setApproveOrder(userId, orderNumber, status)}
                className="btn btn-dark btn-sm w-250 mr-2"
              >
                {confirmLoading ? 'Please Wait...' : 'Yes'}
              </button>
              <button
                onClick={() => setRejectModal(false)}
                className="btn btn-dark btn-sm w-250"
                disabled={confirmLoading}
              >
                {'No'}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isApproveFailsPopup}
        onRequestClose={() => setIsApproveFailsPopup(false)}
        ariaHideApp={false}
        contentLabel="Esim Port-In Fails"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-body">
            <h5 className="react-modal-title react-modal-title-md">
              Order request fails
            </h5>
            <p className="react-modal-subtitle mt-3">{errorMessage}</p>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => {
                setIsApproveFailsPopup(false);
              }}
            >
              <CloseIcon />
            </button>
            <div className="text-right mt-3">
              <button
                className="btn btn-dark w-150"
                onClick={() => {
                  setIsApproveFailsPopup(false);
                }}
              >
                {'OK'}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default PendingOrderComponent;
