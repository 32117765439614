import { Record } from 'immutable';
import { assign } from 'lodash';
import { combineEpics } from 'redux-observable';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const FETCH_SPR_PLANS = 'ultra/plan/FETCH_SPR_PLANS';
export const FETCH_LOB = 'ultra/plan/FETCH_LOB';
export const FETCH_FIXED_PLAN = 'ultra/plan/FETCH_FIXED_PLAN';
export const FETCH_FIXED_PLAN_BY_USERID =
  'ultra/plan/FETCH_FIXED_PLAN_BY_USERID';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const fetchSPRPlans = () => {
  return {
    type: FETCH_SPR_PLANS,
    payload: api.fetchSPRPlans(),
  };
};

export const fetchLoB = () => {
  return {
    type: FETCH_LOB,
    payload: api.fetchLoB(),
  };
};

export const fetchFixedPlans = () => {
  return {
    type: FETCH_FIXED_PLAN,
    payload: api.fetchFixedPlans(),
  };
};

export const fetchFixedPlansByUserId = () => {
  return {
    type: FETCH_FIXED_PLAN_BY_USERID,
    payload: api.fetchFixedPlansByUserId(),
  };
};

/***********************************
 * Epics
 ***********************************/

export const planEpic = combineEpics();
