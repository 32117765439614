/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import PasswordStrengthStatus from '../../components/PasswordStengthStatus';
import InputMask from 'react-text-mask';
import Logo from '../../images/logo.png';
import { Formik, ErrorMessage } from 'formik';
import './styles.scss';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default class AcceptInvitationComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      initialValues: {
        companyname: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
      },
      id: 0,
      isProcessing: false,
      passwordStrength: {},
    };
  }

  componentDidMount = async () => {
    document.title = 'Accept Invitation | UltraIoT';
    const {
      match: {
        params: { token },
      },
      fetchInviteUserDetail,
    } = this.props;
    const {
      value: { data },
    } = await fetchInviteUserDetail(token);
    if (data) {
      this.setState({
        initialValues: {
          companyname: data.companyname,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          phone: data.phone,
          password: '',
        },
        id: data.id,
      });
    }
  };

  validatePassword = (values) => {
    const errors = {};
    if (!values.companyname) {
      errors.companyname = 'Company name is required';
    }
    if (values.companyname && !/^[a-zA-Z 0-9]+$/.test(values.companyname)) {
      errors.companyname =
        'Company Name should not contain any special character';
    }
    if (!values.firstname) {
      errors.firstname = 'First name is required';
    }
    if (!values.lastname) {
      errors.lastname = 'Last name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }

    let obj = {};
    // Special Character
    if (new RegExp('[$@$!%*#?&]').test(values.password)) {
      obj.specialChar = true;
    }
    // Uppercase Alpabates
    if (new RegExp('[A-Z]').test(values.password)) {
      obj.upperChar = true;
    }
    // Numbers
    if (new RegExp('[0-9]').test(values.password)) {
      obj.numbers = true;
    }
    // Lowercase Alphabates
    if (new RegExp('[a-z]').test(values.password)) {
      obj.lowerChar = true;
    }
    // Length should be 8 char
    if (new RegExp('.{8,}').test(values.password)) {
      obj.charLength = true;
    }

    if (Object.keys(obj).length > 0 && Object.keys(obj).length < 5) {
      errors.password = 'Resolve all password pattern checks to proceed.';
    }

    this.setState({ passwordStrength: obj });
    return errors;
  };

  handleAcceptInvite = async (values, { setSubmitting }) => {
    try {
      const { id } = this.state;
      this.setState({ errorMessage: '', isProcessing: true });
      values.companyname = values.companyname.trim();
      const payload = {
        ...values,
        id,
      };
      const {
        value: { success, token },
      } = await this.props.acceptInvite(payload);
      if (success) {
        localStorage.setItem('Authorization', token);
        this.setState({ errorMessage: '', isProcessing: false });
        this.props.history.push('/dashboard');
      }
    } catch (err) {
      const { success, message } = _.get(err, 'data', err);
      if (!success) {
        this.setState({ errorMessage: message, isProcessing: false });
      }
    }
  };

  render() {
    const { initialValues, errorMessage, passwordStrength } = this.state;
    return (
      <div className="signup">
        <div className="signup-inner d-flex align-items-center">
          <div className="signup-body">
            <div className="card">
              <div className="card-body">
                <div className="card-inner-heading">
                  <div className="logo">
                    <img src={Logo} alt="Ultra" />
                  </div>
                  <p className="subtitle">
                    Welcome to Ultra, fill out the form below to get setup
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={this.handleAcceptInvite}
                  validate={this.validatePassword}
                  // validationSchema={acceptInvitationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          className="form-control form-control-lg material-textfield-input"
                          name="companyname"
                          onChange={handleChange}
                          value={values.companyname || ''}
                          required
                        />
                        <label className="material-textfield-label">
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <ErrorMessage
                          component="span"
                          name="companyname"
                          className="invalid-feedback d-block"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="firstname"
                              onChange={handleChange}
                              value={values.firstname || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="firstname"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="lastname"
                              onChange={handleChange}
                              value={values.lastname || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="lastname"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control form-control-lg material-textfield-input"
                              name="email"
                              autoComplete="off"
                              onChange={handleChange}
                              value={values.email || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Email Address{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <ErrorMessage
                              component="span"
                              name="email"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <InputMask
                              guide={false}
                              type="text"
                              id="phone"
                              keepCharPositions={false}
                              mask={phoneNumberMask}
                              className="form-control form-control-lg material-textfield-input"
                              name="phone"
                              onChange={handleChange}
                              value={values.phone || ''}
                              required
                            />
                            <label className="material-textfield-label">
                              Phone Number{' '}
                            </label>
                            <ErrorMessage
                              component="span"
                              name="phone"
                              className="invalid-feedback d-block"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group material-textfield">
                        <input
                          type="password"
                          className="form-control form-control-lg material-textfield-input"
                          name="password"
                          onChange={handleChange}
                          value={values.password || ''}
                          required
                        />
                        <label className="material-textfield-label">
                          Password
                        </label>
                        <ErrorMessage
                          component="span"
                          name="password"
                          className="invalid-feedback d-block"
                        />
                      </div>
                      <PasswordStrengthStatus
                        passwordStrength={passwordStrength}
                      />
                      <div className="invalid-feedback text-center mb-3">
                        {errorMessage}
                      </div>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmitting ? 'Please wait ...' : 'Accept Invitation'}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
