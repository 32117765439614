/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import clsx from 'clsx';
import { Pagination } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { SortUpIcon, SortDownIcon } from '../../../components/icons';
import {
  isAdmin,
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../../constants/common';
import _ from 'lodash';
import './styles.scss';
import moment from 'moment';
import { handleEsimPdfDownload } from '../../../utils/helpers';

export default class SimListComponent extends Component {
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  Capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  generatePDF = async (e, iccid) => {
    try {
      await this.props.generatePDF({ ICCID: iccid });
    } catch (e) {
      //error occurred here
      console.log('Error occured while regenerating pdf : ', e);
    }
  };

  render() {
    const {
      simDataWithImeiCompatibility,
      user,
      iAuthorization,
      sortOrder,
      data,
      totalPagination,
      page,
      onPageChange,
      limit,
      activeTab,
      markedSimList,
      onCheck,
      match,
      simPlansData,
    } = this.props;
    let { sortBy } = this.props;
    return (
      <div>
        <div className="table-responsive custom-table-shadow">
          <table className="table custom-table custom-table-fixed-col">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>
                  <span
                    onClick={() => this.props.sortByKey('simname')}
                    className="table-column-title cursor-poniter"
                  >
                    SIM Name{' '}
                    {sortBy === 'simname' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span className="table-column-title">Tags</span>
                </th>
                {user &&
                  (isSPRPartner(user) ||
                    (isSubUser(user) &&
                      checkPermission(user, '/add-client'))) && (
                    <th>
                      <span
                        className="table-column-title cursor-poniter"
                        onClick={() => this.props.sortByKey('clientname')}
                      >
                        Client{' '}
                        {sortBy === 'clientname' ? (
                          sortOrder === 'asc' ? (
                            <SortUpIcon />
                          ) : (
                            <SortDownIcon />
                          )
                        ) : (
                          <SortUpIcon />
                        )}
                      </span>
                    </th>
                  )}
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('zipcode')}
                  >
                    Zipcode{' '}
                    {sortBy === 'zipcode' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                {activeTab === 'availableeSim' && (
                  <th>
                    <span className="table-column-title cursor-poniter">
                      IMEI{' '}
                    </span>
                  </th>
                )}
                <th>
                  <span className="table-column-title cursor-poniter">
                    User ID{' '}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('simnumber')}
                  >
                    ICCID{' '}
                    {sortBy === 'simnumber' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                {activeTab === 'activationInprogress' && <th></th>}
                {activeTab !== 'inactive' &&
                  activeTab !== 'activationInProgress' &&
                  activeTab !== 'availableeSim' && (
                    <>
                      <th>
                        <span
                          className="table-column-title cursor-poniter"
                          onClick={() => this.props.sortByKey('msisdn')}
                        >
                          Phone Number{' '}
                          {sortBy === 'msisdn' ? (
                            sortOrder === 'asc' ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )
                          ) : (
                            <SortUpIcon />
                          )}
                        </span>
                      </th>
                      <th>
                        <span
                          className="table-column-title cursor-poniter"
                          onClick={() => this.props.sortByKey('activationdate')}
                        >
                          Activated Date{' '}
                          {sortBy === 'activationdate' ? (
                            sortOrder === 'asc' ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )
                          ) : (
                            <SortUpIcon />
                          )}
                        </span>
                      </th>
                      {match.path !== '/deactivated-sims' &&
                      match.path !== '/paused-sims' ? (
                        <>
                          <th>
                            <span className="table-column-title cursor-poniter">
                              Rate Plan{' '}
                            </span>
                          </th>
                          <th>
                            <span
                              className="table-column-title cursor-poniter"
                              onClick={() => this.props.sortByKey('planid')}
                            >
                              Rate Plan ID{' '}
                              {sortBy === 'planid' ? (
                                sortOrder === 'asc' ? (
                                  <SortUpIcon />
                                ) : (
                                  <SortDownIcon />
                                )
                              ) : (
                                <SortUpIcon />
                              )}
                            </span>
                          </th>
                        </>
                      ) : (
                        <th>
                          <span
                            className="table-column-title cursor-poniter"
                            onClick={() =>
                              this.props.sortByKey('datePlanChanged')
                            }
                          >
                            {match.path === '/deactivated-sims'
                              ? 'Deactivated Date'
                              : 'Paused Date'}{' '}
                            {sortBy === 'datePlanChanged' ? (
                              sortOrder === 'asc' ? (
                                <SortUpIcon />
                              ) : (
                                <SortDownIcon />
                              )
                            ) : (
                              <SortUpIcon />
                            )}
                          </span>
                        </th>
                      )}
                      {activeTab === 'deactive' && (
                        <th>
                          <span className="table-column-title cursor-poniter">
                            Deactivation Type
                          </span>
                        </th>
                      )}
                      {activeTab === 'active' && (
                        <>
                          <th>
                            <span className="table-column-title cursor-poniter">
                              eSIM Installation{' '}
                            </span>
                          </th>
                        </>
                      )}
                    </>
                  )}
                <th colSpan="4"></th>
              </tr>
            </thead>
            <tbody>
              {_.size(data) > 0 &&
                data.map((item, index) => {
                  let tagsArr =
                    item.tags && item.tags !== '' ? item.tags.split(',') : [];
                  let checked =
                    markedSimList.map((mark) => mark.id).indexOf(item.id) !==
                    -1;
                  let planFilter = [];
                  let offerName = '';
                  let offerId = 0;
                  if (_.get(user, 'consumeOfferAPI', false)) {
                    planFilter = simPlansData.filter((el) => {
                      return parseInt(el.offerId) === parseInt(item.planid);
                    });
                    offerName =
                      _.get(planFilter, '[0].title', '') !== ''
                        ? _.get(planFilter, '[0].title', '')
                        : _.get(item, 'title', '');
                    offerId = parseInt(_.get(planFilter, '[0].offerId', 0));
                  } else {
                    offerName = _.get(item, 'title', '');
                  }

                  return (
                    <tr className="cursor-poniter" key={index}>
                      <td className="checkbox-wrap">
                        {activeTab !== 'inactive' ||
                        activeTab !== 'availableeSim' ? (
                          <label className="custom-checkbox-only checkmark-only-disabled">
                            <input
                              disabled={item.status !== 9 ? false : true}
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="check"
                              value={index}
                              checked={
                                markedSimList
                                  .map((mark) => mark.id)
                                  .indexOf(item.id) !== -1
                              }
                              onChange={() => onCheck(item)}
                            />
                            <span
                              className={
                                item.status !== 9
                                  ? 'checkmark-only'
                                  : 'checkmark-only-disabled'
                              }
                            ></span>
                          </label>
                        ) : (
                          <label className="custom-checkbox-only">
                            <input
                              disabled={!item.status === 0 ? true : false}
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="check"
                              value={index}
                              checked={
                                markedSimList
                                  .map((mark) => mark.id)
                                  .indexOf(item.id) !== -1
                              }
                              onChange={() => onCheck(item)}
                            />
                            <span
                              className={
                                !item.status === 0
                                  ? 'checkmark-only-disabled'
                                  : 'checkmark-only'
                              }
                            ></span>
                          </label>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive' ||
                              activeTab === 'availableeSim'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        <div className="d-flex align-items-center">
                          {checked &&
                          (activeTab === 'inactive' ||
                            activeTab === 'availableeSim') ? (
                            <div className="material-textfield">
                              <DebounceInput
                                minLength={3}
                                debounceTimeout={500}
                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                name="simname"
                                value={
                                  item.simname === 'undefined'
                                    ? ''
                                    : item.simname
                                }
                                onChange={(e) =>
                                  this.props.handelSimNameChange(index, e)
                                }
                                required
                              />
                              <label className="material-textfield-label material-textfield-label-sm">
                                SIM Name
                              </label>
                            </div>
                          ) : _.get(item, 'simname', '') &&
                            _.get(item, 'simname', '') !== '' &&
                            _.get(item, 'simname', '') !== 'undefined' ? (
                            _.get(item, 'simname', '-')
                          ) : (
                            _.get(item, 'simnumber', '-')
                          )}
                          {item.isEsim === 1 && (
                            <span className="blue-chip-label ml-2">eSIM</span>
                          )}
                        </div>
                      </td>
                      <td>
                        {_.size(tagsArr) > 0 &&
                          tagsArr.map((tag) => {
                            return (
                              <button key={tag} className="btn btn-tags mr-2">
                                {tag}
                              </button>
                            );
                          })}
                        {item.status !== 9 && (
                          <button
                            onClick={() => this.props.addTagOnSim(index)}
                            className="btn text-underline btn-link-orange"
                          >
                            Add Tag
                          </button>
                        )}
                      </td>
                      {user &&
                        (isSPRPartner(user) ||
                          (isSubUser(user) &&
                            checkPermission(user, '/add-client'))) && (
                          <td>
                            {item.clientname &&
                            _.get(item, 'clientname', '') !== '' ? (
                              <button className="btn btn-tags btn-client mr-2">
                                {_.get(item, 'clientname', '-')}
                              </button>
                            ) : activeTab === 'inactive' ||
                              activeTab === 'active' ||
                              activeTab === 'activationInProgress' ||
                              activeTab === 'availableeSim' ? (
                              <button
                                onClick={() => this.props.addClientOnSim(index)}
                                className="btn text-underline btn-link-orange"
                              >
                                Add Client
                              </button>
                            ) : (
                              '-'
                            )}
                          </td>
                        )}
                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive' ||
                              activeTab === 'availableeSim'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        {checked &&
                        (activeTab === 'inactive' ||
                          activeTab === 'availableeSim') ? (
                          <>
                            <div 
                              className={clsx('material-textfield', {
                                'input-error': 
                                  markedSimList?.find(
                                    (sim) => {
                                      return sim?.id === item?.id
                                    }
                                  )?.isZipCodeIneligible === true,
                              })}
                              >
                              <DebounceInput
                                minLength={3}
                                type="number"
                                name="zipcode"
                                id="zipcode"
                                value={item.zipcode}
                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                onChange={(e) =>
                                  this.props.handleZipcodeChange(index, e)
                                }
                                required
                              />
                              <label className="material-textfield-label material-textfield-label-sm" htmlFor="zipcode">
                                Zipcode
                              </label>
                            </div>
                          </>
                        ) : (
                          _.get(item, 'zipcode', '-')
                        )}
                      </td>
                      {activeTab === 'availableeSim' && (
                        <td
                          onClick={() =>
                            checked
                              ? console.log('')
                              : activeTab === 'inactive' ||
                                activeTab === 'availableeSim'
                              ? console.log('inactive')
                              : this.props.simDetails(item.simnumber)
                          }
                        >
                          {checked ? (
                            <div
                              className={clsx('material-textfield', {
                                'input-error':
                                  simDataWithImeiCompatibility?.find(
                                    (sim) => sim.simnumber === item.simnumber
                                  )?.isImeiEsimCompatible === false,
                              })}
                            >
                              <DebounceInput
                                minLength={3}
                                type="number"
                                name="imeinumber"
                                id="imeinumber"
                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                required
                                onChange={(e) =>
                                  this.props.handleSimImeiChange(index, e)
                                }
                                autoComplete="off"
                              />
                              <label
                                className="material-textfield-label material-textfield-label-sm"
                                htmlFor="imeinumber"
                              >
                                IMEI
                              </label>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                      )}
                      <td onClick={() => this.props.simDetails(item.simnumber)}>
                        {_.get(item, 'ultraUserId', '') &&
                        _.get(item, 'ultraUserId', '') !== ''
                          ? _.get(item, 'ultraUserId', '')
                          : '-'}
                      </td>
                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive' ||
                              activeTab === 'availableeSim'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        {_.get(item, 'simnumber', '') &&
                        _.get(item, 'simnumber', '') !== ''
                          ? _.get(item, 'simnumber', '')
                          : '-'}
                      </td>
                      {activeTab === 'activationInProgress' && (
                        <td>
                          {_.get(item, 'failedReason', '-') &&
                          _.get(item, 'failedReason', '') !== '' ? (
                            <span className="btn btn-link-danger">
                              {_.get(item, 'failedReason', '')}
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                      )}
                      {activeTab !== 'inactive' &&
                        activeTab !== 'activationInProgress' &&
                        activeTab !== 'availableeSim' && (
                          <>
                            <td
                              onClick={() =>
                                checked
                                  ? console.log('')
                                  : activeTab === 'inactive' ||
                                    activeTab === 'availableeSim'
                                  ? console.log('inactive')
                                  : this.props.simDetails(item.simnumber)
                              }
                            >
                              {_.get(item, 'msisdn', '') &&
                              _.get(item, 'msisdn', '') !== ''
                                ? this.formatPhoneNumber(
                                    _.get(item, 'msisdn', '-')
                                  )
                                : '-'}
                            </td>
                            <td
                              onClick={() =>
                                checked
                                  ? console.log('')
                                  : activeTab === 'inactive' ||
                                    activeTab === 'availableeSim'
                                  ? console.log('inactive')
                                  : this.props.simDetails(item.simnumber)
                              }
                            >
                              {_.get(item, 'activationdate', '') &&
                              moment(
                                _.get(item, 'activationdate', '')
                              ).isValid() &&
                              _.get(item, 'activationdate', '') !== ''
                                ? moment(
                                    _.get(item, 'activationdate', '')
                                  ).format('MMMM DD, YYYY')
                                : '-'}
                            </td>
                            {match.path !== '/deactivated-sims' &&
                            match.path !== '/paused-sims' ? (
                              <>
                                <td
                                  onClick={() =>
                                    checked
                                      ? console.log('')
                                      : activeTab === 'inactive' ||
                                        activeTab === 'availableeSim'
                                      ? console.log('inactive')
                                      : this.props.simDetails(item.simnumber)
                                  }
                                >
                                  {offerName && offerName !== ''
                                    ? offerName
                                    : '-'}
                                </td>
                                <td
                                  onClick={() =>
                                    checked
                                      ? console.log('')
                                      : activeTab === 'inactive' ||
                                        activeTab === 'availableeSim'
                                      ? console.log('inactive')
                                      : this.props.simDetails(item.simnumber)
                                  }
                                >
                                  {parseInt(offerId) !== 0
                                    ? parseInt(offerId)
                                    : _.get(item, 'productid', '')}
                                </td>
                              </>
                            ) : (
                              <td
                                onClick={() =>
                                  this.props.simDetails(item.simnumber)
                                }
                              >
                                {_.get(item, 'datePlanChanged', '') &&
                                _.get(item, 'datePlanChanged', '') !== ''
                                  ? moment(
                                      _.get(item, 'datePlanChanged', '')
                                    ).format('MMMM DD, YYYY')
                                  : '-'}
                              </td>
                            )}
                          </>
                        )}
                      {(activeTab === 'inactive' ||
                        activeTab === 'availableeSim') && (
                        <>
                          {_.get(item, 'status', 0) === 12 &&
                          _.get(item, 'portInRequestStatus', '') !== '' &&
                          _.get(item, 'portInRequestStatus', '') !== null ? (
                            <>
                              <td>&nbsp;</td>
                            </>
                          ) : (
                            <>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    activeTab === 'availableeSim'
                                      ? this.props.singleSimAction(
                                          'activateEsimbyID',
                                          item
                                        )
                                      : this.props.singleSimAction(
                                          'activate',
                                          item
                                        )
                                  }
                                  className="btn btn-link-purple"
                                >
                                  Activate
                                </button>
                              </td>
                              {((iAuthorization && iAuthorization !== '') ||
                                (user && isAdmin(user))) && (
                                <td>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.props.singleSimAction('delete', item)
                                    }
                                    className="btn btn-link-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {activeTab === 'activationInProgress' && (
                        <>
                          <td>
                            <span
                              className={
                                item.portInRequestStatus === 'cancelled'
                                  ? 'btn btn-link-danger'
                                  : item.portInRequestStatus === 'pending'
                                  ? 'btn btn-green'
                                  : 'btn btn-link-danger'
                              }
                            >
                              {this.Capitalize(item.portInRequestStatus)}
                            </span>
                          </td>
                          <td>&nbsp;</td>
                          {((iAuthorization && iAuthorization !== '') ||
                            (user && isAdmin(user))) && (
                            <td>
                              <button
                                type="button"
                                onClick={() =>
                                  this.props.singleSimAction('delete', item)
                                }
                                className="btn btn-link-danger"
                              >
                                Delete
                              </button>
                            </td>
                          )}
                        </>
                      )}
                      {activeTab === 'active' && (
                        <td>
                          {_.get(item, 'isEsim', 0) === 1 ? (
                            <span
                              className="download-pdf-list"
                              onClick={(e) =>
                                handleEsimPdfDownload(item?.simnumber, this.props.downloadEsimPDF, this.props.generatePDF)
                              }
                            >
                              Download
                            </span>
                          ) : (
                            '-'
                          )}
                        </td>
                      )}
                      {activeTab === 'deactive' && (
                        <td>
                          {item?.StatusReason || '-'}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          className="mb-5"
          hideOnSinglePage
          onChange={onPageChange}
          current={page}
          pageSize={limit}
          defaultCurrent={page}
          total={totalPagination}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
