/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import { Pagination } from 'antd';
import { SortUpIcon, SortDownIcon } from '../../../components/icons';
import { DebounceInput } from 'react-debounce-input';
import {
  isAdmin,
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../../constants/common';
import _ from 'lodash';
import './styles.scss';
import moment from 'moment';
import clsx from 'clsx';

// let currentDate = moment(new Date()).format('MMM DD, YYYY')
// const monthFirstDate = moment().startOf('month').format('MMM DD, YYYY')

export default class SimListComponent extends Component {
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  Capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      user,
      iAuthorization,
      sortOrder,
      data,
      totalPagination,
      page,
      onPageChange,
      limit,
      activeTab,
      markedSimList,
      onCheck,
      match,
      simPlansData,
    } = this.props;
    let { sortBy } = this.props;
    return (
      <div>
        <div className="table-responsive custom-table-shadow">
          <table className="table custom-table custom-table-fixed-col">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>
                  <span
                    onClick={() => this.props.sortByKey('simname')}
                    className="table-column-title cursor-poniter"
                  >
                    Device Name
                    {sortBy === 'simname' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span className="table-column-title">Tags</span>
                </th>
                {user &&
                  (isSPRPartner(user) ||
                    (isSubUser(user) &&
                      checkPermission(user, '/add-client'))) && (
                    <th>
                      <span
                        className="table-column-title cursor-poniter"
                        onClick={() => this.props.sortByKey('clientname')}
                      >
                        Clients
                        {sortBy === 'clientname' ? (
                          sortOrder === 'asc' ? (
                            <SortUpIcon />
                          ) : (
                            <SortDownIcon />
                          )
                        ) : (
                          <SortUpIcon />
                        )}
                      </span>
                    </th>
                  )}
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('zipcode')}
                  >
                    Zipcode{' '}
                    {sortBy === 'zipcode' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                <th>
                  <span
                    className="table-column-title cursor-poniter"
                    onClick={() => this.props.sortByKey('simnumber')}
                  >
                    ICCID{' '}
                    {sortBy === 'simnumber' ? (
                      sortOrder === 'asc' ? (
                        <SortUpIcon />
                      ) : (
                        <SortDownIcon />
                      )
                    ) : (
                      <SortUpIcon />
                    )}
                  </span>
                </th>
                {activeTab === 'activationInprogress' && <th></th>}
                {activeTab !== 'inactive' &&
                  activeTab !== 'activationInProgress' && (
                    <>
                      <th>
                        <span
                          className="table-column-title cursor-poniter"
                          onClick={() => this.props.sortByKey('simnumber')}
                        >
                          IMEI{' '}
                          {sortBy === 'simnumber' ? (
                            sortOrder === 'asc' ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )
                          ) : (
                            <SortUpIcon />
                          )}
                        </span>
                      </th>
                      <th>
                        <span
                          className="table-column-title cursor-poniter"
                          onClick={() => this.props.sortByKey('msisdn')}
                        >
                          Phone Number{' '}
                          {sortBy === 'msisdn' ? (
                            sortOrder === 'asc' ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )
                          ) : (
                            <SortUpIcon />
                          )}
                        </span>
                      </th>
                      <th>
                        <span
                          className="table-column-title cursor-poniter"
                          onClick={() => this.props.sortByKey('activationdate')}
                        >
                          Activated Date{' '}
                          {sortBy === 'activationdate' ? (
                            sortOrder === 'asc' ? (
                              <SortUpIcon />
                            ) : (
                              <SortDownIcon />
                            )
                          ) : (
                            <SortUpIcon />
                          )}
                        </span>
                      </th>

                      {match.path !== '/fixed-deactivated-sims' &&
                      match.path !== '/fixed-paused-sims' ? (
                        <>
                          <th>
                            <span className="table-column-title cursor-poniter">
                              Rate Plan{' '}
                            </span>
                          </th>
                          <th>
                            <span
                              className="table-column-title cursor-poniter"
                              onClick={() => this.props.sortByKey('planid')}
                            >
                              Rate Plan ID{' '}
                              {sortBy === 'planid' ? (
                                sortOrder === 'asc' ? (
                                  <SortUpIcon />
                                ) : (
                                  <SortDownIcon />
                                )
                              ) : (
                                <SortUpIcon />
                              )}
                            </span>
                          </th>
                          <th colSpan="3"></th>
                        </>
                      ) : (
                        <th>
                          <span
                            className="table-column-title cursor-poniter"
                            onClick={() =>
                              this.props.sortByKey('datePlanChanged')
                            }
                          >
                            {match.path === '/fixed-deactivated-sims'
                              ? 'Deactivated Date'
                              : 'Paused Date'}{' '}
                            {sortBy === 'datePlanChanged' ? (
                              sortOrder === 'asc' ? (
                                <SortUpIcon />
                              ) : (
                                <SortDownIcon />
                              )
                            ) : (
                              <SortUpIcon />
                            )}
                          </span>
                        </th>
                      )}
                    </>
                  )}
                <th colSpan="4"></th>
              </tr>
            </thead>
            <tbody>
              {_.size(data) > 0 &&
                data.map((item, index) => {
                  let tagsArr =
                    item.tags && item.tags !== '' ? item.tags.split(',') : [];
                  let checked =
                    markedSimList.map((mark) => mark.id).indexOf(item.id) !==
                    -1;
                  let planFilter = [];
                  let offerName = '';
                  let offerId = 0;
                  if (_.get(user, 'consumeOfferAPI', false)) {
                    planFilter = simPlansData.filter((el) => {
                      return parseInt(el.offerId) === parseInt(item.planid);
                    });
                    offerName = _.get(planFilter, '[0].title', item.title);
                    offerId = parseInt(_.get(planFilter, '[0].offerId', 0));
                  } else {
                    offerName = _.get(item, 'title', '');
                  }
                  return (
                    <tr className="cursor-poniter" key={index}>
                      <td className="checkbox-wrap">
                        {activeTab !== 'inactive' ? (
                          <label className="custom-checkbox-only">
                            <input
                              disabled={item.status !== 9 ? false : true}
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="check"
                              value={index}
                              checked={
                                markedSimList
                                  .map((mark) => mark.id)
                                  .indexOf(item.id) !== -1
                              }
                              onChange={() => onCheck(item)}
                            />
                            <span
                              className={
                                item.status !== 9
                                  ? 'checkmark-only'
                                  : 'checkmark-only-disabled'
                              }
                            ></span>
                          </label>
                        ) : (
                          <label className="custom-checkbox-only">
                            <input
                              disabled={item.status === 0 ? false : true}
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="check"
                              value={index}
                              checked={
                                markedSimList
                                  .map((mark) => mark.id)
                                  .indexOf(item.id) !== -1
                              }
                              onChange={() => onCheck(item)}
                            />
                            <span
                              className={
                                item.status === 0
                                  ? 'checkmark-only'
                                  : 'checkmark-only-disabled'
                              }
                            ></span>
                          </label>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        {checked && activeTab === 'inactive' ? (
                          <div className="material-textfield">
                            <DebounceInput
                              minLength={3}
                              debounceTimeout={500}
                              className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                              name="simname"
                              value={item.simname}
                              // placeholder="Enter simname"
                              onChange={(e) =>
                                this.props.handelSimNameChange(index, e)
                              }
                              required
                            />
                            <label className="material-textfield-label material-textfield-label-sm">
                              Device Name
                            </label>
                          </div>
                        ) : _.get(item, 'simname', '') &&
                          _.get(item, 'simname', '') !== '' ? (
                          _.get(item, 'simname', '-')
                        ) : (
                          _.get(item, 'simnumber', '-')
                        )}
                      </td>
                      <td>
                        {_.size(tagsArr) > 0 &&
                          tagsArr.map((tag) => {
                            return (
                              <button key={tag} className="btn btn-tags mr-2">
                                {tag}
                              </button>
                            );
                          })}
                        {item.status !== 9 && (
                          <button
                            onClick={() => this.props.addTagOnSim(index)}
                            className="btn text-underline btn-link-orange"
                          >
                            Add Tag
                          </button>
                        )}
                      </td>
                      {user &&
                        (isSPRPartner(user) ||
                          (isSubUser(user) &&
                            checkPermission(user, '/add-client'))) && (
                          <td>
                            {item.clientname &&
                            _.get(item, 'clientname', '') !== '' ? (
                              <button className="btn btn-tags btn-client mr-2">
                                {_.get(item, 'clientname', '-')}
                              </button>
                            ) : activeTab === 'inactive' ||
                              activeTab === 'active' ||
                              activeTab === 'activationInProgress' ? (
                              <button
                                onClick={() => this.props.addClientOnSim(index)}
                                className="btn text-underline btn-link-orange"
                              >
                                Add Client
                              </button>
                            ) : (
                              '-'
                            )}
                          </td>
                        )}
                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        {checked && activeTab === 'inactive' ? (
                          <>
                            <div 
                              className={clsx('material-textfield', {
                                'input-error': 
                                  markedSimList?.find(
                                    (sim) => {
                                      return sim?.id === item?.id
                                    }
                                  )?.isZipCodeIneligible === true,
                              })}
                              >
                              <DebounceInput
                                minLength={3}
                                type="number"
                                name="zipcode"
                                value={item.zipcode}
                                className="form-control form-control-sm material-textfield-input material-textfield-input-sm"
                                onChange={(e) =>
                                  this.props.handleZipcodeChange(index, e)
                                }
                                required
                              />
                              <label className="material-textfield-label material-textfield-label-sm">
                                Zipcode
                              </label>
                            </div>
                          </>
                        ) : (
                          _.get(item, 'zipcode', '-')
                        )}
                      </td>

                      <td
                        onClick={() =>
                          checked
                            ? console.log('')
                            : activeTab === 'inactive'
                            ? console.log('inactive')
                            : this.props.simDetails(item.simnumber)
                        }
                      >
                        {_.get(item, 'simnumber', '') &&
                        _.get(item, 'simnumber', '') !== ''
                          ? _.get(item, 'simnumber', '')
                          : '-'}
                      </td>
                      {activeTab === 'activationInProgress' && (
                        <td>
                          {_.get(item, 'failedReason', '-') &&
                          _.get(item, 'failedReason', '') !== '' ? (
                            <span className="btn btn-link-danger">
                              {_.get(item, 'failedReason', '')}
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                      )}
                      {activeTab !== 'inactive' &&
                        activeTab !== 'activationInProgress' && (
                          <>
                            <td
                              onClick={() =>
                                checked
                                  ? console.log('')
                                  : activeTab === 'inactive'
                                  ? console.log('inactive')
                                  : this.props.simDetails(item.simnumber)
                              }
                            >
                              {_.get(item, 'imeinumber', '') &&
                              _.get(item, 'imeinumber', '') !== ''
                                ? _.get(item, 'imeinumber', '')
                                : '-'}
                            </td>
                            <td
                              onClick={() =>
                                checked
                                  ? console.log('')
                                  : activeTab === 'inactive'
                                  ? console.log('inactive')
                                  : this.props.simDetails(item.simnumber)
                              }
                            >
                              {_.get(item, 'msisdn', '') &&
                              _.get(item, 'msisdn', '') !== ''
                                ? this.formatPhoneNumber(
                                    _.get(item, 'msisdn', '-')
                                  )
                                : '-'}
                            </td>
                            <td
                              onClick={() =>
                                checked
                                  ? console.log('')
                                  : activeTab === 'inactive'
                                  ? console.log('inactive')
                                  : this.props.simDetails(item.simnumber)
                              }
                            >
                              {_.get(item, 'activationdate', '') &&
                              moment(
                                _.get(item, 'activationdate', '')
                              ).isValid() &&
                              _.get(item, 'activationdate', '') !== ''
                                ? moment(
                                    _.get(item, 'activationdate', '')
                                  ).format('MMMM DD, YYYY')
                                : '-'}
                            </td>
                            {/* <td onClick={() => checked ? console.log('') : activeTab === 'inactive' ? console.log('inactive') : this.props.simDetails(item.simnumber)}>
                      { _.get(item,'usage.last_connected','') && moment(_.get(item,'usage.last_connected','')).isValid() && _.get(item,'usage.last_connected','') !== '' ?
                        moment(_.get(item,'usage.last_connected','')).format('MMMM DD, YYYY')
                      :
                        '-'
                      }
                    </td>
                    <td onClick={() => checked ? console.log('') : activeTab === 'inactive' ? console.log('inactive') : this.props.simDetails(item.simnumber)}>
                      { _.get(item,'usage.data','') && _.get(item,'usage.data','') !== '' ?
                        _.get(item,'usage.data','').toLocaleString(undefined, {maximumFractionDigits: 2})
                      :
                        '-'
                      }
                    </td> */}
                          </>
                        )}
                      {match.path !== '/fixed-deactivated-sims' &&
                      match.path !== '/fixed-paused-sims' ? (
                        <>
                          <td
                            onClick={() =>
                              checked
                                ? console.log('')
                                : activeTab === 'inactive'
                                ? console.log('inactive')
                                : this.props.simDetails(item.simnumber)
                            }
                          >
                            {offerName && offerName !== '' ? offerName : '-'}
                          </td>
                          <td
                            onClick={() =>
                              checked
                                ? console.log('')
                                : activeTab === 'inactive'
                                ? console.log('inactive')
                                : this.props.simDetails(item.simnumber)
                            }
                          >
                            {parseInt(offerId) !== 0
                              ? parseInt(offerId)
                              : _.get(item, 'productid', '')}
                          </td>
                        </>
                      ) : (
                        <td
                          onClick={() => this.props.simDetails(item.simnumber)}
                        >
                          {_.get(item, 'datePlanChanged', '') &&
                          _.get(item, 'datePlanChanged', '') !== ''
                            ? moment(_.get(item, 'datePlanChanged', '')).format(
                                'MMMM DD, YYYY'
                              )
                            : '-'}
                        </td>
                      )}
                      {activeTab === 'inactive' && (
                        <>
                          {_.get(item, 'status', 0) === 12 &&
                          _.get(item, 'portInRequestStatus', '') !== '' &&
                          _.get(item, 'portInRequestStatus', '') !== null ? (
                            <>
                              {/* <td>
                              <span className={item.portInRequestStatus === 'cancelled' ? 'btn btn-link-danger' : item.portInRequestStatus === 'pending' ? 'btn btn-green' : 'btn btn-link-danger'}>
                                {this.Capitalize(item.portInRequestStatus)}
                              </span>
                            </td> */}
                              <td>&nbsp;</td>
                            </>
                          ) : (
                            <>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.props.singleSimAction('activate', item)
                                  }
                                  className="btn btn-link-purple"
                                >
                                  Activate
                                </button>
                              </td>
                              {((iAuthorization && iAuthorization !== '') ||
                                (user && isAdmin(user))) && (
                                <td>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.props.singleSimAction('delete', item)
                                    }
                                    className="btn btn-link-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {activeTab === 'activationInProgress' && (
                        <>
                          <td>
                            <span
                              className={
                                item.portInRequestStatus === 'cancelled'
                                  ? 'btn btn-link-danger'
                                  : item.portInRequestStatus === 'pending'
                                  ? 'btn btn-green'
                                  : 'btn btn-link-danger'
                              }
                            >
                              {this.Capitalize(item.portInRequestStatus)}
                            </span>
                          </td>
                          <td>&nbsp;</td>
                          {((iAuthorization && iAuthorization !== '') ||
                            (user && isAdmin(user))) && (
                            <td>
                              <button
                                type="button"
                                onClick={() =>
                                  this.props.singleSimAction('delete', item)
                                }
                                className="btn btn-link-danger"
                              >
                                Delete
                              </button>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          className="mb-5"
          hideOnSinglePage
          onChange={onPageChange}
          current={page}
          pageSize={limit}
          defaultCurrent={page}
          total={totalPagination}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
