/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Spin } from 'antd';
import InputMask from 'react-text-mask';
import { LoadingOutlined } from '@ant-design/icons';
import OTPInput, { ResendOTP } from 'otp-input-react';
import {
  isAdmin,
  isSysOpsUser,
  isSPRPartner,
  isSubUser,
} from '../../constants/common';
import _ from 'lodash';
import Logo from '../../images/logo.png';
import 'react-phone-input-2/lib/style.css';
import './styles.scss';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export default class TwoFactorAuthComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      user: {},
      countryCode: '1',
      isPageLoading: true,
      phone: '',
      otp: '',
      isSubmittingPhone: false,
      isSubmittingOtp: false,
      isOTPSent: false,
      phoneError: '',
      otpError: '',
      mobileLastFour: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Two Factor Authentications | UltraIoT';
    try {
      const {
        value: { user },
      } = await this.props.fetchMe();
      if (user) {
        this.setState({ user, isPageLoading: false });
        if (
          _.get(user, 'phone') &&
          _.get(user, 'countryCode') &&
          _.get(user, 'is2FAEnabled', 0) === 1
        ) {
          this.setState(
            {
              phone: _.get(user, 'phone'),
              countryCode: _.get(user, 'countryCode'),
              isOTPSent: true,
            },
            () => {
              this.sendOtp();
            }
          );
        }
      } else {
        localStorage.clear();
        this.props.history.push('/login');
      }
    } catch (err) {
      const error = err.data;
      this.setState({ isLoading: false });
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      }
    }
  };

  handlePhoneChange = (e) => {
    const phone = e.target.value?.replace(/[^0-9]/g, '');
    this.setState({ phone: phone, phoneError: '' });
  };

  handleChange = (otp) =>
    this.setState({ otp }, () => {
      if (otp?.length === 6) {
        this.verifyOtp();
      }
    });

  sendOtp = async () => {
    const { phoneError, phone, user, countryCode } = this.state;
    this.setState({ isSubmittingPhone: true });
    try {
      if (!phone || phoneError || !user.id) {
        this.setState({
          isOTPSent: false,
          phoneError: 'Please fill your phone number.',
          isSubmittingPhone: false,
        });
        return;
      }
      const {
        value: { success },
      } = await this.props.sendOtp({ userId: user.id, phone, countryCode });
      if (success) {
        const mobileLastFour = phone?.substr(phone.length - 4);
        this.setState({
          isOTPSent: true,
          mobileLastFour,
          isSubmittingPhone: false,
        });
      }
    } catch (error) {
      this.setState({
        isOTPSent: false,
        phoneError: error.message,
        isSubmittingPhone: false,
      });
    }
  };

  reSendOtp = () => {
    this.sendOtp();
  };

  verifyOtp = async () => {
    this.setState({ isSubmittingOtp: true });
    try {
      const { phone, otp, user, countryCode } = this.state;
      if (!otp || !phone || !user.id) {
        this.setState({
          otpError: 'OTP is required.',
          otp: '',
          isSubmittingOtp: false,
        });
        return;
      }
      const {
        value: { success },
      } = await this.props.verifyOtp({
        phone,
        otp,
        userId: user.id,
        countryCode,
      });
      if (success) {
        this.setState({ isSubmittingOtp: false });
        this.props.setUser(user);
        const token = localStorage.getItem('t_Authorization');
        localStorage.setItem('Authorization', token);
        localStorage.removeItem('t_Authorization');
        this.setState({ user });
        if (user && (isAdmin(user) || isSysOpsUser(user))) {
          this.props.history.push('/spr-dashboard');
        } else {
          if (
            user &&
            (isSPRPartner(user) || isSubUser(user)) &&
            _.get(user, 'lobId', 1) === 3
          ) {
            this.props.history.push('/fixed-dashboard');
          } else {
            this.props.history.push('/dashboard');
          }
        }
      }
    } catch (error) {
      this.setState({
        otpError: error.message,
        otp: '',
        isSubmittingOtp: false,
      });
      return;
    }
  };

  render() {
    const {
      phoneError,
      otpError,
      isPageLoading,
      isSubmittingPhone,
      countryCode,
      phone,
      isSubmittingOtp,
      isOTPSent,
      otp,
      mobileLastFour,
    } = this.state;
    return (
      <div className="oauth-authentication-2-0">
        <header className="oauth-authentication-header">
          <div className="oauth-authentication-logo">
            <img className="logo" src={Logo} alt="Ultra" width="100" />
          </div>
        </header>
        <div className="oauth-authentication-inner">
          <div className="oauth-authentication-body">
            <div className="card mb-0">
              <Spin
                size="large"
                spinning={isPageLoading}
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: '#000' }}
                    spin
                  />
                }
              >
                <div className="card-body">
                  {!isOTPSent && !isPageLoading && (
                    <div id="enterPhoneScreen" className="enterPhoneScreen">
                      <h2 className="title">Let's secure your account </h2>
                      <p className="subtitle">
                        We are implementing two-factor best-practices to enhance
                        the security of your Ultra account. Please enter your
                        mobile phone number below to receive a login code via
                        SMS.
                      </p>
                      <div className="authCodeWarp">
                        <div className="row">
                          <div className="col-3">
                            <div className="form-group material-textfield">
                              <div className="phone-input-styled">
                                <PhoneInput
                                  inputProps={{
                                    readOnly: true,
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                    className:
                                      'form-control form-control-lg material-textfield-input',
                                  }}
                                  country={'us'}
                                  value={countryCode}
                                  onChange={(phone, val) =>
                                    this.setState({ countryCode: val.dialCode })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="form-group material-textfield">
                              <InputMask
                                guide={false}
                                type="text"
                                id="phone"
                                keepCharPositions={false}
                                mask={phoneNumberMask}
                                className="form-control form-control-lg material-textfield-input"
                                name="phone"
                                onChange={(e) => {
                                  this.handlePhoneChange(e);
                                }}
                                value={phone || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Phone Number
                              </label>
                              <div className="invalid-feedback">
                                {phoneError}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          disabled={isSubmittingPhone}
                          onClick={this.sendOtp}
                          type="submit"
                          className="btn btn-dark btn-lg w-100"
                        >
                          {isSubmittingPhone
                            ? 'Please wait ...'
                            : 'Send me a code'}
                        </button>
                      </div>
                    </div>
                  )}
                  {isOTPSent && (
                    <div id="verifyOTPscreen" className="verifyOTPscreen">
                      <h2 className="title">Confirm your session </h2>
                      <p className="subtitle">
                        We have sent you a code via SMS to your number ending in
                        <b className="ml-1">{mobileLastFour}</b>.
                      </p>
                      <p className="subtitle">
                        Didn't receive a code?
                        <ResendOTP
                          onResendClick={() => this.reSendOtp()}
                          className="otp-resend"
                          renderButton={renderButton}
                          renderTime={renderTime}
                        />
                      </p>
                      <div className="form-group">
                        <OTPInput
                          className="otp-input"
                          value={otp}
                          onChange={(otp) => {
                            this.handleChange(otp);
                          }}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          secure
                        />
                        <div className="invalid-feedback">{otpError}</div>
                      </div>
                      <button
                        disabled={isSubmittingOtp}
                        onClick={this.verifyOtp}
                        type="submit"
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmittingOtp ? 'Please wait ...' : 'Login'}
                      </button>
                    </div>
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const renderButton = (buttonProps) => {
  return (
    <button {...buttonProps}>
      {buttonProps.remainingTime !== 0
        ? `Please wait for ${buttonProps.remainingTime} sec`
        : 'Resend'}
    </button>
  );
};
const renderTime = () => React.Fragment;
