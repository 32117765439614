import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const signUpSchema = Yup.object().shape({
  companyname: Yup.string().required('Company name is required'),
  website_url: Yup.string()
    .url('Invalid url')
    .required('Website url is required'),
  business_description: Yup.string().required(
    'Business description is required'
  ),
  numberOfSims: Yup.string().required('Number of sims per month is required'),
  monthlyUsage: Yup.string().required(
    'Monthly usage per sim (in MD) is required'
  ),
  usecase: Yup.string().required('Using this sim description is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  password: Yup.string().required('Password is required'),
});

export const acceptInvitationSchema = Yup.object().shape({
  companyname: Yup.string().required('Company name is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const addUserSchema = Yup.object().shape({
  role: Yup.string().required('Account type is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  maid: Yup.string().required('MA ID is required'),
  companyname: Yup.string()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z 0-9]+$/,
      'Company Name should not contain any special character'
    ),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const addUserSPRSchema = Yup.object().shape({
  role: Yup.string().required('Account type is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z 0-9]+$/,
      'Company Name should not contain any special character'
    ),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  brandName: Yup.string().required('Brand name key is required'),
  //maid: Yup.string().required('MA ID is required'),
  sprPartnerId: Yup.string().required('SPR Partner Id is required'),
  sprPartnerSecret: Yup.string().required('SPR Partner Secret is required'),
  fixedPartnerId: Yup.string().required('Fixed Partner Id is required'),
  fixedPartnerSecret: Yup.string().required('Fixed Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const addUserSPRSchemaforSpr = Yup.object().shape({
  role: Yup.string().required('Account type is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z 0-9]+$/,
      'Company Name should not contain any special character'
    ),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  brandName: Yup.string().required('Brand name key is required'),
  //maid: Yup.string().required('MA ID is required'),
  sprPartnerId: Yup.string().required('SPR Partner Id is required'),
  sprPartnerSecret: Yup.string().required('SPR Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});
export const addUserSPRSchemaforFixed = Yup.object().shape({
  role: Yup.string().required('Account type is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z 0-9]+$/,
      'Company Name should not contain any special character'
    ),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  brandName: Yup.string().required('Brand name key is required'),
  //maid: Yup.string().required('MA ID is required'),
  fixedPartnerId: Yup.string().required('Fixed Partner Id is required'),
  fixedPartnerSecret: Yup.string().required('Fixed Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const editPartnerUserSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string().required('Company name is required'),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  sprPartnerId: Yup.string().required('SPR Partner Id is required'),
  sprPartnerSecret: Yup.string().required('SPR Partner Secret is required'),
  fixedPartnerId: Yup.string().required('Fixed Partner Id is required'),
  fixedPartnerSecret: Yup.string().required('Fixed Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const editPartnerUserSchemaSpr = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string().required('Company name is required'),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  sprPartnerId: Yup.string().required('SPR Partner Id is required'),
  sprPartnerSecret: Yup.string().required('SPR Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const editPartnerUserSchemaFixed = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  total_lines: Yup.string().required(
    'Total approved lines is required and should be greater then 0'
  ),
  companyname: Yup.string().required('Company name is required'),
  // apiKey: Yup.string().required('Api key is required'),
  partnerCode: Yup.string().required('Partner code is required'),
  fixedPartnerId: Yup.string().required('Fixed Partner Id is required'),
  fixedPartnerSecret: Yup.string().required('Fixed Partner Secret is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  countryCode: Yup.string().required('Country code is required'),
});

export const orderSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  address: Yup.string().required('Address is required'),
  addressline2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zip is required'),
});

export const orderESchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
});

export const profileSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
  phone: Yup.string()
    .min(12, 'Invalid phone number')
    .required('Phone Number is required'),
  companyname: Yup.string()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z 0-9]+$/,
      'Company Name should not contain any special character'
    ),
  // addressline1: Yup.string().required('Address is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zip is required'),
});

export const userProfileSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
});

export const updatePasswordSchema = Yup.object().shape({
  // password: Yup.string().required('Password is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmpassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Passwords and Confirm password must match'
    )
    .required('Confirm Password is required'),
});

export const cardSchema = Yup.object().shape({
  cc: Yup.string().required('Card number is required'),
  expDate: Yup.string().required('Expiration date is required'),
  cardholder: Yup.string().required('Name on card is required'),
  cvv: Yup.string().required('Security code is required'),
  zipcode: Yup.string().required('Zipcode is required'),
  state: Yup.string().required('State is required'),
});

export const userSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email Address is required'),
});

export const maintenanceBannerSchema = Yup.object().shape({
  maintenance_start_date: Yup.string().required('From Date is required'),
  maintenance_end_date: Yup.string().required('To Date is required'),
  maintenance_start_time: Yup.string().required('From Time is required'),
  maintenance_end_time: Yup.string().required('To Time is required'),
  maintenance_message: Yup.string().required('Banner message is required'),
});

export const pageSettingSchema = Yup.object().shape({
  pagesize: Yup.number()
    .required('Page Size is required')
    .typeError('Must be a number'),
  simquantity: Yup.number()
    .required('Sim Quantity is required')
    .typeError('Must be a number'),
});

export const wifiCallingSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  addressline2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zip is required'),
});
