/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Spin, Select, Pagination } from 'antd';
import ReactModal from 'react-modal';
import { LoadingOutlined } from '@ant-design/icons';
import { FailedIcon } from '../../components/icons';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import _ from 'lodash';
import './styles.scss';

let isnum = /^\d+$/;
const { Option } = Select;

export default class BulkActivation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAddSimLoading: false,
      errorMessage: '',
      allChecked: false,
      userData: {},
      markedSimList: [],
      sims: [],
      filterTags: [],
      selectedListTags: [],
      newTagSize: false,
      selectedTag: '',
      simPlansData: [],
      selectedSimPlan: {},
      planError: false,
      zipcode: '',
      zipCodeError: false,
      zipCodeErrorMessage: '',
      simname: '',
      clients: [],
      selectedClient: {},
      bulkActivateModal: false,
      eligibleZipModelOpen: false,
      page: 1,
      limit: 50,
      total: 0,
      searchedIccidsArray: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Bulk Activation Sims | UltraIoT';
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
      }
      this.getInactiveSims();
      this.fetchPlans();
      this.fetchTags();
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  getInactiveSims = async () => {
    try {
      const tab = _.get(
        this.props,
        'location.pathname',
        '/spr-bulk-activation-sims'
      );
      const simType = tab === '/fixed-bulk-activation-sims' ? 'fixed' : 'spr';
      const { page, limit, searchedIccidsArray } = this.state;
      var iccidsArray =
        searchedIccidsArray && searchedIccidsArray.trim() !== ''
          ? searchedIccidsArray.replace(/ /g, '').split(',')
          : [];
      const obj = {
        page,
        limit,
        simType,
        search: iccidsArray,
        type: 'inactive',
      };
      const {
        value: { success, data, simCount },
      } = await this.props.getBulkSims(obj);
      if (success) {
        this.setState({
          sims: data,
          total: simCount,
          markedSimList: [],
          isLoading: false,
          allChecked: false,
          errorMessage: '',
        });
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'data.message', 'Some thing went wrong...'),
      });
    }
  };

  fetchTags = async () => {
    let type = 'all';
    const tab = _.get(
      this.props,
      'location.pathname',
      '/spr-bulk-activation-sims'
    );
    const simType = tab === '/fixed-bulk-activation-sims' ? 'fixed' : 'spr';
    try {
      const {
        value: { success, data },
      } = await this.props.fetchTagsByType({ type, simType });
      if (success) {
        this.setState({ filterTags: data, isLoading: false });
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  fetchPlans = async () => {
    try {
      let pathname = _.get(
        this.props,
        'location.pathname',
        '/bulk-activation-sims'
      );
      if (pathname === '/bulk-activation-sims') {
        const {
          value: { success, plans },
        } = await this.props.fetchPlans();
        if (success) {
          let filteredPlans = [];
          filteredPlans = plans.filter((plan) => plan.category !== 'Fixed');
          this.setState({ simPlansData: filteredPlans });
        }
      } else {
        const {
          value: { success, fixedPlans },
        } = await this.props.fetchFixedPlansByUserId();
        if (success) {
          this.setState({ simPlansData: fixedPlans });
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  onCheck = (item, key = 'single') => {
    const { sims, allChecked } = this.state;
    let markedSimList = _.cloneDeep(this.state.markedSimList);
    if (key === 'single') {
      let index = markedSimList.findIndex((list) => list.id === item.id);
      if (index === -1) {
        markedSimList.push(item);
      } else {
        markedSimList.splice(index, 1);
      }
      this.setState({ markedSimList }, () => {
        if (_.size(sims) === _.size(markedSimList)) {
          this.setState({ allChecked: true });
        } else {
          this.setState({ allChecked: false });
        }
      });
    } else {
      let allSimData = [...sims];
      if (!allChecked) {
        this.setState({ markedSimList: allSimData, allChecked: true });
      } else {
        this.setState({ markedSimList: [], allChecked: false });
      }
    }
  };

  handleTagChange = (tags) => {
    if (tags.length > 0) {
      let tagSizeError = false;
      _.forEach(tags, (tag) => {
        if (tag.trim().length < 3) {
          tagSizeError = true;
        }
      });
      if (tagSizeError) {
        this.setState({ newTagSize: true });
      } else {
        this.setState({ newTagSize: false, selectedListTags: tags });
      }
    } else {
      this.setState({ selectedListTags: [] });
    }
  };

  addSims = async () => {
    let {
      selectedSimPlan,
      zipcode,
      simname,
      markedSimList,
      selectedListTags,
      newTagSize,
      userData,
    } = this.state;
    selectedListTags = selectedListTags ? selectedListTags.join(',') : '';
    let isError = false;
    if (_.isEmpty(selectedSimPlan)) {
      isError = true;
      this.setState({ planError: true });
    }
    if (zipcode === '') {
      isError = true;
      this.setState({
        zipCodeError: true,
        zipCodeErrorMessage: 'Zipcode is required',
      });
    } else if (zipcode.length !== 5 || !isnum.test(zipcode)) {
      isError = true;
      this.setState({
        zipCodeError: true,
        zipCodeErrorMessage: 'Zipcode is not valid',
      });
    }
    if (newTagSize) {
      isError = true;
    }

    if (!isError) {
      this.setState({ isAddSimLoading: true });
      try {
        let obj = {};
        obj.isBulkActivation = true;
        obj.sims = markedSimList.map((sim) => ({
          simname,
          iccid: sim.simnumber,
          zipcode,
          tags: selectedListTags,
        }));
        if (_.get(userData, 'consumeOfferAPI', false)) {
          obj.title = selectedSimPlan.title;
          obj.offerId = selectedSimPlan.offerId;
        } else {
          obj.planid = selectedSimPlan.id;
        }

        const {
          value: { success },
        } = await this.props.activateSims(obj);
        if (success) {
          this.setState({ isAddSimLoading: false, bulkActivateModal: true });
        } else {
          this.setState({ isAddSimLoading: false, eligibleZipModelOpen: true });
        }
      } catch (error) {
        const err = _.get(error, 'data', error);
        this.setState({ isAddSimLoading: false, errorMessage: err.message });
      }
    }
  };

  bulkActivateOk = () => {
    this.setState({ bulkActivateModal: false });
    let pathname = _.get(
      this.props,
      'location.pathname',
      '/bulk-activation-sims'
    );
    if (pathname === '/bulk-activation-sims') {
      this.props.history.push('/available-sims');
    } else {
      this.props.history.push('/fixed-never-been-activated');
    }
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getInactiveSims();
    });
  };

  handleSearchByICCID = (e) => {
    this.setState(
      { errorMessage: '', searchedIccidsArray: e.target.value, page: 1 },
      () => {
        if (this.state.searchedIccidsArray === '') {
          this.getInactiveSims();
        }
      }
    );
  };

  submitSearchByICCID = () => {
    this.getInactiveSims();
  };

  render() {
    const {
      simPlansData,
      filterTags,
      sims,
      markedSimList,
      selectedListTags,
      eligibleZipModelOpen,
      total,
      limit,
      page,
    } = this.state;
    let pathname = _.get(
      this.props,
      'location.pathname',
      '/bulk-activation-sims'
    );
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
              <div className="side-heading mt-2">
                <h4 className="title">
                  {' '}
                  {pathname === '/bulk-activation-sims'
                    ? 'Bulk Activate Non Active SIMs'
                    : 'Fixed Bulk Activate Non Active SIMs'}{' '}
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  onChange={(e) =>
                    this.setState({
                      selectedSimPlan: simPlansData[e.target.value],
                      planError: false,
                    })
                  }
                  required
                >
                  <option value="{}">Select</option>
                  {_.size(simPlansData) > 0 &&
                    simPlansData.map((plan, index) => {
                      return (
                        <option key={index} value={index}>
                          {pathname === '/bulk-activation-sims'
                            ? plan.title
                            : plan.offername}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">
                  Select Plan <span className="text-danger">*</span>
                </label>
                {this.state.planError && (
                  <div className="invalid-feedback">Plan is required</div>
                )}
                <p className="text-extra-note">
                  This will be the plan for all the below SIM’s
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="zipcode"
                  onChange={(e) =>
                    this.setState({
                      zipcode: e.target.value,
                      zipCodeError: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">
                  Zipcode <span className="text-danger">*</span>
                </label>
                {this.state.zipCodeError && (
                  <div className="invalid-feedback">
                    {this.state.zipCodeErrorMessage}
                  </div>
                )}
                <p className="text-extra-note">
                  This will be the zipcode for all the below SIMs
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group material-textfield">
                <Select
                  mode="tags"
                  size="large"
                  bordered={false}
                  defaultValue={selectedListTags}
                  className="multiple__tags__textfield ex-large"
                  dropdownClassName="multiple__tags__textfield__overlay"
                  style={{ width: '100%' }}
                  placeholder="Select Tag(s) (optional)"
                  onChange={this.handleTagChange}
                >
                  {_.size(filterTags) > 0 &&
                    filterTags.map((tag, index) => {
                      return (
                        <Option key={index} value={tag}>
                          {tag}
                        </Option>
                      );
                    })}
                </Select>
                {this.state.newTagSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save.
                  </div>
                )}
                <p className="text-extra-note">
                  These will be tag(s) associated with all the below SIM’s
                </p>
              </div>
            </div>
          </div>
          <div className="form-group material-textfield">
            <input
              type="text"
              className="form-control form-control-lg material-textfield-input"
              name="simname"
              onChange={(e) => this.setState({ simname: e.target.value })}
              required
            />
            <label className="material-textfield-label">
              SIM Name (optional){' '}
            </label>
            <p className="text-extra-note">
              This will be the SIM name for all the below SIM’s..{' '}
              <strong>example</strong> if you put the name as Ultra we would
              append a name so it would look like Ultra 1, Ultra 2, etc
            </p>
          </div>
          <Spin
            spinning={this.state.isLoading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <div className="card mb-4">
              <div className="card-header d-flex align-items-center">
                <div>
                  <h5 className="card-title">SIMs to Activated</h5>
                  <p className="card-subtitle mt-1">
                    Below are the SIMs that will be bulk activted{' '}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="search-with-tags grid-block-container">
                    <div className="serach-items">
                      <input
                        type="text"
                        className="form-control form-control-lg material-textfield-input"
                        name="searchedIccidsArray"
                        placeholder="Search by iccid with comma separated"
                        onChange={this.handleSearchByICCID}
                        required
                      />
                      {total > 0 ? (
                        <div className="filter-page-number text-right mt-1">
                          {page > 1 ? (page - 1) * limit + 1 : 1}-
                          {limit * page < total ? limit * page : total} of{' '}
                          {total}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <button
                      className="btn btn-dark btn-lg"
                      onClick={this.submitSearchByICCID}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table custom-table-secondary bulk-sim-activate-table">
                    <thead>
                      <tr>
                        <th>
                          <label className="custom-checkbox-only mr-3 align-middle">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              name="duplex"
                              checked={this.state.allChecked}
                              onChange={(e) => this.onCheck({}, 'All')}
                            />
                            <span className="checkmark-only"></span>
                          </label>
                          <span className="table-column-title">ICCID</span>
                        </th>
                        <th>
                          <span className="table-column-title">SIM Name</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.size(sims) > 0 &&
                        sims.map((item, index) => {
                          let checked =
                            markedSimList
                              .map((mark) => mark.id)
                              .indexOf(item.id) !== -1;
                          return (
                            <tr key={index}>
                              <td>
                                <label className="custom-checkbox-only mr-3 align-middle">
                                  <input
                                    type="checkbox"
                                    className="custom-checkbox--input"
                                    name="check"
                                    value={index}
                                    checked={checked}
                                    onChange={() => this.onCheck(item)}
                                  />
                                  <span className="checkmark-only"></span>
                                </label>
                                {_.get(item, 'simnumber', '') &&
                                _.get(item, 'simnumber', '') !== ''
                                  ? _.get(item, 'simnumber', '')
                                  : '-'}
                              </td>
                              <td>
                                {_.get(item, 'simname', '') &&
                                _.get(item, 'simname', '') !== ''
                                  ? _.get(item, 'simname', '-')
                                  : _.get(item, 'simnumber', '-')}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
              className="mb-5"
              hideOnSinglePage
              onChange={this.onPageChanged}
              current={page}
              pageSize={limit}
              defaultCurrent={page}
              total={total}
              showSizeChanger={false}
            />
          </Spin>
          {this.state.errorMessage !== '' && (
            <div className="form-group">
              <div className="invalid-feedback">{this.state.errorMessage}</div>
            </div>
          )}
          <div className="form-group">
            <button
              disabled={
                this.state.isLoading ||
                this.state.isAddSimLoading ||
                _.size(markedSimList) === 0
              }
              className="btn btn-dark btn-lg w-100"
              onClick={this.addSims}
            >
              {this.state.isAddSimLoading ? 'Please wait...' : 'Activate SIMs'}
            </button>
          </div>
        </main>

        {/* BULK ACTIVATION DONE MODAL  */}
        <ReactModal
          isOpen={this.state.bulkActivateModal}
          ariaHideApp={false}
          contentLabel="Thank You - Info"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <h5 className="react-modal-title react-modal-title-md">
                Thank You
              </h5>
              <p className="react-modal-subtitle mt-2">
                Your SIM card(s) are processing. Check the notifications icon
                for updates.
              </p>
              <div className="text-right mt-3">
                <button
                  onClick={this.bulkActivateOk}
                  className="btn btn-dark w-150"
                >
                  {'OK'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={eligibleZipModelOpen}
          onRequestClose={() => this.setState({ eligibleZipModelOpen: false })}
          ariaHideApp={false}
          contentLabel="Success"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xxs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="connection-message-header failed">
              <div>
                <FailedIcon />
              </div>
              <h6>Failed</h6>
            </div>
            <div className="react-modal-body">
              <div className="connection-message form-group">
                <p>
                  {' '}
                  Sorry, Zipcode {this.state.zipcode} is not eligible for Sim
                  Activation.
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-purple btn-lg w-100"
                onClick={() => this.setState({ eligibleZipModelOpen: false })}
              >
                Ok
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
