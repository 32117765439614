/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Menu, Dropdown } from 'antd';
import ReactModal from 'react-modal';
import { CloseIcon, MoreIcon } from '../../../components/icons';
import { userSchema } from '../../../utils/validations';
import { Formik, ErrorMessage } from 'formik';
import _ from 'lodash';
import './styles.scss';

export default class UsersComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      modalIsOpen: false,
      isOpenDeleteModal: false,
      initialValues: {
        firstname: '',
        lastname: '',
        email: '',
      },
      id: 0,
      selectedPermission: [],
      permissions: [],
      grouppedPermissions: [],
      isProcessing: false,
      errorMessage: '',
      popupHeading: 'Add User',
      users: [],
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Users | UltraIoT';
    this.fetchMe();
  };

  fetchMe = async () => {
    try {
      const {
        value: { user },
      } = await this.props.fetchMe();
      this.fetchSubUsers();
      this.fetchPermissions();
      this.setState({ user });
    } catch (err) {
      const error = err.data;
      if (_.get(error, 'outage', false)) {
        this.props.history.push('/maintenance');
      } else if (
        _.get(error, 'reason', false) &&
        _.get(error, 'resetToken', false)
      ) {
        let token = _.get(error, 'resetToken', '');
        this.props.history.push(`/password-update/${token}`);
      }
    }
  };

  fetchSubUsers = async () => {
    const {
      value: { data },
    } = await this.props.fetchSubUsers();
    this.setState({ users: data });
  };

  fetchPermissions = async () => {
    const {
      value: { data },
    } = await this.props.fetchPermissions();
    if (data && data.length > 0) {
      let _permission = [];
      _.forEach(data, function (d) {
        let item = _.find(_permission, function (x) {
          return x.route === d.route;
        });
        if (!item) {
          item = {
            route: d.route,
            items: [
              {
                id: String(d.id),
                name: d.name,
                url: d.url,
              },
            ],
          };
          _permission.push(item);
        } else {
          item.items.push({
            id: String(d.id),
            name: d.name,
            url: d.url,
          });
        }
      });

      this.setState({ grouppedPermissions: _permission, permissions: data });
    }
  };

  openModal() {
    this.setState({
      modalIsOpen: true,
      initialValues: {
        firstname: '',
        lastname: '',
        email: '',
      },
      id: 0,
      selectedPermission: [],
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      isOpenDeleteModal: false,
      id: 0,
      errorMessage: '',
      isProcessing: false,
    });
  }

  selectPermission(item) {
    const selectedPermission = _.cloneDeep(this.state.selectedPermission);
    const index = _.findIndex(selectedPermission, function (per) {
      return parseInt(per.id) === parseInt(item.id);
    });
    if (index === -1) {
      selectedPermission.push(item);
    } else {
      selectedPermission.splice(index, 1);
    }
    this.setState({ selectedPermission });
  }

  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { id, selectedPermission, user } = this.state;
      if (_.size(selectedPermission) > 0) {
        this.setState({ isProcessing: true, errorMessage: '' });
        const payload = {
          ...values,
          id,
          companyname: user.companyname,
          lobId: user.lobId,
          isILDEnabled: user.isILDEnabled,
          isIREnabled: user.isIREnabled,
          role: 'sub_user',
          permissions: JSON.stringify(selectedPermission),
        };
        const {
          value: { success },
        } = await this.props.addSubUser(payload);
        if (success) {
          this.closeModal();
          this.fetchSubUsers();
        }
      } else {
        this.setState({
          isProcessing: false,
          errorMessage: 'Please select permissions',
        });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      const { message } = error;
      this.setState({ isProcessing: false, errorMessage: message });
    }
  };

  showEditPopup = (u) => {
    this.setState({
      initialValues: {
        firstname: u.firstname,
        lastname: u.lastname,
        email: u.email,
      },
      id: u.id,
      selectedPermission: JSON.parse(u.permissions),
      popupHeading: 'Edit User',
      modalIsOpen: true,
      isOpenDeleteModal: false,
    });
  };

  showDeletePopup = (u) => {
    this.setState({
      id: u.id,
      modalIsOpen: false,
      isOpenDeleteModal: true,
    });
  };

  handleDeleteUser = async () => {
    try {
      const { id } = this.state;
      if (parseInt(id) > 0) {
        this.setState({ isProcessing: true, errorMessage: '' });
        const payload = {
          id,
        };
        const {
          value: { success },
        } = await this.props.deleteUser(payload);
        if (success) {
          this.closeModal();
          this.fetchSubUsers();
        }
      } else {
        this.setState({ isProcessing: false });
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      const { message } = error;
      this.setState({ isProcessing: false, errorMessage: message });
    }
  };

  menu = (user) => {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => this.showEditPopup(user)}>
          Edit
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.showDeletePopup(user)}>
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const {
      initialValues,
      selectedPermission,
      grouppedPermissions,
      isProcessing,
      errorMessage,
      popupHeading,
      users,
      isOpenDeleteModal,
    } = this.state;
    const { user } = this.props;
    return (
      <>
        <div className="card-container">
          <div className="card-wrapper">
            <div className="card mb-0">
              <div className="card-header d-flex align-items-center">
                <div>
                  <h5 className="card-title">Users</h5>
                </div>
                {_.get(user, 'role', '') !== 'sub_user' && (
                  <div className="ml-auto">
                    <button
                      className="btn btn-purple btn-sm w-150"
                      onClick={this.openModal}
                    >
                      Add Users
                    </button>
                  </div>
                )}
              </div>
              <div className="">
                <table className="table table-users">
                  <tbody>
                    {users &&
                      users.map((u, index) => {
                        return (
                          <tr key={u.id}>
                            <td>
                              {u.firstname} {u.lastname}
                              <span className="team-role">
                                {u.parentid === 0 ? 'Admin' : 'Regular User'}
                              </span>
                            </td>
                            <td className="text-right">
                              <div
                                className={
                                  u.status === 10
                                    ? 'user-status active'
                                    : 'user-status'
                                }
                              >
                                {u.status === 10 ? 'Active' : 'Not Active'}
                              </div>
                              {_.get(user, 'role', '') !== 'sub_user' && (
                                <Dropdown
                                  overlay={this.menu(u)}
                                  trigger={['click']}
                                  placement="bottomRight"
                                >
                                  <button className="btn ant-dropdown-link">
                                    <MoreIcon />
                                  </button>
                                </Dropdown>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Add Team Member"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{popupHeading}</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={userSchema}
              onSubmit={(values, formikProps) =>
                this.handleSubmit(values, formikProps)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="firstname"
                            className="form-control form-control-lg material-textfield-input"
                            value={values && values.firstname}
                            onChange={handleChange}
                            required
                          />
                          <label className="material-textfield-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="firstname"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="lastname"
                            className="form-control form-control-lg material-textfield-input"
                            value={values && values.lastname}
                            onChange={handleChange}
                            required
                          />
                          <label className="material-textfield-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="lastname"
                            className="invalid-feedback d-block"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg material-textfield-input"
                        value={values && values.email}
                        onChange={handleChange}
                        required
                      />
                      <label className="material-textfield-label">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <ErrorMessage
                        component="span"
                        name="email"
                        className="invalid-feedback d-block"
                      />
                    </div>
                    <h2 className="label-text label-text-sm mb-2">
                      Permissions
                    </h2>
                    {grouppedPermissions.map((permission, index) => (
                      <div key={index}>
                        <h4 className="label-text mb-3">{permission.route}</h4>
                        <ul className="checkbox-list vertical mb-5">
                          {permission.items.map((item, i) => {
                            const checked = _.findIndex(
                              selectedPermission,
                              function (sp) {
                                return parseInt(sp.id) === parseInt(item.id);
                              }
                            );
                            return (
                              <li key={item.id}>
                                <label className="custom-checkbox custom-checkbox-line">
                                  <input
                                    className="custom-checkbox--input"
                                    type="checkbox"
                                    checked={checked !== -1 ? true : false}
                                    onChange={() => this.selectPermission(item)}
                                  />
                                  {item.name}
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ))}
                    <div className="invalid-feedback d-block text-center mb-3">
                      {errorMessage}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-dark btn-lg w-100"
                      disabled={isProcessing}
                    >
                      {isProcessing ? 'Please wait ...' : 'Save'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={isOpenDeleteModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Delete User</h5>
              <p className="react-modal-subtitle mt-2">
                Are you want to delete this user?
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  disabled={isProcessing}
                  onClick={this.handleDeleteUser}
                >
                  {isProcessing ? 'Please wait ...' : 'Yes, Delete'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
