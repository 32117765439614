import { fetch } from '../../utils';

const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const getCoverageDetail = async (data) => {
  return fetch(`${HOSTNAME}/api/v1/coverage/getdetails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDeviceCompatibility = async (data) => {
  return fetch(`${HOSTNAME}/api/v1/sim/deviceCompatible`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBrands = (data) => {
  return fetch(`${HOSTNAME}/api/v1/coverage/getBrands`, {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
