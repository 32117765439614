/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
  formatMegaBytes,
} from '../../constants/common';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import {
  DashboardColorIcon,
  UserColorIcon,
  CaretRightIcon,
  ActiveSimsIcon,
  TagIcon,
  SimsColorIcon,
  NotActivatedIcon,
  PausedSimsIcon,
  DeactivatedSimsIcon,
  DataIcon,
  VoiceIcon,
  SmsIcon,
  InfoIcon,
} from '../../components/icons';
import AverageUsage from '../Dashboard/AverageUsage/component';
import './styles.scss';

export default class DashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      simcards: {},
      simType: 'all',
      pathname: '/dashboard',
      inventoryStats: {},
      userStats: {},
      simsByTags: [],
      simsByTagsCount: 0,
      taglimit: 20,
      tagpage: 1,
      orderByData: [],
      orderBySms: [],
      orderByVoice: [],
      totalDataUsage: 0,
      totalSmsUsage: 0,
      totalVoiceUsage: 0,
      hasPooledPlan: false,
      pooledLoading: true,
      usageLoading: true,
    };
  }

  componentDidMount = async () => {
    document.title = 'Dashboard | UltraIoT';
    const pathname = _.get(this.props, 'location.pathname', '/dashboard');
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      this.props.setUser(user);
      let simType = 'all';
      if (_.get(user, 'lobId', 1) === 1) {
        simType = 'spr';
      } else if (_.get(user, 'lobId', 1) === 3) {
        simType = 'fixed';
      } else {
        if (pathname === '/fixed-dashboard') {
          simType = 'fixed';
        } else {
          simType = 'spr';
        }
      }
      this.setState({ simType }, () => {
        this.getDashboardData();
        this.getSimsByMaxUsage('data');
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tagpage !== prevState.tagpage) {
      this.getDashboardData();
    }
  }

  getDashboardData = async () => {
    const { taglimit, tagpage, simType } = this.state;
    const pathname = _.get(this.props, 'location.pathname', '/dashboard');
    const { user } = this.props;
    try {
      let type = 'spr';
      if (_.get(user, 'lobId', 1) === 2) {
        if (pathname === '/fixed-dashboard') {
          type = 'fixed';
        }
      } else {
        type = simType;
      }
      localStorage.setItem('simType', type);
      this.setState({ simType: type });
      if (type && type !== '') {
        const {
          value: { success, data },
        } = await this.props.fetchDashboardStats('', {
          taglimit,
          tagpage,
          simType: type,
        });

        if (success) {
          const {
            simcards,
            inventoryStats,
            userStats,
            simsByTags,
            simsByTagsCount,
            partnersByUsage,
            hasPooledPlan,
            userInfo,
          } = data;
          this.setState({
            simcards,
            inventoryStats,
            userStats,
            partnersByUsage,
            simsByTagsCount,
            hasPooledPlan,
            userInfo,
          });
          if (simsByTags && simsByTags.length) {
            this.setState({
              simsByTags: [...this.state.simsByTags, ...simsByTags],
            });
          }
        }
      }
    } catch (error) {
      // console.log(error)
    }
  };

  getSimsByMaxUsage = async (type) => {
    const { simType } = this.state;
    try {
      const {
        value: { success, data },
      } = await this.props.fetchSimsByMaxUsage({
        simType,
        type,
        limit: 10,
        page: 1,
      });
      if (success) {
        const {
          orderByData,
          orderBySms,
          orderByVoice,
          totalDataUsage,
          totalSmsUsage,
          totalVoiceUsage,
        } = data;
        this.setState(
          {
            orderByData,
            orderBySms,
            orderByVoice,
            totalDataUsage,
            totalSmsUsage,
            totalVoiceUsage,
          },
          () => {
            this.setState({ usageLoading: false });
          }
        );
      }
    } catch (error) {
      this.setState({ usageLoading: false });
    }
  };

  navigateTo = (page) => {
    const urls = [
      'active-sims',
      'activation-in-progress',
      'never-been-activated',
      'paused-sims',
      'deactivated-sims',
    ];
    const { user } = this.props;
    const hasPermisson = checkPermission(user, page);
    if (user.lobId === 3 && urls.indexOf(page) !== -1) {
      this.props.history.push({ pathname: `/fixed-${page}` });
    } else {
      const pathname = _.get(this.props, 'location.pathname', '/dashboard');
      if (pathname === '/fixed-dashboard') {
        this.props.history.push({ pathname: `/fixed-${page}` });
      } else {
        if (hasPermisson) {
          this.props.history.push({ pathname: `/${page}` });
        }
      }
    }
  };

  loadMoreSimsByTag = (pageNum) => {
    this.setState({ tagpage: pageNum });
  };

  yourSimsByTags = (tags) => {
    const { user } = this.props;
    if (_.get(user, 'lobId', 1) === 3) {
      this.props.history.push('/fixed-active-sims', {
        from: 'dashboard',
        tags,
      });
    } else {
      this.props.history.push('/active-sims', { from: 'dashboard', tags });
    }
  };

  updateDashboard = () => {
    this.getDashboardData();
  };

  // function to add comma's to a numerical value
  formatNumWithComma(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  updateDashboardRoute = (route) => {
    this.props.history.push(route);
    setTimeout(() => {
      this.getDashboardData();
    }, 200);
  };

  render() {
    const {
      simcards,
      inventoryStats,
      userStats,
      simsByTags,
      simsByTagsCount,
      userInfo,
      tagpage,
      orderByData,
      orderBySms,
      orderByVoice,
      totalDataUsage,
      totalSmsUsage,
      totalVoiceUsage,
    } = this.state;
    const { user } = this.props;
    let totalActiveSims = parseInt(simcards && simcards.Active);
    let totalPausedSims = parseInt(simcards && simcards.Paused);
    let totalLines = parseInt(simcards && simcards.TotalLine);
    let availableLines = 0;
    if (totalLines > 0) {
      availableLines = parseInt(totalLines - totalActiveSims - totalPausedSims);
    }
    const monthFirstDate = moment().startOf('month');
    const monthLastDate = moment().endOf('month');
    // getting startDate & endDate for the monthly data query
    const fromDate = moment(monthFirstDate).startOf('day').format('LL');
    const toDate = moment(monthLastDate).endOf('day').format('LL');
    const pathname = _.get(this.props, 'location.pathname', '/dashboard');
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation updateDashboard={this.updateDashboard} {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-7">
              <h2 className="heading-title">
                <DashboardColorIcon />
                Welcome {userInfo && userInfo.firstname}!
              </h2>
              <p className="heading-subtitle">Dashboard Overview</p>
            </div>
            <div className="col-md-5">
              {userInfo && userInfo.role === 'superadmin' && (
                <div className="toggle-dashboard ml-auto">
                  <button
                    className="btn"
                    onClick={() => this.props.history.push('/spr-dashboard')}
                  >
                    SPR Dashboard
                  </button>
                  <button
                    className="btn btn-active"
                    onClick={() => this.props.history.push('/dashboard')}
                  >
                    Classic Dashboard
                  </button>
                </div>
              )}
              {userInfo &&
                (isSPRPartner(userInfo) || isSubUser(userInfo)) &&
                _.get(user, 'lobId', 1) === 2 && (
                  <div className="toggle-dashboard ml-auto">
                    <button
                      className={
                        pathname === '/dashboard' ? `btn btn-active` : 'btn'
                      }
                      onClick={() => this.updateDashboardRoute('/dashboard')}
                    >
                      SPR Dashboard
                    </button>
                    <button
                      className={
                        pathname === '/fixed-dashboard'
                          ? `btn btn-active`
                          : 'btn'
                      }
                      onClick={() =>
                        this.updateDashboardRoute('/fixed-dashboard')
                      }
                    >
                      Fixed Dashboard
                    </button>
                  </div>
                )}
            </div>
          </div>
          {userInfo &&
            (userInfo.role === 'spr_partner' ||
              userInfo.role === 'sub_user') && (
              <Spin
                spinning={this.state.usageLoading}
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: '#000' }}
                    spin
                  />
                }
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="cylce-graph-container">
                        <div className="cylce-graph-header">
                          <DataIcon className="" />
                          <h4 className="cylce-graph-title">Data</h4>
                        </div>
                        <div className="cylce-graph">
                          <div className="graph-circle data-b">
                            <div
                              className="graph-circle-text"
                              data-testId="totalDataUsage"
                            >
                              {formatMegaBytes(
                                this.state?.totalDataUsage || 0,
                                2
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {totalDataUsage > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table custom-table">
                              <tbody>
                                {orderByData &&
                                  orderByData.length > 0 &&
                                  orderByData.map((DataUsage, i) => {
                                    return (
                                      <tr key={i} className="cursor-poniter">
                                        <td>
                                          <span className="count">{i + 1}</span>
                                        </td>
                                        <td>
                                          <span
                                            className="text-underline"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/detail/${DataUsage.simnumber}`
                                              );
                                            }}
                                          >
                                            {DataUsage.simnumber}
                                          </span>
                                        </td>
                                        <td>
                                          {DataUsage && DataUsage.data_used ? (
                                            <NumberFormat
                                              thousandSeparator={true}
                                              displayType={'text'}
                                              value={DataUsage.data_used}
                                              decimalScale={2}
                                            />
                                          ) : (
                                            0
                                          )}
                                          mb
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          <Link
                            to={`/dashboard/top-data-usage`}
                            className="btn btn-lg btn-purple btn-view-all"
                          >
                            View all
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {user &&
                    user.lobId !== 3 &&
                    pathname !== '/fixed-dashboard' && (
                      <div className="col-md-4">
                        <div className="card">
                          <div className="cylce-graph-container">
                            <div className="cylce-graph-header">
                              <VoiceIcon className="" />
                              <h4 className="cylce-graph-title">Voice</h4>
                            </div>
                            <div className="cylce-graph">
                              <div className="graph-circle voice-b">
                                <div className="graph-circle-text">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={totalVoiceUsage}
                                  />{' '}
                                  mins
                                </div>
                              </div>
                            </div>
                            <h4 className="cylce-graph-date">
                              Current month: {fromDate} - {toDate}
                            </h4>
                          </div>
                          {totalVoiceUsage > 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table custom-table">
                                  <tbody>
                                    {orderByVoice &&
                                      orderByVoice.length > 0 &&
                                      orderByVoice.map((voiceUsage, i) => {
                                        return (
                                          <tr key={i} className="cursor-poniter">
                                            <td>
                                              <span className="count">
                                                {i + 1}
                                              </span>
                                            </td>
                                            <td>
                                              <span
                                                className="text-underline"
                                                onClick={() => {
                                                  this.props.history.push(
                                                    `/detail/${voiceUsage.simnumber}`
                                                  );
                                                }}
                                              >
                                                {voiceUsage.simnumber}
                                              </span>
                                            </td>
                                            <td>
                                              {voiceUsage.usage &&
                                              voiceUsage.usage.voice ? (
                                                <NumberFormat
                                                  thousandSeparator={true}
                                                  displayType={'text'}
                                                  value={voiceUsage.usage.voice}
                                                />
                                              ) : (
                                                0
                                              )}
                                              mins
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                              <Link
                                to={`/dashboard/top-voice-usage`}
                                className="btn btn-lg btn-purple btn-view-all"
                              >
                                View all
                              </Link>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                  {user &&
                    user.lobId !== 3 &&
                    pathname !== '/fixed-dashboard' && (
                      <div className="col-md-4">
                        <div className="card">
                          <div className="cylce-graph-container">
                            <div className="cylce-graph-header">
                              <SmsIcon className="" />
                              <h4 className="cylce-graph-title">SMS</h4>
                            </div>
                            <div className="cylce-graph">
                              <div className="graph-circle sms-b">
                                <div className="graph-circle-text">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={totalSmsUsage}
                                  />{' '}
                                  sms
                                </div>
                              </div>
                            </div>
                            <h4 className="cylce-graph-date">
                              Current month: {fromDate} - {toDate}
                            </h4>
                          </div>
                          {totalSmsUsage > 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table custom-table">
                                  <tbody>
                                    {orderBySms &&
                                      orderBySms.length > 0 &&
                                      orderBySms.map((smsUsage, i) => {
                                        return (
                                          <tr key={i} className="cursor-poniter">
                                            <td>
                                              <span className="count">
                                                {i + 1}
                                              </span>
                                            </td>
                                            <td>
                                              <span
                                                className="text-underline"
                                                onClick={() => {
                                                  this.props.history.push(
                                                    `/detail/${smsUsage.simnumber}`
                                                  );
                                                }}
                                              >
                                                {smsUsage.simnumber}
                                              </span>
                                            </td>
                                            <td>
                                              {smsUsage.usage &&
                                              smsUsage.usage.sms ? (
                                                <NumberFormat
                                                  thousandSeparator={true}
                                                  displayType={'text'}
                                                  value={smsUsage.usage.sms}
                                                />
                                              ) : (
                                                0
                                              )}
                                              sms
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                              <Link
                                to={`/dashboard/top-sms-usage`}
                                className="btn btn-lg btn-purple btn-view-all"
                              >
                                View all
                              </Link>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </Spin>
            )}
          <div className="row">
            <div className="col-md-6">
              {userInfo &&
                (userInfo.role === 'spr_partner' ||
                  userInfo.role === 'sub_user') && (
                  <div className="card card__tile__other">
                    <div>
                      <div className="card__tile__title">Available Lines</div>
                      {/* <div className="card__tile__subtitle">These are all your pooled SIMs</div> */}
                      <div className="total-received">
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={availableLines}
                          />
                        </span>{' '}
                        <div className="info--icon">
                          <div className="info-icon-div">
                            {' '}
                            <InfoIcon className="info-icon-block" />
                          </div>
                          <div className="hide">
                            <b>Available Lines</b> = Total Approved Lines -
                            (Active SIMs + Paused SIMs)
                          </div>
                        </div>
                      </div>
                      <div className="total-lines-approved">
                        Total Approved Lines -{' '}
                        <NumberFormat
                          thousandSeparator={true}
                          displayType={'text'}
                          value={totalLines}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {simsByTags && simsByTags.length > 0 ? (
                <div className="card card__tile__other">
                  <div className="d-flex align-items-center">
                    <div className="card__tile__title">
                      <TagIcon />
                      SIMs by Tag
                    </div>
                  </div>
                  <ul
                    className="card__tile__list"
                    style={{ maxHeight: '192px', overflowY: 'auto' }}
                  >
                    {simsByTags.map((sim) => {
                      return (
                        <li
                          className="tag"
                          key={sim.tags}
                          onClick={() => this.yourSimsByTags(sim.tags)}
                        >
                          {sim.tags} (
                          {this.formatNumWithComma(sim.count ? sim.count : 0)})
                        </li>
                      );
                    })}
                  </ul>
                  {simsByTagsCount && simsByTagsCount.length > 20 && (
                    <button
                      className="btn btn-lg btn-purple btn-view-all"
                      onClick={() => this.loadMoreSimsByTag(tagpage + 1)}
                    >
                      View More
                    </button>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-6">
              <div
                className="card__tile"
                onClick={() => this.navigateTo('active-sims')}
              >
                <div className="card__tile__icon purple">
                  <ActiveSimsIcon />
                </div>
                <div className="card__tile__content">
                  <div className="card__tile__title">Active SIMs </div>
                  <div className="card__tile__subtitle">
                    All your SIMs that are actively being used
                  </div>
                </div>
                <div className="card__tile__arrow">
                  <span className="card__tile__count">
                    <NumberFormat
                      thousandSeparator={true}
                      displayType={'text'}
                      value={simcards && simcards.Active}
                    />
                  </span>
                  <CaretRightIcon />
                </div>
              </div>
              <div
                className="card__tile"
                onClick={() => this.navigateTo('never-been-activated')}
              >
                <div className="card__tile__icon purple">
                  <NotActivatedIcon />
                </div>
                <div className="card__tile__content">
                  <div className="card__tile__title">Not been Activated </div>
                  <div className="card__tile__subtitle">
                    All the SIMs you have not been activated yet
                  </div>
                </div>
                <div className="card__tile__arrow">
                  <span className="card__tile__count">
                    <NumberFormat
                      thousandSeparator={true}
                      displayType={'text'}
                      value={simcards && simcards.NotActive}
                    />
                  </span>
                  <CaretRightIcon />{' '}
                </div>
              </div>
              <div
                className="card__tile"
                onClick={() => this.navigateTo('paused-sims')}
              >
                <div className="card__tile__icon purple">
                  <PausedSimsIcon />
                </div>
                <div className="card__tile__content">
                  <div className="card__tile__title">Paused SIMs </div>
                  <div className="card__tile__subtitle">
                    All the SIMs that have been paused{' '}
                  </div>
                </div>
                <div className="card__tile__arrow">
                  <span className="card__tile__count">
                    <NumberFormat
                      thousandSeparator={true}
                      displayType={'text'}
                      value={simcards && simcards.Paused}
                    />
                  </span>
                  <CaretRightIcon />{' '}
                </div>
              </div>
              <div
                className="card__tile"
                onClick={() => this.navigateTo('deactivated-sims')}
              >
                <div className="card__tile__icon purple">
                  <DeactivatedSimsIcon />
                </div>
                <div className="card__tile__content">
                  <div className="card__tile__title">Deactivated SIMs</div>
                  <div className="card__tile__subtitle">
                    All the SIMs that have been deactivated{' '}
                  </div>
                </div>
                <div className="card__tile__arrow">
                  <span className="card__tile__count">
                    <NumberFormat
                      thousandSeparator={true}
                      displayType={'text'}
                      value={simcards && simcards.Deactivated}
                    />
                  </span>
                  <CaretRightIcon />{' '}
                </div>
              </div>

              <AverageUsage {...this.props} />

            </div>
          </div>
          {userInfo && userInfo.role === 'superadmin' ? (
            <>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="card card__tile__other">
                    <div className="d-flex align-items-center">
                      <div className="card__tile__title">
                        <SimsColorIcon />
                        Inventory Stats{' '}
                      </div>
                      <div className="card__tile__header__count">
                        <NumberFormat
                          thousandSeparator={true}
                          displayType={'text'}
                          value={inventoryStats && inventoryStats.TotalSim}
                        />
                      </div>
                    </div>
                    <ul className="card__tile__list">
                      <li>
                        Total - Active SIMs{' '}
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={
                              inventoryStats && inventoryStats.TotalActiveSim
                            }
                          />
                        </span>
                      </li>
                      <li>
                        Total - Never been Activated{' '}
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={
                              inventoryStats && inventoryStats.TotalNotActiveSim
                            }
                          />
                        </span>
                      </li>
                      <li>
                        Total - Paused SIMs{' '}
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={
                              inventoryStats && inventoryStats.TotalPausedSim
                            }
                          />
                        </span>
                      </li>
                      <li>
                        Total - Deactivated SIMs{' '}
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={
                              inventoryStats &&
                              inventoryStats.TotalDeactivatedSim
                            }
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card__tile__other">
                    <div className="d-flex align-items-center">
                      <div className="card__tile__title">
                        <UserColorIcon />
                        User Stats
                      </div>
                      <div className="card__tile__header__count">
                        <NumberFormat
                          thousandSeparator={true}
                          displayType={'text'}
                          value={userStats && userStats.TotalSprPartners}
                        />
                      </div>
                    </div>
                    <ul className="card__tile__list">
                      <li>
                        Total - SPR Partners{' '}
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={userStats && userStats.TotalSprPartners}
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </main>
      </div>
    );
  }
}
