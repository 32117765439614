import { connect } from 'react-redux';
import AcceptInvitationComponent from './component';
import { fetchInviteUserDetail, acceptInvite } from '../../store/user/duck';

const AcceptInvitationContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    fetchInviteUserDetail,
    acceptInvite,
  }
)(AcceptInvitationComponent);

export default AcceptInvitationContainer;
