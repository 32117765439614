import React from 'react';
import { Link } from 'react-router-dom';
import { CaretRightIcon } from '../../../components/icons';

const AverageUsage = (props) => {
  return (
    <div className="average-usage-section mb-3">
      <div className="card__tile--link">
        <Link
          className="btn btn-purple w-100"
          onClick={() => props.history.push('/dashboard/avg-usage')}
        >
          <span className="card__tile--title">Average Usage by Plan</span>{' '}
          <CaretRightIcon />
        </Link>
      </div>
      <br />
      <div className="card__tile--link">
        <Link
          className="btn btn-purple w-100"
          onClick={() => props.history.push(`/dashboard/mom-usage`)}
        >
          <span className="card__tile--title">Month over month usage </span>{' '}
          <CaretRightIcon />
        </Link>
      </div>
    </div>
  );
};

export default AverageUsage;
