import React, { useEffect, useState } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { RatesIcon } from '../../components/icons';
import { DebounceInput } from 'react-debounce-input';
import InternationLongDistance from './partials/InternationalLongDistance';
import InternationalRoaming from './partials/InternationalRoaming';

import './styles.scss';

const InternationRates = ({ fetchMe, setUser, ...props }) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    (async () => {
      const {
        value: { success, user },
      } = await fetchMe();
      if (success && user) {
        setUser(user);
      }
    })();
  }, [fetchMe, setUser]);

  const onTabChange = (tab) => {
    if (tab === 'International Roaming') {
      props.history.push('/rates/ir');
    } else if (tab === 'International long distance') {
      props.history.push('/rates/ild');
    }
  };

  return (
    <>
      <div className="layout-has-sidebar">
        <SidebarNavigation {...props} />
        <TopNavigation {...props} user={props.user} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-5">
              <h2 className="heading-title">
                <RatesIcon />
                International Rates
              </h2>
            </div>
          </div>
          <div className="mobile-select d-lg-none">
            <select
              className="form-control custom-select"
              onChange={(e) => onTabChange(e.target.value)}
            >
              <option value="International long distance">
                International long distance{' '}
              </option>
              <option value="International Roaming">
                International Roaming
              </option>
            </select>
          </div>
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <div className="tabs__control mb-3">
                <button
                  onClick={() => props.history.push('/rates/ild')}
                  className={
                    props.match.path === '/rates/ild' ? 'btn active' : 'btn'
                  }
                >
                  International long distance
                </button>
                <button
                  onClick={() => props.history.push('/rates/ir')}
                  className={
                    props.match.path === '/rates/ir' ? 'btn active' : 'btn'
                  }
                >
                  International Roaming
                </button>
              </div>
              <div className="mb-3">
                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  className="form-control"
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search by destination or country code..."
                  name="searchUser"
                  required
                />
              </div>
            </div>
          </div>

          {props.match.path === '/rates/ild' ? (
            <InternationLongDistance searchText={searchText} {...props} />
          ) : (
            <InternationalRoaming searchText={searchText} {...props} />
          )}
          {/* <Pagination
            className="mb-5"
            hideOnSinglePage
            onChange={onPageChange}
            current={page}
            pageSize={limit}
            defaultCurrent={page}
            total={totalPagination}
            showSizeChanger={false}
          /> */}
        </main>
      </div>
    </>
  );
};
export default InternationRates;
