import React, { PureComponent } from 'react';
import SidebarNavigation from '../SidebarNavigation/component';
import TopNavigation from '../TopNavigation/container';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Pagination } from 'antd';
import ReactModal from 'react-modal';
import { Select } from 'antd';
import {
  BackArrowIcon,
  SortDownIcon,
  SortUpIcon,
  CloseIcon,
} from '../../components/icons';
import {
  isSPRPartner,
  isSubUser,
  checkPermission,
} from '../../constants/common';
import _ from 'lodash';
import './styles.scss';
// For tags options
const { Option } = Select;

export default class ManageBulkComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      iAuthorization: localStorage.getItem('i_Authorization'),
      isLoading: false,
      user: null,
      simList: [],
      markedSimList: [],
      page: 1,
      limit: 50,
      tabType: 'ild',
      tabValue: 0,
      sortOrder: 'desc',
      sortBy: 'activationdate',
      totalSimsCount: 0,
      selectAllSims: false,
      isCreateClientModal: false,
      newClientSize: false,
      selectedClient: [],
      clients: [],
      newTag: '',
      newTagSize: false,
      isTagChanged: false,
      filterTags: [],
      selectedListTags: [],
      originalSelectedTags: [],
      isCreateTagModal: false,
      updateLoading: false,
      confirmationModal: false,
      simtype: 'spr',
      confirmationTitle: '',
      confirmationText: '',
      searchBy: 'iccid',
      searchSims: ''
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Manage ILD/IR | UltraIoT';
    try {
      const { match } = this.props;
      let simtype = 'spr';
      if (match.path.indexOf('fixed') !== -1) {
        simtype = 'fixed';
      }
      this.setState({ isLoading: true, simtype });
      const {
        value: { user },
      } = await this.props.fetchMe();
      if (user) {
        this.props.setUser(user);
        if (parseInt(_.get(user, 'isILDEnabled', 0)) === 1) {
          this.setState({ user, tabType: 'ild', tabValue: 0 });
        } else {
          this.setState({ user, tabType: 'ir', tabValue: 0 });
        }
        this.fetchSimsWithAddOns();
      }
    } catch (error) {
      return;
    }
  };

  fetchSimsWithAddOns = async () => {
    const { page, limit, tabType, tabValue, sortBy, sortOrder, simtype, searchBy, searchSims } =
      this.state;
    this.setState({ isLoading: true, markedSimList: [] });
    try {
      let payload = {
        type: tabType,
        value: tabValue,
        page,
        limit,
        sortBy,
        sortOrder,
        simtype,
      };
      if (searchBy === 'iccid') {
        payload.iccid = searchSims;
      }else{
      payload.msisdn = searchSims;
      }
      const {
        value: { records, total },
      } = await this.props.fetchSimsWithAddOns(payload, searchBy);
      this.getAllClients();
      this.setState({
        simList: records,
        totalSimsCount: total,
        isLoading: false,
        selectAllSims: false,
        updateLoading: false,
        confirmationModal: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.fetchSimsWithAddOns();
    });
  };

  onTabChange = (tab, value, path) => {
    const { simtype } = this.state;
    this.setState({ tabType: tab, tabValue: value, page: 1 }, () => {
      window.scrollTo(0, 0);
      this.fetchSimsWithAddOns();
      this.props.history.push(`/active-${simtype}-sims/${path}`);
    });
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  sortByKey = (key) => {
    const { sortOrder, sortBy } = this.state;
    if (sortOrder === 'asc' || sortBy !== key) {
      this.setState({ sortOrder: 'desc', sortBy: key }, () => {
        this.fetchSimsWithAddOns();
      });
    } else {
      this.setState({ sortOrder: 'asc', sortBy: key }, () => {
        this.fetchSimsWithAddOns();
      });
    }
  };

  onCheck = (item) => {
    const markedSimList = [...this.state.markedSimList];
    const index = markedSimList.indexOf(item);
    if (index === -1) {
      markedSimList.push(item);
    } else {
      markedSimList.splice(index, 1);
    }
    this.setState({ markedSimList });
  };

  handleSelectAllSims = (event) => {
    this.setState({
      selectAllSims: event.target.checked,
      markedSimList: event.target.checked ? this.state.simList : [],
    });
  };

  closeModal() {
    this.setState({
      isCreateClientModal: false,
      isCreateTagModal: false,
      confirmationModal: false,
      confirmationText: '',
      confirmationTitle: '',
      markedSimList: [],
      selectAllSims: false,
    });
  }

  // SIM Detail Page
  simDetails = (iccid) => {
    this.props.history.push(`/detail/${iccid}`);
  };

  getAllClients = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchClients();
      if (success) {
        this.setState({ clients: data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addClientOnSim = (index) => {
    let allSims = this.state.simList;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createClientModal();
    });
  };

  createClientModal() {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      this.setState({
        isCreateClientModal: true,
        newClientSize: false,
        selectedClient: [],
      });
    }
  }

  handleClientSelect = (client) => {
    this.setState({ selectedClient: [] }, () => {
      setTimeout(() => {
        if (client.trim().length !== 0) {
          this.setState({ selectedClient: [client] });
        }
      }, 300);
    });
  };

  handleClientChange = (clientsArr) => {
    if (clientsArr.length > 0) {
      let index = clientsArr.length - 1;
      let clientName = clientsArr[index];
      let newClientSize = false;
      if (clientName.trim().length < 3) {
        newClientSize = true;
      }
      if (newClientSize) {
        this.setState({ newClientSize: true });
      } else {
        this.setState({ selectedClient: [clientName], newClientSize: false });
      }
    } else {
      this.setState({ selectedClient: [], newClientSize: false });
    }
  };

  saveClient = async () => {
    const { selectedClient, markedSimList, clients } = this.state;
    let obj = {};
    let clientIndex = clients.findIndex(
      (client) => client.fullname === selectedClient[0]
    );
    if (clientIndex !== -1) {
      obj = {
        simIds: markedSimList.map((sim) => sim.id),
        clientId: clients[clientIndex].id,
      };
    } else {
      obj = {
        simIds: markedSimList.map((sim) => sim.id),
        clientId: 0,
        name: selectedClient[0],
      };
    }
    this.setState({ updateLoading: true });
    try {
      const {
        value: { success },
      } = await this.props.addClient(obj);
      if (success) {
        this.setState(
          {
            isCreateClientModal: false,
            selectedClient: [],
            updateLoading: false,
          },
          () => {
            // this.updateClientLocal(client);
            this.fetchSimsWithAddOns();
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err);
      this.setState({
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  updateClientLocal = (client) => {
    let allSims = this.state.simList;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.clientId = client.clientId;
          record.clientname = client.clientname;
        }
        return mark;
      });
    });
    let allClients = this.state.clients;
    let obj = {
      id: client.clientId,
      fullname: client.clientname,
    };
    if (allClients.findIndex((item) => item.id === client.clientId) === -1) {
      allClients.push(obj);
    }
    this.setState({ simList: allSims, clients: allClients });
  };

  addTagOnSim = (index) => {
    let allSims = this.state.simList;
    let selectedSims = allSims[index];
    this.setState({ markedSimList: [selectedSims] }, () => {
      this.createTagsModal();
    });
  };

  createTagsModal() {
    const { markedSimList } = this.state;
    if (_.size(markedSimList) > 0) {
      let tagsArr = [];
      _.forEach(markedSimList, (item, index) => {
        if (item.tags && item.tags !== '') {
          let arr = item.tags.split(',');
          tagsArr.push(...arr);
        }
      });
      let uniquesTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      let originalTags = tagsArr.filter((v, i, a) => a.indexOf(v) === i);
      this.setState({
        isCreateTagModal: true,
        newTagSize: false,
        newTag: '',
        selectedListTags: uniquesTags,
        originalSelectedTags: originalTags,
      });
    } else {
      this.setState({ selectedListTags: [], originalSelectedTags: [] });
    }
  }

  saveTag = async () => {
    const { newTag, markedSimList } = this.state;
    this.setState({ updateLoading: true });
    let applyTags = newTag ? newTag.join(',') : '';
    let obj = {};
    obj.sims = [];
    obj.sims = markedSimList.map((sim) => ({ id: sim.id, tags: applyTags }));
    try {
      const {
        value: { success },
      } = await this.props.updateTag(obj);
      if (success) {
        this.setState(
          {
            isCreateTagModal: false,
            isTagChanged: false,
            newTag: '',
            updateLoading: false,
          },
          () => {
            this.updateTagsLocal(applyTags);
            // this.fetchSimsWithAddOns();
          }
        );
      }
    } catch (err) {
      const error = _.get(err, 'data', err)
      this.setState({
        updateLoading: false,
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  updateTagsLocal = (applyTags) => {
    let allSims = this.state.simList;
    let selectedSims = this.state.markedSimList;
    allSims.filter((record) => {
      return selectedSims.filter((mark) => {
        if (mark.id === record.id) {
          record.tags = applyTags;
        }
        return mark;
      });
    });
    this.setState({ simList: allSims });
  };

  handleTagChange = (tags) => {
    this.setState({ isTagChanged: true });
    if (tags.length > 0) {
      let tagSizeError = false;
      _.forEach(tags, (tag) => {
        if (tag.trim().length < 3) {
          tagSizeError = true;
        }
      });
      if (tagSizeError) {
        this.setState({ newTagSize: true });
      } else {
        this.setState({ newTagSize: false, newTag: tags });
      }
    } else {
      this.setState({ newTag: [] });
    }
  };

  handleConfirmationModal = () => {
    const { tabType, tabValue } = this.state;
    let confirmationTitle = '';
    let confirmationText = '';
    if (tabType === 'ild' && tabValue === 0) {
      confirmationTitle = 'Enable ILD';
      confirmationText =
        'Are you sure you want to Enable ILD for selected SIM(s)?';
    } else if (tabType === 'ild' && tabValue === 1) {
      confirmationTitle = 'Disable ILD';
      confirmationText =
        'Are you sure you want to Disable ILD for selected SIM(s)?';
    } else if (tabType === 'ir' && tabValue === 0) {
      confirmationTitle = 'Enable IR';
      confirmationText =
        'Are you sure you want to Enable IR for selected SIM(s)?';
    } else if (tabType === 'ir' && tabValue === 1) {
      confirmationTitle = 'Disable IR';
      confirmationText =
        'Are you sure you want to Disable IR for selected SIM(s)?';
    }
    this.setState({
      confirmationModal: true,
      confirmationTitle,
      confirmationText,
    });
  };

  bulkManageAddOns = async () => {
    const { tabType, tabValue, markedSimList } = this.state;
    this.setState({ isLoading: true, updateLoading: true });
    try {
      let payload = {
        type: tabType,
        value: tabValue === 1 ? 0 : 1,
        ids: markedSimList.map((sim) => sim.id),
      };
      const manageIntlBenefitsResp = await this.props.bulkManageAddOns(payload);
      if (!manageIntlBenefitsResp)
        throw new Error('Error while processing the request.');
      window.scrollTo(0, 0);
      this.fetchSimsWithAddOns();
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  searchByChange = (e) => {
    this.setState({ searchBy: e.target.value }, () => {
      if (this.state.searchBy) {
        this.fetchSimsWithAddOns();
      }
    });
  };

  handleSearch(e) {
    let searchSims = e.target.value;
    const isSims = searchSims.includes(',');
    if (isSims) {
      searchSims =
        searchSims && searchSims.trim() !== ''
          ? searchSims.replace(/ /g, '').split(',')
          : '';
      searchSims = searchSims.join(',')
    }
    this.setState({ searchSims, page: 1 }, () => {
      if (this.state.searchSims === '') {
        this.fetchSimsWithAddOns();
      }
    });
  }

  submitSearch = () => {
    this.fetchSimsWithAddOns();
  };

  render() {
    const {
      isLoading,
      tabType,
      tabValue,
      page,
      limit,
      sortBy,
      sortOrder,
      simList,
      totalSimsCount,
      markedSimList,
      selectAllSims,
      clients,
      selectedClient,
      filterTags,
      selectedListTags,
      confirmationTitle,
      confirmationText,
      total
    } = this.state;
    const { user } = this.props;

    return (
      <>
        <div className="layout-has-sidebar">
          <SidebarNavigation {...this.props} />
          <TopNavigation {...this.props} />
          <main className="dashboard-layout-content">
            <div className="row align-items-md-center mb-4">
              <div className="col-md-4">
                <button
                  onClick={() => this.props.history.push('/active-sims')}
                  className="btn btn-back"
                >
                  <BackArrowIcon className="back-icon" /> Back
                </button>
              </div>
              <div className="col-md-8 text-right"></div>
            </div>

            <div className="card">
              <div className="card-header d-flex align-items-center">
                <div>
                  <h5 className="card-title card-title-md">
                    Bulk Manage ILD/IR{' '}
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="search-with-tags grid-block-container mobile-stack-container">
                    <div className="serach-items">
                      <div className="search-by-select">
                        <span className="search-by-select-label">
                          Search by:
                        </span>
                        <select
                          className="form-control custom-select col-md-2"
                          name="searchBy"
                          value={this.state.searchBy}
                          onChange={this.searchByChange.bind(this)}
                        >
                          <option value={'iccid'}>ICCID</option>
                          <option value={'msisdn'}>Phone Number</option>
                        </select>
                        <input
                          type="text"
                          value={this.state.keyword}
                          className="form-control form-control-lg material-textfield-input"
                          name="searchSims"
                          placeholder={`Search by ${
                            this.state.searchBy === 'iccid'
                              ? 'iccid'
                              : 'Phone Number'
                          }`}
                          onChange={this.handleSearch.bind(this)}
                          required
                        />
                      </div>
                     {total > 0 ? (
                        <div className="filter-page-number text-right mt-1">
                          {page > 1 ? (page - 1) * limit + 1 : 1}-
                          {limit * page < total ? limit * page : total} of{' '}
                          {total}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <button
                      className="btn btn-dark btn-lg"
                      onClick={this.submitSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div className="table-btn-filters">
                  {parseInt(user.isILDEnabled) === 1 && (
                    <button
                      onClick={() => {
                        this.onTabChange('ild', 0, 'bulk-ild');
                      }}
                      className={
                        tabType === 'ild' && tabValue === 0
                          ? 'btn active'
                          : 'btn'
                      }
                    >
                      Bulk Enable ILD Feature
                    </button>
                  )}
                  {parseInt(user.isILDEnabled) === 1 && (
                    <button
                      onClick={() => {
                        this.onTabChange('ild', 1, 'bulk-disable-ild');
                      }}
                      className={
                        tabType === 'ild' && tabValue === 1
                          ? 'btn active'
                          : 'btn'
                      }
                    >
                      Bulk Disable ILD Feature
                    </button>
                  )}
                  {parseInt(user.isIREnabled) === 1 && (
                    <button
                      onClick={() => {
                        this.onTabChange('ir', 0, 'bulk-ir');
                      }}
                      className={
                        tabType === 'ir' && tabValue === 0
                          ? 'btn active'
                          : 'btn'
                      }
                    >
                      Bulk Enable IR Feature
                    </button>
                  )}
                  {parseInt(user.isIREnabled) === 1 && (
                    <button
                      onClick={() => {
                        this.onTabChange('ir', 1, 'bulk-disable-ir');
                      }}
                      className={
                        tabType === 'ir' && tabValue === 1
                          ? 'btn active'
                          : 'btn'
                      }
                    >
                      Bulk Disable IR Feature
                    </button>
                  )}
                </div>

                <div className="col-md-12">
                  <div className="all-filters">
                    <div className="select-all">
                      <label className="custom-checkbox-only">
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          name="duplex"
                          checked={selectAllSims}
                          onChange={(event) => this.handleSelectAllSims(event)}
                        />
                        <span className="checkmark-only"></span>
                      </label>
                    </div>

                    {/* select Action */}
                    <div className="sims-action">
                      {tabType === 'ild' && tabValue === 0 && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          className="btn btn-link"
                          onClick={this.handleConfirmationModal}
                        >
                          Enable ILD
                        </button>
                      )}
                      {tabType === 'ild' && tabValue === 1 && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          className="btn btn-link"
                          onClick={this.handleConfirmationModal}
                        >
                          Disable ILD
                        </button>
                      )}
                      {tabType === 'ir' && tabValue === 0 && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          className="btn btn-link"
                          onClick={this.handleConfirmationModal}
                        >
                          Enable IR
                        </button>
                      )}
                      {tabType === 'ir' && tabValue === 1 && (
                        <button
                          disabled={_.size(markedSimList) === 0}
                          className="btn btn-link"
                          onClick={this.handleConfirmationModal}
                        >
                          Disable IR
                        </button>
                      )}
                    </div>
                    <div className="filter-page-number ml-auto">
                      {page > 1 ? (page - 1) * limit + 1 : 1} -{' '}
                      {limit * page < totalSimsCount
                        ? limit * page
                        : totalSimsCount}{' '}
                      of {totalSimsCount}
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <div className="blank__table__card mt-3">
                    <div className="blank__table__card__inner">
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: '#000' }}
                            spin
                          />
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {_.size(simList) === 0 ? (
                      <div className="blank__table__card mt-3">
                        <div className="blank__table__card__inner">No SIMs</div>
                      </div>
                    ) : (
                      <table className="table custom-table custom-table-fixed-col">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>
                              <span
                                onClick={() => this.sortByKey('simname')}
                                className="table-column-title cursor-poniter"
                              >
                                SIM Name
                                {sortBy === 'simname' ? (
                                  sortOrder === 'asc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  )
                                ) : (
                                  <SortUpIcon />
                                )}
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">Tags</span>
                            </th>
                            {user &&
                              (isSPRPartner(user) ||
                                (isSubUser(user) &&
                                  checkPermission(user, '/add-client'))) && (
                                <th>
                                  <span
                                    onClick={() => this.sortByKey('clientname')}
                                    className="table-column-title cursor-poniter"
                                  >
                                    Client
                                    {sortBy === 'clientname' ? (
                                      sortOrder === 'asc' ? (
                                        <SortUpIcon />
                                      ) : (
                                        <SortDownIcon />
                                      )
                                    ) : (
                                      <SortUpIcon />
                                    )}
                                  </span>
                                </th>
                              )}
                            <th>
                              <span
                                onClick={() => this.sortByKey('zipcode')}
                                className="table-column-title cursor-poniter"
                              >
                                Zipcode
                                {sortBy === 'zipcode' ? (
                                  sortOrder === 'asc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  )
                                ) : (
                                  <SortUpIcon />
                                )}
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title cursor-poniter">
                                User ID{' '}
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={() => this.sortByKey('simnumber')}
                                className="table-column-title cursor-poniter"
                              >
                                ICCID
                                {sortBy === 'simnumber' ? (
                                  sortOrder === 'asc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  )
                                ) : (
                                  <SortUpIcon />
                                )}
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={() => this.sortByKey('msisdn')}
                                className="table-column-title cursor-poniter"
                              >
                                Phone Number
                                {sortBy === 'msisdn' ? (
                                  sortOrder === 'asc' ? (
                                    <SortUpIcon />
                                  ) : (
                                    <SortDownIcon />
                                  )
                                ) : (
                                  <SortUpIcon />
                                )}
                              </span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {simList.map((item, index) => {
                            let tagsArr =
                              item.tags && item.tags !== ''
                                ? item.tags.split(',')
                                : [];
                            let checked =
                              markedSimList
                                .map((mark) => mark.id)
                                .indexOf(item.id) !== -1;
                            return (
                              <tr className="cursor-poniter" key={item.id}>
                                <td className="checkbox-wrap">
                                  <label className="custom-checkbox-only">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      name="check"
                                      checked={checked}
                                      onChange={(e) => this.onCheck(item)}
                                    />
                                    <span className="checkmark-only"></span>
                                  </label>
                                </td>
                                <td
                                  onClick={() =>
                                    checked
                                      ? console.log('')
                                      : this.simDetails(item.simnumber)
                                  }
                                >
                                  {_.get(item, 'simname', '') &&
                                  _.get(item, 'simname', '') !== '' &&
                                  _.get(item, 'simname', '') !== 'undefined'
                                    ? _.get(item, 'simname', '-')
                                    : _.get(item, 'simnumber', '-')}
                                </td>
                                <td>
                                  {_.size(tagsArr) > 0 &&
                                    tagsArr.map((tag) => {
                                      return (
                                        <button
                                          key={tag}
                                          className="btn btn-tags mr-2"
                                        >
                                          {tag}
                                        </button>
                                      );
                                    })}
                                  {item.status !== 9 && (
                                    <button
                                      onClick={() => this.addTagOnSim(index)}
                                      className="btn text-underline btn-link-orange"
                                    >
                                      Add Tag
                                    </button>
                                  )}
                                </td>
                                {user &&
                                  (isSPRPartner(user) ||
                                    (isSubUser(user) &&
                                      checkPermission(
                                        user,
                                        '/add-client'
                                      ))) && (
                                    <td>
                                      {item.clientname &&
                                      _.get(item, 'clientname', '') !== '' ? (
                                        <button className="btn btn-tags btn-client mr-2">
                                          {_.get(item, 'clientname', '-')}
                                        </button>
                                      ) : item.status === 0 ||
                                        item.status === 10 ||
                                        item.status === 12 ? (
                                        <button
                                          onClick={() =>
                                            this.addClientOnSim(index)
                                          }
                                          className="btn text-underline btn-link-orange"
                                        >
                                          Add Client
                                        </button>
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                  )}
                                <td
                                  onClick={() =>
                                    checked
                                      ? console.log('')
                                      : this.simDetails(item.simnumber)
                                  }
                                >
                                  {_.get(item, 'zipcode', '-')}
                                </td>
                                <td
                                  onClick={() =>
                                    this.simDetails(item.simnumber)
                                  }
                                >
                                  {_.get(item, 'ultraUserId', '') &&
                                  _.get(item, 'ultraUserId', '') !== ''
                                    ? _.get(item, 'ultraUserId', '')
                                    : '-'}
                                </td>
                                <td
                                  onClick={() =>
                                    this.simDetails(item.simnumber)
                                  }
                                >
                                  {_.get(item, 'simnumber', '') &&
                                  _.get(item, 'simnumber', '') !== ''
                                    ? _.get(item, 'simnumber', '')
                                    : '-'}
                                </td>
                                <td
                                  onClick={() =>
                                    this.simDetails(item.simnumber)
                                  }
                                >
                                  {_.get(item, 'msisdn', '') &&
                                  _.get(item, 'msisdn', '') !== ''
                                    ? this.formatPhoneNumber(
                                        _.get(item, 'msisdn', '-')
                                      )
                                    : '-'}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>
            <Pagination
              className="mb-5"
              hideOnSinglePage
              onChange={(page) => this.onPageChange(page)}
              current={page}
              pageSize={limit}
              defaultCurrent={page}
              total={totalSimsCount}
              showSizeChanger={false}
            />
          </main>

          {/* CREATE CLIENT MODAL */}
          <ReactModal
            isOpen={this.state.isCreateClientModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            contentLabel="Create tags"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Add Client </h5>
                <button
                  type="button"
                  className="btn react-modal-close"
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="form-group material-textfield">
                  <Select
                    autoFocus
                    mode="tags"
                    size="large"
                    bordered
                    className="multiple__tags__textfield"
                    dropdownClassName="multiple__tags__textfield__overlay"
                    style={{ width: '100%' }}
                    placeholder="Client Name"
                    value={selectedClient}
                    onChange={this.handleClientChange}
                    onSelect={this.handleClientSelect}
                  >
                    {_.size(clients) > 0 &&
                      clients.map((client, index) => {
                        return (
                          <Option key={index} value={client.fullname}>
                            {client.fullname}
                          </Option>
                        );
                      })}
                  </Select>
                  {this.state.newClientSize && (
                    <div className="invalid-feedback">
                      Please enter at least three characters to save.
                    </div>
                  )}
                </div>
                <button
                  disabled={
                    this.state.updateLoading ||
                    this.state.newClientSize ||
                    _.size(this.state.selectedClient) === 0
                  }
                  type="button"
                  onClick={this.saveClient.bind(this)}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              </div>
            </div>
          </ReactModal>

          {/* CREATE TAG MODAL */}
          <ReactModal
            isOpen={this.state.isCreateTagModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            contentLabel="Create tags"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Add Tag </h5>
                <button
                  type="button"
                  className="btn react-modal-close"
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <div className="form-group material-textfield">
                  <Select
                    autoFocus
                    mode="tags"
                    size="large"
                    bordered={false}
                    // maxTagTextLength={5}
                    defaultValue={selectedListTags}
                    className="multiple__tags__textfield"
                    dropdownClassName="multiple__tags__textfield__overlay"
                    // dropdownStyle={{display: "none"}}
                    style={{ width: '100%' }}
                    placeholder="Tag Name"
                    onChange={this.handleTagChange}
                  >
                    {_.size(filterTags) > 0 &&
                      filterTags.map((tag, index) => {
                        return (
                          <Option key={index} value={tag}>
                            {tag}
                          </Option>
                        );
                      })}
                  </Select>
                  {this.state.newTagSize && (
                    <div className="invalid-feedback">
                      Please enter at least three characters to save.
                    </div>
                  )}
                </div>
                <button
                  disabled={
                    this.state.updateLoading ||
                    this.state.newTagSize ||
                    !this.state.isTagChanged
                  }
                  type="button"
                  onClick={this.saveTag.bind(this)}
                  className="btn btn-dark btn-lg w-100"
                >
                  {this.state.updateLoading ? 'Please wait...' : 'Save'}
                </button>
              </div>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={this.state.confirmationModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            contentLabel="Are you sure?"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title react-modal-title-md">
                  {confirmationTitle}
                </h5>
                <button
                  type="button"
                  className="btn react-modal-close"
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body">
                <p className="react-modal-subtitle mb-2">{confirmationText}</p>
                <div className="text-right">
                  <button
                    disabled={this.state.updateLoading}
                    type="button"
                    onClick={this.bulkManageAddOns}
                    className="btn-dark btn-lg w-150"
                  >
                    {this.state.updateLoading
                      ? 'Please wait...'
                      : "Yes, I'm sure"}
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
      </>
    );
  }
}
