import { Button, Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchFileDataFromDocumentId } from '../../store/user/api';
import { useMutation } from '@tanstack/react-query';
import { downloadFileFromBlob } from '../../utils/helpers';

function DownloadDocumentView() {
  const { id, filename } = useParams();

  const {
    isLoading,
    error,
    isSuccess,
    mutate: fetchFileData,
  } = useMutation({
    mutationFn: async () => {
      const fileBlob = await fetchFileDataFromDocumentId({ documentId: id });

      downloadFileFromBlob(fileBlob, filename);
    },
  });

  useEffect(() => {
    fetchFileData();
  }, [fetchFileData]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState error={error} fetchFileData={fetchFileData} />;
  }

  if (isSuccess) {
    return <SuccessState />;
  }

  return null;
}

function LoadingState() {
  return (
    <Result
      status="info"
      title="Your File is being downloaded..."
      subTitle={null}
      icon={<Spin size="large" />}
      extra={null}
    />
  );
}

function ErrorState({ error, fetchFileData }) {
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle={error?.data?.message || error?.message}
      extra={
        <Button
          type="primary"
          className="btn btn-dark"
          onClick={() => fetchFileData()}
        >
          Try Again
        </Button>
      }
    />
  );
}

function SuccessState() {
  return (
    <Result
      status="success"
      title="File Downloaded"
      subTitle="File Downloaded Successfully, you can close this page now."
      extra={
        <Link type="primary" className="btn btn-dark" to="/dashboard">
          Back to Dashboard
        </Link>
      }
    />
  );
}

export default DownloadDocumentView;
