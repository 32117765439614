import React, { useState, useEffect } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { formatMegaBytes } from '../../constants/common';
import { Spin } from 'antd';
import _ from 'lodash';
import './styles.scss';

function MostUsageSimsComponent(props) {
  document.title = 'Average Usage By Plan | UltraIoT';
  const [planData, setPlanData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [usageDetails, setUsageDetails] = useState({});

  useEffect(() => {
    const usage = _.get(props, 'location.state', '');
    setUsageDetails(usage);
    setLoading(true);
    const getMostUsageSimsOfPlan = async () => {
      const simType = localStorage.getItem('simType');
      try {
        const body = { productid: _.get(usage, 'productid', ''), simType };
        const {
          value: { success, data },
        } = await props.fetchSimsWithMostUsageByPlan(body);
        if (success) {
          setPlanData(data);
          setLoading(false);
        } else {
          setPlanData([]);
          setLoading(false);
        }
      } catch (error) {
        setPlanData([]);
        setLoading(false);
      }
    };
    getMostUsageSimsOfPlan();
  }, [props]);
  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.goBack()}
            >
              <BackArrowIcon className="back-icon" /> Back to Dashboard
            </button>
          </div>
          <div className="col-md-8 text-right"></div>
        </div>
        <div className="layout-head">
          <h4 className="layout-head-title">
            {_.get(usageDetails, 'offername', '')}
          </h4>
          <p className="layout-head-subtitle">
            Current Month Usage:{' '}
            {usageDetails.current_month_avg_usage &&
              formatMegaBytes(usageDetails.current_month_avg_usage, 2)}
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {_.size(planData) > 0 ? (
                <>
                  <div className="card mb-3">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="">
                        <h5 className="card-title">Usage Details</h5>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table custom-table-secondary">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title cursor-poniter">
                                ICCID
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title cursor-poniter">
                                Usage
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planData.map((sim, inx) => {
                            return (
                              <tr key={inx}>
                                <td>{sim.iccid}</td>
                                <td>
                                  {sim.data_usage &&
                                    formatMegaBytes(sim.data_usage, 2)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  {!isLoading && (
                    <div className="blank__table__card__inner">
                      No sims found
                    </div>
                  )}
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MostUsageSimsComponent;
