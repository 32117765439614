import React, { useState, useEffect } from 'react';
import { CloseIcon, BackArrowIcon } from '../../components/icons';
import { get, uniqBy } from 'lodash';

import './styles.scss';

export default function ManagePermissionsComponent(props) {
  // eslint-disable-next-line
  const selectedUser =
    props.location && props.location.state ? props.location.state : {};
  const defaultPermissionSet = [
    {
      id: 1,
      route: 'cdr-data-files',
      name: 'Can see CDR Data Files',
      checked: false,
    },
    {
      id: 2,
      route: 'fixed-wireless',
      name: 'Can see Fixed wireless tab',
      checked: false,
    },
  ];
  const [permisions, setPermissions] = useState(defaultPermissionSet);

  useEffect(() => {
    const getUserPermission = async () => {
      try {
        const permissionDataSet = {
          id: selectedUser.id,
        };
        const {
          value: { success, data },
        } = await props.fetchUserPermissions(permissionDataSet);
        if (success) {
          const permissionData = JSON.parse(data[0].permissionData);
          setPermissions(permissionData);
        }
      } catch (error) {
        // console.log(error)
      }
    };
    getUserPermission();
  }, [props, selectedUser]);

  const setUserPermissions = (e, data) => {
    const newPermission = [];
    if (e.target.checked) {
      permisions.map((d) => {
        if (d.id === data.id) {
          d.checked = true;
          newPermission.push(d);
        } else {
          newPermission.push(d);
        }
        return true;
      });
      setPermissions(newPermission);
    } else {
      permisions.map((d) => {
        if (d.id === data.id) {
          d.checked = false;
          newPermission.push(d);
        } else {
          newPermission.push(d);
        }
        return true;
      });
      setPermissions(newPermission);
    }
  };

  const saveUserPermissions = async () => {
    try {
      const permissionDataSet = {
        id: selectedUser.id,
        permissionData: uniqBy(permisions, 'id'),
      };
      const {
        value: { success },
      } = await props.setUserPermissions(permissionDataSet);
      if (success) {
        props.history.push('/partners');
      }
    } catch (error) {
      // console.log(error)
    }
  };

  return (
    <>
      <div className="head-section-header d-flex align-items-center">
        <div className="head-section-title">Manage Permissions</div>
        <div className="head-section-cta">
          <button
            onClick={() => props.history.push('/partners')}
            className="btn btn-link btn-back"
            // onClick={e => this.handleCloseIcon()}
          >
            <CloseIcon />
          </button>
        </div>
      </div>

      <section className="section-manage-permissions">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="manage-permissions-container">
                <button
                  className="btn btn-link btn-back"
                  onClick={() => props.history.push('/partners')}
                >
                  <BackArrowIcon className="mr-3" /> Back
                </button>
                <div className="card mb-3">
                  <div className="card-header d-flex align-items-center">
                    <div>
                      <h5 className="card-title">
                        {get(selectedUser, 'firstname', '')}{' '}
                        {get(selectedUser, 'lastname', '')} Permissions
                      </h5>
                    </div>
                  </div>
                  <ul className="select-check-list">
                    {permisions &&
                      permisions.length > 0 &&
                      permisions.map((permission, index) => {
                        if (permission.id !== 2) {
                          return (
                            <li key={index}>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  className="custom-checkbox--input"
                                  type="checkbox"
                                  checked={permission.checked}
                                  onChange={(e) => {
                                    setUserPermissions(e, permission);
                                  }}
                                />
                                {permission.name}
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                  </ul>
                </div>
                <div>
                  <button
                    disabled={permisions.length === 0}
                    className="btn btn-dark btn-lg w-100"
                    onClick={() => saveUserPermissions()}
                  >
                    Update Permissions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
