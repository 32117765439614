import { fetch } from '../../utils';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const fetchInternationalRates = ({ searchText }) => {
  return fetch(
    `${HOSTNAME}/api/v1/sim/international-rates?searchText=${searchText}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
