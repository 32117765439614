import { fetch } from '../../utils';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const fetchSetting = (data) => {
  return fetch(`${HOSTNAME}/api/v1/setting`)
    .then((res) => res.json())
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      return { error };
    });
};

export const createSetting = (data) => {
  return fetch(`${HOSTNAME}/api/v1/setting/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
