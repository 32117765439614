/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';

export default class ViewCoverageMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = async () => {
    document.title = 'View Coverage Map | UltraIoT';
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  };

  render() {
    const iAuthorization = localStorage.getItem('i_Authorization');
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <Spin
            spinning={this.state.loading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <iframe
              className={
                iAuthorization ? 'coverage-map-less-height' : 'coverage-map'
              }
              src="https://www.mintmobile.com/stripped-coverage-map/?variation=noroam-5g"
              title="Ultra | IOT View Coverage Map"
            ></iframe>
          </Spin>
        </main>
      </div>
    );
  }
}
