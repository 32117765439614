import { connect } from 'react-redux';
import { fetchMe, setUser } from '../../store/user/duck';
import { fetchFwdeviceList } from '../../store/sim/duck';
import YourSiMsComponent from './component';

const YourSiMsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchFwdeviceList,
  }
)(YourSiMsComponent);

export default YourSiMsContainer;
