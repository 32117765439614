// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable';
import { assign } from 'lodash';
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
// import { get } from "lodash"

// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const FETCH_DASHBOARD_STATS = 'ultra/dashboard/FETCH_DASHBOARD_STATS';
export const FETCH_SIMS_BY_MAX_USASE =
  'ultra/dashboard/FETCH_SIMS_BY_MAX_USASE';
export const FETCH_RECENT_ORDERS = 'ultra/dashboard/FETCH_RECENT_ORDERS';
export const FETCH_RECENT_ACTIVATED = 'ultra/dashboard/FETCH_RECENT_ACTIVATED';
export const FETCH_RECENT_DEACTIVATED =
  'ultra/dashboard/FETCH_RECENT_DEACTIVATED';
export const GET_REPORT = 'ultra/dashboard/GET_REPORT';
export const SEARCH_RESULT = 'ultra/dashboard/SEARCH_RESULT';
export const FETCH_POOLED_STATS = 'ultra/dashboard/FETCH_POOLED_STATS';
export const FETCH_CLIENT_STATS = 'ultra/dashboard/FETCH_CLIENT_STATS';
export const FETCH_AVERAGE_USAGE_PLAN =
  'ultra/dashboard/FETCH_AVERAGE_USAGE_PLAN';
export const FETCH_MOST_USAGE_SIMS = 'ultra/dashboard/FETCH_MOST_USAGE_SIMS';
export const FETCH_PENDING_eSIM_ORDERS =
  'ultra/dashboard/FETCH_PENDING_eSIM_ORDERS';
export const APPROVE_REJECT_ESIM_ORDERS =
  'ultra/dashboard/APPROVE_REJECT_ESIM_ORDERS';
export const FETCH_RECENT_eSIM_ORDERS =
  'ultra/dashboard/FETCH_RECENT_eSIM_ORDERS';
export const FETCH_RECENT_PAUSED_SIMS =
  'ultra/dashboard/FETCH_RECENT_PAUSED_SIMS';
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const fetchDashboardStats = (type, data) => {
  return {
    type: FETCH_DASHBOARD_STATS,
    payload: api.fetchDashboardStats(type, data),
  };
};
export const fetchSimsByMaxUsage = (data) => {
  return {
    type: FETCH_SIMS_BY_MAX_USASE,
    payload: api.fetchSimsByMaxUsage(data),
  };
};

export const fetchSPRRecentOrders = (data) => {
  return {
    type: FETCH_RECENT_ORDERS,
    payload: api.fetchSPRRecentOrders(data),
  };
};

export const fetchSPRRecentActivated = (data) => {
  return {
    type: FETCH_RECENT_ACTIVATED,
    payload: api.fetchSPRRecentActivated(data),
  };
};

export const fetchRecentPausedSims = (data) => {
  return {
    type: FETCH_RECENT_PAUSED_SIMS,
    payload: api.fetchRecentPausedSims(data),
  };
};

export const fetchEsimPendingOrders = (data) => {
  return {
    type: FETCH_PENDING_eSIM_ORDERS,
    payload: api.fetchEsimPendingOrders(data),
  };
};

export const approveOrRejectPendingOrder = (data) => {
  return {
    type: APPROVE_REJECT_ESIM_ORDERS,
    payload: api.approveOrRejectPendingOrder(data),
  };
};

export const fetchRecentEsimOrders = (data) => {
  return {
    type: FETCH_RECENT_eSIM_ORDERS,
    payload: api.fetchRecentEsimOrders(data),
  };
};

export const getReport = (data) => {
  return {
    type: GET_REPORT,
    payload: api.getReport(data),
  };
};

export const fetchSPRRecentDeActivated = (data) => {
  return {
    type: FETCH_RECENT_DEACTIVATED,
    payload: api.fetchSPRRecentDeActivated(data),
  };
};

export const searchResults = (data) => {
  return {
    type: SEARCH_RESULT,
    payload: api.searchResults(data),
  };
};

export const fetchPooledStats = () => {
  return {
    type: FETCH_POOLED_STATS,
    payload: api.fetchPooledStats(),
  };
};

export const fetchAverageUsagePlan = (data) => {
  return {
    type: FETCH_AVERAGE_USAGE_PLAN,
    payload: api.fetchAverageUsagePlan(data),
  };
};

export const fetchSimsWithMostUsageByPlan = (data) => {
  return {
    type: FETCH_MOST_USAGE_SIMS,
    payload: api.fetchSimsWithMostUsageByPlan(data),
  };
};

/***********************************
 * Epics
 ***********************************/

export const dashboardEpic = combineEpics();
