import { connect } from 'react-redux';
import {
  fetchMe,
  setUser,
  getUsers,
  updateProfile,
  updateSupportUrl,
  updateUserStatus,
  userImpersonate,
  addCredits,
  fetchUserPlans,
  updatePlans,
  unblockUser,
} from '../../store/user/duck';
import { fetchSPRPlans } from '../../store/plan/duck';
import UsersComponent from './component';

const UsersContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    getUsers,
    updateProfile,
    updateSupportUrl,
    updateUserStatus,
    userImpersonate,
    addCredits,
    fetchSPRPlans,
    fetchUserPlans,
    updatePlans,
    unblockUser,
  }
)(UsersComponent);

export default UsersContainer;
