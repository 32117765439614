import { connect } from 'react-redux';
import AppComponent from './component';
import { fetchMe, checkOutage } from '../store/user/duck';
const AppContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    checkOutage,
  }
)(AppComponent);
export default AppContainer;
