import { fetch } from '../../utils';
const HOSTNAME = window.REACT_APP_API_HOSTNAME;

export const fetchDashboardStats = (type, data) => {
  let apiUrl = `${HOSTNAME}/api/v1/dashboard/stats`;
  if (data) {
    apiUrl = `${HOSTNAME}/api/v1/dashboard/stats?taglimit=${data.taglimit}&tagpage=${data.tagpage}&simtype=${data.simType}`;
  }
  if (type && type === 'spr') {
    apiUrl = `${HOSTNAME}/api/v1/dashboard/spr-dashboard`;
  }
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchSimsByMaxUsage = (data) => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/get-sims-by-max-usage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSPRRecentOrders = (data) => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/recent-orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSPRRecentActivated = (data) => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/recently-activated`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSPRRecentDeActivated = (data) => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/recently-deactivated`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchRecentPausedSims = (data) => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/recently-paused`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchRecentEsimOrders = async (data) => {
  try {
    const response = await fetch(
      `${HOSTNAME}/api/v1/dashboard/recent-eSIM-orders`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    const payload = await response.json();
    return payload;
  } catch (error) {
    throw error;
  }
};

export const fetchEsimPendingOrders = async (data) => {
  try {
    const response = await fetch(
      `${HOSTNAME}/api/v1/order/eSIM-pending-orders`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    const payload = await response.json();
    return payload;
  } catch (error) {
    throw error;
  }
};

export const approveOrRejectPendingOrder = async (data) => {
  try {
    const response = await fetch(`${HOSTNAME}/api/v1/order/status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const payload = await response.json();
    return payload;
  } catch (error) {
    throw error;
  }
};

export const getReport = (data) => {
  let fetURL = `${HOSTNAME}/api/v1/dashboard/getreports`;
  return fetch(fetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ type: data }),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchResults = (filter) => {
  return fetch(`${HOSTNAME}/api/v1/search?q=${JSON.stringify(filter)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchPooledStats = () => {
  return fetch(`${HOSTNAME}/api/v1/dashboard/pooled-stats`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAverageUsagePlan = (data) => {
  let fetURL = `${HOSTNAME}/api/v1/dashboard/getAverageUsagePlans`;
  return fetch(fetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchSimsWithMostUsageByPlan = (data) => {
  let fetURL = `${HOSTNAME}/api/v1/usage/fetchmostusagesims`;
  return fetch(fetURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
