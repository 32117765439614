/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Spin, Pagination } from 'antd';
import moment from 'moment';
import ReactModal from 'react-modal';
import NumberFormat from 'react-number-format';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import {
  ClientsColorIcon,
  EditLineIcon,
  DeleteLineIcon,
  CloseIcon,
} from '../../components/icons';
import { isSubUser, checkPermission } from '../../constants/common';
import { DebounceInput } from 'react-debounce-input';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';

export default class ClientsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userData: {},
      clients: [],
      totalClients: 0,
      searchKeyword: '',
      selectedClient: null,
      limit: 50,
      page: 1,
      errorMessage: '',
      requireClientName: false,
      newClientNameSize: false,

      addClientName: '',
      addNewClientModal: false,
      addNewClientConfirm: false,

      editClientName: '',
      editClientModal: false,
      editClientNameConfirm: false,

      deleteClientName: '',
      deleteClientModal: false,
      deleteConfirm: false,

      customFirstDate: moment('20200801', 'YYYYMMDD'),
      customEndDate: new Date(),
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    document.title = 'Your Clients | UltraIoT';
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
        if (
          user.role === 'corporate_client' ||
          user.role === 'user' ||
          (isSubUser(user) && !checkPermission(user, '/clients'))
        ) {
          this.props.history.push('/dashboard');
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: _.get(error, 'message', 'Something went wrong...'),
      });
    }
    this.getClients();
  };

  getClients = async () => {
    this.setState({ isLoading: true });
    const { user } = this.props;
    let date = {
      from: this.state.customFirstDate,
      to: this.state.customEndDate,
    };
    try {
      let obj = {
        fromDate: moment(date.from).format('YYYY-MM-DD'),
        toDate: moment(date.to).format('YYYY-MM-DD'),
        keyword: this.state.searchKeyword,
        page: this.state.page,
        limit: this.state.limit,
        simType: 'all',
      };
      if (parseInt(user.lobId) === 1) {
        obj.simType = 'spr';
      } else if (parseInt(user.lobId) === 3) {
        obj.simType = 'fixed';
      }
      const {
        value: { success, data, totalClients },
      } = await this.props.fetchClientListing(obj);
      if (success) {
        this.setState({
          clients: data,
          totalClients,
          isLoading: false,
          selectedClient: null,
        });
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getClients();
    });
  };

  handleSearch(e) {
    const searchClientsKeyword = e.target.value;
    this.setState({ searchKeyword: searchClientsKeyword, page: 1, totalClients: 0 }, () => {
      this.getClients();
    });
  }

  closeModal() {
    this.setState({
      selectedClient: null,
      requireClientName: false,
      newClientNameSize: false,
      addClientName: '',
      addNewClientModal: false,
      addNewClientConfirm: false,
      editClientName: '',
      editClientModal: false,
      editClientNameConfirm: false,
      deleteClientModal: false,
      deleteClientName: '',
      deleteConfirm: false,
      errorMessage: '',
    });
  }

  setAddClient(e) {
    this.setState({ addNewClientModal: true, addClientName: '' });
  }

  setEditClient(client) {
    this.setState({
      editClientModal: true,
      editClientName: client.clientName,
      selectedClient: client,
    });
  }

  setDeleteClient(client) {
    this.setState({
      deleteClientModal: true,
      selectedClient: client,
      deleteClientName: client.clientName,
    });
  }

  addNewClient = async () => {
    const { addClientName } = this.state;
    if (addClientName.trim().length === 0) {
      this.setState({ requireClientName: true });
    } else if (addClientName.trim().length < 3) {
      this.setState({ newClientNameSize: true });
    } else {
      try {
        this.setState({ addNewClientConfirm: true });
        let payload = {
          name: addClientName,
        };
        const {
          value: { success },
        } = await this.props.addNewClient(payload);
        if (success) {
          await this.getClients();
          this.closeModal();
        }
      } catch (err) {
        const error = _.get(err, 'data', err);
        this.setState({
          addNewClientConfirm: false,
          errorMessage: error.message,
        });
      }
    }
  };

  editClientName = async () => {
    const { selectedClient, editClientName } = this.state;
    if (editClientName.trim().length === 0) {
      this.setState({ requireClientName: true });
    } else if (editClientName.trim().length < 3) {
      this.setState({ newClientNameSize: true });
    } else {
      this.setState({ editClientNameConfirm: true });
      try {
        let payload = {
          clientId: selectedClient.id,
          clientName: editClientName,
        };
        const {
          value: { success },
        } = await this.props.updateClientName(payload);
        if (success) {
          await this.getClients();
          this.closeModal();
        }
      } catch (err) {
        this.setState({
          editClientNameConfirm: false,
          errorMessage: err.message,
        });
      }
    }
  };

  deleteClient = async () => {
    const { selectedClient } = this.state;
    this.setState({ deleteConfirm: true, errorMessage: '' });
    try {
      let payload = {
        clientId: selectedClient.id,
      };
      const {
        value: { success },
      } = await this.props.deleteClient(payload);
      if (success) {
        await this.getClients();
        this.closeModal();
      }
    } catch (err) {
      this.setState({ deleteConfirm: false, errorMessage: err.message });
    }
  };

  render() {
    const {
      clients,
      totalClients,
      isLoading,
      limit,
      page,
      addNewClientModal,
      addClientName,
      addNewClientConfirm,
      deleteClientModal,
      deleteClientName,
      deleteConfirm,
      editClientModal,
      editClientName,
      editClientNameConfirm,
      requireClientName,
      newClientNameSize,
      errorMessage,
    } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} onTabChange={this.onTabChange} />
        <TopNavigation {...this.props} onTabChange={this.onTabChange} />

        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2 className="heading-title">
                <ClientsColorIcon />
                Your Clients
              </h2>
            </div>
            <div className="col-lg-8 text-sm-right">
              <button
                className="btn btn-dark w-150"
                onClick={(e) => this.setAddClient(e)}
              >
                Add Client
              </button>
            </div>
          </div>

          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <div className="mt-3">
                <DebounceInput
                  minLength={2}
                  debounceTimeout={500}
                  className="form-control"
                  placeholder="Search by client name..."
                  name="searchClient"
                  onChange={this.handleSearch.bind(this)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-right">
              {totalClients ? (
                <div className="filter-page-number mt-3 mb-3">
                  {page > 1 ? (page - 1) * limit + 1 : 1}-
                  {limit * page < totalClients ? limit * page : totalClients} of{' '}
                  {totalClients}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="blank__table__card mt-3">
              <div className="blank__table__card__inner">
                No Clients
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: '#000' }}
                      spin
                    />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    {_.size(clients) > 0 ? (
                      <div className="table-responsive custom-table-shadow">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Client Name
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Total SIMS
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Total Data
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Total SMS
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Total Voice
                                </span>
                              </th>
                              <th colSpan="3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.size(clients) > 0 &&
                              clients.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {_.get(item, 'clientName', '') &&
                                      _.get(item, 'clientName', '') !== ''
                                        ? _.get(item, 'clientName', '-')
                                        : '-'}
                                    </td>
                                    <td>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={item.iccid}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        displayType={'text'}
                                        value={item.data}
                                      />{' '}
                                      mb
                                    </td>
                                    <td>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={item.sms}
                                      />{' '}
                                      sms
                                    </td>
                                    <td>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={item.voice}
                                      />{' '}
                                      min
                                    </td>
                                    <td className="text-right">
                                      <button
                                        className="btn btn-link"
                                        onClick={(e) =>
                                          this.setEditClient(item)
                                        }
                                      >
                                        <EditLineIcon />
                                      </button>
                                      <button
                                        className="btn btn-link"
                                        onClick={(e) =>
                                          this.setDeleteClient(item)
                                        }
                                      >
                                        <DeleteLineIcon />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="blank__table__card mt-3">
                        <div className="blank__table__card__inner">
                          No Clients
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <Pagination
            className="mb-5"
            hideOnSinglePage
            onChange={this.onPageChanged}
            current={page}
            pageSize={limit}
            defaultCurrent={page}
            total={totalClients}
            showSizeChanger={false}
          />
        </main>

        {/* Delete Client Modal */}
        <ReactModal
          isOpen={deleteClientModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-body">
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title">Delete Client</h5>
              <p className="react-modal-subtitle mt-2 mb-4">
                Please note that deleting this client will not delete the SIM.
                It will remove this client from all asscoiated SIMs.
                <br />
                <br /> Are you sure you like to proceed with deleting{' '}
                {deleteClientName}?
              </p>
              <div className="text-right">
                <button
                  className="btn btn-danger btn-lg w-150"
                  onClick={(e) => this.deleteClient()}
                >
                  {deleteConfirm ? 'Please wait...' : 'Yes, Delete'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Edit Client Name Modal*/}
        <ReactModal
          isOpen={editClientModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Edit Client Name"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Edit Client Name </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  name="editClientName"
                  value={editClientName}
                  onChange={(e) =>
                    this.setState({
                      editClientName: e.target.value,
                      requireClientName: false,
                      newClientNameSize: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Client Name</label>
                {requireClientName && (
                  <div className="invalid-feedback">
                    Client Name is required
                  </div>
                )}
                {newClientNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save
                  </div>
                )}
                {errorMessage && (
                  <div className="invalid-feedback">{errorMessage}</div>
                )}
              </div>
              <button
                disabled={editClientNameConfirm}
                type="button"
                onClick={(e) => this.editClientName()}
                className="btn btn-dark btn-lg w-100"
              >
                {editClientNameConfirm ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>

        {/* Add Client Name Modal*/}
        <ReactModal
          isOpen={addNewClientModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Edit Client Name"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Add Client</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  className="form-control form-control-lg material-textfield-input"
                  value={addClientName}
                  onChange={(e) =>
                    this.setState({
                      addClientName: e.target.value,
                      requireClientName: false,
                      newClientNameSize: false,
                    })
                  }
                  required
                />
                <label className="material-textfield-label">Client Name</label>
                {requireClientName && (
                  <div className="invalid-feedback">
                    Client Name is required
                  </div>
                )}
                {newClientNameSize && (
                  <div className="invalid-feedback">
                    Please enter at least three characters to save
                  </div>
                )}
                {errorMessage && (
                  <div className="invalid-feedback">{errorMessage}</div>
                )}
              </div>
              <button
                disabled={addNewClientConfirm}
                type="button"
                onClick={(e) => this.addNewClient()}
                className="btn btn-dark btn-lg w-100"
              >
                {addNewClientConfirm ? 'Please wait...' : 'Save'}
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
