/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import PasswordStrengthStatus from '../../components/PasswordStengthStatus';
import { Formik, ErrorMessage } from 'formik';
import _ from 'lodash';
import './styles.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

export default class ResetPasswordComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      errorMessage: '',
      isLoading: true,
      isTokenExpired: false,
      initialValues: {
        password: '',
        confirmpassword: '',
      },
      passwordStrength: {},
    };
  }

  componentDidMount = async () => {
    try {
      const resetToken = _.get(this.props, 'match.params.token', '');
      const obj = {
        token: resetToken,
      };
      const {
        value: { success, isTokenExpired },
      } = await this.props.checkResetPasswordToken(obj);
      if (success && isTokenExpired) {
        this.setState({ isTokenExpired, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (err) {
      const { message } = _.get(err, 'data', err);
      this.setState({ errorMessage: message, isLoading: false });
    }
  };

  validatePassword = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmpassword) {
      errors.confirmpassword = 'Passwords and Confirm password must match';
    }

    let obj = {};
    // Special Character
    if (new RegExp('[$@$!%*#?&]').test(values.password)) {
      obj.specialChar = true;
    }
    // Uppercase Alpabates
    if (new RegExp('[A-Z]').test(values.password)) {
      obj.upperChar = true;
    }
    // Numbers
    if (new RegExp('[0-9]').test(values.password)) {
      obj.numbers = true;
    }
    // Lowercase Alphabates
    if (new RegExp('[a-z]').test(values.password)) {
      obj.lowerChar = true;
    }
    // Length should be 8 char
    if (new RegExp('.{8,}').test(values.password)) {
      obj.charLength = true;
    }

    if (Object.keys(obj).length > 0 && Object.keys(obj).length < 5) {
      errors.password = 'Resolve all password pattern checks to proceed.';
    }

    this.setState({ passwordStrength: obj });
    return errors;
  };

  handleResetPassword = async (values, { setSubmitting }) => {
    try {
      let resetToken = _.get(this.props, 'match.params.token', '');
      this.setState({ errorMessage: '' });
      let obj = {
        token: resetToken,
        password: values.password.trim(),
      };
      const {
        value: { success, token },
      } = await this.props.resetPassword(obj);
      if (success) {
        setSubmitting(false);
        let route = _.get(window.location, 'pathname', '');
        let isResetPassword = route.includes('/reset/');
        if (isResetPassword) {
          this.props.history.push('/login');
        } else {
          localStorage.setItem('Authorization', token);
          this.props.history.push('/dashboard');
        }
      }
    } catch (err) {
      const { success, message } = _.get(err, 'data', err);
      if (!success) {
        setSubmitting(false);
        this.setState({ errorMessage: message });
      }
    }
  };

  render() {
    const { initialValues, errorMessage, passwordStrength, isTokenExpired } =
      this.state;
    let route = _.get(window.location, 'pathname', '');
    let isResetPassword = route.includes('/reset/');
    return this.state.isLoading ? (
      <div className="lodder-bg">
        <div className="d-flex align-items-center justify-content-center mt-3">
          <div className="blank__table__card__inner">
            <Spin
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="reset-password">
        <div className="reset-password-inner d-flex align-items-center">
          <div className="reset-password-body">
            <div className="card">
              <div className="card-body">
                <div className="card-inner-heading">
                  <h4 className="title">
                    {isResetPassword
                      ? 'Reset Your Password'
                      : "It's time to update your password!"}
                  </h4>
                  {!isResetPassword && !isTokenExpired && (
                    <p>
                      {
                        'Your password was either too old or not strong enough. Please set a new password for your Ultra IoT Portal Account. Kindly note that you cannot use a previously used password.'
                      }
                    </p>
                  )}
                </div>
                {!isTokenExpired ? (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validate={this.validatePassword}
                    onSubmit={this.handleResetPassword}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="form-group material-textfield">
                          <input
                            type="password"
                            className="form-control form-control-lg material-textfield-input"
                            name="password"
                            onChange={handleChange}
                            value={values && values.password}
                            required
                          />
                          <label className="material-textfield-label">
                            Enter a new password{' '}
                            <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="password"
                            className="invalid-feedback d-block"
                          />
                        </div>
                        <div className="form-group material-textfield">
                          <input
                            type="password"
                            className="form-control form-control-lg material-textfield-input"
                            name="confirmpassword"
                            onChange={handleChange}
                            value={values && values.confirmpassword}
                            required
                          />
                          <label className="material-textfield-label">
                            Confirm your new password{' '}
                            <span className="text-danger">*</span>
                          </label>
                          <ErrorMessage
                            component="span"
                            name="confirmpassword"
                            className="invalid-feedback d-block"
                          />
                        </div>
                        <PasswordStrengthStatus
                          passwordStrength={passwordStrength}
                        />
                        <span className="invalid-feedback d-block spacer-10 text-center">
                          {errorMessage}
                        </span>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="btn btn-dark btn-lg w-100"
                        >
                          {isSubmitting ? 'Please wait ...' : 'Reset'}
                        </button>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <p>
                    This password reset link is expired. Click{' '}
                    <Link to={'/forgot-password'}> here </Link> to generate a
                    new link to reset your password.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
