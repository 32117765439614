import { connect } from 'react-redux';

import MaintenanceComponent from './component';

const MaintenanceContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(MaintenanceComponent);

export default MaintenanceContainer;
