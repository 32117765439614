import { connect } from 'react-redux';

import { fetchMe, setUser, userImpersonate } from '../../../store/user/duck';
import {
  fetchRecentPausedSims,
  getReport,
} from '../../../store/dashboard/duck';

import RecentlyPausedComponent from './component';

const RecentlyPausedSimsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    setUser,
    fetchRecentPausedSims,
    getReport,
    userImpersonate,
  }
)(RecentlyPausedComponent);

export default RecentlyPausedSimsContainer;
