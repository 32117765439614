/* eslint no-undef: "off"*/
import React, { Component } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import './styles.scss';
import _ from 'lodash';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default class FwDeviceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isExportLoading: false,
      userData: null,
      deviceList: [],
      iAuthorization: localStorage.getItem('i_Authorization'),
    };
  }

  componentDidMount = async () => {
    document.title = 'Approved Devices | UltraIoT';
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user) {
      this.props.setUser(user);
      const {
        value: { deviceList },
      } = await this.props.fetchFwdeviceList();
      this.setState({ userData: user, deviceList, isLoading: false });
    }
  };

  render() {
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation user={this.state.userData} {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2 className="heading-title">FW Certified Device List</h2>
            </div>
            <div className="col-lg-8 text-sm-right"></div>
          </div>
          <div className="row mt-3"></div>

          <div className="row d-none d-lg-block"></div>

          <div className="row">
            <div className="col-md-12">
              {/* LISTS */}
              <div className="row">
                <div className="col-md-12">
                  {this.state.isLoading ? (
                    <div className="blank__table__card mt-3">
                      <div className="blank__table__card__inner">
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: '#000' }}
                              spin
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive custom-table-shadow mb-3">
                        <table className="table custom-table custom-table-fixed-col">
                          <thead>
                            <tr>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Wholesale Certified Device
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Brand
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  Model
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  TAC
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title cursor-poniter">
                                  External Antenna Port
                                </span>
                              </th>
                              {/* <th>
                              <span className="table-column-title cursor-poniter">
                                Supported Bands{' '}
                              </span>
                            </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {_.get(this.state, 'deviceList', []).map(
                              (device, i) => {
                                return (
                                  <tr key={i} className="cursor-poniter">
                                    <td>{_.get(device, 'device', '')}</td>
                                    <td>{_.get(device, 'brand', '')}</td>
                                    <td>{_.get(device, 'model', '')}</td>
                                    <td>{_.get(device, 'tac', '')}</td>
                                    <td>{_.get(device, 'antennaPort', '')}</td>
                                    {/* <td>
                                  { _.get(device, 'supportedBands', '') }
                                </td> */}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
