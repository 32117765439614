import { connect } from 'react-redux';
import MyProfileComponent from './component';

const MyProfileContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(MyProfileComponent);

export default MyProfileContainer;
