import { connect } from 'react-redux';

import SimListComponent from './component';

const SimListContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(SimListComponent);

export default SimListContainer;
