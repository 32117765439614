import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import ReactModal from 'react-modal';
import TopNavigation from '../../TopNavigation/container';
import SidebarNavigation from '../../SidebarNavigation/component';
import { BackArrowIcon, CloseIcon } from '../../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import './styles.scss';

function RecentlyPausedComponent(props) {
  document.title = 'Recently Activated SIMs | UltraIoT';
  const [recentPausedSims, setRecentPausedSims] = useState([]);
  const [simsReportData, setsimsReportData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [simtype, setSimType] = useState('spr');
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [confirmLoading, setconfirmLoading] = useState(false);
  const [totalCounts, setTotalCounts] = useState(20);
  const [page, setPage] = useState(1);

  const countPerPage = 30;

  useEffect(() => {
    const getSimReport = async () => {
      setLoading(true);
      try {
        const {
          value: { success, data },
        } = await props.getReport('paused');
        if (success) {
          setsimsReportData(data);
          setLoading(false);
        }
      } catch (err) {
        setsimsReportData([]);
        setLoading(false);
      }
    };
    getSimReport();
  }, [props]);

  useEffect(() => {
    const getRecentPausedSims = async (pge) => {
      try {
        const body = { limit: countPerPage, page: pge };
        const {
          value: { success, data },
        } = await props.fetchRecentPausedSims(body);
        if (success) {
          const { totalPausedSims, simArray } = data;
          setRecentPausedSims(simArray);
          setTotalCounts(totalPausedSims);
        }
      } catch (error) {
        setRecentPausedSims([]);
      }
    };

    getRecentPausedSims(page);
  }, [page, props]);

  const setImpersonate = (userId, simtype) => {
    if (userId) {
      setUserId(userId);
      setSimType(simtype);
      setConfirmModal(true);
      setconfirmLoading(false);
    }
  };

  const getImpersonateUser = async () => {
    if (userId) {
      try {
        const {
          value: { success, token },
        } = await props.userImpersonate(userId);
        if (success) {
          const auth = localStorage.getItem('Authorization');
          localStorage.setItem('i_Authorization', auth);
          localStorage.setItem('Authorization', token);
          if (simtype && simtype === 'fixed') {
            props.history.push('/fixed-paused-sims');
          } else {
            props.history.push('/paused-sims');
          }

          setConfirmModal(false);
          setconfirmLoading(false);
          let lastPath = _.get(this.props, 'location.pathname', '/all-users');
          localStorage.setItem('@lastActiveTab', lastPath);
        }
      } catch (err) {
        setConfirmModal(false);
        setconfirmLoading(false);
      }
    }
  };

  const onConfirmed = () => {
    setConfirmModal(false);
    setconfirmLoading(false);
    getImpersonateUser();
  };

  //Export Sim Data
  const exportData = async () => {
    setLoading(true);
    if (_.size(simsReportData) > 0) {
      let date = moment().format('YYYY-MM-DD');
      let fileName = 'recently-paused-sims-report-' + date;
      const _data = simsReportData.map((sim) => ({
        Sim_Name: _.get(sim, 'simName', '-'),
        Plan: sim.simPlan ? `"${sim.simPlan}"` : '-',
        ICCID: sim.simnumber ? `"${sim.simnumber}F"` : '-',
        Paused_Date: sim.deactivationDate
          ? moment(sim.deactivationDate).format('YYYY-MM-DD')
          : '-',
        Company: sim.companyName ? sim.companyName : '-',
      }));
      let csvRows = [];
      const headers = Object.keys(_data[0]);
      csvRows.push(headers.join(','));
      for (const row of _data) {
        const values = headers.map((header) => {
          return row[header];
        });
        csvRows.push(values.join(','));
      }
      csvRows = csvRows.join('\n');
      const blob = new Blob([csvRows], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(blob);
      let a = '';
      a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', csvURL);
      a.setAttribute('download', fileName + '.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
    }
  };

  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.push('/spr-dashboard')}
            >
              <BackArrowIcon className="back-icon" /> Back to SPR Dashboard
            </button>
          </div>
          <div className="col-md-8 text-right"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {recentPausedSims && recentPausedSims.length ? (
                <>
                  <div className="card mb-3">
                    <div className="card-header d-flex align-items-center">
                      <div className="">
                        <h5 className="card-title">Recently Paused SIMs</h5>
                      </div>
                      <div className="ml-auto">
                        <button
                          className="btn btn-purple w-200"
                          disabled={_.size(simsReportData) === 0}
                          onClick={exportData}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table custom-table-secondary table-nowidth">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">
                                SIM Name
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">Plan</span>
                            </th>
                            <th>
                              <span className="table-column-title">ICCID</span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Paused Date
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Company
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {recentPausedSims.map((sim, inx) => {
                            return (
                              <tr key={inx}>
                                <td>{_.get(sim, 'simName', '-')}</td>
                                <td>{sim.simPlan ? sim.simPlan : '-'}</td>
                                <td>{sim.simnumber}</td>
                                <td>
                                  {sim.deactivationDate
                                    ? moment(sim.deactivationDate)
                                        .tz(moment.tz.guess())
                                        .format('MMM DD, YYYY h:mm a z')
                                    : '-'}
                                </td>
                                <td
                                  onClick={() => {
                                    setImpersonate(sim.userId, sim.simtype);
                                  }}
                                >
                                  <span className="text-underline btn-link-orange">
                                    {sim.companyName}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={countPerPage}
                      defaultCurrent={page}
                      total={totalCounts}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  <div className="blank__table__card__inner">
                    No record found
                  </div>
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
      <ReactModal
        isOpen={isConfirmModal}
        onRequestClose={() => setConfirmModal(false)}
        ariaHideApp={false}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">
              Impersonate
            </h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => setConfirmModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-subtitle">
              Are you sure you want to impersonate this user?
            </p>
            <div className="text-right">
              <button
                onClick={onConfirmed}
                className="btn btn-dark btn-lg w-150"
              >
                {confirmLoading ? 'Please Wait...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default RecentlyPausedComponent;
