import { useQuery } from '@tanstack/react-query';
import iotApi from '../../api/iotApi.client';
import { SimQueryKey } from './sim.hooks.keys';

export function useSimCardDetail(params: { iccid: string }) {
  const { iccid } = params;

  return useQuery({
    queryKey: [SimQueryKey.SIM_CARD_DETAIL, iccid],
    queryFn: async () => {
      const res = await iotApi.sim.getSimCardDetail({ iccid });

      return res.data;
    },
    enabled: !!iccid,
  });
}

export function useActivePortInRequestData(params: { iccid: string }) {
  const { iccid } = params;

  return useQuery({
    queryKey: [SimQueryKey.ACTIVE_PORTIN_REQUEST_DATA, iccid],
    queryFn: async () => {
      const res = await iotApi.sim.getActivePortInRequestData({ iccid });

      return res.data;
    },
    enabled: !!iccid,
  });
}
