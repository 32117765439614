// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable';
import { assign } from 'lodash';
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'
// import { get } from "lodash"

// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// ULTRA PHASE START

export const FETCH_USER_PERMISSIONS = 'ultra/admin/FETCH_USER_PERMISSIONS';
export const SET_USER_PERMISSIONS = 'ultra/admin/SET_USER_PERMISSIONS';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  notifications: [],
  user: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ''; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const fetchUserPermissions = (data) => ({
  type: FETCH_USER_PERMISSIONS,
  payload: api.fetchUserPermissions(data),
});

export const setUserPermissions = (data) => ({
  type: SET_USER_PERMISSIONS,
  payload: api.setUserPermissions(data),
});
/***********************************
 * Epics
 ***********************************/

export const adminEpic = combineEpics();
