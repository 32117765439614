import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { formatMegaBytes } from '../../constants/common';
//import NumberFormat from "react-number-format"
import './styles.scss';
import _ from 'lodash';

function AverageUsageByPlan(props) {
  document.title = 'Average Usage By Plan | UltraIoT';
  const [usageDetails, setusageDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const countPerPage = 30;

  useEffect(() => {
    setLoading(true);
    const getAverageUsagePlan = async (pge) => {
      const simType = localStorage.getItem('simType');
      try {
        const body = { type: 'avg', simType, limit: countPerPage, page: pge };
        const {
          value: { success, data },
        } = await props.fetchAverageUsagePlan(body);
        if (success) {
          setusageDetails(data);
          setLoading(false);
        }
      } catch (error) {
        setusageDetails([]);
      }
    };

    getAverageUsagePlan(page);
  }, [page, props]);
  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center mb-4">
          <div className="col-md-4">
            <button
              className="btn btn-back"
              onClick={(e) => props.history.push('/dashboard')}
            >
              <BackArrowIcon className="back-icon" /> Back to Dashboard
            </button>
          </div>
          <div className="col-md-8 text-right"></div>
          <p className="ml-3 large-para-one">
            Note: Usage Data based on individual subscriber’s 30 day cycle NOT
            on the calendar month.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Spin
              spinning={isLoading}
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
              }
            >
              {usageDetails && usageDetails.length > 0 ? (
                <>
                  <div className="card mb-3">
                    <div className="table-responsive">
                      <table className="table custom-table-secondary table-nowidth average-table">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">
                                Average Usage Plan
                              </span>
                            </th>
                            <th>
                              <span className="table-column-title">
                                Rate Plan ID
                              </span>
                            </th>
                            <th>
                              <div className="average-usage-status-text text-right">
                                <span className="average-usage-status-current">
                                  Current 30-day Period
                                </span>{' '}
                                |{' '}
                                <span className="verage-usage-status-previous">
                                  Previous 30-day Period
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {usageDetails.map((usage, inx) => {
                            return (
                              <tr key={inx}>
                                <td>
                                  <span
                                    onClick={(e) =>
                                      props.history.push({
                                        pathname: '/dashboard/most-usage-sims',
                                        state: usage,
                                      })
                                    }
                                    className="average-title btn-link-orange cursor-poniter"
                                  >
                                    {usage.offername}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {_.get(usage, 'offerid', '') ||
                                      _.get(usage, 'productid', '')}
                                  </span>
                                </td>
                                <td>
                                  <div className="average-data-top">
                                    <span className="data-current">
                                      {usage.current_month_avg_usage &&
                                        formatMegaBytes(
                                          usage.current_month_avg_usage,
                                          2
                                        )}
                                    </span>{' '}
                                    |{' '}
                                    <span className="average-data-top">
                                      {usage.previous_month_avg_usage &&
                                        formatMegaBytes(
                                          usage.previous_month_avg_usage,
                                          2
                                        )}
                                    </span>
                                  </div>
                                  <div className="average-data-bottom">
                                    Total Data
                                  </div>
                                </td>
                                {/* <td>
                                    <div className="average-data-top"> <span className="data-current">N/A</span> | N/A </div>
                                    <div className="average-data-bottom">Total SMS</div>
                                  </td>
                                  <td>
                                    <div className="average-data-top"> <span className="data-current">N/A</span> | N/A </div>
                                    <div className="average-data-bottom">Total Voice</div>
                                  </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-left">
                    <Pagination
                      className="mb-5"
                      hideOnSinglePage
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={countPerPage}
                      defaultCurrent={page}
                      total={0}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ) : (
                <div className="blank__table__card">
                  {!isLoading && (
                    <div className="blank__table__card__inner">
                      No record found
                    </div>
                  )}
                </div>
              )}
            </Spin>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AverageUsageByPlan;
