/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { loginSchema } from '../../utils/validations';
import {
  isAdmin,
  isSysOpsUser,
  isSPRPartner,
  isSubUser,
} from '../../constants/common';
import { Formik } from 'formik';
import Logo from '../../images/logo.png';
import './styles.scss';

export default class LoginComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      user: {},
      errorMessage: '',
      initialValues: {
        email: '',
        password: '',
      },
    };
  }

  componentDidMount = () => {
    document.title = 'Login | UltraIoT';
    const isAuthed = localStorage.getItem('Authorization') ? true : false;
    if (isAuthed) {
      this.props.history.push('/');
    }
  };

  handleLogin = async (values, { setSubmitting }) => {
    try {
      this.setState({ errorMessage: '' });
      let obj = {
        email: values.email.trim(),
        password: values.password.trim(),
        skip2Fa: true,
      };
      const {
        value: { success, token, user, skip2Fa },
      } = await this.props.loginUser(obj);
      if (success) {
        if (skip2Fa && skip2Fa !== null) {
          localStorage.setItem('Authorization', token);
          setSubmitting(false);
          this.setState({ user });
          if (user && (isAdmin(user) || isSysOpsUser(user))) {
            this.props.history.push('/spr-dashboard');
          } else {
            if (
              user &&
              (isSPRPartner(user) || isSubUser(user)) &&
              _.get(user, 'lobId', 1) === 3
            ) {
              this.props.history.push('/fixed-dashboard');
            } else {
              this.props.history.push('/dashboard');
            }
          }
        } else {
          localStorage.setItem('t_Authorization', token);
          setSubmitting(false);
          this.setState({ user });
          this.props.history.push('/two-factor-auth');
        }
      }
    } catch (err) {
      const { success, message, reason, resetToken } = _.get(err, 'data', err);
      if (!success) {
        setSubmitting(false);
        this.setState({ errorMessage: message });
        if (reason && resetToken) {
          this.props.history.push(`/password-update/${resetToken}`);
        }
      }
    }
  };

  render() {
    const { initialValues, errorMessage } = this.state;
    return (
      <div className="login">
        <div className="login-inner d-flex align-items-center">
          <div className="login-body">
            <div className="card">
              <div className="card-body">
                <div className="card-inner-heading">
                  <div className="logo">
                    <img src={Logo} alt="Ultra" />
                  </div>
                  <p className="subtitle">
                    Welcome back. Please enter your email and password to login{' '}
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={this.handleLogin}
                  validationSchema={loginSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          className="form-control form-control-lg material-textfield-input"
                          name="email"
                          onChange={(e) => {
                            handleChange(e);
                            this.setState({
                              errorMessage: '',
                              email: e.target.value,
                            });
                          }}
                          onBlur={handleBlur}
                          value={values.email || ''}
                          required
                        />
                        <label className="material-textfield-label">
                          Email Address
                        </label>
                        <div className="invalid-feedback">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </div>
                      <div className="form-group material-textfield">
                        <input
                          type="password"
                          className="form-control form-control-lg material-textfield-input"
                          name="password"
                          onChange={(e) => {
                            handleChange(e);
                            this.setState({
                              errorMessage: '',
                              password: e.target.value,
                            });
                          }}
                          onBlur={handleBlur}
                          value={values.password || ''}
                          required
                          autocomplete="off"
                        />
                        <label className="material-textfield-label">
                          Password
                        </label>
                        <div className="invalid-feedback">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </div>
                        <Link
                          className="forgot-password-link"
                          to={'/forgot-password'}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="invalid-feedback text-center mb-3 spacer-10">
                        {errorMessage}
                      </div>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-dark btn-lg w-100"
                      >
                        {isSubmitting ? 'Please wait ...' : 'Continue'}
                      </button>
                      {/*<div className="create__link">Don't have have an account? <Link to={'/signup'}><span>Sign Up</span></Link></div>*/}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
