/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import { BackArrowIcon } from '../../components/icons';
import { orderESchema } from '../../utils/validations';
import InputMask from 'react-text-mask';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { isSubUser, checkPermission } from '../../constants/common';
import { Formik, ErrorMessage } from 'formik';
import { message } from 'antd';
import _ from 'lodash';
import './styles.scss';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const simquantity = [25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];

export default class OrderESimsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      initialValues: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        quantity: '25',
      },
      errorMessage: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Order eSIMs | UltraIoT';
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
        this.setState((prevState) => {
          let initialValues = Object.assign({}, prevState.initialValues);
          initialValues.firstname = user.firstname;
          initialValues.lastname = user.lastname;
          initialValues.email = user.email;
          initialValues.phone = user.phone;
          return { initialValues };
        });
        if (
          user.role === 'corporate_client' ||
          user.role === 'user' ||
          (isSubUser(user) && !checkPermission(user, '/order-sims'))
        ) {
          this.props.history.push('/dashboard');
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }
  };

  success = (msg) => {
    message.success({ key: 'updatable', content: msg });
  };

  error = (msg) => {
    message.error({ key: 'updatable', content: msg });
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  handleSubmit = async (
    { address, addressline2, ...rest },
    { setSubmitting, setErrors }
  ) => {
    try {
      const { userData } = this.state;
      setSubmitting(true);
      this.setState({ errorMessage: '' });

      let payload = {
        email: rest.email,
        firstname: rest.firstname,
        lastname: rest.lastname,
        phone: rest.phone,
        quantity: parseInt(rest.quantity),
        state: rest.state,
        ordertype: _.get(this.props, 'location.state', 'spr'),
        ma_id: userData.ma_id,
      };
      const {
        value: { success },
      } = await this.props.orderESims(payload);
      if (success) {
        this.success('Your order placed successfully!');
        this.props.history.push(`/eSIM-orders`);
      }
    } catch (err) {
      const { message } = _.get(err, 'data', err);
      setSubmitting(false);
      this.setState({ errorMessage: message });
    }
  };

  render() {
    const { initialValues, errorMessage } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-12">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
              <div className="side-heading mt-2">
                <h4 className="title">Order eSIMs</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-5">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={orderESchema}
                  onSubmit={(values, formikProps) =>
                    this.handleSubmit(values, formikProps)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setValues,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="firstname"
                                value={(values && values.firstname) || ''}
                                onChange={handleChange}
                                required
                              />
                              <label className="material-textfield-label">
                                First Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <ErrorMessage
                                component="span"
                                name="firstname"
                                className="invalid-feedback d-block"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="lastname"
                                value={(values && values.lastname) || ''}
                                onChange={handleChange}
                                required
                              />
                              <label className="material-textfield-label">
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <ErrorMessage
                                component="span"
                                name="lastname"
                                className="invalid-feedback d-block"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <InputMask
                                guide={false}
                                type="text"
                                id="phone"
                                keepCharPositions={false}
                                mask={phoneNumberMask}
                                className="form-control form-control-lg material-textfield-input"
                                name="phone"
                                onChange={handleChange}
                                value={(values && values.phone) || ''}
                                required
                              />
                              <label className="material-textfield-label">
                                Phone Number{' '}
                                <span className="text-danger"></span>
                              </label>
                              {/* <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" /> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <input
                                type="text"
                                className="form-control form-control-lg material-textfield-input"
                                name="email"
                                value={(values && values.email) || ''}
                                onChange={handleChange}
                                required
                              />
                              <label className="material-textfield-label">
                                Email Address{' '}
                                <span className="text-danger">*</span>{' '}
                              </label>
                              <ErrorMessage
                                component="span"
                                name="email"
                                className="invalid-feedback d-block"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group material-textfield">
                          <select
                            className="form-control form-control-lg custom-select material-textfield-input"
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          >
                            {simquantity.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                          <label className="material-textfield-label">
                            How many SIMS?
                          </label>
                          {/* <div className="invalid-feedback">{errors.quantity && touched.quantity && errors.quantity}</div> */}
                          <p className="text-extra-note">
                            If you need more than 500 eSIMs in an order, please
                            contact your Account Manager
                          </p>
                        </div>
                        <div className="invalid-feedback d-block text-center mb-3">
                          {errorMessage}
                        </div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-dark btn-lg w-100"
                        >
                          {isSubmitting ? 'Please wait...' : 'Place Order'}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
