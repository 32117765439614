/* eslint no-undef: "off"*/
import React from 'react';
import { Select } from 'antd';
import ReactModal from 'react-modal';
import { CloseIcon } from './icons';
const { Option } = Select;
export default function ClientModal({
  selectedSimPlan,
  selectedClient,
  clients,
  isOpen,
  onRequestClose,
  onSelectionChange,
  onSelection,
  saveClient,
  updateLoading,
  newClientSize,
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Create tags"
      portalClassName="react-modal"
      overlayClassName="modal"
      className="modal-dialog react-modal-dialog-sm modal-dialog-centered modal-create-tag"
    >
      <div className="modal-content">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Add Client </h5>
          <button
            type="button"
            className="btn react-modal-close"
            onClick={onRequestClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="react-modal-body">
          <div className="form-group material-textfield">
            <Select
              autoFocus
              mode="tags"
              size="large"
              bordered
              className="multiple__tags__textfield"
              dropdownClassName="multiple__tags__textfield__overlay"
              style={{ width: '100%' }}
              placeholder="Client Name"
              value={selectedClient}
              onChange={onSelectionChange}
              onSelect={onSelection}
            >
              {clients.length &&
                clients.map((client, index) => {
                  return (
                    <Option key={index} value={client.fullname}>
                      {client.fullname}
                    </Option>
                  );
                })}
            </Select>
            {newClientSize && (
              <div className="invalid-feedback">
                Please enter at least three characters to save.
              </div>
            )}
          </div>
          <button
            disabled={updateLoading || selectedClient.length >= 2}
            type="button"
            onClick={saveClient}
            className="btn btn-dark btn-lg w-100"
          >
            {updateLoading ? 'Please wait...' : 'Save'}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
