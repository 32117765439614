import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Spin, message } from 'antd';
import { Chart } from 'react-google-charts';
import ReactModal from 'react-modal';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash';
import {
  DashboardColorIcon,
  UserDarkIcon,
  UserLightIcon,
  CloseIcon,
} from '../../components/icons';

import './styles.scss';
import { OrderStatus } from '../../constants/Orders';

const pieOptions = {
  title: '',
  slices: {
    0: { color: '#0EDD4D' },
    1: { color: '#F4E137' },
    2: { color: '#3856C1' },
    3: { color: '#FFBF00' },
    4: { color: '#F90B0B' },
  },
  legend: {
    position: 'right',
    alignment: 'center',
    textStyle: {
      color: '233238',
      fontSize: 14,
    },
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 0,
    top: '10%',
    width: '100%',
    height: '80%',
  },
  fontName: 'Pangram',
};

export default class SprDashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sprUsersCount: {},
      topSprUsers: [],
      sprInventoryStats: {},
      recentlyDeactivatedSims: [],
      recentlyActivatedSims: [],
      recentOrders: [],
      recentESIMOrders: [],
      userInfo: {},
      userId: '',
      url: '',
      isLoading: true,
      confirmLoading: false,
      isConfirmModal: false,
      recentlyPausedSims: [],
    };
  }

  componentDidMount = async () => {
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        if (user.role !== 'superadmin') {
          this.props.history.push('/dashboard');
        } else {
          document.title = 'SPR Dashboard | UltraIoT';
          this.getDashboardData();
        }
      }
    } catch (error) {
      this.props.history.push('/dashboard');
    }
  };

  getDashboardData = async () => {
    try {
      const {
        value: { success, data },
      } = await this.props.fetchDashboardStats('spr');
      if (success) {
        const {
          sprUsersCount,
          topSprUsers,
          sprInventoryStats,
          recentlyDeactivatedSims,
          recentlyActivatedSims,
          recentlyPausedSims,
          recentOrders,
          recentESIMOrders,
          userInfo,
        } = data;
        this.setState(
          {
            sprUsersCount,
            topSprUsers,
            sprInventoryStats,
            recentlyDeactivatedSims,
            recentlyActivatedSims,
            recentlyPausedSims,
            recentOrders,
            recentESIMOrders,
            userInfo,
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      }
    } catch (error) {
      message.error({
        key: 'getDashboardData',
        content: _.get(
          error,
          'data.message',
          'Error in processing the request!'
        ),
      });
    }
  };

  setImpersonate = (userId, url) => {
    if (userId) {
      this.setState({ userId, url }, () => {
        this.setState({ isConfirmModal: true, confirmLoading: false });
      });
    }
  };

  getImpersonateUser = async (url) => {
    const userId = this.state.userId;
    if (userId !== '') {
      try {
        const {
          value: { success, token },
        } = await this.props.userImpersonate(userId);
        if (success) {
          const auth = localStorage.getItem('Authorization');
          localStorage.setItem('i_Authorization', auth);
          localStorage.setItem('Authorization', token);
          this.props.history.push(`/${url}`);
          this.setState({ isConfirmModal: false, confirmLoading: false });
          let lastPath = _.get(this.props, 'location.pathname', '/all-users');
          localStorage.setItem('@lastActiveTab', lastPath);
        }
      } catch (err) {
        this.setState({ isConfirmModal: false, confirmLoading: false });
      }
    }
  };

  onConfirmed = () => {
    const { url } = this.state;
    this.setState({ confirmLoading: true }, () => {
      this.getImpersonateUser(url);
    });
  };

  render() {
    const {
      sprUsersCount,
      topSprUsers,
      sprInventoryStats,
      recentlyDeactivatedSims,
      recentlyActivatedSims,
      recentlyPausedSims,
      recentOrders,
      recentESIMOrders,
      userInfo,
      isLoading,
    } = this.state;
    const topSprData = [['Partner Company Name', 'No of Active SIMs']];
    topSprUsers &&
      topSprUsers.map((sprUser) => {
        const tempArray = [sprUser.companyName, sprUser.activeSimsCount];
        topSprData.push(tempArray);
        return true; // for eslint rules
      });

    const recentlyActivatedSimsList = () => {
      return (
        recentlyActivatedSims &&
        recentlyActivatedSims.length > 0 &&
        recentlyActivatedSims.map((rActSim, i) => {
          return (
            <tr key={i}>
              <td>
                {moment(rActSim.activationDate)
                  .tz(moment.tz.guess())
                  .format('MMM DD, YYYY h:mm a z')}
              </td>
              <td>
                <span>{rActSim.simnumber}</span>
              </td>
              <td>
                <span
                  onClick={this.setImpersonate.bind(
                    this,
                    rActSim.userId,
                    rActSim.simtype === 'spr'
                      ? 'active-sims'
                      : 'fixed-active-sims'
                  )}
                  className="text-underline btn-link-orange"
                >
                  {rActSim.companyName}
                </span>
              </td>
            </tr>
          );
        })
      );
    };

    const recentlyDeactivatedSimsList = () => {
      return (
        recentlyDeactivatedSims &&
        recentlyDeactivatedSims.length > 0 &&
        recentlyDeactivatedSims.map((rDeSim, i) => {
          return (
            <tr key={i}>
              <td>
                {moment(rDeSim.deactivationDate)
                  .tz(moment.tz.guess())
                  .format('MMM DD, YYYY h:mm a z')}
              </td>
              <td>
                <span>{rDeSim.simnumber}</span>
              </td>
              <td>
                <span
                  onClick={this.setImpersonate.bind(
                    this,
                    rDeSim.userId,
                    rDeSim.simtype === 'spr'
                      ? 'deactivated-sims'
                      : 'fixed-deactivated-sims'
                  )}
                  className="text-underline btn-link-orange"
                >
                  {rDeSim.companyName}
                </span>
              </td>
            </tr>
          );
        })
      );
    };

    const recentOrdersList = () => {
      return (
        recentOrders &&
        recentOrders.length > 0 &&
        recentOrders.map((orders, i) => {
          return (
            <tr key={i}>
              <td>
                {moment(orders.orderDate)
                  .tz(moment.tz.guess())
                  .format('MMM DD, YYYY h:mm a z')}
              </td>
              <td>
                <span>{orders.companyName}</span>
              </td>
              <td>
                <span>{orders.orderNumber}</span>
              </td>
              <td>
                <span
                  onClick={this.setImpersonate.bind(
                    this,
                    orders.userId,
                    orders.ordertype === 'spr' ? 'spr-orders' : 'fixed-orders'
                  )}
                  className="text-underline btn-link-orange"
                >
                  {orders.orderedBy}
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  displayType={'text'}
                  value={orders.orderedSims}
                />
              </td>
            </tr>
          );
        })
      );
    };

    const recentESIMOrdersList = () => {
      return (
        recentESIMOrders &&
        recentESIMOrders.length &&
        recentESIMOrders.map((orders, i) => {
          return (
            <tr key={i}>
              <td>
                {moment(orders.orderDate)
                  .tz(moment.tz.guess())
                  .format('MMM DD, YYYY h:mm a z')}
              </td>
              <td>
                <span>
                  {' '}
                  {
                    OrderStatus[orders?.status] ?? '-'
                  }
                </span>
              </td>
              <td>
                <span>{orders.companyName}</span>
              </td>
              <td>
                <span>{orders.orderNumber}</span>
              </td>
              <td>
                <span
                  onClick={this.setImpersonate.bind(
                    this,
                    orders.userId,
                    (orders.ordertype = 'eSIM-orders')
                  )}
                  className="text-underline btn-link-orange"
                >
                  {orders.orderedBy}
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  displayType={'text'}
                  value={orders.orderedSims}
                />
              </td>
            </tr>
          );
        })
      );
    };

    const recentlyPausedSimsList = () => {
      return (
        recentlyPausedSims &&
        recentlyPausedSims.length &&
        recentlyPausedSims.map((sims, i) => {
          return (
            <tr key={i}>
              <td>
                {moment(sims.deactivationDate)
                  .tz(moment.tz.guess())
                  .format('MMM DD, YYYY h:mm a z')}
              </td>
              <td>
                <span>{sims.simnumber}</span>
              </td>
              <td>
                <span
                  onClick={this.setImpersonate.bind(
                    this,
                    sims.userId,
                    sims.simtype === 'spr' ? 'paused-sims' : 'fixed-paused-sims'
                  )}
                  className="text-underline btn-link-orange"
                >
                  {sims.companyName}
                </span>
              </td>
            </tr>
          );
        })
      );
    };
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation updateDashboard={this.updateDashboard} {...this.props} />
        {isLoading ? (
          <Spin
            className="loader__full"
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          />
        ) : (
          <main className="dashboard-layout-content">
            <div className="row align-items-md-center mb-4">
              <div className="col-md-7">
                <h2 className="heading-title">
                  <DashboardColorIcon />
                  Welcome {userInfo && userInfo.firstname}!
                </h2>
                <p className="heading-subtitle">SIM Overview</p>
              </div>
              <div className="col-md-5">
                <div className="toggle-dashboard ml-auto">
                  <button
                    className="btn btn-active"
                    onClick={() => this.props.history.push('/spr-dashboard')}
                  >
                    SPR Dashboard
                  </button>
                  <button
                    className="btn"
                    onClick={() => this.props.history.push('/dashboard')}
                  >
                    Classic Dashboard
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div
                  className="spr-card-tile light"
                  onClick={() => this.props.history.push('/partners')}
                >
                  <div className="spr-card-tile-icon">
                    <UserLightIcon />
                  </div>
                  <div className="spr-card-tile-info">
                    <div className="spr-card-tile-title">Total SPR Users</div>
                    <div className="spr-card-tile-count">
                      <NumberFormat
                        thousandSeparator={true}
                        displayType={'text'}
                        value={sprUsersCount && sprUsersCount.totalSPRUsers}
                      />
                    </div>
                    <div className="spr-card-tile-subtitle">
                      Total number SPR Ultra IoT Users
                    </div>
                  </div>
                </div>
                <div
                  className="spr-card-tile dark mb-5"
                  onClick={() => this.props.history.push('/partners')}
                >
                  <div className="spr-card-tile-icon">
                    <UserDarkIcon />
                  </div>
                  <div className="spr-card-tile-info">
                    <div className="spr-card-tile-title">
                      Total SPR Sub-Users
                    </div>
                    <div className="spr-card-tile-count">
                      <NumberFormat
                        thousandSeparator={true}
                        displayType={'text'}
                        value={sprUsersCount && sprUsersCount.totalSubUsers}
                      />
                    </div>
                    <div className="spr-card-tile-subtitle">
                      Total number SPR Sub-Users
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card mb-5">
                  <div className="card-header d-flex align-items-center">
                    <h5 className="card-title">SPR Inventory Stats</h5>
                    <h5 className="card-title ml-auto">
                      Total SIMs -{' '}
                      <NumberFormat
                        thousandSeparator={true}
                        displayType={'text'}
                        value={
                          sprInventoryStats && sprInventoryStats.totalSprSims
                        }
                      />
                    </h5>
                  </div>
                  <Spin
                    spinning={isLoading}
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: '#000' }}
                        spin
                      />
                    }
                  >
                    <Chart
                      chartType="PieChart"
                      data={[
                        ['SIMs Status', 'SIMs Counts'],

                        [
                          `Active SIMs -  ${
                            sprInventoryStats &&
                            sprInventoryStats.totalActive.toLocaleString()
                          }`,
                          sprInventoryStats && sprInventoryStats.totalActive,
                        ],
                        [
                          `In Progress SIMs - ${
                            sprInventoryStats &&
                            sprInventoryStats.totalInProgressSims.toLocaleString()
                          }`,
                          sprInventoryStats &&
                            sprInventoryStats.totalInProgressSims,
                        ],
                        [
                          `Available SIMs - ${
                            sprInventoryStats &&
                            sprInventoryStats.totalNonActiveSims.toLocaleString()
                          }`,
                          sprInventoryStats &&
                            sprInventoryStats.totalNonActiveSims,
                        ],
                        [
                          `Paused - ${
                            sprInventoryStats &&
                            sprInventoryStats.totalPausedSims.toLocaleString()
                          }`,
                          sprInventoryStats &&
                            sprInventoryStats.totalPausedSims,
                        ],
                        [
                          `Deactivated - ${
                            sprInventoryStats &&
                            sprInventoryStats.totalDeactivatedSims.toLocaleString()
                          }`,
                          sprInventoryStats &&
                            sprInventoryStats.totalDeactivatedSims,
                        ],
                      ]}
                      options={pieOptions}
                      graph_id="PieChart"
                      width={'100%'}
                      height={'240px'}
                      legend_toggle
                      rootProps={{ 'data-testid': '6' }}
                    />
                  </Spin>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mb-5">
                  <div className="card-header d-flex align-items-center">
                    <div className="">
                      <h5 className="card-title">
                        Top 10 SPR Partner with the most active lines
                      </h5>
                    </div>
                  </div>

                  <Chart
                    chartType="BarChart"
                    data={topSprData}
                    options={{
                      colors: ['#673594'],
                      chartArea: {
                        left: '20%',
                        top: '5%',
                        width: '60%',
                        height: '80%',
                      },
                      hAxis: {
                        title: 'No of Active SIMs',
                        minValue: 0,
                      },
                      vAxis: {
                        title: 'SPR Partners',
                      },
                      fontName: 'Pangram',
                    }}
                    graph_id="BarChart"
                    width={'100%'}
                    height={'700px'}
                    legend_toggle
                    rootProps={{ 'data-testid': '7' }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="">
                      <h5 className="card-title">
                        10 Most Recently Activated SIMs
                      </h5>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table custom-table-secondary table-nowidth">
                      <thead>
                        <tr>
                          <th>
                            <span className="table-column-title">
                              Activated Date{' '}
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">ICCID</span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Company Name
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{recentlyActivatedSimsList()}</tbody>
                    </table>
                  </div>
                  <Link
                    to={`/spr-dashboard/recently-activated-sims`}
                    className="btn btn-lg btn-purple btn-view-all"
                  >
                    View all
                  </Link>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="">
                      <h5 className="card-title">
                        10 Most Recently Deactivated SIMs
                      </h5>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table custom-table-secondary table-nowidth">
                      <thead>
                        <tr>
                          <th>
                            <span className="table-column-title">
                              Deactivated Date{' '}
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">ICCID</span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Company Name
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{recentlyDeactivatedSimsList()}</tbody>
                    </table>
                  </div>
                  <Link
                    to={`/spr-dashboard/recently-deactivated-sims`}
                    className="btn btn-lg btn-purple btn-view-all"
                  >
                    View all
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="">
                      <h5 className="card-title">
                        10 Most Recently Paused SIMs
                      </h5>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table custom-table-secondary table-nowidth">
                      <thead>
                        <tr>
                          <th>
                            <span className="table-column-title">
                              Paused Date{' '}
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">ICCID</span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Company Name
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{recentlyPausedSimsList()}</tbody>
                    </table>
                  </div>
                  <Link
                    to={`/spr-dashboard/recently-paused-sims`}
                    className="btn btn-lg btn-purple btn-view-all"
                  >
                    View all
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="col-md-10">
                      <h5 className="card-title">
                        Most Recent Physical SIM Orders from SPR Partners
                      </h5>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table custom-table-secondary table-nowidth">
                      <thead>
                        <tr>
                          <th>
                            <span className="table-column-title">
                              Order Date{' '}
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">Company</span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Order Number
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Ordered By
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              SIMs Ordered
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{recentOrdersList()}</tbody>
                    </table>
                  </div>
                  <Link
                    to={`/spr-dashboard/recently-ordered-sims`}
                    className="btn btn-lg btn-purple btn-view-all"
                  >
                    View all
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <div className="col-md-10">
                      <h5 className="card-title">
                        Most Recent eSIM Orders from SPR Partners
                      </h5>
                    </div>
                    <div className="col-md-2">
                      <Link
                        to={`/spr-dashboard/pending-approval`}
                        className="text-underline btn-link-orange"
                      >
                        Pending Approval
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table custom-table-secondary table-nowidth">
                      <thead>
                        <tr>
                          <th>
                            <span className="table-column-title">
                              Order Date{' '}
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Order Status
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">Company</span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Order Number
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              Ordered By
                            </span>
                          </th>
                          <th>
                            <span className="table-column-title">
                              eSIMs Ordered
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{recentESIMOrdersList()}</tbody>
                    </table>
                  </div>
                  <Link
                    to={`/spr-dashboard/recently-ordered-eSIMs`}
                    className="btn btn-lg btn-purple btn-view-all"
                  >
                    View all
                  </Link>
                </div>
              </div>
            </div>
          </main>
        )}
        <ReactModal
          isOpen={this.state.isConfirmModal}
          onRequestClose={() => this.setState({ isConfirmModal: false })}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title react-modal-title-md">
                Impersonate
              </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ isConfirmModal: false })}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-subtitle">
                Are you sure you want to impersonate this user?
              </p>
              <div className="text-right">
                <button
                  onClick={this.onConfirmed}
                  className="btn btn-dark btn-lg w-150"
                >
                  {this.state.confirmLoading ? 'Please Wait...' : 'Yes'}
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
