import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment-timezone';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
const PageSize = 25;
export default function ActivityLogs({ getActivitiesLogs }) {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { id: iccid } = useParams();
  const [hasMoreActivities, setHasMoreActivities] = useState(true);
  const [offset, setOffset] = useState(0);

  const fetchActivityLogs = useCallback(
    async (iccid, offset) => {
      try {
        const reqParams = {
          iccid,
          offset,
          limit: PageSize,
        };
        const {
          value: { data },
        } = await getActivitiesLogs(reqParams);
        if (data.length < PageSize) {
          setHasMoreActivities(false);
        }
        setActivities((prevActivities) => [...prevActivities, ...data]);
        setOffset(offset + PageSize);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [getActivitiesLogs]
  );
  const loadMore = () => {
    fetchActivityLogs(iccid, offset);
  };
  useEffect(() => {
    setHasMoreActivities(true);
    setOffset(0);
    setActivities([]);
    fetchActivityLogs(iccid, 0, );
  }, [fetchActivityLogs, iccid]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card mt-5">
          <div className="card-header d-flex align-items-center">
            <h5 className="card-title">Activity Logs </h5>
          </div>
          {isLoading ? (
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table custom-table-secondary">
                  <tbody>
                    <tr>
                      <td>
                        <Spin
                          spinning={isLoading}
                          size="large"
                          style={{ width: '100%' }}
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: '#000' }}
                              spin
                            />
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : activities.length ? (
            <div className="card-body card-body-sm">
              <ul className="usage-activity">
                {activities.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <li>
                        {item.date &&
                          moment(item.date)
                            .tz(moment.tz.guess())
                            .format('MMM DD, YYYY h:mm a z')}
                        -{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
              {hasMoreActivities && (
                <div className="text-center">
                  <button
                    className="btn mt-3"
                    onClick={loadMore}
                    style={{ color: 'purple' }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table custom-table-secondary">
                  <tbody>
                    <tr>
                      <td>
                        <span className="font-16">
                          No Activities Available.
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
