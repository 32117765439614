import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Select } from 'antd';

import '../styles.scss';

const { Option } = Select;

function BrandComponent(props) {
  const [imeiNumber, setImeiNumber] = useState('');
  const [brand, setBrand] = useState('select');
  const [brandsArray, setBrandsArray] = useState([]);
  const [model, setModel] = useState('select');
  const [modelArray, setModeArray] = useState([]);
  const [imeiNumberError, setimeiNumberError] = useState(false);
  const [brandError, setBrandError] = useState(false);
  const [modelError, setModelError] = useState(false);
  const [brandApiErr, setBrandApiErr] = useState(false);
  const [imeiApiErr, setimeiApiErr] = useState(false);

  useEffect(() => {
    (async (props) => {
      if (brandsArray.length === 0) {
        setBrandsArray(_.get(props, 'brandsArray', []));
      }
    })(props);
  }, [props, brandsArray]);

  const imeiSubmit = () => {
    try {
      setimeiApiErr(false);
      if (!imeiNumber) {
        setimeiNumberError(true);
      } else if (
        imeiNumber &&
        (imeiNumber.length < 15 || imeiNumber.length > 17)
      ) {
        setimeiNumberError(true);
      } else {
        setimeiNumberError(false);
        setimeiApiErr(true);
        let imeiNum = imeiNumber.toString().slice(0, 15);
        props.checkDeviceCompatibilty({ imei: imeiNum, isImei: true });
      }
    } catch (error) {
      console.log(error, 'err');
    }
  };

  const handleChangeBrand = (value) => {
    const brandName = value;
    const filterModel = _.filter(
      brandsArray,
      (v) => _.get(v, 'name', '') === brandName
    );
    if (filterModel && filterModel.length > 0) {
      setModeArray(_.get(filterModel[0], 'models', []));
    }
    setBrand(brandName);
  };

  const handleChangeModel = (e) => {
    const modelName = e.target.value;
    setModel(modelName);
  };

  const submitDeviceDetail = async () => {
    try {
      setBrandApiErr(false);
      if (!brand || brand === 'select') {
        setBrandError(true);
      } else if (!model || model === 'select') {
        setBrandError(false);
        setModelError(true);
      } else {
        setModelError(false);
        setBrandError(false);
        setBrandApiErr(true);
        const filterFinalData = _.filter(
          modelArray,
          (m) => _.get(m, 'name', '') === model
        );
        if (filterFinalData && filterFinalData.length > 0) {
          const finalObj = filterFinalData[0];
          let tacNumber = _.get(finalObj, 'tac', '');
          tacNumber = `${_.get(finalObj, 'tac', '')}0000000`;
          props.checkDeviceCompatibilty({ imei: tacNumber, isBrand: true });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onBlur = (e) => {};

  const onFocus = (e) => {};

  const onSearch = (e) => {};

  return (
    <>
      <div className="device-coverage-wrap">
        <div className="card">
          <div className="card-body">
            <h5 className="title">Best results by IMEI number</h5>
            <form>
              <div className="form-group material-textfield">
                <input
                  type="number"
                  id="imeiNumber"
                  placeholder="Enter your phone’s 15-17 digit IMEI"
                  className="form-control form-control-lg material-textfield-input"
                  name="imeiNumber"
                  value={imeiNumber || ''}
                  onChange={(e) => {
                    setImeiNumber(e.target.value);
                  }}
                />
                <label className="material-textfield-label">IMEI Number</label>
                {imeiApiErr && _.get(props, 'deviceApiError', '') !== '' ? (
                  <div className="invalid-feedback">
                    {_.get(props, 'deviceApiError', '')}
                  </div>
                ) : (
                  <div className="invalid-feedback">
                    {imeiNumberError ? 'Please enter valid IMEI number.' : ''}
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={imeiSubmit}
                disabled={props.imeiLoding}
                className="btn btn-dark btn-lg w-100"
              >
                {props.imeiLoding ? 'Please wait...' : 'Continue'}
              </button>
            </form>
          </div>
        </div>
        <div className="divider-space-or">or</div>
        <div className="card">
          <div className="card-body">
            <h5 className="title">Quick check by brand and model</h5>
            <form>
              <div className="form-group material-textfield">
                <Select
                  showSearch
                  // placeholder="Select"
                  optionFilterProp="children"
                  name="brand"
                  value={brand}
                  onChange={(e) => {
                    handleChangeBrand(e);
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  className="search-with-select"
                  showArrow={false}
                  dropdownClassName="search-with-select-overlay"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="select">Select</Option>
                  {brandsArray &&
                    brandsArray.map((data, i) => {
                      return (
                        <Option key={i} value={_.get(data, 'name', '')}>
                          {_.get(data, 'name', '')}
                        </Option>
                      );
                    })}
                </Select>
                <label className="material-textfield-label search-with-select-label">
                  Brand
                </label>
                <div className="invalid-feedback">
                  {brandError ? 'Please select brand.' : ''}
                </div>
              </div>
              {/* <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  name="brand"
                  value={brand}
                  onChange={e => { handleChangeBrand(e) }}
                  required
                >
                  <option value='select'>Select</option>
                  { brandsArray && brandsArray.map((data, i) => {
                      return (
                        <option key={i} value={_.get(data, 'Dv Top Tac By Manufacturer Manufacturer C', '')}>{_.get(data, 'Dv Top Tac By Manufacturer Manufacturer C', '')}</option>
                      )
                    })
                  }
                </select>
                <label className="material-textfield-label">Brand</label>
                <div className="invalid-feedback">
                  {brandError ? "Please select brand." : ""}
                </div>
              </div> */}
              <div className="form-group material-textfield">
                <select
                  className="form-control form-control-lg custom-select material-textfield-input"
                  name="model"
                  value={model}
                  onChange={(e) => {
                    handleChangeModel(e);
                  }}
                  required
                >
                  <option value="select">Select</option>
                  {modelArray &&
                    modelArray.map((data, i) => {
                      return (
                        <option key={i} value={_.get(data, 'name', '')}>
                          {_.get(data, 'name', '')}
                        </option>
                      );
                    })}
                </select>
                <label className="material-textfield-label">Model</label>
                <div className="invalid-feedback">
                  {modelError ? 'Please select model.' : ''}
                </div>
              </div>
              {brandApiErr && _.get(props, 'deviceApiError', '') !== '' ? (
                <div className="invalid-feedback">
                  Error while processing request.
                </div>
              ) : (
                ''
              )}
              <button
                type="button"
                onClick={submitDeviceDetail}
                disabled={props.brandLoding}
                className="btn btn-dark btn-lg w-100"
              >
                {props.brandLoding ? 'Please wait...' : 'Continue'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default BrandComponent;
