/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import '../styles.scss';
import TncImage from '../tncTable.png';

export default class TermsConditionsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="dashboard-layout-content termsconditions__wrapper">
        <h1 className="page__title">UVNV Terms & Conditions</h1>
        <p>
          These UVNV Terms &amp; Conditions{' '}
          <strong>(&rdquo;UVNV T&amp;Cs&rdquo;)</strong> are part of the
          Reseller Master Services Agreement{' '}
          <strong>(&ldquo;Agreement&rdquo;)</strong> you entered into with UVNV
          and constitute part of the binding terms under which we provide you
          Service. Your acceptance of the Agreement is an acceptance of these
          UVNV T&amp;Cs, which are incorporated into the Agreement by reference.
          Your Agreement with us includes, and any reference to the Agreement
          includes, (i) these UVNV T&amp;Cs, (ii) our Privacy Policy, (iii) our
          Acceptable Use Policy, (iv) any End User agreement we provide or make
          available to you, (v) the Rate Plan(s) that you choose as set forth in
          the Agreement or our written services and transaction materials that
          we provide or refer you to during the sales transaction, (vi) any
          confirmation materials that we may provide to you, (vii) the terms set
          forth in any applicable coverage map brochures, and (viii) any other
          supplemental terms and conditions that we provide or otherwise make
          available to you. &nbsp;In the event of a conflict between these UVNV
          T&amp;Cs and any other materials that makeup the Agreement, these UVNV
          T&amp;Cs shall govern to the extent necessary to resolve the conflict.
          Sections marked &ldquo;*&rdquo; continue after termination of our
          Agreement with you. Capitalized terms in these UVNV T&amp;Cs have the
          same meaning as in the Agreement. The term{' '}
          <b>&ldquo;Customer&rdquo;</b> in these UVNV T&amp;Cs refers
          collectively to the terms <b>&ldquo;Reseller&rdquo;</b>and{' '}
          <b>&ldquo;End User&rdquo;</b> as those terms are defined in the
          Agreement.&nbsp;
        </p>
        <h2 className="title">What is a Rate Plan?</h2>
        <p>
          Your <strong>&ldquo;Rate Plan&rdquo;</strong> includes your Service
          allotments, for example, for minutes, messages or data, rates, and
          other terms. UVNV may introduce access to new technologies, features,
          or services that you can add for an additional charge. You can check
          your current usage by visiting UVNV's IoT Portal. If any term in your
          Rate Plan conflicts with these UVNV T&amp;Cs, the term in your Rate
          Plan governs.
        </p>
        <h2 className="title">How will I be charged for data usage?</h2>
        <p>
          Data service may be included in your Rate Plan or data add-on or you
          may be charged for data usage on a pay per use basis{' '}
          <strong>(&ldquo;Data Plan&rdquo;)</strong>. Your Agreement will
          contain more information about how we calculate data usage. You can
          check your current usage by visiting UVNV's IoT Portal. If you do not
          have a Data Plan, your Device may not be able to access data services.
        </p>

        <h2 className="title">Where, how, and when does my Service work?</h2>
        <p>
          These UVNV T&amp;Cs describe the experience you can expect on the
          Network, including information about our reasonable Network management
          practices.
        </p>
        <p>
          Your experience on the Network may vary and change without notice
          depending on a variety of factors. You agree that we are not liable
          for problems relating to Service availability or quality.
        </p>
        <p>
          Your Service will operate only after you have purchased and activated
          a UVNV Rate Plan. &nbsp;
        </p>
        <p>
          <strong>Charges.</strong>You agree to pay all charges we bill you or
          that were accepted or processed through your Device or on such other
          Devices connected with your account and as designated in your Rate
          Plan you agree to provide us with accurate and complete billing
          information and to immediately report to us all changes to your
          billing information.
        </p>
        <p>
          <strong>Voice Usage.</strong>&nbsp;UVNV Rate Plans may include a voice
          plan as part of your Service. &nbsp;We round up any fraction of a
          minute to the next full minute. Airtime usage is measured from the
          time the Carrier begins to process a call through its termination of
          the call and the Network disconnects.
        </p>
        <p>
          <strong>Data Usage and Messaging.</strong> UVNV Rate Plans may include
          a data plan (<strong>&ldquo;Data Plan&rdquo;</strong>) as part of your
          Service. &nbsp;Data usage is rounded up to the next full-kilobyte
          increment at the end of each data session. &nbsp; For all UVNV Rate
          Plans, 1 gigabyte of data is equivalent to 1,000 megabytes. &nbsp;Your
          data usage includes, among other things, text, web browsing, instant
          or picture messages, and email whether read or unread, sent or
          received, solicited or unsolicited. We use filters to block spam
          messages, but we do not guarantee that you will not receive spam or
          other unsolicited messages, and we are not liable for such messages.
          &nbsp;All data purchased with an UVNV Rate Plan (both data that comes
          with a particular UVNV Rate Plan and any additional data you purchase
          as an add-on) will expire immediately upon termination of your UVNV
          Rate Plan for any reason.
        </p>
        <p>
          <strong>Permissible and Prohibited Uses.</strong> Your Data Plan is
          intended for the approved Service Applications in the Agreement.
          Examples of prohibited uses can be found in the Agreement, these UVNV
          T&amp;Cs and in our Acceptable Use Policy.
        </p>
        <p>
          <strong>Data Usage Level.</strong> UVNV has set a 4G data usage level
          (<strong>&ldquo;Usage Level&rdquo;</strong>) for each of its Rate
          Plans based on plan period, except for its Pay-Per-Use plans.
          &nbsp;For purposes of these UVNV T&amp;Cs,{' '}
          <strong>&ldquo;4G&rdquo;</strong> means minimum data speeds equal to
          the lesser of (i) an average of 1.0 mbps over a plan period service
          term, or (ii) the average Network speed in your market over a plan
          period service term as dictated by the Carrier (over which UVNV has no
          control). &nbsp;Our Carrier measures your upload and download data
          usage (&ldquo;Actual Usage&rdquo;) to determine if your total Actual
          Usage, as aggregated over the applicable plan period (&ldquo;Usage
          Total&rdquo;), exceeds the plan period's 4G Usage Level for the Rate
          Plan you selected. Please note that Actual Usage includes all of your
          requests to upload or download data, whether or not such data is
          actually uploaded or downloaded, as well as Network overhead. For
          example, if you request that an image be downloaded to your Device,
          but travel outside of your Network coverage area before the download
          is complete, such request will be included in your Actual Usage even
          though the image was never downloaded to your Device. If you purchased
          a Rate Plan that includes a finite amount of data in a plan's Service
          period, your data will be suspended once you reach the plan period
          data limit and reset upon the commencement of the following plan
          period's Service beginning. &nbsp;If you purchased a Rate Plan that
          includes unlimited data and you exceed your Rate Plan's Usage Level,
          UVNV may reduce the speed at which you can send and receive data over
          the Network until the end of the applicable plan period. &nbsp;If you
          continue to send and/or receive a substantial amount of data (as
          determined by UVNV or the Carrier in each of their sole discretions)
          after our initial reduction of your data speed, we may further reduce
          your data speed to 128 kbps. &nbsp;Once you begin a new plan period in
          your Rate Plan, your Rate Plan Usage Level will be reset and your
          upload and download speeds will be restored. For example, if a
          Customer purchases a Rate Plan that includes unlimited data service,
          but only includes up to 1 gigabyte of high speed data at 4G speeds
          during a given plan period, then the first gigabyte of data requested
          by that Customer in that plan period will be provided at up to 4G
          speeds (subject to 4G Network availability and the actual Network
          speeds of UVNV's Carrier). &nbsp;Any data uploaded or downloaded in
          excess of 1 gigabyte will be provided at reduced speeds. Please be
          aware that your Usage Level is much more likely to exceed your Rate
          Plan's plan period Usage Level if you use streaming video, or if you
          download significant quantities of music files, movies, software
          applications, or engage in other high-bandwidth activities.
        </p>
        <p>
          <strong>Protective Measures.</strong> To provide a good experience for
          the majority of our Customers and minimize capacity issues and
          degradation in Network performance, we may take measures including
          temporarily reducing data throughput for a subset of Customers who use
          a disproportionate amount of bandwidth. If you use your Data Plan in a
          manner that could interfere with other Customers&rsquo; service,
          affect the Carrier's ability to allocate Network capacity among
          Customers, or degrade service quality for other Customers, UVNV or the
          Carrier may suspend, terminate, or restrict your data session, or
          switch you to a more appropriate Data Plan which may result in an
          increased cost. The Network is managed to facilitate the proper
          functioning of services that require consistent high speeds, such as
          video calling, which may, particularly at times and in areas of
          Network congestion, result in reduced speeds for other services.
          Additionally, other Network management practices may be implemented,
          such as caching less data, using less capacity, sizing video more
          appropriately for a Device to transmit data files more efficiently,
          and deploying streaming video optimization technology, which may
          affect the performance and download times of data-heavy activities
          such as video-streaming on some unlimited plans. Streaming video
          optimization technology is intended to manage data usage on the
          Network, reduce the risk of streaming video stalling and buffering,
          and reduce the amount of high-speed data consumption used for
          streaming video. Streaming video optimization improves streaming video
          reliability as well as makes room for other users to enjoy higher
          browsing speeds. Detectable video may typically stream at DVD quality
          or Standard Definition.
        </p>

        <p>
          <strong>Wi-Fi Features.</strong> &nbsp;UVNV or Carrier, at their sole
          discretions, may make available Wi-Fi voice and text messaging
          services, i.e., the ability to originate and terminate calls and text
          messages over a Wi-Fi connection (
          <strong>&ldquo;Wi-Fi Calling&rdquo;</strong>). &nbsp;If offered by
          UVNV, Wi-Fi Calling will only be available for UVNV Resellers or End
          Users that (i) have a Wi-Fi capable Device with UVNV supported Wi-Fi
          calling capability, (ii) have Wi-Fi Calling service provisioned on
          their account, (iii) have a compatible SIM card, and (iv) are
          connected to functioning third-party internet service. &nbsp;Not all
          services available on the Carrier's Network are available while using
          Wi-Fi Calling. &nbsp;For example, emergency alerts may not be
          available with Wi-Fi Calling. &nbsp;You acknowledge that calling 9-1-1
          via Wi-Fi Calling uses the internet and operates differently than
          traditional 9-1-1. &nbsp;For example, 9-1-1 may not work during power
          or internet outages or disruptions, or if internet or your Service is
          suspended. &nbsp;Location information when using Wi-Fi Calling may be
          limited or unavailable. When you call 9-1-1 over Wi-Fi away from your
          primary address, we and the Carrier may have no or very limited
          information about your location. &nbsp;Neither UVNV nor the Carrier
          are responsible or liable for anything related to your use of or
          inability to use any Wi-Fi Calling MADE AVAILABLE TO you, including,
          without limitation, any failure of emergency calls (whether 9-1-1
          calls or otherwise). &nbsp;Wi-Fi Calling may decrement Rate Plan
          minutes. &nbsp;Most Devices will not transition between Wi-Fi and the
          wireless Network. &nbsp;Devices using wireless connections may be
          vulnerable to unauthorized attempts to access data and software stored
          on the device.
        </p>

        <p>
          <strong>Downloadable Content and Applications.</strong> Content or
          applications (e.g., downloadable or networked applications,
          wallpapers, ringtones, games, and productivity tools) (collectively,{' '}
          <strong>&ldquo;Content &amp; Apps&rdquo;</strong>) that you can
          purchase or download with your Device are not sold or offered by UVNV.
          UVNV is not responsible for the Content &amp; Apps, including
          download, installation, use, transmission failure, interruption, or
          delay, or any content or website you may be able to access through the
          Content &amp; Apps. Unless otherwise stated, any support questions for
          these Content &amp; Apps may be directed to the third party seller.
          When you use, download or install Content &amp; Apps sold by a third
          party seller, you may be subject to license terms, terms of use, a
          privacy policy and/or other policies between you and that third party.
          Content &amp; Apps you purchase from third parties are licensed for
          personal, lawful, non-commercial use on your Device only. Content
          &amp; Apps may not be transferable from one Device to another Device.
          Some Devices or Content &amp; Apps may continue to have contact with
          the Network without your knowledge, which may result in additional
          charges. Software on your Device may automatically shut down or limit
          the use of Content &amp; Apps or other features or Services without
          warning. UVNV is not responsible for any third party content,
          advertisements, or websites you may be able to access using your
          Device.
        </p>

        <p>
          <strong>Use of Information.</strong> If you visit any third party
          website or app store, or download or use any Content &amp; Apps, the
          third party may access, collect, use or disclose your personal or
          company information or require the Carrier to disclose your
          information, including location information (when applicable) to the
          Content &amp; Apps Reseller or some other third party. &nbsp;If you
          access or use any Content &amp; Apps through UVNV's Service, you agree
          and authorize UVNV and the Carrier to provide information related to
          such use. You understand that your use of a third party app is subject
          to the third party's terms and conditions and policies, including its
          privacy policy. Please refer to the Content &amp; Apps creator/owner's
          privacy policy for information regarding their use of information
          collected when you download, install, or use any third party Content
          &amp; Apps. We are not responsible for any transmission failure,
          interruption, or delay related to Content &amp; Apps, or any content
          or website you may be able to access through the Content &amp; Apps.
        </p>

        <p>
          Your Device may connect to another provider's network even when you
          are within the UVNV coverage area. Check your Device to determine if
          you are roaming. There may be extra charges (including long distance,
          tolls, data usage) and higher rates for roaming usage, and your
          quality and availability of service may vary significantly. &nbsp;If
          made available, our roaming charges and rates are subject to change at
          any time.
        </p>

        <p>
          <strong>Prioritization.</strong> To provide the best possible
          experience for the most possible Resellers and End Users on UVNV
          branded Rate Plans, for the vast majority of Rate Plans, UVNV or
          Carrier may prioritize the data usage of a small percentage of our
          heavy data users, specifically those using more than 30GB of data in a
          billing cycle, below that of other data users. This threshold number
          is periodically evaluated and may change over time. UVNV or Carrier
          also may prioritize the data of Resellers or End Users who choose
          certain Rate Plans after the data for other UVNV branded Rate Plans,
          but before Resellers or End Users who are prioritized as heavy data
          users. Resellers or End Users whose data is prioritized lower may
          notice speeds lower than those users with higher priority in times and
          locations where there are competing demands for Network resources.
          UVNV or Carrier may prioritize smartphone and mobile internet (tablet)
          over Smartphone Mobile HotSpot (tethering) traffic on the Network.
        </p>

        <p>
          Streaming video optimization technology may be used in the Network to
          help minimize data consumption while also improving the service
          experience for all users.&nbsp;
        </p>

        <p>
          Additionally, UVNV or Carrier may implement other Network practices to
          ensure optimized Network performance as technologies evolve.
        </p>

        <h2 className="title">
          *What are the permitted and prohibited uses for my Device and the
          Services?
        </h2>
        <p>
          The wireless Network is a shared resource, which is managed for the
          benefit of all of our users. Your Data Plan is intended for Web
          browsing, messaging, and similar activities. Certain activities and
          uses of our Services and your Device are permitted and others are not.
          For examples of permitted and prohibited uses, please refer to our
          Acceptable Use Policy and the Examples of Prohibited Uses of the
          Service section in these UVNV T&amp;Cs. If you buy, lease, or finance
          a Device manufactured for use on the Network, you agree, and we rely
          on your agreement, that you intend it to be activated on our Service
          and will not resell or modify the Device, or assist anyone doing so.
        </p>

        <h2 className="title">*What happens if my Device is lost or stolen?</h2>
        <p>
          You agree to notify us if your Device is lost or stolen. Once you
          notify us, we will suspend your Service. After your Service is
          suspended, you will not be responsible for additional usage charges
          incurred in excess of your Rate Plan charges, and applicable Taxes and
          Surcharges. If you request that we not suspend your Service, you will
          remain responsible for all usage and charges incurred and applicable
          Taxes and Surcharges. We may prevent a lost or stolen Device from
          registering on any network. <b>California Customers:</b> For charges
          incurred before you notify us, you are not liable for charges you did
          not authorize, but the fact that your Device or account was used is
          some evidence of authorization. You may request that we investigate
          charges you believe were unauthorized. We may ask you to provide
          information and you may submit information to support your request. If
          we determine the charges were unauthorized, we will credit your
          account. If we determine the charges were authorized, we will inform
          you within thirty (30) days and you will remain responsible for the
          charges.
        </p>

        <h2 className="title">
          *How will I be billed for use of the Services?
        </h2>
        <p>
          You agree to pay all Charges we assess and bill you or that were
          accepted or processed through all Devices on your account.
        </p>
        <p>
          Off-Rate Plan Charges. You may have to pay extra for calls to some
          numbers (e.g., conference &amp; chat lines, broadcast, calling card,
          international, 900 or 976 calls, etc.). You agree to provide us with
          accurate and complete billing and tax related information and to
          report all changes within 30 days of the change.&nbsp;
        </p>

        <h2 className="title">What if I don't pay on time?</h2>
        <p>
          We may charge a late fee of the greater of 1.5% per month (18%
          annually), subject to the maximum allowed by law. If you fail to pay
          on time and we refer your account to a third party for collection, a
          collection fee will be assessed by UVNV and will be due at the time of
          the referral to the third party. The fee will be calculated as a
          percentage of the amount due to the extent permitted, or not otherwise
          prohibited, by applicable law. If we accept late or partial payments,
          you still must pay us the full amount you owe, including late fees.
          &nbsp;Late payment, non-payment and/or collection fees are intended to
          be a reasonable advance estimate of our actual costs resulting from
          late payments and non-payments by our Customers; these costs are not
          readily ascertainable and are difficult to predict or calculate at the
          time that these fees are set.
        </p>

        <p>
          <b>*License.</b> If available, your Device's Software is licensed, not
          sold, to you by UVNV and/or other licensors for use as intended by the
          approved Service Application(s) in the Agreement. You may only use the
          Software as authorized by its license. Your Device's
          &ldquo;Software&rdquo; includes its software, interfaces,
          documentation, data, and Content &amp; Apps, as each may be updated or
          replaced by feature enhancements or other updates.&nbsp;
        </p>

        <p>
          <b>*Your Consent to be Contacted.</b> We may contact you without
          charge, on any wireless telephone number assigned to your account for
          any purpose, including marketing, and in any manner permitted by law.
          You also expressly consent to be contacted by us, and anyone
          contacting you on our behalf, for any purpose, including billing,
          collection, or other account or Service related purpose, at any
          telephone number or physical or electronic address where you may be
          reached, including any wireless telephone number. You agree that UVNV,
          and anyone contacting you on our behalf, may communicate with you in
          any manner, including using a pre-recorded or artificial voice, using
          an automatic telephone dialing system to place calls or send messages,
          or alerts, or using an automatic e-mail system to deliver email
          messages. If a contact number you have provided to us is no longer
          your number, you agree to notify us promptly that you can no longer be
          reached at that number. You represent that you have received, and are
          authorized to convey to us, the consent of any authorized users on
          your account to be contacted by us as described in this Section. You
          agree that all consents provided in this Section will survive
          cancellation of your Service and account or expiration of the
          Agreement.
        </p>

        <p>
          <b>Emergency Alerts.</b> UVNV participates in the wireless emergency
          alerts program administered by the federal government within portions
          of its Network. This allows federal, state, and local government
          agencies to send alerts about local emergencies to Customers in
          specifically defined geographic areas. Wireless alert capable Devices
          with appropriate notification settings are required for the service.
          There is no additional charge for these wireless emergency
          alerts.&nbsp;
        </p>

        <p>
          <b>911 Access.</b> 911 services are made possible by your state and
          local government. Check to see if your Devices are capable of making
          calls to 911 in the United States. The Device must have battery power
          and Network connectivity to complete a 911 call. When making 911
          calls, you should be prepared to provide information about where you
          are located. In some cases, 911 communications center operators may
          not know your phone number or have information about your location.
          Other third-party entities are involved in connecting a 911 call and
          UVNV does not determine the public safety agency to which your 911
          call is routed. If you are porting a phone number to or from us, while
          the port is in process, we may not be able to provide you with some
          Services, such as 911 location services. If you are outside the U.S.,
          you may have to dial a different number than 911 to call emergency
          services.
        </p>

        <p>
          <b>Puerto Rico Customers.</b> We will provide you with a determination
          regarding any dispute that is presented to us in accordance with this
          Section 11 within fifteen (15) days after we receive it. You may
          appeal our determination to the <b>Telecommunications Board</b> of the
          Commonwealth of Puerto Rico (&ldquo;Telecommunications Board&rdquo;)
          by filing a petition for review up to thirty (30) days after the date
          of our determination. Your petition for review shall be made through
          the filing of a document containing the following information: (i)
          your name and address; (ii) our company name; (iii) the pertinent
          facts; (iv) any applicable legal provisions that you are aware of; and
          (v) the remedy you are requesting. The document may be filed
          handwritten or typewritten and must be signed by you. You must send us
          a copy of your document to the following address: UVNV, LLC, 1550
          Scenic Avenue, Suite 100, Costa Mesa, California 92626, Attn: Customer
          Service. You must send your petition for review to the
          Telecommunications Board at the following address: 500 Ave. Roberto H.
          Todd (Pda. 18 &ndash; Santurce), San Juan, Puerto Rico 00907-3941. The
          Telecommunications Board will review our determination only on appeal.
          You are advised of the provisions regarding suspension of Service that
          appear in Law 33 of July 7, 1985, Law 213 of September 12, 1996 and
          Regulation 5940 promulgated by the Telecommunications Board. You are
          also advised of Regulation 5939 of March 12, 1999 promulgated by the
          Telecommunications Board regarding the procedures for resolution of
          customer disputes.
        </p>

        <p>
          <b>*DISCLAIMER OF WARRANTIES.</b> Except for the express warranties in
          the Agreement and any written warranty that may be provided with a
          UVNV Device you purchase from us, and to the extent permitted by law,
          the Services and Devices are provided on an <b>&ldquo;</b>as is&rdquo;
          and &ldquo;with all faults&rdquo; basis and without warranties of any
          kind. We make no representations or warranties, express or implied,
          including any implied warranty of merchantability or fitness for a
          particular purpose concerning your Service or your Device. We can't
          and don't promise uninterrupted or error-free service and don't
          authorize anyone to make any warranties on our behalf. This doesn't
          deprive you of any warranty rights you may have against anyone else.
          We do not guarantee that your communications will be private or
          secure; it is illegal for unauthorized people to intercept your
          communications, but such interceptions can occur.
        </p>

        <p>
          Services or Software provided by third parties (including voice
          applications), 911 or E911, text to 911, or other calling or messaging
          functionality, may work differently than services offered by us, or
          may not work at all. Please review all terms and conditions of such
          third party products. When using these products, we are not
          responsible for the availability or reliability of 911 calls or text
          to 911 messages, or if inaccurate location information is provided to
          the 911 Communications Center. We cannot assure you that if you place
          a 911 call or text you will be found.
        </p>

        <p>
          We are not responsible for any download, installation, use,
          transmission failure, interruption, or delay related to Content &amp;
          Apps, or any third party content, services, advertisements, or
          websites you may be able to access by using your Device or the
          Services, even if charges for Content &amp; Apps appear on your UVNV
          bill. You are responsible for maintaining virus and other Internet
          security protections when accessing third party Content &amp; Apps or
          other services.
        </p>

        <p>
          <b>Additional Software License Terms.</b> Except as permitted by
          applicable law, you may not assign, transfer, sublicense, copy,
          reproduce, redistribute, resell, modify, decompile, attempt to derive
          the source code of, or reverse engineer all or any part of the
          Software, or alter, disable or circumvent any digital rights
          management security features embedded in the Software. The Software
          may not be transferable from one Device to another Device. You may not
          create derivative works of all or any part of the Software. You agree
          the Software contains proprietary content and information owned by
          UVNV, its licensors, and/or other third parties. UVNV, its licensors,
          and such other third parties reserve the right to change, suspend,
          terminate, remove, impose limits on the use or access to, or disable
          access to, the Software at any time without notice and will have no
          liability for doing so. You agree that your violation of the Software
          license harms UVNV, its licensors, and/or other third parties, that
          this harm cannot be fully redressed by money damages, and that UVNV,
          its licensors, and such other third parties shall be entitled to
          immediate injunctive relief in addition to all other remedies
          available.
        </p>

        <h2 className="title">ADDITIONAL TERMS</h2>
        <p>
          If we don&#39;t enforce our rights under this Agreement in one
          instance, that doesn&#39;t mean we won&#39;t or can&#39;t enforce
          those rights in any other instance. If any part of the Agreement is
          held invalid that part may be severed from the Agreement.
        </p>

        <p>
          You can&#39;t assign or transfer the Agreement or any of your rights
          or duties under it without our written consent. We may assign or
          transfer all or part of the Agreement, or your debts to us, without
          notice. You understand that the assignment or transfer of all or any
          part of this Agreement or your debt will not change or relieve your
          obligations under this Agreement.
        </p>

        <p>
          The Agreement is the entire agreement between you and us regarding the
          rights you have with respect to your Service, except as provided by
          law, and you cannot rely on any other documents or statements by any
          sales or service representatives or other agents.
        </p>

        <p>
          The original version of the Agreement is in English. To the extent
          there are conflicts between the English version and any other language
          version, the English version will control.
        </p>

        <h1 className="page__title">ACCEPTABLE USE POLICY</h1>
        <p>
          The Acceptable Use Policy (&ldquo;<b>Acceptable Use Policy</b>&rdquo;)
          in Exhibit C is incorporated by reference into the Reseller Master
          Services Agreement and binding on Reseller and its End Users. Reseller
          will enter into terms of service with End Users which are at least as
          restrictive as the Acceptable Use Policy in this Exhibit C.&nbsp;
        </p>
        <p>
          Examples of permitted and prohibited uses of the Service and Your
          Device
        </p>
        <section className="list__section">
          <h3 className="list__title">Permitted uses include:</h3>
          <ul className="list__style">
            <li>Voice calls;</li>
            <li>Web browsing;</li>
            <li>Messaging;</li>
            <li>Email;</li>
            <li>Streaming music;</li>
            <li>
              Uploading and downloading applications and content to and from the
              Internet or third party stores;
            </li>
            <li>
              Using applications and content without excessively contributing to
              network congestion; and
            </li>
            <li>
              Tethering your Device to other non-harmful devices pursuant to the
              terms and conditions and allotments of your Data Plan.
            </li>
          </ul>
        </section>
        <p>
          Except as may be specifically authorized by UVNV in Reseller's
          approved Service Application or unless explicitly permitted by your
          Rate Plan or Data Plan, you are not permitted to use your Device or
          the Services in a way that we determine:
        </p>

        <section className="list__section">
          <ul className="list__style">
            <li>
              Uses a repeater or signal booster other than one we provide to
              you;
            </li>
            <li>
              Uses the Service for Machine to Machine based remote monitoring to
              provide life-sustaining medical care for any individual, including
              without limitation, in health care and assisted living
              environments;
            </li>
            <li>
              Uses the Service for the sale of any type of calling card product
              or service, including, but not limited to, any
              &lsquo;grab-and-go&rsquo; or rechargeable product with a pin code
              that allows the End User to purchase and/or refill minutes,
              internet access features or service;
            </li>
            <li>
              Uses, or attempts to use, the Service for voice communications on
              a non-UVNV branded (white label) SIM card, or in any manner that
              creates, or appears to create a new &ldquo;brand&rdquo; of
              wireless service;
            </li>
            <li>
              Uses the Service for monitoring third parties without their
              permission, including, but not limited to, prisoner and parolee
              monitoring;
            </li>
            <li>
              Uses Devices that utilizes a UVNV SIM to make voice calls
              primarily over a Wi-Fi network, including, without limitation, the
              use of any existing UVNV add-on feature that allows Wi-Fi calling
              capability; Use Devices that utilizes a UVNV SIM to make voice
              calls primarily over a Wi-Fi network. Wi-Fi calling capability may
              be offered on a UVNV SIM with a voice Rate Plan as an ancillary
              offering only;
            </li>
            <li>
              Modifies the Service in any manner, including, but not limited to,
              combining to include any Reseller or third-party products,
              services, features or functionalities that are not included in the
              Service standing alone (&ldquo;Bundled Service&rdquo;) without
              prior written approval from a UVNV Vice President;&nbsp;
            </li>
            <li>
              Resells the Service or Devices to any government End User or
              subscriber, including, but not limited to federal, state, and
              local governmental entities;
            </li>
            <li>
              Resells the Service or Devices by promoting any specific feature
              that may be included with the UVNV Rate Plan other than base voice
              or internet access Service usage allotments that are provided for
              domestic use with each rate plan.
            </li>
            <li>
              Compromises Network security or capacity, degrades Network
              performance, uses malicious software or &ldquo;malware&rdquo;,
              hinders other customers&rsquo; access to the Network, or otherwise
              adversely impacts Network service levels or legitimate data flows;
            </li>
            <li>
              Uses applications which automatically consume unreasonable amounts
              of available Network capacity;
            </li>
            <li>
              Uses applications which are designed for unattended use, automatic
              data feeds, automated machine-to-machine connections, or
              applications that are used in a way that degrades Network capacity
              or functionality;
            </li>
            <li>
              Misuses the Service, including &ldquo;spamming&rdquo; or sending
              abusive, unsolicited, or other mass automated communications;
            </li>
            <li>Accesses the accounts of others without authority;</li>
            <li>
              Results in more than 50% of your voice and/or data usage being
              Off-Net (i.e., connected to another provider's Network) for any 2
              billing cycles within any 12-month period;
            </li>
            <li>
              Results in unusually high usage (specifically, more than 50GB
              (updated periodically) in a month) and the majority of your data
              usage being Smartphone Mobile HotSpot (tethering) usage for any 3
              billing cycles within any 6-month period;
            </li>
            <li>
              Resells the Service without authorization, either alone or as part
              of any other good or service;
            </li>
            <li>
              Tampers with, reprograms, alters, or otherwise modifies your
              Device to circumvent any of our policies or violate anyone's
              intellectual property rights;
            </li>
            <li>
              Causes harm or adversely affects us, the Network, our customers,
              employees, business, or any other person;
            </li>
            <li>Conflicts with applicable law;</li>
            <li>Is not in accordance with the UVNV T&amp;Cs;&nbsp;</li>
            <li>
              Attempts or assists or facilitates anyone else in any of the above
              activities; or
            </li>
            <li>In other ways as we may describe from time to time.&nbsp;</li>
          </ul>
        </section>
        <h1 className="page__title">PRIVACY POLICY</h1>
        <p>
          The following sets forth the Privacy Policy (
          <strong>"Privacy Policy"</strong>) of UVNV and is incorporated into
          and made a part of the Reseller Master Services Agreement (the{' '}
          <b>&ldquo;Agreement&rdquo;</b>).&nbsp;
        </p>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Purpose of Privacy Policy</li>
          </ul>
          <p>
            Your privacy is important to UVNV. Accordingly, UVNV provides this
            Privacy Policy so you understand how UVNV collects, uses and, in
            certain circumstances, discloses the information that you provide
            and/or that is automatically provided to UVNV as a result of your
            visiting and using the Plum web site, the IoT Portal, or any other
            website or portal owned and operated by UVNV (collectively, the
            &ldquo;<b>Sites</b>&rdquo;), or purchasing or using the Services.
            &nbsp;This Privacy Policy also explains your options regarding the
            collection, use and disclosure of your personal information. UVNV is
            not responsible for the content or privacy practices on any
            third-party web site that may be linked to or from the Sites. If you
            do not agree with the policies outlined in this Privacy Policy, do
            not visit our Sites our use our Services. By using our Sites or
            Services, you agree to UVNV's data handling practices outlined in
            this Privacy Policy.&nbsp;
          </p>
          <p>
            Any questions regarding this Privacy Policy should be directed to:
          </p>
          <p>
            UVNV, LLC<br/>
            Attn: Customer Service <br />
            17870 Newhope Street #104-155 <br />
            Fountain Valley, CA 92708<br />
            Email:&nbsp;
            <span>
              <a className="btn-link-orange" href="mailto:info@ultra.me">
                info@ultra.me
              </a>
            </span>
          </p>
          <ul className="list__style inner__list">
            <li>Collection and Use of Personal Information</li>
          </ul>
          <p>
            Personal information is information that identifies or is associated
            with a specific individual, such as a name, address, email address,
            or telephone number. UVNV collects personal information about
            Resellers, End Users, and other individuals. The ways in which we
            collect personal information about you include, but are not limited
            to, the following:
          </p>
          <ul className="list__style">
            <li>
              Registration and Ordering. Before using certain parts of the Sites
              or purchasing Services, you must complete an online registration
              form or provide certain information to UVNV's representatives.
              During registration (whether via the Sites or otherwise), you will
              be prompted to provide to UVNV certain personal information, which
              may include, but is not limited to, your name, billing
              address(es), &nbsp;phone number, port-in phone number, email
              address, and credit card number. This personal information is used
              for billing purposes, fulfilling your orders, communicating with
              you about your order or the services, and for marketing
              purposes.&nbsp;
            </li>
            <li>
              User Submissions. Certain locations in the Sites may permit you to
              enter your email address and other contact information to receive
              further information about UVNV or for other purposes. Your
              submissions for these purposes are completely voluntary, so you
              have a choice whether to participate and disclose information to
              UVNV. Should you choose to submit information in response to these
              requests, UVNV may collect and use your name, email address,
              geographic location and other information submitted on the Sites
              to market UVNV's (including Affiliates&rsquo;) products and
              services.
            </li>
            <li>
              Automatically Generated and Collected Information. In addition to
              information that you volunteer as described above, the Sites may
              utilize technologies to collect information and track your user
              experience. UVNV will use this information to improve the Sites
              and user experience. In addition, the Sites automatically
              recognizes the Internet URL from which you access the Sites and
              may also log your Internet protocol (&ldquo;IP&rdquo;) address
              (which may indicate the location of your computer on the
              Internet), Internet service provider, and date/time stamp for
              system administration, order verification, marketing, &nbsp;and
              system troubleshooting purposes. In addition, all wireless
              communication systems know when your Device is turned on and
              approximately where the device is physically located.
            </li>
            <li>
              Information from Third Parties. We may obtain information about
              you and combine it with your account information. See the table
              for more information on how we may use this information.
            </li>
          </ul>
        </section>
        <p>
          Generally, UVNV collects the above information for the following
          purposes: (i) to improve your experience on the Sites; (ii) to improve
          the products and services that UVNV sells; (iii) to communicate with
          you regarding the Sites; (iv) to market and sell products and services
          to you and send you information about special offers; (v) to contact
          you about products or services you have purchased from UVNV; (vi) to
          respond to your inquiries; (vii) to manage and develop our business
          and operations, administer accounts, and collect and process payments;
          and (viii) &nbsp;to communicate with you about your account.&nbsp;
        </p>
        <p>
          UVNV will not sell, rent, trade, license or otherwise disclose your
          specific personal information or financial information to third
          parties, except that: (1) UVNV uses third parties to perform specific
          functions on its behalf and it must disclose information to help those
          parties perform their services (for example, UVNV would disclose your
          name, address and phone number to a commercial carrier such as Federal
          Express or UPS in order to deliver a physical product that you order);
          (2) UVNV must disclose your name and credit card number and
          information to financial services and credit card processing companies
          in order to process your orders; (3) UVNV may be required to disclose
          such information in response to a legal process, order or
          investigation (for example, a subpoena or court order); (4) UVNV may
          disclose personal information where disclosure is necessary to protect
          its legal rights or to protect itself or others (for example, in order
          to reduce the risk of fraud or illegal activities); (5) UVNV may share
          such information amongst the Affiliates for the purpose of marketing
          products and services to you; (6) UVNV may provide identity
          verification services to third parties and your personal information
          may be included in the data transferred to third parties in connection
          with those services; and (7) UVNV may acquire, merge with or be
          acquired by another company or it might sell some or all of its
          assets, and, in such a case, your personal information may be included
          in the transferred business assets. Also, in the event of our
          bankruptcy, insolvency, reorganization, receivership, or assignment
          for the benefit of creditors, we may not be able to control how your
          personal information is treated, transferred, or used and your
          personal information may be included in the transferred assets. UVNV
          may sell, rent, trade, license, use and disclose all non- personal
          information and aggregate information, so long as such information
          does not personally identify you or any user, to any third party for
          any purpose, including, but not limited to, research and analytical
          purposes.
        </p>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Cookies</li>
          </ul>
          <p>
            <strong>Cookies</strong> are pieces of information that may be
            placed on your computer by a Sites for the purpose of facilitating
            and enhancing your communications and interaction with that Sites.
            Cookies may possibly be used for such things as: (1) to customize
            your visitation and shopping experience; (2) to deliver content
            consistent with your stated interests; (3) to provide you with
            custom-tailored content; or (4) to save your password in
            password-protected areas. The use of cookies is an industry standard
            &mdash; you will find them at most major Sites. Most browsers are
            initially set to accept cookies. If you would prefer, you can set
            yours to refuse cookies or to alert you when cookies are being sent.
            However, it is possible that some parts of the Sites will not
            function properly if you do so. Cookies, by themselves, do not tell
            us your email address or other personal information unless you
            choose to provide this information to us. However, once you choose
            to furnish the Sites with personal information, this information may
            be linked to the data stored in the cookie. We use cookies to
            understand usage of the Sites and to improve the content and
            offerings on our Sites. In addition, we may utilize web beacons to
            recognize you when you visit the Sites. We may also use other
            technologies, currently available or which may become available in
            the future, to provide us with other important anonymous information
            regarding the use of our Sites.
          </p>
        </section>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Consumer Proprietary Network Information</li>
          </ul>
          <p>
            Under federal law, you have a right, and UVNV has a duty, to protect
            the confidentiality of information regarding your use of the
            Services, the calls you place and the location of your Device on the
            Network when you make a telephone call. This information is referred
            to as, among other things, &ldquo;
            <b>Customer Proprietary Network Information.</b>&rdquo; We may share
            Customer Proprietary Network Information with Affiliates; provided,
            however, that we will first notify you of your rights under
            applicable law, describe how we intend to use the Customer
            Proprietary Network Information, and give you an opportunity to opt
            out of such use (or opt in, to the extent required by applicable
            law). Resellers are responsible for complying with federal
            regulations regarding End User Customer Proprietary Network
            Information.&nbsp;
          </p>
        </section>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Children's Online Privacy Protection Act</li>
          </ul>
          <p>
            Our Sites are not intended for children under 13 years of age. No
            one under age 13 may provide any information, personal or otherwise,
            to or on any of our Sites. We do not knowingly collect personal
            information from children under 13. If you are under 13, do not use
            or provide any information on the Sites or on or through any of its
            features, make any purchases, use any of the interactive features,
            or provide any information about yourself to us, including your
            name, address, telephone number, email address, or any screen name
            or user name you may use. If we learn we have collected or received
            personal information from a child under 13 without verification of
            parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 13, please
            contact us at the email address above.
          </p>
        </section>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Revisions</li>
          </ul>
          <p>
            From time to time, we may revise the Privacy Policy. Your use of the
            Sites or Services following the posting of any revised Privacy
            Policy shall be deemed acceptance of the revised policy, so we
            recommend that you check the Privacy Policy periodically. If you
            disagree with the terms of the Privacy Policy at any time, your sole
            remedy is to terminate your use of the Sites and Services. Continued
            use of the Sites or Services constitutes your agreement to any
            Privacy Policy as in effect.
          </p>
        </section>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>Severability</li>
          </ul>
          <p>
            If any part of the Privacy Policy is determined to be invalid or
            unenforceable by any arbitrator, court of competent jurisdiction or
            applicable law, the parties intend that such provision be amended
            and construed in a manner designed to effectuate the purposes of the
            provision to the fullest extent permitted by law.
          </p>
        </section>
        <section className="list__section">
          <ul className="list__style inner__list">
            <li>California Subscriber Rights</li>
          </ul>
          <p>
            As of January 1, 2021 if you are a California resident, as defined
            in the California Code of Regulations, you have rights under the
            California Consumer Privacy Act of 2018 (the{' '}
            <b>&ldquo;CCPA&rdquo;</b>). A description of your rights and
            disclosures about how we process your personal information are
            below.
          </p>
          <p>
            The CCPA will give California residents certain rights regarding
            their data, including:
          </p>
        </section>

        <section className="list__section">
          <ul className="list__style">
            <li>
              The right to know the categories of personal information we've
              collected and the categories of sources from which we got the
              information (see Information collected by UVNV and the chart just
              below)
            </li>
            <li>
              The right to know the business purposes for sharing personal
              information (see Why UVNV uses your information)
            </li>
            <li>
              The right to know the categories of third parties with whom we've
              shared personal information (see Sharing your information)
            </li>
            <li>
              The right to access the specific pieces of personal information
              we've collected and the right to delete your information (see
              Privacy tools and choices)
            </li>
          </ul>
        </section>
        <p>
          California residents will also have the right to not be discriminated
          against if they exercise their rights under the CCPA.
        </p>
        <p>
          Before we honor your rights to request information or delete
          information, we must verify your or your authorized agent's identity.
          In certain cases, we may require you to provide additional information
          in order to verify your request.
        </p>
        <p>
          UVNV does not sell your personal information, but we may disclose your
          personal information to service providers who support our operations.
        </p>
        <p>
          <i>
            Categories of Personal Information under the California Consumer
            Privacy Act
          </i>
        </p>
        <div style={{ textAlign: 'center' }}>
          <img
            className="terms__img"
            src={TncImage}
            alt="Terms & Conditions table-"
            width="800"
            height="400"
          />
        </div>
        <p>
          <i>Privacy tools and choices</i>
        </p>
        <p>
          For CCPA related requests, please submit a request to your UVNV
          representative&nbsp;
        </p>
        <p>Call 1.888.777.0446</p>
        <p>What to expect once you submit a request?</p>
        <p>
          UVNV will confirm receipt of the request within 10 days via email
          address you provided.
        </p>
        <h1 className="page__title">RESELLER TERMS & CONDITIONS</h1>
        <p>
          These Reseller Terms &amp; Conditions (
          <strong>&ldquo;Reseller Terms &amp; Conditions&rdquo;</strong>) are
          part of the Reseller Master Services Agreement (
          <b>&ldquo;Agreement&rdquo;</b>) you entered into with UVNV and
          constitute part of the binding terms under which we provide you
          Service. The Agreement includes other legally binding terms that you
          accept when you accept the Agreement. Your acceptance of the Agreement
          is also an acceptance of these Reseller Terms &amp; Conditions, which
          are incorporated into the Agreement. &nbsp;1. Resellers are restricted
          from any further sale or resale of the Services, except as part of a
          Service Application approved by UVNV and sold only to End Users. The
          End User's service contract and relationship regarding the Service
          Application must be with the Reseller. &nbsp;Reseller may not sell the
          Service Application to Excluded Distribution Partners or to any other
          person or entity for the purpose of that person or entity re-selling
          the Service Application under its own brand or pursuant to a service
          contract between the Reseller or End User and any person other than
          the reseller. &nbsp;Without limitation, Reseller will not sell the
          Services to any wholesaler or reseller. &nbsp;&nbsp;
        </p>
        <section className="list__section">
          <ul className="list__style">
            <li>
              Reseller will distribute Service Application to End Users in
              accordance with the terms of the Agreement (including without
              limitation any regulatory restrictions) and only in the Authorized
              Areas. Reseller will not sell or provide access to the Service
              Application to End Users outside the Authorized Areas.&nbsp;
            </li>
            <li>
              Reseller will require all End Users to agree to terms of service
              which include the Mandatory End User Contract Terms set forth in{' '}
              <b>Exhibit F </b>of this Agreement.
            </li>
            <li>
              Reseller will not make any representations or warranties to third
              parties on UVNV or T-Mobile's behalf.&nbsp;
            </li>
            <li>
              Reseller is not authorized to act as an agent for or legal
              representative of UVNV or T-Mobile and does not have the authority
              to assume or create any obligation on behalf of, in the name of,
              or that will be binding upon, UVNV or T-Mobile. &nbsp;
            </li>
            <li>
              Reseller may not assign its rights or delegate its obligations
              under the Agreement without UVNV's prior written approval.&nbsp;
            </li>
            <li>T-Mobile has no obligations to Reseller.&nbsp;</li>
            <li>
              Reseller will under no circumstances have any claims against
              T-Mobile, and Reseller agrees not to sue T-Mobile in connection
              with any of its rights in operating as a reseller. &nbsp;&nbsp;
            </li>
            <li>Reseller's sole remedies are against UVNV.&nbsp;</li>
            <li>
              Reseller will not use any intellectual property of T-Mobile,
              including but not limited to any T-Mobile name, tradename, mark,
              trademark, service mark, trade dress (including color, design,
              brand, and logo), copyrights, patents, inventions, &ldquo;moral
              rights,&rdquo; mask works, rights of personality, publicity or
              privacy, and any other intellectual property and proprietary
              rights, without T-Mobile's prior written consent, which may be
              withheld in its sole discretion.
            </li>
            <li>
              The Agreement can be terminated by UVNV if the Reseller fails to
              include the Mandatory End User Contract Terms in its agreements
              with End Users, the Reseller sells the Service in any offering
              that has not been approved by UVNV as a Service Application, or if
              there is a filing of any criminal indictment or information
              against the Resellers or its proprietors, partners, officers,
              directors or shareholders (to the extent such shareholders control
              in the aggregate or individual 10% or more of the voting rights or
              equity interests of the Reseller).
            </li>
          </ul>
        </section>
        <h1 className="page__title">
          MANDATORY END USER CONTRACT TERMS & CONDITIONS
        </h1>
        <p>
          Reseller will enter into terms of service with End Users which are at
          least as restrictive as the Mandatory End User Contract Terms &amp;
          Conditions in this Exhibit F.&nbsp;
        </p>
        <section className="list__section">
          <ul className="list__style">
            <li>
              End User has no contractual relationship with, and is not a
              third-party beneficiary of, any agreement between Reseller and
              UVNV or UVNV and the Carrier. End User agrees that the wireless
              service carrier or Reseller will have no legal, equitable or other
              liability of any kind to the End User.
            </li>
            <li>
              Subject to FCC Number portability rules, End User has no property
              or other rights in any assigned Number and such Number can be
              changed at any time.
            </li>
            <li>
              End User understands Service may be temporarily refused,
              interrupted, curtailed or limited because of atmospheric, terrain,
              or other natural or artificial conditions and may be temporarily
              interrupted or curtailed due to usage concentrations,
              modifications, upgrades, relocation and repairs of transmission
              Network. End User agrees that the wireless service carrier or
              Reseller will not be responsible for such interruptions of the
              Service or the inability to use the Service within or outside the
              Authorized Areas. &nbsp;
            </li>
            <li>
              End User understands that the wireless service carrier or Reseller
              cannot guarantee the security of wireless transmissions and will
              not be liable for any lack of security relating to the use of the
              Service.
            </li>
            <li>
              End User understands and agrees that the liability and obligations
              of Reseller to End User under the End User agreement for Services
              may be strictly controlled and limited by the wireless service
              carrier's tariff, if any, and the laws, rules and regulations of
              the FCC and other United States or foreign governmental
              authorities which from time to time have jurisdiction. In any
              event, regardless of the form of action, whether for breach of
              contract, warranty, negligence, strict liability in tort or
              otherwise, End User's exclusive remedy and the total liability of
              the wireless service carrier, Reseller, and/or any supplier of
              services to Reseller arising in any way in connection with the End
              User agreement, for any cause whatsoever, including, but not
              limited to, any failure or disruption of Service provided, will be
              limited to payment by Resellerof damages in an amount equal to the
              amount charged to End User for the Service provided under this End
              User agreement. In no event will Reseller and/or the wireless
              service carrier be liable for any cost, delay, failure or
              disruption of the Service, lost profits, or incidental, special,
              punitive or consequential damages.
            </li>
            <li>
              Reseller and/or the wireless service carrier will not be liable
              for the failure or incompatibility of Devices in connection with
              the Service. End User will use Devices at its own risk.
            </li>
            <li>
              End User will indemnify, defend and hold Reseller, the wireless
              service carrier and the officers, employees and agents of each of
              them harmless from and against all claims, causes of action,
              losses, expenses, liability or damages (including reasonable
              attorneys&rsquo; fees and costs), and including without limitation
              for any personal injury or death, arising in any way directly or
              indirectly in connection with the End User agreement; the
              provision or use of the Service; or the use, failure to use or
              inability to use the Number. This provision will survive the
              termination of the End User agreement.
            </li>
            <li>
              End User acknowledges that the End User agreement is assignable by
              Reseller.
            </li>
            <li>
              Service may be temporarily suspended or permanently terminated
              without notice if Reseller's agreement with the wireless service
              carrier is terminated for any reason, or the End User violates the
              Network rules and policies. End User waives any and all claims
              against the underlying wireless service carrier, including any
              roaming carrier, for such suspension or termination. &nbsp;
            </li>
            <li>
              End User will not engage in any of the actions described in
              Section 7.10 of the Agreement.&nbsp;
            </li>
            <li>
              End User will not Activate the Service outside of the Authorized
              Areas.&nbsp;
            </li>
          </ul>
        </section>

        <h1 className="page__title">
          MANDATORY END USER CONTRACT TERMS & CONDITIONS FOR BUNDLED IoT
          OFFERINGS
        </h1>
        <p>
          With respect to Bundled IoT Offerings, in addition to the Mandatory
          End User Contract Terms &amp; Conditions in Exhibit F, Reseller will
          include the following binding terms in its contracts with End Users:
        </p>
        <section className="list__section">
          <ul className="list__style">
            <li>
              End User will not use the Bundled IoT Offering in any way
              that:&nbsp;
            </li>
            <ul className="list__style">
              <li>
                compromises network security or capacity, degrades network
                performance, uses malicious software or malware, hinders other
                users&rsquo; access to the network, or otherwise adversely
                impacts network service levels or legitimate data flows;&nbsp;
              </li>
              <li>
                violates applicable law (including &ldquo;spam&rdquo; laws);
                or&nbsp;
              </li>
              <li>
                attempts or assists or facilitates anyone else in any of the
                above activities.
              </li>
            </ul>
            <li>
              End User has no contractual relationship with the Carrier and End
              User is not a third-party beneficiary of any agreement between the
              provider of the Bundled IoT Offering and the Carrier. End User
              understands and agrees that the Carrier will have no legal,
              equitable or other liability of any kind to End User.
            </li>
            <li>
              Subject to FCC number portability rules, End User has no property
              or other rights in any number assigned to it and End User
              understands that any such number can be changed from time to time.
            </li>
            <li>
              End User acknowledges that the IoT Services may be temporarily
              refused, interrupted, curtailed or limited because of atmospheric,
              terrain, or other natural or artificial conditions and may be
              temporarily interrupted or curtailed due to usage concentrations,
              modifications, upgrades, relocation and repairs of a transmission
              network. End User agrees that the Carrier will not be responsible
              for such interruptions of the IoT Services or the inability to use
              the IoT Services within or outside the Territory. End User
              understands that the Carrier and any other wireless service
              carriers cannot guarantee the security of wireless transmissions
              and will not be liable for any lack of security relating to the
              use of IoT Services Service.
            </li>
            <li>
              End User expressly understands and agrees that the liability and
              obligations of UVNV to End User under the agreement for the
              Bundled IoT Offering may be strictly controlled and limited by the
              Carrier's tariff, if any, and the laws, rules and regulations of
              the Federal Communications Commission and other United States or
              foreign governmental authorities which from time to time have
              jurisdiction.&nbsp;
            </li>
            <li>
              In no event will the Carrier be liable for the failure or
              incompatibility of Equipment utilized by End User in connection
              with the IoT Services. &nbsp;End User will use Equipment at its
              own risk.
            </li>
            <li>
              End User understands and agrees that personal data generated or
              collected by the provider of the Bundled IoT Offering or any IoT
              Solution will be governed by such provider's privacy and data
              security policy, as amended from time to time, and that any and
              all claims regarding use of personal data will be governed by the
              terms agreed between End User and that provider.
            </li>
            <li>
              End User will indemnify, defend and hold the Carrier and the
              officers, employees and agents of the Carrier harmless from and
              against all claims, causes of action, losses, expenses, liability
              or damages (including reasonable attorneys&rsquo; fees and costs),
              and including without limitation for any personal injury or death,
              arising in any way directly or indirectly in connection with the
              agreement or the provision or use of the Bundled IoT Offering.
              This provision will survive the termination of the agreement.
            </li>
            <li>
              End User acknowledges that the agreement is assignable by the
              provider of the Bundled IoT Offering.
            </li>
            <li>
              IoT Services may be temporarily suspended or permanently
              terminated without notice in the event that UVNV's agreement with
              the Carrier is terminated or otherwise permits suspension or
              termination or in the event the End User violates the Carrier's
              Terms and Conditions or other Network rules and policies. End User
              waives any and all claims against the Carrier or the underlying
              wireless service carrier, including any roaming carrier, for such
              suspension or termination.
            </li>
          </ul>
        </section>
      </main>
    );
  }
}
