import { connect } from 'react-redux';
import {
  fetchMe,
  signUpUser,
  getPartnerDetailFromUltra,
} from '../../store/user/duck';
import {
  fetchSPRPlans,
  fetchLoB,
  fetchFixedPlans,
} from '../../store/plan/duck';

import AddUserComponent from './component';

const AddUserContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
  }),
  // Map actions to dispatch and props
  {
    fetchMe,
    signUpUser,
    fetchSPRPlans,
    fetchLoB,
    fetchFixedPlans,
    getPartnerDetailFromUltra,
  }
)(AddUserComponent);

export default AddUserContainer;
