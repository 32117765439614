import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { geocodeByAddress } from 'react-places-autocomplete';

import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import ZipcodeComponent from './partials/zipcodeComponent';
import BrandModelComponent from './partials/brandModelComponent';
import CoverageComponent from './partials/coverageComponent';
import { DeviceCoverageIcon, BackArrowIcon } from '../../components/icons';
import './styles.scss';

export default function CoverageDetail(props) {
  const [step, setStep] = useState(1);
  const [zipcode, setZipcode] = useState('');
  const [brandsArray, setBrandsArray] = useState([]);
  const [zipcodeLoding, setZipcodeLoding] = useState(false);
  const [zipCodeApiError, setzipCodeApiError] = useState('');
  const [deviceApiError, setDeviceApiError] = useState('');
  const [brandLoding, setBrandLoding] = useState(false);
  const [imeiLoding, setImeiLoding] = useState(false);
  const [address, setAddress] = useState('');
  const [coverageResponse, coverageResponseSet] = useState({});
  const [deviceCompatibleResponse, setDeviceCompatibility] = useState({});

  useEffect(() => {
    (async (props) => {
      if (brandsArray.length === 0) {
        const {
          value: { success, data },
        } = await props.getBrands();
        if (success) {
          const sortedBrands = _.orderBy(
            _.get(data, 'brands', []),
            ['name'],
            ['asc']
          );
          setBrandsArray(sortedBrands);
        }
      }
    })(props);
  }, [props, brandsArray]);

  const StepOne = async (zipData) => {
    try {
      setZipcode(_.get(zipData, 'zipcode', ''));
      setZipcodeLoding(true);
      setzipCodeApiError('');
      if (_.get(zipData, 'zipcode', '') !== '') {
        const simType =
          parseInt(_.get(props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
        const {
          value: { success, data, statusCode },
        } = await props.coverageDetail({ zip: zipData.zipcode, simType });
        setZipcodeLoding(false);
        if (success) {
          coverageResponseSet(data);
          getAddressInfobyZipcode(zipData);
          setStep(2);
        } else {
          if (statusCode === 404) {
            setzipCodeApiError('Invalid zipcode');
          } else if (statusCode === 400) {
            setzipCodeApiError(
              'zipcode should NOT be longer than 5 characters'
            );
          } else {
            setzipCodeApiError('Error while processing request');
          }
        }
      }
    } catch (error) {
      setzipCodeApiError('Error while processing request');
    }
  };

  const getAddressInfobyZipcode = async (obj) => {
    geocodeByAddress(obj.zipcode)
      .then((results) => {
        if (results && results.length > 0) {
          let state = '';
          let city = '';
          let zip = '';
          results[0].address_components.map((addr) => {
            if (
              addr.types.indexOf('locality') !== -1 ||
              addr.types.indexOf('sublocality') !== -1
            ) {
              city = addr.long_name;
            }
            if (addr.types.indexOf('administrative_area_level_1') !== -1) {
              state = `, ${addr.short_name}`;
            }
            if (addr.types.indexOf('postal_code') !== -1) {
              zip = addr.long_name;
            }
            return addr;
          });
          const add = `${city}${state} ${zip}`;
          setAddress(add);
        }
      })
      .catch((error) => console.error(error));
  };

  const getDeviceCompatibility = async (obj) => {
    try {
      let isImei = _.get(obj, 'isImei', false);
      let isBrand = _.get(obj, 'isBrand', false);
      if (isImei) {
        setImeiLoding(true);
        delete obj.isImei;
      }
      if (isBrand) {
        setBrandLoding(true);
        delete obj.isBrand;
      }
      const simType =
        parseInt(_.get(props, 'user.lobId', 1)) === 3 ? 'fixed' : 'spr';
      obj.simType = simType;
      setDeviceApiError('');
      const {
        value: { success, data, statusCode },
      } = await props.getDeviceCompatibility(obj);
      setBrandLoding(false);
      setImeiLoding(false);
      if (success) {
        setDeviceCompatibility(data);
        setStep(3);
      } else if (statusCode === 400 || statusCode === 500) {
        setDeviceApiError('Please enter valid IMEI number.');
      } else if (statusCode === 404) {
        setDeviceApiError('Invalid IMEI number.');
      } else {
        setDeviceApiError('Error while processing request.');
      }
    } catch (error) {
      setDeviceApiError('Error while processing request.');
    }
  };

  const goBack = () => {
    if (step === 1) {
      props.history.push('/dashboard');
    } else if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  return (
    <div className="layout-has-sidebar">
      <SidebarNavigation {...props} />
      <TopNavigation {...props} />
      <main className="dashboard-layout-content">
        <div className="row align-items-md-center">
          <div className="col-md-6">
            <h2 className="heading-title device-coverage-title">
              <DeviceCoverageIcon className="icon" />
              Device/Coverage Checker
            </h2>
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-back ml-auto" onClick={goBack}>
              <BackArrowIcon className="back-icon" /> Back
            </button>
          </div>
        </div>
        {step && step === 1 && (
          <ZipcodeComponent
            nextStep={StepOne}
            zipcodeLoding={zipcodeLoding}
            zipCodeApiError={zipCodeApiError}
            {...props}
          />
        )}
        {step && step === 2 && (
          <BrandModelComponent
            checkDeviceCompatibilty={getDeviceCompatibility}
            brandLoding={brandLoding}
            imeiLoding={imeiLoding}
            brandsArray={brandsArray}
            deviceApiError={deviceApiError}
            {...props}
          />
        )}
        {step && step === 3 && (
          <CoverageComponent
            deviceCompatibility={deviceCompatibleResponse}
            coverageDetailData={coverageResponse}
            zipcode={zipcode}
            address={address}
            {...props}
          />
        )}
      </main>
    </div>
  );
}
