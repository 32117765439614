import { connect } from 'react-redux';

import UsersComponent from './component';

const UsersContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {}
)(UsersComponent);

export default UsersContainer;
