/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import TopNavigation from '../TopNavigation/container';
import ReactModal from 'react-modal';
import { Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon, CloseIcon, CalenderIcon } from '../../components/icons';
import _ from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import './styles.scss';

const dateFormat = 'MMMM YYYY';
let months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export default class UsageLogsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      updateLoading: false,
      updateIndex: -1,
      summary: {},
      simDetailData: {},
      usageLogsData: [],
      usageLogsDetailedData: [],
      viewUsageData: [],
      selectedViewUsage: {},
      isViewUsageLoading: false,
      dateFilter: '',
    };
  }

  componentDidMount = async () => {
    document.title = 'Usage Logs | UltraIoT';

    const {
      value: { success, user },
    } = await this.props.fetchMe();
    if (success && user) {
      this.props.setUser(user);
      this.setState({ userData: user });
    }

    this.getUsageDetails();
  };

  getUsageDetails = async () => {
    try {
      let pathName = _.get(window, 'location.pathname', '');
      let url = pathName.split('/');
      let iccid = '';
      let month = '';
      let year = '';
      let dateFilter = '';
      let date = new Date();
      if (url.length >= 2) {
        iccid = url[2];
      }
      if (url.length >= 3) {
        date = new Date(url[3]);
        month = parseInt(moment(date).month()) + 1;
        if (month < 10) {
          month = '0' + month;
        }
        //month = parseInt(moment(date).month()) < 10 ? ('0'+ (parseInt(moment(date).month()) + 1)) : (parseInt(moment(date).month()) + 1)
        year = moment(date).year();
      }
      dateFilter = new Date(moment(date).year(), moment(date).month(), 1);
      let obj = {
        iccid,
        year,
        month,
      };
      this.setState(
        { simDetailData: obj, dateFilter, isLoading: true },
        async () => {
          try {
            const {
              value: { success, records, summary },
            } = await this.props.getUsageDetailsByMonth(obj);
            if (success) {
              this.setState({
                usageLogsDetailedData: records,
                summary,
                isLoading: false,
              });
            } else {
              this.setState({
                usageLogsDetailedData: [],
                summary: {},
                isLoading: false,
              });
            }
          } catch (err) {
            this.setState({
              usageLogsDetailedData: [],
              summary: {},
              isLoading: false,
            });
          }
        }
      );
    } catch (error) {
      this.setState({
        usageLogsDetailedData: [],
        summary: {},
        isLoading: false,
      });
    }
  };

  viewUsage = async (item, index) => {
    const { simDetailData } = this.state;
    this.setState({
      updateLoading: true,
      isViewUsageModal: true,
      selectedViewUsage: item,
      updateIndex: index,
      updateCloseIndex: index,
      viewUsageData: [],
      isViewUsageLoading: true,
    });
    if (item.dateString) {
      let currenDate = moment(item.dateString, 'DD/MM/YYYY');
      let year = currenDate.year();
      let month = parseInt(currenDate.month()) + 1;
      let day = parseInt(currenDate.date());
      if (month < 10) {
        month = '0' + month;
      }
      try {
        let obj = {
          iccid: simDetailData.iccid,
          year: year.toString(),
          month,
          day: day < 10 ? '0' + day.toString() : day.toString(),
        };
        const {
          value: { success, records },
        } = await this.props.getUsageDetailsByDay(obj);
        if (success) {
          this.setState({
            updateLoading: false,
            viewUsageData: records,
            updateIndex: -1,
            isViewUsageLoading: false,
          });
        }
      } catch (error) {
        this.setState({
          updateLoading: false,
          isViewUsageModal: false,
          updateIndex: -1,
          isViewUsageLoading: false,
        });
      }
    }
  };

  onChangeDate = (date) => {
    this.setState({ dateFilter: date }, () => {
      let pathName = _.get(window, 'location.pathname', '');
      let url = pathName.split('/');
      let iccid = '';
      if (url.length >= 2) {
        iccid = url[2];
      }
      let month = months[moment(date).month()];
      let year = moment(date).year();
      let querystring = `/usage/${iccid}/${month}-${year}`;
      if (_.get(window, 'location.pathname', '') !== querystring) {
        if (window.history.pushState) {
          var newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            querystring.toString();
          window.history.pushState({ path: newurl }, '', newurl);
          this.getUsageDetails();
        }
      }
    });
  };

  closeModal = () => {
    this.setState({ isViewUsageModal: false, updateCloseIndex: -1 });
  };

  formatAMPM = (date) => {
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  render() {
    const {
      simDetailData,
      dateFilter,
      summary,
      usageLogsDetailedData,
      viewUsageData,
      selectedViewUsage,
    } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-4">
              <button
                className="btn btn-back"
                onClick={() =>
                  this.props.history.push(`/detail/${simDetailData.iccid}`)
                }
              >
                <BackArrowIcon className="back-icon" /> Back to
                {_.get(simDetailData, 'iccid', '') &&
                _.get(simDetailData, 'iccid', '') !== ''
                  ? _.get(simDetailData, 'iccid', '')
                  : ''}
              </button>
            </div>
            <div className="col-md-8 text-right"></div>
          </div>
          <Spin
            spinning={this.state.isLoading}
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: '#000' }} spin />
            }
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex">
                  <div className="w-100">
                    <div className="usage-summary-big">
                      <div className="usage-summary-big-header">
                        <h5 className="title">
                          {dateFilter && moment(dateFilter).format('MMMM YYYY')}{' '}
                          Usage Summary
                        </h5>
                        <div className="material-textfield ml-auto w-180">
                          <DatePicker
                            picker="month"
                            placeholder=""
                            format={dateFormat}
                            onChange={this.onChangeDate.bind(this)}
                            className="custom-datepicker"
                            dropdownClassName="custom-datepicker-overlay"
                            size="large"
                            showToday={false}
                            allowClear={false}
                            suffixIcon={<CalenderIcon />}
                            value={
                              this.state.dateFilter !== ''
                                ? moment(this.state.dateFilter)
                                : null
                            }
                          />
                          <label className="datepicker-textfield-label">
                            Usage Activity
                          </label>
                        </div>
                      </div>
                      <div className="usage-summary-big-body">
                        <table className="table usage-summary-table">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>
                                <span className="table-column-title">
                                  Total MB
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  Total Mins
                                </span>
                              </th>
                              <th>
                                <span className="table-column-title">
                                  Total SMS
                                </span>
                              </th>
                              <th>
                                {/* <span className="table-column-title">Total Overage Cost	</span> */}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="title">Summary</span>
                              </td>
                              <td>
                                <NumberFormat
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={'text'}
                                  value={_.get(summary, 'data', '')}
                                />
                              </td>
                              <td>
                                <NumberFormat
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={'text'}
                                  value={_.get(summary, 'voice', '')}
                                />
                              </td>
                              <td>
                                <NumberFormat
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  displayType={'text'}
                                  value={_.get(summary, 'sms', '')}
                                />
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card">
                      <div className="table-responsive">
                        {_.size(usageLogsDetailedData) > 0 ? (
                          <table className="table custom-table-secondary">
                            <thead>
                              <tr>
                                <th>
                                  <span className="table-column-title">
                                    Date
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">MB</span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    Mins
                                  </span>
                                </th>
                                <th>
                                  <span className="table-column-title">
                                    SMS{' '}
                                  </span>
                                </th>
                                <th colSpan="2">
                                  {/* <span className="table-column-title">Overage Cost		</span> */}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {usageLogsDetailedData.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {moment(
                                        _.get(item, 'dateString', ''),
                                        'DD/MM/YYYY'
                                      ).format('MM/DD/YYYY')}
                                    </td>
                                    <td>
                                      <NumberFormat
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={_.get(item, 'data', '')}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={_.get(item, 'voice', '')}
                                      />
                                    </td>
                                    <td>
                                      <NumberFormat
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        value={_.get(item, 'sms', '')}
                                      />
                                    </td>
                                    <td className="text-right">
                                      <button
                                        onClick={() =>
                                          this.viewUsage(item, index)
                                        }
                                        className="btn btn-view-usage"
                                      >
                                        View Usage
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <table className="table custom-table-secondary">
                            <tbody>
                              <tr>
                                <td>No Usage Available.</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          {/* Usage Displayed Modal */}
          <ReactModal
            isOpen={this.state.isViewUsageModal}
            onRequestClose={() =>
              this.setState({
                isViewUsageModal: false,
                updateLoading: false,
                viewUsageData: [],
              })
            }
            ariaHideApp={false}
            contentLabel="View Usage - Info"
            portalClassName="react-modal"
            overlayClassName="modal"
            className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
          >
            <div className="modal-content">
              <div className="react-modal-header">
                <h5 className="react-modal-title">
                  {_.get(selectedViewUsage, 'dateString', '') &&
                    moment(
                      _.get(selectedViewUsage, 'dateString', ''),
                      'DD/MM/YYYY'
                    ).format('MM/DD/YYYY')}{' '}
                  Usage Summary
                </h5>
                <button
                  type="button"
                  className="btn react-modal-close"
                  onClick={this.closeModal}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="react-modal-body p-0">
                <Spin
                  spinning={this.state.updateLoading}
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: '#000' }}
                      spin
                    />
                  }
                >
                  <div className="overflow-auto" style={{ maxHeight: 500 }}>
                    {_.size(viewUsageData) > 0 ? (
                      <table className="table custom-table-secondary">
                        <thead>
                          <tr>
                            <th>
                              <span className="table-column-title">Time</span>
                            </th>
                            <th>
                              <span className="table-column-title">Data</span>
                            </th>
                            <th>
                              <span className="table-column-title">Voice</span>
                            </th>
                            <th>
                              <span className="table-column-title">SMS</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewUsageData.map((item, index) => {
                            item.time = this.formatAMPM(new Date(item.date));

                            return (
                              <tr key={index}>
                                <td>{_.get(item, 'time', '')} UTC</td>
                                <td>
                                  <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={_.get(item, 'data', '')}
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={_.get(item, 'voice', '')}
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    value={_.get(item, 'sms', '')}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-muted text-center mt-4 mb-4">
                        No Usage Available.
                      </div>
                    )}
                  </div>
                </Spin>
              </div>
            </div>
          </ReactModal>
        </main>
      </div>
    );
  }
}
