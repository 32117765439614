/* eslint no-undef: "off"*/
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleAddress = async (address) => {
    const { isSetToState } = this.props;
    try {
      const results = await geocodeByAddress(address);
      let state = '';
      let city = '';
      let zip = '';
      let shortAddress = address.split(',')[0];
      results[0].address_components.map((addr) => {
        if (
          addr.types.indexOf('locality') !== -1 ||
          addr.types.indexOf('sublocality') !== -1
        ) {
          city = isSetToState ? `, ${addr.long_name}` : addr.long_name;
        }
        if (addr.types.indexOf('administrative_area_level_1') !== -1) {
          state = isSetToState ? `, ${addr.short_name}` : addr.short_name;
        }
        if (addr.types.indexOf('postal_code') !== -1) {
          zip = addr.long_name;
        }
        return addr;
      });
      if (isSetToState) {
        this.props.handleAddressChange({
          address: `${shortAddress}${city}${state} ${zip}`,
        });
        this.props.setValue(`${shortAddress}${city}${state} ${zip}`);
      } else {
        this.props.setValue(shortAddress);
        this.props.handleAddressChange({
          address: shortAddress,
          city,
          state,
          zip,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (address) => {
    this.setState({ address }, async () => {
      this.props.setValue(address);
      // this.handleAddress(address)
    });
  };

  handleSelect = (address) => {
    this.setState({ address }, async () => {
      this.handleAddress(address);
    });
  };

  render() {
    const { className, isRequired } = this.props;
    return (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                // placeholder: 'Search Places ...',
                className,
                required: true,
              })}
            />
            <label className="material-textfield-label">
              Address{' '}
              <span className="text-danger">{isRequired ? '*' : ''}</span>
            </label>
            {suggestions.length !== 0 && (
              <div className="autocomplete-dropdown-container">
                {/* {loading && <div>Loading...</div>} */}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
