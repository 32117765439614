/* eslint no-undef: "off"*/
import React, { PureComponent } from 'react';
import TopNavigation from '../TopNavigation/container';
import SidebarNavigation from '../SidebarNavigation/component';
import { BackArrowIcon } from '../../components/icons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import _ from 'lodash';
import './styles.scss';

export default class OrderDetailsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userData: {},
      orderDetail: [],
      errorMessage: '',
      limit: 50,
      page: 1,
      totalSims: 0,
    };
  }

  componentDidMount = async () => {
    document.title = 'Order Detail | UltraIoT';
    try {
      const {
        value: { success, user },
      } = await this.props.fetchMe();
      if (success && user) {
        this.props.setUser(user);
        this.setState({ userData: user });
        if (user.role === 'corporate_client' || user.role === 'user') {
          this.props.history.push('/dashboard');
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: _.get(error, 'message', 'Some thing went wrong...'),
      });
    }

    this.getOrderDetail();
  };

  getOrderDetail = async () => {
    try {
      let path = _.get(window, 'location.pathname', false);
      if (path.split('/order-detail/')[1]) {
        let orderId = path.split('/order-detail/')[1];
        const { page, limit } = this.state;
        const obj = { id: orderId, page: page, limit: limit };
        const {
          value: { success, order },
        } = await this.props.getOrderById(obj);
        if (success) {
          this.setState({
            orderDetail: order,
            totalSims: order.orderSimsCount,
            isLoading: false,
          });
        }
      }
    } catch (error) {
      const err = _.get(error, 'data', error);
      this.setState({
        isLoading: false,
        errorMessage: _.get(err, 'message', 'Some thing went wrong...'),
      });
    }
  };

  simDetail = (item) => {
    this.props.history.push(`/detail/${item.simnumber}`);
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.getOrderDetail();
    });
  };

  onLimitChange = (limit) => {
    this.setState({ limit, page: 1 }, () => {
      window.scrollTo(0, 0);
      this.getOrderDetail();
    });
  };

  redirectSimPage = () => {
    const orderType = _.get(this.props, 'location.state', 'spr');
    if (orderType === 'spr') {
      this.props.history.push('/available-sims');
    } else if (orderType === 'eSIM') {
      this.props.history.push('/available-esims');
    } else {
      this.props.history.push('/fixed-never-been-activated');
    }
  };

  render() {
    const { orderDetail, isLoading, limit, page, totalSims } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="row align-items-md-center mb-4">
            <div className="col-md-4">
              <button
                onClick={() => this.props.history.goBack()}
                className="btn btn-back"
              >
                <BackArrowIcon className="back-icon" /> Back
              </button>
            </div>
          </div>
          <div className="row m-b-15">
            <div className="col-md-5">
              <div className="side-heading">
                <h4 className="title">
                  Order #{orderDetail ? orderDetail.ordernumber : ''} SIMs
                </h4>
              </div>
            </div>
            <div className="col-md-7">
              {orderDetail && _.size(orderDetail.sims) > 0 && (
                <div className="text-right">
                  <button
                    onClick={this.redirectSimPage.bind(this)}
                    className="btn btn-purple w-180 m-r-10"
                  >
                    {_.get(this.props, 'location.state', 'spr') === 'eSIM'
                      ? 'Activate eSIMs'
                      : 'Activate SIMs'}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-15">
                <div className="card-body p-0">
                  <Spin
                    spinning={isLoading}
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: '#000' }}
                        spin
                      />
                    }
                  >
                    <div className="orders-sim-info">
                      <h4 className="title col">ICCID</h4>
                      {orderDetail && _.size(orderDetail.sims) > 0 ? (
                        <ul className="orders-sim-list">
                          {_.size(orderDetail.sims) > 0 &&
                            orderDetail.sims.map((item, index) => {
                              return _.get(item, 'ultraplan', '') ===
                                'IOTDVS' && _.get(item, 'planid', '') > 0 ? (
                                <li
                                  key={index}
                                  onClick={() => this.simDetail(item)}
                                >
                                  <span className="btn-view-apn">
                                    {item.simnumber}
                                  </span>
                                </li>
                              ) : (
                                <li key={index}>{item.simnumber}</li>
                              );
                            })}
                        </ul>
                      ) : (
                        <div className="table-responsive">
                          <table className="table custom-table-secondary">
                            <tbody>
                              <tr>
                                <td style={{ paddingLeft: 45 }}>
                                  No SIMs found.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </Spin>
                </div>
              </div>
              <Pagination
                className="mb-5"
                hideOnSinglePage
                onChange={this.onPageChanged}
                current={page}
                onShowSizeChange={(current, size) => {
                  this.onLimitChange(size);
                }}
                pageSize={limit}
                defaultCurrent={page}
                total={totalSims}
                showSizeChanger={false}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}
